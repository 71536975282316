import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import { from } from '@style/mediaQueries';
import { CaptionSmall } from '@style/typeface';

const StyledCard = styled.div`
  width: 100%;
  text-align: left;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  justify-content: space-between;
  cursor: auto;
  box-shadow: ${(props) => props.theme.boxShadow1};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  padding: 1rem;
  color: ${(props) => props.theme.primaryTextColor};
  letter-spacing: 0;
  line-height: 18px;
  div.optionalContent {
    font-size: ${(props) => props.theme.heading3FontSize};
  }
  header {
    font-weight: ${(props) => props.theme.fontWeightSemiBold};
    font-size: ${(props) => props.theme.heading3FontSize};
    line-height: 1.5rem;
  }
  div.supportingContent {
    ${CaptionSmall};
  }
  footer {
    font-size: ${(props) => props.theme.heading3FontSize};
  }
  &:focus {
    outline: none;
  }

  ${from('laptop')} {
    padding: 1rem 2rem;
  }
`;

export const Card = ({
  id,
  optionalContent = '',
  headerContent,
  supportingContent,
  actionContent,
  className = ',',
}) => (
  <StyledCard tabIndex={-1} id={id} className={className}>
    {optionalContent && <div className="optionalContent">{optionalContent}</div>}
    {headerContent && <header>{headerContent}</header>}
    {supportingContent && <div className="supportingContent">{supportingContent}</div>}
    {actionContent && <footer>{actionContent}</footer>}
  </StyledCard>
);

Card.defaultProps = {
  optionalContent: '',
  headerContent: '',
  supportingContent: '',
  actionContent: '',
  className: '',
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  optionalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  supportingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  actionContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
};
