import PACKAGE from '../../package.json';

const config = {
  // eslint-disable-next-line no-undef
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  // eslint-disable-next-line no-undef
  REACT_APP_GMAP_API_KEY: process.env.REACT_APP_GMAP_API_KEY,
  // eslint-disable-next-line no-undef
  REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID,
  VERSION: `v${PACKAGE.version}`,
};

export default config;
