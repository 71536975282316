import React from 'react';
import i18next from 'i18next';
import * as uuid from 'uuid';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import APP from '@config/constants';
import { SnackbarOptions } from '@redux/Snackbar/types';

export const makeShowSnackbarAction = ({ icon, translation, translationParams }: SnackbarOptions) => {
  const text = i18next.t(translation, translationParams);
  return {
    type: 'RRS_SHOW_SNACK',
    payload: {
      id: uuid.v4(),
      data: {
        label: (
          <Box center>
            {icon ? <Box marginRightSM>{icon}</Box> : null}
            <Text typography={'CaptionSmall'} weight={'700'}>
              {text}
            </Text>
          </Box>
        ),
        timeout: APP.snackbarDuration,
        button: undefined,
      },
    },
  };
};
