import styled from 'styled-components';

import { ModalFooter } from '@components/common/Modal';
import { StyledPrimaryButton as PrimaryButton } from '@components/deprecated/Buttons/PrimaryButton';

export const StyledWrapper = styled.div(
  ({ theme }) => `
      width: 540px;
    `
);

export const StyledFormWrapper = styled.div`
  overflow-x: hidden;
  padding: 48px 16px 40px 20px;
`;

export const StyledSectionWrapper = styled.div`
  display: flex;
  width: 2000px;
  margin-top: 24px;
`;

export const StyledBorderWrapper = styled.div(
  ({ theme }) => `
      background-color: ${theme.colors.coreWhite};
      border: 1px solid ${theme.colors.grey04};

      &:focus,
      &:focus-within {
        box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02}; 
        outline: none;
      }
    `
);

export const StyledForm = styled.form<{ activeSection?: number; isActive: boolean }>(
  ({ activeSection, isActive }) => `
      display: grid;
      grid-row-gap: 24px;
      width: calc(492px + 24px + 8px);
      padding-right: 28px;
      padding-left: 4px;
      transition: all 0.3s ease-out;
      opacity: ${isActive ? '1' : '0'};
      transform: translateX(${activeSection === 3 ? '-200%' : activeSection === 2 ? '-100%' : '0%'});
      margin-bottom: auto;
    `
);

export const StyledInputWrapper = styled.div(
  ({ theme }) => `
      margin-top: ${theme.spacing.xs}px;
    `
);

export const StyledInputPairWrapper = styled.div(
  ({ theme }) => `
      margin-top: ${theme.spacing.xs}px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    `
);

export const StyledFooter = styled(ModalFooter)(
  () => `
      display: flex;
      justify-content: space-between;
    `
);

export const Dot = styled.span<{ isActive: boolean }>(
  ({ theme, isActive }) => `
    margin-right: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${isActive ? theme.colors.grey08 : theme.colors.grey05}
    `
);

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 110px;
  padding: 0 14px;
  height: 2rem;
`;
