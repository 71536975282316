import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';

import * as types from '../actionTypes';

import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 */
export const makeRecreatePasswordRequestStartAction = () => ({
  type: types.RECREATE_PASSWORD_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeRecreatePasswordRequestSuccessAction = () => ({
  type: types.RECREATE_PASSWORD_SUCCESS,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeRecreatePasswordRequestFailureAction = () => ({
  type: types.RECREATE_PASSWORD_FAILURE,
});

/**
 *
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * @param oldPassword
 * @param newPassword
 * @param confirmationPassword
 * @param uid
 * @param token
 * @returns {Function}
 */
export const makeRecreatePasswordRequestThunk = (bodyObj, history) => {
  const payload = {
    new_password1: bodyObj.newPassword,
    new_password2: bodyObj.confirmationPassword,
    uid: bodyObj.uid,
    token: bodyObj.token,
  };
  const jsonData = JSON.stringify(payload);

  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeRecreatePasswordRequestStartAction());

    fetch(ENDPOINTS.passwordRecreate, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then((res) => res.json())
      .then((jsonObj = {}) => {
        // here, capturing the following errors:
        // "new_password2":["The password is too similar to the email address."]
        // "new_password2":["This password is too common."]
        // "token": ["Invalid value"]
        if ('errors' in jsonObj) {
          dispatch(makeRecreatePasswordRequestFailureAction());
          let snack = 'snack_default_msg';

          if ('new_password2' in jsonObj.errors) snack = 'snack_new_password_invalid';
          else if ('token' in jsonObj.errors) snack = 'snack_invalid_token';

          dispatch(makeShowSnackbarAction(snack));
        } else {
          dispatch(makeRecreatePasswordRequestSuccessAction());
          dispatch(makeShowSnackbarAction('snack_password_changed_msg'));
          // force redirect to login page:
          history.push(APP.routes.login);
        }
      });
  };
};
