import wkx from 'wkx';

import { MapUtil } from '@components/common/Map/util';
import {
  AnyInstance,
  isUncommitedInstance,
  TransientBlock,
  TransientDrop,
  TransientPoi,
  TransientState,
} from '@components/pollination/PolliMap/types';
import { PollinationBatch } from '@redux/Contract/types';

export const PolliMapUtil = {
  parseDropsStateToGlobal,
  parseBlocksStateToGlobal,
  parsePoisStateToGlobal,
  parseBlocksToTransient,
  parseDropsToTransient,
  parsePoisToTransient,
  areInstancesEquals,
  replacePoiName,
  generateNextPoiNumber,
  extractPoiNumber,
  buildSelectionParams,
};

function parseDropsStateToGlobal(drops: TransientState<AnyInstance<TransientDrop>>): PollinationBatch['drop'] {
  return {
    add: Object.values(drops.added).map(parseDropToGlobal),
    patch: Object.values(drops.edited)
      .filter((drop) => drop.hasChanged)
      .map(parseDropToGlobal),
    remove: Object.values(drops.removed).map(({ id }: any) => id),
  };
}

function parseBlocksStateToGlobal(blocks: TransientState<AnyInstance<TransientBlock>>): PollinationBatch['block'] {
  return {
    add: Object.values(blocks.added).map(parseBlockToGlobal),
    patch: Object.values(blocks.edited)
      .filter((block) => block.hasChanged)
      .map(parseBlockToGlobal),
    remove: Object.values(blocks.removed).map(({ id }: any) => id),
  };
}

function parsePoisStateToGlobal(
  pois: TransientState<AnyInstance<TransientPoi>>
): PollinationBatch['point_of_interest'] {
  return {
    add: Object.values(pois.added).map(parsePoiToGlobal),
    patch: Object.values(pois.edited)
      .filter((poi) => poi.hasChanged)
      .map(parsePoiToGlobal),
    remove: Object.values(pois.removed).map(({ id }: any) => id),
  };
}

function parseDropToGlobal(drop: AnyInstance<TransientDrop>): BeeDrop {
  const id = isUncommitedInstance(drop) ? undefined : drop.id;
  const {
    name,
    targetHiveNumber,
    position: { lat, lng },
  } = drop;

  return {
    id,
    name,
    targetHiveNumber,
    dropCenter: new wkx.Point(lng, lat).toGeoJSON(),
  } as BeeDrop;
}

function parseBlockToGlobal(block: AnyInstance<TransientBlock>): BeeBlock {
  const id = isUncommitedInstance(block) ? undefined : block.id;
  const { name, area, path } = block;

  const closedPath = MapUtil.getClosedPath(path);
  const coordinates = closedPath.map(({ lat, lng }) => new wkx.Point(lng, lat));

  return {
    id,
    name,
    area,
    geometry: new wkx.Polygon(coordinates).toGeoJSON(),
  } as BeeBlock;
}

function parsePoiToGlobal(poi: AnyInstance<TransientPoi>): BeePointOfInterest {
  const id = isUncommitedInstance(poi) ? undefined : poi.id;
  const {
    name,
    category,
    descriptionEnglish,
    descriptionSpanish,
    location: { lat, lng },
  } = poi;

  return {
    id,
    name,
    category: category,
    descriptionEnglish,
    descriptionSpanish,
    location: new wkx.Point(lng, lat).toGeoJSON(),
  } as BeePointOfInterest;
}

function parseDropsToTransient(drops: BeeDrop[]): TransientState<TransientDrop>['edited'] {
  const edited: TransientState<TransientDrop>['edited'] = {};
  drops
    .filter((drop) => !!drop.dropCenter)
    .forEach((drop) => {
      edited[drop.id] = parseDropToTransient(drop);
    });
  return edited;
}

function parseBlocksToTransient(blocks: BeeBlock[]): TransientState<TransientBlock>['edited'] {
  const edited: TransientState<TransientBlock>['edited'] = {};
  blocks.forEach((block) => {
    edited[block.id] = parseBlockToTransient(block);
  });
  return edited;
}

function parsePoisToTransient(pois: BeePointOfInterest[]): TransientState<TransientPoi>['edited'] {
  const edited: TransientState<TransientPoi>['edited'] = {};
  pois.forEach((poi) => {
    edited[poi.id] = parsePoiToTransient(poi);
  });
  return edited;
}

function parseDropToTransient({ id, name, dropCenter, targetHiveNumber, nbHives }: BeeDrop): TransientDrop {
  const [lng, lat] = dropCenter.coordinates as number[];
  return {
    id,
    name,
    targetHiveNumber,
    nbHives: nbHives ?? 0,
    isActive: Boolean(nbHives && nbHives > 0),
    position: { lat, lng },
  };
}

function parseBlockToTransient({ id, name, area, geometry }: BeeBlock): TransientBlock {
  const geometryCoordinates = geometry.coordinates[0] ?? [];
  return {
    id,
    name,
    area,
    path: geometryCoordinates.map(([lng, lat]) => ({ lng, lat })),
  };
}

function parsePoiToTransient({
  id,
  category,
  name,
  location,
  descriptionEnglish,
  descriptionSpanish,
}: BeePointOfInterest): TransientPoi {
  const [lng, lat] = location.coordinates as number[];
  return {
    id,
    category: category,
    name,
    descriptionEnglish,
    descriptionSpanish,
    location: { lat, lng },
  };
}

function areInstancesEquals(instanceA: any, instanceB: any) {
  if (typeof instanceA !== 'object' || instanceA === null || typeof instanceB !== 'object' || instanceB === null) {
    return instanceA === instanceB;
  }

  const skippableKeys = ['hasChanged'];
  const aKeys = Object.keys(instanceA).filter((k) => !skippableKeys.includes(k));
  const bKeys = Object.keys(instanceB).filter((k) => !skippableKeys.includes(k));

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (const key of aKeys) {
    const aProp = instanceA[key];
    const bProp = instanceB[key];
    if (!areInstancesEquals(aProp, bProp)) {
      return false;
    }
  }

  return true;
}

/**
 * Generates a new poi name replacing the given props.
 * E.g:
 *     replacePoiName({ name: "Access 1" }, { number: 10 })
 *     output >> "Access 10"
 *
 *     replacePoiName({ name: "Access 1" }, { category: "security" })
 *     output >> "Security 1"
 * */
function replacePoiName(poi: AnyInstance<TransientPoi>, options: { number?: number; category?: string }) {
  let poiName = poi.name ?? '';
  if (options.number) {
    poiName = poiName.replace(/(^.*?)(\s*)(\d*)$/, `$1$2${options.number}`);
  }
  if (options.category) {
    poiName = poiName.replace(/(^.*?)(\s*)(\d*)$/, `${options.category}$2$3`);
  }
  return poiName;
}

/**
 * Generates a new unique poi number.
 * E.g:
 *     generateNextPoiNumber("access", [{ name: "Access 1" }])
 *     output >> 2
 * */
function generateNextPoiNumber(category: BeePointOfInterestCategory, poisList: Array<AnyInstance<TransientPoi>>) {
  const poisNumbers = poisList
    .filter((poi) => poi.category === category)
    .map(extractPoiNumber)
    .sort((a, b) => a - b);
  const highestPoisNumber = poisNumbers.length ? poisNumbers[poisNumbers.length - 1] : 0;
  return highestPoisNumber + 1;
}

/**
 * Extracts the current poi number from its name.
 * E.g:
 *     extractPoiNumber({ name: "Access 1" });
 *     output >> 1
 * */
function extractPoiNumber(poi: AnyInstance<TransientPoi>) {
  const match = poi.name?.match(/^.*?(\d+)$/i);
  return match ? parseInt(match[1]) : 0;
}

/**
 * Returns a params object that can be used to
 * navigate to map at a specific selected element.
 * E.g:
 *     // Goes to map tab and selects the poi with id 10.
 *     tabs.goToTab('map', buildSelectionParams({ poi: 10 }));
 * */
function buildSelectionParams(params: { drop?: number; block?: number; poi?: number }) {
  if (Object.values(params).length > 1) {
    throw new Error(`Only one map element can be selected at time.`);
  }

  const entries = Object.entries({ drop: null, block: null, poi: null, ...params });
  return entries.reduce((params, [type, id]) => ({ ...params, [`${type}-item`]: id }), {});
}
