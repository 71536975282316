import styled, { css } from 'styled-components/macro';

import mediumSearchIcon from '@assets/Medium_Search_White.svg';
import smallSearchIconGrey from '@assets/Small_Search_Grey.svg';
import smallSearchIcon from '@assets/Small_Search_White.svg';
import { YardName } from '@components/common/YardName';

const SEARCH_BOX_WIDTH = '400px';
const SEARCH_BOX_HEIGHT = '40px';
const RESULTS_HEADER_HEIGHT = '36px';
const RESULTS_SHOW_MORE_HEIGHT = '44px';

const FontStyle = css`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  font-size: ${({ theme }) => theme.captionSmallFontSize};
  line-height: 12px;
  letter-spacing: 0.5px;
`;

export const ResultsLayout = styled.div`
  position: absolute;
  top: 0;
  width: 0;
  display: none;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  margin-top: ${({ theme }) => theme.tabBarHeight}px;
  flex-direction: column;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  overflow: hidden;

  ${({ isMobile }) => !isMobile && 'border-radius: 0 0 3px 3px;'};
`;

export const ResultsFooter = styled.div`
  padding: 16px;
  letter-spacing: 0.5px;
  ${FontStyle}
  color: ${({ theme }) => theme.deadoutColor};
  span {
    font-weight: 700;
  }
  border-top: ${({ theme }) => theme.tertiaryBorderStyle};
`;

export const NoResults = styled.div`
  padding: 16px;
  ${FontStyle}
  font-weight: 700;
  color: ${({ theme }) => theme.primaryColor};
`;

export const ResultsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;
  ${({ isMobile, theme }) =>
    isMobile
      ? `max-height: calc(100vh - ${theme.tabBarHeight}px - ${RESULTS_HEADER_HEIGHT} - ${RESULTS_SHOW_MORE_HEIGHT});`
      : 'max-height: 384px'};
`;

export const ShowMore = styled.div`
  padding: 16px;
  ${FontStyle}
  color: ${({ theme }) => theme.boxShadowColor};
  display: flex;
  div {
    cursor: pointer;
  }
  height: ${RESULTS_SHOW_MORE_HEIGHT};
`;

export const ResultsListItem = styled.button`
  display: flex;
  height: 48px;
  min-height: 48px;
  padding: 0 4px 0 16px;
  cursor: pointer;
  align-items: center;
  :hover,
  :focus {
    background: ${({ theme }) => theme.hoverColor};
    border-left: 2px solid ${({ theme }) => theme.selectedColor};
    padding-left: 14px;
  }
`;

export const StyledYardName = styled(YardName)`
  width: 160px;
  text-align: left;
  margin-right: 8px;
  white-space: nowrap;
`;

export const NbHives = styled.div`
  margin: 1px 8px 0 0;
  ${FontStyle}
  color: ${({ theme }) => theme.unknownColor};
  white-space: nowrap;
`;

export const YardIcon = styled.img`
  margin-right: 16px;
  filter: ${({ theme }) => theme.primaryColorFilter};
`;

export const ActiveStatus = styled.img`
  margin-right: 8px;
  min-height: 6px;
  min-width: 6px;
  background-color: ${({ active, theme }) => (active ? theme.gColor : theme.wColor)};
  border-radius: 50%;
  display: inline-block;
`;

export const ResultsHeader = styled.div`
  padding: 16px 0 8px 16px;
  ${FontStyle}
  font-weight: 600;
  color: ${({ theme }) => theme.deadoutColor};
  text-transform: capitalize;
  height: ${RESULTS_HEADER_HEIGHT};
`;

export const SearchBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 32px;
  height: 100%;
  align-self: center;
  margin-right: ${({ isMobile }) => (isMobile ? '1rem' : '2rem')};
  z-index: ${({ theme }) => theme.getZIndexOf('highLevelIndex')};
  ${({ searchExpandedOnMobile }) => searchExpandedOnMobile && 'margin-right: 0;'}
  ${ResultsLayout} {
    ${({ searchExpandedOnMobile }) =>
      searchExpandedOnMobile &&
      `display: flex;
    min-width: 100vw;
    margin: calc(${SEARCH_BOX_HEIGHT} + 0.5rem) 0 0 -1rem;`}
    ${({ expanded, theme }) => expanded && `min-height: calc(100vh - ${theme.tabBarHeight}px);`};
    ${({ resultFocused }) =>
      resultFocused &&
      `display: flex;
    width: ${SEARCH_BOX_WIDTH};`};
  }
  & > div:first-child {
    display: flex;
    align-items: center;
    position: ${({ isMobile }) => (isMobile ? 'relative' : 'absolute')};
    width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
    right: 0;
    height: 100%;
    background-color: ${(props) => props.theme.primaryColor};
  }
  input {
    position: relative;
    z-index: ${({ theme }) => theme.getZIndexOf('highLevelIndex')};
    cursor: pointer;
    :focus ~ ${ResultsLayout} {
      display: flex;
      width: ${({ isMobile }) => (isMobile ? '100%' : SEARCH_BOX_WIDTH)};
    }
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    font-size: ${({ theme }) => theme.paragraphSmallFontSize};
    line-height: ${({ theme }) => theme.defaultLineHeight};
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.primaryTextColor};
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    background-image: url(${({ isMobile }) => (isMobile ? smallSearchIcon : mediumSearchIcon)});
    background-repeat: no-repeat;
    border: none;
    background-position: ${({ isMobile }) => (isMobile ? '0 8px' : '0 4px')};
    width: ${({ isMobile }) => (isMobile ? '24px' : '32px')};
    height: 40px;
    background-color: ${({ theme }) => theme.primaryColor};
    border-radius: 3px;
    ::placeholder {
      font-style: normal;
      font-weight: ${({ theme }) => theme.fontWeightRegular};
      font-size: ${({ theme }) => theme.paragraphSmallFontSize};
      line-height: ${({ theme }) => theme.defaultLineHeight};
      letter-spacing: 0.5px;
      color: ${({ theme }) => theme.deadoutColor};
      opacity: 0;
    }
    :focus {
      cursor: auto;
      padding: 8px 56px 8px 48px;
      width: ${({ searchExpandedOnMobile }) => (searchExpandedOnMobile ? '100%' : SEARCH_BOX_WIDTH)};
      background-position: 16px 8px;
      outline: none;
      background-color: ${({ theme }) => theme.primaryBackgroundColor};
      background-image: url(${smallSearchIconGrey});
      ::placeholder {
        opacity: 1;
      }
      ${({ isMobile, theme, noDropDown }) =>
        !isMobile &&
        !noDropDown &&
        `border-radius: 3px 3px 0 0;
      border-bottom: 1px solid ${theme.timelineColor};
      `};
    }
    ${({ hasInput, theme, searchExpandedOnMobile, showHistory, resultFocused }) =>
      (hasInput || searchExpandedOnMobile || (showHistory && resultFocused)) &&
      `cursor: auto;
      padding: 8px 56px 8px 48px;
      width:  ${searchExpandedOnMobile ? '100%' : SEARCH_BOX_WIDTH};
      background-position: 16px 8px;
      outline: none;
      background-color: ${theme.primaryBackgroundColor};
      background-image: url(${smallSearchIconGrey});
      ::placeholder {
        visibility: visible;
      }`}
    ${({ resultFocused, theme }) =>
      resultFocused &&
      `border-radius: 3px 3px 0 0;
      border-bottom: 1px solid ${theme.timelineColor};
      `}
  }
`;

export const ClearCommand = styled.button`
  position: absolute;
  color: ${({ theme }) => theme.deadoutColor};
  cursor: pointer;
  align-self: center;
  left: calc(100% - ${({ searchExpandedOnMobile }) => (searchExpandedOnMobile ? '106px' : '47px')});
  z-index: ${({ theme }) => theme.getZIndexOf('highLevelIndex')};
  ${FontStyle}
`;

export const CancelCommand = styled.button`
  ${FontStyle}
  color: ${({ theme }) => theme.defaultBeekPracticeColor};
  cursor: pointer;
  align-self: center;
  margin-left: 16px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const ViewOnMap = styled.div`
  white-space: nowrap;
  display: flex;
  ${FontStyle};
  color: ${({ theme }) => theme.deadoutColor};
  margin-left: 8px;
  div {
    margin: 2px 4px 0 0;
  }
  img {
    filter: ${({ theme, isMobile }) => theme.colors.filter(isMobile ? 'grey08' : 'grey06')};
  }
`;
