import styled from 'styled-components';

import { StyledMapToolbarButton } from '@components/pollination/PolliMapToolbar/styles';

export const StyledMapToolbarButtonToggle = styled(StyledMapToolbarButton)<{ isVisible: boolean }>(
  ({ theme, isVisible }) => `
  position: absolute;
  top: ${theme.spacing.sm}px;
  right: ${theme.spacing.sm}px;
  height: 40px;
  transition: ${theme.animations.transitionFast('opacity')};
  
  opacity: ${isVisible ? 1 : 0};
  
  svg {
    margin-right: ${theme.spacing.xs}px;
  }
`
);
