import { ENDPOINTS } from '@config/api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';

import i18n from '../../../i18n';
import * as types from '../actionTypes';

import { makeFetchEventListRequestThunk } from './eventListRequestActionCreator';
import { makeShowSnackbarAction } from './snackbarActionCreator';

/**************** BATCH UPDATE YARDS ****************/

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeBatchUpdateYardsRequestStartAction = () => ({
  type: types.BATCH_UPDATE_YARDS_ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{yard: object}}
 *
 */
export const makeBatchUpdateYardsRequestSuccessAction = () => ({
  type: types.BATCH_UPDATE_YARDS_ACTION_SUCCESS,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeBatchUpdateYardsRequestFailureAction = () => ({
  type: types.BATCH_UPDATE_YARDS_ACTION_FAILURE,
});

export const makeBatchUpdateYardsRequestThunk = (detail, user) => {
  detail.patch = detail.patch.filter((yard) => !detail.remove.includes(yard.id));

  const jsonData = JSON.stringify(detail);

  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeBatchUpdateYardsRequestStartAction());

    return new Promise((accept, reject) => {
      fetch(`${ENDPOINTS.yardsBatch}`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors', // no-cors, cors, same-origin
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: jsonData,
      })
        .then(parseRawResponseToJson, parseRejectedToJson)
        .then((jsonObj = {}) => {
          if ('error' in jsonObj || 'errors' in jsonObj) {
            dispatch(makeBatchUpdateYardsRequestFailureAction());
            reject();
          } else {
            const snackbar_msg = jsonObj.are_hives_moving_yard
              ? i18n.t('changes_saved_update_delay')
              : i18n.t('changes_saved');
            dispatch(makeShowSnackbarAction(snackbar_msg, { icon: true }));
            dispatch(makeBatchUpdateYardsRequestSuccessAction());
            //settimeout by 5 for the event fetch after the batch operation completes
            setTimeout(() => {
              dispatch(makeFetchEventListRequestThunk(user, true));
            }, 5000);
            accept();
          }
        });
    });
  };
};
