import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';

import * as types from '../actionTypes';

import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 * action creator
 *
 * @note: using the redux-thunk middleware, an action creator
 * can return a function instead of an action object
 *
 * @returns {{type: string}}
 */
export const makeForgottenPasswordRequestStartAction = () => ({
  type: types.FORGOTTEN_PASSWORD_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeForgottenPasswordRequestSuccessAction = () => ({
  type: types.FORGOTTEN_PASSWORD_SUCCESS,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeForgottenPasswordRequestFailureAction = () => ({
  type: types.FORGOTTEN_PASSWORD_FAILURE,
});

/**
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 *
 * Since reducers are supposed to be “pure” (as in, they don’t change
 * anything outside their scope) we can’t do any API calls or dispatch
 * actions from inside a reducer.
 * the redux-thunk middleware knows how to handle functions
 * It passes the dispatch method as an argument to the function,
 * thus making it able to dispatch actions itself.
 * @see https://github.com/reduxjs/redux-thunk
 *
 * @param email
 * @returns {Function}
 */
export const makeForgottenPasswordRequestThunk = (email, history) => {
  return (dispatch) => {
    // show the spinning screen:
    dispatch(makeForgottenPasswordRequestStartAction());

    const jsonData = JSON.stringify(email);

    fetch(ENDPOINTS.passwordForgotten, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          // stop the spinner:
          dispatch(makeForgottenPasswordRequestFailureAction());
          // display the snack:
          dispatch(makeShowSnackbarAction(jsonObj.error, { status: jsonObj.status }));
          return;
        } else {
          // all is right, hide the spinner
          dispatch(makeForgottenPasswordRequestSuccessAction());
          // display the snack:
          dispatch(makeShowSnackbarAction('snack_password_reset_email_sent', email));
          // force redirect to login page:
          history.push(APP.routes.login);
        }
      });
  };
};
