import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip-currenttarget';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WeatherTooltipStyled = styled(ReactTooltip)`
  opacity: 1 !important;
  z-index: ${(props) => props.theme.getZIndexOf('lowLevelIndex')};
  text-transform: uppercase;
  width: 250px;
  height: 73px;
  box-shadow: ${(props) => props.theme.boxShadow4};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  color: #d4d4d4;
  padding: 0 !important;
`;

export class WeatherTooltip extends Component {
  render() {
    const { id, disable, tooltipText } = this.props;

    var timeout;

    const startTimeout = () => {
      timeout = setTimeout(ReactTooltip.hide, 5000);
    };

    const stopTimeout = () => {
      clearTimeout(timeout);
    };

    return (
      <WeatherTooltipStyled
        key={id}
        id={id}
        disable={disable}
        getContent={() => tooltipText}
        multiline={true}
        effect="solid"
        place="top"
        arrowColor="transparent"
        backgroundColor="#252629"
        afterShow={() => startTimeout()}
        afterHide={() => stopTimeout()}
      />
    );
  }
}

WeatherTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.object]),
};
