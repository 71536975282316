import React, { useCallback, useState } from 'react';
import { Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// icons
import hiveAddedIcon from '@assets/alerts/xSmall_HiveAdded.svg';
import { AlertsIconContainer, StyledImg } from '@components/deprecated/Elements/EventsElements';
import { StyledCaptionSmall, StyledParagraphSmall } from '@components/deprecated/Elements/TypographyElements';
import { InspectionView } from '@components/deprecated/Partials/InspectionView';
import { useHiveModalOpener } from '@components/hive/HiveModal/hooks';
import { Children } from '@helpers/Children';
import { getIconByType } from '@helpers/deprecated/icons';
// nectar
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getMoment, getTime } from '@helpers/deprecated/time';
import { Sorting } from '@helpers/Sorting';
import { hiveSerialFormatter } from '@helpers/StringTransformers';
import { makeOpenHiveModalAction } from '@redux/deprecated/actions';

const VerticalDivider = styled.div`
  height: 100%;
  border-right: ${(props) => props.theme.primaryBorderStyle};
  transform: translateX(-50%);
  width: 2rem;
  margin-right: 1rem;
`;

const Date = styled.span`
  ${StyledParagraphSmall};
  font-size: 0.875rem;
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  line-height: 1.5rem;
  color: ${(props) => props.theme.supportingColor};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.fontWeightBold};
  color: ${(props) => props.theme.primaryTextColor};
`;

const Link = styled.a`
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
`;

const BoldLink = styled(Link)`
  color: ${(props) => props.theme.primaryTextColor};
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

const AlertContainer = styled.div`
  width: 80%;
  color: ${(props) => props.theme.supportingColor};
  line-height: 1.5rem;
  font-size: 0.875rem;
`;

/**
 * For hive inspections, it sets how many hive
 * tags to show by default until the user clicks
 * to see more.
 * */
const MAX_HIVE_TAGS_PREVIEW = 4;

export const BeeTrackEventsView = ({ t, events, actionList, hive_identity_id, type }) => {
  const [eventsShowMoreDictionary, setEventsShowMoreDictionary] = useState({});
  const { openHiveModal } = useHiveModalOpener();

  const toggleEventShowMore = useCallback((eventId) => {
    setEventsShowMoreDictionary((curr) => ({
      ...curr,
      [eventId]: !curr[eventId],
    }));
  }, []);

  const openHiveDetailsModal = useCallback(
    (yardId, yardName, hiveId) => {
      openHiveModal(hiveId);
    },
    [openHiveModal]
  );

  const getDate = (analyzed_at) => {
    const today = moment().endOf('day');
    const tomorrow = moment().add(1, 'day').endOf('day');
    if (analyzed_at < today) return t('today');
    if (analyzed_at < tomorrow) return t('tomorrow');
    else return getMoment(analyzed_at);
  };

  const buildCustomAlertString = ({ event_list_id, yard_id, event_details }) => {
    const {
      alert_type,
      yard_name,
      user_name,
      new_hives,
      nb_hives_created,
      nb_hives_moved,
      inspection_nb_hives,
      hives,
      yard_total_nb_hives,
      city_name,
      edited_information,
      has_yard_been_resized,
    } = event_details;

    const hiveAdded = new_hives?.includes(hive_identity_id);
    let user = user_name && ` by ${user_name}`;
    let city = city_name && ` around ${city_name}`;
    let information = edited_information?.map((info) => t(info));

    // TODO: check that these yards have been merged, if so the older yard activities would need to include the "[yard_name]" in alerts #301, #302, #303, and #312.
    // let hive_merged = false;

    let hive_yard_spec = '';
    let hive_yard_spec_prefixed = '';

    switch (type) {
      case 'hive_activity':
        hive_yard_spec = (
          <>
            {' '}
            <Bold>{yard_name}</Bold>
          </>
        );
        hive_yard_spec_prefixed = hive_yard_spec;
        break;
      case 'yard_activity':
        const isShowingMore = eventsShowMoreDictionary[event_list_id];
        const sortedHivesList = Sorting.sort(hives ?? [], 'tag_serial');
        const slicedHivesList = sortedHivesList.slice(0, isShowingMore ? undefined : MAX_HIVE_TAGS_PREVIEW);
        const hasOverflowingItems = sortedHivesList.length > MAX_HIVE_TAGS_PREVIEW;
        const hiddenHivesCount = sortedHivesList.length - slicedHivesList.length;
        const singleSeparator = hasOverflowingItems && !isShowingMore ? ', ' : ` ${t('and')} `;
        const lastSeparator = hasOverflowingItems && !isShowingMore ? ', ' : `, ${t('and')} `;

        const showMoreContent = hasOverflowingItems && (
          <>
            {isShowingMore ? ' ' : ', '}
            <Link onClick={() => toggleEventShowMore(event_list_id)}>
              {isShowingMore ? t('show_less').toLowerCase() : `+${hiddenHivesCount}`}
            </Link>
            <br />
          </>
        );

        const itemWrapper = (item, separator) => (
          <>
            <nobr>
              <BoldLink
                key={item.hive_identity_id}
                onClick={() => openHiveDetailsModal(yard_id, yard_name, item.hive_identity_id)}
              >
                {hiveSerialFormatter(item.tag_serial)}
              </BoldLink>
              {separator}
              <wbr />
            </nobr>
            <wbr />
          </>
        );

        const readableTagList = Children.createWithSeparators(slicedHivesList, {
          singleSeparator,
          lastSeparator,
          itemWrapper,
        });

        hive_yard_spec = (
          <>
            {' '}
            {readableTagList}
            {showMoreContent}
          </>
        );
        hive_yard_spec_prefixed = (
          <>
            {' '}
            {t('for')} {hive_yard_spec}
          </>
        );
        break;
      default:
        break;
    }

    let hives_excluded = yard_total_nb_hives - inspection_nb_hives;
    let additional_info = '.';
    if (0 !== hives_excluded && yard_total_nb_hives && 'yard_activity' === type)
      additional_info = ` (${maybePluralize(hives_excluded, 'hive', t)} excluded).`;
    let totalNumHives = nb_hives_created + nb_hives_moved;

    switch (alert_type) {
      case 'new_yard_created':
        if ('hive_activity' === type)
          return (
            <>
              <Bold>{yard_name} created</Bold> {user} and is included in this hive.
            </>
          );
        return (
          <>
            <Bold>{t('yard_created')}</Bold>
            {city}
            {user}.
          </>
        );
      case 'new_hive_report':
        return (
          <>
            <Bold>{t('hive_inspection')}</Bold>
            {hive_yard_spec_prefixed}
            {user}.
          </>
        );
      case 'new_yard_report':
        return (
          <>
            <Bold>{t('yard_inspection')}</Bold> {t('at')} <Bold>{yard_name}</Bold>
            {user}
            {additional_info}
          </>
        );
      case 'hive_added_to_yard':
        switch (true) {
          case !hive_identity_id:
            return (
              <>
                {capitalize(maybePluralize(totalNumHives, 'hive', t, 's', false))} {hive_yard_spec} {t('added_at')}{' '}
                <Bold>{yard_name}</Bold>
                {user}. {has_yard_been_resized && t('yard_boundaries_updated')}
              </>
            );
          case hiveAdded:
            return (
              <>
                {t('hive_created_at')}
                {hive_yard_spec}
                {user}.
              </>
            );
          default:
            return (
              <>
                {t('hive_moved_to')}
                {hive_yard_spec}
                {user}.
              </>
            );
        }
      case 'yard_edited_by_manager':
        return (
          <>
            <Bold>Yard {information.join(', ')}</Bold> were <Bold>updated</Bold>
            {user}.
          </>
        );
      case 'hive_moved_to_yard':
        if ('hive_activity' === type)
          return (
            <>
              This hive was reassigned to <Bold>{yard_name}</Bold>
              {user}.
            </>
          );
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {events.map((alert, idx, arr) => {
        const { alert_type, new_hives, actions, note } = alert?.event_details;
        const hiveAdded = new_hives?.includes(hive_identity_id);

        if ('hive_moved_to_yard' === alert_type && 'yard_activity' === type) return null;

        return (
          <div
            key={alert.event_list_id}
            style={{
              textAlign: 'left',
            }}
          >
            {/* we don't want to render the date again if the previous entry is the same date, but if the previous entry is an alert type hive_moved_to_yard which we do not display, we may lose the date, so conditionally render it */}
            {(events[idx - 1]?.event_details.alert_type === 'hive_moved_to_yard' ||
              getDate(events[idx]?.analyzed_at) !== getDate(events[idx - 1]?.analyzed_at)) && (
              <Row
                style={{
                  paddingTop: idx !== 0 ? '2rem' : '0.75rem',
                  paddingBottom: '1rem',
                }}
              >
                <Date>{getDate(alert?.analyzed_at)}</Date>
              </Row>
            )}

            <div style={{ display: 'flex' }}>
              <div style={{ overflowY: 'hidden' }}>
                <AlertsIconContainer style={{ marginRight: '1rem' }}>
                  <StyledImg
                    src={hiveAdded ? hiveAddedIcon : getIconByType('alerts', alert_type, 'x')}
                    alt="alert icon"
                  />
                </AlertsIconContainer>
                {Boolean(idx < arr.length - 1) && <VerticalDivider />}
              </div>

              <div style={{ flex: 1 }}>
                <AlertContainer>{buildCustomAlertString(alert)}</AlertContainer>

                <div
                  style={{
                    width: '80%',
                    marginTop: '0.5rem',
                    marginBottom: '3rem',
                  }}
                >
                  {('new_hive_report' === alert_type || 'new_yard_report' === alert_type) && (
                    <InspectionView actions={actions} note={note} actionList={actionList} type="events" />
                  )}
                  <StyledCaptionSmall>{getTime(alert.analyzed_at)}</StyledCaptionSmall>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

BeeTrackEventsView.propTypes = {
  t: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  actionList: PropTypes.array.isRequired,
  hive_identity_id: PropTypes.number,
  type: PropTypes.string.isRequired,
};
