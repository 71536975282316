import styled from 'styled-components';

import Container from '@components/deprecated/Container/Container';
import { StyledEditIcon } from '@components/deprecated/Elements/SummaryElements';
import { Table } from '@components/deprecated/Table_deprecated/Table';
import { from, until } from '@style/mediaQueries';

export const StyledContainer = styled(Container)`
  margin: 66px 0.75rem;

  ${from('laptop')} {
    padding: 0;
    margin: 66px 3rem;
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;
  box-shadow: initial;
  tr {
    td:nth-child(12) {
      width: 20%;
    }
  }
  th {
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const StyledTr = styled.tr`
  position: relative;
  &:hover {
    ${from('tablet')} {
      background-color: ${({ theme }) => theme.colors.grey01};
    }
  }
`;

export const StyledMoreIcon = styled(StyledEditIcon)`
  margin-right: 34.5px;
`;

export const StatusSpan = styled.span<{ isActive: boolean }>`
  cursor: pointer;
  text-transform: capitalize;
  background-color: ${(props) => (props.isActive ? 'inherit' : props.theme.colors.yellow01)};
  border-radius: ${(props) => props.theme.shape.paperRadius01}px;
  padding: 3px 5px;
`;

export const StyledTableHeading = styled.th`
  font-size: 14px;
`;

export const HiddenOnMobileTh = styled(StyledTableHeading)`
  ${until('tablet')} {
    display: none;
  }
`;

export const HiddenOnMobileTd = styled.td`
  ${until('tablet')} {
    display: none;
  }
`;

export const StyledBottomRightNav = styled.div`
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
`;

export const StyledLastTd = styled.td`
  text-align: right;
  padding-right: 20px;
`;
