import React, { MouseEventHandler, ReactNode } from 'react';

import { UnstyledButton } from '../UnstyledButton';

const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

export interface UnstyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

/**
 *
 * Purpose: for elements that the user should be able to focus on, in which
 * an event is caused another way, such as being handled by the tooltip library
 */

export const UnclickableButton: React.FC<UnstyledButtonProps> = ({ children, ...props }) => {
  return (
    <UnstyledButton {...props} onClick={handleClick}>
      {children}
    </UnstyledButton>
  );
};
