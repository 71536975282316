import * as React from 'react';
import { useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';

import type { ComboBoxProps } from '@react-types/combobox';

import { Close } from '../Icon';

import { ListBox } from './ListBox';
import { Popover } from './Popover';
import {
  StyledDisabledOuterWrapper,
  StyledInput,
  StyledInputPillsWrapper,
  StyledLabel,
  StyledName,
  StyledOuterWrapper,
  StyledPillButton,
  StyledSpan,
  StyledUL,
  StyledWrapper,
} from './styles';

type Item = {
  id: string | number;
  name: string;
};
type DataDictionary = Record<string | number, Item>;

interface PillsListProps {
  selectedKeys: React.Key[];
  dataDictionary: DataDictionary;
  deleteKey: (key: React.Key) => void;
}

const PillsList = ({ selectedKeys, deleteKey, dataDictionary }: PillsListProps) => {
  return (
    <StyledUL>
      {selectedKeys?.map((key, index) => (
        <li key={key}>
          <StyledPillButton type="button" key={key} onClick={(e) => deleteKey(key)}>
            <StyledSpan>{dataDictionary[key]?.name}</StyledSpan>
            <Close />
          </StyledPillButton>
        </li>
      ))}
    </StyledUL>
  );
};

const NamesList = ({ selectedKeys, dataDictionary }: { selectedKeys: React.Key[]; dataDictionary: DataDictionary }) => {
  const selectedNames = selectedKeys.map((key) => dataDictionary[key]?.name);
  return <StyledName>{selectedNames.join(', ')}</StyledName>;
};

interface InputPillProps {
  name: string;
  id?: string;
  selectedKeys: React.Key[];
  dataDictionary: DataDictionary;
  setSelectedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>; // todo, make this an onChange
}

export function InputPill<T extends object>(props: ComboBoxProps<T> & InputPillProps) {
  const [selectedKey, setSelectedKey] = React.useState<React.Key>();
  const { startsWith } = useFilter({ sensitivity: 'base' });
  const { selectedKeys, setSelectedKeys, dataDictionary } = props;

  const updatePills = (key: React.Key) => {
    if (!key) return;
    setSelectedKey(key);
    setSelectedKeys((ids) => [...ids, key]);
  };

  const state = useComboBoxState({
    ...props,
    selectedKey,
    onSelectionChange: updatePills,
    defaultFilter: startsWith,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const deletePill = (key: React.Key) => {
    const newSelectedCropIds = selectedKeys.filter((id) => id !== key);
    setSelectedKeys(newSelectedCropIds);
    inputRef?.current && inputRef?.current.focus();
  };

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      placeholder: selectedKeys.length > 0 ? '' : props.placeholder,
      inputRef,

      listBoxRef,
      popoverRef,
    },
    state
  );

  React.useEffect(() => {
    setSelectedKey('');
    state.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  React.useEffect(() => {
    if (!state.inputValue) {
      state.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.inputValue]);

  if (props.isDisabled) {
    return (
      <StyledDisabledOuterWrapper>
        <StyledLabel {...labelProps} $isDisabled={props.isDisabled}>
          {props.label}
        </StyledLabel>
        <NamesList selectedKeys={selectedKeys} dataDictionary={dataDictionary} />
      </StyledDisabledOuterWrapper>
    );
  }

  return (
    <StyledOuterWrapper tabIndex={0} hasSelectedItems={selectedKeys.length > 0}>
      <StyledWrapper>
        <StyledLabel {...labelProps}>{props.label}</StyledLabel>
        <StyledInputPillsWrapper>
          <PillsList selectedKeys={selectedKeys} deleteKey={deletePill} dataDictionary={dataDictionary} />
          <StyledInput {...inputProps} ref={inputRef} />
        </StyledInputPillsWrapper>
        <NamesList selectedKeys={selectedKeys} dataDictionary={dataDictionary} />
      </StyledWrapper>
      {state.isOpen && state.inputValue !== '' && (
        <Popover popoverRef={popoverRef} isOpen={state.isOpen} onClose={state.close}>
          <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
    </StyledOuterWrapper>
  );
}
