import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { Text } from '@components/common/Text';

export const StyledTimeline = styled(Box)(
  ({ theme }) => css`
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    flex: 1 1 0;
    padding: 0 ${theme.spacing.md}px;
  `
);

export const StyledTimelineInner = styled(Box)<{ $isVisible: boolean; $suppressDayMarks?: boolean }>(
  ({ theme, $isVisible, $suppressDayMarks }) => css`
    ${$suppressDayMarks
      ? css`
          padding: ${theme.spacing.xs}px 0 ${theme.spacing.md}px 0;
        `
      : css`
          padding: ${theme.spacing.md}px 0;
        `}

    opacity: 0;

    ${$isVisible &&
    css`
      opacity: 1;
    `}
  `
);

export const StyledTimelineItemGroup = styled(Box)(
  ({ theme }) => css`
    border-left: ${theme.colors.grey05} 1px solid;
    margin: ${theme.spacing.md}px 0;
    margin-left: ${theme.spacing.xs}px;
    margin-top: calc(${theme.spacing.md}px + ${theme.spacing.sm}px);
  `
);

export const StyledTimelineItem = styled(Box)(
  ({ theme }) => css`
    position: relative;
    padding-left: ${theme.spacing.md}px;
    color: ${theme.colors.grey06};
  `
);

export const StyledTimelineItemIconWrapper = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: -12px;
    width: 24px;
    height: 24px;
    background-color: ${theme.colors.grey08};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  `
);

export const StyledTimelineFooter = styled(Box)(
  ({ theme }) => css`
    position: relative;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${theme.colors.grey05};
    }
  `
);

export const StyledTimelineFooterCard = styled(Box)(
  ({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.coreWhite};
    border: ${theme.colors.grey05} 1px solid;
    border-radius: ${theme.shape.paperRadius01}px;
    padding: 0 ${theme.spacing.sm}px;
    align-items: center;
    height: 32px;

    a {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      line-height: inherit;
    }
  `
);

export const StyledHeading = styled(Text)(
  ({ theme }) => css`
    color: ${theme.colors.grey06};
    strong {
      color: ${theme.colors.grey08};
      font-weight: 600;
    }
  `
);

export const StyledUnderlinedButton = styled(Button).attrs({
  tertiary: true,
})(
  ({ theme }) => css`
    color: ${theme.colors.grey08};
    font-weight: 600;
    display: inline;
    padding: 0;
  `
);

export const StyledYardNameLink = styled(NavRouterLink).attrs({
  tertiary: true,
})(
  () => css`
    display: inline-block;
    padding: 0;
  `
);

export const StyledYardBoxInfo = styled(Text)(
  ({ theme }) => css`
    border-radius: ${theme.shape.paperRadius01}px;
    background-color: ${theme.colors.grey05};
    color: ${theme.colors.grey08};
    padding: 0 ${theme.spacing.xxs}px;
    font-weight: 600;
  `
);

export const StyledGoToTopArrowButton = styled(Button).attrs({
  primary: true,
  paddingHorizontal: false,
  paddingVertical: false,
})<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    position: absolute;

    align-items: center;
    justify-content: center;

    bottom: ${theme.spacing.md}px;
    right: ${theme.spacing.md}px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: ${theme.shadows.boxShadow03};
    z-index: ${theme.getZIndexOf('highLevelIndex')};
    transform: scale(0);
    pointer-events: none;
    transition: ${theme.animations.transitionMedium('transform')};

    svg {
      transform: rotate(-90deg);
    }

    ${$isVisible &&
    css`
      transform: scale(1);
      pointer-events: auto;
    `};
  `
);
