import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Download = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3C10 2.44772 10.4477 2 11 2H13C13.5523 2 14 2.44772 14 3V11H17.5C17.7004 11.0036 17.8793 11.1266 17.9545 11.3124C18.0297 11.4983 17.9866 11.7111 17.845 11.853L17.839 11.859L17.831 11.865L17.802 11.893L12.702 16.705L12.692 16.717L12.679 16.728C12.2943 17.0887 11.6957 17.0887 11.311 16.728L11.278 16.697L6.17801 11.883C6.17518 11.8804 6.17234 11.8779 6.16953 11.8754C6.16141 11.8682 6.15343 11.8612 6.14601 11.853C6.00335 11.7099 5.96085 11.495 6.0383 11.3083C6.11576 11.1217 6.29794 11 6.50001 11H10V3ZM1.98601 20.986C1.99374 20.4337 2.44772 19.9923 3.00001 20H21.028C21.3853 20.005 21.7127 20.2002 21.887 20.5121C22.0613 20.824 22.056 21.2052 21.873 21.5121C21.6901 21.819 21.3573 22.005 21 22H2.97201C2.41972 21.9923 1.97827 21.5383 1.98601 20.986Z"
      />
    </IconSVGView>
  );
};
