import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Color } from 'styled-components';

import { AccordionContent } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { Inspection } from '@components/common/Icon/presets/Inspection';
import { ProgressBar } from '@components/common/ProgressBar';
import { Text } from '@components/common/Text';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import { YardsMapLayer } from '@components/yard/YardsMap/types';
import {
  StyledAccordion,
  StyledAccordionHeader,
  StyledCardWrapper,
  StyledProgressCard,
} from '@components/yard/YardsMapLastInspectionsCard/styled';
import { LastInspectionsConfig } from '@config/LastInspectionConfig';
import { useLastInspectionSpecGetter } from '@config/LastInspectionConfig/hooks';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { Sorting } from '@helpers/Sorting';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makePatchAppliedFiltersAction } from '@redux/YardsFilters/actions';
import { useYardsFilters } from '@redux/YardsFilters/hooks';
import { YardsFiltersCategoryKey } from '@redux/YardsFilters/types';

export const YardsMapLastInspectionsCard: React.VFC = () => {
  const { t } = useTranslation();
  const { activeLayers } = useYardsMap();

  const [expanded, setExpanded] = useState(true);
  const isLastInspectionsEnabled = !!activeLayers[YardsMapLayer.LAST_INSPECTIONS];

  return (
    <StyledCardWrapper $visible={isLastInspectionsEnabled}>
      <StyledAccordion initiallyExpanded expanded={expanded} onExpandedChange={setExpanded}>
        <StyledAccordionHeader $expanded={expanded}>
          <Inspection size={16} />
          <Text typography={'Heading3'} weight={'600'}>
            {t('last_visits')}
          </Text>
        </StyledAccordionHeader>
        <AccordionContent>
          <LastInspectionsStats />
        </AccordionContent>
      </StyledAccordion>
    </StyledCardWrapper>
  );
};

const LastInspectionsStats = () => {
  const { yards } = useYardsMap();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getLastInspectionsColorSpec = useLastInspectionSpecGetter();

  const yardsColorSpecs = useMemo(() => yards.map(getLastInspectionsColorSpec), [getLastInspectionsColorSpec, yards]);

  const { appliedFilters, appliedFiltersCount } = useYardsFilters();
  const appliedLastInspectionFilters = useMemo(
    () => appliedFilters[YardsFiltersCategoryKey.LAST_INSPECTION_INTERVAL] ?? [],
    [appliedFilters]
  );

  const hasAppliedFilters = appliedFiltersCount > 0;
  const areYardsEmpty = yards.length === 0;
  const emptyMessage =
    areYardsEmpty && hasAppliedFilters
      ? t('no_results_simple')
      : areYardsEmpty
      ? t('no_yards_simple')
      : t('not_available_with_slash');

  const ranges = useMemo(() => {
    return Object.values(LastInspectionsConfig.INTERVALS_SPECS).filter(
      (spec) => appliedLastInspectionFilters.length === 0 || appliedLastInspectionFilters.includes(spec.key)
    );
  }, [appliedLastInspectionFilters]);

  const rangeCounts = useMemo(() => {
    const sortedRanges = Sorting.sort(ranges, 'order');
    return sortedRanges.map(({ key, labelKey, color, interval: [from, to] }) => {
      const count = yardsColorSpecs.length ? yardsColorSpecs.filter((spec) => spec.key === key).length : null;
      return {
        key,
        color,
        labelLeft: t(labelKey, { from, to }),
        labelRight: count === null ? emptyMessage : maybePluralize(count, 'yard', t),
        progress: count === null ? null : count / yardsColorSpecs.length,
      };
    });
  }, [ranges, emptyMessage, t, yardsColorSpecs]);

  const isFilterApplied = useCallback(
    (filter: string) => {
      return appliedLastInspectionFilters.includes(filter);
    },
    [appliedLastInspectionFilters]
  );

  const toggleFilter = useCallback(
    (filter: string) => {
      const isAlreadyApplied = isFilterApplied(filter);
      const nextFilters = isAlreadyApplied
        ? appliedLastInspectionFilters.filter((f) => f !== filter)
        : [...appliedLastInspectionFilters, filter];
      dispatch(makePatchAppliedFiltersAction({ [YardsFiltersCategoryKey.LAST_INSPECTION_INTERVAL]: nextFilters }));
    },
    [appliedLastInspectionFilters, dispatch, isFilterApplied]
  );

  return (
    <Box fit column stretch>
      {rangeCounts.map(({ key, color, labelLeft, labelRight, progress }, index) => (
        <StyledProgressCard
          key={key}
          $marginTop={index > 0}
          title={isFilterApplied(key) ? t('click_to_remove_filter') : t('click_to_apply_filter')}
          onClick={() => toggleFilter(key)}
        >
          <Box justifyContent={'space-between'} marginBottomXS>
            <Text typography={'CaptionSmall'}>{labelLeft}</Text>
            <Text typography={'CaptionSmall'}>{labelRight}</Text>
          </Box>
          <ProgressBar color={color as Color} progress={progress ?? 0} />
        </StyledProgressCard>
      ))}
    </Box>
  );
};
