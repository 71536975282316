import { useCallback, useContext, useMemo, useState } from 'react';

import { MapContext } from '@components/common/Map/context';
import { MapContextValue, MapState, MapStateSetter } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';
import { useQueryParamsState } from '@helpers/QueryParams/hooks';
import { useSessionStorageState } from '@helpers/Storage/hooks';

export function useMap(): MapContextValue {
  const map = useContext(MapContext);

  if (!map) {
    throw new Error('Map elements must be rendered inside a <Map />.');
  }

  return map;
}

/** Manages the map state using memory. */
export function useMapState(defaultState: MapState): [MapState, MapStateSetter] {
  const [state, setState] = useState(defaultState);

  const setter = useCallback<MapStateSetter>((valueOrFunction) => {
    setState((current) => {
      let nextState: MapState;

      if (typeof valueOrFunction === 'function') {
        nextState = { ...current, ...valueOrFunction(current) };
      } else {
        nextState = { ...current, ...valueOrFunction };
      }

      if (!MapUtil.areMapStatesEqual(current, nextState)) {
        return { ...current, ...nextState };
      }

      return current;
    });
  }, []);

  return [state, setter];
}

/** Manages the map state using the current URL params. */
export function useMapURLState(
  defaultState: MapState,
  options = {
    name: 'map',
    separator: ',',
    precision: 7,
  }
): [MapState, MapStateSetter] {
  const { name, separator, precision } = options;
  const [params, setQueryParams] = useQueryParamsState();

  const paramsToState = useCallback(
    (params): MapState => {
      const data = params[name] || '';
      const [mapTypeStr, zoomStr, latStr, lngStr] = data.split(separator);

      return {
        mapType: mapTypeStr || defaultState.mapType,
        zoom: parseInt(zoomStr) || defaultState.zoom,
        center: {
          lat: parseFloat(latStr) || defaultState.center.lat,
          lng: parseFloat(lngStr) || defaultState.center.lng,
        },
      };
    },
    [defaultState.center.lat, defaultState.center.lng, defaultState.mapType, defaultState.zoom, name, separator]
  );

  const stateToParams = useCallback(
    (state: MapState) => {
      return [state.mapType, state.zoom, state.center.lat.toFixed(precision), state.center.lng.toFixed(precision)].join(
        separator
      );
    },
    [precision, separator]
  );

  const setter = useCallback<MapStateSetter>(
    (valueOrFunction) => {
      setQueryParams((currentParam) => {
        const currentState = paramsToState(currentParam);
        const nextParam = { [name]: '' };

        if (typeof valueOrFunction === 'function') {
          nextParam[name] = stateToParams({ ...currentState, ...valueOrFunction(currentState) });
        } else {
          nextParam[name] = stateToParams({ ...currentState, ...valueOrFunction });
        }

        if (currentParam[name] !== nextParam[name]) {
          return nextParam;
        }

        return currentParam;
      });
    },
    [name, paramsToState, setQueryParams, stateToParams]
  );

  const state = useMemo<MapState>(() => paramsToState(params), [params, paramsToState]);

  return [state, setter];
}

/** Manages the map state using session storage. */
export function useMapStorageState(defaultState: MapState): [MapState, MapStateSetter] {
  return useSessionStorageState('map-storage-state', defaultState);
}

/**
 * Helper to pick the first child, which is
 * expected to be a map element.
 * */
export function useMapChildren(children: any) {
  const [map, ...otherElements] = useMemo(() => [...(Array.isArray(children) ? children : [children])], [children]);
  return [map, ...otherElements];
}
