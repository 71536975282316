import produce from 'immer';

import * as actionTypes from '../actionTypes';

export const exportReducer = (state = { isFetching: false }, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.EXPORT_START:
        st.isFetching = true;
        break;
      case actionTypes.EXPORT_SUCCESS:
        st.isFetching = false;
        break;
      case actionTypes.EXPORT_FAILURE:
        st.isFetching = false;
        break;
      default:
    }
  });
};
