import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { sumBy } from 'lodash';
import moment from 'moment/moment';

import { Box } from '@components/common/Box';
import { PrimaryButtonWithIcon } from '@components/common/Button';
import { Add } from '@components/common/Icon/presets/Add';
import { Feeding } from '@components/common/Icon/presets/Feeding';
import { HiveDefault } from '@components/common/Icon/presets/HiveDefault';
import { Treatment } from '@components/common/Icon/presets/Treatment';
import { Loading } from '@components/common/Loading';
import { Pagination } from '@components/common/Pagination';
import { createHeader, Table } from '@components/common/Table';
import { TableCellRenderOptions, TableHeaderRenderOptions, TableSortingOptions } from '@components/common/Table/types';
import { useCurrentTabFocus, useTabs } from '@components/common/Tabs/hooks';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipYardAction } from '@components/common/TooltipYardAction';
import { TooltipYardInspection } from '@components/common/TooltipYardInspection';
import { YardStatusChip } from '@components/common/YardStatusChip';
import APP from '@config/constants';
import { getDate } from '@helpers/deprecated/time';
import { useActionCategoriesFetcher } from '@hooks/useActions';
import { useContractDrops, useContractId, useDropsCountTitle } from '@hooks/useContract';
import { useOperation } from '@hooks/useOperation';
import { makeFetchDropsListThunk } from '@redux/Contract/actions';

import {
  GradingCircle,
  StyledTableEmptyCard,
  StyledTableEmptyCardWrapper,
  StyledTableWrapper,
  StyledTitle,
} from './styles';

const SEASON_START_DATEFORMAT = 'MMMM DD, YYYY';

export const PolliDropsTable: React.VFC = () => {
  useActionCategoriesFetcher();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const tabs = useTabs();
  const contractId = useContractId();
  const operation = useOperation();
  const seasonStart = operation ? moment(operation.seasonStart).format(SEASON_START_DATEFORMAT) : null;
  const { contractDropsList, contractDropsListOptions, isFetchingContractDrops } = useContractDrops();
  const { limit, offset, count, orderBy, orderDirection } = contractDropsListOptions;
  const dropsTabTitle = useDropsCountTitle();

  const pageIndex = Math.ceil(offset / limit);
  const sorting = useMemo(
    () =>
      ({
        header: orderBy,
        direction: orderDirection,
      } as TableSortingOptions<BeeDrop>),
    [orderBy, orderDirection]
  );

  const goToDropPage = useCallback(
    (drop: BeeDrop) => {
      history.push(generatePath(APP.routes.yard, { uid: drop.id }));
    },
    [history]
  );

  const goToMapPage = useCallback(() => {
    tabs.goToTab('map');
  }, [tabs]);

  const getSortingToggler = useCallback(
    (header) => {
      if (contractId) {
        const query = { orderDirection, orderBy: header };
        if (header === sorting.header) {
          query.orderDirection = query.orderDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        return () => {
          dispatch(
            makeFetchDropsListThunk(contractId, {
              ...contractDropsListOptions,
              ...query,
            })
          );
        };
      }
    },
    [contractDropsListOptions, contractId, dispatch, orderDirection, sorting.header]
  );

  const headers = useMemo(() => {
    let order = 0;
    return {
      ...createHeader('name', ++order, { title: t('pollination_drop_name') }),
      ...createHeader('nb_hives', ++order, {
        title: t('Hives'),
      }),
      ...createHeader('latest_feedings', ++order, {
        title: t('feeding'),
        sortable: true,
        onClick: getSortingToggler('latest_feedings'),
      }),
      ...createHeader('latest_treatments', ++order, {
        title: t('treatment'),
        sortable: true,
        onClick: getSortingToggler('latest_treatments'),
      }),
      ...createHeader('last_visit', ++order, {
        title: t('last_visit'),
        sortable: true,
        padding: 0,
        onClick: getSortingToggler('last_visit'),
      }),
      ...createHeader('grading', ++order, {
        title: t('grading'),
      }),
      ...createHeader('queenless', ++order, {
        title: t('queenless'),
        sortable: true,
        onClick: getSortingToggler('queenless'),
      }),
      ...createHeader('nb_deadout_season', ++order, {
        title: t('deadout_short'),
        sortable: true,
        onClick: getSortingToggler('nb_deadout_season'),
      }),
      ...createHeader('status', ++order, {
        title: t('status'),
        sortable: true,
        onClick: getSortingToggler('status'),
      }),
    };
  }, [getSortingToggler, t]);

  const onChangePage = useCallback(
    (page) => {
      const offset = contractDropsListOptions.limit * page;
      if (contractId) {
        dispatch(makeFetchDropsListThunk(contractId, { ...contractDropsListOptions, offset }));
      }
    },
    [contractDropsListOptions, contractId, dispatch]
  );

  const renderHeader = useCallback(
    ({ header, headerOptions: { title } }: TableHeaderRenderOptions<BeeDrop>) => {
      const isDeadoutHeader = String(header) === 'nb_deadout_season';
      return (
        <Text typography={'CaptionSmall'} weight={'600'} dashed={isDeadoutHeader}>
          {title}
          {isDeadoutHeader && (
            <Tooltip>
              <Text
                typography={'CaptionSmall'}
                dangerouslySetInnerHTML={{ __html: t('deadout_since', { date: seasonStart }) }}
              />
            </Tooltip>
          )}
        </Text>
      );
    },
    [seasonStart, t]
  );

  const renderCellName = useCallback(
    (drop: BeeDrop) => {
      return (
        <Box column>
          <Text typography={'CaptionSmall'} weight={'600'}>
            {drop.name}
          </Text>
          <Text typography={'CaptionSmall'} color={'grey06'}>
            {t('pollination_drops_hives_count', { count: drop.nbHives })}
          </Text>
        </Box>
      );
    },
    [t]
  );

  const renderCellHives = useCallback((drop: BeeDrop) => {
    return (
      <Box center>
        <Box marginRightXS>
          <HiveDefault size={12} color={'grey08'} />
        </Box>
        <Text typography={'CaptionSmall'}>
          {drop.nbHives} / {drop.targetHiveNumber ?? '-'}
        </Text>
      </Box>
    );
  }, []);

  const renderCellFeeding = useCallback(
    (drop: BeeDrop) => {
      const { latestFeedings } = drop;
      const feedingsCount = sumBy(latestFeedings, (f: any) => f.dates.length);

      return (
        Boolean(feedingsCount) && (
          <Box center>
            <Box marginRightXS>
              <Feeding size={12} />
            </Box>
            <Text typography={'CaptionSmall'}>{feedingsCount || ''}</Text>
            <TooltipYardAction seasonStartBased icon={Feeding} title={t('feeding')} actions={latestFeedings} />
          </Box>
        )
      );
    },
    [t]
  );

  const renderCellTreatment = useCallback(
    (drop: BeeDrop) => {
      const { latestTreatments } = drop;
      const treatmentsCount = sumBy(latestTreatments, (f: any) => f.dates.length);
      return (
        Boolean(treatmentsCount) && (
          <Box center>
            <Box marginRightXS>
              <Treatment size={12} />
            </Box>
            <Text typography={'CaptionSmall'}>{treatmentsCount || ''}</Text>
            <TooltipYardAction seasonStartBased icon={Feeding} title={t('treatment')} actions={latestTreatments} />
          </Box>
        )
      );
    },
    [t]
  );

  const renderCellLastVisit = useCallback((drop: BeeDrop) => {
    const { requiringAttention, lastInspection } = drop;
    const lastInspectionDatetime = lastInspection?.analyzedAt;
    const lastInspectionWorkername = lastInspection?.eventDetails.userName;

    if (!lastInspection) {
      return null;
    }

    return (
      <Box fit column stretch paddingSM backgroundColor={requiringAttention ? 'yellow01' : undefined}>
        <Box marginBottomXXS>
          <Text dashed typography={'CaptionSmall'} weight={'400'} color={requiringAttention ? 'orange03' : undefined}>
            {getDate(lastInspectionDatetime)}
          </Text>
        </Box>
        <Text typography={'CaptionSmall'} color={'grey07'}>
          {lastInspectionWorkername}
        </Text>
        <TooltipYardInspection inspection={lastInspection} placement={'bottom'} />
      </Box>
    );
  }, []);

  const renderCellGrading = useCallback((drop: BeeDrop) => {
    const { nbStrongHives, nbMediumHives, nbWeakHives } = drop;
    return (
      <Box center>
        <GradingCircle center backgroundColor={'green01'} visible={Boolean(nbStrongHives)}>
          <Text typography={'CaptionSmall'}>{nbStrongHives}</Text>
        </GradingCircle>
        <GradingCircle center backgroundColor={'yellow01'} visible={Boolean(nbMediumHives)}>
          <Text typography={'CaptionSmall'}>{nbMediumHives}</Text>
        </GradingCircle>
        <GradingCircle center backgroundColor={'red01'} visible={Boolean(nbWeakHives)}>
          <Text typography={'CaptionSmall'}>{nbWeakHives}</Text>
        </GradingCircle>
      </Box>
    );
  }, []);

  const renderCellQueeless = useCallback((drop: BeeDrop) => {
    const { nbQueenlessHives } = drop;
    return <Text typography={'CaptionSmall'}>{nbQueenlessHives}</Text>;
  }, []);

  const renderCellDeadout = useCallback((drop: BeeDrop) => {
    const { nbDeadoutSeason } = drop;
    return <Text typography={'CaptionSmall'}>{nbDeadoutSeason}</Text>;
  }, []);

  const renderCellStatus = useCallback((drop: BeeDrop) => {
    return <YardStatusChip status={drop.status} />;
  }, []);

  const renderCell = useCallback(
    ({ header, item }: TableCellRenderOptions<BeeDrop>) => {
      switch (header) {
        case 'name':
          return renderCellName(item);
        case 'nb_hives':
          return renderCellHives(item);
        case 'latest_feedings':
          return renderCellFeeding(item);
        case 'latest_treatments':
          return renderCellTreatment(item);
        case 'last_visit':
          return renderCellLastVisit(item);
        case 'grading':
          return renderCellGrading(item);
        case 'queenless':
          return renderCellQueeless(item);
        case 'nb_deadout_season':
          return renderCellDeadout(item);
        case 'status':
          return renderCellStatus(item);
        default:
          return null;
      }
    },
    [
      renderCellDeadout,
      renderCellFeeding,
      renderCellGrading,
      renderCellHives,
      renderCellLastVisit,
      renderCellName,
      renderCellQueeless,
      renderCellStatus,
      renderCellTreatment,
    ]
  );

  useCurrentTabFocus(
    useCallback(() => {
      if (contractId) {
        dispatch(makeFetchDropsListThunk(contractId));
      }
    }, [contractId, dispatch])
  );

  if (!contractDropsList || isFetchingContractDrops) {
    return <Loading />;
  }

  if (!contractDropsList?.length) {
    return (
      <StyledTableEmptyCardWrapper center>
        <StyledTableEmptyCard column center>
          <Text typography={'Heading2'} weight={'700'}>
            {t('pollination_drops_empty')}
          </Text>
          <Box marginTopXXS marginBottomMD>
            <Text
              typography={'SmallParagraph'}
              align={'center'}
              dangerouslySetInnerHTML={{ __html: t('pollination_drops_empty_hint') }}
            />
          </Box>
          <PrimaryButtonWithIcon size={'large'} onClick={goToMapPage}>
            <Add size={24} />
            {t('pollination_drops_empty_create')}
          </PrimaryButtonWithIcon>
        </StyledTableEmptyCard>
      </StyledTableEmptyCardWrapper>
    );
  }

  return (
    <StyledTableWrapper column stretch>
      <StyledTitle typography={'Heading1'}>{dropsTabTitle}</StyledTitle>

      <Box column stretch paper01 shadow={'boxShadow01'}>
        <Table
          items={contractDropsList}
          onItemClick={({ item }) => goToDropPage(item)}
          renderCell={renderCell}
          renderHeader={renderHeader}
          headers={headers}
          sorting={sorting}
        />
      </Box>

      <Box column stretch marginTopSM>
        <Pagination
          pageIndex={pageIndex}
          limit={limit}
          totalItems={count}
          itemLabel={t('pollination_drops').toLowerCase()}
          changePage={onChangePage}
        />
      </Box>
    </StyledTableWrapper>
  );
};
