import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@components/common/Box';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { Notes } from '@components/common/Icon/presets/Notes';
import { StyledVisitCardNavArrow } from '@components/common/NotesNavigator/styles';
import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';
import { useTranslation } from '@hooks/useTranslation';

export const NotesNavigator = <N extends { text: string }>({
  notes,
  titleTextProps,
  bodyTextProps,
  onNoteSelected,
}: {
  notes: Array<N>;
  titleTextProps?: TextProps;
  bodyTextProps?: TextProps;
  onNoteSelected?: (note: N) => void;
}) => {
  const t = useTranslation();

  const notesCount = notes.length ?? 0;
  const hasMultipleNotes = notesCount > 1;

  const [notesPage, setNotesPage] = useState(0);
  const canNavigateToNextNote = notesPage < notesCount - 1;
  const canNavigateToPrevNote = notesPage > 0;

  const navigateNotesForward = useCallback(() => setNotesPage((curr) => curr + 1), []);
  const navigateNotesBackward = useCallback(() => setNotesPage((curr) => curr - 1), []);

  const selectedNote = notes[notesPage];

  useEffect(() => {
    selectedNote && onNoteSelected && onNoteSelected(selectedNote);
  }, [onNoteSelected, selectedNote]);

  if (!selectedNote) {
    return null;
  }

  return (
    <Box fit column stretch>
      <Box fit alignItems={'center'}>
        <Box paddingXS>
          <Notes size={16} />
        </Box>

        <Text typography={'Heading3'} weight={'600'} {...titleTextProps}>
          {t('notes')}
        </Text>

        {hasMultipleNotes && (
          <Box tag={'span'} center marginLeftXS gapXXS>
            <StyledVisitCardNavArrow onClick={navigateNotesBackward} disabled={!canNavigateToPrevNote}>
              <ArrowLeft size={16} />
            </StyledVisitCardNavArrow>
            <Text typography={'Heading3'} color={'grey06'} {...titleTextProps}>
              {notesPage + 1}/{notesCount}
            </Text>
            <StyledVisitCardNavArrow onClick={navigateNotesForward} disabled={!canNavigateToNextNote}>
              <ArrowRight size={16} />
            </StyledVisitCardNavArrow>
          </Box>
        )}
      </Box>

      <Box marginLeftXS marginRightXS marginBottomXS>
        <Text typography={'SmallParagraph'} {...bodyTextProps}>
          {selectedNote.text}
        </Text>
      </Box>
    </Box>
  );
};
