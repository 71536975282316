import { MapApi, MapState, MapType } from '@components/common/Map/types';

export const DEFAULT_MAP_API = MapApi.GOOGLE_MAPS;
export const DEFAULT_MAP_STATE: MapState = {
  mapType: MapType.SATELLITE,
  zoom: 4,
  center: { lat: 0, lng: 0 },
};
export const DEF_MIN_ZOOM = 3;
export const DEF_MAX_ZOOM = 21;
export const DEF_PANE_WIDTH = 288;
