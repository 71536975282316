import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import { PrimaryCTAInverse } from '@style/typeface';

const StyledTagButton = styled.button`
  ${PrimaryCTAInverse};
  width: 112px;
  height: 2.5rem;
  border-radius: 20px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.buttonPrimaryColor};
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline-style: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.primaryBackgroundColor}99;
    box-shadow: ${(props) => props.theme.boxShadow3};
  }
  &:focus {
    color: rgba(255, 255, 255, 0.6);
    box-shadow: ${(props) => props.theme.buttonFocusBoxShadow};
  }
`;

export const TagButton = ({ onClick, label, type = 'button', ...others }) => {
  const copiedProps = {};
  Object.keys(others).forEach((key) => {
    // these are the props that need to get thru:
    if (key.startsWith('data-') || 'style' === key || 'className' === key) {
      copiedProps[key] = others[key];
    }
  });

  return (
    <StyledTagButton onClick={onClick} type={type} {...copiedProps} aria-disabled="true">
      {label}
    </StyledTagButton>
  );
};

TagButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
};
