import { ColDef, ColGroupDef, GridApi, SortDirection } from 'ag-grid-community';
import { produce } from 'immer';
import * as uuid from 'uuid';

import {
  DEF_ABSTRACT_COLUMNS_KEYS,
  DEF_AUTO_RESIZED_COLUMNS,
  DEF_COLUMN_MIN_WIDTH,
  DEF_COLUMN_MIN_WIDTHS,
  DEF_PAGE_PARAM_NAME,
  DEF_PERMANENT_COLUMNS_KEYS,
  DEF_PERMANENT_LEFT_COLUMNS,
  DEF_PERMANENT_RIGHT_COLUMNS,
  DEF_SORTING,
} from '@components/yard/YardsList/constants';
import { ColDefWithMetadata, Column, ColumnType, GridColumnMetadata, View } from '@components/yard/YardsList/types';
import { YardsListHeaderGroup } from '@components/yard/YardsList/YardsListHeaderGroup';
import { QueryParams } from '@helpers/QueryParams';
import { Sorting } from '@helpers/Sorting';

export const GridApiUtil = {
  updateGridColumns,
  getPatchedColumnDefsUsingId,
  getPatchedColumnDefsUsingColumnKey,
  areColumnDefsEqual,
  getFlattenColumnIds,
  getFlattenColumnKeys,
  getFlattenColumnDefs,
  getNonAbstractVisibleColumnDefs,
  getVisibleColumnKeys,
  getDefsWithGroupedColumns,
  getColumnDefsWithMetadata,
  getColumnDefWithMetadata,
  getPageFromURLParams,
  setPageToURLParams,
  getActiveView,
  getDefaultView,
  isColumnDef,
};

/**
 * Sets the given column definitions to the grid in case there
 * is any change.
 * */
function updateGridColumns(gridApi: GridApi, nextColumnDefs: Array<ColDef | ColGroupDef>) {
  const currentColumnDefs = gridApi.getColumnDefs() ?? [];
  const areColumnsEqual = GridApiUtil.areColumnDefsEqual(currentColumnDefs, nextColumnDefs);

  if (areColumnsEqual) {
    return false;
  }

  nextColumnDefs = getDefsWithPermanentColumns(nextColumnDefs);
  nextColumnDefs = getDefsWithDefaultProperties(nextColumnDefs);
  nextColumnDefs = getDefsWithAppliedSorting(nextColumnDefs);
  nextColumnDefs = getDefsWithGroupedColumns(nextColumnDefs);
  nextColumnDefs = getDefsWithoutUnnecessarilyGroupedColumns(nextColumnDefs);
  gridApi.setColumnDefs(nextColumnDefs);

  return true;
}

function getDefsWithPermanentColumns(columnDefs: Array<ColDef>) {
  columnDefs = [...columnDefs];

  const columnDefsKeys = getFlattenColumnKeys(columnDefs) as Array<string | undefined>;
  const isColumnAlreadyAppended = (columnDef: ColDef) => columnDefsKeys.includes(columnDef.field);

  for (const columnDef of DEF_PERMANENT_LEFT_COLUMNS.reverse()) {
    !isColumnAlreadyAppended(columnDef) && columnDefs.unshift(columnDef);
  }

  for (const columnDef of DEF_PERMANENT_RIGHT_COLUMNS.reverse()) {
    !isColumnAlreadyAppended(columnDef) && columnDefs.push(columnDef);
  }

  return columnDefs;
}

function getPatchedColumnDefsUsingId(columnDefs: Array<ColDef | ColGroupDef>, patch: ColDef) {
  return getPatchedColumnDefs(columnDefs, patch, { patchUsingColumnKey: false });
}

function getPatchedColumnDefsUsingColumnKey(columnDefs: Array<ColDef | ColGroupDef>, patch: ColDef) {
  return getPatchedColumnDefs(columnDefs, patch, { patchUsingColumnKey: true });
}

/**
 * Patches a specific column within a list of definitions.
 * */
function getPatchedColumnDefs(
  columnDefs: Array<ColDef | ColGroupDef>,
  patch: ColDef,
  options = { patchUsingColumnKey: false }
) {
  columnDefs = getColumnDefsCopy(columnDefs);

  const flattenedColumnDefs = getColumnDefsWithMetadata(GridApiUtil.getFlattenColumnDefs(columnDefs));
  const patchWithMetadata = getColumnDefWithMetadata(patch);

  const columnDefsToPatch: any = options.patchUsingColumnKey
    ? flattenedColumnDefs.filter((def) => def.field === patchWithMetadata.field)
    : flattenedColumnDefs.filter((def) => def.colId === patchWithMetadata.colId);

  if (columnDefsToPatch.length) {
    for (const columnDefToPatch of columnDefsToPatch) {
      const nonPatchableKeys = ['colId'];
      Object.entries(patch).forEach(([key, value]) => {
        if (!nonPatchableKeys.includes(key)) {
          if (key === 'metadata') {
            columnDefToPatch[key] = { ...columnDefToPatch[key], ...value };
          } else {
            columnDefToPatch[key] = value;
          }
        }
      });
    }
  } else {
    flattenedColumnDefs.push(getColumnDefWithMetadata(patch));
  }

  return flattenedColumnDefs;
}

/**
 * Checks if the given column definitions are equal. Useful to
 * avoid unnecessary changes.
 * */
function areColumnDefsEqual(columnDefs1: Array<ColDef | ColGroupDef>, columnDefs2: Array<ColDef | ColGroupDef>) {
  return getColumnDefsHash(columnDefs1) === getColumnDefsHash(columnDefs2);
}

function getColumnDefsHash(columnDefs: Array<ColDef | ColGroupDef>) {
  const defsWithMetadata = getFlattenColumnDefs(columnDefs) as Array<ColDefWithMetadata>;
  const prefix = `columns-${defsWithMetadata.length}`;
  const hash = defsWithMetadata
    .map((def, index) => {
      const includeWidth = !isColumnAutoResized(def.field);
      const hashValues = [
        index,
        def.field,
        Boolean(def.hide),
        def.sort ?? null,
        def.metadata?.groupId,
        def.metadata?.activeView,
      ];

      if (includeWidth) {
        hashValues.push(def.width, def.maxWidth, def.minWidth);
      }

      return hashValues.join('-');
    })
    .sort(Sorting.defaultSortFunc)
    .join('\n');
  return `${prefix}-${hash}`;
}

function isColumnAutoResized(columnKey?: string): boolean {
  return DEF_AUTO_RESIZED_COLUMNS.some((autoResizedColumnKey) => {
    if (autoResizedColumnKey.endsWith('*')) {
      return columnKey?.includes(autoResizedColumnKey.slice(0, -1));
    }
    return autoResizedColumnKey === columnKey;
  });
}

/**
 * Flattens a possibly nested list of column group definitions into
 * a list of column ids.
 * */
function getFlattenColumnIds(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined) {
  return getFlattenColumnDefs(columnsOrGroupDefs).map((def) => def.colId ?? '');
}

/**
 * Flattens a possibly nested list of column group definitions into
 * a list of column keys.
 * */
function getFlattenColumnKeys(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined) {
  return getFlattenColumnDefs(columnsOrGroupDefs).map((def) => def.field ?? '');
}

/**
 * Flattens a possibly nested list of column group definitions into
 * a list of column only definitions.
 * */
function getFlattenColumnDefs(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<ColDef> {
  const recursivelyGetColumnDefs = (defs: Array<ColDef | ColGroupDef>): Array<ColDef> => {
    return defs.flatMap((def) => (isColumnDef(def) ? def : recursivelyGetColumnDefs(def.children)));
  };
  return getColumnDefsCopy(recursivelyGetColumnDefs(columnsOrGroupDefs ?? []));
}

function getNonAbstractVisibleColumnDefs(
  columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined
): Array<ColDef> {
  return getVisibleColumnDefs(columnsOrGroupDefs).filter((def) => !DEF_ABSTRACT_COLUMNS_KEYS.includes(def.field));
}

function getVisibleColumnDefs(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<ColDef> {
  return getFlattenColumnDefs(columnsOrGroupDefs).filter(({ hide }) => !hide);
}

function getVisibleColumnKeys(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<string> {
  return getVisibleColumnDefs(columnsOrGroupDefs).map(({ field }) => field as string);
}

/**
 * Groups a list of column definitions based on its metadata.
 * */
function getDefsWithGroupedColumns(columnDefs: Array<ColDef>) {
  const columnDefsWithMetadata = columnDefs as Array<ColDefWithMetadata>;
  const nextColumnDefs: Array<ColGroupDef> = [];

  for (const columnDef of columnDefsWithMetadata) {
    const { groupId, groupName } = columnDef.metadata;
    let columnGroupDef = nextColumnDefs.find((def) => def.groupId === groupId);

    if (!columnGroupDef) {
      columnGroupDef = {
        groupId,
        headerName: groupName,
        headerGroupComponent: YardsListHeaderGroup,
        children: [],
      };
      nextColumnDefs.push(columnGroupDef);
    }

    columnGroupDef.children.push(columnDef);
  }

  return nextColumnDefs;
}

/**
 * Cleans up the given defs by transforming groups with less
 * than two visible child into a simple column.
 * */
function getDefsWithoutUnnecessarilyGroupedColumns(columnDefs: Array<ColDef | ColGroupDef>) {
  return columnDefs.flatMap((def) => {
    if (isColumnDef(def)) {
      return def;
    }

    const visibleChildren = getFlattenColumnDefs(def.children).filter((def) => !def.hide);
    const column = getColumnDefWithMetadata(def.children[0])?.metadata?.column ?? null;

    if (visibleChildren.length == 0 || (visibleChildren.length == 1 && column?.type === ColumnType.DEFAULT)) {
      return def.children;
    }

    return def;
  });
}

/**
 * Helper to convert the columns type.
 * */
function getColumnDefsWithMetadata(columnDefs: Array<ColDef>) {
  return columnDefs as Array<ColDefWithMetadata>;
}

/**
 * Helper to convert the column type.
 * */
function getColumnDefWithMetadata(columnDef: ColDef) {
  return columnDef as ColDefWithMetadata;
}

function getColumnDefsCopy(columnDefs: Array<ColDef | ColGroupDef>) {
  return produce(columnDefs, (_) => _).map((def) => ({ ...def }));
}

function getPageFromURLParams(): number {
  const rawPageFromURLParams = QueryParams.getCurrentQueryParams()[DEF_PAGE_PARAM_NAME] ?? 0;
  return Math.max(urlPageToGrid(rawPageFromURLParams), 0);
}

function setPageToURLParams(page: number): void {
  const params = { ...QueryParams.getCurrentQueryParams(), [DEF_PAGE_PARAM_NAME]: gridToUrlPage(page) };
  QueryParams.setCurrentQueryParams(params);
}

function urlPageToGrid(page: string): number {
  return parseInt(page) - 1;
}

function gridToUrlPage(page: number): string {
  return page === 0 ? '' : String(page + 1);
}

function getDefaultView(column: Column): View | null {
  const views = Object.values(column.views ?? {});
  return views.find((view: View) => view.isDefault) ?? views[0] ?? null;
}

function getActiveView(columnDef: ColDef): View | null {
  const { metadata } = getColumnDefWithMetadata(columnDef);
  const views = metadata?.column?.views ?? {};
  const activeView = metadata?.activeView;
  const defaultView = metadata?.column ? getDefaultView(metadata.column) : null;

  if (activeView) {
    return views[activeView] ?? defaultView;
  }

  return defaultView;
}

/**
 * Adds missing column properties.
 * Normally these props will be missing if the given columns are
 * default, or if the data comes corrupted from API.
 * */
function getDefsWithDefaultProperties(columnDefs: Array<ColDef | ColGroupDef>) {
  return getColumnDefsWithMetadata(getFlattenColumnDefs(columnDefs)).map(({ ...def }) => {
    if (!def.colId) {
      const isPermanentColumn = DEF_PERMANENT_COLUMNS_KEYS.includes(def.field);
      def.colId = isPermanentColumn ? def.field : uuid.v4();
    }

    if (typeof def.minWidth === 'number') {
      const defMinWidth = DEF_COLUMN_MIN_WIDTHS[def.field as string] ?? DEF_COLUMN_MIN_WIDTH;
      def.minWidth = Math.max(defMinWidth, def.minWidth ?? 0);
    }

    if (!def.metadata) {
      def.metadata = {} as GridColumnMetadata;
    }

    if (!def.metadata.groupId) {
      def.metadata = {
        ...def.metadata,
        groupId: def.field as string,
      };
    }

    return def;
  });
}

/**
 * Normalizes the current sorting columns by:
 * - If the column currently being sorted becomes hidden, the sorting
 *   fallbacks to a default configured sorting column;
 * - Default configured sorting columns may contain also hidden columns.
 *   In that case, the first non-hidden available column is used.
 * */
function getDefsWithAppliedSorting(columnDefs: Array<ColDef | ColGroupDef>) {
  const flattenColumnDefs = getFlattenColumnDefs(columnDefs);
  const sortingColumns = flattenColumnDefs.filter((def) => Boolean(def.sort));
  const visibleSortingColumns = sortingColumns.filter((def) => !def.hide);

  if (visibleSortingColumns.length === 0) {
    for (const { field, sort } of DEF_SORTING) {
      const currentColumnDef = flattenColumnDefs.find((def) => field == def.field);
      if (currentColumnDef && !currentColumnDef.hide) {
        visibleSortingColumns.push({ ...currentColumnDef, sort });
        break;
      }
    }
  }

  const sortingByColumn = visibleSortingColumns.reduce(
    (acc, def) => ({ ...acc, [def.colId as string]: def.sort ?? null }),
    {} as Record<string, SortDirection>
  );

  return flattenColumnDefs.map((def) => ({ ...def, sort: sortingByColumn[def.colId as string] ?? null }));
}

function isColumnDef(columnDef: any): columnDef is ColDef {
  return typeof columnDef.field === 'string';
}
