import produce from 'immer';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isAuthenticated: true,
};

export const authReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.API_UNAUTHORIZED_REQUEST_FAILURE:
        st.isAuthenticated = false;
        break;
      default:
        break;
    }
  });
};
