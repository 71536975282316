const SVG_CACHE = {} as Record<any, string>;

export const SVG = {
  generateCachedSvgUri,
  generateCachedSvg,
  toDataURI,
};

function generateCachedSvgUri(key: any, codeGetter: () => string): string {
  const svgCode = generateCachedSvg(key, codeGetter);
  return toDataURI(svgCode);
}

function generateCachedSvg(key: any, codeGetter: () => string): string {
  if (!SVG_CACHE[key]) {
    SVG_CACHE[key] = codeGetter();
  }
  return SVG_CACHE[key];
}

function toDataURI(svgCode: string): string {
  return `data:image/svg+xml;base64,${btoa(svgCode)}`;
}
