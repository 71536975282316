import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  vertical-align: middle;
  margin-left: ${(props) => !props.isMobile && '1.5rem'};
  margin-top: ${(props) => props.isMobile && '1.5rem'};
`;

export const StyledText = styled.span`
  line-height: 1.5rem;
  margin-left: 11px;
`;
