import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@components/common/CTA';
import { Add } from '@components/common/Icon/presets/Add';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { YardsFiltersTrigger } from '@components/yard/YardsFilters';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';

export const WhiteboardHeader: React.VFC = () => {
  const t = useTranslation();
  const history = useHistory();

  const goToYardManagement = useCallback(() => {
    // TODO: Clarify with design team.
    history.push(APP.routes.yardsMapManagement);
  }, [history]);

  return (
    <PageHeader>
      <PageHeaderTitle>{t('whiteboard')}</PageHeaderTitle>
      <Button secondary withIconLeft onClick={goToYardManagement}>
        <Add />
        {t('create_new_yard')}
      </Button>
      <YardsFiltersTrigger />
    </PageHeader>
  );
};
