import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const StyledTextLimit = styled.div`
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StyledIconWrapper = styled.span(
  ({ theme }) => `
    margin-right: ${theme.spacing.xs}px;
  `
);

export const StyledTableHeader = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey03};
`;

export const StyledHiveCount = styled(Text)<{ $warned: boolean }>(
  ({ theme, $warned }) => `
  vertical-align: middle;
  padding: ${$warned ? theme.spacing.xxs : 0}px;
  border-radius: ${theme.shape.paperRadius01}px;
  background-color: ${$warned ? theme.colors.yellow01 : 'transparent'};
`
);
