import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Shows a confirmation alert to the user
 * when exiting the current page.
 *
 * Note: in order for this feature to work, the
 * user must interact with some UI element before
 * attempting to exit the page. More info here:
 * - https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#security
 * */
export function useAskBeforeExitAlert(options: { message: string; disabled?: boolean; onExit?: () => void }): void {
  const history = useHistory();

  useEffect(() => {
    if (!options.disabled) {
      // Handles the refresh and normal browser navigation
      const browserHandler = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        return (e.returnValue = options.message);
      };
      window.addEventListener('beforeunload', browserHandler, { capture: true });

      // Handles react router navigation
      const unblock = history.block(() => {
        if (!window.confirm(options.message)) {
          return false;
        }
        options.onExit && options.onExit();
      });

      return () => {
        window.removeEventListener('beforeunload', browserHandler, { capture: true });
        unblock();
      };
    }
  }, [history, options.disabled, options.message]);
}
