import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import boxConditionalIcon from '@assets/Small_Box-disable.svg';
// icons
import radioCheckedIcon from '@assets/Small_Radio-checked.svg';
import radioUncheckedIcon from '@assets/Small_Radio-unchecked.svg';
import boxCheckedIcon from '@assets/SmallBoxChecked.svg';
import boxUncheckedIcon from '@assets/SmallBoxUnchecked.svg';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
// nectar
import theme from '@style/theme';

const StyledIcon = styled.img`
  filter: ${(props) => props.filter};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:focus {
    outline: none;
  }
`;

export const Select = ({
  type,
  id,
  altActive,
  altInactive,
  disabled,
  conditional,
  src,
  hover,
  tooltip,
  tooltipText,
}) => {
  const getFilter = () => {
    switch (true) {
      case disabled:
      case conditional:
        return theme.iconDisabledFilter;
      case hover:
        return theme.hoverColorFilter;
      case src:
        return theme.primaryColorFilter;
      default:
        return theme.iconUncheckedFilter;
    }
  };

  const getSrc = () => {
    if ('radio' === type) {
      if (src) return radioCheckedIcon;
      else return radioUncheckedIcon;
    }
    if ('checkbox' === type) {
      if (conditional) return boxConditionalIcon;
      else if (src) return boxCheckedIcon;
      else return boxUncheckedIcon;
    }
  };
  return (
    <>
      <StyledIcon
        tabIndex={-1}
        type={type}
        id={id}
        checked={src}
        disabled={disabled}
        hover={hover}
        src={getSrc()}
        alt={src ? altActive : altInactive}
        filter={getFilter()}
        data-for={id}
        data-tip
      />

      {!!tooltip && <Tooltip id={id} disable={!tooltip} tooltipText={tooltipText} />}
    </>
  );
};

Select.defaultProps = {
  type: '',
  src: '',
  filter: '',
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  conditional: PropTypes.bool,
  type: PropTypes.string.isRequired,
  altActive: PropTypes.string.isRequired,
  altInactive: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  hover: PropTypes.bool.isRequired,
  tooltip: PropTypes.bool.isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
