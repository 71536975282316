import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { now } from '@helpers/deprecated/time';

import * as types from '../actionTypes';

import { makeUnauthorizedFetchFailureAction, updateSpanishTranslationsRequestSuccessAction } from './index';
import { makeCloseBeekPracticesModalAction } from './modalActionCreator';
import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeActionRequestStartAction = () => ({
  type: types.ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeFetchActionRequestSuccessAction = (actionList) => ({
  type: types.FETCH_ACTION_SUCCESS,
  actionList,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeAddActionRequestSuccessAction = (action) => ({
  type: types.ADD_ACTION_SUCCESS,
  action,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeUpdateActionRequestSuccessAction = (action) => ({
  type: types.UPDATE_ACTION_SUCCESS,
  action,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeActionRequestFailureAction = () => ({
  type: types.ACTION_FAILURE,
});

/**
 *
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * @param actionName
 * @param actionPhone
 * @returns {Function}
 */

export const makeFetchActionRequestThunk = () => {
  return (dispatch, getState) => {
    const fetchState = getState().actionReducer;
    const { isFetching, fetchedAt } = fetchState;

    if (isFetching || now - fetchedAt < APP.defaultCacheForSeconds * 1000) return;

    dispatch(makeActionRequestStartAction());

    fetch(ENDPOINTS.actionList, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeActionRequestFailureAction());
            dispatch(makeShowSnackbarAction(jsonObj.error, { status: jsonObj.status }));
            return;
          }
        } else if ('actionList' in jsonObj) {
          dispatch(makeFetchActionRequestSuccessAction(jsonObj.actionList));
        }
      });
  };
};

export const makeAddActionRequestThunk = (bodyObj) => {
  const jsonData = JSON.stringify(bodyObj);

  return (dispatch) => {
    // show the spinning screen:
    dispatch(makeActionRequestStartAction());

    fetch(ENDPOINTS.action, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_default_msg'));
          return;
        } else if ('errors' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeAddActionRequestSuccessAction(jsonObj));
          dispatch(updateSpanishTranslationsRequestSuccessAction(jsonObj));
          dispatch(makeShowSnackbarAction('action_added', { icon: true }));
        }
      });
  };
};

export const makeUpdateActionRequestThunk = (id, detail) => {
  const jsonData = JSON.stringify(detail);

  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeActionRequestStartAction());

    fetch(`${ENDPOINTS.action}/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_default_msg'));
          return;
        } else if ('errors' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeCloseBeekPracticesModalAction());
          dispatch(makeUpdateActionRequestSuccessAction(jsonObj));
          dispatch(updateSpanishTranslationsRequestSuccessAction(jsonObj));
          dispatch(makeShowSnackbarAction('action_updated', { icon: true }));
        }
      });
  };
};

export const makeEnableDisableActionRequestThunk = (id, bodyObj) => {
  const jsonData = JSON.stringify(bodyObj);

  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeActionRequestStartAction());

    fetch(`${ENDPOINTS.action}/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_default_msg'));
          return;
        } else if ('errors' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeCloseBeekPracticesModalAction());
          dispatch(makeUpdateActionRequestSuccessAction(jsonObj));
          const { is_active } = bodyObj;
          if (is_active) {
            dispatch(makeShowSnackbarAction('action_activated', { icon: true }));
          } else dispatch(makeShowSnackbarAction('action_deactivated', { icon: true }));
        }
      });
  };
};
