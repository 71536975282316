import React from 'react';
import { useTranslation } from 'react-i18next';

import { Edit } from '@components/common/Icon/presets/Edit';
import { PollinationAdd } from '@components/common/Icon/presets/PollinationAdd';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';

export const PolliMapPaneBlocksEmpty: React.VFC = () => {
  const { t } = useTranslation();
  const { isManaging, blocksList } = usePolliMap();

  const isEmpty = blocksList.length === 0;
  const blocksHintText1 = t(isManaging ? 'pollination_blocks_management_hint_part1' : 'pollination_blocks_hint_part1');
  const blocksHintText2 = t(isManaging ? 'pollination_blocks_management_hint_part2' : 'pollination_blocks_hint_part2');
  const BlocksHintIcon = isManaging ? PollinationAdd : Edit;
  const hintIconProps = isManaging ? { size: 24 } : { size: 16 };

  if (!isEmpty) {
    return null;
  }

  return (
    <Text typography={'SmallParagraph'}>
      <span dangerouslySetInnerHTML={{ __html: blocksHintText1 }} />
      <BlocksHintIcon {...hintIconProps} color={'grey08'} />
      <span dangerouslySetInnerHTML={{ __html: blocksHintText2 }} />
    </Text>
  );
};
