import styled, { css } from 'styled-components';

export const StyledLabel = styled.label<{ isRequired?: boolean }>(
  ({ theme, isRequired }) => `
        line-height: 16px;
        font-size: 0.75rem;
        color: ${theme.colors.grey07};
        
        // :has is a magic selector that lets you target next siblings
        &:has(+ div > input:disabled) {
            color: ${theme.colors.grey06};
          }


        &::after {
          ${isRequired && 'content: "*"'}
        }
      `
);

export const InputTextStyles = css(
  ({ theme }) => `
      color: ${theme.colors.grey08};
      line-height: 22px;
      font-size: 1rem;
      font-weight: 600;
      font-family: inherit;
    `
);
export const InputStyles = css(
  ({ theme }) => `
      border: none;
      outline: none;
      width: 100%;
      padding-left: 0;
  
      :disabled, ::placeholder {
        color: ${theme.colors.grey06};
      }
    `
);

export const StyledDollarSign = styled.span`
  ${InputTextStyles}
`;

export const StyledInput = styled.input`
  ${InputTextStyles}
  ${InputStyles}
`;

export const StyledTextArea = styled.textarea`
  ${InputTextStyles}
  ${InputStyles}
`;

export const StyledWrapper = styled.div<{ hasError: boolean; disabled?: boolean }>(
  ({ theme, hasError, disabled }) => `
        background-color: ${disabled ? theme.colors.grey02 : theme.colors.coreWhite};
        border: 1px solid ${disabled ? theme.colors.grey05 : theme.colors.grey04};
        padding: 10px ${theme.spacing.sm}px;
        ${hasError && `border-color: ${theme.colors.red02};`}
       
        &:focus,
        &:focus-within {
          box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02}; 
          border-color: transparent;
          outline: none;
        }
      `
);

export const StyledErrorMessage = styled.p(
  ({ theme }) => `
      margin-top: ${theme.spacing.xxs}px;
      font-size: 12px;
      line-height: 1rem;
      color: ${theme.colors.red02};
      
    `
);
