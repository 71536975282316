/**
 * Map layer to render yards, yard geometries and hives.
 * */

import React from 'react';

import { MarkerOptimizer } from '@components/common/Map/components/MarkerOptimizer';
import { YardHivesRenderer } from '@components/yard/YardsMapLayers/elements/YardHivesRenderer';
import { YardMarkerRenderer } from '@components/yard/YardsMapLayers/elements/YardMarkerRenderer';
import { YardPolygonRenderer } from '@components/yard/YardsMapLayers/elements/YardPolygonRenderer';
import {
  useYardDescriptors,
  useYardElementsDescriptors,
  useYardElementsVisibilityFilter,
} from '@components/yard/YardsMapLayers/hooks';

const MIN_ZOOM_TO_SHOW_YARD_ELEMENTS = 14;
const MAX_ZOOM_TO_CLUSTER = 13;

export interface YardsMapDefaultLayerProps {
  yards: Array<BeeYardOnMapInfo>;
  isYardSelected?: (yard: BeeYardOnMapInfo) => boolean;
  onYardPinClick?: (yard: BeeYardOnMapInfo) => void;
}

export const YardsMapDefaultLayer: React.VFC<YardsMapDefaultLayerProps> = ({
  yards,
  isYardSelected,
  onYardPinClick,
}) => {
  const yardDescriptors = useYardDescriptors(yards);
  const yardElementsDescriptors = useYardElementsDescriptors(yards);
  const visibleMarkersFilter = useYardElementsVisibilityFilter();

  return (
    <>
      <MarkerOptimizer
        enableSmartBounds
        markers={yardElementsDescriptors}
        markerRenderer={(props) => (
          <>
            <YardPolygonRenderer {...props} />
            <YardHivesRenderer {...props} />
          </>
        )}
        visibleMarkersFilter={visibleMarkersFilter}
        minZoomToRender={MIN_ZOOM_TO_SHOW_YARD_ELEMENTS}
      />
      <MarkerOptimizer
        enableClustering
        markers={yardDescriptors}
        markerRenderer={(props) => (
          <YardMarkerRenderer isYardSelected={isYardSelected} onYardPinClick={onYardPinClick} {...props} />
        )}
        maxZoomToCluster={MAX_ZOOM_TO_CLUSTER}
      />
    </>
  );
};
