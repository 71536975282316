import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ArrowsVertical = ({ color, ...props }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 6 13" {...props}>
      <path
        d="M2.7.3.075 3.8a.375.375 0 0 0 .3.6h5.25a.375.375 0 0 0 .3-.6L3.3.3a.375.375 0 0 0-.6 0ZM3.3 12.5 5.925 9a.375.375 0 0 0-.3-.6H.375a.375.375 0 0 0-.3.6L2.7 12.5a.375.375 0 0 0 .6 0Z"
        fill={useColor(color)}
      />
    </IconSVGView>
  );
};
