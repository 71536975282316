import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionContent, AccordionHeader } from '@components/common/Accordion';
import { PolliMapPaneBlockForm } from '@components/pollination/PolliMapPaneForms/PolliMapPaneBlockForm';
import { PolliMapPaneDropForm } from '@components/pollination/PolliMapPaneForms/PolliMapPaneDropForm';
import { PolliMapPanePoiForm } from '@components/pollination/PolliMapPaneForms/PolliMapPanePoiForm';
import { PolliMapPaneBlock } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneBlock';
import { PolliMapPaneBlocks } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneBlocks';
import { PolliMapPaneBlocksEmpty } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneBlocksEmpty';
import { PolliMapPaneDrops } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneDrops';
import { PolliMapPaneDropsEmpty } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneDropsEmpty';
import { PolliMapPaneDropsProgress } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneDropsProgress';
import { PolliMapPanePoi } from '@components/pollination/PolliMapPaneSummary/PolliMapPanePoi';
import { PolliMapPanePois } from '@components/pollination/PolliMapPaneSummary/PolliMapPanePois';
import { PolliMapPanePoisEmpty } from '@components/pollination/PolliMapPaneSummary/PolliMapPanePoisEmpty';
import { PaneContent } from '@components/pollination/PolliMapPaneSummary/styles';

import { usePolliMap } from '../PolliMap/hooks';

import { PolliMapPaneDrop } from './PolliMapPaneDrop';

export const PolliMapPaneSummary: React.VFC = () => {
  const { t } = useTranslation();
  const { selectedDrop, selectedBlock, selectedPoi, dropsList, blocksList, poisList } = usePolliMap();

  const hasSelectedElement = Boolean(selectedDrop || selectedBlock || selectedPoi);

  const getTitle = useCallback(
    (translation_key, count) => {
      const title = t(translation_key);
      return count ? `${count} ${title}` : title;
    },
    [t]
  );

  return (
    <>
      <PaneContent visible={!hasSelectedElement} scrollable>
        <Accordion initiallyExpanded>
          <AccordionHeader>{getTitle('pollination_blocks', blocksList.length)}</AccordionHeader>
          <AccordionContent>
            <PolliMapPaneBlocksEmpty />
            <PolliMapPaneBlocks />
          </AccordionContent>
        </Accordion>

        <Accordion initiallyExpanded>
          <AccordionHeader>{getTitle('pollination_drops', dropsList.length)}</AccordionHeader>
          <AccordionContent>
            <PolliMapPaneDropsProgress />
            <PolliMapPaneDropsEmpty />
            <PolliMapPaneDrops />
          </AccordionContent>
        </Accordion>

        <Accordion initiallyExpanded>
          <AccordionHeader>{getTitle('pollination_pois', poisList.length)}</AccordionHeader>
          <AccordionContent>
            <PolliMapPanePoisEmpty />
            <PolliMapPanePois />
          </AccordionContent>
        </Accordion>
      </PaneContent>
      <PaneContent visible={!!selectedDrop}>
        <PolliMapPaneDrop />
        <PolliMapPaneDropForm />
      </PaneContent>
      <PaneContent visible={!!selectedBlock}>
        <PolliMapPaneBlock />
        <PolliMapPaneBlockForm />
      </PaneContent>
      <PaneContent visible={!!selectedPoi}>
        <PolliMapPanePoi />
        <PolliMapPanePoiForm />
      </PaneContent>
    </>
  );
};
