import { useCallback } from 'react';

import { LastInspectionIntervalSpec } from '@config/LastInspectionConfig/types';

import { LastInspectionsConfig } from '.';

export function useLastInspectionSpecGetter() {
  return useCallback(({ lastInspectionDatetime }: { lastInspectionDatetime: string | null }) => {
    let daysSinceLastInspection = Infinity;

    if (lastInspectionDatetime) {
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const todayInMilliSeconds = +new Date();
      const lastInspectionInMilliseconds = +new Date(lastInspectionDatetime);
      daysSinceLastInspection = Math.round((todayInMilliSeconds - lastInspectionInMilliseconds) / oneDayInMilliseconds);
    }

    const spec = Object.values(LastInspectionsConfig.INTERVALS_SPECS).find(
      (spec) => daysSinceLastInspection >= spec.interval[0] && daysSinceLastInspection <= spec.interval[1]
    ) as LastInspectionIntervalSpec;

    return {
      ...spec,
      daysSinceLastInspection,
    };
  }, []);
}
