import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColDef } from 'ag-grid-community';

import { Loading } from '@components/common/Loading';
import { DEF_VISIBLE_COLUMNS } from '@components/yard/YardsList/constants';
import { YardsListSaveViewContext } from '@components/yard/YardsList/context/context';
import { useGridApi, useYardsListDataCycle } from '@components/yard/YardsList/hooks';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useOperation } from '@hooks/useOperation';
import { makePatchOperationThunk } from '@redux/Operation/actions';

export const YardsListSaveViewProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const operation = useOperation();
  const { gridApi } = useGridApi();

  const [savedColumnsDefs, setSavedColumnsDefs] = useState<Array<ColDef> | null>(null);
  const [currentColumnsDefs, setCurrentColumnsDefs] = useState<Array<ColDef> | null>(null);

  const hasUnsavedViewChanges = useMemo(() => {
    if (currentColumnsDefs && savedColumnsDefs) {
      return !GridApiUtil.areColumnDefsEqual(savedColumnsDefs, currentColumnsDefs);
    }
    return false;
  }, [currentColumnsDefs, savedColumnsDefs]);

  const saveCurrentViewToAPI = useCallback(() => {
    const whiteboardState = { columnsDefs: GridApiUtil.getFlattenColumnDefs(gridApi?.getColumnDefs()) };
    dispatch(makePatchOperationThunk({ preferences: { whiteboardState } }, 'changes_have_been_saved'));
    setSavedColumnsDefs(whiteboardState.columnsDefs);
  }, [dispatch, gridApi]);

  const loadInitialView = useCallback(() => {
    if (!gridApi) {
      return;
    }

    const restoredColumnsFromSaveView = operation?.preferences?.whiteboardState?.columnsDefs;
    const columnsToLoad = (restoredColumnsFromSaveView || DEF_VISIBLE_COLUMNS).map(({ hide, ...props }: ColDef) => ({
      hide: !!hide,
      ...props,
    }));
    GridApiUtil.updateGridColumns(gridApi, columnsToLoad);
    gridApi.refreshInfiniteCache();
  }, [gridApi, operation?.preferences?.whiteboardState?.columnsDefs]);

  useYardsListDataCycle(gridApi, {
    onDataServerLoad: useCallback(() => {
      setSavedColumnsDefs(gridApi?.getColumnDefs() ?? []);
    }, [gridApi]),
  });

  /**
   * Effect responsible for restoring the saved view in
   * the first whiteboard load.
   * */
  useEffect(() => {
    loadInitialView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  /**
   * Binding grid events which trigger the save view button.
   * */
  useEffect(() => {
    const eventsToWatch = ['columnResized', 'displayedColumnsChanged'];
    const handler = () => {
      setCurrentColumnsDefs(gridApi?.getColumnDefs() ?? []);
    };
    eventsToWatch.forEach((ev) => gridApi?.addEventListener(ev, handler));
    return () => eventsToWatch.forEach((ev) => gridApi?.removeEventListener(ev, handler));
  }, [gridApi]);

  const context = useMemo(
    () => ({
      hasPendingChange: hasUnsavedViewChanges,
      patchViewState: saveCurrentViewToAPI,
      discardViewState: loadInitialView,
    }),
    [hasUnsavedViewChanges, loadInitialView, saveCurrentViewToAPI]
  );

  if (!operation) {
    return <Loading />;
  }

  return <YardsListSaveViewContext.Provider value={context}>{children}</YardsListSaveViewContext.Provider>;
};
