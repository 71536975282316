import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common/Text';
import { InputText } from '@components/form/InputText';
import { InputTextV2 } from '@components/form/InputTextV2';
import { useValidator } from '@components/form/InputTextV2/hooks';
import { Validators } from '@components/form/InputTextV2/validators';

import { StyledForm, StyledInputWrapper } from './styles';
import { GrowerInfoFormSectionProps } from './types';

const PHONE_REGEX = '^\\+(\\d{1,3})(\\s*\\d{2})?(\\s*\\d{3})(\\s*\\d{3})(\\s*\\d{3,4})$';

export const FormSectionGrowerInfo: React.FC<GrowerInfoFormSectionProps> = ({
  isActive,
  section,
  activeSection,
  handleFormSectionSubmit,
  formErrors,
  defaultState,
}) => {
  const { t } = useTranslation();
  const [localFormState, setLocalFormState] = useState<BeeGrower>(defaultState);
  const firstInput = useRef<HTMLInputElement>(null);
  const emailValidators = [useValidator(Validators.email)];
  const handleFormFieldChange = (value: string | number | number[] | Date | null | undefined, name: string) => {
    setLocalFormState((oldState) => ({ ...oldState, [name]: value }));
  };

  const onTransitionEnd: React.TransitionEventHandler<HTMLFormElement> = () => {
    isActive && firstInput && firstInput.current && firstInput.current.focus();
  };

  return (
    <StyledForm
      onTransitionEnd={onTransitionEnd}
      isActive={isActive}
      activeSection={activeSection}
      id={`contractForm-section-${section}`}
      onSubmit={(e) => handleFormSectionSubmit(e, { grower: localFormState })}
    >
      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('pollination_grower_info')}
        </Text>

        <StyledInputWrapper>
          <InputText
            disabled={!isActive}
            inputRef={firstInput}
            required
            label={t('pollination_grower_name')}
            id="contractForm-name"
            placeholder={t('pollination_grower_name_placeholder')}
            name="name"
            value={localFormState['name']}
            onTextChange={handleFormFieldChange}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputText
            disabled={!isActive}
            required
            label={t('pollination_contact_name')}
            id="contractForm-contactName"
            placeholder={t('pollination_contact_name_placeholder')}
            name="contactName"
            value={localFormState['contactName']}
            onTextChange={handleFormFieldChange}
          />
        </StyledInputWrapper>

        <StyledInputWrapper>
          <InputText
            disabled={!isActive}
            type="tel"
            pattern={PHONE_REGEX}
            label={t('phone_int')}
            id="contractForm-contactPhoneNumber"
            placeholder={t('phone_int_placeholder')}
            name="contactPhoneNumber"
            value={localFormState['contactPhoneNumber']}
            error={formErrors.growerContactPhoneNumber}
            onTextChange={handleFormFieldChange}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputTextV2
            disabled={!isActive}
            inputMode="email"
            label={t('email')}
            id="contractForm-contactEmail"
            placeholder={t('email_placeholder')}
            name="contactEmail"
            value={localFormState['contactEmail']}
            onTextChange={handleFormFieldChange}
            validators={emailValidators}
          />
        </StyledInputWrapper>
      </fieldset>
    </StyledForm>
  );
};
