import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Hazard = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.689 21C22.2297 21 23.1918 19.3312 22.4198 17.9979L13.7308 2.98963C12.9605 1.65906 11.0395 1.65906 10.2691 2.98963L1.58014 17.9979C0.808209 19.3312 1.77032 21 3.31099 21H20.689ZM12.05 7.99997C11.3596 7.99997 10.8 8.55962 10.8 9.24997V13.25C10.8 13.9403 11.3596 14.5 12.05 14.5C12.7404 14.5 13.3 13.9403 13.3 13.25V9.24997C13.3 8.55962 12.7404 7.99997 12.05 7.99997ZM12.05 18.5C12.7404 18.5 13.3 17.9403 13.3 17.25C13.3 16.5596 12.7404 16 12.05 16C11.3596 16 10.8 16.5596 10.8 17.25C10.8 17.9403 11.3596 18.5 12.05 18.5Z"
      />
    </IconSVGView>
  );
};
