import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Delete = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 4H18C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5C5 4.44772 5.44772 4 6 4H8.5L9.21 3.29C9.3968 3.10607 9.64785 3.00206 9.91 3H14.09C14.3521 3.00206 14.6032 3.10607 14.79 3.29L15.5 4ZM8 21C6.8968 20.9967 6.00329 20.1032 6 19V9C6.00329 7.8968 6.8968 7.00329 8 7H16C17.1032 7.00329 17.9967 7.8968 18 9V19C17.9967 20.1032 17.1032 20.9967 16 21H8Z"
      />
    </IconSVGView>
  );
};
