import { useContext } from 'react';

import { GoogleMapsContext } from '@components/common/Map/apis/GoogleMaps/googleMapsContext';

export function useGoogle(): typeof google {
  const googleInstance = useContext(GoogleMapsContext)?.google;

  if (!googleInstance) {
    throw new Error("Can't find the google map context.");
  }

  return google;
}

export function useGoogleMap(): google.maps.Map {
  const map = useContext(GoogleMapsContext)?.map ?? null;

  if (!map) {
    throw new Error("Can't find the google map context.");
  }

  return map;
}
