import produce from 'immer';

import * as actionTypes from '../actionTypes';

const getCount = localStorage.getItem('notificationCount');

const defaultState = {
  notificationCount: getCount ? JSON.parse(getCount) : 0,
};

export const notificationCountReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.RESET_NOTIFICATIONS_COUNT_TO_ZERO:
        localStorage.setItem('notificationCount', JSON.stringify(0));
        st.notificationCount = 0;
        break;
      case actionTypes.SET_NOTIFICATION_COUNT:
        localStorage.setItem('notificationCount', JSON.stringify(action.notificationCount));
        st.notificationCount = action.notificationCount;
        break;
      default:
    }
  });
};
