import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { Clock } from '@components/common/Icon/presets/Clock';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Edit } from '@components/common/Icon/presets/Edit';
import { HiveAdded } from '@components/common/Icon/presets/HiveAdded';
import { HiveMoved } from '@components/common/Icon/presets/HiveMoved';
import { Inspection } from '@components/common/Icon/presets/Inspection';
import { Yard } from '@components/common/Icon/presets/Yard';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonCircle } from '@components/common/LoadingSkeleton/LoadingSkeletonCircle';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import {
  StyledGoToTopArrowButton,
  StyledTimeline,
  StyledTimelineFooter,
  StyledTimelineFooterCard,
  StyledTimelineInner,
  StyledTimelineItem,
  StyledTimelineItemGroup,
  StyledTimelineItemIconWrapper,
} from '@components/timeline/TimelineBase/styles';
import { TimelineBaseLoading } from '@components/timeline/TimelineBase/TimelineBaseLoading';
import { BaseActivity, TimelineBaseProps } from '@components/timeline/TimelineBase/types';
import { TimelineDayMark } from '@components/timeline/TimelineDayMark';
import { TimelineEmpty } from '@components/timeline/TimelineEmpty';
import { useTranslation } from '@hooks/useTranslation';

const GO_TO_TOP_ARROW_OFFSET = 128;
const LOAD_MORE_OFFSET = 192;
const ICON_PRESETS: Record<string, any> = {
  new_inspection: Inspection,
  hive_added_to_yard: HiveAdded,
  hive_managed_to_yard: HiveMoved,
};

export const TimelineBase = <I extends BaseActivity>({
  items,
  itemHeadingRenderer,
  itemContentRenderer,
  itemsTotalCount,
  onMoreItemsRequest,
  isLoadingInitialItems,
  isLoadingMoreItems,
  enableGoToTopArrow,
  footerMessage,
  emptyMessage,
  suppressDayMarks,
}: TimelineBaseProps<I>) => {
  const [showGoToTopArrow, setShowGoToTopArrow] = useState(false);

  const t = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const loadMoreCalledFor = useRef<Record<number, boolean>>({ 0: true });
  const hasMoreToLoad = items.length < itemsTotalCount;
  const isEmpty = itemsTotalCount === 0;

  const groups = useMemo(() => {
    const groups: Array<Array<I>> = [];
    items.forEach((item, index, arr) => {
      if (index === 0) {
        groups.push([item]);
        return;
      }

      const prevDate = moment(arr[index - 1].occurredAt).format('YYYY-MM-DD');
      const currDate = moment(item.occurredAt).format('YYYY-MM-DD');

      if (prevDate !== currDate) {
        groups.push([item]);
      } else {
        groups[groups.length - 1].push(item);
      }
    });
    return groups;
  }, [items]);

  const scrollToTop = useCallback(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const getIcon = useCallback(({ alertType }) => {
    switch (alertType) {
      case 'new_yard_visit':
      case 'new_yard_created':
        return Yard;
      case 'yard_edited_by_manager':
        return Edit;
      case 'yard_deleted':
        return Delete;
      default:
        return ICON_PRESETS[alertType];
    }
  }, []);

  useLayoutEffect(() => {
    const scrollElement = scrollRef.current;
    const handler = throttle(() => {
      if (!scrollElement) {
        return;
      }

      const scrollableArea = scrollElement.scrollHeight - scrollElement.offsetHeight;
      const scrollTop = scrollElement.scrollTop;
      const hasReachedScrollOffset = scrollableArea - scrollTop <= LOAD_MORE_OFFSET;
      const hasLoadedForCurrentItems = loadMoreCalledFor.current[items.length];
      if (hasMoreToLoad && hasReachedScrollOffset && !hasLoadedForCurrentItems) {
        loadMoreCalledFor.current[items.length] = true;
        onMoreItemsRequest && onMoreItemsRequest();
      }

      setShowGoToTopArrow(Boolean(enableGoToTopArrow && scrollTop > GO_TO_TOP_ARROW_OFFSET));
    }, 120);

    scrollElement?.addEventListener('scroll', handler);
    handler();

    return () => scrollElement?.removeEventListener('scroll', handler);
  }, [enableGoToTopArrow, hasMoreToLoad, items.length, onMoreItemsRequest]);

  return (
    <StyledTimeline ref={scrollRef} column stretch>
      <StyledGoToTopArrowButton $isVisible={showGoToTopArrow} onClick={scrollToTop}>
        <ArrowRight size={24} />
      </StyledGoToTopArrowButton>
      <StyledTimelineInner column stretch $isVisible={!isLoadingInitialItems} $suppressDayMarks={suppressDayMarks}>
        {groups.map((group, groupIndex) => (
          <Box column stretch key={groupIndex}>
            {!suppressDayMarks && <TimelineDayMark date={group[0].occurredAt} />}

            <StyledTimelineItemGroup column stretch gapMD>
              {group.map((item, index) => {
                const Icon = getIcon(item);
                return (
                  <StyledTimelineItem column stretch key={index}>
                    {itemHeadingRenderer(item, index)}
                    <Box alignItems={'center'} marginBottomSM>
                      <Clock size={16} />
                      <Box paddingLeftXXS />
                      <Text typography={'SmallParagraph'}>{moment(item.occurredAt).format('H:mm')}</Text>
                    </Box>

                    {itemContentRenderer(item, index)}

                    <StyledTimelineItemIconWrapper>
                      {Icon && <Icon size={12} color={'coreWhite'} />}
                    </StyledTimelineItemIconWrapper>
                  </StyledTimelineItem>
                );
              })}
            </StyledTimelineItemGroup>
          </Box>
        ))}

        {isLoadingMoreItems && (
          <LoadingSkeleton column stretch paddingTopXS visible>
            {!suppressDayMarks && (
              <Box marginVerticalMD center>
                <LoadingSkeletonRectangle width={120} height={32} borderRadius={'paperRadius03'} />
              </Box>
            )}
            <Box marginVerticalMD alignItems={'center'} gapSM>
              <LoadingSkeletonCircle size={24} backgroundColor={'grey06'} />
              <LoadingSkeletonRectangle width={128} height={24} />
            </Box>
          </LoadingSkeleton>
        )}

        {!hasMoreToLoad && !isEmpty && (
          <StyledTimelineFooter marginVerticalMD>
            <StyledTimelineFooterCard>
              <Box center gapXS>
                <Text typography={'Heading3'}>{footerMessage}</Text>
                <Button tertiary paddingHorizontal={false} paddingVertical={false} onClick={scrollToTop}>
                  {t('timeline_bottom_go_to_top')}
                </Button>
              </Box>
            </StyledTimelineFooterCard>
          </StyledTimelineFooter>
        )}

        {isEmpty && <TimelineEmpty message={emptyMessage} />}
      </StyledTimelineInner>

      <TimelineBaseLoading
        suppressDayMarks={suppressDayMarks}
        visible={isLoadingInitialItems}
        absolutelyFitParent
        marginHorizontalXXS
        paddingHorizontalSM
        paddingVerticalXS
      />
    </StyledTimeline>
  );
};
