import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const GroupPage = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" size={size}>
      <path
        d="M13.334 4H8L6.667 2.667h-4C1.93 2.667 1.34 3.263 1.34 4l-.007 8c0 .737.597 1.333 1.334 1.333h10.666c.737 0 1.334-.596 1.334-1.333V5.333c0-.736-.597-1.333-1.333-1.333Z"
        fill={useColor(color)}
      />
    </IconSVGView>
  );
};
