import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Inspection = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4H15C15 2.34315 13.6569 1 12 1C10.3431 1 9 2.34315 9 4H5C3.8968 4.00329 3.00329 4.8968 3 6V20C3.00329 21.1032 3.8968 21.9967 5 22H19C20.1032 21.9967 20.9967 21.1032 21 20V6C20.9967 4.8968 20.1032 4.00329 19 4ZM12 2.75C12.6904 2.75 13.25 3.30964 13.25 4C13.25 4.69036 12.6904 5.25 12 5.25C11.3096 5.25 10.75 4.69036 10.75 4C10.75 3.30964 11.3096 2.75 12 2.75ZM8 18H12C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16H8C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18ZM16 14H8C7.44772 14 7 13.5523 7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14ZM8 10H16C16.5523 10 17 9.55229 17 9C17 8.44772 16.5523 8 16 8H8C7.44772 8 7 8.44772 7 9C7 9.55229 7.44772 10 8 10Z"
      />
    </IconSVGView>
  );
};
