import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StatusChipView = styled.div`
  text-transform: uppercase;
  background-color: ${(props) => props.theme[`${props.colorcode}Color`]};
  height: 22px;
  width: ${(props) => (props.isMobile ? '22px' : 'fit-content')};
  border-radius: 13px;
  line-height: 1rem;
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  padding: ${(props) => !props.isMobile && '1px 10px'};
  font-size: ${(props) => props.theme.captionSmallFontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  line-height: 22px;
  text-align: center;
`;

export class StatusChip extends Component {
  render() {
    const { isMobile, children } = this.props;

    return (
      <StatusChipView isMobile={isMobile} colorcode={children}>
        {isMobile ? children.charAt(0) : children}
      </StatusChipView>
    );
  }
}

StatusChip.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
