import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { CellClickedEvent, ColDef, GetRowIdFunc, ICellRendererParams } from 'ag-grid-community';

import { AgGrid } from '@components/common/AgGrid';
import { YardName } from '@components/common/YardName';
import { DefaultCellText } from '@components/yard/YardsList/cells/DefaultCellText';
import { DefaultCellWrapper } from '@components/yard/YardsList/cells/DefaultCellWrapper';
import { GroupsCell } from '@components/yard/YardsList/cells/GroupsCell';
import { LoadingCell } from '@components/yard/YardsList/cells/LoadingCell';
import { MenuCell } from '@components/yard/YardsList/cells/MenuCell';
import { PracticeCell } from '@components/yard/YardsList/cells/PracticeCell';
import { Status } from '@components/yard/YardsList/cells/Status';
import { YardTypeCell } from '@components/yard/YardsList/cells/YardTypeCell';
import {
  DEF_ABSTRACT_COLUMNS_KEYS,
  DEF_CACHE_BLOCK_COUNT,
  DEF_CACHE_BLOCK_SIZE,
  DEF_COLUMN_MIN_WIDTH,
  DEF_HEADER_GROUP_HEIGHT,
  DEF_HEADER_HEIGHT,
  DEF_INITIAL_ROW_COUNT,
  DEF_MAX_CONCURRENT_REQUESTS,
  DEF_PAGINATION_SIZE,
  DEF_ROW_HEIGHT,
  DEF_UPDATE_UPDATE_DEBOUNCE,
} from '@components/yard/YardsList/constants';
import {
  useYardsListDataCycle,
  useYardsListHasLoaded,
  useYardsListHeightSetter,
  useYardsListPaginationHelper,
} from '@components/yard/YardsList/hooks';
import { useYardsListEmptyState } from '@components/yard/YardsList/hooks/useYardsListEmptyState';
import { useYardsListScrollingEffects } from '@components/yard/YardsList/hooks/useYardsListScrollingEffects';
import { StyledYardsListStyleSetter } from '@components/yard/YardsList/styles';
import { ColumnType, RowColumnData, YardRow } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { YardsListEmpty, YardsListEmptyWithFilters } from '@components/yard/YardsList/YardsListEmpty';
import APP from '@config/constants';
import { getDate } from '@helpers/deprecated/time';
import { useActionCategoriesFetcher } from '@hooks/useActions';
import { makeNavBarTitleChangeAction } from '@redux/deprecated/actions';

import { AvgVisits } from './cells/AvgVisits';
import { Grading } from './cells/Grading';
import { LastVisit } from './cells/LastVisit';
import { YardSelectCheckbox } from './cells/YardSelectCheckbox';
import { useGridApi } from './hooks/useGridApi';
import { YardsListHeader } from './YardsListHeader';
import { YardsSelectAllAcrossPages } from './YardsSelectAllAcrossPages';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export const YardsList: React.VFC = () => {
  const [didFirstLoad, setDidFirstLoad] = useState(false);

  const { gridApi, setGridApi } = useGridApi();
  const dispatch = useDispatch();
  const gridWrapperRef = useRef<HTMLDivElement>(null);
  const { isListEmpty } = useYardsListEmptyState();
  const enableTableInteraction = didFirstLoad && !isListEmpty;
  const hasLoaded = useYardsListHasLoaded();
  const hasAppliedFilters = useSelector((state) => state.yardsFiltersReducer.appliedFiltersCount > 0);
  const showEmptyYards = hasLoaded && isListEmpty && !hasAppliedFilters;
  const showFilteredEmptyYards = hasLoaded && isListEmpty && hasAppliedFilters;

  useActionCategoriesFetcher();

  useYardsListDataCycle(gridApi, {
    onDataServerLoad: useCallback(() => {
      setDidFirstLoad(true);
    }, []),
  });

  useYardsListScrollingEffects();
  useYardsListHeightSetter(gridWrapperRef);
  useYardsListPaginationHelper(gridApi);

  const getRowId = useCallback<GetRowIdFunc<YardRow>>(({ data }) => String(data.meta.id), []);
  const onGridReady = useCallback(({ api }) => setGridApi(api), [setGridApi]);

  const onCellClicked = useCallback((e: CellClickedEvent<YardRow>) => {
    if (e.column.getId() === 'select') return;

    // If we don't use a timeout, the screen freezes.
    // Why? No idea. Maybe another AgGrid issue.
    setTimeout(() => {
      if (e.data?.meta.id) {
        window.open(generatePath(APP.routes.yard, { uid: e.data.meta.id }));
      }
    });
  }, []);

  const getCellRenderParams = useCallback(({ colDef, data: rowData }: ICellRendererParams<YardRow, RowColumnData>) => {
    const colDefWithMetadata = colDef ? GridApiUtil.getColumnDefWithMetadata(colDef) : null;

    if (
      colDef &&
      !DEF_ABSTRACT_COLUMNS_KEYS.includes(colDef.field) &&
      colDefWithMetadata &&
      colDefWithMetadata.field &&
      rowData
    ) {
      const id = rowData.meta.id;
      const { column } = colDefWithMetadata.metadata;
      const view = GridApiUtil.getActiveView(colDefWithMetadata);

      if (view) {
        const data = rowData.data[colDefWithMetadata.field]?.views[view.key].data;
        return { id, column, view, data, colDef };
      }
    }

    return null;
  }, []);

  const renderCell = useCallback(
    (gridParams: ICellRendererParams<YardRow, RowColumnData>) => {
      const params = getCellRenderParams(gridParams);

      if (gridParams.colDef?.field === 'select') {
        return <YardSelectCheckbox yard={gridParams.data} />;
      }

      if (gridParams.colDef?.field === 'menu') {
        return <MenuCell data={gridParams.data ?? null} />;
      }

      let cellRender: any;
      const isLoading = params?.data === undefined;

      if (params?.data === undefined) {
        cellRender = null;
      } else {
        const { column, data } = params;

        switch (column.key) {
          case 'composedName':
            cellRender = (
              <YardName
                name={data.name}
                contractName={data.contractName}
                typography={'SmallParagraph'}
                maxWidth={188}
                weight={'600'}
              />
            );
            break;
          case 'composedStatus':
            cellRender = <Status data={data} />;
            break;
          case 'averageVisits':
            cellRender = <AvgVisits data={data} />;
            break;
          case 'lastVisit':
            cellRender = <LastVisit {...params} />;
            break;
          case 'grading':
            cellRender = <Grading data={data} />;
            break;
          case 'groups':
            cellRender = <GroupsCell data={data} />;
            break;
          case 'type':
            cellRender = <YardTypeCell data={data} />;
            break;
          default:
            if (column.type == ColumnType.PRACTICE || column.type == ColumnType.PRACTICE_CATEGORY) {
              cellRender = <PracticeCell {...params} />;
            } else if (data !== null) {
              // TODO: Move it to its own cell component.
              let value = String(data);
              const isDate = /^\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}/.test(String(data));

              if (isDate) {
                value = getDate(data);
              }

              cellRender = (
                <DefaultCellWrapper>
                  <DefaultCellText>{value}</DefaultCellText>
                </DefaultCellWrapper>
              );
            } else {
              cellRender = null;
            }
            break;
        }
      }

      return (
        <>
          {cellRender}
          <LoadingCell visible={isLoading} />
        </>
      );
    },
    [getCellRenderParams]
  );

  const defaultColDef = useMemo<ColDef<YardRow>>(
    () => ({
      resizable: true,
      lockPinned: true,
      lockPosition: true,
      headerComponent: YardsListHeader,
      cellRenderer: renderCell,
      minWidth: DEF_COLUMN_MIN_WIDTH,
    }),
    [renderCell]
  );

  // todo: delete this horrible thing as soon as new navigation is in place
  // it's only purpose is to be able to make the "list" nav button look selected
  useEffect(() => {
    dispatch(
      makeNavBarTitleChangeAction({
        backBtn: false,
        label: '',
        yard: null,
        view: 'list-beetrack',
      })
    );
  }, [dispatch]);

  return (
    <StyledYardsListStyleSetter ref={gridWrapperRef} $enableInteraction={enableTableInteraction}>
      <YardsSelectAllAcrossPages />
      <div className="ag-theme-alpine">
        <AgGrid
          rowModelType={'infinite'}
          rowSelection={'multiple'}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          pagination
          suppressRowVirtualisation
          suppressColumnVirtualisation
          suppressRowClickSelection
          onCellClicked={onCellClicked}
          onGridReady={onGridReady}
          rowHeight={DEF_ROW_HEIGHT}
          headerHeight={DEF_HEADER_HEIGHT}
          groupHeaderHeight={DEF_HEADER_GROUP_HEIGHT}
          cacheBlockSize={DEF_CACHE_BLOCK_SIZE}
          maxBlocksInCache={DEF_CACHE_BLOCK_COUNT}
          paginationPageSize={DEF_PAGINATION_SIZE}
          blockLoadDebounceMillis={DEF_UPDATE_UPDATE_DEBOUNCE}
          maxConcurrentDatasourceRequests={DEF_MAX_CONCURRENT_REQUESTS}
          infiniteInitialRowCount={DEF_INITIAL_ROW_COUNT}
          sortingOrder={['asc', 'desc']}
        />

        <YardsListEmptyWithFilters visible={showFilteredEmptyYards} />
      </div>

      <YardsListEmpty visible={showEmptyYards} />
    </StyledYardsListStyleSetter>
  );
};
