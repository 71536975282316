import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  text-align: center;
`;

const MapText = styled.p`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${(props) => props.theme.fontStack};
  font-size: ${(props) => props.theme.captionSmallFontSize};
  color: ${(props) => props.theme.supportingColor};
`;

export const LowSrcImgView = ({ t, src, alt, height }) => (
  <Container>
    <img width="100%" height={height} alt={alt} src={src} />
    <MapText>{t('no_beehub_data')}</MapText>
  </Container>
);

LowSrcImgView.propTypes = {
  t: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
