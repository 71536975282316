import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HiveGrading = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 1C15.3466 1.00821 14.0082 2.34656 14 4V5H13C12.4477 5 12 5.44772 12 6C12 6.55228 12.4477 7 13 7H14V8C14 9.65685 15.3431 11 17 11C18.6569 11 20 9.65685 20 8V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H20V4C19.9918 2.34656 18.6534 1.00821 17 1ZM17 3C17.2665 2.99563 17.5234 3.09958 17.7119 3.28807C17.9004 3.47657 18.0044 3.73347 18 4V5H16V4C15.9956 3.73347 16.0996 3.47657 16.2881 3.28807C16.4766 3.09958 16.7335 2.99563 17 3ZM4 10C4.00821 8.34656 5.34656 7.00821 7 7C8.65344 7.00821 9.99179 8.34656 10 10V11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H10V14C10 15.6569 8.65685 17 7 17C5.34315 17 4 15.6569 4 14V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H4V10ZM18 7V8C18 8.55228 17.5523 9 17 9C16.4477 9 16 8.55228 16 8V7H18ZM7.71193 9.28807C7.52343 9.09958 7.26653 8.99563 7 9C6.73347 8.99563 6.47657 9.09958 6.28807 9.28807C6.09958 9.47657 5.99563 9.73347 6 10V11H8V10C8.00437 9.73347 7.90042 9.47657 7.71193 9.28807ZM8 13V14C8 14.5523 7.55228 15 7 15C6.44772 15 6 14.5523 6 14V13H8ZM14 16C14.0082 14.3466 15.3466 13.0082 17 13C18.6534 13.0082 19.9918 14.3466 20 16V17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H20V20C20 21.6569 18.6569 23 17 23C15.3431 23 14 21.6569 14 20V19H13C12.4477 19 12 18.5523 12 18C12 17.4477 12.4477 17 13 17H14V16ZM17 15C17.2665 14.9956 17.5234 15.0996 17.7119 15.2881C17.9004 15.4766 18.0044 15.7335 18 16V17H16V16C15.9956 15.7335 16.0996 15.4766 16.2881 15.2881C16.4766 15.0996 16.7335 14.9956 17 15ZM18 20V19H16V20C16 20.5523 16.4477 21 17 21C17.5523 21 18 20.5523 18 20Z"
      />
    </IconSVGView>
  );
};
