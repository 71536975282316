import styled from 'styled-components/macro';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { Text } from '@components/common/Text';

export const ActionPillView = styled(Box)<{ isDefault: boolean; isActive: boolean } & BoxProps<'div'>>(
  ({ theme, isDefault, isActive }) => `
  padding: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
  background-color: ${isDefault ? theme.colors.grey04 : isActive ? theme.colors.yellow01 : theme.colors.grey02};
  border-radius: 192px;
`
);

export const ActionTitle = styled(Text)(
  ({ theme }) => `
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
`
);

export const ActionMoreIcon = styled.img(
  ({ theme }) => `
  filter: ${theme.colors.filter('grey08')};
  border-radius: 192px;
  transform: rotate(90deg);
`
);

export const ActionMore = styled.button(
  ({ theme }) => `
  padding: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
  border-radius: 192px;
  
  &:hover {
    ${ActionMoreIcon} {
      filter: ${theme.colors.filter('grey08')};
    }
  }
`
);
