import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const StyledGridRow = styled.div`
  display: grid;
  grid-template-columns: auto 70px 70px;
  grid-gap: 16px;
  align-items: center;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey03};
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
`;

export const StyledText = styled(Text)`
  text-wrap: nowrap;
`;

export const StyledCheckboxWrapper = styled(Box)`
  justify-content: center;
`;

export const StyledVisibleBox = styled(Box)<{ isVisible: boolean }>(
  ({ isVisible }) => `
    visibility: ${isVisible ? 'visible' : 'hidden'};
  `
);
