import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledWrapper = styled(Box)(
  ({ theme }) => `
  flex-direction: column;
  align-items: stretch;
  margin-top: ${theme.navigation.headerHeight}px;
`
);
