import { DefaultTheme } from 'styled-components';

import { DynamicIcon } from '@components/common/Icon/types';
import { SVG } from '@helpers/SVG';

const DEF_COLOR = '#9432D9';

export const HiveDot: DynamicIcon<[{ theme: DefaultTheme; size: number; color?: string }]> = {
  getImageURI({ theme, size, color }) {
    return SVG.generateCachedSvgUri(`${size}-${color}`, () => {
      return `
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="${size}" height="${size}" 
          viewBox="0 0 ${size} ${size}">
          <circle 
            r="50%" cx="50%" cy="50%" fill="${theme.colors.coreWhite}" />
          <circle 
            r="40%" cx="50%" cy="50%" fill="${color ?? DEF_COLOR}" />
        </svg>
      `;
    });
  },
};
