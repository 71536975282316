import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PlusIcon from '@assets/Small_Plus.svg';
import QuestionMarkIcon from '@assets/xSmall_Help.svg';
import { Box } from '@components/common/Box';
import { Card, CardContent, CardHeader } from '@components/common/Card';
import { IconIMG } from '@components/common/Icon';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { ActionPill } from '@components/operation/ActionPill';
import { capitalizeFirst } from '@helpers/deprecated/capitalizeFirst';
import { getIconByType } from '@helpers/deprecated/icons';
import { useActionCategory } from '@hooks/useActions';
import { makeEnableDisableActionRequestThunk, makeOpenBeekPracticesModalAction } from '@redux/deprecated/actions';

import { CategoryActions, PlusButton, PlusButtonIcon } from './styles';
import { ActionCategoryCardProps } from './types';

export const ActionCategoryCard: React.FC<ActionCategoryCardProps> = ({ categoryID, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const category = useActionCategory(categoryID);
  const actions = useMemo(() => category?.actions.filter((action) => !action.isDeleted) || [], [category]);

  const isEmpty = !actions.length;
  const isReadOnly = category?.readOnly;

  const openAddActionModal = useCallback(() => {
    category && dispatch(makeOpenBeekPracticesModalAction({ modalType: 'create-practice', category }));
  }, [category, dispatch]);

  const openEditActionModal = useCallback(
    (actionObj: BeeAction) => {
      category && dispatch(makeOpenBeekPracticesModalAction({ modalType: 'update-practice', category, actionObj }));
    },
    [category, dispatch]
  );

  const openDisableActionModal = useCallback(
    (actionObj: BeeAction) => {
      category && dispatch(makeOpenBeekPracticesModalAction({ modalType: 'remove-practice', category, actionObj }));
    },
    [category, dispatch]
  );

  const handleEnableAction = useCallback(
    (action: BeeAction) => {
      dispatch(makeEnableDisableActionRequestThunk(action.id, { is_active: true }));
    },
    [dispatch]
  );

  const renderHeader = useCallback(() => {
    const name = capitalizeFirst(category?.name);
    const iconResource = getIconByType('actions', category?.flag, 'x');
    const tooltipId = `category-help-tooltip-${category?.id}`;

    return (
      <Box alignItems={'center'}>
        <IconIMG src={iconResource} size={20} marginRightXS />
        <Text typography={'Heading3'} weight={'600'}>
          {name}
        </Text>
        {category?.readOnly ? (
          <>
            <IconIMG
              id={tooltipId}
              src={QuestionMarkIcon}
              size={18}
              color={'grey06'}
              alt={'Help tooltip'}
              clickable
              marginLeftXS
            />
            <Tooltip target={tooltipId}>
              <Text typography={'CaptionSmall'}>{capitalizeFirst(t('category_tooltip', { category_name: name }))}</Text>
            </Tooltip>
          </>
        ) : null}
      </Box>
    );
  }, [category?.id, category?.name, category?.readOnly, category?.flag, t]);

  const renderContent = useCallback(() => {
    return (
      <CategoryActions alignItems={'center'}>
        {actions.map(({ id }) => (
          <ActionPill
            key={id}
            actionId={id}
            marginXXS
            onEditClick={openEditActionModal}
            onDisableClick={openDisableActionModal}
            onEnableClick={handleEnableAction}
          />
        ))}
        {!isReadOnly ? (
          <PlusButton onClick={openAddActionModal}>
            <PlusButtonIcon src={PlusIcon} alt={'Add action'} />
          </PlusButton>
        ) : null}
      </CategoryActions>
    );
  }, [actions, handleEnableAction, isReadOnly, openAddActionModal, openDisableActionModal, openEditActionModal]);

  if (!category || (isEmpty && isReadOnly)) return null;

  return (
    <Card {...props}>
      <CardHeader>{renderHeader()}</CardHeader>
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
};
