import React, { useState } from 'react';
import { GridApi } from 'ag-grid-community';

import { GridApiContext, GridApiContextValue } from '.';

export const GridApiProvider: React.FC = ({ children }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const context: GridApiContextValue = {
    gridApi,
    setGridApi,
  };
  return <GridApiContext.Provider value={context}>{children}</GridApiContext.Provider>;
};
