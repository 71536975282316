import React, { Fragment, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { MarkerCluster as MarkerClusterIcon } from '@components/common/Icon/dynamic/MarkerCluster';
import { Marker } from '@components/common/Map';
import { useMap } from '@components/common/Map/hooks';
import {
  Bounds,
  MarkerCluster,
  MarkerDescriptor,
  MarkerOptimizerProps,
  MarkerProps,
} from '@components/common/Map/types';
import { MapClusterer, MapSmartBounds } from '@helpers/MapOptimizer';

const CLUSTER_FITTING_PADDING = 192;
const CLUSTER_ICON_SIZE = 32;

export const MarkerOptimizer: React.VFC<MarkerOptimizerProps> = ({
  debug,
  enableClustering,
  enableSmartBounds,
  markerClusterProps,
  minZoomToRender,
  maxZoomToRender,
  maxZoomToCluster,
  markers,
  markerRenderer: propMarkerRenderer,
  markerClusterRenderer: propMarkerClusterRenderer,
  visibleMarkersFilter,
}) => {
  const map = useMap();
  const mapBounds = map.state.bounds;
  const mapZoom = map.state.zoom;
  const shouldRender =
    (minZoomToRender === undefined || mapZoom >= minZoomToRender) &&
    (maxZoomToRender === undefined || mapZoom <= maxZoomToRender);

  const { markerSmartBounds, markerClusterer } = useMemo(() => {
    const markerElements = markers.map((marker) => ({ id: marker.id, ...marker.position, properties: { marker } }));
    const clusterOptions = maxZoomToCluster ? { maxZoom: maxZoomToCluster } : {};
    return {
      markerSmartBounds: new MapSmartBounds(enableSmartBounds ? [...markerElements] : [], debug),
      markerClusterer: new MapClusterer(enableClustering ? [...markerElements] : [], clusterOptions, debug),
    };
  }, [debug, enableClustering, enableSmartBounds, markers, maxZoomToCluster]);

  const { markersToRender, markerClustersToRender } = useMemo(() => {
    let markersToRender = [] as Array<MarkerDescriptor>;
    let markerClustersToRender = [] as Array<MarkerCluster>;

    if (mapBounds && shouldRender) {
      if (enableClustering) {
        const { elements, clusters } = markerClusterer.getElementsAndClusters(mapBounds, mapZoom);
        markersToRender = Object.values(elements).map((element) => element.properties.marker);
        markerClustersToRender = Object.values(clusters).map(({ id, lat, lng, elementCount, getElements }) => ({
          id,
          position: { lat, lng },
          markerCount: elementCount,
          getMarkers: () => Object.values(getElements()).map((element) => element.properties.marker),
        }));
      } else if (enableSmartBounds) {
        const visibleElements = markerSmartBounds.getVisibleElements(mapBounds as Bounds);
        markersToRender = Object.values(visibleElements).map((element) => element.properties.marker);
      } else {
        markersToRender = markers;
      }

      if (visibleMarkersFilter) {
        markersToRender = visibleMarkersFilter(markersToRender);
      }
    }

    return { markersToRender, markerClustersToRender };
  }, [
    mapBounds,
    shouldRender,
    enableClustering,
    enableSmartBounds,
    visibleMarkersFilter,
    markerClusterer,
    mapZoom,
    markerSmartBounds,
    markers,
  ]);

  const markerRenderer = useCallback(
    (marker: MarkerDescriptor) => {
      if (propMarkerRenderer) {
        return propMarkerRenderer(marker);
      }
      return <Marker {...marker} />;
    },
    [propMarkerRenderer]
  );

  const markerClusterRenderer = useCallback(
    (markerCluster: MarkerCluster) => {
      if (propMarkerClusterRenderer) {
        return propMarkerClusterRenderer(markerCluster);
      }

      return <MarkerClusterCircle markerCluster={markerCluster} markerClusterProps={markerClusterProps} />;
    },
    [markerClusterProps, propMarkerClusterRenderer]
  );

  return (
    <>
      {markersToRender.map((marker) => (
        <Fragment key={marker.id}>{markerRenderer(marker)}</Fragment>
      ))}
      {markerClustersToRender.map((markerCluster) => (
        <Fragment key={markerCluster.id}>{markerClusterRenderer(markerCluster)}</Fragment>
      ))}
    </>
  );
};

export const MarkerClusterCircle: React.VFC<{
  markerCluster: MarkerCluster;
  markerClusterProps?: Partial<MarkerProps>;
}> = ({ markerCluster, markerClusterProps }) => {
  const map = useMap();
  const theme = useTheme();

  const onClusterClick = useCallback(() => {
    const clusteredPositions = markerCluster.getMarkers().map((marker) => marker.position);
    map.instance?.fitCoordinates(clusteredPositions, CLUSTER_FITTING_PADDING);
  }, [map.instance, markerCluster]);

  const { markerCount } = markerCluster;

  return (
    <Marker
      position={markerCluster.position}
      onClick={onClusterClick}
      icon={MarkerClusterIcon.getImageURI({ markerCount, theme, size: CLUSTER_ICON_SIZE })}
      {...markerClusterProps}
    />
  );
};
