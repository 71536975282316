import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.theme.maxDesktopWidth};
  margin: 0 auto;
  padding-left: 1rem;
  padding-top: 30px;
  background-color: ${(props) => (props.disabled ? 'rgba(102,102,102,0.05)' : props.theme.primaryBackgroundColor)};
  color: ${(props) => (props.error ? props.theme.criticalColor : props.theme.primaryTextColor)};
  border: none;
  outline: none;
  font-family: 'Open Sans';
  font-size: ${(props) => props.theme.paragraphFontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  line-height: 22px;
  letter-spacing: 0;
  ::placeholder {
    font-family: 'Open Sans';
    color: rgba(55, 67, 65, 0.6);
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 12px;
  left: 1rem;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  overflow: hidden;
  color: ${(props) =>
    props.disabled ? 'rgba(55,67,65,0.6)' : props.error ? props.theme.criticalColor : props.theme.supportingColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
  letter-spacing: 0;
  &:disabled {
    color: rgba(55, 67, 65, 0.6);
  }
`;

export class TextArea extends React.Component {
  /**
   *
   * @returns {*} JSX
   */
  render() {
    const {
      id,
      name,
      label,
      value,
      defaultValue,
      handleChange,
      type,
      styles,
      required = false,
      disabled = false,
      error = false,
    } = this.props;

    return (
      <>
        <StyledLabel error={error} disabled={disabled} htmlFor={id}>
          {label}
        </StyledLabel>
        <StyledTextArea
          onChange={handleChange}
          id={id}
          name={name}
          placeholder={defaultValue}
          value={value ? value : ''}
          type={type}
          style={styles}
          required={required}
          disabled={disabled}
          error={error}
        />
      </>
    );
  }
}

TextArea.defaultProps = {
  required: false,
  disabled: false,
  error: false,
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  styles: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
