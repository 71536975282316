import React, { ChangeEvent, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { debounce } from 'lodash';

import GoogleLogo from '@assets/powered_by_google.png';
import { Pin } from '@components/common/Icon/presets/Pin';
import { useGoogle } from '@components/common/Map/apis/GoogleMaps/hooks';
import { StyledSearchBoxDropdown, StyledSearchBoxDropdownItem } from '@components/common/Map/apis/GoogleMaps/styles';
import { Coordinate, MapSearchBoxProps } from '@components/common/Map/types';
import { KeyHandlerPriority, useKeyHandler } from '@hooks/useKeyHandler';

const SEARCH_DEBOUNCE = 250;
const SINGLE_COORDINATE_GAP = 0.0001;
const COORDINATE_MATCHED = /^\s*(-?\d+\.?\d*)\s*([,;:])\s*(-?\d+\.?\d*)\s*$/;

interface Suggestion {
  name: string;
  nameFormatted: string;
  placeId?: string;
  coordinate?: Coordinate;
}

export const SearchBoxRenderer = React.forwardRef<HTMLInputElement | null, MapSearchBoxProps>(
  function SearchBoxRenderer({ onAddressFound, ...props }, ref) {
    const google = useGoogle();
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [autocompleteService] = useState(() => new google.maps.places.AutocompleteService());
    const [geocoder] = useState(() => new google.maps.Geocoder());
    const [focused, setFocused] = useState(false);
    const [selectionIndex, setSelectionIndex] = useState(0);
    const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);
    const hasSuggestions = suggestions.length > 0;
    const shouldShowSuggestions = hasSuggestions && focused;

    const blurInput = useCallback(() => {
      inputRef.current?.focus(); // Bring focus back to the input...
      inputRef.current?.blur(); // Then release it.
    }, []);

    const updateDropDownPosition = useCallback(() => {
      if (dropdownRef.current && inputRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        dropdownRef.current.style.top = `${inputRect.bottom}px`;
        dropdownRef.current.style.left = `${inputRect.left}px`;
        dropdownRef.current.style.width = `${inputRect.width}px`;
      }
    }, []);

    const getCoordinateSuggestions = useCallback((queryText): Array<Suggestion> => {
      const match = queryText.match(COORDINATE_MATCHED);
      if (match) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[3]);
        const name = [lat, lng].join(', ');
        return [{ name, nameFormatted: name, coordinate: { lat, lng } }];
      }
      return [];
    }, []);

    const getPredictionDescription = useCallback((prediction: google.maps.places.AutocompletePrediction) => {
      const { description, matched_substrings } = prediction;
      let output = '';
      let lastOffset = 0;
      matched_substrings.forEach(({ offset, length }) => {
        output += description.substring(lastOffset, offset);
        output += `<strong>${description.substring(offset, offset + length)}</strong>`;
        lastOffset = offset + length;
      });
      return output + description.substring(lastOffset);
    }, []);

    const getQuerySuggestions = useCallback(
      async (queryText: string) => {
        const { predictions } = await autocompleteService.getPlacePredictions({
          input: queryText,
          types: ['geocode'],
        });
        return predictions.map((p) => ({
          name: p.description,
          nameFormatted: getPredictionDescription(p),
          placeId: p.place_id,
        }));
      },
      [autocompleteService, getPredictionDescription]
    );

    const updateSuggestions = useMemo(
      () =>
        debounce(async () => {
          try {
            const queryText = inputRef.current?.value ?? '';
            if (queryText) {
              const querySuggestions = await getQuerySuggestions(queryText);
              const coordinateSuggestions = getCoordinateSuggestions(queryText);
              setSuggestions([...coordinateSuggestions, ...querySuggestions]);
            } else {
              setSuggestions([]);
            }
          } catch (error) {
            console.error(error);
            setSuggestions([]);
          }
        }, SEARCH_DEBOUNCE),
      [getCoordinateSuggestions, getQuerySuggestions]
    );

    const handleOnChangeValue = useCallback(() => {
      updateSuggestions();
    }, [updateSuggestions]);

    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(event);
        handleOnChangeValue();
      },
      [props, handleOnChangeValue]
    );

    const handleFocus = useCallback(
      (event: any) => {
        props.onFocus && props.onFocus(event);
        setFocused(true);
        updateDropDownPosition();
        updateSuggestions();
      },
      [props, updateDropDownPosition, updateSuggestions]
    );

    const handleBlur = useCallback(
      (event: any) => {
        props.onBlur && props.onBlur(event);
        setFocused(false);
        setSelectionIndex(0);
      },
      [props]
    );

    const handleSuggestionSelection = useCallback(
      async ({ name, placeId, coordinate }: Suggestion) => {
        if (inputRef.current) {
          blurInput();
          props.onChange && props.onChange({ target: { value: name } } as any);
        }

        if (onAddressFound) {
          if (coordinate) {
            const { lat, lng } = coordinate;
            const ne = { lat: lat + SINGLE_COORDINATE_GAP, lng: lng - SINGLE_COORDINATE_GAP };
            const sw = { lat: lat - SINGLE_COORDINATE_GAP, lng: lng + SINGLE_COORDINATE_GAP };
            onAddressFound({ name, path: [ne, sw] });
          } else if (placeId) {
            const { results } = await geocoder.geocode({ placeId });
            if (results.length) {
              const { viewport } = results[0].geometry;
              const path = [viewport.getNorthEast().toJSON(), viewport.getSouthWest().toJSON()];
              onAddressFound({ name, path });
            }
          }
        }
      },
      [blurInput, geocoder, onAddressFound, props]
    );

    const handleItemFocus = useCallback(
      (direction) => {
        let nextSelectionIndex = selectionIndex + direction;
        if (nextSelectionIndex < 0) {
          nextSelectionIndex = suggestions.length;
        } else if (nextSelectionIndex > suggestions.length) {
          nextSelectionIndex = 0;
        }

        if (nextSelectionIndex === 0) {
          inputRef.current?.focus();
        } else {
          const item = dropdownRef.current?.querySelector<HTMLButtonElement>(`button:nth-child(${nextSelectionIndex})`);
          item?.focus();
        }

        setSelectionIndex(nextSelectionIndex);
      },
      [selectionIndex, suggestions.length]
    );

    const handleItemSelection = useCallback(() => {
      const suggestion = suggestions[selectionIndex - 1];
      if (suggestion) {
        handleSuggestionSelection(suggestion).then(null);
      }
    }, [handleSuggestionSelection, selectionIndex, suggestions]);

    const handleCoordinatesSelection = useCallback(() => {
      const firstSuggestion = suggestions[0];
      if (firstSuggestion?.coordinate) {
        handleSuggestionSelection(firstSuggestion).then(null);
      }
    }, [handleSuggestionSelection, suggestions]);

    const keyHandler = useCallback(
      (event) => {
        if (focused) {
          switch (event.key) {
            case 'ArrowUp':
              handleItemFocus(-1);
              break;
            case 'ArrowDown':
              handleItemFocus(1);
              break;
            case 'Enter':
              handleCoordinatesSelection();
              break;
            case ' ':
              handleItemSelection();
              break;
            case 'Escape':
              blurInput();
              return true; // Avoid map ESC handler from being called.
          }
        }
      },
      [focused, handleCoordinatesSelection, blurInput, handleItemFocus, handleItemSelection]
    );

    useKeyHandler({ handler: keyHandler, priority: KeyHandlerPriority.HIGH });

    useEffect(() => {
      handleOnChangeValue();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    useImperativeHandle(ref, () => inputRef.current as any);

    return (
      <>
        <input {...props} ref={inputRef} onChange={handleOnChange} onFocus={handleFocus} onBlur={handleBlur} />
        {createPortal(
          <StyledSearchBoxDropdown ref={dropdownRef} visible={shouldShowSuggestions}>
            {suggestions.map((suggestion, index) => (
              <StyledSearchBoxDropdownItem
                key={index}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={() => handleSuggestionSelection(suggestion)}
              >
                <Pin size={16} />
                <span dangerouslySetInnerHTML={{ __html: suggestion.nameFormatted }} />
              </StyledSearchBoxDropdownItem>
            ))}
            <img src={GoogleLogo} alt="Powered by Google" />
          </StyledSearchBoxDropdown>,
          document.body
        )}
      </>
    );
  }
);
