/**
 * Map layer to render yards, yard geometries and hives.
 * */

import React, { useMemo } from 'react';

import { Polygon } from '@components/common/Map';
import { useMap } from '@components/common/Map/hooks';
import { MapUtil } from '@components/common/Map/util';
import { MapSmartBounds } from '@helpers/MapOptimizer';

const MIN_ZOOM_TO_SHOW_FLIGHT_RADIUS = 11;
const FLIGHT_RADIUS_IN_METTERS = 3000;
const FLIGHT_RADIUS_POLYGON_PROPS = {
  fillColor: '#FFFFFF',
  fillOpacity: 0.4,
  strokeWeight: 0,
};

export interface YardsMapBeeFlightRadiusLayerProps {
  yards: Array<BeeYard>;
}

export const YardsMapBeeFlightRadiusLayer: React.VFC<YardsMapBeeFlightRadiusLayerProps> = ({ yards }) => {
  const { state } = useMap();
  const mapZoom = state.zoom;
  const mapBounds = state.bounds;

  const yardMarkers = useMemo(
    () =>
      yards.map(({ id, yardCenter }) => {
        const [lng, lat] = yardCenter.coordinates;
        const flightRadius = MapUtil.generateCirclePath({ lat, lng }, FLIGHT_RADIUS_IN_METTERS);
        return { id, lat, lng, properties: { flightRadius } };
      }, []),
    [yards]
  );

  const optimizer = useMemo(() => new MapSmartBounds(yardMarkers), [yardMarkers]);

  const paths = useMemo(() => {
    if (mapBounds && mapZoom >= MIN_ZOOM_TO_SHOW_FLIGHT_RADIUS) {
      const visibleElements = optimizer.getVisibleElements(mapBounds);
      return Object.values(visibleElements).map((el) => el.properties.flightRadius);
    }
    return [];
  }, [mapBounds, mapZoom, optimizer]);

  return <Polygon paths={paths} {...FLIGHT_RADIUS_POLYGON_PROPS} />;
};
