import React from 'react';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';

import { useMap } from '@components/common/Map/hooks';
import { MapType } from '@components/common/Map/types';

import { BlockInfoText } from './styles';

export interface BlockPolygonTextProps {
  block: {
    name: string | null;
    area: string | number | null;
  };
}

export const BlockPolygonText: React.VFC<BlockPolygonTextProps> = ({ block }) => {
  const { t } = useTranslation();

  const map = useMap();
  const mapType = map.state.mapType;
  const useDarkerText = [MapType.TERRAIN, MapType.TERRAIN_SIMPLIFIED].includes(mapType);

  return (
    <BlockInfoText typography={'CaptionSmall'} weight={'600'} $useDarkerText={useDarkerText}>
      <span>{truncate(block.name ?? '', { length: 32 })}</span>
      <span>{t('pollination_block_acres_count_abr', { acres: block.area })}</span>
    </BlockInfoText>
  );
};
