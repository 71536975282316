import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

import { TextButton } from '@components/deprecated/Buttons/TextButton';

export const StyledRow = styled(Row)`
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-bottom: ${(props) => props.theme.tertiaryBorderStyle};
  padding: 1.5rem 1.5rem 1.5rem 0;
  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
  }
`;

export const StyledCol = styled(Col)`
  text-align: left;
  white-space: pre-wrap;
`;

export const DropColumn = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const StyledDesc = styled.div`
  line-height: 20px;
  font-size: 14px;
`;

export const StyledHeader = styled.div`
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const StyledImg = styled.img`
  filter: invert(1);
`;

export const Bold = styled.span`
  font-weight: ${(props) => props.theme.fontWeightBold};
  color: ${(props) => props.theme.primaryTextColor};
`;

export const TimelineBar = styled.p`
  color: ${(props) => props.theme.supportingColor};
  font-size: ${(props) => props.theme.tooltipSmallFontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: 0.5px;
  line-height: 26px;
  background-color: ${(props) => props.theme.timelineColor};
  text-transform: uppercase;
  text-align: left;
  padding-left: 20px;
`;

export const AlertBar = styled.p`
  line-height: 42px;
  background-color: ${(props) => props.theme.alertsColor};
  color: white;
  text-transform: uppercase;
  border-radius: 3px 3px 0 0;
  font-size: ${(props) => props.theme.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const StyledTextButton = styled(TextButton)`
  font-size: ${(props) => props.theme.heading3FontSize};
  margin-top: 1rem;
  color: ${(props) => props.theme.buttonPrimaryColor}99;
  &:hover {
    color: ${(props) => props.theme.buttonPrimaryColor};
  }
`;

export const AlertsIconContainer = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.primaryColor};
  justify-content: center;
  align-items: center;
  display: flex;
`;
