import React from 'react';

import { Marker } from '@components/common/Map';
import { MarkerDescriptor } from '@components/common/Map/types';
import { YardMarkerPin } from '@components/common/YardMarkerPin';

export function YardMarkerRenderer(
  props: {
    isYardSelected?: (yard: BeeYardOnMapInfo) => boolean;
    onYardPinClick?: (yard: BeeYardOnMapInfo) => void;
  } & MarkerDescriptor<BeeYardOnMapInfo>
) {
  const { data: yard, isYardSelected, onYardPinClick, ...extraProps } = props;
  const [lng, lat] = yard.yardCenter.coordinates;

  return (
    <Marker onClick={() => onYardPinClick && onYardPinClick(yard)} {...extraProps} position={{ lat, lng }}>
      <YardMarkerPin clickable={!!onYardPinClick} active={isYardSelected && isYardSelected(yard)} yard={yard} />
    </Marker>
  );
}
