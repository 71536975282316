import React from 'react';
import { useTranslation } from 'react-i18next';

import moreIcon from '@assets/xSmall_More.svg';

import { Icon, MoreButton } from './styles';

export const ThreeDotsMenuTrigger: React.FC<{ id: string; title?: string }> = ({ id, title }) => {
  const { t } = useTranslation();
  return (
    <MoreButton
      // to make this work with AG Grid, the dom event needs to be stopped, rather than just the react synthetic event
      // to prevent the click from passing through the button to the row
      ref={(ref) => {
        if (!ref) return;

        ref.onclick = (e) => {
          e.preventDefault();
          e.stopPropagation();
        };
      }}
      id={id}
      title={title ? title : t('open_menu')}
    >
      <Icon src={moreIcon} />
    </MoreButton>
  );
};
