import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loading } from '@components/common/Loading';
import { Analytics } from '@helpers/Analytics/index';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { isEmptyArray } from '@helpers/deprecated/array';
// nectar:
import { handleSortHiveList, makeFetchHiveListRequestThunk, makeOpenHiveModalAction } from '@redux/deprecated/actions';

import { BeeTrackHivesListView } from './BeeTrackHivesListView';

class BeeTrackHivesList extends React.Component {
  constructor(props) {
    super(props);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidMount() {
    const { fetchHhtHives, match, yardId, status, actionList } = this.props;
    const { uid } = match.params;
    if (yardId !== uid) fetchHhtHives(uid, status, actionList);
  }

  handleSort = (order_by) => {
    const { status, sortHiveList, order_direction } = this.props;
    sortHiveList(status, order_by, order_direction);
  };

  render() {
    const { t, isMobile, isTablet, order_by, order_direction, hiveList, actionList, yardName, status } = this.props;

    let sort = {
      order_by,
      order_direction,
    };

    let list = hiveList && !isEmptyArray(hiveList) && order_direction === 'DESC' ? [...hiveList].reverse() : hiveList;

    if (!hiveList) return <Loading />;

    return (
      <BeeTrackHivesListView
        t={t}
        yard={yardName}
        setRef={this.ref}
        sort={sort}
        isMobile={isMobile}
        isTablet={isTablet}
        hiveList={list}
        handleSort={this.handleSort}
        actionList={actionList}
        status={status}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  hiveList: state.beeTrackHiveListReducer[props.status].sortedHiveList,
  order_by: state.beeTrackHiveListReducer[props.status].order_by,
  order_direction: state.beeTrackHiveListReducer[props.status].order_direction,
  yardId: state.beeTrackHiveListReducer[props.status].yardId,
});

const mapDispatchToProps = (dispatch) => ({
  sortHiveList: (status, order_by, order_direction) => {
    dispatch(handleSortHiveList(status, order_by, order_direction));
  },
  fetchHhtHives: (id, status, actionList) => {
    dispatch(makeFetchHiveListRequestThunk(id, status, actionList));
  },
  makeOpenHiveModalAction: (action) => {
    dispatch(makeOpenHiveModalAction(action));
  },
});

BeeTrackHivesList.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  actionList: PropTypes.array.isRequired,
  yardName: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['alive', 'deadout']),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BeeTrackHivesList)));
