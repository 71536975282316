import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { ArrowUp } from '@components/common/Icon/presets/ArrowUp';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import MetricCard from '@components/yard/MetricCard';
import { useMetricConfigGetter, useMetricsLoader } from '@components/yard/MetricCarrousel/hooks';
import MetricModal from '@components/yard/MetricModal';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useSessionStorageState } from '@helpers/Storage/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenMetricModal } from '@redux/Yards/actions';
import { MetricValue } from '@redux/Yards/types';

import { CardType } from '../MetricCard/type';

import {
  ArrowButton,
  CardsContainer,
  CardWrapper,
  CarrouselContainer,
  EmptyView,
  StyledToggleMetricsButton,
} from './style';

const MIN_CARD_WIDTH = 230;

const MetricCarrousel: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const { isFetching, cards } = useSelector((state) => state.yardsReducer.metrics);

  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [visibleCardsCount, setVisibleCardCount] = useState(0);
  const [leftmostCardIndex, setLeftmostCardIndex] = useState(0);
  const [metricsState, setMetricsState] = useSessionStorageState('metrics-storage-state', { isVisible: true });

  const leftmostCardMaxIndex = Math.max(cards.length - visibleCardsCount, 0);
  const canSlideLeft = leftmostCardIndex > 0;
  const canSlideRight = leftmostCardIndex < leftmostCardMaxIndex;

  useMetricsLoader({ isEnabled: metricsState.isVisible });

  const getMetricConfig = useMetricConfigGetter();
  const openTrendModal = useCallback(
    (
      metricName: string,
      metricValue: MetricValue[],
      positiveDirection: boolean,
      cardType: CardType,
      categories: string[] | null
    ) => {
      Analytics.sendEvent({ event: AnalyticsEventType.METRIC_CHART_VIEW, eventData: { metric: metricName } });
      dispatch(makeOpenMetricModal(metricName, metricValue, positiveDirection, cardType, categories));
    },
    [dispatch]
  );

  const toggleMetricsVisibility = useCallback(() => {
    setMetricsState((curr) => ({ ...curr, isVisible: !curr.isVisible }));
  }, [setMetricsState]);

  useLayoutEffect(() => {
    const handleCardWidth = () => {
      if (cardsContainerRef.current) {
        const { width } = cardsContainerRef.current.getBoundingClientRect();
        const visibleCardCount = Math.min(Math.floor(width / MIN_CARD_WIDTH), cards.length);
        const cardsWidth = Math.floor(width / visibleCardCount);
        setVisibleCardCount(visibleCardCount);
        setCardWidth(cardsWidth);
      }
    };

    const cardsContainerElement = cardsContainerRef.current;
    if (cardsContainerElement) {
      const resizeObserver = new ResizeObserver(handleCardWidth);
      resizeObserver.observe(cardsContainerElement);
      return () => resizeObserver.unobserve(cardsContainerElement);
    }
  }, [cards.length]);

  const slideLeft = useCallback(() => {
    setLeftmostCardIndex((curr) => Math.max(curr - 1, 0));
  }, []);

  const slideRight = useCallback(() => {
    setLeftmostCardIndex((curr) => Math.min(curr + 1, leftmostCardMaxIndex));
  }, [leftmostCardMaxIndex]);

  useEffect(() => {
    setLeftmostCardIndex((curr) => Math.min(curr, leftmostCardMaxIndex));
  }, [leftmostCardMaxIndex]);

  const isCardHidden = useCallback(
    (cardIndex: number) => {
      return cardIndex < leftmostCardIndex || cardIndex >= leftmostCardIndex + visibleCardsCount;
    },
    [leftmostCardIndex, visibleCardsCount]
  );

  const isCalculatingCardsWidth = cardWidth === 0;
  const isLoading = isFetching || isCalculatingCardsWidth;
  const isEmpty = cards.length === 0;

  return (
    <Box fit column stretch>
      <CarrouselContainer $isVisible={metricsState.isVisible}>
        <ArrowButton onClick={slideLeft} disabled={!canSlideLeft}>
          <ArrowLeft size={18} />
        </ArrowButton>
        <CardsContainer ref={cardsContainerRef}>
          {isLoading ? (
            <Loading />
          ) : isEmpty ? (
            <EmptyView fit center>
              <Text typography={'SmallParagraph'}>{t('no_result')}</Text>
            </EmptyView>
          ) : (
            cards.map((card, index) => (
              <CardWrapper
                key={card.metricName}
                $width={cardWidth}
                $index={index - leftmostCardIndex}
                $hidden={isCardHidden(index)}
                $animate={index < visibleCardsCount}
              >
                <MetricCard
                  onClick={() =>
                    !card.isFetching
                      ? openTrendModal(
                          card.metricName,
                          getMetricConfig({ ...card }).metricValue,
                          getMetricConfig({ ...card }).positiveDirection,
                          getMetricConfig({ ...card }).cardType,
                          getMetricConfig({ ...card }).categories
                        )
                      : undefined
                  }
                  {...card}
                  {...getMetricConfig({ ...card })}
                />
              </CardWrapper>
            ))
          )}
        </CardsContainer>
        <ArrowButton onClick={slideRight} disabled={!canSlideRight}>
          <ArrowRight size={18} />
        </ArrowButton>
        <MetricModal />
      </CarrouselContainer>
      <StyledToggleMetricsButton $areMetricsVisible={metricsState.isVisible} onClick={toggleMetricsVisibility}>
        <Box alignItems={'center'}>
          <Text typography={'CaptionSmall'}>{metricsState.isVisible ? t('hide_metrics') : t('show_metrics')}</Text>{' '}
          <ArrowUp size={16} />
        </Box>
      </StyledToggleMetricsButton>
    </Box>
  );
};

export default MetricCarrousel;
