import { useCallback, useState } from 'react';

import { useGridApi } from '@components/yard/YardsList/hooks/useGridApi';
import { useYardsListDataCycle } from '@components/yard/YardsList/hooks/useYardsListDataCycle';

export function useYardsListEmptyState() {
  const [isListEmpty, setIsListEmpty] = useState(false);
  const { gridApi } = useGridApi();

  useYardsListDataCycle(gridApi, {
    onDataServerLoad: useCallback(() => {
      setIsListEmpty(gridApi?.getInfiniteRowCount() === 0);
    }, [gridApi]),
    onDataServerReload: useCallback(() => {
      setIsListEmpty(gridApi?.getInfiniteRowCount() === 0);
    }, [gridApi]),
  });

  return { isListEmpty };
}
