import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeFetchOperationOnceThunk } from '@redux/Operation/actions';
import { RootState } from '@redux/Root/types';
import { FetchState } from '@redux/types';

const fetchOperationThunk = makeFetchOperationOnceThunk();

export function useOperation(): BeeOperation | null {
  useOperationFetcher();

  return useSelector<RootState, BeeOperation | null>((state) => state.operationReducer.operation);
}

export function useOperationFetcher(): FetchState {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOperationThunk);
  }, [dispatch]);

  const { isFetching, hasFetchError } = useSelector<RootState, FetchState>((state) => state.operationReducer);
  return { isFetching, hasFetchError };
}
