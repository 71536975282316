import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useGoogle, useGoogleMap } from '@components/common/Map/apis/GoogleMaps/hooks';
import { MarkerProps } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';

type Google = typeof google;

export function MarkerRenderer(props: MarkerProps) {
  const { children, onFirstPlacementComplete } = props;

  const google = useGoogle();
  const map = useGoogleMap();
  const markerRef = useRef<any>(null);
  const [markerInstance, setMarkerInstance] = useState<any>(null);

  const startDrawing = useCallback(() => {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      map,
    });
    const listener = drawingManager.addListener('markercomplete', (marker: google.maps.Marker) => {
      marker.setMap(null);
      listener.remove();
      drawingManager.setMap(null);
      const pos = marker.getPosition()?.toJSON();
      if (pos && onFirstPlacementComplete) {
        onFirstPlacementComplete(pos);
      }
    });
    return () => {
      listener.remove();
      drawingManager.setMap(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, map]);

  useLayoutEffect(() => {
    if (!markerRef.current) {
      markerRef.current = markerInstance || createMarker(google, map, props);
      setMarkerInstance(markerRef.current);
    }

    markerRef.current.updateProps(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, map, props]);

  useLayoutEffect(() => {
    if (!props.position) {
      return startDrawing();
    }
  }, [props.position, startDrawing]);

  useLayoutEffect(() => {
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
    };
  }, []);

  return !props.icon && markerInstance ? createPortal(children, markerInstance.content) : null;
}

MarkerRenderer.displayName = 'MarkerRenderer';

function createMarker(google: Google, map: google.maps.Map, markerProps: MarkerProps) {
  return markerProps.icon ? createImageMarker(google, map) : createHTMLMarker(google, map);
}

function createImageMarker(google: Google, map: google.maps.Map) {
  class CustomMarker extends google.maps.Marker {
    unbinders = [] as Array<() => void>;
    markerProps: MarkerProps | null = null;

    bindListener(event: string, handler: (...args: any) => void) {
      const subs = this.addListener(event, handler);
      this.unbinders.push(() => subs.remove());
    }

    unbindAllListeners() {
      this.unbinders.map((u) => u());
      this.unbinders = [];
    }

    updateProps(nextProps: MarkerProps) {
      if (areMarkerPropsEqual(this.markerProps, nextProps)) {
        return;
      }
      this.markerProps = nextProps;
      this.setIcon(nextProps.icon);
      this.setPosition(nextProps.position);
      this.setZIndex(nextProps.zIndex);
      this.unbindAllListeners();
      this.bindListener('click', () => nextProps.onClick && nextProps.onClick());
    }
  }
  return new CustomMarker({ map });
}

function createHTMLMarker(google: Google, map: google.maps.Map): google.maps.OverlayView {
  class CustomMarker extends google.maps.OverlayView {
    position = { lat: 0, lng: 0 };
    interacting = false;
    didMove = false;
    dragging = false;
    draggingOrigin = { x: 0, y: 0 };
    draggingPosition = { lat: 0, lng: 0 };
    unbinders = [] as Array<() => void>;
    markerProps: MarkerProps | null = null;

    constructor(public content: HTMLElement) {
      super();
      this.setMap(map);
    }

    bindListener(element: HTMLElement, event: string, handler: (...args: any) => void) {
      element.addEventListener(event, handler);
      this.unbinders.push(() => element.removeEventListener(event, handler));
    }

    unbindAllListeners() {
      this.unbinders.map((u) => u());
      this.unbinders = [];
    }

    updateProps(nextProps: MarkerProps) {
      if (areMarkerPropsEqual(this.markerProps, nextProps)) {
        return;
      }

      this.markerProps = nextProps;
      const { position, draggable, onPositionChange, onDraggingChange, onClick } = nextProps;

      this.unbindAllListeners();

      this.position = position || { lat: 0, lng: 0 };
      this.content.style.visibility = 'visible';
      this.content.style.position = 'absolute';
      this.content.style.width = '0px';
      this.content.style.height = '0px';
      this.content.style.display = 'flex';
      this.content.style.alignItems = 'center';
      this.content.style.justifyContent = 'center';
      this.content.style.cursor = draggable ? 'grab' : 'pointer';

      this.bindListener(this.content, 'click', (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
      });

      this.bindListener(this.content, 'mousedown', (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.didMove = false;
        this.interacting = true;
        (map as any).skipInteractions = true;

        if (draggable) {
          map.set('draggable', false);
          this.dragging = true;
          this.draggingOrigin = { x: event.clientX, y: event.clientY };
          this.draggingPosition = { ...this.position };
          this.content.style.cursor = 'grabbing';
        }
      });

      this.bindListener(map.getDiv(), 'mousemove', (event) => {
        if (this.interacting && this.dragging) {
          this.didMove = true;
          onDraggingChange && onDraggingChange(true);
          const screenPos = this.getProjection().fromLatLngToDivPixel(this.position);

          if (screenPos) {
            const nextScreenPos = new google.maps.Point(
              screenPos.x - (this.draggingOrigin.x - event.clientX),
              screenPos.y - (this.draggingOrigin.y - event.clientY)
            );
            const nextPosition = this.getProjection().fromDivPixelToLatLng(nextScreenPos)?.toJSON();
            if (nextPosition) {
              this.draggingPosition = { ...nextPosition };
              this.draw();
            }
          }
        }
      });

      ['mouseup', 'mouseleave'].forEach((ev) =>
        this.bindListener(map.getDiv(), ev, () => {
          if (this.interacting) {
            if (this.didMove && this.dragging) {
              onDraggingChange && onDraggingChange(false);
              onPositionChange && onPositionChange({ ...this.draggingPosition });

              this.content.style.cursor = 'grab';
              this.position = { ...this.draggingPosition };
            } else if (!this.didMove) {
              onClick && onClick();
              this.content.style.cursor = 'pointer';
            }

            this.dragging = false;
            this.interacting = false;
            map.set('draggable', true);
            this.content.style.pointerEvents = 'auto';
            (map as any).skipInteractions = false;
          }
        })
      );

      this.draw();
    }

    onAdd() {
      const panes = this.getPanes();
      panes?.floatPane.appendChild(this.content);
    }

    draw() {
      const position = this.dragging ? this.draggingPosition : this.position;
      const projection = this.getProjection();
      if (position && projection) {
        const screenPosition = projection.fromLatLngToDivPixel(position);
        if (screenPosition) {
          const { x, y } = screenPosition;
          this.content.style.transform = `translate(${x}px, ${y}px)`;
          this.content.style.zIndex = (this.markerProps?.zIndex ?? y * 10).toFixed();
        }
      }
    }

    onRemove() {
      this.content.remove();
    }

    hide() {
      this.content.style.visibility = 'hidden';
    }

    show() {
      this.content.style.visibility = 'visible';
    }

    toggle() {
      if (this.content.style.visibility === 'hidden') {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  return new CustomMarker(document.createElement('div')) as any;
}

function areMarkerPropsEqual(prevProps: MarkerProps | null, nextProps: MarkerProps | null) {
  return (
    MapUtil.areCoordinatesEquals(prevProps?.position, nextProps?.position) &&
    prevProps?.draggable === nextProps?.draggable &&
    prevProps?.onPositionChange === nextProps?.onPositionChange &&
    prevProps?.onDraggingChange === nextProps?.onDraggingChange &&
    prevProps?.onClick === nextProps?.onClick
  );
}
