// vendor
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, StyledComponent } from 'styled-components';

// nectar
import { HiveStatusChipProps } from '@components/common/HiveStatusChip/types';
import { hiveStatusIcon } from '@components/common/Icon/presets/HiveStatus';

import { ChipDeadOut, ChipIcon, ChipIntroduced, ChipQueenLess, ChipQueenRight, ChipRetired } from './styles';

const PRESETS: Record<HiveStatus, StyledComponent<'div', DefaultTheme>> = {
  introduced: ChipIntroduced,
  queenright: ChipQueenRight,
  queenless: ChipQueenLess,
  deadout: ChipDeadOut,
  retired: ChipRetired,
  removed: ChipRetired,
};

export const HiveStatusChip: React.FC<HiveStatusChipProps> = ({ status }) => {
  const { t } = useTranslation();
  const CompPreset = useMemo(() => PRESETS[status], [status]);
  const Icon = useMemo(() => hiveStatusIcon[status], [status]);

  if (!Icon || !CompPreset) {
    return null;
  }

  return (
    <CompPreset>
      <ChipIcon>
        <Icon size={16} />
      </ChipIcon>
      {t(status)}
    </CompPreset>
  );
};
