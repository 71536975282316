import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Notes = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6H20C20.5523 6 21 6.44772 21 7V7.01C21 7.56228 20.5523 8.01 20 8.01H4C3.44772 8.01 3 7.56228 3 7.01V7C3 6.44772 3.44772 6 4 6ZM20 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11ZM4 18H14C14.5523 18 15 17.5523 15 17C15 16.4477 14.5523 16 14 16H4C3.44772 16 3 16.4477 3 17C3 17.5523 3.44772 18 4 18Z"
      />
    </IconSVGView>
  );
};
