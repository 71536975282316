import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Close } from '@components/common/Icon';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import { YardName } from '@components/common/YardName';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import {
  StyledContentTransition,
  StyledFooter,
  StyledFooterButton,
  StyledHeader,
  StyledHeaderCloseButton,
  StyledLoaderWrapper,
  StyledPane,
  StyledPaneScroll,
  StyledSection,
  StyledYardTitle,
} from '@components/yard/YardsMapDetailsPane/styles';
import { YardsMapDetailsPaneActions } from '@components/yard/YardsMapDetailsPane/YardsMapDetailsPaneActions';
import { YardsMapDetailsPaneSummary } from '@components/yard/YardsMapDetailsPane/YardsMapDetailsPaneSummary';
import { YardsMapDetailsPaneVisit } from '@components/yard/YardsMapDetailsPane/YardsMapDetailsPaneVisit';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeOpenUpdateYardModalAction } from '@redux/deprecated/actions';

export const YardsMapDetailsPane: React.FC = ({ children }) => {
  const { selectedYard, selectedYardDetail, setSelectedYard, retrieveSelectedYard, isFetchingDetail } = useYardsMap();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasSelectedYard = !!selectedYard;
  const clearYard = useCallback(() => setSelectedYard(null), [setSelectedYard]);

  const goToYardDetails = useCallback(() => {
    if (selectedYard) {
      window.open(generatePath(APP.routes.yard, { uid: selectedYard.id }));
    }
  }, [selectedYard]);

  const openUpdateYardModal = useCallback(() => {
    if (selectedYard) {
      dispatch(
        makeOpenUpdateYardModalAction({
          modalType: 'update-yard',
          yard: selectedYard,
          onUpdated: () => retrieveSelectedYard(),
        })
      );
    }
  }, [dispatch, selectedYard, retrieveSelectedYard]);

  useEffect(() => {
    if (hasSelectedYard) {
      Analytics.sendEvent({ event: AnalyticsEventType.YARDS_MAP_LEFT_PANE_VIEW });
    }
  }, [hasSelectedYard]);

  return (
    <StyledPane contractMapToExpand hideToggle expanded={hasSelectedYard}>
      {children}

      <StyledHeader>
        <Text typography={'Heading3'} weight={'600'}>
          {t('yard_selected')}
        </Text>
        <StyledHeaderCloseButton onClick={clearYard}>
          <Close size={24} />
        </StyledHeaderCloseButton>
      </StyledHeader>

      <StyledPaneScroll>
        <StyledContentTransition content={selectedYardDetail}>
          {(yard) => (
            <StyledYardTitle>
              <YardName
                name={yard.name}
                contractName={yard.contractName}
                typography={'Heading2'}
                weight={'700'}
                color={'coreWhite'}
                disableTruncation
              />
            </StyledYardTitle>
          )}
        </StyledContentTransition>

        <StyledContentTransition content={selectedYardDetail}>
          {(yard) => (
            <>
              <StyledSection>
                <YardsMapDetailsPaneSummary {...yard} />
              </StyledSection>
              <StyledSection>
                <YardsMapDetailsPaneActions {...yard} />
              </StyledSection>
            </>
          )}
        </StyledContentTransition>

        <StyledContentTransition content={selectedYardDetail?.lastVisitInspections}>
          {(lastVisitInspections) => (
            <StyledSection $last>
              <YardsMapDetailsPaneVisit lastVisitInspections={lastVisitInspections} />
            </StyledSection>
          )}
        </StyledContentTransition>

        {isFetchingDetail && (
          <StyledLoaderWrapper>
            <Loading />
          </StyledLoaderWrapper>
        )}
      </StyledPaneScroll>

      <StyledFooter>
        <StyledFooterButton onClick={openUpdateYardModal} flat>
          {t('edit_yard')}
        </StyledFooterButton>
        <StyledFooterButton onClick={goToYardDetails}>{t('view_details')}</StyledFooterButton>
      </StyledFooter>
    </StyledPane>
  );
};
