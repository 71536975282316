import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PRESETS } from '../Text/styles';

import { StyledCTAProps } from './types';

const unstyledStyles = css`
  border: none;
  margin: 0;
  padding: 0;
  background-color: initial;
  text-align: initial;
  text-decoration: none;

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.coreFocus01};
    outline: 1px solid ${({ theme }) => theme.colors.coreFocus02};
  }
`;

const paddingHorizontalStyles = css(
  ({ theme }) => css`
    padding-left: ${theme.spacing.sm}px;
    padding-right: ${theme.spacing.sm}px;
  `
);

const paddingVerticalStyles = css(
  ({ theme }) => css`
    padding-top: ${theme.spacing.xs}px;
    padding-bottom: ${theme.spacing.xs}px;
  `
);

const sharedStyles = css(
  ({ theme }) => css`
    border-radius: ${theme.spacing.xxs}px;
    font-size: ${PRESETS.CTA.fontSize};
    line-height: ${PRESETS.CTA.lineHeight};
    font-weight: 600;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    position: relative;

    &:focus-visible {
      border-color: ${theme.colors.coreFocus01};
      outline: 1px solid ${theme.colors.coreFocus02};
    }

    &:disabled {
      cursor: default;
    }
  `
);
const withIconLeftStyles = css(
  ({ theme }) => css`
    & > svg {
      margin-right: ${theme.spacing.xs}px;
      height: ${theme.spacing.sm}px;
      width: ${theme.spacing.sm}px;
    }
  `
);
const withIconRightStyles = css(
  ({ theme }) => css`
    & > svg {
      margin-left: ${theme.spacing.xs}px;
      height: ${theme.spacing.sm}px;
      width: ${theme.spacing.sm}px;
    }
  `
);

const primaryStyles = css(
  ({ theme }) => css`
    color: ${theme.colors.coreWhite};
    background-color: ${theme.colors.grey08};

    &:hover {
      background-color: ${theme.colors.grey07};
    }

    &:disabled {
      color: ${theme.colors.grey06};
      background-color: ${theme.colors.grey05};
    }
  `
);

const secondaryStyles = css(
  ({ theme }) => css`
    color: ${theme.colors.grey08};
    background-color: transparent;
    border-color: ${theme.colors.grey08};

    &:hover {
      color: ${theme.colors.coreWhite};
      background-color: ${theme.colors.grey08};
      border-color: transparent;
    }

    &:disabled {
      color: ${theme.colors.grey06};
      background-color: transparent;
      border-color: ${theme.colors.grey06};
    }
  `
);

const tertiaryStyles = css(
  ({ theme }) => css`
    color: ${theme.colors.grey08};
    text-decoration: underline;
    padding: ${theme.spacing.xxs}px;

    &:hover {
      color: ${theme.colors.grey07};
    }

    &:disabled {
      color: ${theme.colors.grey06};
    }
  `
);

const elevatedStyles = css(
  ({ theme }) => css`
    color: ${theme.colors.grey08};
    background-color: ${theme.colors.coreWhite};
    box-shadow: ${theme.shadows.boxShadow01};

    &:hover {
      color: ${theme.colors.grey08};
      background-color: ${theme.colors.grey01};
      box-shadow: ${theme.shadows.boxShadow02};
    }

    &:focus-visible {
      box-shadow: ${theme.shadows.boxShadow02};
    }

    &:disabled {
      color: ${theme.colors.grey06};
      background-color: ${theme.colors.grey05};
      box-shadow: initial;
    }
  `
);

const roundedStyles = css(
  ({ theme }) => css`
    border-radius: 100px;
    box-shadow: ${theme.shadows.boxShadow03};
  `
);

const ctaStyles = css<StyledCTAProps>(
  ({
    theme,
    $unstyled,
    $primary,
    $secondary,
    $tertiary,
    $elevated,
    $rounded,
    $withIconLeft,
    $withIconRight,
    $paddingHorizontal,
    $paddingVertical,
  }) => {
    if ($unstyled) return unstyledStyles;

    return css`
      ${sharedStyles}

      ${$paddingHorizontal && paddingHorizontalStyles}
      ${$paddingVertical && paddingVerticalStyles}
   
      ${$primary && primaryStyles}
      ${$secondary && secondaryStyles}
      ${$tertiary && tertiaryStyles}
      ${$elevated && elevatedStyles}
      ${$rounded && roundedStyles}
      ${$withIconLeft && withIconLeftStyles}
      ${$withIconRight && withIconRightStyles}
    `;
  }
);

export const StyledButton = styled.button`
  ${ctaStyles}
`;

export const StyledAnchor = styled.a`
  ${ctaStyles}
  display: inline-flex; // no idea why the anchor is taking the full width of the parent, with the same styles as the button
`;

export const StyledNavLink = styled(NavLink)`
  ${ctaStyles}
`;

// todo: will implement this later if it makes sense to
// export const StyledLink = styled(Link)`
//   ${ctaStyles}
// `;
