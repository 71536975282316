import { useSelector } from 'react-redux';

import { getDetailedActivitySet } from '@redux/Timeline/reducer';
import { ActivityFilters, TimelineState } from '@redux/Timeline/types';

export function useDetailedActivitySet(filters: ActivityFilters | null) {
  const { isFetchingDetailedActivitySets, detailedActivitySets } = useSelector((state) => state.timelineReducer);

  const detailedActivitySet = filters
    ? getDetailedActivitySet({ detailedActivitySets } as TimelineState, filters)
    : null;

  return { isFetchingDetailedActivitySets, detailedActivitySet };
}
