import { Color } from 'styled-components';

/**
 * Defines which spec to be applied to a yard based on how
 * many days since it's last inspection.
 * */
export interface LastInspectionIntervalSpec {
  /** Unique key for a spec, used in the filter endpoint. */
  key: LastInspectionIntervalKey;

  /** Translation key */
  labelKey: string;

  /** Translation key for the tooltip */
  tooltipLabelKey: string;

  /** Interval this spec applies to.  */
  interval: [number, number];

  /** Color used to define this interval. */
  color: Color;

  /** Defines which specs appear at front/top. */
  priority: number;

  /** Defines in which order each interval should appear when listed */
  order: number;
}

export enum LastInspectionIntervalKey {
  BETWEEN_00_15 = 'between_00_15',
  BETWEEN_16_30 = 'between_16_30',
  BETWEEN_31_90 = 'between_31_90',
  BETWEEN_91_INF = 'between_91_inf',
}
