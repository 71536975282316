import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Add } from '@components/common/Icon/presets/Add';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import AssignYardsGroupsModal from '@components/deprecated/DynamicModals/AssignYardsGroupsModal';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { getGroupColor } from '@helpers/deprecated/color';
import { Sorting } from '@helpers/Sorting';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useGroups, useGroupsFetcher } from '@hooks/useGroups';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenAssignYardsGroupsModalAction } from '@redux/deprecated/actions';
import { makeYardDetailsFetchThunk } from '@redux/YardDetails/actions';

export const YardDetailsDashboardGroups: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const { isFetching: isFetchingGroups } = useGroupsFetcher();
  const groups = useGroups();

  const yardGroups = useMemo(() => {
    return Sorting.sortAlphanumerically(
      groups
        .filter(({ id }) => yard?.groupIds.includes(id))
        .map((group) => ({ ...group, color: getGroupColor(groups ?? [], group.id) })),
      'name'
    );
  }, [yard, groups]);

  const openGroupAssignModal = useCallback(() => {
    if (!yard) {
      return;
    }

    const backwardCompatibleYard = CaseAdapter.objectToSnakeCase(yard);

    dispatch(
      makeOpenAssignYardsGroupsModalAction({
        modalType: 'assign-yard-groups',
        yard: backwardCompatibleYard,
        onAssign: () => dispatch(makeYardDetailsFetchThunk(yard.id)),
      })
    );
  }, [dispatch, yard]);

  if (!yard) {
    return null;
  }

  return (
    <Box column paddingVerticalSM paddingHorizontalMD gapXXS>
      <Box alignItems={'center'} justifyContent={'space-between'}>
        <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
          {t('groups')}
        </Text>

        <Button tertiary withIconLeft onClick={openGroupAssignModal}>
          <Add />
          {t('assign_group')}
        </Button>
      </Box>

      <Box wrap alignItems={'center'} gapXS>
        {yardGroups.map(({ id, name, color }) => (
          <Box key={id} paddingXS borderWidth={1} borderColor={color} paper01>
            <Text typography={'CaptionSmall'}>{name}</Text>
          </Box>
        ))}
      </Box>

      <LoadingSkeleton visible={isFetchingGroups} backgroundColor={'coreWhite'} absolutelyFitParent>
        <LoadingSkeletonRectangle width={'100%'} height={'100%'} />
      </LoadingSkeleton>

      <AssignYardsGroupsModal />
    </Box>
  );
};
