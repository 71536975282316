import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton, PrimaryButtonWithIcon } from '@components/common/Button';
import { Download } from '@components/common/Icon/presets/Download';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { Checkbox } from '@components/form/CheckBox';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';
import { useContractId } from '@hooks/useContract';

type ExportLanguageOptions = { [K in BeeSupportedLangCode as `worker-${K}`]: boolean };
type ExportLanguageOptionKey = keyof ExportLanguageOptions;
type ExportOptions = { manager: boolean } & ExportLanguageOptions;
type ExportOptionKey = keyof ExportOptions;

const DEFAULT_EXPORT_LANGUAGE_OPTIONS: ExportLanguageOptions = {
  'worker-en': true,
  'worker-es': true,
  // Add more language keys if necessary.
};
const DEFAULT_EXPORT_OPTIONS: ExportOptions = {
  ...DEFAULT_EXPORT_LANGUAGE_OPTIONS,
  manager: true,
};

const EXPORT_LANGUAGE_OPTION_KEYS = Object.keys(DEFAULT_EXPORT_LANGUAGE_OPTIONS) as Array<ExportLanguageOptionKey>;
const EXPORT_OPTION_KEYS = Object.keys(DEFAULT_EXPORT_OPTIONS) as Array<ExportOptionKey>;

const StyledTooltip = styled(Tooltip)(
  ({ theme }) => `
  box-shadow: ${theme.shadows.boxShadow02};
`
);

const StyledCheckBoxRow = styled(Box)<{ secondary?: boolean }>(
  ({ theme, secondary }) => `
  align-items: center;
  margin-left: ${secondary ? theme.spacing.md : '0'}px;
  padding-left: ${secondary ? theme.spacing.xs : '0'}px;
  
  &:nth-child(2) {
    border-bottom: 1px solid ${theme.colors.grey03};
  }
  
  p {
    margin-left: ${theme.spacing.xxs}px;
    padding-left: ${theme.spacing.xs}px;
  }
`
);

export const ExportPDFButton: React.VFC = () => {
  const { t } = useTranslation();
  const contractId = useContractId();
  const history = useHistory();

  const [exportOptions, setExportOptions] = useState<ExportOptions>(DEFAULT_EXPORT_OPTIONS);

  const hasAnyOptionEnabled = useMemo(() => EXPORT_OPTION_KEYS.some((key) => exportOptions[key]), [exportOptions]);
  const hasAnyLanguageOptionEnabled = useMemo(
    () => EXPORT_LANGUAGE_OPTION_KEYS.some((key) => exportOptions[key]),
    [exportOptions]
  );

  const handleToggle = useCallback((value: boolean, key: string) => {
    setExportOptions((current) =>
      produce<ExportOptions>(current, (current) => {
        // If updating worker, also update all languages.
        if (key === 'worker') {
          EXPORT_LANGUAGE_OPTION_KEYS.forEach((k) => (current[k] = value));
        } else {
          current[key as ExportOptionKey] = value;
        }
      })
    );
  }, []);

  const goToExportPage = useCallback(() => {
    const path = URLUtil.buildURL(APP.routes.pollinationContractPrintable, {
      id: contractId,
      type: Object.entries(exportOptions)
        .filter(([key, value]) => value && EXPORT_OPTION_KEYS.includes(key as ExportOptionKey))
        .map(([key]) => key)
        .join(','),
    });
    history.push(path);
  }, [contractId, exportOptions, history]);

  return (
    <Box marginRightSM>
      <PrimaryButtonWithIcon>
        <Download size={24} />
        <span>{t('export')}</span>

        <StyledTooltip light interactive placement={'bottom-end'}>
          <Box column stretch paddingSM>
            <Text typography={'Legend'} weight={'600'} color={'grey06'}>
              {t('pollination_pdf_export')}
            </Text>
            <StyledCheckBoxRow paddingVerticalSM>
              <Checkbox value={exportOptions.manager} name={'manager'} onChange={handleToggle} />
              <Text typography={'SmallParagraph'}>{t('pollination_pdf_manager_contract')}</Text>
            </StyledCheckBoxRow>
            <StyledCheckBoxRow paddingTopSM>
              <Checkbox value={hasAnyLanguageOptionEnabled} name={'worker'} onChange={handleToggle} />
              <Text typography={'SmallParagraph'}>{t('pollination_pdf_worker_contract')}</Text>
            </StyledCheckBoxRow>
            <StyledCheckBoxRow secondary paddingTopXS>
              <Checkbox value={exportOptions['worker-en']} name={'worker-en'} onChange={handleToggle} />
              <Text typography={'SmallParagraph'}>{t('lang_english')}</Text>
            </StyledCheckBoxRow>
            <StyledCheckBoxRow secondary paddingTopXS paddingBottomMD>
              <Checkbox value={exportOptions['worker-es']} name={'worker-es'} onChange={handleToggle} />
              <Text typography={'SmallParagraph'}>{t('lang_spanish')}</Text>
            </StyledCheckBoxRow>
            <PrimaryButton disabled={!hasAnyOptionEnabled} onClick={goToExportPage}>
              {t('export')}
            </PrimaryButton>
          </Box>
        </StyledTooltip>
      </PrimaryButtonWithIcon>
    </Box>
  );
};
