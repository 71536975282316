import { Reducer } from 'redux';

import { FetchState } from '@redux/types';

export interface AssignContractYard {
  name: string;
  id: number;
  contractName: string | null;
}

export type AssignContractState = FetchState<{
  isAssignContractModalOpen: boolean;
  selectedYards: Array<AssignContractYard>;
}>;

export type AssignContractAction =
  | { type: AssignContractActionType.OPEN_ASSIGN_CONTRACT_MODAL; payload: Array<AssignContractYard> }
  | { type: AssignContractActionType.CLOSE_ASSIGN_CONTRACT_MODAL }
  | {
      type:
        | AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_START
        | AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_FAILURE
        | AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_SUCCESS;
    };

export enum AssignContractActionType {
  OPEN_ASSIGN_CONTRACT_MODAL = 'OPEN_ASSIGN_CONTRACT_MODAL',
  CLOSE_ASSIGN_CONTRACT_MODAL = 'CLOSE_ASSIGN_CONTRACT_MODAL',
  PATCH_ASSIGN_CONTRACT_BATCH_START = 'PATCH_ASSIGN_CONTRACT_BATCH_START',
  PATCH_ASSIGN_CONTRACT_BATCH_SUCCESS = 'PATCH_ASSIGN_CONTRACT_BATCH_SUCCESS',
  PATCH_ASSIGN_CONTRACT_BATCH_FAILURE = 'PATCH_ASSIGN_CONTRACT_BATCH_FAILURE',
}

export type AssignContractReducer = Reducer<AssignContractState, AssignContractAction>;
