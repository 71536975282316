import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Close } from '@components/common/Icon';
import { Pollination } from '@components/common/Icon/presets/Pollination';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapMode } from '@components/pollination/PolliMap/types';
import {
  SummaryClose,
  SummaryContent,
  SummaryFooter,
  SummaryHeader,
  SummaryHeaderTitle,
  SummaryPaddedRow,
  SummaryRow,
  SummaryTitle,
} from '@components/pollination/PolliMapPaneSummary/styles';

import { StyledButtonFlat, StyledButtonPrimary } from '../PolliMap/styles';

export const PolliMapPaneBlock: React.VFC = () => {
  const { t } = useTranslation();
  const {
    selectedBlock,
    removeBlock,
    setMode,
    clearAnyElementSelection,
    isManaging,
    isManagingABlock,
    isDraggingElements,
  } = usePolliMap();

  const goToEdit = useCallback(() => {
    setMode(PolliMapMode.MANAGING_A_BLOCK);
  }, [setMode]);

  if (isDraggingElements || isManagingABlock || !selectedBlock) {
    return null;
  }

  return (
    <Box column stretch fit>
      <SummaryHeader>
        <SummaryHeaderTitle typography={'Heading3'} weight={'600'}>
          {t('selected')}
        </SummaryHeaderTitle>
        <SummaryClose onClick={clearAnyElementSelection} aria-label={t('close')}>
          <Close size={24} />
        </SummaryClose>
      </SummaryHeader>

      <SummaryContent column stretch fit>
        <SummaryRow>
          <Pollination size={16} color={'grey08'} />
          <SummaryTitle typography={'Heading3'} weight={'600'}>
            {t('pollination_block_name', { name: selectedBlock.name })}
          </SummaryTitle>
        </SummaryRow>
        <SummaryPaddedRow>
          <SummaryTitle typography={'Heading3'} weight={'600'}>
            {t('pollination_block_acres_label')}
          </SummaryTitle>
          <Text typography={'Heading3'}>{selectedBlock.area || '-'}</Text>
        </SummaryPaddedRow>
      </SummaryContent>

      {isManaging ? (
        <SummaryFooter>
          <StyledButtonFlat onClick={() => removeBlock(selectedBlock)}>
            <Text weight={'700'} typography={'CTA'}>
              {t('delete')}
            </Text>
          </StyledButtonFlat>
          <StyledButtonPrimary onClick={goToEdit}>
            <Text weight={'700'} typography={'CTA'}>
              {t('edit')}
            </Text>
          </StyledButtonPrimary>
        </SummaryFooter>
      ) : null}
    </Box>
  );
};
