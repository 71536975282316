import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Menu = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00001 4.99998C2.44772 4.99225 1.99374 5.43369 1.98601 5.98598C1.97827 6.53826 2.41972 6.99225 2.97201 6.99998H21C21.5523 7.00771 22.0063 6.56626 22.014 6.01398C22.0217 5.46169 21.5803 5.00771 21.028 4.99998H3.00001ZM3.00001 11C2.44772 10.9922 1.99374 11.4337 1.98601 11.986C1.97827 12.5383 2.41972 12.9922 2.97201 13H21C21.5523 13.0077 22.0063 12.5663 22.014 12.014C22.0217 11.4617 21.5803 11.0077 21.028 11H3.00001ZM2.12698 17.4879C2.30995 17.181 2.64274 16.995 3.00001 17H21.028C21.3853 17.005 21.7127 17.2002 21.887 17.5121C22.0613 17.824 22.056 18.2052 21.873 18.5121C21.6901 18.819 21.3573 19.005 21 19H2.97201C2.61474 18.995 2.28728 18.7998 2.11298 18.4879C1.93868 18.176 1.94402 17.7948 2.12698 17.4879Z"
      />
    </IconSVGView>
  );
};
