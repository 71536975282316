/**
 * Media query helper functions
 * 
 * Used to make media queries easier to write and read within styled components
 * 
 * @example 
    const StyledBox = styled.div`
      ${from('desktop')} {
        margin-bottom: 2rem;
        background-color: orange;
      }
 *  `
 */

import { BreakPoint, BREAKPOINTS } from './constants';

export function from(bp: BreakPoint) {
  return `@media (min-width: ${BREAKPOINTS[bp]}px)`;
}

export function until(bp: BreakPoint) {
  return `@media (max-width: ${BREAKPOINTS[bp] - 1}px)`;
}

export function between(from: BreakPoint, until: BreakPoint) {
  return `@media ((min-width: ${BREAKPOINTS[from]}px and max-width: ${BREAKPOINTS[until] - 1}px)`;
}
