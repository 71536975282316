import React from 'react';
import PropTypes from 'prop-types';

// vendor:
// nectar:
import { ModalContainer } from '@components/deprecated/Elements/ModalElements';
import { StyledCloseButton, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';

export const DeleteYardModalView = ({ t, isMobile, contentObj, handleSubmit, handleCancel, modalObj, isLoading }) => {
  const { modalType } = contentObj;

  const { title, instructions, nextButton, backButton } = modalObj;

  return (
    <ModalContainer isMobile={isMobile} smallModal={true} disabled={isLoading}>
      <section
        style={{
          padding: 0,
          paddingTop: '3rem',
        }}
      >
        <StyledHeading2
          style={{
            padding: '0 1.5rem',
            marginBottom: '1rem',
          }}
          mobile={isMobile}
        >
          {t(title)}
        </StyledHeading2>

        {instructions && <StyledParagraph style={{ padding: '0 1.5rem 1.5rem' }}>{instructions}</StyledParagraph>}
      </section>

      <footer>
        <div>
          <StyledCloseButton type="button" label={t(backButton)} onClick={handleCancel} disabled={false} />

          <StyledSubmitButton
            type="button"
            style={{
              marginRight: 0,
              width: 'cancel-changes' === modalType && '137px',
            }}
            label={t(nextButton)}
            onClick={handleSubmit}
            disabled={false}
            height="2rem"
          />
        </div>
      </footer>
    </ModalContainer>
  );
};

DeleteYardModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    modalType: PropTypes.string.isRequired,
  }).isRequired,
  modalObj: PropTypes.shape({
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    nextButton: PropTypes.string.isRequired,
    backButton: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};
