import React from 'react';

import { Box } from '@components/common/Box';
import { NavRouterLink } from '@components/common/CTA';
import { FlyingBee } from '@components/common/FlyingBee';
import { StyledLandOn, StyledPageTitle } from '@components/common/Page404/styles';
import { Text } from '@components/common/Text';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';

export const Page404: React.VFC = () => {
  const t = useTranslation();

  return (
    <Box fit center column gapSM backgroundColor={'coreBackground'}>
      <StyledPageTitle>
        404
        <StyledLandOn id="bee-target" />
      </StyledPageTitle>

      <Text
        typography={'Heading3'}
        align={'center'}
        dangerouslySetInnerHTML={{ __html: t('page_not_found_message') }}
      />
      <NavRouterLink to={APP.routes.home} tertiary>
        {t('go_to_home_page')}
      </NavRouterLink>

      <FlyingBee landOn="#bee-target" />
      <FlyingBee landOn="#bee-target" delay={1200} />
    </Box>
  );
};
