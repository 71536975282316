import React from 'react';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { from } from '@style/mediaQueries';

const PageView = styled(Box)`
  margin: 2rem 1rem;

  ${from('mobile')} {
    margin: 4.125rem 3rem;
    max-width: min(max(585px, 50%), 100%);
  }
`;

export const SlimPage: React.FC = ({ children }) => {
  return (
    <PageView column stretch>
      {children}
    </PageView>
  );
};
