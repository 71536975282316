import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import Fuse from 'fuse.js';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Pin } from '@components/common/Icon/presets/Pin';
import { Search } from '@components/common/Icon/presets/Search';
import { Yard } from '@components/common/Icon/presets/Yard';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import APP from '@config/constants';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { HTMLInteractiveElement, useKeyboardNavigation } from '@hooks/useKeyboardNavigation';
import { useTranslation } from '@hooks/useTranslation';
import { makeClearAppliedFiltersAction } from '@redux/YardsFilters/actions';
import { makeGlobalSearchFetchYardsThunk, makeGlobalSearchPushToHistoryAction } from '@redux/YardsGlobalSearch/actions';

import {
  ActiveStatus,
  Spacer,
  StyledClearButton,
  StyledNbHives,
  StyledResult,
  StyledResults,
  StyledResultsDrawer,
  StyledResultsFooter,
  StyledSearch,
  StyledSearchWrapper,
  StyledViewOnMapButton,
  StyledYardButton,
  StyledYardName,
  UnstyledInput,
} from './styles';

const DEFAULT_NUM_RESULTS = 5;

export const GlobalSearch: React.VFC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { goToIndex } = useKeyboardNavigation(wrapperRef.current);
  const [searchValue, setSearchValue] = useState('');
  const [showAllResults, setShowAllResults] = useState(false);

  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const yardsListSummary = useSelector((state) => state.yardsGlobalSearchReducer.yards ?? []);
  const yardsSearchHistory = useSelector((state) => state.yardsGlobalSearchReducer.history ?? []);
  const isLoading = useSelector((state) => state.yardsGlobalSearchReducer.isFetching);

  const searchHistory = useMemo(
    () => yardsListSummary.filter((yard) => yardsSearchHistory.includes(yard.id)),
    [yardsListSummary, yardsSearchHistory]
  );

  // i don't understand what this does
  const searchIndex = useMemo(() => {
    return new Fuse(yardsListSummary, {
      keys: ['contractName', 'name'],
      threshold: 0.2,
    });
  }, [yardsListSummary]);

  const searchResults = useMemo(() => {
    return searchIndex.search(searchValue).map(({ item }) => item);
  }, [searchIndex, searchValue]);

  const haveResults = useMemo(() => searchResults.length > 0, [searchResults]);

  const showHistory = useMemo(() => searchHistory.length > 0 && searchValue === '', [searchHistory, searchValue]);

  const list = useMemo(() => {
    const results = showAllResults ? searchResults : searchResults.slice(0, DEFAULT_NUM_RESULTS);
    return showHistory ? [...searchHistory].reverse() : results;
  }, [searchHistory, searchResults, showAllResults, showHistory]);

  const addYardToSearchHistory = useCallback(
    (yardId) => {
      dispatch(makeGlobalSearchPushToHistoryAction({ id: yardId }));
    },
    [dispatch]
  );

  const clearYardFilters = useCallback(() => {
    dispatch(makeClearAppliedFiltersAction());
  }, [dispatch]);

  const onClearSearch = useCallback(() => {
    setSearchValue('');
    inputRef?.current?.focus();
  }, []);

  const onCancel = useCallback(() => {
    const activeElement = document.activeElement as HTMLInteractiveElement;
    activeElement && activeElement.blur();
    setSearchValue('');
  }, []);

  const onYardSelect = useCallback(
    (yardId: number) => {
      history.push(generatePath(APP.routes.yard, { uid: yardId }));
      addYardToSearchHistory(yardId);
      onCancel();
    },
    [history, addYardToSearchHistory, onCancel]
  );

  const onMapClick = useCallback(
    (yardId) => {
      clearYardFilters();
      addYardToSearchHistory(yardId);
      onCancel();

      // Cleaning and setting selection again to make sure the navigation
      // occurs even if the same item is re-selected.
      history.push(APP.routes.yardsMap);
      setTimeout(() => history.push(APP.routes.yardsMap + `?yard=${yardId}`));
    },
    [history, addYardToSearchHistory, onCancel, clearYardFilters]
  );

  const onSearchFocused = useCallback(() => {
    goToIndex(0);
    dispatch(makeGlobalSearchFetchYardsThunk());
  }, [dispatch, goToIndex]);

  return (
    <StyledSearch ref={wrapperRef}>
      <StyledSearchWrapper>
        <Box alignItems="center" paddingVerticalXS paddingHorizontalSM>
          <Box paddingRightXS>
            <Search />
          </Box>
          <UnstyledInput
            type="text"
            ref={inputRef}
            $color="coreWhite"
            $placeholderColor="grey05"
            placeholder={t('search')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={onSearchFocused}
          />

          <StyledClearButton disabled={!searchValue} $visible={!!searchValue} onClick={onClearSearch}>
            <Text typography="CaptionSmall">{t('clear')}</Text>
          </StyledClearButton>
        </Box>
        <Box block fullWidth>
          {(!!searchValue || showHistory) && (
            <StyledResultsDrawer>
              <Loading visible={isLoading} size="1.5rem" whiteLoader />

              {(haveResults || showHistory) && (
                <Box paddingVerticalXS block paddingHorizontalSM>
                  <Text typography="CaptionSmall" weight="700">
                    {showHistory ? t('recent_searches') : t('yards_capitalized')}
                  </Text>
                </Box>
              )}

              {!haveResults && !!searchValue && (
                <Box paddingTopXS paddingBottomSM block paddingHorizontalSM>
                  <Text weight="700" typography="CaptionSmall">
                    {t('no_results', { searchValue })}
                  </Text>
                </Box>
              )}

              {(haveResults || showHistory) && !!list.length && (
                <StyledResults>
                  {list.map((yard) => (
                    <StyledResult key={yard.id}>
                      <StyledYardButton onClick={() => onYardSelect(yard.id)}>
                        <Yard />

                        <ActiveStatus $active={yard.nbHives > 0} />

                        <StyledYardName
                          name={yard.name}
                          contractName={yard.contractName}
                          maxWidth={160}
                          typography={'SmallParagraph'}
                          color="coreWhite"
                        />
                        <StyledNbHives>{maybePluralize(yard.nbHives, 'hive', t)}</StyledNbHives>
                      </StyledYardButton>
                      <Spacer />
                      <StyledViewOnMapButton onClick={() => onMapClick(yard.id)}>
                        <Text typography="CaptionSmall">{t('view_on_map')}</Text>
                        <Box tag="span" paddingLeftXXS></Box>
                        <Pin />
                      </StyledViewOnMapButton>
                    </StyledResult>
                  ))}
                </StyledResults>
              )}
              {haveResults && searchResults.length > DEFAULT_NUM_RESULTS && (
                <Box block paddingSM>
                  <Button unstyled onClick={() => setShowAllResults((state) => !state)}>
                    <Text typography="CaptionSmall" color="coreFocus01">
                      {showAllResults
                        ? t('show_less')
                        : t('show_more', { results: maybePluralize(searchResults.length, 'result', t) })}
                    </Text>
                  </Button>
                </Box>
              )}
              {(haveResults || showHistory) && (
                <StyledResultsFooter>
                  <Text
                    typography={'CaptionSmall'}
                    dangerouslySetInnerHTML={{ __html: t('keyboard_navigation_instructions') }}
                  />
                </StyledResultsFooter>
              )}
            </StyledResultsDrawer>
          )}
        </Box>
      </StyledSearchWrapper>
    </StyledSearch>
  );
};
