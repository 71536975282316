import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ArrowDown = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M12.6 17.2005L17.85 10.2C18.0204 9.97274 18.0479 9.66868 17.9208 9.41459C17.7938 9.1605 17.5341 9 17.25 9H6.75C6.46592 9 6.20622 9.1605 6.07918 9.41459C5.95214 9.66868 5.97955 9.97274 6.15 10.2L11.4 17.2005C11.5416 17.3894 11.7639 17.5005 12 17.5005C12.2361 17.5005 12.4584 17.3894 12.6 17.2005Z"
      />
    </IconSVGView>
  );
};
