import styled from 'styled-components';

export const TooltipView = styled.div<{ arrow?: boolean; light?: boolean }>(
  ({ theme, arrow, light }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${light ? theme.colors.coreWhite : theme.colors.grey08};
  color: ${light ? theme.colors.grey08 : theme.colors.coreWhite};
  border-radius: ${theme.shape.paperRadius01}px;
  box-shadow: ${theme.shadows.boxShadow04};
  padding: ${theme.spacing.xs}px;
  
  strong {
    font-weight: 700;
  }
  
  &::before {
    content: ${arrow ? '""' : 'none'};
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    width: 14px;
    height: 14px;
    border-radius: ${theme.shape.paperRadius01}px;
    border-top-left-radius: 14px;
    background-color: ${light ? theme.colors.coreWhite : theme.colors.grey08};
  }
`
);
