import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { withGetScreen } from '@HOC/withGetScreen';
import { makeAddYardToSearchHistory, makeOpenMenuAction } from '@redux/deprecated/actions';
import { makeClearAppliedFiltersAction } from '@redux/YardsFilters/actions';

import { MobileTabBarView } from './MobileTabBarView';
// nectar:
import { NavBarView } from './NavBarView';

type NavBarProps = {
  t: () => void;
  showTab?: boolean;
  history: any;
  isMobile: () => boolean;
  isTablet: () => boolean;
  openMenuDispatch: (args: { modalType: string }) => void;
  user: any;
  addYardToSearchHistory: () => void;
  yardsSearchHistory: any[];
  clearYardFilters: () => void;
};

type NavBarState = {
  searchInput: string;
  searchExpanded: boolean;
  searchFocused: boolean;
};

class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.state = {
      searchInput: '',
      searchExpanded: false,
      searchFocused: false,
    };
  }

  openMenu(e: any) {
    e.preventDefault();
    const { openMenuDispatch } = this.props;
    openMenuDispatch({ modalType: 'menu' });
    Analytics.sendEvent({ event: AnalyticsEventType.NAVBAR_MENU_OPEN });
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const { t, isMobile, isTablet, showTab, user, clearYardFilters } = this.props as NavBarProps;

    const { searchInput, searchFocused } = this.state as NavBarState;

    const isMobileOrTablet = isMobile() || isTablet();

    return (
      <>
        <NavBarView
          t={t}
          user={user}
          isMobile={isMobile()}
          isMobileOrTablet={isMobileOrTablet}
          isSearchFocused={searchFocused}
          showTab={showTab && !isMobileOrTablet}
          handleClickOnMenu={this.openMenu}
          searchInput={searchInput}
          setSearchInput={(text) => this.setState({ searchInput: text })}
          searchExpandedOnMobile={!!((searchFocused || searchInput) && isMobile())}
          setSearchFocused={(focus) => this.setState({ searchFocused: focus })}
          clearYardFilters={clearYardFilters}
        />
        {isMobileOrTablet && showTab && <MobileTabBarView isMobileOrTablet={isMobileOrTablet} />}
      </>
    );
  }
}

/**
 *
 * @param state
 * @returns {{user: (*|object)}}
 *
 */
const mapStateToProps = (state: any) => ({
  user: state.accountReducer.user,
});

/**
 *
 * @param dispatch
 * @return {{openMenuDispatch: makeOpenMenuAction}}
 */

const mapDispatchToProps = (dispatch: any) => ({
  openMenuDispatch: (modalType: any) => {
    dispatch(makeOpenMenuAction(modalType));
  },
  clearYardFilters: () => dispatch(makeClearAppliedFiltersAction()),
  addYardToSearchHistory: (yardId: any) => dispatch(makeAddYardToSearchHistory(yardId)),
});

export default withRouter<any, React.ComponentType<NavBarProps>>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(NavBar)))
);
