import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Feeding = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.389 2C15.9958 1.99957 15.6119 2.11963 15.289 2.344L10.231 5.688C9.78538 5.98021 9.47479 6.43817 9.36815 6.96028C9.26152 7.48238 9.36765 8.02546 9.663 8.469L13.263 13.781C13.5608 14.2202 14.021 14.5231 14.5423 14.6229C15.0635 14.7226 15.603 14.6112 16.042 14.313L21.094 11C21.5398 10.708 21.8506 10.2501 21.9574 9.72795C22.0642 9.20583 21.9583 8.66267 21.663 8.219L18.063 2.906C17.6878 2.34729 17.062 2.0086 16.389 2ZM8.653 8.75L7.831 9.313C7.38538 9.60521 7.07479 10.0632 6.96815 10.5853C6.86152 11.1074 6.96765 11.6505 7.263 12.094L5.274 14.594L5.842 15.438L8.942 14.594C9.57643 15.5088 10.8275 15.7452 11.752 15.125L12.573 14.563L8.653 8.75ZM3 19.906C3 18.508 5.116 16 5.116 16C5.116 16 7.232 18.508 7.232 19.906C7.232 21.0746 6.28463 22.022 5.116 22.022C3.94737 22.022 3 21.0746 3 19.906Z"
      />
    </IconSVGView>
  );
};
