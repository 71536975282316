import React from 'react';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { AnimationType } from '@components/common/ContentTransition/types';
import { Animation } from '@helpers/Animation';

const PRESET_ANIMATIONS = {
  fade: {
    in: (duration: number) =>
      `
        opacity: 1;
        ${Animation.createCSSKeyframes('anim-fade-in', (progress) => `opacity: ${progress}`)}
        animation: anim-fade-in ${duration * 10}ms linear 0ms;
      `,
    out: (duration: number) =>
      `
        opacity: 0;
            ${Animation.createCSSKeyframes('anim-fade-out', (progress) => `opacity: ${1 - progress}`)}
        animation: anim-fade-out ${duration}ms linear 0ms 1;
      `,
  },
  focus: {
    in: (duration: number) =>
      `
        opacity: 1;
        ${Animation.createCSSKeyframes(
          'anim-focus-in',
          (progress) => `filter: blur(${(1 - progress) * 2.5}px); opacity: ${progress}`
        )}
        animation: anim-focus-in ${duration}ms linear 0ms 1;
      `,
    out: (duration: number) =>
      `
        opacity: 0;
        ${Animation.createCSSKeyframes(
          'anim-focus-out',
          (progress) => `filter: blur(${progress * 2.5}px); opacity: ${1 - progress}`
        )}
        animation: anim-focus-out ${duration}ms linear 0ms 1;
      `,
  },
} as Record<AnimationType, { in: (duration: number) => string; out: (duration: number) => string }>;

export const AnimatedContent = styled(Box)<{
  $type: AnimationType;
  $duration: number;
  $stage: 'in' | 'out';
}>`
  position: relative;
  ${({ $type, $duration, $stage }) => PRESET_ANIMATIONS[$type][$stage]($duration)}
`;
