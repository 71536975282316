import React, { useEffect } from 'react';

import { Loading } from '@components/common/Loading';
import config from '@config/config';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { Environment } from '@helpers/Environment';
import { useOperation } from '@hooks/useOperation';
import { useUser } from '@hooks/useUser';

export const AnalyticsLoader: React.FC = ({ children }) => {
  const operation = useOperation();
  const user = useUser();

  useEffect(() => {
    Analytics.initialize();
  }, []);

  useEffect(() => {
    if (user && operation) {
      Analytics.sendEvent({
        event: AnalyticsEventType.SESSION_START,
        eventData: {
          user_id: user.id,
          user_language: user.languageCode,
          user_type: user.isStaff ? 'staff' : 'customer',
          operation_id: operation.id,
          operation_name: operation.name,
          app_version: config.VERSION,
          app_environment: Environment.getName(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation?.id, user?.id]);

  if (!user || !operation) {
    return <Loading defaultBackground />;
  }

  return <>{children}</>;
};
