import React, { useEffect } from 'react';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { HiveStatusChip } from '@components/common/HiveStatusChip';
import { Text } from '@components/common/Text';
import { useHiveModalInnerContext, useHiveModalOpener } from '@components/hive/HiveModal/hooks';
import { StyledHiveModalContent } from '@components/hive/HiveModalContent/styles';
import { HiveModalMainLoading } from '@components/hive/HiveModalLoading/HiveModalMainLoading';
import { HiveModalTabs } from '@components/hive/HiveModalTabs';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useAction } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';
import { makeHiveDisposeAction, makeHiveFetchDetailThunk } from '@redux/Hive/actions';
import { useHiveData } from '@redux/Hive/hooks';

export const HiveModalContent: React.VFC = () => {
  const { id, isFocused } = useHiveModalInnerContext();
  const { openedHiveModals } = useHiveModalOpener();
  const { detail } = useHiveData();
  const dispatch = useDispatch();
  const t = useTranslation();

  const latestStatusAction = useAction(detail?.latestStatusAction ?? null);

  useEffect(() => {
    if (isFocused) {
      dispatch(makeHiveFetchDetailThunk(id));
    }
  }, [dispatch, id, isFocused]);

  useEffect(() => {
    return () => {
      const modalsWithSameHiveId = openedHiveModals.filter((sId) => sId === id);
      if (modalsWithSameHiveId.length === 1) {
        dispatch(makeHiveDisposeAction(id));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  return (
    <StyledHiveModalContent fit column stretch marginTopSM>
      <Box paddingTopLG paddingBottomMD paddingHorizontalMD gapSM alignItems={'center'}>
        <Text typography={'Heading2'} weight={'600'}>
          {detail ? `${capitalize(t('hive'))} ${detail.hiveName}` : ''}
        </Text>

        {latestStatusAction ? <HiveStatusChip status={latestStatusAction.flag as HiveStatus} /> : null}
      </Box>

      {detail ? <HiveModalTabs /> : null}

      <HiveModalMainLoading />
    </StyledHiveModalContent>
  );
};
