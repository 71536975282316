import { useSelector } from 'react-redux';

import { isEmptyArray } from '@helpers/deprecated/array';

export function useWorkers() {
  const { order_direction, order_by, sortedWorkerList } = useSelector((state) => state.workerReducer);

  const workers =
    sortedWorkerList && !isEmptyArray(sortedWorkerList) && order_direction === 'DESC'
      ? [...sortedWorkerList].reverse()
      : sortedWorkerList;

  return { workers, order_by, order_direction };
}
