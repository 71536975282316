import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Home = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M22.778 11.784L12.711 2.27795C12.3095 1.90719 11.6905 1.90719 11.289 2.27795L1.222 11.784C1.00887 11.9898 0.946467 12.3066 1.06561 12.5779C1.18474 12.8492 1.46022 13.0176 1.756 13H4V21C4 21.5522 4.44771 22 5 22H9C9.55228 22 10 21.5522 10 21V15H14V21C14 21.5522 14.4477 22 15 22H19C19.5523 22 20 21.5522 20 21V13H22.244C22.5398 13.0176 22.8152 12.8492 22.9344 12.5779C23.0535 12.3066 22.9911 11.9898 22.778 11.784Z"
      />
    </IconSVGView>
  );
};
