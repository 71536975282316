import { createGlobalStyle } from 'styled-components';

import { reset } from './reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    height:100%;
    font-family: ${(props) => props.theme.font.family};
    color: ${(props) => props.theme.colors.grey08};
    background-color: ${(props) => props.theme.colors.coreBackground};
  }

  #root {
    position:absolute;
    width:100%;
    height:100%;
    display: flex;
    flex-direction:column;
    overflow-x: hidden;

    main {
      flex: 1 0 auto;

    }
  }

  @media print {
    #root, main {
      display: block;
      overflow: initial;
      background-color: white;
      margin: 0;
      position: initial;
    }

    body {
      position: initial;
      background-color: white;
      margin: 0;
    }
   
  }

  /* prevents background from scrolling while a modal is open */
  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  //leaves the map clean of Google text
  .gmnoprint a, .gmnoprint span {
    display:none;
  }
  .gmnoprint div {
    background:none !important;
  }

  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"], a[href^="http://maps.google.com/maps"]{
  display:none !important;
  }

  .styled-link {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.grey08};
  }

  // to hide the bottom arrow
  .__react_component_tooltip.place-left::after {
    border-left: 0 solid transparent !important;
  }
  .__react_component_tooltip.place-right::after {
    border-right: 0 solid transparent !important;
  }
  .__react_component_tooltip.place-top::after {
    border-top: 0 solid transparent !important;
  }
  .__react_component_tooltip.place-bottom::after {
    border-bottom: 0 solid transparent !important;
  }

  // Tooltip fade animation
  .tippy-box[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }
`;
