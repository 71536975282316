import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ZoomOut = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 14.0001H14.71L14.43 13.7301C16.712 11.0718 16.4845 7.08509 13.9148 4.70372C11.3452 2.32234 7.35275 2.39822 4.87545 4.87552C2.39816 7.35281 2.32228 11.3453 4.70366 13.9149C7.08503 16.4845 11.0717 16.7121 13.73 14.4301L14 14.7101V15.5001L18.26 19.7501C18.4559 19.9469 18.7222 20.0576 19 20.0576C19.2778 20.0576 19.5441 19.9469 19.74 19.7501L19.75 19.7401C19.9469 19.5441 20.0576 19.2778 20.0576 19.0001C20.0576 18.7223 19.9469 18.456 19.75 18.2601L15.5 14.0001ZM9.49999 14.0001C7.67991 14.0001 6.03905 12.9037 5.34253 11.2221C4.64602 9.54059 5.03102 7.60506 6.31801 6.31807C7.605 5.03108 9.54053 4.64608 11.2221 5.34259C12.9036 6.03911 14 7.67997 14 9.50005C14.0016 10.694 13.528 11.8395 12.6837 12.6838C11.8395 13.5281 10.694 14.0016 9.49999 14.0001ZM11.5 9.00005H7.49999C7.22385 9.00005 6.99999 9.22391 6.99999 9.50005C6.99999 9.77619 7.22385 10.0001 7.49999 10.0001H11.5C11.7761 10.0001 12 9.77619 12 9.50005C12 9.22391 11.7761 9.00005 11.5 9.00005Z"
      />
    </IconSVGView>
  );
};
