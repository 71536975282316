function getDefaultStyles(theming) {
  return {
    snack: {
      display: 'inline-block',
      position: 'fixed',
      bottom: '2rem',
      left: '2.5rem',
      zIndex: theming.snackbarLevelIndex,
      transition: 'transform 200ms ease-out',
      willChange: 'transform',
      transform: 'translate(0, 100%)',
      backgroundColor: '#252629',
      padding: '13px 16px',
      border: '3px solid #252629',
      boxShadow: '0 14px 28px rgba(102,102,102,0.20),  0 10px 10px rgba(102,102,102,0.30)',
      largeScreen: {
        left: '2.5rem',
        transform: 'translate(-50%, 100%)',
        borderRadius: '2px',
        padding: '20px 24px',
      },
    },
    label: {
      flex: 4,
      fontFamily: 'Open Sans',
      fontSize: '12px',
      letterSpacing: '0.86px',
      fontWeight: '600',
      color: 'white',
    },
    button: {
      flex: 1,
      textAlign: 'right',
      border: 'none',
      background: 'none',
      padding: 0,
      margin: 0,
      float: 'right',
      marginLeft: '1rem',
      color: 'white',
      fontWeight: '600',
      letterSpacing: '1px',
      '&:hover': {
        opacity: '60%',
      },
    },
  };
}

function getTransitionStyles(elem, largeScreen, visible, theming) {
  if (elem === 'snack') {
    if (visible) {
      const small = `translate(${(theming.transitionToXY || {}).smallScreen || '0, 0'})`;
      const large = `translate(${(theming.transitionToXY || {}).largeScreen || '0, 0'})`;
      return {
        transform: largeScreen ? large : small,
      };
    }
    return {};
  }
  return {
    transition: 'opacity 200ms ease-out',
    transitionDelay: '150ms',
    willChange: 'opacity',
    opacity: visible ? 1 : 0,
  };
}

function getComputedStyles(elem, largeScreen, visible, theming = {}, customStyles = {}) {
  let styles = {
    ...getDefaultStyles(theming)[elem],
    ...customStyles[elem],
  };
  delete styles.largeScreen;
  if (largeScreen) {
    styles = {
      ...styles,
      ...(getDefaultStyles(theming)[elem] || {}).largeScreen,
      ...(customStyles[elem] || {}).largeScreen,
    };
  }
  styles = { ...styles, ...getTransitionStyles(elem, largeScreen, visible, theming) };
  return styles;
}

export default getComputedStyles;
