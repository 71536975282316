import React, { useMemo, useState } from 'react';
import { Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Card, CardContent, CardContentRow } from '@components/common/Card';
import { SlimPage } from '@components/common/SlimPage';
import { StyledHeading3, StyledParagraphSmall } from '@components/deprecated/Elements/TypographyElements';
import { Dropdown } from '@components/form/Dropdown';
import {
  makeChangeAccountRequestThunk,
  makeOpenModalAction,
  makeOpenPasswordModalAction,
} from '@redux/deprecated/actions';

import { StyleRow, StyleTextBtn, UnderlineSpan } from './styles';

export const TabUserProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.accountReducer.user);

  const openContactModal = () => dispatch(makeOpenModalAction({ modalType: 'contact' }));
  const handleChangePassword = () => dispatch(makeOpenPasswordModalAction({ modalType: 'change-pass' }));

  const { first_name, username, email, phone_number, timezone } = user;
  const [timezoneState, setTimeZoneState] = useState({ timezone });

  const tz = timezone && moment.tz([2012, 0], timezone).format('Z z').split(' ');
  const zone = timezone && timezone.split('/');

  const timezone_list = moment.tz.names();

  const updateTimezone = (newTimezone: string) => {
    const newState = { ...timezoneState, timezone: newTimezone };
    setTimeZoneState(newState);
    dispatch(makeChangeAccountRequestThunk(newState));
  };

  const overview = useMemo(
    () => [
      {
        label: t('name'),
        data: first_name ? first_name : username,
      },
      {
        label: t('email'),
        data: email ? email : '',
      },
      {
        label: t('phone'),
        data: phone_number ? (
          phone_number
        ) : (
          <p>
            {t('add_phone')}
            <UnderlineSpan onClick={openContactModal}>{t('contact_us')}</UnderlineSpan>.
          </p>
        ),
      },
      {
        label: t('time-zone'),
        data:
          zone && tz ? (
            <Dropdown
              id={'timezone'}
              name={'timezone'}
              list={timezone_list}
              defaultValue={timezone}
              onUpdate={updateTimezone}
            />
          ) : (
            'UTC'
          ),
      },
      {
        label: t('password'),
        data: (
          <span
            style={{
              display: 'flex',
              whiteSpace: 'nowrap',
            }}
          >
            •••••••••••••
            <StyleTextBtn type="button" label={t('change')} onClick={handleChangePassword} disabled={false} />
          </span>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, first_name, phone_number, t, tz, username, zone]
  );
  return (
    <SlimPage>
      <Card id="about">
        <CardContent>
          {overview.map((item) => (
            <CardContentRow key={item.label}>
              <StyleRow align="center">
                <Col xs={12} sm={3}>
                  <StyledHeading3>{t(item.label)}</StyledHeading3>
                </Col>
                <Col xs={12} sm={9}>
                  {item.label === t('time-zone') ? item.data : <StyledParagraphSmall>{item.data}</StyledParagraphSmall>}
                </Col>
              </StyleRow>
            </CardContentRow>
          ))}
        </CardContent>
      </Card>
    </SlimPage>
  );
};
