// note
// for labels you must use translation key
// see in /src/translations/**
import APP from '@config/constants';

const MENU_ITEMS = [
  {
    label: 'operation',
    route: APP.routes.operation,
  },
  {
    label: 'settings',
    route: APP.routes.settings,
  },
  {
    label: 'contact',
    route: '+contact',
  },
  {
    label: 'logout',
    route: '=logout',
  },
];

export default MENU_ITEMS;
