import React, { ComponentType } from 'react';

import { Directions } from '@components/common/Icon/presets/Directions';
import { Hazard } from '@components/common/Icon/presets/Hazard';
import { Road } from '@components/common/Icon/presets/Road';
import { Security } from '@components/common/Icon/presets/Security';
import { Storage } from '@components/common/Icon/presets/Storage';
import { IconSVGProps } from '@components/common/Icon/types';

const POI_ICON_PRESET: Record<BeePointOfInterestCategory, ComponentType<IconSVGProps>> = {
  access: Directions,
  road: Road,
  security: Security,
  storage: Storage,
  hazard: Hazard,
};

export const PoiIcon: React.VFC<IconSVGProps & { category?: BeePointOfInterestCategory }> = ({
  category = 'access',
  ...props
}) => {
  const Icon = POI_ICON_PRESET[category];
  return <Icon {...props} />;
};
