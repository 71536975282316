import { useContext } from 'react';

import { YardsListTooltipsContext, YardsListTooltipsContextValue } from '../context';

export function useYardsListTooltips(): YardsListTooltipsContextValue {
  const yardsListTooltips = useContext(YardsListTooltipsContext);

  if (!yardsListTooltips) {
    throw new Error("Can't find a parent yards list tooltips context.");
  }

  return yardsListTooltips;
}
