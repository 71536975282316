import React from 'react';
import { useTranslation } from 'react-i18next';

import { Edit } from '@components/common/Icon/presets/Edit';
import { YardAdd } from '@components/common/Icon/presets/YardAdd';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';

export const PolliMapPaneDropsEmpty: React.VFC = () => {
  const { t } = useTranslation();
  const { isManaging, dropsList } = usePolliMap();

  const isEmpty = dropsList.length === 0;
  const dropsHintText1 = t(isManaging ? 'pollination_drops_management_hint_part1' : 'pollination_drops_hint_part1');
  const dropsHintText2 = t(isManaging ? 'pollination_drops_management_hint_part2' : 'pollination_drops_hint_part2');
  const DropsHintIcon = isManaging ? YardAdd : Edit;
  const hintIconProps = isManaging ? { size: 24 } : { size: 16 };

  if (!isEmpty) {
    return null;
  }

  return (
    <Text typography={'SmallParagraph'}>
      <span dangerouslySetInnerHTML={{ __html: dropsHintText1 }} />
      <DropsHintIcon {...hintIconProps} color={'grey08'} />
      <span dangerouslySetInnerHTML={{ __html: dropsHintText2 }} />
    </Text>
  );
};
