import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { DOM } from '@helpers/DOM';
import { useTranslation } from '@hooks/useTranslation';

import { POICard } from './POICard';
import { PolliContractDetailsPrintable } from './PolliContractDetailsPrintable';
import { PolliContractMapPrintable } from './PolliContractMapPrintable';
import { PolliContractPrintablePage } from './PolliContractPrintablePage';
import {
  StyledColumn,
  StyledDetailsHeading,
  StyledDetailsWrapper,
  StyledFlexColWrapper,
  StyledPagesWrapper,
} from './styles';

export interface LegendItem {
  key: string;
  type: 'block' | 'drop' | 'poi' | 'title' | undefined;
  data: BeeBlock | BeeDrop | BeePointOfInterest | string;
}

export const PolliContractPrintable: React.FC<{
  contract: BeeContract;
  drops: Array<BeeDrop> | null;
  blocks: Array<BeeBlock> | null;
  pois: Array<BeePointOfInterest> | null;
  lang: BeeSupportedLangCode;
  type: 'worker' | 'manager';
  onMapLoad: () => void;
}> = ({ contract, drops, blocks, pois, lang, type, onMapLoad }) => {
  const t = useTranslation({ lng: lang });
  const pagesWrapperRef = useRef<HTMLDivElement>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [renderedPois, setRenderedPois] = useState<BeePointOfInterest[][]>([]);
  const [renderedItems, setRenderedItems] = useState<LegendItem[][]>([]);
  const currentPoiPage = useRef(-1);
  const indexPerPage = useRef(-1);
  const indexOverall = useRef(-1);

  useEffect(() => {
    if (pagesWrapperRef && pagesWrapperRef.current) {
      const pages = pagesWrapperRef.current.querySelectorAll('.js-page').length;
      setTotalPages(pages);
    }
  }, [renderedPois, renderedItems]);

  useLayoutEffect(() => {
    if (!pois) {
      return;
    }
    if (indexOverall.current >= pois.length - 1) {
      return;
    }

    if (!renderedPois.length) {
      currentPoiPage.current += 1;
      indexPerPage.current += 1;
      indexOverall.current += 1;
      setRenderedPois([[pois[0]]]);

      return;
    }

    const currentRenderPageArray = renderedPois[currentPoiPage.current];
    const lastRenderedItemId = currentRenderPageArray[indexPerPage.current].id;
    const lastDomItem = document.getElementById(`poi-${lastRenderedItemId}`);
    const parent = lastDomItem?.closest('.js-page') as HTMLElement;
    const maxPageWidth = parent?.getBoundingClientRect().width ?? 0;
    const maxPageHeight = (parent?.getBoundingClientRect().height ?? 0) * 0.95;
    if (!parent || !lastDomItem) return;

    const distance = DOM.getDistanceFromParent(lastDomItem, parent).y;
    const parentOffset = DOM.getDistanceFromParent(lastDomItem, parent);
    const isWithinThePage = parentOffset.x < maxPageWidth && parentOffset.y < maxPageHeight;

    if (
      (currentPoiPage.current === 0 && distance <= maxPageHeight) ||
      (currentPoiPage.current > 0 && isWithinThePage)
    ) {
      indexPerPage.current += 1;
      indexOverall.current += 1;
      setRenderedPois((pages) => {
        const newState = [...pages];
        newState[pages.length - 1].push(pois[indexOverall.current]);
        return newState;
      });
    } else {
      currentPoiPage.current += 1;
      indexPerPage.current = 0;
      setRenderedPois((pages) => {
        const currentPage = pages[pages.length - 1];
        const nextPage = currentPage.splice(currentPage.length - 1, 1);
        return [...pages, nextPage];
      });
    }
  }, [renderedPois, pois]);

  return (
    <StyledPagesWrapper ref={pagesWrapperRef} className="js-pages">
      {pois && !pois.length ? (
        <PolliContractPrintablePage
          firstPage
          totalPages={totalPages}
          heading={contract.name}
          subheading={contract.grower.name}
          t={t}
          lang={lang}
        >
          <StyledDetailsWrapper>
            <PolliContractDetailsPrintable contract={contract} t={t} type={type} />
          </StyledDetailsWrapper>
        </PolliContractPrintablePage>
      ) : (
        renderedPois.map((pageOfPois, index) => {
          return (
            <PolliContractPrintablePage
              key={index}
              firstPage={index === 0}
              totalPages={totalPages}
              heading={contract.name}
              subheading={contract.grower.name}
              t={t}
              lang={lang}
            >
              {index === 0 ? (
                <StyledDetailsWrapper>
                  <PolliContractDetailsPrintable contract={contract} t={t} type={type} />
                  <StyledColumn>
                    <StyledDetailsHeading>{t('pollination_pois')}</StyledDetailsHeading>
                    <div>
                      {pageOfPois?.map((poi, index) => {
                        return <POICard poi={poi} key={index} lang={lang} />;
                      })}
                    </div>
                  </StyledColumn>
                </StyledDetailsWrapper>
              ) : (
                <StyledFlexColWrapper>
                  {pageOfPois?.map((poi) => {
                    return <POICard poi={poi} key={poi.id} lang={lang} halfWidth />;
                  })}
                </StyledFlexColWrapper>
              )}
            </PolliContractPrintablePage>
          );
        })
      )}

      <PolliContractMapPrintable
        contract={contract}
        renderedItems={renderedItems}
        setRenderedItems={setRenderedItems}
        totalPages={totalPages}
        heading={contract.name}
        subheading={contract.grower.name}
        lang={lang}
        t={t}
        drops={drops}
        blocks={blocks}
        pois={pois}
        onLoad={onMapLoad}
      />
    </StyledPagesWrapper>
  );
};
