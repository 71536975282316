import React from 'react';
import moment from 'moment';

import { Text } from '@components/common/Text';
import {
  StyledTimelineDayLine,
  StyledTimelineDayMark,
  StyledTimelineDayMarkSelector,
} from '@components/timeline/TimelineDayMark/styles';
import { TimelineDayMarkProps } from '@components/timeline/TimelineDayMark/types';

export const TimelineDayMark: React.VFC<TimelineDayMarkProps> = ({ date }) => {
  const formattedDate = moment(date).format('MMMM DD, YYYY');

  return (
    <>
      <StyledTimelineDayLine />
      <StyledTimelineDayMark>
        <StyledTimelineDayMarkSelector>
          <Text typography={'Heading3'} weight={'700'}>
            {formattedDate}
          </Text>
        </StyledTimelineDayMarkSelector>
      </StyledTimelineDayMark>
    </>
  );
};
