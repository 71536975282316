import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const ActionTooltipContainer = styled(Box)(
  ({ theme }) => `
    color: ${theme.colors.coreWhite};
    
    & > * {
      margin: ${theme.spacing.xxs}px;
    }
  `
);

export const ActionTooltipSection = styled(Box)(
  ({ theme }) => `
    border: 1px solid ${theme.colors.grey07};
    border-radius: ${theme.shape.paperRadius01}px;
  `
);

export const ActionTooltipSectionRow = styled(Box)(
  ({ theme }) => `
    &:first-child {
      border-bottom: 1px solid ${theme.colors.grey07};
    }
  `
);
