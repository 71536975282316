import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

// nectar:
import { GROUP_COLOR_BY_COLOR_ID } from '@helpers/deprecated/color';

const GroupColorsContainer = styled.div`
  position: absolute;
  width: 6rem;
  height: 6.25rem;
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 3px;
  right: 10px;
  z-index: ${(props) => props.theme.getZIndexOf('lowLevelIndex')};
  margin-top: -${({ scrollTop }) => scrollTop}px;
`;

const GroupColor = styled.span`
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin: 0.25rem;
`;

export const GroupColorsPalleteView = ({ setSelectedGroupColor, scrollTop }) => (
  <GroupColorsContainer scrollTop={scrollTop}>
    {Object.entries(GROUP_COLOR_BY_COLOR_ID).map(([key, val]) => (
      <GroupColor key={key} color={val} onClick={() => setSelectedGroupColor(key)} />
    ))}
  </GroupColorsContainer>
);

GroupColorsPalleteView.propTypes = {
  setSelectedGroupColor: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
};

GroupColorsPalleteView.defaultProps = {
  scrollTop: 0,
};
