import styled from 'styled-components';

export const GroupColorsView = styled.div`
  background-color: ${(props) => props.background};
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: absolute;
  right: 3rem;
`;
