import { URLUtil } from '@helpers/URL';

import { Params, ParamsListener } from './types';

const NAVIGATION_HISTORY_STORAGE_KEY = 'navigation-history';

const listeners = [] as Array<ParamsListener<any>>;

export const QueryParams = {
  getCurrentQueryParams,
  getCurrentQueryParamsStr,
  setCurrentQueryParams,
  popLatestPathname,
  addListener,
};

bindHistoryListeners();

function bindHistoryListeners() {
  window.addEventListener('popstate', () => {
    updateNavigationHistory();
    callListeners();
  });

  const methodNames = ['pushState', 'replaceState', 'back', 'forward', 'go'];
  methodNames.forEach((methodName) => {
    const historyObject = history as any;
    const method = historyObject[methodName];
    historyObject[methodName] = (...args: any) => {
      method.apply(historyObject, args);
      updateNavigationHistory();
      callListeners();
    };
  });
}

function getCurrentQueryParams<T extends Params>(): T {
  const params = {} as T;
  const paramsObj = new URLSearchParams(window.location.search);
  const iterator = paramsObj.entries();
  let entry = iterator.next();
  while (!entry.done) {
    const [key, value] = entry.value;
    params[key as keyof T] = value as T[keyof T];
    entry = iterator.next();
  }
  return params;
}

function getCurrentQueryParamsStr(): string {
  return URLUtil.buildURL(getCurrentQueryParams());
}

function setCurrentQueryParams<T extends Params>(params: T) {
  const urlPath = window.location.href.split('?')[0];
  const paramsStr = Object.entries(params)
    .filter(([, value]) => ![null, undefined, ''].includes(value))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const currentURL = window.location.href;
  let nextURL;
  if (paramsStr) {
    nextURL = `${urlPath}?${paramsStr}`;
  } else {
    nextURL = urlPath;
  }

  if (currentURL !== nextURL) {
    history.pushState(null, '', nextURL);
  }
}

function popLatestPathname(): string | null {
  const navigationHistory = getNavigationHistory();
  const navigationHistoryCopy = [...navigationHistory];

  const currentPath = getURLPathname(window.location.href);
  let previousUrl: string | null = null;

  for (let i = navigationHistory.length - 1; i > -1; i--) {
    if (getURLPathname(navigationHistory[i]) !== currentPath) {
      previousUrl = getURLPathnameAndQueryParams(navigationHistory[i]);
      navigationHistoryCopy.splice(i);

      break;
    }
  }

  setNavigationHistory(navigationHistoryCopy);
  return previousUrl;
}

function updateNavigationHistory() {
  const navigationHistory = getNavigationHistory();
  const latestUrl = navigationHistory[navigationHistory.length - 1];
  const currentUrl = getURLPathnameAndQueryParams(window.location.href);
  if (latestUrl !== currentUrl) {
    navigationHistory.push(currentUrl);
    setNavigationHistory(navigationHistory);
  }
}

function getNavigationHistory(): Array<string> {
  try {
    return JSON.parse(sessionStorage.getItem(NAVIGATION_HISTORY_STORAGE_KEY) ?? '');
  } catch (error) {
    return [];
  }
}

function setNavigationHistory(navigationHistory: Array<string>) {
  try {
    return sessionStorage.setItem(NAVIGATION_HISTORY_STORAGE_KEY, JSON.stringify(navigationHistory));
  } catch (error) {
    // No need to handle.
  }
}

function getURLPathnameAndQueryParams(url: string) {
  const urlDomainRegex = /^https?:\/\/[^/]+/;
  return url.replace(urlDomainRegex, '');
}

function getURLPathname(url: string) {
  const urlDomainRegex = /^https?:\/\/[^/]+/;
  const urlQueryParamsRegex = /\/?\?.*$/;
  return url.replace(urlDomainRegex, '').replace(urlQueryParamsRegex, '');
}

function callListeners() {
  const params = getCurrentQueryParams();
  listeners.forEach((listener) => listener({ ...params }));
}

function addListener<T extends Params>(listener: ParamsListener<T>) {
  listeners.push(listener);
  return () => removeListener(listener);
}

function removeListener<T extends Params>(listener: ParamsListener<T>) {
  listeners.splice(listeners.indexOf(listener), 1);
}
