import React, { useCallback, useEffect } from 'react';

import { Box } from '@components/common/Box';
import { useHiveModalInnerContext } from '@components/hive/HiveModal/hooks';
import { TimelineByActivityRequest } from '@components/timeline/TimelineByActivityRequest';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeHiveFetchActivityThunk } from '@redux/Hive/actions';
import { useHiveData } from '@redux/Hive/hooks';

export const HiveModalTabActivity: React.VFC = () => {
  const t = useTranslation();
  const { id, isFocused } = useHiveModalInnerContext();
  const { activitySet, isFetchingActivitySet } = useHiveData();
  const dispatch = useDispatch();

  const isLoadingInitialItems = isFetchingActivitySet && !activitySet?.activities.length;
  const isLoadingMoreItems = Boolean(isFetchingActivitySet && activitySet?.activities.length);

  const loadMoreItems = useCallback(() => {
    dispatch(makeHiveFetchActivityThunk(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isFocused) {
      loadMoreItems();
    }
  }, [id, isFocused, loadMoreItems]);

  return (
    <Box fit column stretch>
      <TimelineByActivityRequest
        items={activitySet?.activities ?? []}
        itemsTotalCount={activitySet?.totalCount ?? 0}
        highlightedHiveId={id}
        onMoreItemsRequest={loadMoreItems}
        isLoadingInitialItems={isLoadingInitialItems}
        isLoadingMoreItems={isLoadingMoreItems}
        footerMessage={t('timeline_bottom_hive_message')}
      />
    </Box>
  );
};
