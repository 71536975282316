import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { StyledBee, StyledBeeWing } from '@components/common/FlyingBee/styles';
import { FlyingBeePoint, FlyingBeeProps } from '@components/common/FlyingBee/types';

const BEE_FLIGHT_BASE_SPEED = 192.0;
const BEE_FLIGHT_VARIANCE = 48.0;
const BEE_TARGET_UPDATE_INTERVAL = 15.0;
const BEE_TARGET_VARIATION_UPDATE_INTERVAL = 2.0;

export const FlyingBee: React.VFC<FlyingBeeProps> = ({ landOn, delay }) => {
  const beeRef = useRef<HTMLDivElement | null>(null);
  const beeState = useRef({
    pos: getOutOfScreenPos(),
    target: { x: 0.0, y: 0.0 },
    targetVariation: null as FlyingBeePoint | null,
    speed: { x: 0.0, y: 0.0 },
    timestamps: {
      position: getTimestamp(),
      target: getTimestamp(),
    },
  });

  const [isAlive, setIsAlive] = useState(false);
  const [isFlying, setIsFlying] = useState(false);
  const { colors } = useTheme();

  const updateBeeSpeed = useCallback(() => {
    const state = beeState.current;
    const target = state.targetVariation || state.target;

    const movementVector = subPoints(target, state.pos);
    const angle = Math.atan2(movementVector.y, movementVector.x);
    state.speed = {
      x: Math.cos(angle) * BEE_FLIGHT_BASE_SPEED,
      y: Math.sin(angle) * BEE_FLIGHT_BASE_SPEED,
    };

    if (Math.abs(state.speed.x) > Math.abs(movementVector.x)) {
      state.speed.x = movementVector.x;
    }
    if (Math.abs(state.speed.y) > Math.abs(movementVector.y)) {
      state.speed.y = movementVector.y;
    }
  }, []);

  const updateBeePosition = useCallback(() => {
    const state = beeState.current;
    const delta = getTimeDelta(state.timestamps.position);
    state.pos.x += state.speed.x * delta;
    state.pos.y += state.speed.y * delta;
    state.timestamps.position = getTimestamp();
  }, []);

  const updateBeeStyle = useCallback(() => {
    if (beeRef.current) {
      const state = beeState.current;
      const angle = getLeveledPoint(state.speed);
      const scaleX = state.speed.x < 0 ? -1 : 1;
      const pos = `calc(${state.pos.x}px - 50%), calc(${state.pos.y}px - 50%)`;
      beeRef.current.style.transform = `translate(${pos}) rotate(${angle}rad) scaleX(${scaleX})`;

      const shouldVariate = Math.floor((+new Date() + (delay ?? 0)) / 10) % 10 === 0;
      if (shouldVariate) {
        const distanceToGo = getDistance(state.pos, state.target);
        const variance = Math.min(BEE_FLIGHT_VARIANCE, distanceToGo);
        const varianceVector = {
          x: (Math.random() - 0.5) * variance,
          y: (Math.random() - 0.5) * variance,
        };
        beeRef.current.style.top = `${varianceVector.y}px`;
        beeRef.current.style.left = `${varianceVector.x}px`;
      }
    }
  }, [delay]);

  const updateBeeFlyingStatus = useCallback(() => {
    const state = beeState.current;
    setIsFlying(getDistance(state.pos, state.target) > 4.0);
  }, []);

  const updateBeeTarget = useCallback(() => {
    const state = beeState.current;
    const delta = getTimeDelta(state.timestamps.target);

    if (delta < BEE_TARGET_UPDATE_INTERVAL && getPointLength(state.target) > 0) {
      return;
    }

    const landOnElement = document.querySelector(landOn);
    if (landOnElement) {
      const { x, y, width, height } = landOnElement.getBoundingClientRect();
      state.target = { x: x + Math.random() * width, y: y + Math.random() * height };
    }
    state.timestamps.target = getTimestamp();
  }, [landOn]);

  // Effect to start the animation loop.
  useLayoutEffect(() => {
    let running = true;
    const performUpdates = () => {
      updateBeeTarget();
      updateBeeSpeed();
      updateBeePosition();
      updateBeeFlyingStatus();
      updateBeeStyle();

      running && requestAnimationFrame(performUpdates);
    };

    setTimeout(() => {
      setIsAlive(true);
      beeState.current.timestamps = {
        target: getTimestamp(),
        position: getTimestamp(),
      };
      performUpdates();
    }, delay);

    return () => {
      running = false;
    };
  }, [delay, updateBeeFlyingStatus, updateBeePosition, updateBeeSpeed, updateBeeStyle, updateBeeTarget]);

  // Effect to handle mouse events.
  useLayoutEffect(() => {
    const beeElement = beeRef.current;
    if (!beeElement) {
      return;
    }

    let timeoutId: any = null;
    const makeBeeRunAway = () => {
      const state = beeState.current;

      state.targetVariation = getRandomPos(state.pos);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        state.targetVariation = null;
      }, BEE_TARGET_VARIATION_UPDATE_INTERVAL * 1000);
    };

    beeElement.addEventListener('mouseenter', makeBeeRunAway);
    return () => beeElement.removeEventListener('mouseenter', makeBeeRunAway);
  }, [isAlive]);

  if (!isAlive) {
    return null;
  }

  return (
    <StyledBee ref={beeRef} $isFlying={isFlying}>
      <svg viewBox="0 0 100 100">
        <defs>
          <mask id="body-mask">
            <ellipse cx="50" cy="50" rx="35" ry="20" fill="#fff" />
          </mask>
        </defs>

        <path fill={colors.grey08} d="M 0 50 L 20 47.5 L 20 52.5 L 0 50" />

        <g mask="url(#body-mask)">
          <rect x="0" y="0" width="100" height="100" fill={colors.yellow02} />
          <rect x="0" y="0" width="35" height="100" fill={colors.grey08} />
          <rect x="43" y="0" width="8" height="100" fill={colors.grey08} />
          <rect x="59" y="0" width="8" height="100" fill={colors.grey08} />
          <circle cx="75" cy="50" r="2" fill={colors.grey08} />
        </g>
      </svg>
      <StyledBeeWing />
      <StyledBeeWing />
    </StyledBee>
  );
};

function getRandomPos(refPoint?: FlyingBeePoint) {
  let point;
  do {
    point = { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight };
  } while (refPoint && getDistance(point, refPoint) < BEE_FLIGHT_BASE_SPEED);
  return point;
}

function getOutOfScreenPos() {
  return {
    x: Math.random() * window.innerWidth,
    y: Math.random() < 0.5 ? -100 : window.innerHeight + 100,
  };
}

function getLeveledPoint({ x, y }: FlyingBeePoint, factor = 4.0) {
  let angle = Math.atan2(y, x);
  if (angle > Math.PI / 2.0) {
    angle -= Math.PI;
  } else if (angle < Math.PI / -2.0) {
    angle += Math.PI;
  }

  return angle / factor;
}

function getDistance(pA: FlyingBeePoint, pB: FlyingBeePoint) {
  return getPointLength(subPoints(pA, pB));
}

function getPointLength(p: FlyingBeePoint) {
  return Math.sqrt(Math.pow(p.x, 2.0) + Math.pow(p.y, 2.0));
}

function subPoints(pA: FlyingBeePoint, pB: FlyingBeePoint) {
  return { x: pA.x - pB.x, y: pA.y - pB.y };
}

function getTimeDelta(startTimestamp: number) {
  return getTimestamp() - startTimestamp;
}

function getTimestamp() {
  return +new Date() / 1000.0;
}
