import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Box } from '@components/common/Box';
import { Close } from '@components/common/Icon';
import { Yard } from '@components/common/Icon/presets/Yard';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { isUncommitedInstance, PolliMapMode } from '@components/pollination/PolliMap/types';
import APP from '@config/constants';

import { StyledButtonFlat, StyledButtonPrimary } from '../PolliMap/styles';

import {
  SummaryChip,
  SummaryClose,
  SummaryContent,
  SummaryFooter,
  SummaryHeader,
  SummaryHeaderTitle,
  SummaryPaddedRow,
  SummaryRow,
  SummaryTitle,
} from './styles';

export const PolliMapPaneDrop: React.VFC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    selectedDrop,
    removeDrop,
    setMode,
    clearAnyElementSelection,
    isManaging,
    isManagingDrops,
    isManagingADrop,
    isDraggingElements,
    isAdding,
    drops,
  } = usePolliMap();

  // Can only go to drop page if it's saved to the server.
  const hasDropPage = selectedDrop && !isAdding(selectedDrop, drops) && !isManaging;
  const isDeleteButtonDisabled = selectedDrop?.isActive;

  const goToEdit = useCallback(() => {
    setMode(PolliMapMode.MANAGING_A_DROP);
  }, [setMode]);

  const goToDropPage = useCallback(() => {
    if (selectedDrop && !isUncommitedInstance(selectedDrop)) {
      history.push(generatePath(APP.routes.yard, { uid: selectedDrop.id }));
    }
  }, [history, selectedDrop]);

  if (isDraggingElements || isManagingADrop || !selectedDrop) {
    return null;
  }

  return (
    <Box column stretch fit>
      <SummaryHeader>
        <SummaryHeaderTitle typography={'Heading3'} weight={'600'}>
          {t('selected')}
        </SummaryHeaderTitle>
        <SummaryClose onClick={clearAnyElementSelection} aria-label={t('close')}>
          <Close size={24} />
        </SummaryClose>
      </SummaryHeader>

      <SummaryContent>
        <SummaryRow>
          <Yard size={16} color={'grey08'} />
          <SummaryTitle typography={'Heading3'} weight={'600'}>
            {t('pollination_drop_name_value', { name: selectedDrop.name })}
          </SummaryTitle>
          <SummaryChip>
            <Text typography={'CaptionSmall'} weight={'700'}>
              {selectedDrop.name}
            </Text>
          </SummaryChip>
        </SummaryRow>
        <SummaryPaddedRow>
          <SummaryTitle typography={'Heading3'} weight={'600'}>
            {t('number_of_hives')}
          </SummaryTitle>
          <Text typography={'Heading3'}>{t(selectedDrop.targetHiveNumber)}</Text>
        </SummaryPaddedRow>
      </SummaryContent>

      {isManaging || isManagingDrops ? (
        <SummaryFooter>
          <Box fit>
            <StyledButtonFlat onClick={() => removeDrop(selectedDrop)} disabled={isDeleteButtonDisabled}>
              <Text weight={'700'} typography={'CTA'}>
                {t('delete')}
              </Text>
            </StyledButtonFlat>
            {isDeleteButtonDisabled && (
              <Tooltip placement={'top'}>
                <Text
                  typography={'CaptionSmall'}
                  dangerouslySetInnerHTML={{ __html: t('tooltip_cannot_delete_active_drop') }}
                />
              </Tooltip>
            )}
          </Box>
          <StyledButtonPrimary onClick={goToEdit}>
            <Text weight={'700'} typography={'CTA'}>
              {t('edit')}
            </Text>
          </StyledButtonPrimary>
        </SummaryFooter>
      ) : hasDropPage ? (
        <SummaryFooter>
          <StyledButtonPrimary onClick={goToDropPage}>
            <Text weight={'700'} typography={'CTA'}>
              {t('view_drop_page')}
            </Text>
          </StyledButtonPrimary>
        </SummaryFooter>
      ) : null}
    </Box>
  );
};
