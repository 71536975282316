import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type YardsFiltersReducer = Reducer<YardsFiltersState, YardsFiltersAction>;

export type YardsFiltersAction =
  | { type: YardsFiltersActionType.FETCH_FILTERS_AVAILABILITIES_START }
  | {
      type: YardsFiltersActionType.FETCH_FILTERS_AVAILABILITIES_FINISH;
      payload: { data: YardsFiltersState['availableFilters']; error: ApiResponseError | null };
    }
  | { type: YardsFiltersActionType.PATCH_APPLIED_FILTERS; payload: { data: YardsFiltersState['appliedFilters'] } }
  | { type: YardsFiltersActionType.CLEAR_APPLIED_FILTERS }
  | {
      type: YardsFiltersActionType.PATCH_TRANSIENT_FILTERS;
      payload: { data: YardsFiltersState['transientAppliedFilters'] };
    }
  | { type: YardsFiltersActionType.CLEAR_TRANSIENT_FILTERS };

export type AppliedFilters = Partial<Record<YardsFiltersCategoryKey, Array<YardsFiltersDefaultValueKey>>>;
export type AvailableFilters = Partial<Record<YardsFiltersCategoryKey, YardsFiltersCategory>>;
export type IncompatibleFilterValues = Partial<
  Record<YardsFilterValuesKey, Partial<Record<YardsFiltersCategoryKey, Array<YardsFilterValuesKey>>>>
>;

export interface YardsFiltersState {
  availableFilters: AvailableFilters;
  transientAvailableFilters: AvailableFilters;
  appliedFilters: AppliedFilters;
  transientAppliedFilters: AppliedFilters;
  appliedFiltersCount: number;

  isFetching: boolean;
  fetchError: ApiResponseError | null;
}

export enum YardsFiltersActionType {
  FETCH_FILTERS_AVAILABILITIES_START = 'FETCH_FILTERS_AVAILABILITIES_START',
  FETCH_FILTERS_AVAILABILITIES_FINISH = 'FETCH_FILTERS_AVAILABILITIES_FINISH',

  PATCH_APPLIED_FILTERS = 'PATCH_APPLIED_FILTERS',
  CLEAR_APPLIED_FILTERS = 'CLEAR_APPLIED_FILTERS',

  PATCH_TRANSIENT_FILTERS = 'PATCH_TRANSIENT_FILTERS',
  CLEAR_TRANSIENT_FILTERS = 'CLEAR_TRANSIENT_FILTERS',
}

export type YardsFiltersCategory = {
  key: YardsFiltersCategoryKey;
  values: Record<YardsFilterValuesKey, boolean>;
};

export type YardsFilterValuesKey =
  | YardsFiltersStatusValuesKey
  | YardsFiltersFlagValuesKey
  | YardsFiltersLastInspectionIntervalValuesKey
  | YardsFiltersDefaultValueKey;

/**
 * Available filter categories. This should match the available
 * values from BE.
 * */
export enum YardsFiltersCategoryKey {
  YARD_STATUS = 'yard_status',
  YARD_FLAG = 'yard_flag',
  YARD_TYPE = 'yard_type',
  GROUP = 'group',
  CROP_TYPE = 'crop_type',
  LAST_INSPECTION_INTERVAL = 'last_inspection_interval',
}

/**
 * Available values for the yard status filter category. This should
 * match the available values from BE.
 * */
export enum YardsFiltersStatusValuesKey {
  ALL_YARDS = 'all',
  ACTIVE_YARDS = 'active',
  INACTIVE_YARDS = 'inactive',
}

/**
 * Available values for the yard flag filter category. This should
 * match the available values from BE.
 * */
export enum YardsFiltersFlagValuesKey {
  REQUIRING_ATTENTION = 'requiring_attention',
  NEW_YARDS = 'new_yards',
  QUEEN_LESS = 'queenless',
}

/**
 * Available values for the last-inspection-interval filter category.
 * This should match the available values from BE.
 * */
export enum YardsFiltersLastInspectionIntervalValuesKey {
  BETWEEN_00_15 = 'between_00_15',
  BETWEEN_16_30 = 'between_16_30',
  BETWEEN_31_90 = 'between_31_90',
  BETWEEN_91_INF = 'between_91_inf',
}

/**
 * Available values for the other filter categories. This is dynamic,
 * and will automatically consider all values returned from BE.
 * */
export type YardsFiltersDefaultValueKey = string;
