// HOC re-written from npm install react-getscreen https://github.com/calinortan/react-getscreen

import React from 'react';

import APP from '@config/constants';

export const withGetScreenOptions = {
  mobileLimit: APP.mobileLimit,
  tabletLimit: APP.tabletLimit,
};

export const screenType = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

export const withGetScreen = () => {
  return (WrappedComponent) => {
    return class GetScreenSize extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          currentSize: this.getSize(window.innerWidth),
          width: window.innerWidth,
        };
        this.onResize = this.onResize.bind(this);
      }
      componentDidMount() {
        window.addEventListener('resize', this.onResize);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
      }
      onResize = () => {
        const newSize = this.getSize(window.innerWidth);
        if (newSize !== this.state.currentSize) {
          this.setState({
            currentSize: newSize,
          });
        }
        this.setState({
          width: window.innerWidth,
        });
      };
      getSize = (size) => {
        if (size <= withGetScreenOptions.mobileLimit) {
          return screenType.MOBILE;
        } else if (size >= withGetScreenOptions.tabletLimit) {
          return screenType.DESKTOP;
        } else {
          return screenType.TABLET;
        }
      };

      isMobile = () => {
        return this.state.currentSize === screenType.MOBILE;
      };
      isTablet = () => {
        return this.state.currentSize === screenType.TABLET;
      };
      isDesktop = () => {
        return this.state.currentSize === screenType.DESKTOP;
      };

      render() {
        const { width } = this.state;

        const detectMethods = {
          isMobile: this.isMobile,
          isTablet: this.isTablet,
          isDesktop: this.isDesktop,
          width,
        };
        return <WrappedComponent {...detectMethods} {...this.props} />;
      }
    };
  };
};
