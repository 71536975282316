import produce from 'immer';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isOpen: false,
  isMenuOpen: false,
  isPasswordOpen: false,
  isWorkerOpen: false,
  isActionOpen: false,
  isUpdateYardOpen: false,
  isCreateYardOpen: false,
  isDeleteYardOpen: false,
  isFilterOpen: false,
  isHiveOpen: false,
  isYardGroupsOpen: false,
  isManyGroupsOpen: false,
  isGroupOpen: false,
  zoom: null,
  contentObj: { modalType: 'none' },
};

export const modalReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.OPEN_MODAL:
        st.isOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_MODAL:
        st.isOpen = false;
        break;
      case actionTypes.OPEN_MAIN_MENU:
        st.isMenuOpen = true;
        break;
      case actionTypes.CLOSE_MAIN_MENU:
        st.isMenuOpen = false;
        break;
      case actionTypes.OPEN_PASSWORD_MODAL:
        st.isPasswordOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_PASSWORD_MODAL:
        st.isPasswordOpen = false;
        break;
      case actionTypes.OPEN_WORKER_MODAL:
        st.isWorkerOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_WORKER_MODAL:
        st.isWorkerOpen = false;
        break;
      case actionTypes.OPEN_ACTION_MODAL:
        st.isActionOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_ACTION_MODAL:
        st.isActionOpen = false;
        break;
      case actionTypes.OPEN_UPDATE_YARD_MODAL:
        st.isUpdateYardOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_UPDATE_YARD_MODAL:
        st.isUpdateYardOpen = false;
        break;
      case actionTypes.OPEN_CREATE_YARD_MODAL:
        st.isCreateYardOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_CREATE_YARD_MODAL:
        st.isCreateYardOpen = false;
        break;
      case actionTypes.SET_ZOOM:
        st.zoom = action.zoom;
        break;
      case actionTypes.RESET_YARD_ZOOM:
        st.zoom = null;
        break;
      case actionTypes.OPEN_HIVE_MODAL:
        st.isHiveOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_HIVE_MODAL:
        st.isHiveOpen = false;
        break;
      case actionTypes.OPEN_DELETE_YARD_MODAL:
        st.isDeleteYardOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_DELETE_YARD_MODAL:
        st.isDeleteYardOpen = false;
        break;
      case actionTypes.OPEN_GROUPS_MODAL:
        st.isYardGroupsOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_GROUPS_MODAL:
        st.isYardGroupsOpen = false;
        break;
      case actionTypes.OPEN_MANY_TO_MANY_GROUPS_MODAL:
        st.isManyGroupsOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_MANY_TO_MANY_GROUPS_MODAL:
        st.isManyGroupsOpen = false;
        break;
      case actionTypes.OPEN_GROUP_MODAL:
        st.isGroupOpen = true;
        st.contentObj = action.contentObj;
        break;
      case actionTypes.CLOSE_GROUP_MODAL:
        st.isGroupOpen = false;
        break;
      default:
    }
  });
};
