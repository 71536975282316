import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { until } from '@style/mediaQueries';

export const StyledModalWrapper = styled(Box)<{ $visible: boolean; $stackIndex: number; $stackReverseIndex: number }>(
  ({ theme, $visible, $stackIndex, $stackReverseIndex }) => css`
    position: fixed;
    inset: 0;
    align-items: center;
    flex-direction: column;
    z-index: ${theme.getZIndexOf('modal') + $stackIndex};
    transition: z-index 0ms linear ${theme.animations.durationLong}ms;
    pointer-events: none;

    ${StyledDialog}, ${StyledModalBackground}, ${StyledModalContentWrapper} {
      pointer-events: none;
    }

    ${$visible &&
    css`
      visibility: ${$stackReverseIndex > 2 ? 'hidden' : 'visible'};

      ${StyledDialog}, ${StyledModalBackground} {
        pointer-events: auto;
      }

      ${StyledModalBackground} {
        ${`background-color: rgba(0, 0, 0, ${0.5 / (2 * $stackReverseIndex + 1)});`}
      }

      ${$stackReverseIndex > 2 &&
      css`
        transition: visibility 0ms linear ${theme.animations.durationLong}ms;
        visibility: hidden;
      `}
    `}
  `
);

export const StyledModalBackground = styled.div(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: ${theme.animations.transitionLong('background')};
  `
);

export const StyledModalContentWrapper = styled(Box)<{ $stackReverseIndex: number }>(
  ({ theme, $stackReverseIndex }) => css`
    position: absolute;
    inset: 0;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    transition: ${theme.animations.transitionLong('transform')};
    transform: translateY(${sigmoid($stackReverseIndex) * -theme.spacing.lg}px)
      scaleX(${1 - Math.min(1.0, $stackReverseIndex * 0.1)});
  `
);

export const StyledModalFooter = styled.footer(
  ({ theme }) => `
  border-top: 1px solid ${theme.colors.grey03};
  padding: 16px 24px;
  `
);

export const StyledModalClose = styled(PrimaryButton).attrs({
  flat: true,
})(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing.md}px;
  right: ${theme.spacing.md}px;
  padding: 0;
  `
);

const StyledDialog = styled.div.attrs({
  role: 'dialog',
  'aria-modal': true,
})(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    border: none;

    box-shadow: ${theme.shadows.boxShadow04};

    &::backdrop {
      display: none;
    }
  `
);

export const StyledModal = styled(StyledDialog)<{ $width: string; $backgroundColor: string; open: boolean }>(
  ({ theme, $width, $backgroundColor }) => css`
    border-radius: ${theme.shape.paperRadius01}px;
    background-color: ${$backgroundColor};
    width: ${$width};
    height: fit-content;

    ${until('mobile')} {
      width: 100%;
      height: 100%;
    }

    opacity: 0;
    transform: translateY(100vh);
    transition: ${theme.animations.transitionLong('opacity', 'transform')};

    &[open] {
      opacity: 1;
      transform: translateY(0);
    }
  `
);

export const StyledModalMeerkat = styled(StyledDialog)<{ $width: number; $backgroundColor: string; open: boolean }>(
  ({ theme, $width, $backgroundColor }) => css`
    top: 35px;
    height: calc(100vh - 70px);
    width: ${$width}px;
    background-color: ${$backgroundColor};
    border-top-left-radius: ${theme.shape.paperRadius03}px;
    border-top-right-radius: ${theme.shape.paperRadius03}px;

    transform: translateY(110vh) scaleX(1);
    transition: ${theme.animations.transitionLong('transform')} 80ms;

    &[open] {
      transform: translateY(0);
    }
  `
);

/** Function to create a smooth ascending curve. */
function sigmoid(x: number, scale = 2.0): number {
  return scale * (1 / (1 + Math.exp(-x * 2.3)) - 0.5);
}
