import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Line } from '@components/common/Line';
import { YardDetailsDashboardAbout } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardAbout';
import { YardDetailsDashboardGroups } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardGroups';
import { YardDetailsDashboardLocation } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardLocation';
import { YardDetailsDashboardNotes } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardNotes';
import { YardDetailsDashboardSummary } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardSummary';

export const YardDetailsDashboard: React.VFC = () => {
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  if (!yard) {
    return null;
  }

  return (
    <Box fit stretch marginMD gapMD>
      <Box fit column stretch gapSM>
        <Box fit paper01 backgroundColor={'coreWhite'} borderWidth={1} borderColor={'grey03'} column stretch>
          <YardDetailsDashboardAbout />
          <Line />
          <YardDetailsDashboardGroups />
          <Line />
          <YardDetailsDashboardNotes />
        </Box>
        <Box fit paper01 backgroundColor={'coreWhite'} borderWidth={1} borderColor={'grey03'} column stretch>
          <YardDetailsDashboardLocation />
        </Box>
      </Box>
      <Box column stretch paper01 backgroundColor={'coreWhite'} borderWidth={1} borderColor={'grey03'}>
        <YardDetailsDashboardSummary />
      </Box>
    </Box>
  );
};
