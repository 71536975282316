import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';

import * as types from '../actionTypes';

import { makeLogoutSuccessAction } from './loginRequestActionCreator';
import { makeClosePasswordModalAction } from './modalActionCreator';
import { makeShowSnackbarAction } from './snackbarActionCreator';
/**
 *
 * @returns {{type: string}}
 */
export const makeChangePasswordRequestStartAction = () => ({
  type: types.CHANGE_PASSWORD_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeChangePasswordRequestSuccessAction = () => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeChangePasswordRequestFailureAction = () => ({
  type: types.CHANGE_PASSWORD_FAILURE,
});

export const removeUserAndPermissions = () => ({
  type: types.REMOVE_USER_AND_PERMISSIONS,
});

/**
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 *
 * Since reducers are supposed to be “pure” (as in, they don’t change
 * anything outside their scope) we can’t do any API calls or
 * dispatch actions from inside a reducer.
 * the redux-thunk middleware knows how to handle functions
 * It passes the dispatch method as an argument to the function,
 * thus making it able to dispatch actions itself.
 * @see https://github.com/reduxjs/redux-thunk
 *
 * @param credentials
 * @returns {Function}
 */
export const makeChangePasswordRequestThunk = (oldPassword, newPassword, history) => {
  const jsonData = JSON.stringify({
    old_password: oldPassword,
    new_password: newPassword,
  });

  return function (dispatch, getState) {
    // show the spinning screen:
    dispatch(makeChangePasswordRequestStartAction());

    fetch(ENDPOINTS.passwordChange, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then((res) => {
        if (!res.ok) {
          if (401 === res.status) {
            return { error: 'unauthorized' };
          }
          if (400 === res.status) {
            return res.json();
          }
        }
      })
      .then((jsonObj = {}) => {
        // here, capturing the following errors:
        // "new_password":"Must be different from old_password"
        // "old_password":"Wrong password"
        // "new_password":["This password is too common."]
        if ('unauthorized' === jsonObj.error) {
          dispatch(makeShowSnackbarAction(jsonObj.error));
          return;
        } else if ('old_password' in jsonObj) {
          dispatch(makeChangePasswordRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_wrong_old_password'));
          return;
        } else if ('new_password' in jsonObj) {
          dispatch(makeChangePasswordRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_wrong_new_password'));
          return;
        } else {
          dispatch(makeChangePasswordRequestSuccessAction());
          dispatch(makeClosePasswordModalAction());
          dispatch(makeShowSnackbarAction('snack_password_changed_msg'));
          dispatch(removeUserAndPermissions());
          dispatch(makeLogoutSuccessAction());
          history.push(APP.routes.login);
        }
      });
  };
};
