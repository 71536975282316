import React, { useEffect, useMemo, useState } from 'react';

import {
  StyledMapToolbar,
  StyledMapToolbarContent,
  StyledMapToolbarWrapper,
} from '@components/common/Map/components/styles';
import { MapContextProvider } from '@components/common/Map/context';
import { useMapChildren } from '@components/common/Map/hooks';
import { MapToolbarProps } from '@components/common/Map/types';

const DEF_TOOLBAR_HEIGHT = 56;

export const MapToolbar: React.VFC<MapToolbarProps> = ({ expanded, onExpanded, children }) => {
  const [expandedState, setExpandedState] = useState(false);
  const [map, ...elements] = useMapChildren(children);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onExpanded && onExpanded(expandedState), [expandedState]);
  useEffect(() => (typeof expanded !== 'undefined' ? setExpandedState(expanded) : void 0), [expanded]);

  const height = useMemo(() => (expandedState ? DEF_TOOLBAR_HEIGHT : 0), [expandedState]);

  return (
    <MapContextProvider>
      <StyledMapToolbar>
        <StyledMapToolbarContent height={height}>{elements}</StyledMapToolbarContent>
        <StyledMapToolbarWrapper top={height}>{map}</StyledMapToolbarWrapper>
      </StyledMapToolbar>
    </MapContextProvider>
  );
};
