import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

// icons
import plusIcon from '@assets/Small_Plus.svg';
// nectar:
import { ModalContainer } from '@components/deprecated/Elements/ModalElements';
import { StyledCloseButton, StyledInput, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import { GroupColorsPalleteView } from '@components/deprecated/GroupColors/GroupColorsPalleteView';
import { GroupColorsView } from '@components/deprecated/GroupColors/GroupColorsView';
import { Select } from '@components/deprecated/Select/Select';
import { getGroupColor } from '@helpers/deprecated/color';
import theme from '@style/theme';

const ScrollContainer = styled.div`
  margin-top: 0.5rem;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledRow = styled.div`
  display: flex;
  position: relative;
  padding-top: 0.5rem;
  cursor: pointer;
  align-items: center;
`;

export const AssignYardsGroupsModalView = ({
  t,
  isMobile,
  handleSubmit,
  handleClose,
  groups,
  assignedGroups,
  createGroup,
  yard,
  name,
  modalType,
  assign_group_ids,
  handleSelectGroup,
  toggleCreateGroup,
  handleAddNewGroupName,
  handleChangeExistingGroupName,
  inputRef,
  buttonRef,
  setSelectedGroupColor,
  heading,
  isLoading,
}) => {
  const [hover, handleHover] = useState(false);
  const [color, showColorSchema] = useState(false);
  const [contentScroll, setContentScroll] = useState(0);

  return (
    <ModalContainer isMobile={isMobile} disabled={isLoading}>
      <section>
        <StyledHeading2
          style={{
            paddingBottom: '0.5rem',
            borderBottom: theme.secondaryBorderStyle,
          }}
          mobile={isMobile}
        >
          {heading}
        </StyledHeading2>
        <ScrollContainer
          onScroll={(event) => {
            setContentScroll(event.target.scrollTop);
            showColorSchema(false);
          }}
        >
          {groups?.map((group, idx) => (
            <Fragment key={idx}>
              <StyledRow>
                <button
                  type="checkbox"
                  style={{ marginRight: '1rem' }}
                  onMouseOver={() => handleHover(group.id)}
                  onMouseLeave={() => handleHover(false)}
                  onClick={() => handleSelectGroup(group)}
                >
                  <Select
                    id={`${group.id}`}
                    type="checkbox"
                    conditional={'assign-yard-groups' === modalType ? false : 'some' === assignedGroups[group.id]}
                    src={
                      'assign-yard-groups' === modalType
                        ? yard?.group_ids.includes(group.id)
                        : 'all' === assignedGroups[group.id] || assign_group_ids.includes(group.id)
                    }
                    hover={group.id === hover}
                    altActive="select group enabled"
                    altInactive="select group disabled"
                    tooltip={false}
                  />
                </button>
                <StyledInput
                  id={group.id}
                  name={group.name}
                  value={group.name}
                  type="text"
                  required={true}
                  onChange={(e) => handleChangeExistingGroupName(e, group)}
                />

                <GroupColorsView
                  onClick={() => showColorSchema(group.id)}
                  background={getGroupColor(groups, group.id)}
                />
              </StyledRow>
              {color === group.id && (
                <GroupColorsPalleteView
                  setSelectedGroupColor={(key) => {
                    showColorSchema(false);
                    setSelectedGroupColor(key, group);
                  }}
                  scrollTop={contentScroll}
                />
              )}
            </Fragment>
          ))}
          <StyledRow style={{ display: !createGroup ? 'flex' : 'none', alignItems: 'center' }}>
            <button
              ref={buttonRef}
              style={{ display: 'inherit' }}
              onClick={() => toggleCreateGroup(inputRef, buttonRef)}
            >
              <img src={plusIcon} alt="create a group on the fly" style={{ marginRight: '1rem' }} />
              <StyledParagraph>{t('create_new_group')}</StyledParagraph>
            </button>
          </StyledRow>
          <StyledRow style={{ display: createGroup ? 'flex' : 'none', alignItems: 'center' }}>
            <button
              type="checkbox"
              style={{ marginRight: '1rem' }}
              onMouseOver={() => handleHover('new_group')}
              onMouseLeave={() => handleHover(false)}
            >
              <Select
                id="new_group"
                type="checkbox"
                src={true}
                hover={'new_group' === hover}
                altActive="new group enabled"
                altInactive="new group disabled"
                tooltip={false}
              />
            </button>

            <StyledInput
              ref={inputRef}
              id="new_group"
              name="new_group"
              value={name}
              type="text"
              required
              autoFocus
              onChange={handleAddNewGroupName}
              onBlur={(e) => {
                if (e.target.value === '') toggleCreateGroup(inputRef, buttonRef);
              }}
            />
            <GroupColorsView background={getGroupColor()} />
          </StyledRow>
        </ScrollContainer>
      </section>

      <footer>
        <div>
          <StyledCloseButton type="button" label={t('cancel')} onClick={handleClose} disabled={false} />
          <StyledSubmitButton type="button" label={t('assign')} onClick={handleSubmit} disabled={false} height="2rem" />
        </div>
      </footer>
    </ModalContainer>
  );
};

AssignYardsGroupsModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  inputRef: PropTypes.object.isRequired,
  buttonRef: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  createGroup: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleSelectGroup: PropTypes.func.isRequired,
  toggleCreateGroup: PropTypes.func.isRequired,
  handleAddNewGroupName: PropTypes.func.isRequired,
  handleChangeExistingGroupName: PropTypes.func.isRequired,
  setSelectedGroupColor: PropTypes.func.isRequired,
  assign_group_ids: PropTypes.array,
  assignedGroups: PropTypes.object,
  groups: PropTypes.array,
  yard: PropTypes.object,
  isLoading: PropTypes.bool,
};
