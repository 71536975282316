import React from 'react';

import { StyledNavLink } from './styles';
import { NavRouterLinkProps } from './types';

// not sure if ref will work, might need to add fwdRef later

export const NavRouterLink: React.FC<NavRouterLinkProps> = ({
  children,
  unstyled,
  primary,
  secondary,
  tertiary,
  elevated,
  rounded,
  withIconLeft,
  withIconRight,
  paddingHorizontal = true,
  paddingVertical = true,
  ...props
}) => {
  return (
    <StyledNavLink
      $unstyled={unstyled}
      $primary={primary}
      $secondary={secondary}
      $tertiary={tertiary}
      $elevated={elevated}
      $rounded={rounded}
      $withIconLeft={withIconLeft}
      $withIconRight={withIconRight}
      $paddingHorizontal={paddingHorizontal}
      $paddingVertical={paddingVertical}
      {...props}
    >
      {children}
    </StyledNavLink>
  );
};
