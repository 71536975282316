import React, { Fragment, useCallback } from 'react';
import { generatePath } from 'react-router-dom';

import { HiveActivityCard } from '@components/hive/HiveActivityCard';
import { TimelineBase } from '@components/timeline/TimelineBase';
import { StyledHeading, StyledYardBoxInfo, StyledYardNameLink } from '@components/timeline/TimelineBase/styles';
import { TimelineByActivityRequestProps } from '@components/timeline/TimelineByActivityRequest/types';
import APP from '@config/constants';
import { Children } from '@helpers/Children';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useTranslation } from '@hooks/useTranslation';

export const TimelineByActivityRequest: React.VFC<TimelineByActivityRequestProps> = ({
  highlightedHiveId,
  ...props
}) => {
  const t = useTranslation();

  const itemHeadingRenderer = useCallback(
    (item: BeeActivityByRequest) => {
      let alertTypeLabel: any;

      if (highlightedHiveId || item.alertType === 'new_inspection') {
        const highlightedHiveIdentity = item.hiveIdentities.find(({ id }) => id === highlightedHiveId);

        const isHiveCreated = highlightedHiveIdentity?.isNew && item.alertType === 'hive_added_to_yard';
        const isHiveMoved = !highlightedHiveIdentity?.isNew && item.alertType !== 'new_inspection';
        const isHiveMovedThroughManagement = isHiveMoved && item.alertType === 'hive_managed_to_yard';

        const alertTypeTranslation = isHiveMovedThroughManagement
          ? 'hive_reassigned_to'
          : isHiveMoved
          ? 'hive_moved_to'
          : isHiveCreated
          ? 'hive_created_at'
          : 'inspection_at';

        alertTypeLabel = <span dangerouslySetInnerHTML={{ __html: t(alertTypeTranslation) }} />;
      } else {
        alertTypeLabel = (
          <span>
            <strong>
              {maybePluralize(item.hiveIdentities.length, 'hive', t)}{' '}
              {t(item.alertType === 'hive_added_to_yard' ? 'added' : 'reassigned')}
            </strong>{' '}
            {t('to')}
          </span>
        );
      }

      const yardsLabel = Children.createWithSeparators(item.yards, {
        itemWrapper: (item, separator) => (
          <>
            {item.isDeleted ? (
              <strong>{item.name}</strong>
            ) : (
              <StyledYardNameLink to={generatePath(APP.routes.yard, { uid: item.id })} target={'_blank'}>
                {item.name}
              </StyledYardNameLink>
            )}{' '}
            <StyledYardBoxInfo typography={'CaptionSmall'}>
              {item.isDeleted ? t('deleted') : item.nbHives}
            </StyledYardBoxInfo>
            {separator}
          </>
        ),
      });
      const forHivesLabel =
        item.alertType === 'new_inspection' ? (
          <span>
            {t('for')} <strong>{maybePluralize(item.hiveIdentities.length, 'hive', t)}</strong>
          </span>
        ) : null;
      const byLabel = `${t('by')} ${item.workerName}`;

      const throughLabel =
        item.alertType === 'hive_managed_to_yard' ? (
          <span dangerouslySetInnerHTML={{ __html: t('through_managers_portal') }} />
        ) : null;

      const elements = [alertTypeLabel, yardsLabel, forHivesLabel, byLabel, throughLabel].filter(Boolean);

      return (
        <StyledHeading typography={'SmallParagraph'}>
          {elements.map((el, index) => (
            <Fragment key={index}>
              {index > 0 ? ' ' : ''}
              {el}
            </Fragment>
          ))}
          {'.'}
        </StyledHeading>
      );
    },
    [highlightedHiveId, t]
  );

  const itemContentRenderer = useCallback(
    (item: BeeActivityByRequest) => {
      return <HiveActivityCard activity={item} highlightedHiveId={highlightedHiveId} />;
    },
    [highlightedHiveId]
  );

  return <TimelineBase {...{ itemHeadingRenderer, itemContentRenderer }} {...props} />;
};
