//returns the category name or icon based on input
import { isEmptyArray } from '@helpers/deprecated/array';
import { getIconByType } from '@helpers/deprecated/icons';

export const getActionCategory = (type, actionList, id, size) => {
  if (!type || isEmptyArray(actionList)) return;
  let categoryName;
  actionList.forEach((categories) => {
    categories.actions.forEach((a) => {
      if (a.id === id) {
        return (categoryName = categories.category_name);
      } else return;
    });
  });

  switch (type) {
    case 'name':
      return categoryName;
    case 'icon':
      return getIconByType('actions', categoryName, size);
    default:
      return;
  }
};

export const getActionName = (actionId, actionList) => {
  return getAction(actionId, actionList)?.detail ?? null;
};

export const getActionFlag = (actionId, actionList) => {
  return getAction(actionId, actionList)?.flag ?? null;
};

export const getAction = (actionId, actionList) => {
  if (!actionList) {
    return null;
  }
  for (const category of actionList) {
    for (const action of category.actions) {
      if (String(action.id) === String(actionId)) {
        return action;
      }
    }
  }
};
