import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Line } from '@components/common/Line';
import { Modal } from '@components/common/Modal';
import { Text } from '@components/common/Text';

export interface ConfirmationModalOptions {
  title: string;
  text?: string;
  acceptText?: string;
  rejectText?: string;
  onAccept?: ConfirmationCallback;
  onReject?: ConfirmationCallback;
}

export interface ConfirmationModalReturn {
  showConfirmation: (options: ConfirmationModalOptions) => void;
  modalContent: ReactNode;
}

export type ConfirmationCallback = () => void;

const StyledButton = styled.button(
  ({ theme }) => `
  width: 111px;
  height: 32px;
  background-color: ${theme.colors.grey08};
  color: ${theme.colors.coreWhite};
  border-radius: ${theme.shape.paperRadius01}px;
  text-transform: uppercase;
  
  &:hover {
    background-color: ${theme.colors.grey06};
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02};
  }
`
);

const StyledButtonFlat = styled(StyledButton)(
  ({ theme }) => `
  background-color: ${theme.colors.coreWhite};
  color: ${theme.colors.grey08};
  
  &:hover {
    background-color: ${theme.colors.coreWhite};
    color: ${theme.colors.grey06};
  }
`
);

const StyledText = styled(Text)(
  ({ theme }) => `
  margin-top: ${theme.spacing.sm}px;
  strong {
    font-weight: 700;
  }
`
);

export function useConfirmationModal(): ConfirmationModalReturn {
  const [open, setOpen] = useState(false);
  const options = useRef<ConfirmationModalOptions | null>(null);

  const buildHandler = useCallback((handler?: ConfirmationCallback) => {
    return () => {
      setOpen(false);
      handler && handler();
      options.current = null;
    };
  }, []);

  const modalContent = useMemo(() => {
    if (!options.current) {
      return null;
    }

    const { title, text, acceptText, rejectText, onAccept, onReject } = options.current;

    return (
      <Modal isOpen={open} size={'small'}>
        <Box column stretch>
          <Box column center marginTopLG marginBottomMD marginHorizontalMD>
            <Text typography={'Heading2'} weight={'600'} align={'center'}>
              {title}
            </Text>
            {text ? (
              <StyledText typography={'Paragraph'} align={'center'} dangerouslySetInnerHTML={{ __html: text }} />
            ) : null}
          </Box>
          <Line />
          <Box center paddingSM>
            <StyledButtonFlat onClick={buildHandler(onReject)}>
              <Text typography={'CTA'} weight={'700'}>
                {rejectText}
              </Text>
            </StyledButtonFlat>
            <StyledButton onClick={buildHandler(onAccept)}>
              <Text typography={'CTA'} weight={'700'}>
                {acceptText}
              </Text>
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    );
  }, [buildHandler, open]);

  const showConfirmation = useCallback((nextOptions: ConfirmationModalOptions) => {
    options.current = nextOptions;
    setOpen(true);
  }, []);

  return { showConfirmation, modalContent };
}
