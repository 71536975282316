import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const StyledAboutItem = styled(Box)(
  () => css`
    min-width: 145px;
  `
);

export const StyledNotesUpdatedLabel = styled(Text).attrs({
  typography: 'SmallParagraph',
  color: 'grey07',
})(
  () => css`
    font-style: italic;
  `
);

export const StyledMapWrapper = styled(Box)(
  () => css`
    position: relative;
    width: 100%;
    height: 316px;
    pointer-events: none;
  `
);

export const StyledSummary = styled(Box)(
  () => css`
    width: 350px;
  `
);

export const StyledSummaryItem = styled(Box).attrs({
  alignItems: 'center',
  gapSM: true,
})(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing.sm}px;

    &:not(:last-child) {
      border-bottom: ${theme.colors.grey03} 1px solid;
    }
  `
);

export const StyledSummaryValueTitle = styled(Text)(
  ({ theme }) => css`
    width: calc(50% - ${theme.spacing.sm}px);
  `
);

export const StyledSummaryValueText = styled(Text)(
  () => css`
    text-align: right;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
);
