import styled from 'styled-components';

export const ModalContainer = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.coreWhite};
  text-align: ${(props) => props.smallModal && 'center'};
  border-radius: ${(props) => !props.isMobile && props.theme.defaultBorderRadius};

  &[disabled] {
    pointer-events: none;
  }

  & > section {
    padding: ${(props) => (props.smallModal ? '3rem' : '3rem 1.5rem')};
    margin-bottom: ${(props) => props.theme.modalFooterHeight};
  }
  & > footer {
    display: flex;
    align-items: center;
    width: 100%;
    //to do this top border
    box-shadow: inset 0 1px 0 0 #dedede;
    position: absolute;
    bottom: 0;
    height: ${(props) => props.theme.modalFooterHeight};
    background-color: inherit;
    justify-content: ${(props) => props.smallModal && 'center'};
    border-bottom-left-radius: ${(props) => !props.isMobile && props.theme.defaultBorderRadius};
    border-bottom-right-radius: ${(props) => !props.isMobile && props.theme.defaultBorderRadius};
    div {
      position: absolute;
      right: ${(props) => !props.smallModal && 0};
    }
  }
`;
export const IsFetching = styled.button`
  height: 100%;
  float: left;
  margin-left: 3rem;
  cursor: default;
`;

export const StyledLabel = styled.label`
  position: relative;
  top: 10px;
  left: 1rem;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  overflow: hidden;
  color: ${(props) => (props.disabled ? 'rgba(55,67,65,0.6)' : props.theme.supportingColor)};
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
  letter-spacing: 0;
  z-index: ${(props) => props.theme.getZIndexOf('content')};
`;
