import React, { ComponentType, RefAttributes, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '@components/common/Icon/presets/Search';
import { MapApiProviders } from '@components/common/Map/apis';
import { useMap } from '@components/common/Map/hooks';
import { MapSearchBoxProps } from '@components/common/Map/types';

import { StyledSearchBox, StyledSearchBoxClear } from './styles';

export const MapSearchBox: React.VFC<MapSearchBoxProps> = ({ ...props }) => {
  const [value, setValue] = useState('');

  const { api } = useMap();
  const { t } = useTranslation();
  const SearchBoxRenderer = useMemo<ComponentType<MapSearchBoxProps & RefAttributes<HTMLInputElement | null>>>(
    () => MapApiProviders[api].searchBoxRenderer,
    [api]
  );

  const hasSearch = !!value;

  const clear = useCallback(() => {
    setValue('');
  }, []);

  return (
    <StyledSearchBox disabled={props.disabled}>
      <SearchBoxRenderer value={value} onChange={({ target }) => setValue(target.value)} {...props} />
      <Search size={24} />
      <StyledSearchBoxClear onClick={clear} visible={hasSearch} flat>
        {t('clear')}
      </StyledSearchBoxClear>
    </StyledSearchBox>
  );
};
