export const sortList = (list, col) => {
  return list.sort((a, b) => {
    if (!b[col] && !a[col]) return 0;
    if (!a[col]) return -1;
    if (!b[col]) return 1;
    if (a[col] < b[col] || a[col] > b[col])
      return String(a[col]).localeCompare(String(b[col]), undefined, { numeric: true });
    else return 0;
  });
};

// even though JS .sort method is said to work well for stability
// https://en.wikipedia.org/wiki/Sorting_algorithm#Stability
// here is an addition helper method to stable sort
// source and implementation samples for fast and stable sorting:
// https://stackoverflow.com/questions/1427608/fast-stable-sorting-algorithm-implementation-in-javascript
export const stableSort = (arr, compare) =>
  arr
    .map((item, index) => ({ item, index }))
    .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item);
