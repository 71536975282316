import TagManager from 'react-gtm-module';

import config from '@config/config';
import { AnalyticsEvent } from '@helpers/Analytics/types';

export const Analytics = {
  initialize,
  sendEvent,
};

function initialize() {
  TagManager.initialize({
    gtmId: config.REACT_APP_GTM_ID as string,
  });
  return Analytics;
}

function sendEvent({ event, ...data }: AnalyticsEvent) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...((data as any).eventData ?? {}),
    },
  });
  return Analytics;
}
