import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';
import { isArray, sortBy } from 'lodash';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { Modal, ModalFooter } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { Text } from '@components/common/Text';
import { Checkbox } from '@components/form/CheckBox';
import { DEF_PERMANENT_COLUMNS_KEYS, DEF_SORTING, DEF_VISIBLE_COLUMNS } from '@components/yard/YardsList/constants';
import { useColumnNameGetter } from '@components/yard/YardsList/hooks/useColumnNameGetter';
import { useColumnNameGetterForAnalytics } from '@components/yard/YardsList/hooks/useColumnNameGetterForAnalytics';
import { ColumnType } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

import { useGridApi } from '../YardsList/hooks';

import { StyledColumnOption, StyledWrapper } from './styles';
import { YardsListCategoryAccordion } from './YardsListCategoryAccordion';

export const YardsListColumnsModal: React.VFC<ModalProps> = ({ ...props }) => {
  const [transientColumnsDefs, setTransientColumnDefs] = useState<Record<string, ColDef>>({});
  const { gridApi } = useGridApi();
  const { t } = useTranslation();

  const getColumnName = useColumnNameGetter();
  const getColumnNameForAnalytics = useColumnNameGetterForAnalytics();

  const getCurrentGridColumnDefs = useCallback(() => {
    return GridApiUtil.getColumnDefsWithMetadata(GridApiUtil.getFlattenColumnDefs(gridApi?.getColumnDefs() ?? []));
  }, [gridApi]);

  const transientDefaultColumnsDefs = useMemo(() => {
    const defaultColumnsIndexes = Object.values(DEF_VISIBLE_COLUMNS).reduce(
      (acc, def, index) => ({ ...acc, [def.field as string]: index }),
      {} as Record<string, number>
    );
    return Object.values(transientColumnsDefs)
      .filter((columnDef) => {
        const { metadata } = GridApiUtil.getColumnDefWithMetadata(columnDef);
        return metadata.column.type === ColumnType.DEFAULT;
      })
      .sort((colA, colB) => defaultColumnsIndexes[colA.field as string] - defaultColumnsIndexes[colB.field as string]);
  }, [transientColumnsDefs]);

  const transientPracticeColumnGroupedDefs = useMemo(() => {
    const practiceColumns = Object.values(transientColumnsDefs).filter((col) => {
      const { metadata } = GridApiUtil.getColumnDefWithMetadata(col);
      return metadata.column.type === ColumnType.PRACTICE || metadata.column.type === ColumnType.PRACTICE_CATEGORY;
    });

    return GridApiUtil.getDefsWithGroupedColumns(practiceColumns)
      .map((group) => {
        const practiceCategory = group.children.find((col) => {
          const { metadata } = GridApiUtil.getColumnDefWithMetadata(col);
          return metadata.column.type === ColumnType.PRACTICE_CATEGORY;
        });

        const abcSorted = sortBy(
          group.children.filter((col) => {
            const { metadata } = GridApiUtil.getColumnDefWithMetadata(col);
            return metadata.column.type === ColumnType.PRACTICE;
          }),
          'headerName'
        ) as Array<ColDef<any>>;

        practiceCategory && abcSorted.unshift(practiceCategory);
        return abcSorted;
      })
      .sort((groupA, groupB) => groupA[0]?.headerName?.localeCompare(groupB[0]?.headerName ?? '') ?? 0);
  }, [transientColumnsDefs]);

  const closeModal = useCallback(() => {
    props.onRequestClose && props.onRequestClose();
  }, [props]);

  const sendGTMEvent = useCallback(() => {
    Analytics.sendEvent({
      event: AnalyticsEventType.WHITEBOARD_COLUMNS_CHANGE,
      eventData: {
        origin: 'management-modal',
        visible_columns: GridApiUtil.getNonAbstractVisibleColumnDefs(getCurrentGridColumnDefs())
          .map(getColumnNameForAnalytics)
          .join(', '),
      },
    });
  }, [getColumnNameForAnalytics, getCurrentGridColumnDefs]);

  const submit = useCallback(() => {
    if (!gridApi) {
      return;
    }

    const transientDefs = Object.values(transientColumnsDefs);
    const patchedDefs = transientDefs.reduce(GridApiUtil.getPatchedColumnDefsUsingId, []);

    if (GridApiUtil.updateGridColumns(gridApi, patchedDefs)) {
      gridApi.refreshInfiniteCache();
      sendGTMEvent();
    }

    closeModal();
  }, [closeModal, gridApi, sendGTMEvent, transientColumnsDefs]);

  const restoreToDefault = useCallback(() => {
    if (!gridApi) {
      return;
    }

    let defs = getCurrentGridColumnDefs();

    defs.forEach((colDef) => {
      // Reset width
      colDef.width = 0;

      // Reset sorting status
      colDef.sort = colDef.field === DEF_SORTING[0].field ? DEF_SORTING[0].sort : null;

      // Reset visibility
      colDef.hide = colDef.metadata?.column?.type !== ColumnType.DEFAULT;

      // Reset active view
      colDef.metadata = {
        ...colDef.metadata,
        activeView: GridApiUtil.getDefaultView(colDef.metadata?.column)?.key,
      };
    });

    // Reset indexes.
    const columnsIndexes = DEF_VISIBLE_COLUMNS.reduce(
      (indexes, colDef, index) => ({
        ...indexes,
        [colDef.field as string]: index,
      }),
      {} as Record<string, number>
    );
    defs = defs.sort((a, b) => {
      return columnsIndexes[a.field as string] - columnsIndexes[b.field as string];
    });

    if (GridApiUtil.updateGridColumns(gridApi, defs)) {
      gridApi.refreshInfiniteCache();
      sendGTMEvent();
    }

    closeModal();
  }, [closeModal, getCurrentGridColumnDefs, gridApi, sendGTMEvent]);

  const toggleColumn = useCallback((columnDef: ColDef | ColDef[], shouldHide?: boolean) => {
    if (isArray(columnDef)) {
      const updatedColumns: Record<string, ColDef<any>> = {};
      columnDef.forEach((col) => {
        const hide = shouldHide === undefined ? !col.hide : shouldHide;
        updatedColumns[col.colId as string] = { ...col, hide };
      });
      setTransientColumnDefs((curr) => ({
        ...curr,
        ...updatedColumns,
      }));
    } else {
      setTransientColumnDefs((curr) => ({
        ...curr,
        [columnDef.colId as string]: { ...columnDef, hide: !columnDef.hide },
      }));
    }
  }, []);

  const shouldHideColumn = useCallback((columnDef: ColDef) => {
    return GridApiUtil.isColumnDef(columnDef) && DEF_PERMANENT_COLUMNS_KEYS.includes(columnDef.field);
  }, []);

  const renderDefaultColumnCheckbox = useCallback(
    (columnDef: ColDef) => {
      if (shouldHideColumn(columnDef)) {
        return null;
      }

      return (
        <StyledColumnOption key={columnDef.colId} tag={'label'} alignItems="center">
          <Checkbox value={!columnDef.hide} name={columnDef.colId as string} onChange={() => toggleColumn(columnDef)} />
          <Box marginLeftSM>
            <Text typography={'Heading3'} weight={'600'}>
              {getColumnName(columnDef, columnDef.headerName)}
            </Text>
          </Box>
        </StyledColumnOption>
      );
    },
    [getColumnName, shouldHideColumn, toggleColumn]
  );

  useEffect(() => {
    if (props.isOpen) {
      const columns = gridApi?.getColumnDefs() ?? [];

      const flattenColumns = GridApiUtil.getColumnDefsWithMetadata(GridApiUtil.getFlattenColumnDefs(columns));

      setTransientColumnDefs(flattenColumns.reduce((dict, def) => ({ ...dict, [def.colId as string]: def }), {}));
    }
  }, [gridApi, props.isOpen]);

  return (
    <Modal backgroundColor={'coreWhite'} {...props}>
      <Box block paddingMD>
        <Box block marginBottomMD>
          <Text typography="SmallParagraph" weight="600">
            {t('manage_columns_header')}
          </Text>
          <Text typography="SmallParagraph">{t('manage_columns_subheader')}</Text>
        </Box>
        <StyledWrapper>
          <Box block paddingHorizontalSM paddingBottomSM>
            <StyledColumnOption>
              <Text typography="Legend" color="grey06">
                {t('default_columns')}
              </Text>
            </StyledColumnOption>
            {transientDefaultColumnsDefs.map(renderDefaultColumnCheckbox)}
            <StyledColumnOption marginTopSM>
              <Text typography="Legend" color="grey06">
                {t('custom_columns')}
              </Text>
            </StyledColumnOption>
            {transientPracticeColumnGroupedDefs.map((customColumns) => (
              <YardsListCategoryAccordion
                key={customColumns[0].colId}
                group={customColumns}
                toggleColumn={toggleColumn}
              />
            ))}
          </Box>
        </StyledWrapper>
      </Box>
      <ModalFooter>
        <Box justifyContent="space-between">
          <PrimaryButton flat flush type="button" size={'small'} onClick={restoreToDefault}>
            {t('restore_default')}
          </PrimaryButton>
          <Box>
            <PrimaryButton flat type="button" size={'small'} onClick={closeModal}>
              {t('cancel')}
            </PrimaryButton>
            <PrimaryButton onClick={submit}>{t('apply')}</PrimaryButton>
          </Box>
        </Box>
      </ModalFooter>
    </Modal>
  );
};
