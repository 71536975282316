import styled from 'styled-components';

export const StyledPageTitle = styled.h1`
  position: relative;
  font-size: 8rem;
  font-weight: 400;
`;

export const StyledLandOn = styled.span`
  position: absolute;
  display: block;
  inset: 20% 10%;
`;
