const MAX_TILE_SIZE = 90;
const TILE_SIZES_COUNT = 20;

// Tile sizes are a sequence of subdivisions of MAX_TILE_SIZE, where
// the next number is a half of the previous one.
// E.g: 90, 45, 22.5, etc...
const TILE_SIZES = new Array(TILE_SIZES_COUNT).fill(null).map((_, i) => MAX_TILE_SIZE / Math.pow(2, i));

export interface MapElement {
  id: any;
  lat: number;
  lng: number;
}

export interface MapTile {
  key: string;
  size: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface MapBounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

export class MapRenderingOptimizer {
  elementsPositionalTree: Record<number, Record<string, Record<any, MapElement>>> = {};

  constructor(elements?: Array<MapElement>) {
    elements?.forEach(this.addElement);
  }

  public getVisibleElements(bounds: MapBounds): Record<any, MapElement> {
    const tiles = this.getTilesWithinBounds(bounds);
    let visibleElements = {} as Record<string, MapElement>;

    for (const { key, size } of tiles) {
      const elements = this.getPositionalMap(key, size);
      visibleElements = { ...visibleElements, ...elements };
    }

    return visibleElements;
  }

  public getVisibleTiles(bounds: MapBounds): Array<MapTile> {
    return this.getTilesWithinBounds(bounds);
  }

  public addElement(element: MapElement) {
    TILE_SIZES.forEach((tileSize) => {
      const elementsPositionalMap = this.getElementPositionalMap(element, tileSize);
      elementsPositionalMap[element.id] = element;
    });
  }

  public removeElement(element: MapElement) {
    TILE_SIZES.forEach((tileSize) => {
      const elementsPositionalMap = this.getElementPositionalMap(element, tileSize);
      delete elementsPositionalMap[element.id];
    });
  }

  private getPositionalMap(positionalKey: string, tileSize: number) {
    if (!this.elementsPositionalTree[tileSize]) {
      this.elementsPositionalTree[tileSize] = {};
    }
    if (!this.elementsPositionalTree[tileSize][positionalKey]) {
      this.elementsPositionalTree[tileSize][positionalKey] = {};
    }
    return this.elementsPositionalTree[tileSize][positionalKey];
  }

  private getElementPositionalMap(element: MapElement, tileSize: number) {
    const positionalKey = this.getElementPositionalKey(element, tileSize);
    return this.getPositionalMap(positionalKey, tileSize);
  }

  private getElementPositionalKey(element: MapElement, tileSize: number): string {
    const lat = Math.floor(element.lat / tileSize);
    const lng = Math.floor(element.lng / tileSize);
    return this.getPositionalKey(lat, lng);
  }

  private getPositionalKey(lat: number, lng: number): string {
    return `${lat}-${lng}`;
  }

  private getTilesWithinBounds({ north, south, east, west }: MapBounds): Array<MapTile> {
    const tileSize = this.getTileSizeFromMapBounds({ north, south, east, west });
    const topmostTile = Math.floor(north / tileSize);
    const bottommostTile = Math.floor(south / tileSize);
    const leftmostTile = Math.floor(west / tileSize);
    const rightmostTile = Math.floor(east / tileSize);

    const tiles = [] as Array<MapTile>;

    for (let latTile = bottommostTile; latTile <= topmostTile; latTile++) {
      for (let lngTile = leftmostTile; lngTile <= rightmostTile; lngTile++) {
        tiles.push({
          key: this.getPositionalKey(latTile, lngTile),
          size: tileSize,
          top: latTile * tileSize,
          bottom: (latTile + 1) * tileSize,
          left: lngTile * tileSize,
          right: (lngTile + 1) * tileSize,
        });
      }
    }

    return tiles;
  }

  private getTileSizeFromMapBounds({ north, south, east, west }: MapBounds) {
    const boundsLatGap = north - south;
    const boundsLngGap = east - west;
    const boundsGap = Math.max(boundsLatGap, boundsLngGap);
    return TILE_SIZES.sort((a, b) => Math.abs(a - boundsGap) - Math.abs(b - boundsGap))[0];
  }
}
