import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ChevronUp = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M6.30507 14.707C6.69557 15.0974 7.32857 15.0974 7.71907 14.707L12.0121 10.414L16.3051 14.707C16.6975 15.0859 17.3212 15.0805 17.7069 14.6948C18.0926 14.3091 18.098 13.6853 17.7191 13.293L12.7191 8.29297C12.3286 7.90259 11.6956 7.90259 11.3051 8.29297L6.30507 13.293C5.91469 13.6835 5.91469 14.3165 6.30507 14.707V14.707Z"
      />
    </IconSVGView>
  );
};
