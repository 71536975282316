import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Pollination = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5.9999C19.7614 5.9999 22 8.23847 22 10.9999C21.9979 12.7157 21.1136 14.3098 19.659 15.2199C20.4707 17.2989 19.7983 19.664 18.0143 21.005C16.2303 22.346 13.7714 22.3345 12 20.9769C10.228 22.3309 7.7716 22.3406 5.98898 21.0007C4.20635 19.6607 3.5329 17.2984 4.34101 15.2199C2.45408 14.0372 1.57895 11.7476 2.19603 9.60787C2.81312 7.46816 4.77309 5.99614 7.00001 5.9999C7.01797 5.9999 7.03538 6.00241 7.05282 6.00493C7.06839 6.00717 7.08397 6.00942 7.10001 6.0099C7.5732 3.67957 9.62213 2.00488 12 2.00488C14.3779 2.00488 16.4268 3.67957 16.9 6.0099C16.916 6.00942 16.9316 6.00717 16.9472 6.00493C16.9646 6.00241 16.982 5.9999 17 5.9999ZM13.683 13.4509L16.1 12.5719H16.101C16.6092 12.3757 16.8668 11.8088 16.6805 11.297C16.4941 10.7851 15.9324 10.5165 15.417 10.6929L13 11.5719V8.9999C13 8.44761 12.5523 7.9999 12 7.9999C11.4477 7.9999 11 8.44761 11 8.9999V11.5719L8.58401 10.6929C8.06862 10.5165 7.50688 10.7851 7.32054 11.297C7.13421 11.8088 7.39185 12.3757 7.90001 12.5719L10.316 13.4509L8.66401 15.4199C8.30889 15.8441 8.36485 16.4758 8.78901 16.8309C9.21316 17.186 9.84489 17.1301 10.2 16.7059L12 14.5559L13.8 16.7059C14.0297 16.9801 14.3883 17.1109 14.7406 17.0491C15.0929 16.9872 15.3855 16.7421 15.5081 16.4061C15.6307 16.07 15.5647 15.6941 15.335 15.4199L13.683 13.4509Z"
      />
    </IconSVGView>
  );
};
