import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Directions = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4214 2.58657L21.4078 10.5866C21.7867 10.9599 22 11.4696 22 12.0016C22 12.5336 21.7867 13.0433 21.4078 13.4166L13.4214 21.4166C13.0465 21.7921 12.5376 22.0031 12.0071 22.0031C11.4765 22.0031 10.9677 21.7921 10.5927 21.4166L2.58631 13.4166C2.21092 13.0414 2 12.5324 2 12.0016C2 11.4708 2.21092 10.9617 2.58631 10.5866L10.5927 2.58657C10.9677 2.21102 11.4765 2 12.0071 2C12.5376 2 13.0465 2.21102 13.4214 2.58657ZM14.361 13.6366L16.65 11.3466C16.7422 11.2528 16.793 11.126 16.7911 10.9945C16.7893 10.863 16.7348 10.7377 16.64 10.6466L14.351 8.35657C14.2082 8.21651 13.9957 8.17509 13.8108 8.25126C13.6259 8.32743 13.5042 8.50654 13.5014 8.70657V9.99657H9.00341C8.45138 9.99657 8.00386 10.4443 8.00386 10.9966V13.9966C8.00386 14.5489 8.45138 14.9966 9.00341 14.9966C9.55545 14.9966 10.003 14.5489 10.003 13.9966V11.9966H13.5114V13.2866C13.5142 13.4866 13.6359 13.6657 13.8208 13.7419C14.0057 13.8181 14.2182 13.7766 14.361 13.6366Z"
      />
    </IconSVGView>
  );
};
