import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { YardDetailsAction, YardDetailsActionType } from '@redux/YardDetails/types';

export const makeYardDetailsDisposeAction = (): YardDetailsAction => ({
  type: YardDetailsActionType.YARD_DETAILS_DISPOSE,
});

export const makeYardDetailsFetchStartAction = (id: number): YardDetailsAction => ({
  type: YardDetailsActionType.YARD_DETAILS_FETCH_START,
  payload: { id },
});

export const makeYardDetailsFetchFinishAction = (
  data: BeeYardDetails | null,
  error: ApiResponseError | null
): YardDetailsAction => ({
  type: YardDetailsActionType.YARD_DETAILS_FETCH_FINISH,
  payload: {
    data,
    error,
  },
});

export const makeYardDetailsFetchThunk = Thunk.createTakeFirst((id: number) => {
  return async (dispatch) => {
    dispatch(makeYardDetailsFetchStartAction(id));

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.hhtYardDetail, { id }));

    let data: BeeYardDetails | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction({ translation: error.translation }));
    } else {
      data = parseYardDetailsFromAPI(await response.json());
    }

    dispatch(makeYardDetailsFetchFinishAction(data, error));
  };
});

function parseYardDetailsFromAPI(data: any): BeeYardDetails {
  return CaseAdapter.objectToCamelCase(data);
}
