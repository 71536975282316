import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Close = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M13.414 12L19.707 5.70698C19.9669 5.45591 20.0712 5.08411 19.9797 4.73448C19.8882 4.38485 19.6151 4.1118 19.2655 4.02029C18.9159 3.92877 18.5441 4.03303 18.293 4.29298L12 10.586L5.70698 4.29298C5.31461 3.91401 4.6909 3.91943 4.30517 4.30517C3.91943 4.6909 3.91401 5.31461 4.29298 5.70698L10.586 12L4.29298 18.293C4.03303 18.5441 3.92877 18.9159 4.02029 19.2655C4.1118 19.6151 4.38485 19.8882 4.73448 19.9797C5.08411 20.0712 5.45591 19.9669 5.70698 19.707L12 13.414L18.293 19.707C18.6854 20.086 19.3091 20.0805 19.6948 19.6948C20.0805 19.3091 20.086 18.6854 19.707 18.293L13.414 12Z"
      />
    </IconSVGView>
  );
};
