import React from 'react';
// vendor:
import { connect, DefaultRootState } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AnalyticsLoader } from '@components/common/AnalyticsLoader';
import { Box } from '@components/common/Box';
import { Page404 } from '@components/common/Page404';
import GroupModal from '@components/deprecated/DynamicModals/GroupModal';
import UpdateYardModal from '@components/deprecated/DynamicModals/UpdateYardModal';
import Menu from '@components/deprecated/Menu_deprecated/Menu';
import StaticModal from '@components/deprecated/StaticModals/StaticModal';
import { HiveModal } from '@components/hive/HiveModal';
import { SideNav } from '@components/layout/SideNav';
import { SiteHeader } from '@components/layout/SiteHeader';
import { YardsManagement } from '@components/yard/YardsManagement';
import APP from '@config/constants';
import { makeFetchAccountRequestThunk } from '@redux/deprecated/actions';
import { Operation } from '@scenes/admin/Operation';
import { YardDetails } from '@scenes/admin/YardDetails';

import NoMatch from '../404';

import Alerts from './Alerts/Alerts';
import Groups from './Groups/Groups';
// nectar:
import { PolliContract, PolliContractsList, PolliContractsPrintable } from './Pollination';
import { Settings } from './Settings';
import { Whiteboard } from './Whiteboard';

interface IndexProps {
  getUser: () => void;
  history: any;
  isAuthenticated: boolean;
  isFetching: boolean;
  location: any;
  loggedIn: boolean; // this doesn't seem to be true
  match: any;
  permissions: any;
  staticContext?: any;
  user?: User; // should be BeeUser
}
class Index extends React.Component<IndexProps> {
  componentDidMount() {
    const { getUser } = this.props;

    getUser();
  }

  render() {
    const { location, loggedIn, isAuthenticated, user, permissions } = this.props;

    // redirect to login is the user has no permissions OR
    // if the user hasn't logged in yet AND tried an endpoint but got a server reject
    const newUser = !isAuthenticated || !user;
    if (!permissions || (!loggedIn && newUser)) {
      return (
        <Redirect
          to={{
            pathname: APP.routes.login,
            state: { from: location },
          }}
        />
      );
    }
    return (
      <>
        <AnalyticsLoader>
          <Box fit stretch>
            <SideNav />
            <Box fit column>
              <SiteHeader />
              <Switch>
                <Route
                  path={[
                    APP.routes.whiteboardYardsList,
                    APP.routes.whiteboardYardsMap,
                    APP.routes.whiteboardActivities,
                  ]}
                  component={Whiteboard}
                  exact
                />
                <Route path={APP.routes.whiteboardYardsMapManagement} component={YardsManagement} exact />
                <Route path={APP.routes.whiteboardYardsDetailDashboard} component={YardDetails} />
                <Route
                  path={APP.routes.pollinationContractPrintable}
                  render={(props: any) => <PolliContractsPrintable {...props} />}
                />
                <Route path={APP.routes.pollinationContract} render={(props: any) => <PolliContract {...props} />} />
                <Route path={APP.routes.pollination} render={(props: any) => <PolliContractsList {...props} />} />

                <Route path={APP.routes.settings} render={(props: any) => <Settings {...props} />} />
                <Route path={APP.routes.operation} render={(props: any) => <Operation {...props} />} />
                <Route path={APP.routes.alerts} render={(props: any) => <Alerts {...props} />} />
                <Route path={APP.routes.groups} render={(props: any) => <Groups {...props} />} />

                <Route component={Page404} />
              </Switch>
            </Box>
          </Box>
          <Menu />
          <StaticModal />
          <UpdateYardModal />
          <GroupModal />
          <HiveModal />
        </AnalyticsLoader>
      </>
    );
  }
}

/**
 * transforms the current Redux store state into the props for
 * the wrapped presentational component
 *
 * @param state
 * @param ownProps
 * @returns {{}}
 */
const mapStateToProps = (state: DefaultRootState) => ({
  // note: we change prop name in order no to copy-cat the redux store state
  isFetching: state.loginReducer.isFetching,
  loggedIn: state.loginReducer.loggedIn,
  isAuthenticated: state.authReducer.isAuthenticated,
  user: state.accountReducer.user,
  permissions: state.accountReducer.permissions,
});

/**
 *
 * @return {{getUser: makeFetchAccountRequestThunk}}
 *
 * `dispatch` should be type Dispatch from redux, but
 * makeFetchAccountRequestThunk isn't properly typed so it's not working
 */

const mapDispatchToProps = (dispatch: any) => ({
  getUser: () => {
    dispatch(makeFetchAccountRequestThunk());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
