import { Row } from 'react-grid-system';
import styled from 'styled-components';

import { TextButton } from '@components/deprecated/Buttons/TextButton';
import { Card } from '@components/deprecated/Card/Card';
import { from } from '@style/mediaQueries';

export const StyleTextBtn = styled(TextButton)`
  width: 100%;
  // todo, remove this font size and replace button with text component
  font-size: ${({ theme }) => theme.font.heading3FontSize};
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  text-align: end;
`;

export const StyleRow = styled(Row)`
  width: 100%;
`;

export const StyledCard = styled(Card)`
  margin-bottom: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;

  ${from('laptop')} {
    margin-bottom: 2rem;
  }
`;

export const UnderlineSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
