import { Reducer } from 'redux';

import { TableSortingDirection } from '@components/common/Table/types';
import { ApiResponseErrorDetail } from '@helpers/Api/types';
import { FetchState } from '@redux/types';

interface Grower {
  name: string;
  contact_name: string;
  contact_phone_number: string | null;
  contact_email: string | null;
}
export interface Contract {
  name: string;
  crop_types_ids: number[];
  bees_in: string | null;
  bees_out: string | null;

  grower: Grower;

  cost_per_hive: number | null;
  nb_hives: number | null;
  total_price: number | null;
  address: string | null;
  notes: string | null;
}

export interface ContractFromApi extends Contract {
  id: number;
  nb_drops: number;
  nb_blocks: number;
  nb_tracked_hives: number;
  nb_targeted_hives: number | null;
  total_acres: number | null;
  contract_year: number | null;
}

export type ContractAction =
  | { type: ContractActionType.FETCH_CONTRACTS_START | ContractActionType.FETCH_CONTRACTS_FAILURE }
  | { type: ContractActionType.FETCH_CONTRACT_START | ContractActionType.FETCH_CONTRACT_FAILURE }
  | { type: ContractActionType.FETCH_CONTRACT_SUMMARY_START | ContractActionType.FETCH_CONTRACT_SUMMARY_FAILURE }
  | { type: ContractActionType.CREATE_START }
  | { type: ContractActionType.PATCH_CONTRACT_START }
  | {
      type: ContractActionType.PATCH_CONTRACT_FAILURE | ContractActionType.CREATE_FAILURE;
      payload: ApiResponseErrorDetail | null;
    }
  | { type: ContractActionType.CREATE_SUCCESS; payload: BeeContract }
  | { type: ContractActionType.OPEN_CONTRACT_MODAL; payload: BeeContract | undefined | null }
  | { type: ContractActionType.CLOSE_CONTRACT_MODAL }
  | { type: ContractActionType.PATCH_CONTRACT_SUCCESS; payload: BeeContract }
  | {
      type: ContractActionType.FETCH_CONTRACTS_SUCCESS;
      payload: {
        contracts: BeeContract[];
        contractsStats: ContractsStats;
        totalContracts: number;
      };
    }
  | {
      type: ContractActionType.FETCH_CONTRACT_SUCCESS;
      payload: BeeContract;
    }
  | {
      type: ContractActionType.FETCH_CONTRACT_SUMMARY_SUCCESS;
      payload: BeeContractSummary[];
    }
  | { type: ContractActionType.FETCH_DROPS_LIST_START | ContractActionType.FETCH_DROPS_LIST_FAILURE }
  | { type: ContractActionType.FETCH_DROPS_LIST_SUCCESS; payload: FetchDropsSuccesPayload }
  | { type: ContractActionType.FETCH_DROPS_MAP_START | ContractActionType.FETCH_DROPS_MAP_FAILURE }
  | { type: ContractActionType.FETCH_DROPS_MAP_SUCCESS; payload: BeeDrop[] }
  | { type: ContractActionType.FETCH_BLOCKS_MAP_START | ContractActionType.FETCH_BLOCKS_MAP_FAILURE }
  | { type: ContractActionType.FETCH_BLOCKS_MAP_SUCCESS; payload: BeeBlock[] }
  | { type: ContractActionType.FETCH_POIS_MAP_START | ContractActionType.FETCH_POIS_MAP_FAILURE }
  | { type: ContractActionType.FETCH_POIS_MAP_SUCCESS; payload: BeePointOfInterest[] }
  | {
      type:
        | ContractActionType.PATCH_POLLINATION_BATCH_START
        | ContractActionType.PATCH_POLLINATION_BATCH_FAILURE
        | ContractActionType.PATCH_POLLINATION_BATCH_SUCCESS;
    };

export enum ContractActionType {
  OPEN_CONTRACT_MODAL = 'OPEN_CONTRACT_MODAL',
  CLOSE_CONTRACT_MODAL = 'CLOSE_CONTRACT_MODAL',

  // focus on errors contract modal?
  FETCH_CONTRACTS_START = 'CONTRACTS_FETCH_START',
  FETCH_CONTRACTS_SUCCESS = 'CONTRACTS_FETCH_SUCCESS',
  FETCH_CONTRACTS_FAILURE = 'CONTRACTS_FETCH_FAILURE',

  FETCH_CONTRACT_START = 'CONTRACT_FETCH_START',
  FETCH_CONTRACT_SUCCESS = 'CONTRACT_FETCH_SUCCESS',
  FETCH_CONTRACT_FAILURE = 'CONTRACT_FETCH_FAILURE',

  FETCH_CONTRACT_SUMMARY_START = 'CONTRACT_SUMMARY_FETCH_START',
  FETCH_CONTRACT_SUMMARY_SUCCESS = 'CONTRACT_SUMMARY_FETCH_SUCCESS',
  FETCH_CONTRACT_SUMMARY_FAILURE = 'CONTRACT_SUMMARY_FETCH_FAILURE',

  CREATE_START = 'CONTRACT_CREATE_START',
  CREATE_SUCCESS = 'CONTRACT_CREATE_SUCCESS',
  CREATE_FAILURE = 'CONTRACT_CREATE_FAILURE',

  PATCH_CONTRACT_START = 'CONTRACT_PATCH_START',
  PATCH_CONTRACT_SUCCESS = 'CONTRACT_PATCH_SUCCESS',
  PATCH_CONTRACT_FAILURE = 'CONTRACT_PATCH_FAILURE',

  FETCH_DROPS_LIST_START = 'CONTRACT_FETCH_DROPS_LIST_START',
  FETCH_DROPS_LIST_SUCCESS = 'CONTRACT_FETCH_DROPS_LIST_SUCCESS',
  FETCH_DROPS_LIST_FAILURE = 'CONTRACT_FETCH_DROPS_LIST_FAILURE',
  FETCH_DROPS_MAP_START = 'CONTRACT_FETCH_DROPS_MAP_START',
  FETCH_DROPS_MAP_SUCCESS = 'CONTRACT_FETCH_DROPS_MAP_SUCCESS',
  FETCH_DROPS_MAP_FAILURE = 'CONTRACT_FETCH_DROPS_MAP_FAILURE',
  FETCH_BLOCKS_MAP_START = 'CONTRACT_FETCH_BLOCKS_START',
  FETCH_BLOCKS_MAP_SUCCESS = 'CONTRACT_FETCH_BLOCKS_SUCCESS',
  FETCH_BLOCKS_MAP_FAILURE = 'CONTRACT_FETCH_BLOCKS_FAILURE',
  FETCH_POIS_MAP_START = 'CONTRACT_FETCH_POIS_START',
  FETCH_POIS_MAP_SUCCESS = 'CONTRACT_FETCH_POIS_SUCCESS',
  FETCH_POIS_MAP_FAILURE = 'CONTRACT_FETCH_POIS_FAILURE',
  PATCH_POLLINATION_BATCH_START = 'PATCH_POLLINATION_BATCH_START',
  PATCH_POLLINATION_BATCH_SUCCESS = 'PATCH_POLLINATION_BATCH_SUCCESS',
  PATCH_POLLINATION_BATCH_FAILURE = 'PATCH_POLLINATION_BATCH_FAILURE',
}

export type ContractState = FetchState<{
  contract: BeeContract | null;
  contractError: ApiResponseErrorDetail | null;
  contractDropsMap: BeeDrop[] | null;
  contractDropsList: BeeDrop[] | null;
  contractDropsListOptions: DropsListOptions;
  contractBlocksMap: BeeBlock[] | null;
  contractPoisMap: BeePointOfInterest[] | null;
  contracts: BeeContract[] | null;
  contractSummaries: BeeContractSummary[] | null;
  contractsStats: ContractsStats | null;
  totalContracts: number | null;

  isFetchingContracts: boolean;
  isFetchingContract: boolean;
  isFetchingContractDrops: boolean;
  isFetchingContractBlocks: boolean;
  isFetchingContractPois: boolean;
  hasFetchContractDropsError: boolean;
  hasFetchContractBlocksError: boolean;
  hasFetchContractPoisError: boolean;

  isContractModalOpen: boolean;
  contractToEdit: BeeContract | null | undefined;
}>;

export type ContractReducer = Reducer<ContractState, ContractAction>;

export interface PollinationBatch {
  contractId: number;
  drop: { add: Array<BeeDrop>; patch: Array<BeeDrop>; remove: Array<number> };
  block: { add: Array<BeeBlock>; patch: Array<BeeBlock>; remove: Array<number> };
  point_of_interest: { add: Array<BeePointOfInterest>; patch: Array<BeePointOfInterest>; remove: Array<number> };
}

export interface FetchDropsSuccesPayload {
  drops: BeeDrop[];
  listOptions: DropsListOptions;
}

export interface DropsListOptions {
  orderBy: string;
  orderDirection: TableSortingDirection;
  limit: number;
  offset: number;
  count: number;
}

export interface ContractsStats {
  nbHives: number | null;
  nbDrops: number | null;
  latestYear: number | null;
  latestYearTotalIncome: number | null;
}
