import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// nectar:
import Modal from '@components/deprecated/Modal/Modal';
import { withGetScreen } from '@HOC/withGetScreen';
import { makeCloseModalAction } from '@redux/deprecated/actions';

import { StaticModalView } from './StaticModalView';

class StaticModal extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    e.preventDefault();
    const { closeDispatch } = this.props;
    closeDispatch();
  }

  /**
   *
   * @returns JSX | null
   */
  render() {
    const { t, isOpen, contentObj, isMobile, user, permissions } = this.props;

    // changing (some) modals height:
    const { modalType } = contentObj;
    const metric = user && 'F' !== user.temp_unit;

    return (
      <Modal isMobile={isMobile()} modalType={modalType} isOpen={isOpen} onRequestClose={this.handleClose}>
        <StaticModalView
          t={t}
          isMobile={isMobile()}
          metric={metric}
          permissions={permissions}
          contentObj={contentObj}
          handleClose={this.handleClose}
        />
      </Modal>
    );
  }
}

/**
 *
 * @param dispatch
 * @returns {{closeDispatch: closeDispatch}}
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeCloseModalAction());
  },
});
/**
 *
 * @param state
 * @returns {{isOpen: (*|boolean)}}
 */
const mapStateToProps = (state) => ({
  isOpen: state.modalReducer.isOpen,
  contentObj: state.modalReducer.contentObj,
  user: state.accountReducer.user,
  permissions: state.accountReducer.permissions,
});

StaticModal.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    modalType: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(StaticModal)));
