import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Treatment = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M15.984 2C15.5773 1.99965 15.211 2.24559 15.0573 2.6221C14.9036 2.99862 14.9932 3.43069 15.284 3.715L17.084 5.515L15.415 7.185L13.825 5.595C13.0445 4.8169 11.7815 4.8169 11.001 5.595L4.624 11.963C4.25268 12.3347 4.04254 12.8376 4.039 13.363L4.013 18.584L2.313 20.277C1.95142 20.6644 1.95488 21.2667 2.32089 21.6499C2.68689 22.0332 3.28833 22.0644 3.692 21.721L3.721 21.693L5.437 19.986H10.592C11.1203 19.9843 11.6265 19.7736 12 19.4L12.507 18.893L10.3 16.641C9.94187 16.246 9.95659 15.6396 10.3335 15.2624C10.7103 14.8853 11.3168 14.8702 11.712 15.228L13.924 17.483L15.492 15.915L13.318 13.7C12.9722 13.3035 12.9927 12.7068 13.365 12.3351C13.7373 11.9633 14.334 11.9436 14.73 12.29L16.9 14.5L18.405 13C19.1838 12.2193 19.1838 10.9557 18.405 10.175L16.827 8.594L18.5 6.924L20.285 8.709C20.6724 9.07771 21.2814 9.07632 21.6671 8.70585C22.0528 8.33538 22.0788 7.72698 21.726 7.325L21.693 7.3L16.7 2.3C16.5115 2.10766 16.2533 1.9995 15.984 2Z"
      />
    </IconSVGView>
  );
};
