import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import { PrimaryCTA } from '@style/typeface';

const StyledTextButton = styled.button`
  ${PrimaryCTA};
  margin: 0 auto;
  background-color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline-style: none;
  cursor: pointer;
  color: ${(props) => (!props.hover ? 'inherit' : props.theme.buttonPrimaryColor)}99;
  &:disabled {
    color: rgba(55, 67, 65, 0.4);
    cursor: default;
  }
  &:hover {
    color: ${(props) => !props.disabled && props.theme.buttonPrimaryColor}99;
  }
  &:focus {
    color: rgba(55, 67, 65, 0.6);
  }
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
`;

export const TextButton = ({ onClick, label, type = 'button', disabled = false, hover, ...others }) => {
  const copiedProps = {};
  Object.keys(others).forEach((key) => {
    // these are the props that need to get thru:
    if (key.startsWith('data-') || 'style' === key || 'className' === key) {
      copiedProps[key] = others[key];
    }
  });

  return (
    <StyledTextButton onClick={onClick} disabled={disabled} hover={hover} type={type} {...copiedProps}>
      {label}
    </StyledTextButton>
  );
};

TextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hover: PropTypes.bool,
  type: PropTypes.string,
};
