import React from 'react';

import { Box } from '@components/common/Box';
import AssignYardsGroupsModal from '@components/deprecated/DynamicModals/AssignYardsGroupsModal';
import DeleteYardModal from '@components/deprecated/DynamicModals/DeleteYardModal';
import { useYardsListEmptyState } from '@components/yard/YardsList/hooks/useYardsListEmptyState';
import { YardsListSaveViewButton } from '@components/yard/YardsList/YardsListSaveViewButton';

import { BulkActionsNav } from '../BulkActionsNav';
import { BulkAssignContractModal } from '../BulkAssignContractModal';
import MetricCarrousel from '../MetricCarrousel';
import { YardsListColumnsModalTrigger } from '../YardsListColumnsModal/YardsListColumnsModalTrigger';

import { YardsListSelectionProvider } from './context/YardsListSelectionProvider';
import { useYardsListDataProvider } from './hooks';
import { YardsList } from './YardsList';

export const YardsListPage: React.VFC = () => {
  const { isFetchingFirstBatch } = useYardsListDataProvider();
  const { isListEmpty } = useYardsListEmptyState();

  return (
    <>
      <Box block marginTopSM paddingHorizontalMD>
        <Box>
          <MetricCarrousel />
        </Box>
        <Box marginTopXS marginBottomSM gapSM>
          <Box fit />
          <YardsListSaveViewButton disabled={isFetchingFirstBatch} />
          {!isListEmpty && <YardsListColumnsModalTrigger disabled={isFetchingFirstBatch} />}
        </Box>

        <YardsListSelectionProvider>
          <YardsList />
          <BulkActionsNav />
          <BulkAssignContractModal />
          <AssignYardsGroupsModal />
        </YardsListSelectionProvider>
      </Box>

      <DeleteYardModal />
    </>
  );
};
