import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Help = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12.0001C4 16.4183 7.58173 20.0001 12 20.0001C16.416 19.9945 19.9945 16.416 20 12.0001C20 7.58178 16.4183 4.00005 12 4.00005C7.58173 4.00005 4 7.58178 4 12.0001ZM11 16H13V18H11V16ZM8.18 8.83329C8.75716 6.93054 10.6443 5.7406 12.61 6.03995C14.3682 6.34008 15.7269 7.74874 15.9633 9.51662C16.1914 11.1302 15.2991 11.9087 14.4378 12.6602C14.2795 12.7982 14.1223 12.9354 13.9733 13.0766C13.8549 13.1801 13.7435 13.2915 13.64 13.41C13.635 13.415 13.63 13.4225 13.625 13.43C13.62 13.4375 13.615 13.445 13.61 13.45C13.5055 13.5716 13.4118 13.7021 13.33 13.84C13.32 13.855 13.3125 13.87 13.305 13.885C13.2975 13.9 13.29 13.915 13.28 13.93C13.0939 14.2557 12.9973 14.6248 13 15H11C10.9947 14.5749 11.0624 14.1521 11.2 13.75C11.2053 13.7263 11.212 13.7029 11.22 13.68C11.225 13.665 11.2325 13.6525 11.24 13.64C11.2475 13.6275 11.255 13.615 11.26 13.6C11.3292 13.4266 11.4128 13.2593 11.51 13.1C11.5144 13.0912 11.5207 13.0824 11.5264 13.0745C11.5337 13.0643 11.54 13.0556 11.54 13.05C11.54 13.045 11.5425 13.0425 11.545 13.04C11.5475 13.0375 11.55 13.035 11.55 13.03C11.8909 12.5166 12.3481 12.1641 12.7755 11.8345C13.389 11.3615 13.9411 10.9357 14 10.15C14.0607 9.16091 13.3961 8.27344 12.43 8.05329C11.451 7.84424 10.4683 8.39115 10.13 9.33329C10.0057 9.71823 9.65421 9.98453 9.25 9.99995H9.05C8.76325 10.0004 8.49319 9.86517 8.32177 9.63529C8.15034 9.40542 8.09777 9.108 8.18 8.83329Z"
      />
    </IconSVGView>
  );
};
