import produce from 'immer';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isFetching: false,
};

export const batchYardsReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.BATCH_UPDATE_YARDS_ACTION_START:
        st.isFetching = true;
        break;
      case actionTypes.BATCH_UPDATE_YARDS_ACTION_SUCCESS:
        st.isFetching = false;
        break;
      case actionTypes.BATCH_UPDATE_YARDS_ACTION_FAILURE:
        st.isFetching = false;
        break;
      default:
        break;
    }
  });
};
