import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { getDetailedActivitySet } from '@redux/Timeline/reducer';
import {
  ActivityByDateSet,
  ActivityByRequestSet,
  ActivityFilters,
  TimelineAction,
  TimelineActionType,
} from '@redux/Timeline/types';

const DEF_TIMELINE_ACTIVITIES_PAGE_SIZE = 20;
const DEF_TIMELINE_VISIT_ACTIVITIES_PAGE_SIZE = 20;

export const makeTimelineFetchActivityStartAction = (): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_ACTIVITY_START,
});

export const makeTimelineFetchActivityFinishAction = (
  data: ActivityByDateSet | null,
  error: ApiResponseError | null
): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_ACTIVITY_FINISH,
  payload: {
    data,
    error,
  },
});

export const makeTimelineFetchActivityThunk = Thunk.createTakeFirst(() => {
  return async (dispatch, getState) => {
    dispatch(makeTimelineFetchActivityStartAction());

    const offset = getState().timelineReducer.activitySet?.activities.length ?? 0;
    const limit = DEF_TIMELINE_ACTIVITIES_PAGE_SIZE;

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.hhtActivitiesByDate, { limit, offset }));

    let data: ActivityByDateSet | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction({ translation: error.translation }));
    } else {
      data = parseTimelineActivityFromApi(await response.json());
    }

    dispatch(makeTimelineFetchActivityFinishAction(data, error));
  };
});

export const makeTimelineFetchVisitActivityStartAction = (filters: ActivityFilters): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_START,
  payload: { filters },
});

export const makeTimelineFetchVisitActivityFinishAction = (
  filters: ActivityFilters,
  data: ActivityByRequestSet | null,
  error: ApiResponseError | null
): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH,
  payload: {
    filters,
    data,
    error,
  },
});

export const makeTimelineFetchVisitActivityThunk = Thunk.createTakeFirst((filters: ActivityFilters) => {
  return async (dispatch, getState) => {
    dispatch(makeTimelineFetchVisitActivityStartAction(filters));

    const visitDetails = getDetailedActivitySet(getState().timelineReducer, filters);
    const offset = visitDetails?.activities.length ?? 0;
    const limit = DEF_TIMELINE_VISIT_ACTIVITIES_PAGE_SIZE;

    const response = await Api.get(
      URLUtil.buildURL(ENDPOINTS.hhtActivitiesByRequest, {
        limit,
        offset,
        day: filters.date,
        yard: filters.yardId,
        alert_type: filters.alertType,
      })
    );

    let data: ActivityByRequestSet | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction({ translation: error.translation }));
    } else {
      data = parseTimelineVisitActivityFromApi(await response.json());
    }

    dispatch(makeTimelineFetchVisitActivityFinishAction(filters, data, error));
  };
});

function parseTimelineActivityFromApi({ count, results }: any): ActivityByDateSet {
  return {
    totalCount: count,
    activities: results.map((entry: any) => CaseAdapter.objectToCamelCase(entry)),
  };
}

function parseTimelineVisitActivityFromApi({ count, results }: any): ActivityByRequestSet {
  return {
    totalCount: count,
    activities: results.map((entry: any) => CaseAdapter.objectToCamelCase(entry)),
  };
}
