import React from 'react';
import styled from 'styled-components';

import { InfoCircle } from '@components/common/Icon/presets/InfoCircle';

const StyledWrapper = styled.div(
  ({ theme }) => `
        padding: 1rem;
        display: grid;
        grid-template-columns: 24px auto;
        grid-gap: 18px;

        // warning
        border-radius: ${theme.spacing.xs}px;
        background-color: ${theme.colors.yellow01};
        border: 1px solid ${theme.colors.orange02};
        color: ${theme.colors.orange03};

        svg {
          color: ${theme.colors.orange03};
        }
    `
);

// could make this for warning, info, error...
type MessageType = 'warning';

export const MessageBox: React.FC<{ type?: MessageType }> = ({ children, type }) => {
  return (
    <StyledWrapper>
      <div>
        <InfoCircle size={24} />
      </div>
      <div>{children}</div>
    </StyledWrapper>
  );
};
