import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import triangleIcon from '@assets/xSmall_Arrow-dropdown.svg';

const StyledTriangleImg = styled.img`
  width: 1rem;
  height: 1rem;
  opacity: 0.75;
  transform: translate(50%, 20%) rotate(${(props) => props.rotate}deg);
  filter: ${(props) =>
    props.hover && 'invert(61%) sepia(12%) saturate(114%) hue-rotate(122deg) brightness(88%) contrast(90%)'};
`;

const Placeholder = styled.img`
  width: 1rem;
  height: 1rem;
  visibility: hidden;
`;

export class SortTriangle extends Component {
  render() {
    const { id, sort, show } = this.props;

    const { order_by, order_direction } = sort;

    return show || id === order_by ? (
      <StyledTriangleImg
        hover={show}
        src={triangleIcon}
        alt="sort triangle"
        style={{ marginBottom: 'ASC' === order_direction ? '-2px' : 0 }}
        rotate={'ASC' === order_direction ? '180' : '0'}
      />
    ) : (
      <Placeholder src="" />
    ); // this is just to hold space for the sort triangle, when it becomes enabled
  }
}

SortTriangle.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  sort: PropTypes.shape({
    order_direction: PropTypes.string.isRequired,
    order_by: PropTypes.string.isRequired,
  }),
};
