import React from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { ArrowDropdown } from '../Icon/presets/ArrowDropdown';

import { Button } from './Button';

const StyledMenuTriggerIconWrap = styled.span(
  ({ theme }) => `
      border-left: 1px solid;
      height: 100%;
      display: block;
      padding: ${theme.spacing.xs}px;
      margin-left: ${theme.spacing.sm}px;
    `
);

export const MenuTrigger: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
  return (
    <Button {...props} secondary paddingVertical={false} paddingHorizontal={false}>
      <Box tag="span" paddingVerticalXS paddingLeftSM>
        {children}
      </Box>
      <StyledMenuTriggerIconWrap>
        <ArrowDropdown size={24} />
      </StyledMenuTriggerIconWrap>
    </Button>
  );
};
