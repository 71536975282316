import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from '@components/common/Icon/presets/Filter';
import { StyledFilterButton, StyledFilterCount } from '@components/yard/YardsFilters/styles';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeFetchFiltersAvailabilitiesThunk } from '@redux/YardsFilters/actions';
import { useYardsFilters } from '@redux/YardsFilters/hooks';

import { YardsFiltersModal } from './YardsFiltersModal';

export const YardsFiltersTrigger: React.VFC = () => {
  const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { appliedFiltersCount } = useYardsFilters();
  const isFilterCountVisible = appliedFiltersCount > 0;

  const retrieveFilterAvailabilities = useCallback(() => {
    dispatch(makeFetchFiltersAvailabilitiesThunk());
  }, [dispatch]);

  const closeFiltersModal = useCallback(() => setFiltersModalOpen(false), []);

  const openFiltersModal = useCallback(() => {
    retrieveFilterAvailabilities();
    setFiltersModalOpen(true);
  }, [retrieveFilterAvailabilities]);

  return (
    <>
      <StyledFilterButton primary withIconLeft onClick={openFiltersModal}>
        <Filter />
        {t('filter_yards')}
        <StyledFilterCount $isVisible={isFilterCountVisible}>{appliedFiltersCount}</StyledFilterCount>
      </StyledFilterButton>
      <YardsFiltersModal isOpen={isFiltersModalOpen} onRequestClose={closeFiltersModal} />
    </>
  );
};
