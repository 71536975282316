import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';

export const StypedMenuView = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    background-color: ${theme.colors.coreWhite};
    border-radius: ${theme.shape.paperRadius01}px;
    box-shadow: ${theme.shadows.boxShadow01};
  `
);

export const StyledMenuItemGroupView = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:not(:first-child) {
      border-top: ${theme.colors.grey03} 1px solid;
    }
  `
);

export const StyledMenuItemView = styled.button(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: ${theme.shape.paperRadius01}px;
    padding: ${theme.spacing.xxs}px ${theme.spacing.sm}px ${theme.spacing.xxs}px ${theme.spacing.xs}px;

    svg {
      // Fix for a small visual issue where icons seem unaligned.
      margin-top: 2px;
    }

    &[disabled] {
      pointer-events: none;

      img {
        opacity: 0.24;
      }

      svg,
      *[role='text'] {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: ${theme.colors.grey02};
    }

    &:active {
      background-color: ${theme.colors.grey04};
    }
  `
);

export const MenuItemTextView = styled(Text)(
  ({ theme }) => css`
    flex: 1;
    margin: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
    text-align: left;
  `
);
