import React from 'react';
import { useTheme } from 'styled-components';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const RoadPin = ({ size }: IconSVGProps) => {
  const { colors } = useTheme();
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <rect fill={colors.grey08} width="16" height="16" rx="8" />
      <path
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82738 4.89307H6.3199C6.19375 4.89307 6.08609 4.98427 6.06535 5.10871L5.11573 10.8064C5.08951 10.9637 5.21081 11.1069 5.37028 11.1069H7.82738V10.4658H8.1726V11.1069H10.6297C10.7891 11.1069 10.9104 10.9637 10.8842 10.8064L9.9346 5.10871C9.91386 4.98427 9.8062 4.89307 9.68004 4.89307H8.1726V5.53413H7.82738V4.89307ZM7.82738 6.52046H8.1726V7.50679H7.82738V6.52046ZM8.1726 8.49312H7.82738V9.47945H8.1726V8.49312Z"
      />
    </IconSVGView>
  );
};
