import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledModalContent = styled(Box).attrs({
  column: true,
  stretch: true,
})(
  ({ theme }) => css`
    padding: ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.sm}px;
  `
);

export const StyledWrapper = styled.div(
  ({ theme }) => `
  border-radius: ${theme.shape.paperRadius02}px;
  height: 471px;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid ${theme.colors.grey04};
`
);

export const StyledColumnOption = styled(Box)(
  ({ theme }) => `
    border-bottom: 1px solid ${theme.colors.grey04};
    padding-top: ${theme.spacing.sm}px;
    padding-bottom: ${theme.spacing.sm}px;
    width: 100%;
  `
);
