import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Settings = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.194 11.3201C19.214 11.5501 19.224 11.7701 19.224 12.0001C19.2257 12.2274 19.2123 12.4545 19.184 12.6801L21.044 14.0901C21.4436 14.3974 21.5546 14.9527 21.304 15.3901L19.434 18.6201C19.1857 19.0587 18.6468 19.2398 18.184 19.0401L16.034 18.1301C15.6651 18.3911 15.2735 18.6187 14.864 18.8101L14.574 21.1201C14.5135 21.6212 14.0889 21.9987 13.584 22.0001H9.85404C9.34923 21.9987 8.92462 21.6212 8.86404 21.1201L8.57404 18.8101C8.16456 18.6187 7.77301 18.3911 7.40404 18.1301L5.25404 19.0401C4.79125 19.2398 4.25234 19.0587 4.00404 18.6201L2.13404 15.3901C1.88254 14.9528 1.99372 14.3969 2.39404 14.0901L4.25404 12.6801C4.23404 12.4501 4.22404 12.2301 4.22404 12.0001C4.22404 11.7701 4.23404 11.5501 4.25404 11.3301L2.39404 9.92005C1.99451 9.61269 1.88347 9.05744 2.13404 8.62005L4.00404 5.38005C4.25234 4.94142 4.79125 4.76034 5.25404 4.96005L7.40404 5.87005C7.77301 5.60899 8.16456 5.38142 8.57404 5.19005L8.86404 2.88005C8.92506 2.37509 9.35541 1.99638 9.86404 2.00005H13.594C14.0989 2.00145 14.5235 2.37888 14.584 2.88005L14.874 5.19005C15.2835 5.38142 15.6751 5.60899 16.044 5.87005L18.194 4.96005C18.6568 4.76034 19.1957 4.94142 19.444 5.38005L21.314 8.61005C21.5655 9.04729 21.4544 9.60318 21.054 9.91005L19.194 11.3201ZM8.26404 12.0001C8.26404 13.933 9.83104 15.5001 11.764 15.5001C12.6923 15.5001 13.5825 15.1313 14.2389 14.4749C14.8953 13.8185 15.264 12.9283 15.264 12.0001C15.264 10.0671 13.697 8.50005 11.764 8.50005C9.83104 8.50005 8.26404 10.0671 8.26404 12.0001Z"
      />
    </IconSVGView>
  );
};
