import React, { FormEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { InputText } from '@components/form/InputText';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';

import { StyledButtonFlat, StyledButtonPrimary } from '../PolliMap/styles';

import { StyledForm, StyledFormContent, StyledFormFooter, StyledFormHead } from './styles';

export const PolliMapPaneDropForm: React.VFC = () => {
  const { t } = useTranslation();
  const {
    selectedDrop,
    selectDrop,
    isAdding,
    isManagingADrop,
    isDraggingElements,
    drops,
    submitDropChange,
    cancelDropChange,
  } = usePolliMap();

  const targetHiveNumberValue = useMemo(() => {
    if (selectedDrop && selectedDrop.targetHiveNumber !== null) {
      return String(selectedDrop.targetHiveNumber);
    }
    return '';
  }, [selectedDrop]);

  const handleTargetHiveNumberChange = useCallback(
    (value: number | string) => {
      if (selectedDrop) {
        const cleanValue = String(value).replace(/[^0-9]/g, '');
        const targetHiveNumber = cleanValue ? parseInt(cleanValue) : null;
        selectDrop({ ...selectedDrop, targetHiveNumber });
      }
    },
    [selectDrop, selectedDrop]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      submitDropChange();
    },
    [submitDropChange]
  );

  const handleCancel = useCallback(() => {
    cancelDropChange();
  }, [cancelDropChange]);

  if (!(isDraggingElements || isManagingADrop)) {
    return null;
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormHead>
        <Text typography={'Heading3'} weight={'600'}>
          {t('pollination_new_drop_name', selectedDrop)}
        </Text>
      </StyledFormHead>
      <StyledFormContent>
        <Box marginBottomSM>
          <Text typography={'SmallParagraph'}>{t('pollination_new_drop_hint')}</Text>
        </Box>

        <InputText
          id="drop-target-hive-number"
          name="drop-target-hive-number"
          type="number"
          label={t('number_of_hives')}
          value={targetHiveNumberValue}
          onTextChange={handleTargetHiveNumberChange}
          required
          autoFocus
        />
      </StyledFormContent>
      <StyledFormFooter>
        <StyledButtonFlat type={'button'} onClick={handleCancel}>
          <Text typography={'CTA'} weight={'700'}>
            {t('cancel')}
          </Text>
        </StyledButtonFlat>
        <StyledButtonPrimary>
          <Text typography={'CTA'} weight={'700'}>
            {t(isAdding(selectedDrop, drops) ? 'create' : 'done')}
          </Text>
        </StyledButtonPrimary>
      </StyledFormFooter>
    </StyledForm>
  );
};
