import config from '@config/config';
import APP from '@config/constants';
import theme from '@style/theme';

export const API_URL = config.REACT_APP_API_URL;
export const GMAP_API_KEY = config.REACT_APP_GMAP_API_KEY;

export const ROOT_URL = APP.nginxHost ? APP.nginxHost : APP.rootUrl;
// google map styles:
// @see https://mapstyle.withgoogle.com/
export const GMAP_STYLES = [
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
];

export const MARKER_CLUSTER_STYLES = [
  {
    textColor: '#252629',
    height: 53,
    url: '/gmap-clusterer-icon/53x53.png',
    width: 53,
    anchorText: [20, 0],
  },
  {
    textColor: '#252629',
    height: 56,
    url: '/gmap-clusterer-icon/56x56.png',
    width: 56,
    anchorText: [21, 0],
  },
  {
    textColor: '#252629',
    height: 66,
    url: '/gmap-clusterer-icon/66x66.png',
    width: 66,
    anchorText: [27, 0],
  },
  {
    textColor: '#252629',
    height: 78,
    url: '/gmap-clusterer-icon/78x78.png',
    width: 78,
    anchorText: [27, 0],
  },
  {
    textColor: '#252629',
    height: 90,
    url: '/gmap-clusterer-icon/90x90.png',
    width: 90,
    anchorText: [27, 0],
  },
];

export const GMAP_ICON_SIZES = {
  x: 54,
  y: 54,
};

export const GMAP_SMALL_ICON_SIZES = {
  x: 32,
  y: 32,
};

export const GMAP_FLYOVER_RADIUS = 3000;

export const DEFAULT_YARD_RADIUS = 45;

export const GMAP_MINIMUM_ZOOM_FLYOVER_DISPLAY = 10;

export const GMAP_TYPE = {
  default: 'terrain',
  terrain: 'terrain',
  satellite: 'satellite',
};

export const GMAP_MIN_ZOOM = 3; // 0 iz the absolute minimum, completely zoomed out

export const GMAP_MAX_ZOOM = 21; // 21 is the absolute maximum, zompletely zoomed in

export const SHOW_YARD_HIVES_ZOOM = 15; // this is the zoom level at which the yard hives are starting to show

export const SHOW_YARD_BOUNDARIES_ZOOM = 15; // this is the zoom level at which the yard boundaries are starting to be visible at

export const DEFAULT_BEE_FLY_ZONE_ZOOM = 10; // this is the zoom level at which the map is to zoom out accordingly when the bee radius is turned on

export const DEFAULT_PAN_TO_ZOOM = 18;

// the cluster marker zoom should be less than the map zoom (at least by 1) to avoid a situation where the map is zoomed in and the cluster is still not decoupled
export const MARKER_MAX_ZOOM = SHOW_YARD_BOUNDARIES_ZOOM;

export const MAP_HEIGHT = 278;

export const MAP_HEIGHT_MOBILE = 384;

export const HHT_MAP_HEIGHT_MOBILE = 190;

// link to the google app (or google map url):
// @see https://developers.google.com/maps/documentation/urls/guide
export const GMAP_SEARCH_URL = 'https://maps.google.com/?q={{LATLNG}}&ll={{LL}}&z=8';
// link to the google static map:
// @see https://developers.google.com/maps/documentation/maps-static/dev-guide
export const GMAP_STATIC_MAP_BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap?';
//in order to render in the static google map, the img has to be of .png format
//and not more than 64px by 64px
export const GMP_STATIC_MAP_ICON_CRITICAL_URL_64 = `${ROOT_URL}/gsmap-marker-icons/static-white-64x64.png`;
export const GMP_STATIC_MAP_ICON_BOTHERSOME_URL_64 = `${ROOT_URL}/gsmap-marker-icons/static-yellow-64x64.png`;
export const GMP_STATIC_MAP_ICON_GOOD_URL_64 = `${ROOT_URL}/gsmap-marker-icons/static-green-64x64.png`;
export const GMP_STATIC_MAP_ICON_UNKNOWN_URL_64 = `${ROOT_URL}/gsmap-marker-icons/static-grey-64x64.png`;

export const GMP_STATIC_MAP_QUERY_PARAMS = {
  // @note: /src/assets/staticmap-blurry-lowsrc.jpg will be
  // displayed briefly while loading google img
  size: '640x355', //640x355, ratio 1.8
  scale: 2, //the accepted scale values at 1,2 and 4
  key: GMAP_API_KEY,
  maptype: 'terrain', // roadmap, terrain, satellite
  language: '__LANG__',
  markers: 'icon:__ICON__|__MARKERS__',
  // if one supplies markers for a map, no need to specify
  // the (normally requiwhite) center and zoom parameters
  //zoom:16 for the cards, zoom:8 for the yard banner
  zoom: '__ZOOM__',
  // center: '__CENTER__',
  format: 'png8', // png8 indexed (default)
  // style: 'element:labels|visibility:off',
};

//restrict the map bounds by the map size: https://stackoverflow.com/questions/11849636/maximum-lat-and-long-bounds-for-the-world-google-maps-api-latlngbounds
export const GMAP_BOUNDS = {
  north: 85,
  south: -85,
  west: -180,
  east: 180,
};

export const YARD_BOUNDS_STYLE = {
  editable: false,
  clickable: true,
  fillColor: theme.yardBoundariesColor,
  fillOpacity: 0.25,
  strokeColor: theme.yardBoundariesColor,
  strokeOpacity: 0,
  strokeWeight: 0,
  zIndex: theme.getZIndexOf('highLevelIndex'),
};

export const EDITABLE_YARD_BOUNDS_STYLE = {
  editable: true,
  draggable: true,
  clickable: true,
  fillColor: theme.editableYardBoundariesColor,
  fillOpacity: 0.3,
  strokeColor: theme.editableYardBoundariesColor,
  strokeOpacity: 1,
  strokeWeight: 1,
  zIndex: theme.getZIndexOf('highLevelIndex'),
};

export const HOVERED_YARD_BOUNDS_STYLE = {
  strokeColor: theme.editableYardBoundariesColor,
  strokeWeight: 2,
  strokeOpacity: 1,
};

export const BEE_FLY_ZONES_STYLE = {
  strokeColor: '#fff',
  strokeOpacity: 0.35,
  strokeWeight: 0,
  fillColor: '#fff',
  fillOpacity: 0.35,
};

export const DEFAULT_MAP_CONFIG = {
  minZoom: GMAP_MIN_ZOOM,
  maxZoom: GMAP_MAX_ZOOM,
  styles: GMAP_STYLES,
  scaleControl: true,
  disableDefaultUI: true,
  gestureHandling: 'auto',
  tilt: 0, // to disable the satellite view 45 degree tilt
  restriction: {
    latLngBounds: GMAP_BOUNDS,
    strictBounds: false,
  },
};
