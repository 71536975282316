import React, { FormEventHandler, useCallback } from 'react';
import produce from 'immer';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { Help } from '@components/common/Icon/presets/Help';
import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { Checkbox } from '@components/form/CheckBox';
import { RadioButton } from '@components/form/RadioButton';
import { useYardsFiltersDefinitions } from '@components/yard/YardsFilters/hooks';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useCropTypeFetcher } from '@hooks/useCropTypes';
import { useGroupsFetcher } from '@hooks/useGroups';
import { useTranslation } from '@hooks/useTranslation';
import {
  makeClearAppliedFiltersAction,
  makeClearTransientFiltersAction,
  makePatchAppliedFiltersAction,
  makePatchTransientFiltersAction,
} from '@redux/YardsFilters/actions';
import { useYardsFilters } from '@redux/YardsFilters/hooks';
import { YardsFiltersCategoryKey, YardsFilterValuesKey } from '@redux/YardsFilters/types';

import {
  StyledClearFiltersButton,
  StyledClearFiltersButtonText,
  StyledFilterItem,
  StyledFilterSection,
  StyledFilterSectionContent,
  StyledFilterSectionTitle,
  StyledFiltersForm,
  StyledFiltersFormContent,
  StyledFiltersFormHeader,
} from './styles';

export const YardsFiltersModal: React.VFC<Omit<ModalProps, 'children'>> = ({ ...props }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const filtersDefinitions = useYardsFiltersDefinitions();
  const { isFetching, transientAvailableFilters, transientAppliedFilters } = useYardsFilters();
  const { isFetching: isFetchingGroups } = useGroupsFetcher();
  const { isFetching: isFetchingCropTypes } = useCropTypeFetcher();

  const onFiltersSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      dispatch(makeClearAppliedFiltersAction());
      dispatch(makePatchAppliedFiltersAction(transientAppliedFilters));

      const filteredSections = Object.entries(transientAppliedFilters)
        .filter(([, sectionItems]) => sectionItems.length > 0)
        .map(([sectionKey]) => sectionKey)
        .join(', ');

      if (filteredSections) {
        Analytics.sendEvent({
          event: AnalyticsEventType.WHITEBOARD_FILTER,
          eventData: {
            sections: filteredSections,
          },
        });
      }

      props.onRequestClose && props.onRequestClose();
    },
    [dispatch, props, transientAppliedFilters]
  );

  const onFilterCancel = useCallback(() => {
    dispatch(makeClearTransientFiltersAction());
    props.onRequestClose && props.onRequestClose();
  }, [dispatch, props]);

  const onFilterChange = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: string, value: boolean) => {
      const newTransientFilters = produce(transientAppliedFilters, (current) => {
        const filterDefinition = filtersDefinitions.find(({ categoryKey: key }) => key === categoryKey);

        if (filterDefinition?.canSelectOnlyOneValue) {
          current[categoryKey] = [valueKey];
        } else {
          const currentValues = new Set(current[categoryKey] ?? []);
          value ? currentValues.add(valueKey) : currentValues.delete(valueKey);
          current[categoryKey] = Array.from(currentValues);
        }
      });
      dispatch(makePatchTransientFiltersAction(newTransientFilters));
    },
    [filtersDefinitions, transientAppliedFilters, dispatch]
  );

  const isFilterActive = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: any) =>
      (transientAppliedFilters[categoryKey] ?? []).includes(valueKey),
    [transientAppliedFilters]
  );

  const isFilterDisabled = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: YardsFilterValuesKey) => {
      const values = (transientAvailableFilters[categoryKey]?.values ?? {}) as Record<string, boolean>;
      return !values[valueKey];
    },
    [transientAvailableFilters]
  );

  return (
    <Modal {...props}>
      <StyledFiltersForm onSubmit={onFiltersSubmit}>
        <StyledFiltersFormHeader>
          <Text typography={'Heading2'} weight={'600'}>
            {t('yard_filters')}
          </Text>
          <StyledClearFiltersButton type="button" onClick={() => dispatch(makeClearTransientFiltersAction())}>
            <StyledClearFiltersButtonText>{t('clear')}</StyledClearFiltersButtonText>
          </StyledClearFiltersButton>
        </StyledFiltersFormHeader>

        <StyledFiltersFormContent>
          <Loading visible={isFetching || isFetchingGroups || isFetchingCropTypes} whiteBackground />

          {filtersDefinitions.map(({ title, tooltip, categoryKey, values, canSelectOnlyOneValue }) => (
            <StyledFilterSection key={categoryKey}>
              {title && (
                <Box center marginBottomSM>
                  <StyledFilterSectionTitle>{capitalize(title)}</StyledFilterSectionTitle>
                  {!!tooltip && (
                    <>
                      <Box marginLeftXS tag={'span'}>
                        <Help size={16} color={'grey06'} />
                      </Box>
                      <Tooltip>
                        <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltip }} />
                      </Tooltip>
                    </>
                  )}
                </Box>
              )}

              <StyledFilterSectionContent>
                {values.map(({ label, tooltip, valueKey }) => {
                  const inputKey = `${categoryKey}-${valueKey}`;
                  const value = isFilterActive(categoryKey, valueKey);
                  const isDisabled = isFilterDisabled(categoryKey, valueKey);
                  const onChange = (value: boolean) => onFilterChange(categoryKey, valueKey, value);
                  const inputComponent = canSelectOnlyOneValue ? (
                    <RadioButton
                      name={`${categoryKey}`}
                      id={inputKey}
                      value={value}
                      disabled={isDisabled}
                      onChange={onChange}
                    />
                  ) : (
                    <Checkbox name={inputKey} value={value} disabled={isDisabled} onChange={onChange} />
                  );

                  return (
                    <StyledFilterItem key={inputKey} isDisabled={isDisabled}>
                      <Box marginRightSM marginTopXXS>
                        {inputComponent}
                      </Box>
                      <Text typography={'Paragraph'} color={isDisabled ? 'grey05' : 'grey08'} dashed={!!tooltip}>
                        {capitalize(label)}

                        {!!tooltip && (
                          <Tooltip>
                            <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltip }} />
                          </Tooltip>
                        )}
                      </Text>
                    </StyledFilterItem>
                  );
                })}
              </StyledFilterSectionContent>
            </StyledFilterSection>
          ))}
        </StyledFiltersFormContent>

        <ModalFooter>
          <Box fit justifyContent={'flex-end'}>
            <PrimaryButton type="button" size="small" flat onClick={onFilterCancel}>
              {t('cancel')}
            </PrimaryButton>
            <PrimaryButton type="submit" size="small">
              {t('apply')}
            </PrimaryButton>
          </Box>
        </ModalFooter>
      </StyledFiltersForm>
    </Modal>
  );
};
