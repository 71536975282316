import produce from 'immer';

import APP from '@config/constants';

import * as actionTypes from '../actionTypes';

const getLimit = sessionStorage.getItem('limit');
const getOffset = sessionStorage.getItem('offset');
const limit = getLimit ? parseInt(getLimit) : APP.defaultLimit;
const offset = getOffset ? parseInt(getOffset) : 0;
const reduced_offset = getOffset ? offset / limit : 0;

const defaultState = {
  isFetching: false,
  isFetchingYard: false,
  isFetchingUpdateYard: false,
  isFetchingDeleteYard: false,
  isFetchingYardsListSummary: false,
  yard: null,
  yardsList: null,
  yardsListSummary: [],
  yardsSearchHistory: [],
  yardsMap: null,
  yards: null,
  hives: null,
  fetchedAt: null,
  order_by: 'last_visit',
  order_direction: 'DESC',
  patch: [],
  remove: [],
  add: [],
  offset,
  limit,
  reduced_offset,
  totalNumOfYards: 0,
  numOfPages: 0,
};

export const beeTrackYardListReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.SET_SEARCH_HISTORY_LIST:
        st.yardsSearchHistory = action.yardIds;
        break;
      case actionTypes.ORDER_YARD_LIST_BY:
        st.order_by = action.order_by;
        break;
      case actionTypes.ORDER_YARD_LIST_BY_DIRECTION:
        st.order_direction = action.order_direction;
        break;
      case actionTypes.DISPATCH_PAGINATION_ACTION:
        const { offset } = action;
        sessionStorage.setItem('offset', offset);
        st.offset = offset;
        st.reduced_offset = offset / state.limit;
        break;
      case actionTypes.DISPATCH_LIMIT_ACTION:
        const { totalNumOfYards } = state;
        const { limit } = action;
        sessionStorage.setItem('limit', limit);
        st.limit = limit;
        st.numOfPages = Math.ceil(totalNumOfYards / limit);
        break;
      case actionTypes.SET_UPDATE_MULTIPLE_YARDS:
        st.patch = action.patch;
        st.yards = action.yards;
        break;
      case actionTypes.SET_REMOVE_MULTIPLE_YARDS:
        st.remove = action.remove;
        st.yards = action.yards;
        break;
      case actionTypes.SET_ADD_MULTIPLE_YARDS:
        st.add = action.add;
        st.yards = action.yards;
        break;
      case actionTypes.RESET_YARD_DATA:
        st.yard = null;
        st.patch = [];
        st.add = [];
        st.remove = [];
        st.yards = [...state.yardsMap];
        break;
      case actionTypes.RESET_YARD_MODAL_DATA:
        st.yard = null;
        break;
      case actionTypes.FETCH_HHT_YARD_LIST_START:
        st.isFetching = true;
        st.yardsList = null;
        st.numOfPages = 0;
        st.totalNumOfYards = 0;
        break;
      case actionTypes.FETCH_HHT_YARD_LIST_SUCCESS:
        const { count } = action.data;
        st.isFetching = false;
        st.yardsList = action.data.results;
        st.numOfPages = Math.ceil(count / state.limit);
        st.totalNumOfYards = count;
        break;
      case actionTypes.FETCH_HHT_YARD_LIST_FAILURE:
        st.isFetching = false;
        break;
      case actionTypes.FETCH_HHT_YARD_LIST_SUMMARY_START:
        st.isFetchingYardsListSummary = true;
        break;
      case actionTypes.FETCH_HHT_YARD_LIST_SUMMARY_SUCCESS:
        st.isFetchingYardsListSummary = false;
        st.yardsListSummary = action.data.results;
        break;
      case actionTypes.FETCH_HHT_YARD_LIST_SUMMARY_FAILURE:
        st.isFetchingYardsListSummary = false;
        break;
      case actionTypes.FETCH_HHT_YARD_MAP_START:
        st.isFetching = true;
        st.yardsMap = null;
        st.yards = null;
        break;
      case actionTypes.FETCH_HHT_YARD_MAP_SUCCESS:
        st.isFetching = false;
        st.yardsMap = action.data.results;
        st.yards = action.data.results;
        st.hives = action.data.results.flatMap((yard) => yard.hives_position);
        break;
      case actionTypes.FETCH_HHT_YARD_MAP_FAILURE:
        st.isFetching = false;
        break;
      case actionTypes.FETCH_HHT_YARD_START:
        st.isFetchingYard = true;
        st.yard = null;
        break;
      case actionTypes.FETCH_HHT_YARD_SUCCESS:
        st.isFetchingYard = false;
        st.yard = action.yard;
        break;
      case actionTypes.FETCH_HHT_YARD_FAILURE:
        st.isFetchingYard = false;
        break;
      case actionTypes.UPDATE_YARD_ACTION_START:
        st.isFetchingUpdateYard = true;
        st.yard = null;
        break;
      case actionTypes.UPDATE_YARD_ACTION_SUCCESS:
        const replaceUpdatedYard = (yardList) => {
          let i = yardList.findIndex((v) => v.id === action.id);
          if (i >= 0) {
            yardList.splice(i, 1, {
              id: action.id,
              ...action.yard,
            });
          }
          return yardList;
        };
        let withUpdatedList = state.yardsList && replaceUpdatedYard([...state.yardsList]);
        let withUpdatedMap = state.yardsMap && replaceUpdatedYard([...state.yardsMap]);
        st.isFetchingUpdateYard = false;
        st.yardsList = withUpdatedList;
        st.yardsMap = withUpdatedMap;
        st.yard = action.yard;
        break;
      case actionTypes.UPDATE_YARD_ACTION_FAILURE:
        st.isFetchingUpdateYard = false;
        break;
      case actionTypes.DELETE_YARD_ACTION_START:
        st.isFetchingDeleteYard = true;
        break;
      case actionTypes.DELETE_YARD_ACTION_SUCCESS:
        const deleteYard = (yardList) => yardList.filter((yard) => yard.id !== action.id);
        let withoutDeletedList = state.yardsList && deleteYard([...state.yardsList]);
        let withoutDeletedMap = state.yardsMap && deleteYard([...state.yardsMap]);
        st.isFetchingDeleteYard = false;
        st.yardsList = withoutDeletedList;
        st.yardsMap = withoutDeletedMap;
        break;
      case actionTypes.DELETE_YARD_ACTION_FAILURE:
        st.isFetchingDeleteYard = false;
        break;
      default:
        break;
    }
  });
};
