import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DEFAULT_COLOR_INDEX } from '@helpers/deprecated/color';
import { withGetScreen } from '@HOC/withGetScreen';
import {
  makeAddGroupRequestThunk,
  makeCloseGroupModalAction,
  makeOpenGroupModalAction,
  makeRemoveGroupRequestThunk,
  makeUpdateGroupRequestThunk,
} from '@redux/deprecated/actions';

import Modal from '../Modal/Modal';

// nectar:
import { GroupModalView } from './GroupModalView';

const initialState = {
  group: null,
  initialGroup: null,
  isDisabledBtn: true,
  errorMsg: '',
};

class GroupsModal extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      title: '',
      instructions: '',
      buttonText: '',
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.verifyInput = this.verifyInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setButtonIsDisabledAdd = this.setButtonIsDisabledAdd.bind(this);
    this.setButtonIsDisabledUpdate = this.setButtonIsDisabledUpdate.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { t, contentObj } = this.props;
    const { modalType, group } = contentObj;

    if (contentObj !== prevProps.contentObj) {
      switch (modalType) {
        case 'update-group':
          return this.setState({
            title: t('update_group'),
            instructions: t('create_new_group_instructions'),
            buttonText: t('save'),
            group,
            initialGroup: group,
          });
        case 'add-group':
          return this.setState({
            title: t('add_group'),
            instructions: t('create_new_group_instructions'),
            buttonText: t('add'),
            group: { name: '' },
          });
        case 'remove-group': {
          const { name, nb_yards: nbYards } = group;
          return this.setState({
            title: t('remove_group'),
            instructions: t('remove_group_instructions', { name, nbYards }),
            buttonText: t('remove'),
            group,
          });
        }
        default:
          break;
      }
    }
  }

  handleClose(e) {
    e.preventDefault();
    const { closeDispatch } = this.props;
    closeDispatch();

    this.setState({
      ...initialState,
    });
  }

  setButtonIsDisabledAdd() {
    const {
      group: { name },
    } = this.state;

    this.setState({
      isDisabledBtn: '' === name,
    });
  }

  setButtonIsDisabledUpdate() {
    const {
      group: { name, color },
      initialGroup,
    } = this.state;

    this.setState({
      isDisabledBtn: true,
    });
    if ((initialGroup?.name !== name || initialGroup?.color !== parseInt(color)) && '' !== name) {
      this.setState({
        isDisabledBtn: false,
      });
    }
  }

  handleChangeName(event) {
    const {
      contentObj: { modalType },
    } = this.props;

    this.setState(
      {
        group: {
          ...this.state.group,
          name: event.target.value,
        },
      },
      modalType === 'add-group'
        ? () => {
            this.setButtonIsDisabledAdd();
          }
        : () => {
            this.setButtonIsDisabledUpdate();
          }
    );
  }

  handleChangeColor(color) {
    const {
      contentObj: { modalType },
    } = this.props;

    this.setState(
      {
        group: {
          ...this.state.group,
          color,
        },
      },
      modalType === 'update-group'
        ? () => {
            this.setButtonIsDisabledUpdate();
          }
        : () => {}
    );
  }

  verifyInput() {
    const { t } = this.props;

    const { group } = this.state;

    this.setState({
      errorMsg: '',
    });

    let error = false;

    if (!group.name) {
      this.setState({
        errorMsg: t('no_name'),
      });
      error = true;
    }
    return error;
  }

  handleKeyDown(event) {
    const { contentObj } = this.props;
    const { isDisabledBtn } = this.state;

    const { modalType } = contentObj;

    if (modalType.includes('group')) {
      if ('Enter' === event.key && !isDisabledBtn) this.handleSubmit(event);
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { group } = this.state;
    const { removeGroupDispatch, updateGroupDispatch, createGroupDispatch, contentObj, closeDispatch } = this.props;

    const { modalType } = contentObj;

    if ('remove-group' === modalType) {
      const { id } = group;
      removeGroupDispatch(id);
      this.setState({
        ...initialState,
      });
      closeDispatch();
      return;
    }

    if (!this.verifyInput()) {
      if ('update-group' === modalType) {
        const { id } = group;
        updateGroupDispatch(id, group);
        this.setState({
          ...initialState,
        });
        closeDispatch();
        return;
      }
      if ('add-group' === modalType) {
        if (!group.color) {
          group.color = DEFAULT_COLOR_INDEX;
        }

        createGroupDispatch(group);
        closeDispatch();
        this.setState({
          ...initialState,
        });
      }
    }
  }

  render() {
    const { t, isGroupOpen, isMobile, contentObj } = this.props;

    const { isDisabledBtn, errorMsg, group, title, instructions, buttonText } = this.state;

    const { modalType } = contentObj;

    return (
      <Modal isMobile={isMobile()} isOpen={isGroupOpen} onRequestClose={this.handleClose} modalType={modalType}>
        <GroupModalView
          t={t}
          isMobile={isMobile()}
          contentObj={{
            group,
            title,
            instructions,
            buttonText,
            modalType: contentObj.modalType,
          }}
          isDisabledBtn={isDisabledBtn}
          errorMsg={errorMsg}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleChangeName={this.handleChangeName}
          handleChangeColor={this.handleChangeColor}
        />
      </Modal>
    );
  }
}
/**
 *
 * @param dispatch
 * @returns {{openGroupModal: makeOpenGroupModalAction}}
 * @returns {{closeDispatch: closeDispatch}}
 *
 */
const mapDispatchToProps = (dispatch) => ({
  openGroupModal: (content) => {
    dispatch(makeOpenGroupModalAction(content));
  },
  closeDispatch: () => {
    dispatch(makeCloseGroupModalAction());
  },
  updateGroupDispatch: (id, body) => {
    dispatch(makeUpdateGroupRequestThunk(id, body));
  },
  createGroupDispatch: (body) => {
    dispatch(makeAddGroupRequestThunk(body));
  },
  removeGroupDispatch: (id) => {
    dispatch(makeRemoveGroupRequestThunk(id));
  },
});

/**
 *
 * @param state
 * @returns {{isGroupOpen: (*|boolean)}}
 */
const mapStateToProps = (state) => ({
  isGroupOpen: state.modalReducer.isGroupOpen,
  contentObj: state.modalReducer.contentObj,
});

GroupsModal.propTypes = {
  isGroupOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  openGroupModal: PropTypes.func.isRequired,
  removeGroupDispatch: PropTypes.func.isRequired,
  createGroupDispatch: PropTypes.func.isRequired,
  updateGroupDispatch: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(GroupsModal)))
);
