import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { TabView } from '@components/common/Tabs/styles';
import { TabProps } from '@components/common/Tabs/types';
import { buildShallowEquals } from '@helpers/deprecated/shallowEquals';

import { TabInfoContext, TabsContext } from './context';

export const Tab = React.memo(({ id, path, title, isScrollable, children }: TabProps) => {
  const [tabHeight, setTabHeight] = useState(0);

  const tabViewRef = useRef<HTMLDivElement>(null);
  const tabContext = useContext(TabsContext);
  const tabInfo = useMemo(() => tabContext?.tabs[id], [id, tabContext?.tabs]);

  useEffect(() => {
    tabContext?.__registerTab({ id, path, title });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, title]);

  useEffect(
    () => () => {
      tabContext?.__unregisterTab({ id, path, title });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  useLayoutEffect(() => {
    if (!tabViewRef.current) {
      return;
    }

    const tabTop = tabViewRef.current.getBoundingClientRect().top;
    const tabHeight = window.innerHeight - tabTop;
    setTabHeight(tabHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabInfo?.hasBeenVisited]);

  if (!tabInfo) {
    return null;
  }

  const { isActive, hasBeenVisited } = tabInfo;

  return hasBeenVisited ? (
    <TabInfoContext.Provider value={tabInfo}>
      <TabView
        ref={tabViewRef}
        column
        stretch
        $isActive={isActive}
        $isFixed={tabContext?.isFixed}
        $isTabBarVisible={!!tabContext?.isTabBarVisible}
        $isScrollable={isScrollable}
        $height={tabHeight}
      >
        {children}
      </TabView>
    </TabInfoContext.Provider>
  ) : null;
}, buildShallowEquals<TabProps>(['id', 'title', 'children']));

Tab.displayName = 'Tab';
