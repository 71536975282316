import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Edit } from '@components/common/Icon/presets/Edit';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { YardStatusChip } from '@components/common/YardStatusChip';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenUpdateYardModalAction } from '@redux/deprecated/actions';
import { makeYardDetailsFetchThunk } from '@redux/YardDetails/actions';

export const YardDetailsHeader: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const { detail } = useSelector((state) => state.yardDetailsReducer);

  const openEditYardModal = useCallback(() => {
    if (detail) {
      dispatch(
        makeOpenUpdateYardModalAction({
          modalType: 'update-yard',
          yard: detail,
          onUpdated: () => dispatch(makeYardDetailsFetchThunk(detail.id)),
        })
      );
    }
  }, [detail, dispatch]);

  if (!detail) {
    return null;
  }

  return (
    <PageHeader>
      <Box fit alignItems={'center'} gapSM>
        <PageHeaderTitle fit={false}>{detail.name}</PageHeaderTitle>
        <YardStatusChip status={detail.status} />
      </Box>
      <Button primary withIconLeft onClick={openEditYardModal}>
        <Edit />
        {t('edit_yard')}
      </Button>
    </PageHeader>
  );
};
