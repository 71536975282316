import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// icons
import notesIcon from '@assets/xSmall_Notes.svg';
import { Text } from '@components/common/Text';
// nectar
import { getActionName } from '@helpers/deprecated/actions';
import { getActionCategory } from '@helpers/deprecated/actions';
import { isEmptyArray } from '@helpers/deprecated/array';
import theme from '@style/theme';

const InspectionContainer = styled.div`
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  margin: 0.5rem 0;
  letter-spacing: 0; ;
`;

const InspectionContent = styled.div`
  height: auto;
  min-height: 2rem;
  align-items: flex-start;
  width: auto;
  word-break: break-word;
  display: flex;
  padding: 0 0.5rem;

  img {
    margin: 10px 0;
  }

  p {
    margin: 8px 0;

    & > span:first-child {
      font-weight: 600;
    }
  }
`;

const StyledIconImg = styled.img`
  filter: ${(props) => props.filter};
  padding-right: 0.5rem;
`;

const tooltipStyle = {
  fontSize: theme.captionSmallFontSize,
  lineHeight: '1rem',
  color: theme.buttonPrimaryTextColor,
  border: theme.tooltipBorderStyle,
};

const eventStyle = {
  fontSize: theme.paragraphSmallFontSize,
  lineHeight: '1.5rem',
  color: theme.primaryTextColor,
  border: theme.primaryBorderStyle,
};

export const InspectionView = ({ actions, note, actionList, type }) => {
  const { t } = useTranslation();

  return (
    <InspectionContainer style={'tooltip' === type ? { ...tooltipStyle } : { ...eventStyle }}>
      {actions &&
        !isEmptyArray(actions) &&
        actions.map((action, idx) => (
          <InspectionContent
            key={idx}
            style={{
              borderBottom:
                idx !== actions.length - 1 || note
                  ? 'tooltip' === type
                    ? theme.tooltipBorderStyle
                    : theme.primaryBorderStyle
                  : 'none',
            }}
          >
            <StyledIconImg
              filter={'tooltip' === type ? 'invert(1)' : theme.primaryColorFilter}
              src={getActionCategory('icon', actionList, action, 'x')}
              alt="action icon"
            />
            <Text typography={'SmallParagraph'} lineHeight="1.125rem">
              <span>{capitalize(getActionCategory('name', actionList, action))}</span>:{' '}
              {getActionName(action, actionList)}
            </Text>
          </InspectionContent>
        ))}
      {note && (
        <InspectionContent>
          <StyledIconImg
            filter={'tooltip' === type ? 'invert(1)' : theme.primaryColorFilter}
            src={notesIcon}
            alt="notes icon"
          />
          <Text typography={'SmallParagraph'} lineHeight="1.125rem">
            <span>{t('notes')}</span>: {note}
          </Text>
        </InspectionContent>
      )}
    </InspectionContainer>
  );
};

InspectionView.propTypes = {
  actions: PropTypes.array.isRequired,
  note: PropTypes.string,
  type: PropTypes.string.isRequired,
  actionList: PropTypes.array.isRequired,
};
