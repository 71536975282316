import React from 'react';

import { Text } from '@components/common/Text';

import { TableCellView } from './styles';
import { TableCellProps } from './types';

export const TableCell = React.forwardRef<HTMLDivElement, React.PropsWithChildren<TableCellProps>>(
  ({ title, children, ...props }, ref) => {
    return (
      <TableCellView data-title={title} {...props} ref={ref}>
        {typeof children === 'string' ? <Text typography={'CaptionSmall'}>{children}</Text> : children}
      </TableCellView>
    );
  }
);

TableCell.displayName = 'TableCell';
