import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import abnormalIcon from '@assets/alerts/Small_Abnormal.svg';
import beeconIcon from '@assets/alerts/Small_Beecon.svg';
import beehubIcon from '@assets/alerts/Small_Beehub.svg';
import deadoutIcon from '@assets/alerts/Small_Deadout.svg';
import queenlessIcon from '@assets/alerts/Small_Queenless.svg';
import map from '@assets/Map-terrain.png';
//medium icons
import mediumBackIcon from '@assets/MediumBack.svg';
import mediumBurgerMenuIcon from '@assets/MediumBurgerMenu.svg';
import mediumCloseIcon from '@assets/MediumClose.svg';
import mediumNotificationIcon from '@assets/MediumNotification.svg';
import smallArrowDownIcon from '@assets/Small_Arrow-down.svg';
import disableIcon from '@assets/Small_Box-disable.svg';
import smallBurgerIcon from '@assets/Small_Burger-menu.svg';
import smallCloseIcon from '@assets/Small_Close.svg';
import copyLinkIcon from '@assets/Small_Copy-link.svg';
import downloadIcon from '@assets/Small_Download.svg';
import passHideIcon from '@assets/Small_Hide.svg';
import hivesIcon from '@assets/Small_Hives.svg';
import smallInfoIcon from '@assets/Small_Info.svg';
import layersIcon from '@assets/Small_Layers.svg';
import locationIcon from '@assets/Small_Location.svg';
import notificationIcon from '@assets/Small_Mobile-Menu-Notification.svg';
import radioCheckedIcon from '@assets/Small_Radio-checked.svg';
import radioUncheckedIcon from '@assets/Small_Radio-unchecked.svg';
import shareIcon from '@assets/Small_Share.svg';
import passShowIcon from '@assets/Small_Show.svg';
//small icons
import backIcon from '@assets/SmallBack.svg';
import checkedIcon from '@assets/SmallBoxChecked.svg';
import uncheckedIcon from '@assets/SmallBoxUnchecked.svg';
//weather small icons
import clearIcon_sm from '@assets/weather/Small_Clear-day.svg';
import cloudyIcon_sm from '@assets/weather/Small_Cloudy.svg';
import fogIcon_sm from '@assets/weather/Small_Fog.svg';
import part_cloudyIcon_sm from '@assets/weather/Small_Partly-cloudy-day.svg';
import rain_highIcon_sm from '@assets/weather/Small_Rain-high.svg';
import rain_lowIcon_sm from '@assets/weather/Small_Rain-low.svg';
import rain_medIcon_sm from '@assets/weather/Small_Rain-med.svg';
import sleetIcon_sm from '@assets/weather/Small_Sleet.svg';
import snowIcon_sm from '@assets/weather/Small_Snow.svg';
import stormIcon_sm from '@assets/weather/Small_Storm.svg';
import windIcon_sm from '@assets/weather/Small_Wind.svg';
//weather xsmall icons
import clearIcon from '@assets/weather/xSmall_Clear-day.svg';
import cloudyIcon from '@assets/weather/xSmall_Cloudy.svg';
import fogIcon from '@assets/weather/xSmall_Fog.svg';
import part_cloudyIcon from '@assets/weather/xSmall_Partly-cloudy-day.svg';
import rain_highIcon from '@assets/weather/xSmall_Rain-high.svg';
import rain_lowIcon from '@assets/weather/xSmall_Rain-low.svg';
import rain_medIcon from '@assets/weather/xSmall_Rain-med.svg';
import sleetIcon from '@assets/weather/xSmall_Sleet.svg';
import snowIcon from '@assets/weather/xSmall_Snow.svg';
import stormIcon from '@assets/weather/xSmall_Storm.svg';
import windIcon from '@assets/weather/xSmall_Wind.svg';
import triangleDownIcon from '@assets/xSmall_Arrow-dropdown.svg';
// xsmall icons
import increaseIcon from '@assets/xSmall_Arrow-NE.svg';
import decreaseIcon from '@assets/xSmall_Arrow-SE.svg';
import lowBatteryIcon from '@assets/xSmall_Battery-low.svg';
import checkIcon from '@assets/xSmall_Check.svg';
import lowDataIcon from '@assets/xSmall_Data-low.svg';
import equalIcon from '@assets/xSmall_Equal.svg';
import infoIcon from '@assets/xSmall_Info.svg';
import moreIcon from '@assets/xSmall_More.svg';
import { StatusChip } from '@components/deprecated/Chip/StatusChip';
import { YardCardView } from '@components/deprecated/Partials/YardCardView';
import StepRangeSlider from '@components/deprecated/StepRangeSlider/StepRangeSlider';
import { Toggle } from '@components/deprecated/Toggle/Toggle';
import theme from '@style/theme';

const InlineBlock = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: ${(props) => (props.marginright ? props.marginright : '2rem')};
  margin-left: ${(props) => props.marginleft && props.marginleft};
  margin-bottom: ${(props) => props.marginbottom && props.marginbottom};
  margin-top: ${(props) => props.margintop && props.margintop};
`;

const WhiteBGInlineBlock = styled.div`
  display: inline-block;
  position: relative;
  height: 178px;
  width: 208px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
`;

const ColorInlineBlock = styled.div`
  height: 4rem;
  width: 4rem;
  background-color: ${(props) => props.background};
`;

const ColorTextInlineBlock = styled.div`
  width: 4rem;
  height: 20px;
  margin-top: 1rem;
  font-family: Monaco;
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.captionSmallFontSize};
  line-height: 20px;
`;

const SectionBreak = styled.div`
  height: 8rem;
`;

const MapImg = styled.img`
  height: 417px;
  width: 638px;
`;

const StyledSmallImg = styled.img`
  margin-right: 22px;
  filter: ${(props) => props.theme.primaryColorFilter};
`;

const ToggleSliderContainer = styled.div`
  width: 488px;
  height: 90px;
  padding: 1.5rem;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
`;

const StyledText = styled.p`
  margin-bottom: 1.5rem;
  font-family: Monaco;
  text-transform: uppercase;
`;

const BoxShadowCard = styled.div`
  padding: 25px 1rem;
  height: 110px;
  width: 277px;
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  box-shadow: ${(props) => props.boxShadow};
  color: ${(props) => props.theme.primaryTextColor};
  font-family: Monaco;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  letter-spacing: 0;
  line-height: 1rem;
  text-transform: none;
`;

const FloatingText = styled.p`
  position: relative;
  width: 100%;
  text-transform: capitalize;
  font-family: Monaco;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  letter-spacing: 0;
  line-height: 1rem;
`;

const Divider = styled.div`
  position: relative;
  border-left: 1px solid #71a3fb;
  top: 10px;
`;

export const LiveStyleGuideFirstView = ({ t, checked, handleToggleChecked }) => {
  const primaryColors = [
    { color: theme.primaryColor, text: '#252629' },
    { color: `${theme.primaryColor}3C`, text: '#252629 60%' },
    { color: theme.supportingColor, text: '#454850' },
    { color: theme.inputColor, text: '#D4D4D4' },
    { color: theme.cardColor, text: '#E6E6E6' },
  ];

  const secondaryColors = [
    { color: theme.criticalColor, text: '#D9766D' },
    { color: theme.worryingColor, text: '#FFCF80' },
    { color: theme.goodColor, text: '#A4D96C' },
    { color: theme.nectarColor, text: '#D8A348' },
    { color: theme.selectedColor, text: '#F7BD13' },
    { color: theme.boxShadowColor, text: '#71A3FB' },
  ];

  const xsmallIcons = [
    { src: increaseIcon, alt: 'increase icon' },
    { src: decreaseIcon, alt: 'decrease icon' },
    { src: lowBatteryIcon, alt: 'low battery icon' },
    { src: checkIcon, alt: 'check icon' },
    { src: lowDataIcon, alt: 'low data icon' },
    { src: equalIcon, alt: 'equal icon' },
    { src: moreIcon, alt: 'more icon' },
    { src: triangleDownIcon, alt: 'dropdown triangle icon' },
    { src: infoIcon, alt: 'info icon' },
  ];

  const smallIcons = [
    { src: uncheckedIcon, alt: 'unchecked icon' },
    { src: radioUncheckedIcon, alt: 'radio unchecked icon' },
    { src: smallBurgerIcon, alt: 'burger icon' },
    { src: smallInfoIcon, alt: 'small info icon' },
    { src: shareIcon, alt: 'share icon' },
    { src: smallArrowDownIcon, alt: 'small arrow down' },
    { src: hivesIcon, alt: 'hives icon' },
    { src: beeconIcon, alt: 'beecon icon' },
    { src: checkedIcon, alt: 'checked icon' },
    { src: radioCheckedIcon, alt: 'radio checked icon' },
    { src: smallCloseIcon, alt: 'small close icon' },
    { src: passShowIcon, alt: 'pass show icon' },
    { src: copyLinkIcon, alt: 'copy link icon' },
    { src: locationIcon, alt: 'location icon' },
    { src: queenlessIcon, alt: 'queenless icon' },
    { src: beehubIcon, alt: 'beehub icon' },
    { src: disableIcon, alt: 'disable icon' },
    { src: backIcon, alt: 'back icon' },
    { src: notificationIcon, alt: 'notification icon' },
    { src: passHideIcon, alt: 'pass hide icon' },
    { src: downloadIcon, alt: 'download icon' },
    { src: layersIcon, alt: 'layers icon' },
    { src: deadoutIcon, alt: 'deadout icon' },
    { src: abnormalIcon, alt: 'abnormal icon' },
  ];

  const mediumIcons = [
    { src: mediumBackIcon, alt: 'back icon' },
    { src: mediumBurgerMenuIcon, alt: 'burger menu icon' },
    { src: mediumNotificationIcon, alt: 'notification icon' },
    { src: mediumCloseIcon, alt: 'close icon' },
  ];

  const weatherIcons = [
    { src: clearIcon, alt: 'clear icon' },
    { src: cloudyIcon, alt: 'cloudy icon' },
    { src: fogIcon, alt: 'fog icon' },
    { src: rain_lowIcon, alt: 'low rain icon' },
    { src: rain_medIcon, alt: 'medium rain icon' },
    { src: rain_highIcon, alt: 'high rain icon' },
    { src: part_cloudyIcon, alt: 'part cloudy icon' },
    { src: sleetIcon, alt: 'sleet icon' },
    { src: snowIcon, alt: 'snow icon' },
    { src: stormIcon, alt: 'storm icon' },
    { src: windIcon, alt: 'wind icon' },
  ];

  const weatherIcons_sm = [
    { src: clearIcon_sm, alt: 'clear icon' },
    { src: cloudyIcon_sm, alt: 'cloudy icon' },
    { src: fogIcon_sm, alt: 'fog icon' },
    { src: rain_lowIcon_sm, alt: 'low rain icon' },
    { src: rain_medIcon_sm, alt: 'medium rain icon' },
    { src: rain_highIcon_sm, alt: 'high rain icon' },
    { src: part_cloudyIcon_sm, alt: 'part cloudy icon' },
    { src: sleetIcon_sm, alt: 'sleet icon' },
    { src: snowIcon_sm, alt: 'snow icon' },
    { src: stormIcon_sm, alt: 'storm icon' },
    { src: windIcon_sm, alt: 'wind icon' },
  ];

  const boxShadow = [
    {
      name: 'Level 1',
      style: theme.boxShadow1,
      line1: '0 1px 3px rgba(102,102,102,0.10),',
      line2: '0 1px 3px rgba(102,102,102,0.20)',
    },
    {
      name: 'Level 2',
      style: theme.boxShadow2,
      line1: '0 3px 6px rgba(102,102,102,0.10),',
      line2: '0 3px 6px rgba(102,102,102,0.20)',
    },
    {
      name: 'Level 3',
      style: theme.boxShadow3,
      line1: '0 10px 20px rgba(102,102,102,0.15),',
      line2: '0 6px 6px rgba(102,102,102,0.15)',
    },
    {
      name: 'Level 4',
      style: theme.boxShadow4,
      line1: '0 14px 28px rgba(102,102,102,0.20),',
      line2: '0 10px 10px rgba(102,102,102,0.30)',
    },
  ];

  return (
    <>
      <StyledText>{t('colours')}</StyledText>

      {primaryColors.map((c, idx) => (
        <InlineBlock key={idx} marginright={idx === primaryColors.length - 1 && '1.5rem'}>
          <ColorInlineBlock background={c.color} />
          <ColorTextInlineBlock>{c.text}</ColorTextInlineBlock>
        </InlineBlock>
      ))}

      <WhiteBGInlineBlock style={{ bottom: '39px' }}>
        <InlineBlock marginleft="1.5rem" margintop="39px">
          <ColorInlineBlock background={theme.colors.coreBackground} />
          <ColorTextInlineBlock>#F2F2F2</ColorTextInlineBlock>
        </InlineBlock>

        <InlineBlock marginright="1.5rem" margintop="39px">
          <ColorInlineBlock background={theme.timelineColor} />
          <ColorTextInlineBlock>#F9F7F3</ColorTextInlineBlock>
        </InlineBlock>
      </WhiteBGInlineBlock>

      <InlineBlock marginleft="1.5rem">
        <ColorInlineBlock background={theme.primaryBackgroundColor} />
        <ColorTextInlineBlock>#FFFFFF</ColorTextInlineBlock>
      </InlineBlock>

      <br />

      {secondaryColors.map((c, idx) => (
        <InlineBlock key={idx}>
          <ColorInlineBlock background={c.color} />
          <ColorTextInlineBlock>{c.text}</ColorTextInlineBlock>
        </InlineBlock>
      ))}

      <SectionBreak />

      <InlineBlock style={{ borderRight: '1px solid #D4D4D4' }}>
        <StyledText>{t('icons')}</StyledText>

        <InlineBlock>
          {xsmallIcons.map((xs, idx) => (
            <span key={idx}>
              <StyledSmallImg src={xs.src} alt={xs.alt} />
              {4 === idx && <br />}
            </span>
          ))}
        </InlineBlock>

        <InlineBlock>
          {smallIcons.map((s, idx) => (
            <span key={idx}>
              <StyledSmallImg src={s.src} alt={s.alt} />
              {(7 === idx || 15 === idx) && <br />}
            </span>
          ))}
        </InlineBlock>

        <InlineBlock>
          {mediumIcons.map((m, idx) => (
            <StyledSmallImg key={idx} src={m.src} alt={m.alt} />
          ))}
        </InlineBlock>
      </InlineBlock>

      <InlineBlock>
        <StyledText>{t('weather_icons')}</StyledText>

        <InlineBlock>
          {weatherIcons.map((s, idx) => (
            <span key={idx}>
              <StyledSmallImg src={s.src} alt={s.alt} />
              {5 === idx && <br />}
            </span>
          ))}
        </InlineBlock>

        <InlineBlock>
          {weatherIcons_sm.map((s, idx) => (
            <span key={idx}>
              <StyledSmallImg src={s.src} alt={s.alt} />
              {5 === idx && <br />}
            </span>
          ))}
        </InlineBlock>
      </InlineBlock>

      <SectionBreak />

      <ToggleSliderContainer>
        <InlineBlock marginright="96px">
          <StyledText style={{ marginBottom: '1rem' }}>{t('toggle')}</StyledText>

          <div style={{ marginBottom: '20px', marginLeft: '6px' }}>
            <Toggle id="toggle1" checked={checked} handleToggle={() => handleToggleChecked('checked')} />
          </div>
        </InlineBlock>

        <InlineBlock>
          <StyledText style={{ marginLeft: '7px' }}>{t('slider')}</StyledText>
          <div style={{ width: '221px' }}>
            <StepRangeSlider
              value={7}
              range={Array.from({ length: 6 }, (v, k) => ({ value: k + 5 }))}
              onChangeComplete={(value) => console.log(value)}
              hideRange={true}
            />
          </div>
        </InlineBlock>
      </ToggleSliderContainer>

      <SectionBreak />

      <InlineBlock marginright="6rem">
        <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
          <Divider style={{ left: '115px', height: '100px' }} />
          <Divider style={{ left: '240px', height: '300px' }} />
          <MapImg src={map} alt="map" />
        </div>

        <FloatingText style={{ top: '10px', left: '100px' }}>{t('yard')}</FloatingText>

        <FloatingText style={{ top: '-5px', left: '222px' }}>{t('cluster')}</FloatingText>
      </InlineBlock>

      <InlineBlock marginright="6rem">
        <StyledText>{t('yard_status')}</StyledText>

        {['c', 'critical', 'w', 'worrying', 'g', 'good'].map((item, idx) => (
          <span key={idx}>
            <InlineBlock marginright="1rem" marginbottom="31px">
              <StatusChip isMobile={0 === idx % 2 && true}>{item}</StatusChip>
            </InlineBlock>{' '}
            {1 === idx % 2 && <br />}
          </span>
        ))}
      </InlineBlock>

      <InlineBlock>
        <StyledText>{t('yard_card')}</StyledText>
        <YardCardView
          t={t}
          yard={{
            yard_id: 116,
            yard_name: 'Bozeman site 1',
            yard_nickname: 'BS1',
            total_hives: 32,
            ai_events: { nb_queenless: 8, nb_deadouts: 3, avg_pop_trend: -1, criticality: 0 },
            yard_location: 'SRID=4326;POINT (-103.3490000024841 46.17899999953806)',
            hardware: { nb_low_battery: 0, nb_low_data_rate: 0, nb_low_rssi: 0, nb_no_transmission: 14 },
            recently_updated: true,
            latest_update: '2020-09-23T23:13:12Z',
          }}
          showWeather={true}
        />
      </InlineBlock>
      <SectionBreak />
      <InlineBlock>
        <StyledText>{t('shadow_levels')}</StyledText>
        {boxShadow.map((b, idx) => (
          <InlineBlock key={idx}>
            <BoxShadowCard boxShadow={b.style}>
              <p>{b.name}</p>
              <p>{t('box-shadow')}</p>
              <p>{b.line1}</p>
              <p>{b.line2}</p>
            </BoxShadowCard>
          </InlineBlock>
        ))}
      </InlineBlock>
    </>
  );
};

LiveStyleGuideFirstView.propTypes = {
  t: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  handleToggleChecked: PropTypes.func.isRequired,
};
