import React from 'react';
import PropTypes from 'prop-types';

//nectar
import backIcon from '@assets/MediumBack.svg';
import logo from '@assets/nectar-logo.svg';
import backMobileIcon from '@assets/SmallBack.svg';
import { BackgroundImage } from '@components/deprecated/BackgroundImage/BackgroundImage';
import { PrimaryButton } from '@components/deprecated/Buttons/PrimaryButton';
import {
  Container,
  LoginContainer,
  StyledErrorMsg,
  StyledForm,
  StyledHeader,
  StyledLogoImg,
  StyledText,
} from '@components/deprecated/Elements/AuthElements';
import { StyledHeading1 } from '@components/deprecated/Elements/TypographyElements';
import { Fieldset } from '@components/deprecated/Fieldset/Fieldset';
import { TextInput } from '@components/deprecated/TextInput/TextInput';

export const ForgottenPasswordView = ({
  t,
  isMobile,
  email,
  handleSubmit,
  handleChange,
  handleGoBack,
  isDisabledBtn,
  errorMsg,
}) => {
  return (
    <Container>
      <LoginContainer isMobile={isMobile}>
        <StyledForm isMobile={isMobile} onSubmit={handleSubmit} autoComplete="off">
          <StyledHeader>
            <StyledLogoImg src={logo} className="" alt="nectar logo" />
          </StyledHeader>

          <section style={{ flex: '0 1 25%' }} />

          <StyledHeader>
            <button style={{ marginRight: isMobile ? '1rem' : '1.5rem' }} type="button" onClick={handleGoBack}>
              <img src={isMobile ? backMobileIcon : backIcon} alt="back" />
            </button>
            <StyledHeading1 style={{ display: 'inline-block' }} mobile={isMobile}>
              {t('forgot_password?')}
            </StyledHeading1>
          </StyledHeader>

          <StyledText>{t('forgot_password_instructions')}</StyledText>

          <StyledErrorMsg>{errorMsg}</StyledErrorMsg>

          <Fieldset style={{ margin: '2rem 0' }} tabIndex={1} error={errorMsg}>
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              value={email}
              label={t('email')}
              handleChange={handleChange}
              error={errorMsg}
            />
          </Fieldset>

          <section style={{ flex: '0 1 36%' }}>
            <PrimaryButton label={t('send')} onClick={handleSubmit} disabled={isDisabledBtn} />
          </section>
        </StyledForm>
      </LoginContainer>

      {!isMobile && <BackgroundImage />}
    </Container>
  );
};

ForgottenPasswordView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
};
