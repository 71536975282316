import React from 'react';
import styled from 'styled-components';

import { PRESETS } from '@components/common/Text/styles';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'large' | 'small' | 'auto';
  flush?: boolean;
  flat?: boolean;
}

export const PrimaryButton = styled.button<ButtonProps>(({ theme, size, flat, flush }) => {
  const padding = flush ? '0' : size === 'small' ? '8px 24px' : size === 'large' ? '12px 24px' : '12px 24px';

  return `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${padding};
    height: auto;
    font-size: ${PRESETS.CTA.fontSize};
    line-height: ${PRESETS.CTA.lineHeight};
    font-weight: 700;
    letter-spacing: 1px; 
    text-transform: uppercase;
    background-color: ${flat ? 'transparent' : theme.colors.grey08};
    color: ${flat ? theme.colors.grey08 : theme.colors.coreWhite};
    border-radius: ${theme.shape.paperRadius01}px;
    
    &:hover {
      color: ${flat ? theme.colors.grey06 : theme.colors.grey05};
    }
    
    &:focus-visible {
      box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02};
    } 
    
    &[disabled] {
      background-color: ${flat ? 'transparent' : theme.colors.grey05};
      color: ${flat ? theme.colors.grey05 : theme.colors.grey06};
      cursor: default;
    }
  `;
});

export const PrimaryButtonWithIcon = styled(PrimaryButton)(
  ({ theme }) => `
    svg {
      margin-right: ${theme.spacing.xs}px;
    }
  `
);
