import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Close } from '@components/common/Icon';
import { HelpAdd } from '@components/common/Icon/presets/HelpAdd';
import { PoiIcon } from '@components/common/PoiIcon';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapMode } from '@components/pollination/PolliMap/types';
import {
  PoiFloatingIconWrapper,
  SummaryClose,
  SummaryContent,
  SummaryFooter,
  SummaryHeader,
  SummaryHeaderTitle,
  SummaryPaddedColumn,
  SummaryRow,
  SummaryTitle,
} from '@components/pollination/PolliMapPaneSummary/styles';

import { StyledButtonFlat, StyledButtonPrimary } from '../PolliMap/styles';

export const PolliMapPanePoi: React.VFC = () => {
  const { t } = useTranslation();
  const {
    selectedPoi,
    removePoi,
    setMode,
    clearAnyElementSelection,
    isManaging,
    isManagingPois,
    isManagingAPoi,
    isDraggingElements,
  } = usePolliMap();

  const goToEdit = useCallback(() => {
    setMode(PolliMapMode.MANAGING_A_POI);
  }, [setMode]);

  if (isDraggingElements || isManagingAPoi || !selectedPoi) {
    return null;
  }

  return (
    <Box column stretch fit>
      <SummaryHeader>
        <SummaryHeaderTitle typography={'Heading3'} weight={'600'}>
          {t('selected')}
        </SummaryHeaderTitle>
        <SummaryClose onClick={clearAnyElementSelection} aria-label={t('close')}>
          <Close size={24} />
        </SummaryClose>
      </SummaryHeader>

      <SummaryContent>
        <SummaryRow paddingTopSM paddingRightLG>
          <HelpAdd size={16} color={'grey08'} />
          <SummaryTitle typography={'Heading3'} weight={'600'}>
            {selectedPoi.name}
          </SummaryTitle>
          <PoiFloatingIconWrapper>
            <PoiIcon size={16} color={'coreWhite'} category={selectedPoi.category} />
          </PoiFloatingIconWrapper>
        </SummaryRow>
        <SummaryPaddedColumn>
          <Box marginLeftXXS column>
            <Box marginBottomXS>
              <Text typography={'CaptionSmall'} weight={'600'}>
                {t('pollination_poi_en_desc_title')}
              </Text>
            </Box>
            <Text typography={'Heading3'}>{selectedPoi.descriptionEnglish || '-'}</Text>

            <Box marginTopMD marginBottomXS>
              <Text typography={'CaptionSmall'} weight={'600'}>
                {t('pollination_poi_es_desc_title')}
              </Text>
            </Box>
            <Text typography={'Heading3'}>{selectedPoi.descriptionSpanish || '-'}</Text>
          </Box>
        </SummaryPaddedColumn>
      </SummaryContent>

      {isManaging || isManagingPois ? (
        <SummaryFooter paddingSM>
          <StyledButtonFlat onClick={() => removePoi(selectedPoi)}>
            <Text weight={'700'} typography={'CTA'}>
              {t('delete')}
            </Text>
          </StyledButtonFlat>
          <StyledButtonPrimary onClick={goToEdit}>
            <Text weight={'700'} typography={'CTA'}>
              {t('edit')}
            </Text>
          </StyledButtonPrimary>
        </SummaryFooter>
      ) : null}
    </Box>
  );
};
