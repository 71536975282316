import React, { useCallback, useMemo } from 'react';

import { useYardsMap } from '@components/yard/YardsMap/hooks';
import { ActiveLayers, YardsMapLayer } from '@components/yard/YardsMap/types';
import { YardsMapBeeFlightRadiusLayer } from '@components/yard/YardsMapLayers/YardsMapBeeFlightRadiusLayer';
import { YardsMapBlocksLayer } from '@components/yard/YardsMapLayers/YardsMapBlocksLayer';
import { YardsMapDefaultLayer } from '@components/yard/YardsMapLayers/YardsMapDefaultLayer';
import { YardsMapLastInspectionLayer } from '@components/yard/YardsMapLayers/YardsMapLastInspectionLayer';

export const YardsMapLayers: React.VFC = () => {
  const { yards, selectedYard, blocks, activeLayers, setSelectedYard } = useYardsMap();

  const isYardSelected = useCallback((yard: BeeYardOnMapInfo) => yard.id === selectedYard?.id, [selectedYard?.id]);
  const toggleYardSelection = useCallback(
    (yard: BeeYardOnMapInfo) => {
      if (selectedYard?.id === yard.id) {
        setSelectedYard(null);
      } else {
        setSelectedYard(yard);
      }
    },
    [selectedYard?.id, setSelectedYard]
  );

  const cleanActiveLayers = useMemo(() => {
    const _cleanActiveLayers: ActiveLayers = { ...activeLayers };

    // Special logic to disable default layer in case
    // "Last Inspections" layer is active.
    const isLastInspectionsVisible = activeLayers[YardsMapLayer.LAST_INSPECTIONS];
    if (isLastInspectionsVisible) {
      _cleanActiveLayers[YardsMapLayer.DEFAULT] = false;
    }

    return _cleanActiveLayers;
  }, [activeLayers]);

  return (
    <>
      {cleanActiveLayers[YardsMapLayer.DEFAULT] && (
        <YardsMapDefaultLayer yards={yards} isYardSelected={isYardSelected} onYardPinClick={toggleYardSelection} />
      )}
      {cleanActiveLayers[YardsMapLayer.LAST_INSPECTIONS] && (
        <YardsMapLastInspectionLayer
          yards={yards}
          isYardSelected={isYardSelected}
          onYardPinClick={toggleYardSelection}
        />
      )}
      {cleanActiveLayers[YardsMapLayer.BEE_FLIGHT_RADIUS] && <YardsMapBeeFlightRadiusLayer yards={yards} />}
      {cleanActiveLayers[YardsMapLayer.POLLINATION_BLOCKS] && <YardsMapBlocksLayer blocks={blocks} />}
    </>
  );
};
