import { useCallback } from 'react';
import { GridApi } from 'ag-grid-community';

import { useYardsListDataCycle } from '@components/yard/YardsList/hooks/useYardsListDataCycle';
import { GridApiUtil } from '@components/yard/YardsList/util';

export function useYardsListPaginationHelper(gridApi: GridApi | null) {
  const applyPageFromURL = useCallback((gridApi) => {
    const initialPage = GridApiUtil.getPageFromURLParams();
    gridApi.paginationGoToPage(initialPage);
  }, []);

  const updatePageToURL = useCallback((gridApi) => {
    const currentPage = gridApi.paginationGetCurrentPage() ?? 0;
    GridApiUtil.setPageToURLParams(currentPage);
  }, []);

  useYardsListDataCycle(gridApi, {
    onDataServerLoad: applyPageFromURL,
    onDataServerReload: updatePageToURL,
  });
}
