import { Bounds, Coordinate, MapState, Path } from '@components/common/Map/types';

const EARTH_RADIUS = 6371; // km

export const MapUtil = {
  areMapStatesEqual,
  areCoordinatesEquals,
  areBoundsEquals,
  getCoordinateKey,
  getCoordinatesDistance,
  getPathKey,
  getPathCenter,
  getPathBounds,
  getBoundsPath,
  getClosedPath,
  generateCirclePath,
};

function areCoordinatesEquals(
  a: Coordinate | null | undefined,
  b: Coordinate | null | undefined,
  precision = 12
): boolean {
  return getCoordinateKey(a, precision) === getCoordinateKey(b, precision);
}

function areBoundsEquals(a: Bounds | null | undefined, b: Bounds | null | undefined, precision = 12): boolean {
  const areNeEquals = areCoordinatesEquals(
    a ? { lat: a.north, lng: a.east } : null,
    b ? { lat: b.north, lng: b.east } : null,
    precision
  );
  const areSwEquals = areCoordinatesEquals(
    a ? { lat: a.south, lng: a.west } : null,
    b ? { lat: b.south, lng: b.west } : null,
    precision
  );
  return areNeEquals && areSwEquals;
}

function areMapStatesEqual(
  a: MapState | null | undefined,
  b: MapState | null | undefined,
  excludeBounds = false
): boolean {
  const isTypeEqual = a?.mapType === b?.mapType;
  const isCenterEqual = MapUtil.areCoordinatesEquals(a?.center, b?.center);
  const isZoomEqual = a?.zoom === b?.zoom;
  const areBoundsEqual = excludeBounds || MapUtil.areBoundsEquals(a?.bounds, b?.bounds);
  return isTypeEqual && isCenterEqual && isZoomEqual && areBoundsEqual;
}

function getCoordinateKey(coord: Coordinate | null | undefined, precision = 12) {
  return `${coord?.lat.toFixed(precision)}-${coord?.lng.toFixed(precision)}`;
}

function getCoordinatesDistance(coordinateA: Coordinate, coordinateB: Coordinate): number {
  const toRad = (n: number) => (n * Math.PI) / 180.0;

  const dLat = toRad(coordinateB.lat - coordinateA.lat);
  const dLon = toRad(coordinateB.lng - coordinateA.lng);
  const a =
    Math.sin(dLat / 2.0) * Math.sin(dLat / 2.0) +
    Math.cos(toRad(coordinateA.lat)) * Math.cos(toRad(coordinateB.lat)) * Math.sin(dLon / 2.0) * Math.sin(dLon / 2.0);
  const c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a));

  return EARTH_RADIUS * c;
}

function getPathKey(path?: Path | null): string | null {
  return path?.map((c) => getCoordinateKey(c)).join('-') ?? null;
}

function getPathCenter(path: Path): Coordinate {
  if (path.length === 0) {
    throw new Error("Can't find the center for an empty path.");
  }

  const lats = path.map((c) => c.lat);
  const lngs = path.map((c) => c.lng);

  return {
    lat: (Math.min(...lats) + Math.max(...lats)) / 2.0,
    lng: (Math.min(...lngs) + Math.max(...lngs)) / 2.0,
  };
}

function getPathBounds(path: Path): Bounds | null {
  if (path.length) {
    const [firstCoord, ...coords] = path;
    const bounds: Bounds = { east: firstCoord.lng, west: firstCoord.lng, north: firstCoord.lat, south: firstCoord.lat };

    for (const { lat, lng } of coords) {
      bounds.west = Math.min(lng, bounds.west);
      bounds.east = Math.max(lng, bounds.east);
      bounds.south = Math.min(lat, bounds.south);
      bounds.north = Math.max(lat, bounds.north);
    }

    return bounds;
  }
  return null;
}

function getBoundsPath({ east, west, north, south }: Bounds): Array<Coordinate> {
  return [
    { lat: south, lng: west },
    { lat: north, lng: west },
    { lat: north, lng: east },
    { lat: south, lng: east },
  ];
}

function getClosedPath(path: Path) {
  if (path.length && MapUtil.getCoordinateKey(path[0]) !== MapUtil.getCoordinateKey(path[path.length - 1])) {
    return [...path, { ...path[0] }];
  }
  return path;
}

function generateCirclePath(center: Coordinate, radiusInMetters: number) {
  const sides = 16;
  const oneLatDegreeInMetters = 111000;
  const oneLngDegreeInMetters = getOneLngDegreeInMetters(center.lat);
  const latRadiusInDegrees = radiusInMetters / oneLatDegreeInMetters;
  const lngRadiusInDegrees = radiusInMetters / oneLngDegreeInMetters;
  const angleDelta = (2 * Math.PI) / sides;

  const path: Array<Coordinate> = new Array(sides).fill(null).map((_, sideIndex) => {
    const angle = sideIndex * angleDelta;
    const lat = Math.sin(angle) * latRadiusInDegrees + center.lat;
    const lng = Math.cos(angle) * lngRadiusInDegrees + center.lng;
    return { lat, lng };
  });

  return getClosedPath(path);
}

function getOneLngDegreeInMetters(lat: number) {
  return getCoordinatesDistance({ lat, lng: 0 }, { lat, lng: 1 }) * 1000;
}
