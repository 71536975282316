import React from 'react';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { BoxProps, BoxRef } from '@components/common/Box/types';

const StyledCellWrapper = styled(Box)(
  () => `
  overflow: hidden;
`
);

export const DefaultCellWrapper = React.forwardRef<BoxRef<'div'>, BoxProps<'div'>>(function DefaultCellWrapper(
  { children, ...props },
  ref
) {
  return (
    <StyledCellWrapper ref={ref} fit alignItems={'center'} {...props}>
      {children}
    </StyledCellWrapper>
  );
});
