import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Queenright = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76 20.4C9.3911 21.6251 10.624 22.4242 12 22.5C13.376 22.4242 14.6089 21.6251 15.24 20.4H8.76ZM7.818 16.2C7.8075 16.3725 7.8 16.548 7.8 16.725C7.80027 17.2541 7.85455 17.7819 7.962 18.3H16.038C16.1454 17.7819 16.1997 17.2541 16.2 16.725C16.2 16.548 16.1925 16.3725 16.182 16.2H7.818ZM12 7.8C10.2603 7.8 8.85 9.2103 8.85 10.95C8.85 11.0325 8.868 11.1105 8.874 11.1915C7.41582 10.3905 5.78802 9.9478 4.125 9.9C2.67525 9.9 1.5 11.0753 1.5 12.525C1.5 13.9747 2.67525 15.15 4.125 15.15C5.59733 15.1144 7.0439 14.756 8.3625 14.1H15.6375C16.9561 14.756 18.4027 15.1144 19.875 15.15C21.3247 15.15 22.5 13.9747 22.5 12.525C22.5 11.0753 21.3247 9.9 19.875 9.9C18.212 9.9478 16.5842 10.3905 15.126 11.1915C15.126 11.1105 15.15 11.0325 15.15 10.95C15.15 9.2103 13.7397 7.8 12 7.8ZM12 1.5C11.5132 1.50017 11.0903 1.83496 10.9785 2.30878C10.8666 2.78259 11.0951 3.27114 11.5305 3.489L10.95 4.65L8.7945 3.9315C8.94892 3.4695 8.7653 2.96196 8.35103 2.70569C7.93676 2.44943 7.40064 2.51174 7.05619 2.85619C6.71174 3.20064 6.64943 3.73676 6.90569 4.15103C7.16196 4.5653 7.6695 4.74892 8.1315 4.5945L8.61 6.0315C8.75202 6.45834 9.15016 6.7473 9.6 6.75H14.4C14.8521 6.74987 15.2533 6.46043 15.396 6.0315L15.8745 4.5945C15.9793 4.63025 16.0892 4.64899 16.2 4.65C16.6556 4.65013 17.0593 4.35644 17.1994 3.92292C17.3395 3.4894 17.1841 3.01499 16.8146 2.74845C16.4451 2.4819 15.9459 2.48405 15.5787 2.75376C15.2115 3.02348 15.0602 3.4992 15.204 3.9315L13.05 4.65L12.4695 3.4875C12.9031 3.26879 13.1302 2.78125 13.0185 2.3086C12.9069 1.83595 12.4857 1.50155 12 1.5Z"
      />
    </IconSVGView>
  );
};
