import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CachedRequest } from '@helpers/CachedRequest';
import { Thunk } from '@helpers/Thunk';
import { YardsFiltersAction, YardsFiltersActionType, YardsFiltersState } from '@redux/YardsFilters/types';

const FILTERS_AVAILABILITIES_MAX_CACHE_AGE = 5000; // 5 seconds

export const makeFetchFiltersAvailabilitiesStartAction = (): YardsFiltersAction => ({
  type: YardsFiltersActionType.FETCH_FILTERS_AVAILABILITIES_START,
});

export const makeFetchFiltersAvailabilitiesFinishAction = (
  data: YardsFiltersState['availableFilters'],
  error: ApiResponseError | null
): YardsFiltersAction => ({
  type: YardsFiltersActionType.FETCH_FILTERS_AVAILABILITIES_FINISH,
  payload: { data, error },
});

export const makePatchAppliedFiltersAction = (data: YardsFiltersState['appliedFilters']): YardsFiltersAction => ({
  type: YardsFiltersActionType.PATCH_APPLIED_FILTERS,
  payload: { data },
});

export const makeClearAppliedFiltersAction = (): YardsFiltersAction => ({
  type: YardsFiltersActionType.CLEAR_APPLIED_FILTERS,
});

export const makePatchTransientFiltersAction = (
  data: YardsFiltersState['transientAppliedFilters']
): YardsFiltersAction => ({
  type: YardsFiltersActionType.PATCH_TRANSIENT_FILTERS,
  payload: { data },
});

export const makeClearTransientFiltersAction = (): YardsFiltersAction => ({
  type: YardsFiltersActionType.CLEAR_TRANSIENT_FILTERS,
});

export const makeFetchFiltersAvailabilitiesThunk = Thunk.createTakeLast((dispatchStatus?: { canceled: boolean }) => {
  return async (dispatch) => {
    dispatch(makeFetchFiltersAvailabilitiesStartAction());

    const response = await CachedRequest.performRequest(
      ENDPOINTS.filters,
      (url) => Api.get(url),
      FILTERS_AVAILABILITIES_MAX_CACHE_AGE
    );

    let data: YardsFiltersState['availableFilters'] = {};
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
    } else {
      data = parseFilterAvailabilitiesFromAPI(await response.json());
    }

    if (!dispatchStatus?.canceled) {
      dispatch(makeFetchFiltersAvailabilitiesFinishAction(data, error));
    }
  };
});

function parseFilterAvailabilitiesFromAPI(data: any): YardsFiltersState['availableFilters'] {
  return Object.entries(data).reduce(
    (all, [key, values]) => ({
      ...all,
      [key]: { key, values },
    }),
    {}
  ) as YardsFiltersState['availableFilters'];
}
