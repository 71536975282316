import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Issue = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <rect fill={useColor(color)} width="24" height="24" fillOpacity="0.01" />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.707 7.979L16.021 3.293C15.8335 3.10545 15.5792 3.00006 15.314 3H8.686C8.4208 3.00006 8.16649 3.10545 7.979 3.293L3.293 7.979C3.10545 8.16649 3.00006 8.4208 3 8.686V15.314C3.00006 15.5792 3.10545 15.8335 3.293 16.021L7.979 20.707C8.16649 20.8946 8.4208 20.9999 8.686 21H15.314C15.5792 20.9999 15.8335 20.8946 16.021 20.707L20.707 16.021C20.8946 15.8335 20.9999 15.5792 21 15.314V8.686C20.9999 8.4208 20.8946 8.16649 20.707 7.979ZM12 17.25C11.3096 17.25 10.75 16.6904 10.75 16C10.75 15.3096 11.3096 14.75 12 14.75C12.6904 14.75 13.25 15.3096 13.25 16C13.25 16.6904 12.6904 17.25 12 17.25ZM12 13C12.6904 13 13.25 12.4404 13.25 11.75V7.75C13.25 7.05964 12.6904 6.5 12 6.5C11.3096 6.5 10.75 7.05964 10.75 7.75V11.75C10.75 12.4404 11.3096 13 12 13Z"
      />
    </IconSVGView>
  );
};
