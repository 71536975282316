import { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';

import { useQueryParamState } from '@helpers/QueryParams/hooks';

import { ModalContext } from './context';
import { ModalContextValue } from './types';

export function useModalContext(): ModalContextValue {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("Can't find a ModalContext");
  }

  return context;
}

/**
 * Helper to defer the modal open state change until it's
 * completely rendered. It will improve the user experience
 * for pre-opened modals.
 * */
export function useModalDeferredOpenState(isOpen: boolean) {
  const [deferredIsOpen, setDeferredIsOpen] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setDeferredIsOpen(isOpen), 20);
  }, [isOpen]);

  return deferredIsOpen;
}

/**
 * Helper to handle modal open state using the current url
 * query params.
 * */
export function useQueryParamsModalOpener<V>(options: {
  paramName: string;
  paramValueStringifier?: (value: V) => string;
  paramValueParser?: (value: string) => V | null;
}) {
  const { paramName, paramValueStringifier = String, paramValueParser = String } = options;
  const [queryParamValues, setQueryParamValues] = useQueryParamState(paramName);

  const unparsedOpenedModals = useMemo(() => {
    return queryParamValues.length ? queryParamValues.split(',') : [];
  }, [queryParamValues]);

  const openedModals = useMemo(() => {
    return unparsedOpenedModals.map((val) => paramValueParser(val)).filter((val) => val !== null) as Array<V>;
  }, [paramValueParser, unparsedOpenedModals]);

  const openModal = useCallback(
    (value: V) => {
      const stringValue = paramValueStringifier(value);
      if (unparsedOpenedModals[openedModals.length - 1] === stringValue) {
        return;
      }
      setQueryParamValues([...openedModals, stringValue].join(','));
    },
    [openedModals, paramValueStringifier, setQueryParamValues, unparsedOpenedModals]
  );

  const closeModal = useCallback(
    (value: V) => {
      const stringValue = paramValueStringifier(value);
      const lastOccurrenceIndex = unparsedOpenedModals.lastIndexOf(stringValue);
      if (lastOccurrenceIndex !== -1) {
        const nextQueryParamValues = unparsedOpenedModals.slice(0, lastOccurrenceIndex);
        setQueryParamValues(nextQueryParamValues.join(','));
      }
    },
    [paramValueStringifier, setQueryParamValues, unparsedOpenedModals]
  );

  return { openedModals, openModal, closeModal };
}
