import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';

interface ExpandedProps {
  $expanded: boolean;
}

export const StyledNavWrapper = styled.div<ExpandedProps>(
  ({ theme, $expanded }) => `
      width: ${$expanded ? '256px' : '72px'};
      transition: ${theme.animations.transitionMedium('width')};
      height: 100%;
      background-color: ${theme.colors.grey08};
      color: ${theme.colors.coreWhite};
  
      & * {
        transition: ${theme.animations.transitionMedium(
          'width',
          'max-width',
          'height',
          'padding',
          'margin',
          'transform',
          'opacity'
        )};
       
      }
    `
);

export const StyledFixedContent = styled.div<ExpandedProps>(
  ({ theme, $expanded }) => `
      width: ${$expanded ? '256px' : '72px'};
      z-index: ${theme.getZIndexOf('sidenav')};
      transition: ${theme.animations.transitionMedium('width')};
      height: 100%;
      background-color: ${theme.colors.grey08};
      color: ${theme.colors.coreWhite};
      position: fixed;
      top: 0;
      bottom: 0;
    `
);

export const StyledLogoWrap = styled(NavRouterLink).attrs({ unstyled: true })<ExpandedProps>(
  ({ theme, $expanded }) => `
      width: ${$expanded ? '200px' : 0};
      overflow: hidden;
      opacity: ${$expanded ? 1 : 0};
    `
);

export const StyledCollapseButton = styled(Button)<ExpandedProps>(
  ({ theme, $expanded }) => `
      transform: ${$expanded ? 'initial' : 'scaleX(-1)'}; // makes it flip
      padding: 8px;
      margin-right: -8px;

      &:hover {
        background-color: ${theme.colors.coreBlack};
      }
    `
);

export const StyledNavText = styled.span``;

export const StyledSVGWrap = styled.span``;

export const navItemStyles = css<ExpandedProps>(
  ({ theme, $expanded }) => css`
    display: flex;
    border-left: ${$expanded ? '4px solid transparent' : 'initial'};
    padding: ${$expanded ? '8px 12px' : 0};
    align-items: center;
    line-height: 24px;
    font-size: 14px;

    &:hover {
      background-color: ${$expanded ? theme.colors.coreBlack : 'inherit'};
    }

    ${StyledSVGWrap} {
      margin: ${$expanded ? '0' : 'auto'};
      padding: ${$expanded ? '8px 8px 8px 0' : '8px'};
      border-radius: ${theme.spacing.xxs}px;
      display: flex;
    }

    &:hover ${StyledSVGWrap} {
      background-color: ${$expanded ? 'inherit' : theme.colors.coreBlack};
    }

    ${StyledNavText} {
      width: ${$expanded ? '150px' : 0};
      overflow: hidden;
      opacity: ${$expanded ? 1 : 0};
    }

    svg {
      height: ${$expanded ? '16px' : '24px'};
      width: ${$expanded ? '16px' : '24px'};
    }
  `
);

export const StyledNavButton = styled(Button).attrs({ unstyled: true })(
  ({}) => css`
    ${navItemStyles}
    width: 100%;
  `
);

export const StyledNavLink = styled(NavRouterLink).attrs({ unstyled: true })<ExpandedProps>(
  ({ theme, $expanded, isActive }) => css`
    ${navItemStyles}

    // React Router NavLink element automatically adds this 'active' class to the active nav
      // 'isActive' as an attribute or prop should be usable to, but doesn't seem to work
      &.active {
      border-left-color: ${theme.colors.orange01};
      background-color: ${$expanded ? theme.colors.coreBlack : 'inherit'};
    }

    &.active ${StyledSVGWrap} {
      color: ${$expanded ? 'inherit' : theme.colors.orange01};
    }
  `
);

export const StyledNavSeparator = styled.div(
  ({ theme }) => `
      border-top: 1px solid ${theme.colors.grey07};
      padding-bottom: ${theme.spacing.sm}px ;
      margin: ${theme.spacing.sm}px ${theme.spacing.sm}px 0;
    `
);

export const StyledInitialsCircle = styled.p(
  ({ theme }) => `
    border-radius: 100%;
    background-color: ${theme.colors.turquoise};
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    font-weight: 600;
  `
);

export const StyledFooterText = styled.div`
  max-width: 200px;
  max-height: 42px;
  padding-left: ${({ theme }) => theme.spacing.sm}px;
  margin-right: auto;
`;

export const StyledFooterBox = styled(Box)``;

export const StyledLogoutButton = styled(Button).attrs({ unstyled: true })``;

export const StyledFooter = styled.footer<ExpandedProps>(
  ({ theme, $expanded }) => `
      position: absolute;
      bottom: 0;
      width: 100%;
      overflow: hidden;
  
      ${StyledFooterText} {
  
        ${
          !$expanded &&
          `
            max-width: 0;
            opacity: 0;
            max-height: 0;
            padding: 0;
            margin: 0;
        `
        }
      }
  
      ${StyledFooterBox} {
        ${!$expanded && `flex-direction: column-reverse;`}
      }

      ${StyledLogoutButton} {
        padding: ${theme.spacing.xs}px;
        border-radius: ${theme.spacing.xxs}px;

        &:hover {
          background-color: ${theme.colors.coreBlack};
        }

        ${
          $expanded
            ? ` margin-right -${theme.spacing.xs}px;`
            : `
              margin-bottom: ${theme.spacing.sm}px;
          `
        }
      }
  `
);
