import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal from '@components/deprecated/Modal/Modal';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
import { Analytics, GTMEventHandler } from '@helpers/Analytics/index';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { withGetScreen } from '@HOC/withGetScreen';
import {
  makeAddWorkerRequestThunk,
  makeCloseWorkerModalAction,
  makeDeactivateWorkerRequestThunk,
  makeOpenWorkerModalAction,
  makeUpdateWorkerRequestThunk,
} from '@redux/deprecated/actions';
import { isValidPhone } from '@regex/isValidPhone';
// nectar:
import theme from '@style/theme';

import { WorkerModalView } from './WorkerModalView';

const initialState = {
  name: '',
  phone_number: '',
  id: '',
  isDisabledBtn: true,
  errorMsg: '',
  keepAdding: false,
};

class WorkerModal extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      title: '',
      instructions: '',
      buttonText: '',
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.verifyInput = this.verifyInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
    this.handleKeepAdding = this.handleKeepAdding.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { t, contentObj, user } = this.props;
    const { modalType, worker } = contentObj;

    const manager = user ? user.first_name + ' ' + user.last_name : t('your_manager');

    if (contentObj !== prevProps.contentObj) {
      switch (true) {
        case 'add-worker' === modalType:
          return this.setState({
            title: t('add_worker'),
            instructions: (
              <>
                {t('add_worker_instructions')}
                <u data-for="text_message" data-tip>
                  {t('text_message')}
                </u>
                <Tooltip id="text_message" disable={false} tooltipText={t('tooltip_text_message', { manager })} />
                {t('add_worker_instructions_cont')}
              </>
            ),
            buttonText: t('add'),
          });
        case 'update-worker' === modalType:
          return this.setState({
            title: t('edit_worker'),
            instructions: (
              <>
                {t('edit_worker_instructions')}
                <u data-for="text_invitation" data-tip>
                  ({t('text_invitation')})
                </u>
                <Tooltip id="text_invitation" disable={false} tooltipText={t('tooltip_text_message', { manager })} />
                {t('edit_worker_instructions_cont')}
              </>
            ),
            buttonText: t('edit'),
            name: worker.first_name + ' ' + worker.last_name,
            phone_number: worker.phone_number,
            id: worker.id,
          });
        case 'remove-worker' === modalType:
          return this.setState({
            title: t('remove_worker'),
            instructions: (
              <>
                {t('remove_worker_instructions')}
                <span style={{ fontWeight: theme.fontWeightSemiBold }}>
                  {worker.first_name + ' ' + worker.last_name}
                </span>
                {t('remove_worker_instructions_cont')}
              </>
            ),
            buttonText: t('remove'),
            id: worker.id,
          });
        default:
          break;
      }
    }
  }

  handleClose(e) {
    e.preventDefault();
    const { closeDispatch } = this.props;
    closeDispatch();

    this.setState({
      ...initialState,
    });
  }

  setIfButtonIsDisabled() {
    const { name, phone_number } = this.state;

    this.setState({
      isDisabledBtn: true,
    });

    if (name && phone_number) {
      this.setState({
        isDisabledBtn: false,
      });
    }
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleKeepAdding(e) {
    this.setState({
      keepAdding: !this.state.keepAdding,
    });
  }
  /**
   *
   * @returns {boolean}
   */
  verifyInput() {
    const { t } = this.props;

    const { name, phone_number } = this.state;

    this.setState({
      errorMsg: '',
    });

    let error = false;

    if (!name) {
      this.setState({
        errorMsg: t('check_input'),
      });
      error = true;
    }

    if (phone_number && !isValidPhone(phone_number)) {
      this.setState({
        errorMsg: t('check_phone_input'),
      });
      error = true;
    }

    return error;
  }

  handleKeyDown(e) {
    const { contentObj } = this.props;
    const { isDisabledBtn } = this.state;

    const { modalType } = contentObj;

    if (modalType.includes('worker')) {
      if ('Enter' === e.key && !isDisabledBtn) this.handleSubmit(e);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { name, phone_number, id, keepAdding } = this.state;
    const { addWorker, deactivateWorker, updateWorkerDispatch, openWorkerModal, closeDispatch, contentObj } =
      this.props;

    const { modalType } = contentObj;
    const nameParts = name.split(' ');
    let first_name = nameParts[0];
    let last_name = nameParts.slice(1).join(' ');

    if ('remove-worker' === modalType) {
      deactivateWorker(id);

      this.setState({
        ...initialState,
      });
      closeDispatch();
      Analytics.sendEvent({ event: AnalyticsEventType.WORKER_REMOVE });
      return;
    }

    if (!this.verifyInput()) {
      if ('add-worker' === modalType) {
        const didSucceed = await addWorker(first_name, last_name, phone_number);
        if (didSucceed) {
          Analytics.sendEvent({ event: AnalyticsEventType.WORKER_CREATE });

          if (keepAdding) {
            openWorkerModal({ modalType: 'add-worker' });
            this.setState({
              ...initialState,
              keepAdding: true,
            });
          } else {
            closeDispatch();
            this.setState({
              ...initialState,
            });
          }
        }
      }

      if ('update-worker' === modalType) {
        const didSucceed = await updateWorkerDispatch(id, first_name, last_name, phone_number);
        if (didSucceed) {
          Analytics.sendEvent({ event: AnalyticsEventType.WORKER_EDIT });

          this.setState({
            ...initialState,
          });
          closeDispatch();
        }
      }
    }
  }

  render() {
    const { t, isWorkerOpen, isMobile, contentObj } = this.props;

    const { isDisabledBtn, errorMsg, name, phone_number, title, instructions, keepAdding, buttonText } = this.state;

    const { modalType } = contentObj;

    return (
      <Modal isMobile={isMobile()} isOpen={isWorkerOpen} onRequestClose={this.handleClose} modalType={modalType}>
        <WorkerModalView
          t={t}
          isMobile={isMobile()}
          contentObj={{
            name,
            phone_number,
            title,
            instructions,
            buttonText,
            modalType: contentObj.modalType,
          }}
          isDisabledBtn={isDisabledBtn}
          errorMsg={errorMsg}
          keepAdding={keepAdding}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleKeepAdding={this.handleKeepAdding}
        />
      </Modal>
    );
  }
}
/**
 *
 * @param dispatch
 * @returns {{closeDispatch: closeDispatch}}
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeCloseWorkerModalAction());
  },
  addWorker: (firstName, lastName, phone_number) => {
    return dispatch(makeAddWorkerRequestThunk(firstName, lastName, phone_number));
  },
  updateWorkerDispatch: (id, firstName, lastName, phone_number) => {
    return dispatch(makeUpdateWorkerRequestThunk(id, firstName, lastName, phone_number));
  },
  deactivateWorker: (id) => {
    dispatch(makeDeactivateWorkerRequestThunk(id));
  },
  openWorkerModal: (content) => {
    dispatch(makeOpenWorkerModalAction(content));
  },
});

/**
 *
 * @param state
 * @returns {{isWorkerOpen: (*|boolean)}}
 * @returns {{contentObj: (*|object)}}
 * @returns {{user: (*|object)}}
 *
 */
const mapStateToProps = (state) => ({
  isWorkerOpen: state.modalReducer.isWorkerOpen,
  contentObj: state.modalReducer.contentObj,
  user: state.accountReducer.user,
});

WorkerModal.propTypes = {
  isWorkerOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  addWorker: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(WorkerModal)))
);
