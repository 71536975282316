import { isSafari } from 'react-device-detect';
import JsPDF, { ImageOptions } from 'jspdf';

import { DOM } from '@helpers/DOM';

const SUPPORTED_FONT = 'helvetica';
const TEXT_TAGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'SPAN', 'DIV[data-pdf-text]'];

export const PDF = {
  elementToPDF,
};

/**
 * Generates a PDF document from the given elements.
 * Each element turns into a PDF page.
 * */
async function elementToPDF(...elements: Array<HTMLElement>): Promise<JsPDF> {
  const doc = await createPDFDoc();

  for (const element of elements) {
    await createPDFPage(doc, element);
    await createPDFText(doc, element);
  }

  return doc;
}

async function createPDFDoc(): Promise<JsPDF> {
  return new JsPDF({ unit: 'px' }).deletePage(1); // Start PDF empty.
}

async function createPDFPage(doc: JsPDF, element: HTMLElement): Promise<void> {
  const { width, height } = element.getBoundingClientRect();
  doc.addPage([width, height]);
  await prepareText(element);

  // Workaround for a Google Maps rendering issue.
  // Ref: https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
  if (isSafari) {
    await DOM.elementToCanvas(element, 'alt1');
    await DOM.elementToCanvas(element, 'alt1');
    await DOM.elementToCanvas(element, 'alt1');
  }

  const pageCanvas = await DOM.elementToCanvas(element, 'alt1');
  doc.addImage({ imageData: pageCanvas, x: 0, y: 0, width, height } as ImageOptions);
  await restoreText(element);
}

async function createPDFText(doc: JsPDF, element: HTMLElement): Promise<void> {
  const pageY = element.getBoundingClientRect().y;
  const children = await getTextElements(element);

  for (const child of children) {
    const childElement = child as HTMLElement;

    const childStyle = getComputedStyle(child);
    const { x, y, width } = childElement.getBoundingClientRect();
    const text = childElement.innerText;
    const color = childStyle.color;
    const align = ['right', 'end'].includes(childStyle.textAlign) ? 'right' : 'left';
    const fontSizeAdjust = 1.32;
    const fontSize = parseFloat(childStyle.fontSize) * fontSizeAdjust;
    const fontWeight = ['bold', '600'].includes(childStyle.fontWeight) ? 'bold' : 'normal';
    const lineHeight = parseFloat(childStyle.lineHeight) * fontSizeAdjust || fontSize;
    const lineHeightFactor = lineHeight / fontSize;
    const rectY = y - pageY;
    const textX = align === 'left' ? x : x + width;
    const textY = rectY + (lineHeight - fontSize);

    doc.setFontSize(fontSize).setFont(SUPPORTED_FONT, fontWeight).setTextColor(color).text(text, textX, textY, {
      baseline: 'top',
      maxWidth: width,
      lineHeightFactor,
      align,
    });
  }
}

/**
 * Remove all text elements.
 * */
async function prepareText(element: HTMLElement) {
  const children = await getTextElements(element);
  for (const child of children) {
    const childElement = child as HTMLElement;
    childElement.style.fontFamily = SUPPORTED_FONT;
    childElement.style.opacity = '0.0';
  }
}

/**
 * Restore all text elements.
 * */
async function restoreText(element: HTMLElement) {
  const children = await getTextElements(element);
  for (const child of children) {
    const childElement = child as HTMLElement;
    childElement.style.opacity = '1.0';
  }
}

async function getTextElements(element: HTMLElement): Promise<Array<HTMLElement>> {
  return Array.from<HTMLElement>(
    element.querySelectorAll(
      TEXT_TAGS.map(
        (tag) => `${tag.toLowerCase()}:not([data-pdf-unselectable]):not([data-polli-map] ${tag.toLowerCase()})`
      ).join(',')
    )
  );
}

/**
 * Transforms map elements into static images.
 * */
async function transformMapElementsIntoImage(element: HTMLElement) {
  const children = element.querySelectorAll('*');
  for (const child of children) {
    const childElement = child as HTMLElement;
    const childStyle = getComputedStyle(childElement);
    const childRect = childElement.getBoundingClientRect();
    childElement.style.boxShadow = 'none';

    if (childElement.hasAttribute('data-polli-map')) {
      const mapId = childElement.getAttribute('data-polli-map') as string;
      const existingMapCanvas = document.getElementById(mapId) as HTMLCanvasElement;
      const margin = childStyle.margin;
      childElement.style.margin = '0';

      let mapCanvas;
      if (existingMapCanvas) {
        mapCanvas = document.createElement('canvas') as HTMLCanvasElement;
        mapCanvas.width = existingMapCanvas.width;
        mapCanvas.height = existingMapCanvas.height;
        const context = mapCanvas.getContext('2d');
        context?.drawImage(existingMapCanvas, 0, 0, existingMapCanvas.width, existingMapCanvas.height);
      } else {
        mapCanvas = await DOM.elementToCanvas(childElement, 'alt1');
        mapCanvas.id = mapId;
      }

      mapCanvas.style.width = childRect.width + 'px';
      mapCanvas.style.height = childRect.height + 'px';
      mapCanvas.style.margin = margin;

      childElement.replaceWith(mapCanvas);
    }
  }
}
