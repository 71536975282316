import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Group = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6h8a2 2 0 0 1 2 2v6.337A5.5 5.5 0 0 0 12.6 20H4a2 2 0 0 1-2-2l.01-12c0-1.105.885-2 1.99-2h6l2 2Z"
        fill={useColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Zm5.353 2.354 2-2a.5.5 0 0 0 0-.707l-2-2a.5.5 0 1 0-.707.707L18.792 17H15.5a.5.5 0 0 0 0 1h3.293l-1.147 1.147a.5.5 0 0 0 .707.707Z"
        fill={useColor(color)}
      />
    </IconSVGView>
  );
};
