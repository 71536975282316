const routes = {
  login: '/login',
  passwordCreate: '/create-new-password/:uid?/:token?',
  passwordForgotten: '/forgotten-password',

  lifeStyle: '/livestyle-guide',
  appStore: '/app-store',

  home: '/',
  groups: '/groups',

  settings: '/settings',
  operation: '/operation',

  pollination: '/pollination-contracts',
  pollinationContract: '/pollination-contract/:uid',
  pollinationContractPrintable: '/pollination-contract-printable',

  alerts: '/alerts',

  whiteboard: '/whiteboard',
  whiteboardYardsList: '/whiteboard/yards/list',
  whiteboardYardsMap: '/whiteboard/yards/map',
  whiteboardYardsMapManagement: '/whiteboard/yards/map/management',
  whiteboardYardsDetail: '/whiteboard/yards/details/:uid',
  whiteboardYardsDetailDashboard: '/whiteboard/yards/details/:uid/dashboard',
  whiteboardActivities: '/whiteboard/activities',

  settingsUserProfile: '/settings/user-profile',
  settingsNotifications: '/settings/notifications',

  // Keeping these props for now so this PR doesn't change
  // many files. A new PR should be sent to remove these props
  // and use the above instead.
  yardsList: '/whiteboard/yards/list',
  yardsMap: '/whiteboard/yards/map',
  yardsMapManagement: '/whiteboard/yards/map/management',
  yard: '/whiteboard/yards/details/:uid',
};

const mainRedirects = [
  { from: [routes.home, routes.whiteboard], to: routes.whiteboardYardsList },
  { from: [routes.whiteboardYardsDetail], to: routes.whiteboardYardsDetailDashboard },
];

// These redirects will make sure that users who have saved
// these routes to favorites will have access to the newest.
const backwardCompatibilityRedirects = [
  { from: ['/yards', '/yards/list'], to: routes.whiteboardYardsList },
  { from: ['/yards/:uid'], to: routes.whiteboardYardsDetail },
  { from: ['/yards/map'], to: routes.whiteboardYardsMap },
  { from: ['/yards/map/management'], to: routes.whiteboardYardsMapManagement },
];

const APP = {
  // Screen routing
  routes,
  mainRedirects,
  backwardCompatibilityRedirects,

  // Home navigation
  nav: [
    {
      label: 'yards',
      route: routes.whiteboard,
    },
    {
      label: 'pollination',
      route: routes.pollination,
    },
    {
      label: 'groups',
      route: routes.groups,
    },
  ],
  // tabs labels
  yardTabs: [{ label: 'summary' }, { label: 'hives' }, { label: 'chart' }],
  tabs: [{ label: 'hives' }, { label: 'yards' }],
  featureFlag: {
    grouping: true,
  },
  // the corresponding translation dictionary must exists
  // @see /src/translations
  availableLanguages: ['en', 'fr', 'es'],
  fallbackLng: 'en',
  // mobile screen size limit
  mobileLimit: 600,
  // tablet screen size limit
  tabletLimit: 960,
  // desktop screen size limit
  desktopLimit: 1440,
  // react flex system configuration
  mapGridConfiguration: {
    breakpoints: [600, 768, 960, 1440, 1600, 1920],
    containerWidths: [600, 768, 960, 1440, 1600, 1920],
    maxScreenClass: 'xxl',
  },
  listGridConfiguration: {
    breakpoints: [600, 768, 960, 1200, 1440, 1920],
    containerWidths: [600, 768, 960, 1200, 1440, 1920],
    maxScreenClass: 'xxl',
  },
  settingsGridConfiguration: {
    breakpoints: [600, 768, 960, 1112, 1600, 1920],
    containerWidths: [600, 768, 960, 1112, 1600, 1920],
    maxScreenClass: 'xxl',
  },
  yardGridConfiguration: {
    breakpoints: [600, 768, 960, 1024, 1440, 1600],
    containerWidths: [600, 768, 960, 1024, 1440, 1600],
    maxScreenClass: 'xxl',
  },
  mobileGutterWidth: 16, //8px on each side of a column
  desktopGutterWidth: 24, //12px on each side of a column
  largeScreenGutterWidth: 48, //24px on each side of a column
  noGroupsBannerWidth: 511,
  // will keep query result for:
  defaultCacheForSeconds: 60,
  // will keep events query result for:
  longerCacheForSeconds: 3600,
  // how long for the snackbar message to display (millisecs) ?
  snackbarDuration: 5000,
  offsetLimit: 20,
  // minimum character count for password set
  passwordMinimum: 12,
  // eslint-disable-next-line no-undef
  nginxHost: process.env.NGINX_HOST,
  rootUrl: window.location.origin,
  supportEmail: 'support@nectar.buzz',
  permissions: {
    hht: 'HHT',
    sensor: 'SENSOR',
  },
  // For south-hemisphere yards, can we just reverse when we apply the summer temp range and the winter temp range
  MIN_TEMP_SUMMER_RANGE: 17,
  MAX_TEMP_SUMMER_RANGE: 45,
  MIN_TEMP_WINTER_RANGE: 10,
  MAX_TEMP_WINTER_RANGE: 35,
  // The humidity "ok" range is the same during winter and summer
  MIN_HUMIDITY_RANGE: 40,
  MAX_HUMIDITY_RANGE: 70,
  SEASON: 'summer',
  TIME_RANGES: [
    {
      value: 'week',
      text: '7 days',
    },
    {
      value: 'month',
      text: '30 days',
    },
    {
      value: 'months',
      text: '90 days',
    },
  ],
  BEECON_EVENT_TYPES: ['no_transmission', 'low_battery', 'low_data_rate'],
  BEEHUB_EVENT_TYPES: ['no_transmission', 'not_charging', 'new_beehub'],
  HIVE_STATUS_EVENT_TYPES: ['deadout', 'queenless'],
  BEETRACK_EVENT_TYPES: [
    'new_yard_created',
    'hive_added_to_yard',
    'new_hive_report',
    'new_yard_report',
    'yard_deleted',
    'yard_edited_by_manager',
    'hive_moved_to_yard',
    // these have been proposed to be developed in the future
    'reminder_deadouts',
    'reminder_queenless',
    'new_practice_created',
  ],
  YARD_TYPES: ['pollination', 'honey', 'holding', 'indoor wintering', 'outdoor wintering', 'mating'],
  ACTIVITY_MENU: ['all_activity', 'hive_movements', 'hive_inspections', 'yard_inspections', 'yard_management'],
  STATUS: {
    deadout: 'Deadout',
    queenless: 'Queenless',
    queenright: 'Queenright',
  },
  GRADING: {
    weak: 'hive_grading_weak',
    medium: 'hive_grading_medium',
    strong: 'hive_grading_strong',
  },
  limit: [20, 40, 60],
  defaultLimit: 20,
  YARD_FILTERS_KEYS: {
    COMMON: 'common',
    YARD_TYPE: 'yardType',
    GROUP: 'group',
    CROP_TYPE: 'cropType',
    LAST_INSPECTION_INTERVAL: 'lastInspectionInterval',
  },
  GOOGLE_PLAY_STORE_LINK:
    'https://play.google.com/store/apps/details?id=buzz.nectar.beetrack&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  APPLE_APP_STORE_LINK: 'https://apps.apple.com/ca/app/nectar/id1589464602',
  FETCH_YARDS_SUMMARY_INTERVAL: 300000, // 5 minutes
  THUNK_DEBOUNCE_INTERVAL: 250,
  SEASON_START_DATE_FORMAT: 'YYYY-MM-DD',
};

export default APP;
