import React, { useCallback } from 'react';
import { Hidden, ScreenClassRender } from 'react-grid-system';
import moment from 'moment';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import { HiveStatusChip } from '@components/common/HiveStatusChip';
import { StyledHeading1 } from '@components/deprecated/Elements/TypographyElements';
import { EmptyBeeTrackView } from '@components/deprecated/EmptyView/EmptyBeeTrackView';
import { Table } from '@components/deprecated/Table_deprecated/Table';
import TableHeader from '@components/deprecated/Table_deprecated/TableHeader';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
import { useHiveModalOpener } from '@components/hive/HiveModal/hooks';
import APP from '@config/constants';
import { getActionName } from '@helpers/deprecated/actions';
// nectar
import { isEmptyArray } from '@helpers/deprecated/array';
import { capitalizeFirst } from '@helpers/deprecated/capitalizeFirst';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { fromScreenClassAndDown } from '@helpers/deprecated/screenClass';
import { getDate, getMoment } from '@helpers/deprecated/time';
import theme from '@style/theme';

const StyledTable = styled(Table)`
  table-layout: fixed;
  width: 100%;

  tr {
    td:nth-child(1) {
      font-weight: bold;
    }

    td:last-child,
    th:last-child {
      padding-right: 1rem;
    }
  }

  th {
    &:hover {
      opacity: 0.6;
    }
  }
`;

const StyledTr = styled.tr`
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
  }
`;

const Top = styled.p`
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Bottom = styled.p`
  text-transform: capitalize;
  color: ${(props) => props.theme.deadoutColor};
`;

const Grading = styled.span`
  background-color: ${(props) => props.background};
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  text-align: center;
  line-height: 1.5rem;
  letter-spacing: 1px;
`;

const AlignSpan = styled.span`
  display: flex;
  align-items: center;
`;

const HiveStatusWrapper = styled.div`
  display: flex;
`;

export const BeeTrackHivesListView = ({
  t,
  isMobile,
  isTablet,
  handleSort,
  sort,
  hiveList = [],
  actionList,
  yard,
  status,
}) => {
  const isAlive = status === 'alive';
  const isMobileOrTablet = isMobile || isTablet;

  const { openHiveModal } = useHiveModalOpener();

  const renderEmptyMessage = useCallback(() => {
    const props = {
      heading: isAlive ? t('no_hives') : t('no_hives_deadout'),
      text: isAlive ? t('no_hives_msg', { yard }) : t('no_hives_msg_deadout', { yard }),
    };
    return <EmptyBeeTrackView isMobile={isMobile} {...props} />;
  }, [t, yard, isAlive, isMobile]);

  const renderResponsive = useCallback((children, ...hideIn) => {
    const _hideIn = hideIn.reduce((a, h) => ({ ...a, [h]: true }), {});
    return (
      <Hidden key={children.key} {..._hideIn}>
        {children}
      </Hidden>
    );
  }, []);

  const renderGrading = useCallback(
    (hive) => {
      let background;
      switch (hive.latest_grading_action_flag) {
        case APP.GRADING.weak:
          background = theme.weakGradingColor;
          break;
        case APP.GRADING.medium:
          background = theme.mediumGradingColor;
          break;
        case APP.GRADING.strong:
          background = theme.strongGradingColor;
          break;
        default:
          break;
      }

      return <Grading background={background}>{t(hive.latest_grading_action_flag)}</Grading>;
    },
    [t]
  );

  const renderNullableData = useCallback((key, data, render) => {
    return data ? (
      render(data)
    ) : (
      <AlignSpan key={key}>
        <Top> — </Top>
      </AlignSpan>
    );
  }, []);

  const renderTH = useCallback(
    (key, title, width = 'auto') => {
      return <TableHeader id={key} key={key} data={title} sort={sort} handleSort={handleSort} width={width} />;
    },
    [sort, handleSort]
  );

  const renderTHs = useCallback(
    () => [
      renderTH('hive_name', t('hive')),
      renderTH('status', t('status')),
      renderTH('latest_feeding_datetime', t('last_feed')),
      renderTH('latest_treatment_datetime', t('last_treatment')),
      renderTH('grading', t('grading')),
      renderTH('current_yard_arrived_at', t('arrived')),
      renderTH('death_date', t('died')),
      renderTH('previous_yard_name', t('previous_yard')),
    ],
    [renderTH, t]
  );

  const renderTD = useCallback((key, children) => {
    return <td key={key}>{children}</td>;
  }, []);

  const renderTDs = useCallback(
    (hive) => {
      const {
        hive_identity_id,
        hive_name,
        born_date,
        death_date,
        latest_feeding_datetime,
        latest_feeding_action,
        latest_treatment_datetime,
        latest_treatment_action,
        current_yard_arrived_at,
        previous_yard_name,
        latest_status_action_name,
        latest_status_datetime,
        latest_grading_datetime,
      } = hive;
      const statusTooltipId = `tooltip-status-${hive_identity_id}`;
      const statusTooltipText = `${capitalizeFirst(t('since'))} ${getDate(latest_status_datetime || born_date)}`;

      const gradingTooltipId = `tooltip-grading-${hive_identity_id}`;
      const gradingTooltipText = `${capitalizeFirst(t('since'))} ${getDate(latest_grading_datetime)}`;

      return [
        renderTD(
          'hive_name',
          <AlignSpan>
            <Top>{hive_name}</Top>
          </AlignSpan>
        ),
        renderTD(
          'status',
          <HiveStatusWrapper>
            <div data-for={statusTooltipId} data-tip>
              <HiveStatusChip t={t} status={latest_status_action_name.toLowerCase()} />
              {Boolean(latest_status_datetime || born_date) && (
                <Tooltip id={statusTooltipId} place="right" tooltipText={statusTooltipText} />
              )}
            </div>
          </HiveStatusWrapper>
        ),
        renderTD(
          'feeding',
          renderNullableData('feeding', latest_feeding_datetime, () => (
            <>
              <Top>{getMoment(latest_feeding_datetime)}</Top>
              <Bottom>{getActionName(latest_feeding_action, actionList)}</Bottom>
            </>
          ))
        ),
        renderTD(
          'treatment',
          renderNullableData('treatment', latest_treatment_datetime, () => (
            <>
              <Top>{getMoment(latest_treatment_datetime)}</Top>
              <Bottom>{getActionName(latest_treatment_action, actionList)}</Bottom>
            </>
          ))
        ),
        renderTD(
          'grading',
          <>
            <span data-for={gradingTooltipId} data-tip>
              {renderGrading(hive)}
            </span>
            {renderNullableData('grading', latest_grading_datetime, () => (
              <Tooltip
                id={gradingTooltipId}
                place="right"
                disable={false}
                tooltipText={gradingTooltipText}
                offset={{ top: 24 }}
              />
            ))}
          </>
        ),
        renderTD(
          'yard_arrived',
          renderNullableData('yard_arrived', current_yard_arrived_at, () => (
            <Top>{capitalizeFirst(moment(current_yard_arrived_at).local().fromNow())}</Top>
          ))
        ),
        renderTD(
          'yard_dead',
          renderNullableData('yard_dead', death_date, () => <Top>{moment(death_date).local().fromNow()}</Top>)
        ),
        renderTD(
          'previous_yard_name',
          renderNullableData('previous_yard_name', previous_yard_name, () => <Top>{previous_yard_name}</Top>)
        ),
      ];
    },
    [renderTD, renderNullableData, actionList, renderGrading, t]
  );

  const renderOrderedColumns = useCallback(
    (items) => {
      const [
        th_hive_name,
        th_status,
        th_last_feed,
        th_last_treatment,
        th_grading,
        th_arrived,
        th_died,
        th_previous_yard,
      ] = items;

      return isAlive
        ? [
            renderResponsive(th_hive_name),
            renderResponsive(th_status),
            renderResponsive(th_last_feed, 'xs'),
            renderResponsive(th_last_treatment, 'xs'),
            renderResponsive(th_grading, ...fromScreenClassAndDown('xl')),
            renderResponsive(th_arrived, ...fromScreenClassAndDown('lg')),
            renderResponsive(th_previous_yard, ...fromScreenClassAndDown('md')),
          ]
        : [
            renderResponsive(th_hive_name),
            renderResponsive(th_status),
            renderResponsive(th_arrived, ...fromScreenClassAndDown('lg')),
            renderResponsive(th_died, ...fromScreenClassAndDown('lg')),
            renderResponsive(th_last_feed, ...fromScreenClassAndDown('xs')),
            renderResponsive(th_last_treatment, ...fromScreenClassAndDown('xs')),
            renderResponsive(th_previous_yard, ...fromScreenClassAndDown('md')),
          ];
    },
    [isAlive, renderResponsive]
  );

  return isEmptyArray(hiveList) ? (
    renderEmptyMessage()
  ) : (
    <div style={{ padding: isMobile ? 0 : '3rem' }}>
      <StyledHeading1
        mobile={isMobileOrTablet}
        style={{ padding: isMobile ? '1.5rem 0 2rem 1.5rem' : '0 0 2rem 0', paddingBottom: '38px' }}
      >
        {maybePluralize(hiveList.length, 'hive', t)}
      </StyledHeading1>

      <ScreenClassRender
        render={(screenClass) => (
          <StyledTable>
            <thead>
              <tr>{renderOrderedColumns(renderTHs())}</tr>
            </thead>

            <tbody>
              {hiveList.map((hive) => (
                <StyledTr key={hive.hive_identity_id} onClick={() => openHiveModal(hive.hive_identity_id)}>
                  {renderOrderedColumns(renderTDs(hive))}
                </StyledTr>
              ))}
            </tbody>
          </StyledTable>
        )}
      />
    </div>
  );
};

BeeTrackHivesListView.propTypes = {
  t: PropTypes.func.isRequired,
  yard: PropTypes.string.isRequired,
  hiveList: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  actionList: PropTypes.array.isRequired,
  status: PropTypes.oneOf(['alive', 'deadout']),
};
