import { Reducer } from 'redux';

import { FetchState } from '@redux/types';

export type CropTypeReducer = Reducer<CropTypeState, CropTypeAction>;

export type CropTypeAction =
  | { type: CropTypeActionType.FETCH_START | CropTypeActionType.FETCH_FAILURE }
  | { type: CropTypeActionType.FETCH_SUCCESS; payload: Array<BeeCropType> };
export type CropTypeState = FetchState<{
  cropTypes: Array<BeeCropType>;
}>;

export enum CropTypeActionType {
  FETCH_START = 'CROP_TYPE_FETCH_START',
  FETCH_SUCCESS = 'CROP_TYPE_OPERATION_FETCH_SUCCESS',
  FETCH_FAILURE = 'CROP_TYPE_OPERATION_FETCH_FAILURE',
}
