import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MoreIcon from '@assets/xSmall_More.svg';
import { Menu } from '@components/common/Menu';
import { MenuItem } from '@components/common/Menu/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { ActionMore, ActionMoreIcon, ActionPillView, ActionTitle } from '@components/operation/ActionPill/styles';
import { ActionPillProps } from '@components/operation/ActionPill/types';
import { useAction } from '@hooks/useActions';

export const ActionPill: React.FC<ActionPillProps> = ({
  actionId,
  onEditClick,
  onEnableClick,
  onDisableClick,
  ...props
}) => {
  const { t } = useTranslation();
  const action = useAction(actionId);

  const menuItems = useMemo<Array<MenuItem>>(
    () => [
      { title: t('edit'), onClick: () => action && onEditClick && onEditClick(action) },
      action?.isActive
        ? {
            title: t('disable'),
            onClick: () => action && onDisableClick && onDisableClick(action),
          }
        : {
            title: t('enable'),
            onClick: () => action && onEnableClick && onEnableClick(action),
          },
    ],
    [action, onDisableClick, onEditClick, onEnableClick, t]
  );

  if (!action) {
    return null;
  }

  return (
    <ActionPillView isDefault={action.isDefault} isActive={action.isActive} alignItems={'center'} {...props}>
      <ActionTitle typography={'CaptionSmall'} weight={'700'}>
        {action.detail}
      </ActionTitle>

      {action.isDefault ? (
        <Tooltip>
          <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t('tooltip_default_action') }} />
        </Tooltip>
      ) : (
        <ActionMore>
          <ActionMoreIcon src={MoreIcon} alt={'Options'} />
          <Menu items={menuItems} placement={'bottom-end'} />
        </ActionMore>
      )}
    </ActionPillView>
  );
};
