import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';

import APP from '@config/constants';
import { withGetScreen } from '@HOC/withGetScreen';

import AppStoreView from './AppStoreView';

const AppStore = ({ isMobile }) => {
  if (isIOS) {
    window.location.assign(APP.APPLE_APP_STORE_LINK);
  } else if (isAndroid) {
    window.location.assign(APP.GOOGLE_PLAY_STORE_LINK);
  }

  return <AppStoreView isMobile={isMobile()} />;
};

AppStore.propTypes = {
  isMobile: PropTypes.func.isRequired,
};

export default withGetScreen()(AppStore);
