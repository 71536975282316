import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';

export const StyledForm = styled.form(
  () => `
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`
);

export const StyledFormHead = styled.div(
  ({ theme }) => `
  margin: ${theme.spacing.sm}px;
`
);

export const StyledFormContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  overflow: visible auto;
  padding: ${theme.spacing.md}px ${theme.spacing.sm}px ${theme.spacing.sm}px ${theme.spacing.sm}px;
  border-top: 1px solid ${theme.colors.grey03};
`
);

export const StyledFormFooter = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm}px;
  background: ${theme.colors.coreWhite};
`
);

export const StyledPoiTypeButtons = styled(Box)(
  ({ theme }) => `
  flex-wrap: wrap;
  margin: -${theme.spacing.xxs}px;
`
);

export const StyledPoiTypeButton = styled(PrimaryButton)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: calc(33% - ${theme.spacing.xs}px);
  height: 64px;
  padding: 0;
  margin: ${theme.spacing.xxs}px;
  border: 1px solid ${theme.colors.grey03};
  border-radius: ${theme.shape.paperRadius01}px;
  text-transform: none;
  letter-spacing: normal;
  
  svg {
    margin-bottom: ${theme.spacing.xxs}px;
  }
`
);
