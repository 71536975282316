import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { groupColorIndexToRealColor } from '@helpers/deprecated/color';
import { makeFetchGroupsOnceRequestThunk } from '@redux/deprecated/actions/groupsRequestActionCreator';

export function useGroupColorMap(): Record<number, string> {
  const groups = useGroups();
  return useMemo(() => {
    const colorMap = {} as Record<number, string>;

    groups.forEach((group) => {
      colorMap[group.id] = groupColorIndexToRealColor(group);
    });

    return colorMap;
  }, [groups]);
}
// todo - replace all yard things with camel case
export function useGroupsByYard(yard: Yard | null): Array<BeeGroup> {
  const groups = useGroups();
  return useMemo(() => {
    const yardGroups = yard?.group_ids || [];
    return groups?.filter((group) => yardGroups.includes(group.id)) ?? [];
  }, [groups, yard]);
}

export function useGroupsByYardsListYard(yard: BeeYardsListYard | null): Array<BeeGroup> {
  const groups = useGroups();
  return useMemo(() => {
    const yardGroups = yard?.groupIds || [];
    return groups?.filter((group) => yardGroups.includes(group.id)) ?? [];
  }, [groups, yard]);
}

export function useGroups(): Array<BeeGroup> {
  return useSelector((state) => state.groupsReducer.groups || []);
}

export function useGroupsFetcher(): { isFetching: boolean } {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeFetchGroupsOnceRequestThunk());
  }, [dispatch]);

  const isFetching = useSelector((state) => state.groupsReducer.isFetching);
  return { isFetching };
}
