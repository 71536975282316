import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Holding = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.8968 3.00329 3.00329 3.8968 3 5V19C3.00329 20.1032 3.8968 20.9967 5 21H19C20.1032 20.9967 20.9967 20.1032 21 19V5C20.9967 3.8968 20.1032 3.00329 19 3H5ZM12 7C13.0604 6.93347 14.1135 7.21431 15 7.8C15.6618 8.37762 16.0289 9.22207 16 10.1C16.0622 10.9632 15.7315 11.8083 15.1 12.4C14.1916 13.1135 13.0531 13.4693 11.9 13.4C11.7348 13.4 11.5394 13.3697 11.3637 13.3424C11.2202 13.3202 11.0899 13.3 11 13.3V16C11.0162 16.2699 10.9161 16.5338 10.7249 16.7249C10.5338 16.9161 10.2699 17.0162 10 17C9.73014 17.0162 9.46625 16.9161 9.27508 16.7249C9.08391 16.5338 8.98378 16.2699 9 16V7.2C9.99358 7.05829 10.9964 6.99144 12 7ZM11.3167 8.7666C11.525 8.73752 11.7937 8.7 12.1 8.7C13.2 8.7 13.8 9.2 13.8 10.1C13.8 11.1 13.1 11.7 11.9 11.7C11.7348 11.7 11.5394 11.6697 11.3637 11.6424C11.2202 11.6202 11.0899 11.6 11 11.6V8.8C11.0775 8.8 11.185 8.78499 11.3167 8.7666Z"
      />
    </IconSVGView>
  );
};
