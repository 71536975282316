import React from 'react';
// vendor:
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import APP from '@config/constants';

import NoMatch from '../404';

import CreateNewPassword from './CreateNewPassword/CreateNewPassword';
import ForgottenPassword from './ForgottenPassword/ForgottenPassword';
import LiveStyleGuide from './LiveStyleGuide/LiveStyleGuide';
// nectar:
import Login from './Login/Login';

class Index extends React.Component {
  render() {
    const { location, loggedIn, isAuthenticated, user, permissions } = this.props;

    // redirect to dashboard if the user is not logged out (have user) AND
    // is already authenticated from the time before OR just logged in
    let alreadyAuthenticatedOrJustLoggedIn = isAuthenticated || loggedIn;

    if (user && permissions && alreadyAuthenticatedOrJustLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: APP.routes.home,
            state: { from: location },
          }}
        />
      );
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/create-new-password/:uid?/:token?" render={(props) => <CreateNewPassword {...props} />} />
          <Route path="/forgotten-password" render={(props) => <ForgottenPassword {...props} />} />
          <Route path="/livestyle-guide" render={(props) => <LiveStyleGuide {...props} />} />
          {/* A Route with no path always matches. */}
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    );
  }
}

/**
 * transforms the current Redux store state into the props for
 * the wrapped presentational component
 *
 * @param state
 * @param ownProps
 * @returns {{}}
 */
const mapStateToProps = (state) => {
  // note: we change prop name in order no to copy-cat the redux store state
  return {
    isFetching: state.loginReducer.isFetching,
    loggedIn: state.loginReducer.loggedIn,
    isAuthenticated: state.authReducer.isAuthenticated,
    user: state.accountReducer.user,
    permissions: state.accountReducer.permissions,
  };
};

Index.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(Index);
