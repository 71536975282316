import styled, { css } from 'styled-components';

import { PRESETS } from '@components/common/Text/styles';

export const StyledYardsListStyleSetter = styled.div<{ $enableInteraction?: boolean }>(
  ({ theme, $enableInteraction }) => css`
    .ag-theme-alpine,
    .ag-theme-alpine-dark {
      --ag-border-color: ${theme.colors.grey04};
      --ag-row-border-color: ${theme.colors.grey04};
      --ag-alpine-active-color: transparent;
      --ag-icon-font-code-checkbox-checked: ${theme.colors.grey08};
      --ag-checkbox-unchecked-color: ${theme.colors.grey05};
      --ag-selected-row-background-color: ${theme.colors.grey04};
      --ag-row-hover-color: ${theme.colors.grey02};
      --ag-cell-horizontal-padding: 0;

      --tableHeight: 800px;

      transition: ${theme.animations.transitionFast('height')};
      height: var(--tableHeight);
      width: 100%;
      position: relative;

      pointer-events: ${$enableInteraction ? 'auto' : 'none'};
    }

    .ag-root-wrapper {
      border-radius: 4px;
    }

    .ag-row {
      background-color: transparent;
      cursor: pointer;
    }

    .ag-cell {
      display: flex;
      align-items: center;
      outline: none !important;
      padding: 0 ${theme.spacing.sm}px;

      // Makes the grid font weight looks consistent.
      -webkit-font-smoothing: antialiased;
    }

    .ag-cell:not([col-id*='select']),
    .ag-cell-last-left-pinned:not([col-id*='select']) {
      border-right: none !important;
    }

    .ag-cell[col-id*='menu'] {
      padding: 0 ${theme.spacing.xs}px;
    }

    .ag-cell-value[col-id^='select'],
    .ag-header-cell[col-id^='select'],
    .ag-header-group-cell[col-id^='0_'],
    .ag-header-group-cell[col-id^='select'] {
      border-right: var(--ag-border-color) 1px solid !important;
    }

    .ag-header-group-cell-label {
      padding: 0 ${theme.spacing.sm}px;
    }

    .ag-header {
      background-color: ${theme.colors.coreWhite};
    }

    .ag-pinned-left-header .ag-header-cell-comp-wrapper {
      overflow: visible;
    }

    .ag-header,
    .ag-pinned-left-header,
    .ag-header-row,
    .ag-header-cell {
      overflow: visible;
    }

    .ag-header-group-text {
      font-weight: 600;
      ${css({
        fontSize: PRESETS.Heading3.fontSize,
      })}
    }

    .ag-ltr .ag-header-cell-resize {
      background-color: transparent;
      inset: 0 -1px 0 calc(100% - 1px);
      width: auto;
      transition: ${theme.animations.transitionFast('background-color')};

      &::before,
      &::after {
        content: '';
        position: absolute;
        inset: 0 0 0 50%;
        width: 0;
        height: 0;
        border: transparent 12px solid;
        background: transparent;
        transform: translateX(-50%) scaleX(0.55);
        transition: ${theme.animations.transitionFast('border-color')};
      }
      &::after {
        inset: calc(100% - 24px) 0 0 50%;
        transform: translateX(-50%) rotate(180deg) scaleX(0.55);
      }

      &:hover {
        background-color: ${theme.colors.grey04};
        &::before,
        &::after {
          border-top-color: ${theme.colors.grey04};
        }
      }
      &:active,
      &:focus {
        background-color: ${theme.colors.coreFocus02};
        &::before,
        &::after {
          border-top-color: ${theme.colors.coreFocus02};
        }
      }
    }

    .ag-pinned-left-cols-container,
    .ag-pinned-left-header,
    .ag-header {
      border: none;

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: ${theme.getZIndexOf('tableShadow')};
        transition: ${theme.animations.transitionMedium('all')};
        pointer-events: none;
      }
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 1;
      }
      &.ag-shadow-active {
        &::before {
          opacity: 1;
        }
        &::after {
          opacity: 0;
        }
      }
    }

    .ag-pinned-left-cols-container,
    .ag-pinned-left-header {
      &::before {
        inset: 0 -12px 0 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(217, 217, 217, 0) 100%);
      }
      &::after {
        inset: 0 -12px 0 100%;
        border-left: ${theme.colors.grey04} 1px solid;
      }
    }
    .ag-header {
      &::before {
        inset: 100% 0 -12px 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(217, 217, 217, 0) 100%);
      }
      &::after {
        inset: 100% 0 -12px 0;
        border-top: ${theme.colors.grey04} 1px solid;
      }
    }

    input.ag-input-field-input.ag-checkbox-input {
      appearance: none;
      cursor: pointer;
      position: relative;
      margin: 0;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 3px;
      border: 2px solid ${theme.colors.grey05};
      background-color: white;

      -webkit-appearance: none;
      opacity: 1;
    }

    /* transition: ${theme.animations.transitionFast('border')}; */

    input.ag-input-field-input.ag-checkbox-input::after {
      content: '';
      position: absolute;
      width: 11px;
      height: 6px;
      border-bottom: 0 solid white;
      border-left: 0 solid white;
      transform: translate(0px, -1px) rotate(-45deg);
      pointer-events: none;
      top: 5px;
      left: 4px;
    }

    .ag-checked input.ag-input-field-input.ag-checkbox-input {
      border: none;
      background-color: ${theme.colors.grey08};
    }

    .ag-checked input.ag-input-field-input.ag-checkbox-input::after {
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      content: '';
    }

    .ag-checkbox-input-wrapper::after {
      content: initial;
    }
  `
);
