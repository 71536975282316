import produce from 'immer';

import { ContractActionType, ContractReducer, ContractState } from './types';

export const DEF_DROPS_LIST_OPTIONS = Object.freeze({
  orderBy: 'last_visit',
  orderDirection: 'DESC',
  limit: 60,
  offset: 0,
  count: 0,
});

const defaultState: ContractState = {
  contract: null,
  contractError: null,
  contractDropsMap: null,
  contractBlocksMap: null,
  contractPoisMap: null,
  contractDropsList: null,
  contractDropsListOptions: DEF_DROPS_LIST_OPTIONS,
  contracts: null,
  contractSummaries: null,
  contractsStats: null,
  totalContracts: null,

  isFetching: false,
  isFetchingContracts: false,
  isFetchingContract: false,
  isFetchingContractDrops: false,
  isFetchingContractBlocks: false,
  isFetchingContractPois: false,
  hasFetchError: false,
  hasFetchContractDropsError: false,
  hasFetchContractBlocksError: false,
  hasFetchContractPoisError: false,

  isContractModalOpen: false,
  contractToEdit: null,
};

export const contractReducer: ContractReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      /****************
       * CONTRACT MODAL
       * */

      case ContractActionType.OPEN_CONTRACT_MODAL:
        state.contractToEdit = action.payload;
        state.isContractModalOpen = true;
        state.contractError = null;
        break;

      case ContractActionType.CLOSE_CONTRACT_MODAL:
        state.isContractModalOpen = false;
        state.contractToEdit = null;
        break;

      /**************
       * CONTRACT
       * */

      case ContractActionType.CREATE_START:
      case ContractActionType.PATCH_CONTRACT_START:
        state.isFetchingContract = true;
        break;

      case ContractActionType.CREATE_SUCCESS:
      case ContractActionType.PATCH_CONTRACT_SUCCESS:
        state.isFetchingContract = false;
        state.hasFetchError = false;
        state.contract = action.payload;
        state.contractError = null;
        break;

      case ContractActionType.CREATE_FAILURE:
      case ContractActionType.PATCH_CONTRACT_FAILURE:
        state.isFetchingContract = false;
        state.hasFetchError = true;
        state.contractError = action.payload;
        break;

      case ContractActionType.FETCH_CONTRACTS_START:
        state.isFetchingContracts = true;
        break;

      case ContractActionType.FETCH_CONTRACT_START:
        state.isFetchingContract = true;
        break;

      case ContractActionType.FETCH_CONTRACTS_SUCCESS:
        state.isFetchingContracts = false;
        state.hasFetchError = false;
        state.contracts = action.payload.contracts;
        state.contractsStats = action.payload.contractsStats;
        state.totalContracts = action.payload.totalContracts;
        break;

      case ContractActionType.FETCH_CONTRACT_SUCCESS:
        state.isFetchingContract = false;
        state.hasFetchError = false;
        state.contract = action.payload;
        break;

      case ContractActionType.FETCH_CONTRACTS_FAILURE:
        state.isFetchingContracts = false;
        state.hasFetchError = true;
        break;

      case ContractActionType.FETCH_CONTRACT_FAILURE:
        state.isFetchingContract = false;
        state.hasFetchError = true;
        // add error?
        break;

      /**************
       * CONTRACT SUMMARIES
       * */

      case ContractActionType.FETCH_CONTRACT_SUMMARY_START:
        state.isFetching = false;
        state.hasFetchError = false;
        break;

      case ContractActionType.FETCH_CONTRACT_SUMMARY_SUCCESS:
        state.isFetching = false;
        state.hasFetchError = false;
        state.contractSummaries = action.payload;
        break;

      case ContractActionType.FETCH_CONTRACT_SUMMARY_FAILURE:
        state.isFetching = false;
        state.hasFetchError = true;
        break;

      /**************
       * DROPS LIST
       * */

      case ContractActionType.FETCH_DROPS_LIST_START:
        state.isFetchingContractDrops = true;
        break;

      case ContractActionType.FETCH_DROPS_LIST_SUCCESS:
        state.contractDropsList = action.payload.drops;
        state.contractDropsListOptions = action.payload.listOptions;
        state.isFetchingContractDrops = false;
        state.hasFetchContractDropsError = false;
        break;

      case ContractActionType.FETCH_DROPS_LIST_FAILURE:
        state.isFetchingContractDrops = false;
        state.hasFetchContractDropsError = true;
        break;

      /**************
       * DROPS MAP
       * */

      case ContractActionType.FETCH_DROPS_MAP_START:
        state.isFetchingContractDrops = true;
        break;

      case ContractActionType.FETCH_DROPS_MAP_SUCCESS:
        state.contractDropsMap = action.payload;
        state.isFetchingContractDrops = false;
        state.hasFetchContractDropsError = false;
        break;

      case ContractActionType.FETCH_DROPS_MAP_FAILURE:
        state.isFetchingContractDrops = false;
        state.hasFetchContractDropsError = true;
        break;

      /**************
       * BLOCKS MAP
       * */

      case ContractActionType.FETCH_BLOCKS_MAP_START:
        state.isFetchingContractBlocks = true;
        break;

      case ContractActionType.FETCH_BLOCKS_MAP_SUCCESS:
        state.contractBlocksMap = action.payload;
        state.isFetchingContractBlocks = false;
        state.hasFetchContractBlocksError = false;
        break;

      case ContractActionType.FETCH_BLOCKS_MAP_FAILURE:
        state.isFetchingContractBlocks = false;
        state.hasFetchContractBlocksError = true;
        break;

      /**************
       * POIS MAP
       * */

      case ContractActionType.FETCH_POIS_MAP_START:
        state.isFetchingContractPois = true;
        break;

      case ContractActionType.FETCH_POIS_MAP_SUCCESS:
        state.contractPoisMap = action.payload;
        state.isFetchingContractPois = false;
        state.hasFetchContractPoisError = false;
        break;

      case ContractActionType.FETCH_POIS_MAP_FAILURE:
        state.isFetchingContractPois = false;
        state.hasFetchContractPoisError = true;
        break;

      default:
        break;
    }

    return state;
  });
};
