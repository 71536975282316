import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Analytics, GTMEventHandler } from '@helpers/Analytics/index';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { deepClone } from '@helpers/deprecated/array';
import { createDropName } from '@helpers/Drop';
import { withGetScreen } from '@HOC/withGetScreen';
import { makeCloseCreateYardModalAction, setAddMultipleYards } from '@redux/deprecated/actions';

import Modal from '../Modal/Modal';

// nectar:
import { CreateYardModalView } from './CreateYardModalView';

const modalInitialState = {
  title: 'yard_info',
  nextButton: 'save',
  backButton: 'cancel',
};

const yardInitialState = {
  name: '',
  id: '',
  yard_type: '',
  crop_types_ids: [],
  contract_id: null,
  yard_center: [],
  geometry: [],
};

const validationInitialState = {
  isDisabledBtn: true,
};

class CreateYardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...modalInitialState,
      ...yardInitialState,
      ...validationInitialState,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleContractChange = this.handleContractChange.bind(this);
    this.handleChangeCropType = this.handleChangeCropType.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
    this.handleSetYardType = this.handleSetYardType.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { contentObj } = this.props;
    if (contentObj?.yard && contentObj.yard !== prevProps.contentObj.yard) {
      const { id, name, geometry, yard_center } = contentObj.yard;

      this.setState({
        id,
        geometry,
        yard_center,
        isDisabledBtn: !name,
      });
    }
  }

  handleClose() {
    const {
      closeDispatch,
      history,
      contentObj: { validateAllYardGeometry },
    } = this.props;
    const { search } = history.location;
    closeDispatch();
    this.setState({
      ...modalInitialState,
      ...yardInitialState,
      ...validationInitialState,
    });
    if (search) {
      history.push({
        pathname: history.location.pathname,
      });
    }
    validateAllYardGeometry();
  }

  setIfButtonIsDisabled() {
    const { name } = this.state;

    this.setState({ isDisabledBtn: !name });
  }

  handleTextChange(value, name) {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleContractChange(contractSummary) {
    if (!contractSummary) {
      this.setState(
        {
          contract_id: null,
          crop_types_ids: yardInitialState.crop_types_ids,
          name: yardInitialState.name,
          yard_type: yardInitialState.yard_type,
        },
        () => {
          this.setIfButtonIsDisabled();
        }
      );
    } else {
      this.setState(
        {
          contract_id: contractSummary.id,
          crop_types_ids: contractSummary.crop_types_ids,
          name: createDropName(contractSummary.latest_drop_name),
          yard_type: 'pollination',
        },
        () => {
          this.setIfButtonIsDisabled();
        }
      );
    }
  }

  handleChangeCropType(cropTypesIds) {
    this.setState(
      {
        crop_types_ids: cropTypesIds,
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleSetYardType(yard_type) {
    this.setState(
      {
        yard_type,
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleKeyDown(e) {
    const { contentObj } = this.props;
    const { isDisabledBtn } = this.state;

    const { modalType } = contentObj;

    if ('create-yard' === modalType) {
      if ('Enter' === e.key && !isDisabledBtn) this.handleNext(e);
    }
  }

  handleNext() {
    const { name, yard_type, crop_types_ids, contract_id, geometry, yard_center } = this.state;

    const { contentObj, closeDispatch, setAddMultipleYards, add, yards } = this.props;

    const { id } = contentObj.yard;

    Analytics.sendEvent({
      event: AnalyticsEventType.YARD_CREATE,
    });

    if (!this.setIfButtonIsDisabled()) {
      const newYard = {
        id,
        name,
        geometry,
        yard_center,
        ...(yard_type && { yard_type }),
        ...(crop_types_ids && { crop_types_ids }),
        ...(contract_id && { contract_id }),
      };
      let yardsToAdd = deepClone(add);
      yardsToAdd.push(newYard);
      let yards_array = [...yards];
      yards_array.push(newYard);
      setAddMultipleYards(yardsToAdd, yards_array);
      this.setState({
        ...modalInitialState,
        ...yardInitialState,
        ...validationInitialState,
      });
      closeDispatch();
    }
  }

  handleBack() {
    const {
      closeDispatch,
      add,
      yards,
      contentObj: { validateAllYardGeometry },
    } = this.props;

    setAddMultipleYards(add, yards);
    this.setState({
      ...modalInitialState,
      ...yardInitialState,
      ...validationInitialState,
    });
    closeDispatch();
    validateAllYardGeometry();
  }

  render() {
    const { t, isCreateYardOpen, isMobile, contentObj } = this.props;

    const {
      isDisabledBtn,
      id,
      yard_type,
      name,
      contract_id,
      crop_types_ids,
      title,
      nextButton,
      backButton,
      geometry,
      yard_center,
    } = this.state;

    const { modalType } = contentObj;

    const modalObj = {
      title,
      nextButton,
      backButton,
    };

    const yardObj = {
      yard_id: id,
      yard_type,
      name,
      crop_types_ids,
      contract_id,
      geometry,
      yard_center,
    };

    return (
      <Modal isMobile={isMobile()} isOpen={isCreateYardOpen} onRequestClose={this.handleClose} modalType={modalType}>
        <CreateYardModalView
          t={t}
          modalObj={modalObj}
          isMobile={isMobile()}
          contentObj={{
            yardObj,
            modalType: contentObj.modalType,
          }}
          isDisabledBtn={isDisabledBtn}
          handleClose={this.handleClose}
          handleNext={this.handleNext}
          handleTextChange={this.handleTextChange}
          handleContractChange={this.handleContractChange}
          handleChangeCropTypes={this.handleChangeCropType}
          handleBack={this.handleBack}
          passDropdownDataToParent={this.handleSetYardType}
        />
      </Modal>
    );
  }
}
/**
 *
 * @param dispatch
 * @returns {{closeDispatch: makeCloseCreateYardModalAction}}
 * @returns {{setAddMultipleYards: setAddMultipleYards}}
 *
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeCloseCreateYardModalAction());
  },
  setAddMultipleYards: (add, yards) => {
    dispatch(setAddMultipleYards(add, yards));
  },
});

/**
 *
 * @param state
 * @returns {{isCreateYardOpen: (*|boolean)}}
 * @returns {{contentObj: (*|object)}}
 * @returns {{yard: (*|object)}}
 * @returns {{yards: (*|array)}}
 * @returns {{add: (*|array)}}
 */
const mapStateToProps = (state) => ({
  isCreateYardOpen: state.modalReducer.isCreateYardOpen,
  contentObj: state.modalReducer.contentObj,
  add: state.beeTrackYardListReducer.add,
  yards: state.beeTrackYardListReducer.yards,
});

CreateYardModal.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,

  isCreateYardOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  add: PropTypes.array.isRequired,
  yards: PropTypes.array,

  closeDispatch: PropTypes.func.isRequired,
  setAddMultipleYards: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(CreateYardModal)))
);
