import { useCallback, useEffect, useState } from 'react';

export type StoragePartialStateGetter<T> = (currentState: T) => Partial<T>;
export type StorageStateSetter<T> = (getter: StoragePartialStateGetter<T> | Partial<T>) => void;

export function useSessionStorageState<T>(key: string, defaultValue: T) {
  return useStorageState(sessionStorage, key, defaultValue);
}

export function useLocalStorageState<T>(key: string, defaultValue: T) {
  return useStorageState(localStorage, key, defaultValue);
}

export function useStorageState<T>(storage: Storage, key: string, defaultValue: T): [T, StorageStateSetter<T>] {
  const [state, setState] = useState<T>(() => {
    const existingValue = storage.getItem(key);
    return existingValue ? deserializeValueFromStorage(existingValue, defaultValue) : defaultValue;
  });

  useEffect(() => {
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === key) {
        setState(event.newValue ? deserializeValueFromStorage<T>(event.newValue, defaultValue) : defaultValue);
      }
    };
    window.addEventListener('storage', onStorageEvent);
    return () => window.removeEventListener('storage', onStorageEvent);
  }, [defaultValue, key]);

  const storageStateSetter = useCallback<StorageStateSetter<T>>(
    (stateOrFunction) => {
      setState((state) => {
        let nextState: T;
        if (typeof stateOrFunction === 'function') {
          nextState = { ...state, ...stateOrFunction(state) };
        } else {
          nextState = { ...state, ...stateOrFunction };
        }
        storage.setItem(key, serializeValueToStorage(nextState));
        return nextState;
      });
    },
    [key, storage]
  );

  return [state, storageStateSetter];
}

function serializeValueToStorage<T>(value: T): string {
  return JSON.stringify(value);
}

function deserializeValueFromStorage<T>(serializedValue: string, defaultValue: T): T {
  try {
    return JSON.parse(serializedValue) as T;
  } catch (error) {
    console.warn("Can't deserialize local storage value:", error);
    return defaultValue;
  }
}
