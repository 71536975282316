import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Tooltip } from '@components/common/Tooltip';

export const StyledTooltip = styled(Tooltip)(
  () => `
  width: 280px;
`
);

export const StyledActionsList = styled(Box)(
  ({ theme }) => `
  flex-direction: column;
  margin: ${theme.spacing.xxs}px;
  border-radius: ${theme.shape.paperRadius01}px;
  border: 1px solid ${theme.colors.grey07};
`
);

export const StyledActionItem = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing.xxs}px;
  border-bottom: 1px solid ${theme.colors.grey07};
  
  &:last-child {
    border-bottom: 0;
  }
  
  p {
    flex: 1;
    line-height: 18px;
    margin: 4px 0;
    
    & > span:first-child {
      font-weight: 600;
    }
  }
  
  svg {
    margin-top: 6px;
    margin-left: ${theme.spacing.xxs}px;
    margin-right: ${theme.spacing.xs}px;
  }
`
);
