import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.primaryTextColor};
  cursor: pointer;
`;
const ConfirmationBox = styled.div`
  position: absolute;
  top: 25%;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  padding: 7px 0.5rem;
  font-weight: 300;
  background-color: ${(props) => props.theme.buttonPrimaryColor};
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  line-height: 17px;
  letter-spacing: 0.86px;
  box-shadow: ${(props) => props.theme.boxShadow4};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
`;

export const ContactModalView = ({ t, isMobile }) => {
  const [show, setShow] = useState(false);
  let delay = 1; // in seconds

  // how to correctly copy to clipboard using JS source -> https://stackoverflow.com/a/30810322
  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };
  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Async: Copying to clipboard was successful!');
        setShow(true);
        let timer = setTimeout(() => setShow(false), delay * 1000);
        return () => {
          clearTimeout(timer);
        };
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };
  return (
    <div>
      <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
        {t('contact_title')}
      </StyledHeading2>
      <StyledParagraph style={{ marginBottom: '1.5rem' }}>
        <Trans i18nKey="contact_desc" />
        {show && <ConfirmationBox>{t('email_clipboard')}</ConfirmationBox>}
        <StyledLink onClick={() => copyTextToClipboard('support@nectar.buzz')}>support@nectar.buzz.</StyledLink>
      </StyledParagraph>
      <StyledParagraph>
        {t('contact_text')}
        <StyledLink href="https://help.nectar.buzz/en" target="_blank" rel="noopener noreferrer">
          {t('online_knowledge_base')}
        </StyledLink>
        {t('contact_text_cont')}
      </StyledParagraph>
    </div>
  );
};

ContactModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
