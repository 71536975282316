import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { BoxProps } from '../Box/types';

export const CardHeader = styled(Box)(
  ({ theme }) => `
    border-bottom: 1px solid ${theme.colors.grey03};
    justify-content: space-between;
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
    align-items: center;
  `
);

export const CardContent = styled.div<{ scrollable?: boolean }>(
  ({ theme, scrollable }) => `
    position: relative;
    overflow: ${scrollable ? 'hidden auto' : 'default'};
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
  `
);

export const CardContentRow = styled.div(
  ({ theme }) => `
    :not(:last-child) {
      border-bottom: 1px solid ${theme.colors.grey03};
      margin-bottom: ${theme.spacing.sm}px;
      padding-bottom: ${theme.spacing.sm}px;
    }
  `
);

export const Card = ({ children, ...props }: { children?: ReactNode } & BoxProps<'div'>) => {
  return (
    <Box paper02 column stretch {...props}>
      {children}
    </Box>
  );
};
