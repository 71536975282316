import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { StyledHeading1, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const LoginContainer = styled.div`
  height: 100%;
  width: ${(props) => (props.isMobile ? '100%' : '520px')};
  z-index: 1;
  top: 0;
  float: left;
  left: 0;
`;

export const StyledForm = styled.form`
  margin: ${(props) => (props.isMobile ? '0 2rem' : '0 3rem')};
  padding: ${(props) => (props.isMobile ? '2rem 0' : '3rem 0')};
  height: inherit;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: space-between;
  & > section {
    flex: 1;
  }
`;

export const StyledHeader = styled.header`
  width: 100%;
  max-width: ${(props) => props.theme.maxDesktopWidth};
  white-space: pre-line;
  display: flex;
`;

export const StyledLogoImg = styled.img`
  width: 150px;
  height: 30px;
  color: ${(props) => props.theme.nectarColor};
  filter: ${(props) => props.theme.nectarLogoFilter};
`;

export const StyledTitle = styled(StyledHeading1)`
  display: inline-block;
`;

export const StyledText = styled(StyledParagraph)`
  width: 100%;
  max-width: ${(props) => props.theme.maxDesktopWidth};
  margin: 0 auto;
  margin-top: 1rem;
  white-space: pre-line;
`;

export const StyledErrorMsg = styled(StyledParagraph)`
  height: 19px;
  display: inline-block;
  color: ${(props) => props.theme.criticalColor};
  float: right;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${(props) => props.theme.paragraphFontSize};
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
