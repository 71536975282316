import React, { useMemo } from 'react';
// vendor:
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';

import { Box } from '@components/common/Box';
import { MessageBox } from '@components/common/MessageBox';
import { Text } from '@components/common/Text';
import { IconButton } from '@components/deprecated/Buttons/IconButton';
import Dropdown from '@components/deprecated/Dropdown/Dropdown';
import { ModalContainer, StyledLabel } from '@components/deprecated/Elements/ModalElements';
import { StyledCloseButton, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2 } from '@components/deprecated/Elements/TypographyElements';
import { Fieldset } from '@components/deprecated/Fieldset/Fieldset';
// nectar:
import { InputText } from '@components/form/InputText';
import { InputCropType } from '@components/yard/InputCropType';
import { InputPolliContract } from '@components/yard/InputPolliContract';
import APP from '@config/constants';

export const CreateYardModalView = ({
  t,
  isMobile,
  modalObj,
  contentObj,
  handleTextChange,
  handleContractChange,
  handleChangeCropTypes,
  handleClose,
  handleNext,
  handleBack,
  isDisabledBtn,
  passDropdownDataToParent,
}) => {
  const { yard_id, name, crop_types_ids, yard_type, contract_id } = contentObj.yardObj;
  const shouldDisableContractRelatedFields = useMemo(() => !!contract_id, [contract_id]);
  const { title, nextButton, backButton } = modalObj;

  const dropdownItems = APP.YARD_TYPES.map((type) => {
    return {
      yard_type: type,
      yard_type_name: type,
    };
  });

  dropdownItems.unshift({
    yard_type: 'none',
    yard_type_name: t('choose_one'),
  });

  return (
    <ModalContainer isMobile={isMobile}>
      <IconButton onClick={handleClose} preset={'close-top-right'} />

      <section
        style={{
          padding: 0,
          paddingTop: '3rem',
        }}
      >
        {!!contract_id && (
          <Box paddingHorizontalMD marginVerticalSM>
            <MessageBox>
              <Text typography="CaptionSmall">{t('edit_contract_yard_warning')}</Text>
            </MessageBox>
          </Box>
        )}
        <StyledHeading2
          style={{
            padding: '0 1.5rem',
            marginBottom: '1rem',
          }}
          mobile={isMobile}
        >
          {t(title)}
        </StyledHeading2>

        <Row nogutter style={{ padding: '0 1.5rem 3rem' }}>
          <Col xs={12}>
            <div style={{ marginBottom: '1rem' }}>
              <InputText
                id="name"
                name="name"
                disabled={shouldDisableContractRelatedFields}
                label={t('name')}
                value={name}
                onTextChange={handleTextChange}
                required
                autoFocus
              />
            </div>
          </Col>
          <Col xs={12}>
            <Fieldset style={{ marginBottom: '1rem' }}>
              <InputPolliContract
                id="yard-contract-id"
                name="contract_id"
                contractId={contract_id}
                onContractChange={handleContractChange}
              />
            </Fieldset>
          </Col>
          <Col xs={12}>
            <Fieldset tabIndex="0" style={{ marginBottom: '1rem' }} disabled={shouldDisableContractRelatedFields}>
              <StyledLabel disabled={shouldDisableContractRelatedFields}>{t('type_yard')}</StyledLabel>
              <Dropdown
                t={t}
                key={yard_id}
                renderIcon={true}
                disabled={shouldDisableContractRelatedFields}
                inputName="yard-type"
                activeItemId={yard_type ? yard_type : 'none'}
                list={dropdownItems.map((a) => {
                  return {
                    id: a.yard_type,
                    name: a.yard_type_name,
                  };
                })}
                passDropdownDataToParent={passDropdownDataToParent}
                selectBoxListStyle={{ height: '12.5rem' }}
              />
            </Fieldset>
          </Col>
          <Col xs={12}>
            <Fieldset>
              <InputCropType
                id="yard-crop-types"
                name="yard-crop-types"
                value={crop_types_ids}
                disabled={shouldDisableContractRelatedFields}
                onChange={handleChangeCropTypes}
              />
            </Fieldset>
          </Col>
        </Row>
      </section>

      <footer>
        <div>
          <StyledCloseButton type="button" label={t(backButton)} onClick={handleBack} disabled={false} />

          <StyledSubmitButton
            type="button"
            label={t(nextButton)}
            onClick={handleNext}
            disabled={isDisabledBtn}
            height="2rem"
          />
        </div>
      </footer>
    </ModalContainer>
  );
};

CreateYardModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleContractChange: PropTypes.func.isRequired,
  handleChangeCropTypes: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    yardObj: PropTypes.object.isRequired,
    modalType: PropTypes.string.isRequired,
  }).isRequired,
  passDropdownDataToParent: PropTypes.func.isRequired,
  modalObj: PropTypes.shape({
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    nextButton: PropTypes.string.isRequired,
    backButton: PropTypes.string,
  }),
};
