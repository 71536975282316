import React from 'react';
import { Col, Row, setConfiguration } from 'react-grid-system';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import clearIcon from '@assets/weather/xSmall_Clear-day.svg';
import lowBatteryIcon from '@assets/xSmall_Battery-low.svg';
import lowDataIcon from '@assets/xSmall_Data-low.svg';
import { StatusChip } from '@components/deprecated/Chip/StatusChip';
import { StyledCaptionSmall, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import StaticMap from '@components/deprecated/Gmap/StaticMap';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
import { WeatherTooltip } from '@components/deprecated/Tooltip_deprecated/WeatherTooltip';
import { WeatherTooltipView } from '@components/deprecated/Tooltip_deprecated/WeatherTooltipView';
import { getColorCodeByCriticality } from '@helpers/deprecated/getColorCodeByCriticality';
import { getPopTrend } from '@helpers/deprecated/icons';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getDays } from '@helpers/deprecated/time';
import { getTooltipStatusText } from '@helpers/deprecated/tooltip';
import { getTooltipTrendText } from '@helpers/deprecated/tooltip';
import { trimString } from '@helpers/deprecated/trimString';
// nectar:
import theme from '@style/theme';

setConfiguration({ gutterWidth: 0 });

const StyledCard = styled.button`
  height: 100%;
  width: 312px;
  text-align: left;
  background-color: ${(props) => (props.disable ? 'inherit' : props.theme.primaryBackgroundColor)};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  outline-style: initial;
  box-shadow: ${(props) => props.theme.boxShadow1};
  &:hover {
    box-shadow: ${(props) => props.theme.boxShadow2};
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  height: 42px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  padding-left: 1rem;
`;

const StyledTitle = styled.span`
  font-size: ${(props) => props.theme.captionSmallFontSize};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const StyledErrorRow = styled(Row)`
  width: 100%;
  height: 2.5rem;
  background-color: ${(props) => props.backgroundcolor};
  align-content: center;
  padding-left: 1rem;
  text-align: left;
  border-radius: 3px 3px 0 0;
`;

const StyledErrorTitle = styled.span`
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  font-size: ${(props) => props.theme.captionSmallFontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  line-height: 1rem;
`;

const Rows = styled(Row)`
  height: 23px;
  width: 100%;
`;

const TopBarColumn = styled(Col)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.supportingColor};
`;

const WeatherColumn = styled(TopBarColumn)`
  &:hover {
    color: ${(props) => props.theme.primaryTextColor};
  }
`;

const StyledColumn = styled(Col)`
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChipColumn = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  position: relative;
  border-left: ${(props) => props.theme.tertiaryBorderStyle};
  left: 180px;
  height: 90px;
  bottom: 90px;
`;

const SmallDivider = styled(Col)`
  border-left: ${(props) => props.theme.primaryBorderStyle};
  height: 1rem;
  align-self: center;
  margin: 0 0.5rem;
`;

const StyledImg = styled.img`
  filter: ${(props) => props.theme.criticalSvgPictoFilter};
`;

const TextRow = styled(Row)`
  width: 100%;
  height: 90px;
  opacity: ${(props) => props.opacity};
`;

const WeatherImg = styled.img`
  width: 1rem;
  height: 1rem;
  filter: ${(props) => props.theme.supportingColorFilter};
  &:hover {
    filter: ${(props) => props.theme.primaryColorFilter};
  }
`;

export const YardCardView = ({ t, yard, handleClick, showWeather }) => {
  const {
    yard_id: id,
    yard_name: name,
    total_hives: hiveCount,
    yard_location: yardLocation,
    yard_nickname: yardNickname,
    recently_updated: transmitting,
  } = yard;

  const {
    nb_queenless: queelessCount,
    nb_deadouts: deadoutsCount,
    avg_pop_trend: population,
    criticality,
  } = yard.ai_events;

  const {
    nb_low_battery: lowBatteryCount,
    nb_low_data_rate: lowDataRateCount,
    nb_no_transmission: noTransmittingCount,
  } = yard.hardware;

  const colorCode = getColorCodeByCriticality(yard);

  const badBeehub = ['unknown'].includes(colorCode) || !transmitting;

  const noGoodBeecons = hiveCount === noTransmittingCount;

  return (
    <StyledCard id={id} disable={badBeehub || noGoodBeecons} onClick={handleClick}>
      {badBeehub ? (
        <StyledErrorRow backgroundcolor={theme.criticalColor}>
          <StyledErrorTitle>{t('not_transmitting_beehub_msg')}</StyledErrorTitle>
        </StyledErrorRow>
      ) : noGoodBeecons ? (
        <StyledErrorRow backgroundcolor={theme.unknownColor}>
          <StyledErrorTitle>{t('no_hives_transmitting_msg')}</StyledErrorTitle>
        </StyledErrorRow>
      ) : (
        <StyledRow>
          {yardNickname && (
            <TopBarColumn xs={1}>
              <StyledTitle color={theme.supportingColor}>{yardNickname}</StyledTitle>
            </TopBarColumn>
          )}

          {showWeather && (
            <>
              <SmallDivider />

              <WeatherColumn style={{ margin: '0 0.5rem' }} xs={1} data-for={'weather' + id} data-tip>
                <WeatherImg src={clearIcon} alt={'weather icon' + id} />
                <StyledTitle color={theme.supportingColor}>15&deg;</StyledTitle>
              </WeatherColumn>

              <WeatherTooltip
                id={'weather' + id}
                disable={false}
                tooltipText={<WeatherTooltipView t={t} days={getDays(5)} src={clearIcon} high={15} low={5} />}
              />
            </>
          )}

          {(showWeather || yardNickname) && (lowDataRateCount > 0 || lowBatteryCount > 0) && <SmallDivider />}

          {lowDataRateCount > 0 && (
            <TopBarColumn xs={1} style={{ marginRight: '0.5rem' }}>
              <StyledDiv data-for={'lowDataRate' + id} data-tip>
                <StyledImg src={lowDataIcon} alt="low data rate devices devices count" />
                <StyledTitle color={theme.criticalColor}>{lowDataRateCount}</StyledTitle>
                <Tooltip id={'lowDataRate' + id} disable={false} tooltipText={t('tooltip_low_data_rate')} />
              </StyledDiv>
            </TopBarColumn>
          )}

          {lowBatteryCount > 0 && (
            <TopBarColumn xs={1}>
              <StyledDiv data-for={'lowBattery' + id} data-tip>
                <StyledImg src={lowBatteryIcon} alt="low battery devices count" />
                <StyledTitle color={theme.criticalColor}>{lowBatteryCount}</StyledTitle>
                <Tooltip id={'lowBattery' + id} disable={false} tooltipText={t('tooltip_low_battery')} />
              </StyledDiv>
            </TopBarColumn>
          )}

          <Col />

          <>
            <ChipColumn data-for={'chip ' + id} data-tip xs={5}>
              <StatusChip>{colorCode}</StatusChip>
            </ChipColumn>
            <Tooltip
              id={'chip ' + id}
              disable={false}
              tooltipText={getTooltipStatusText(t, criticality)}
              className="tooltip"
            />
          </>
        </StyledRow>
      )}

      <Row style={{ margin: 0, opacity: badBeehub || noGoodBeecons ? '0.5' : '1' }}>
        <Col xs={12} style={{ padding: 0 }}>
          <StaticMap
            t={t}
            markerLocation={yardLocation}
            // easy debugging no marker location cases
            // markerLocation={null}
            zoom={10}
            colorCode={colorCode}
            height="104px"
          />
        </Col>
      </Row>

      <TextRow opacity={badBeehub || noGoodBeecons ? '0.5' : '1'}>
        <Col style={{ height: '100%', padding: '1rem' }} xs={7}>
          <StyledParagraph
            style={{ fontWeight: theme.fontWeightSemiBold, textShadow: '0 1px 2px 0 rgba(255,255,255,0.5)' }}
          >
            {trimString(name, 12)}
          </StyledParagraph>
          {hiveCount && <StyledCaptionSmall>{maybePluralize(hiveCount, 'hive', t)}</StyledCaptionSmall>}
        </Col>

        <Col style={{ height: '100%', padding: '10.5px' }} xs={5}>
          <Rows>
            <Col style={{ alignSelf: 'center' }} xs={9}>
              <StyledCaptionSmall>{t('deadouts')}</StyledCaptionSmall>
            </Col>
            <StyledColumn xs={3}>{deadoutsCount ? deadoutsCount : 0}</StyledColumn>
          </Rows>

          <Rows>
            <Col style={{ alignSelf: 'center' }} xs={9}>
              <StyledCaptionSmall>{t('queenless')}</StyledCaptionSmall>
            </Col>
            <StyledColumn xs={3}>{queelessCount ? queelessCount : 0}</StyledColumn>
          </Rows>

          <Rows>
            <Col style={{ alignSelf: 'center' }} xs={9}>
              <StyledCaptionSmall>{t('population')}</StyledCaptionSmall>
            </Col>

            <StyledColumn xs={3} data-for={'population' + id} data-tip>
              <img style={{ width: '1rem', height: '1rem' }} src={getPopTrend(population)} alt="population trend" />
            </StyledColumn>

            <Tooltip
              id={'population' + id}
              disable={badBeehub || noGoodBeecons}
              tooltipText={getTooltipTrendText(t, population)}
            />
          </Rows>
        </Col>
        <Divider />
      </TextRow>
    </StyledCard>
  );
};

StyledImg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

YardCardView.propTypes = {
  t: PropTypes.func.isRequired,
  yard: PropTypes.shape({
    yard_id: PropTypes.number.isRequired,
    yard_nickname: PropTypes.string,
    yard_name: PropTypes.string.isRequired,
    yard_location: PropTypes.string,
    total_hives: PropTypes.number,
    ai_events: PropTypes.object.isRequired,
    recently_updated: PropTypes.bool.isRequired,
    hardware: PropTypes.object.isRequired,
  }).isRequired,
  handleClick: PropTypes.func,
  showWeather: PropTypes.bool.isRequired,
};
