import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { useTranslation } from '@hooks/useTranslation';

const TOOLTIP_DATES_MAX_PREVIEW_COUNT = 5;

interface PracticeCellTooltipProps {
  dates: Array<string>;
}

export const StyledTooltipContent = styled(Box).attrs({
  column: true,
})(
  ({ theme }) => `
  border: ${theme.colors.grey07} 1px solid;
  border-radius: ${theme.shape.paperRadius01}px;
`
);

export const StyledTooltipRow = styled(Box).attrs({
  paddingXS: true,
})(
  ({ theme }) => `
  &:not(:first-child) {
    border-top: ${theme.colors.grey07} 1px solid;
  }
`
);

export const PracticeCellTooltipContent: React.FC<PracticeCellTooltipProps> = ({ dates }) => {
  const t = useTranslation();

  const visibleDates = dates.slice(0, TOOLTIP_DATES_MAX_PREVIEW_COUNT);
  const hiddenDatesCount = dates.length - visibleDates.length;

  return (
    <StyledTooltipContent>
      {visibleDates.map((date: string, index: number) => {
        const dateStr = moment(date).format('MMM DD, YYYY');
        const daysDiff = Math.abs(moment(date).diff(moment(), 'days'));
        const daysStr = t(daysDiff === 0 ? 'today' : daysDiff === 1 ? 'yesterday' : 'days_ago', { number: daysDiff });
        return (
          <StyledTooltipRow key={index}>
            <Text typography={'CaptionSmall'}>
              {dateStr} ({daysStr})
            </Text>
          </StyledTooltipRow>
        );
      })}
      {hiddenDatesCount > 0 && (
        <StyledTooltipRow>
          <Text typography={'CaptionSmall'}>
            {hiddenDatesCount} {t(hiddenDatesCount === 1 ? 'more_date' : 'more_dates')}
          </Text>
        </StyledTooltipRow>
      )}
    </StyledTooltipContent>
  );
};
