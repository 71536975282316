import React, { useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { MapApiProviders } from '@components/common/Map/apis';
import { DEFAULT_MAP_API, DEFAULT_MAP_STATE } from '@components/common/Map/constants';
import { useMapState, useMapStorageState, useMapURLState } from '@components/common/Map/hooks';
import { MapContextValue, MapInstance, MapProps } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';

export const MapContext = React.createContext<MapContextValue | null>(null);

export const MapContextProvider = React.forwardRef<MapContextValue, MapProps>(({ children, ...props }, ref) => {
  const forwardedContext = useContext(MapContext);

  if (forwardedContext) {
    return children;
  }

  return (
    <MapContextProviderBase ref={ref} {...props}>
      {children}
    </MapContextProviderBase>
  );
});
MapContextProvider.displayName = 'MapContextProvider';

const MapContextProviderBase = React.forwardRef<MapContextValue, MapProps>(
  ({ useURLState, useStorageState, children, state: propState, onStateChange: propOnStateChange, ...props }, ref) => {
    const [mapMemState, setMapMemState] = useMapState({ ...DEFAULT_MAP_STATE, ...propState });
    const [mapURLState, setMapURLState] = useMapURLState({ ...DEFAULT_MAP_STATE, ...propState });
    const [mapStorageState, setMapStorageState] = useMapStorageState({ ...DEFAULT_MAP_STATE, ...propState });
    const [instance, __setInstance] = useState<MapInstance | null>(null);

    const [state, setState] = useMemo(
      () =>
        useURLState
          ? [mapURLState, setMapURLState]
          : useStorageState
          ? [mapStorageState, setMapStorageState]
          : [mapMemState, setMapMemState],
      [
        mapMemState,
        mapStorageState,
        mapURLState,
        setMapMemState,
        setMapStorageState,
        setMapURLState,
        useStorageState,
        useURLState,
      ]
    );

    const api = props.api ?? DEFAULT_MAP_API;
    const ApiContextProvider = MapApiProviders[api].contextProvider ?? React.Fragment;
    const mapContext = useMemo<MapContextValue>(
      () => ({ api, state, setState, instance, __setInstance }),
      [api, instance, setState, state]
    );

    useEffect(
      () => {
        if (propState) {
          const nextState = { ...state, ...propState };
          if (!MapUtil.areMapStatesEqual(state, nextState)) {
            setState(nextState);
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [propState]
    );

    useEffect(() => {
      if (
        propOnStateChange &&
        (propState === undefined || !MapUtil.areMapStatesEqual({ ...state, ...propState }, state))
      ) {
        propOnStateChange(state);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
      instance && props.onInstance && props.onInstance(instance);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance]);

    useImperativeHandle(ref, () => mapContext, [mapContext]);

    return (
      <MapContext.Provider value={mapContext}>
        <ApiContextProvider>{children}</ApiContextProvider>
      </MapContext.Provider>
    );
  }
);
MapContextProviderBase.displayName = 'MapContextProviderBase';
