import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type YardsGlobalSearchReducer = Reducer<YardsGlobalSearchState, YardsGlobalSearchAction>;

export type YardsGlobalSearchAction =
  | { type: YardsGlobalSearchActionType.GLOBAL_SEARCH_FETCH_YARDS_START }
  | {
      type: YardsGlobalSearchActionType.GLOBAL_SEARCH_FETCH_YARDS_FINISH;
      payload: {
        data: Array<BeeGlobalSearchYard> | null;
        error: ApiResponseError | null;
      };
    }
  | {
      type: YardsGlobalSearchActionType.GLOBAL_SEARCH_PUSH_TO_HISTORY;
      payload: {
        data: { id: number };
      };
    };

export interface YardsGlobalSearchState {
  isFetching: boolean;

  /**
   * Summary info from all yards.
   * */
  yards: Array<BeeGlobalSearchYard> | null;

  /**
   * List of the last searched yards ids.
   * */
  history: Array<number>;
}

export enum YardsGlobalSearchActionType {
  GLOBAL_SEARCH_FETCH_YARDS_START = 'GLOBAL_SEARCH_FETCH_YARDS_START',
  GLOBAL_SEARCH_FETCH_YARDS_FINISH = 'GLOBAL_SEARCH_FETCH_YARDS_FINISH',
  GLOBAL_SEARCH_PUSH_TO_HISTORY = 'GLOBAL_SEARCH_PUSH_TO_HISTORY',
}
