import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Edit = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.172 2.82801C17.702 2.29694 18.4217 1.99896 19.172 2.00001C20.316 1.99976 21.3475 2.68878 21.7853 3.74569C22.2231 4.80261 21.9811 6.01919 21.172 6.82801L20 8.00001L16 4.00001L17.172 2.82801ZM3 17L14.5 5.50001L18.5 9.50001L7 21H3V17Z"
      />
    </IconSVGView>
  );
};
