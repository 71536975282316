import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HiveAdded = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <rect fill={useColor(color)} width="24" height="24" fillOpacity="0.01" />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1564 12.5279C13.2308 13.4349 12.0014 15.3715 12 17.5C11.9991 18.7781 12.4452 20.0162 13.261 21H5C3.89883 20.9918 3.00817 20.1012 3 19V7C3.00063 6.82415 3.04791 6.65161 3.137 6.5L4.887 3.5C5.06509 3.19153 5.39382 3.00108 5.75 3H18.25C18.6062 3.00108 18.9349 3.19153 19.113 3.5L20.863 6.5C20.9521 6.65161 20.9994 6.82415 21 7V13.261C19.3581 11.9065 17.082 11.621 15.1564 12.5279ZM17.676 5H6.324L5.158 7H18.842L17.676 5ZM9 10C9 9.44772 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H10C9.44772 11 9 10.5523 9 10ZM17.5 13C15.0147 13 13 15.0147 13 17.5C13 19.9853 15.0147 22 17.5 22C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13ZM19.5 18H18V19.5C18 19.7761 17.7761 20 17.5 20C17.2239 20 17 19.7761 17 19.5V18H15.5C15.2239 18 15 17.7761 15 17.5C15 17.2239 15.2239 17 15.5 17H17V15.5C17 15.2239 17.2239 15 17.5 15C17.7761 15 18 15.2239 18 15.5V17H19.5C19.7761 17 20 17.2239 20 17.5C20 17.7761 19.7761 18 19.5 18Z"
      />
    </IconSVGView>
  );
};
