export const getParsedFromSessionStorage = (what) => {
  const item = sessionStorage.getItem(what);
  if (item) return JSON.parse(item);
  else return null;
};

export const getParsedFromLocalStorage = (what) => {
  const item = localStorage.getItem(what);
  if (item) return JSON.parse(item);
  else return null;
};
