import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import smallPageIcon from '@assets/Small_Arrow-down.svg';
import smallLastPageIcon from '@assets/Small_LastPage.svg';
import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

import { UnstyledButton } from '../UnstyledButton';

interface OffseProps {
  pageIndex: number;
  limit: number;
  totalItems: number;
  itemLabel: string;
  changePage: (pageIndex: number) => void;
}

const PagesContainer = styled(Box)(
  ({ theme }) => `
        border-radius: ${theme.shape.paperRadius01}px;
        background-color: ${theme.colors.coreWhite};
        box-shadow: 0px 1px 3px rgba(102, 102, 102, 0.1), 0px 1px 3px rgba(102, 102, 102, 0.2);
        padding: ${theme.spacing.xs}px;
    `
);

const PageButton = styled(UnstyledButton)<{ isActive: boolean }>(
  ({ theme, isActive }) => `
        border-radius: ${theme.shape.paperRadius01}px;
        background-color: ${isActive ? theme.colors.grey08 : 'inherit'};
        color: ${isActive ? theme.colors.coreWhite : theme.colors.grey08};
        padding: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
        font-weight: ${isActive ? 700 : 400};
        margin: 0 0.25rem;
        &:hover {
            opacity: ${!isActive ? 0.6 : 1}
        }
    `
);

const PageIcon = styled.img<{ rotate: number }>`
  filter: invert(23%) sepia(9%) saturate(719%) hue-rotate(120deg) brightness(93%) contrast(89%); // remove this
  transform: rotate(${(props) => props.rotate}deg);
  margin-left: 0.5rem;
  &:hover {
    opacity: 0.6;
  }
`;

export const Pagination: React.FC<OffseProps> = ({ pageIndex, limit, totalItems, itemLabel, changePage }) => {
  const totalPages = Math.ceil(totalItems / limit);
  const { t } = useTranslation();
  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === totalPages - 1;
  const from = pageIndex * limit;
  const until = isLastPage || limit > totalItems ? totalItems : from + limit;
  const isThereMoreThanOnePage = 1 < totalPages;
  let viewablePagesArray = [pageIndex - 1, pageIndex, pageIndex + 1];

  if (isFirstPage) {
    if (totalPages === 2) viewablePagesArray = [0, 1];
    else viewablePagesArray = [0, 1, 2];
  }
  if (isLastPage) {
    if (totalPages === 2) viewablePagesArray = [pageIndex - 1, pageIndex];
    else viewablePagesArray = [pageIndex - 2, pageIndex - 1, pageIndex];
  }

  return (
    <Box justifyContent={'space-between'} alignItems={'center'}>
      <div>
        <Text typography="SmallParagraph"> {`${from + 1}-${until} ${t('of')} ${totalItems} ${itemLabel}`}</Text>
      </div>
      {isThereMoreThanOnePage && (
        <PagesContainer justifyContent={'center'} alignItems={'center'}>
          {!isFirstPage && totalPages > 3 && (
            <UnstyledButton aria-label={t('skip_to_first_page')} onClick={() => changePage(0)}>
              <PageIcon rotate={180} src={smallLastPageIcon} />
            </UnstyledButton>
          )}

          {!isFirstPage && (
            <UnstyledButton aria-label={t('skip_to_previous_page')} onClick={() => changePage(pageIndex - 1)}>
              <PageIcon rotate={90} src={smallPageIcon} />
            </UnstyledButton>
          )}

          {viewablePagesArray.map((page, idx) => (
            <PageButton key={idx} onClick={() => changePage(page)} isActive={page === pageIndex}>
              <Text style={{ lineHeight: 1.2 }} typography="SmallParagraph">
                {page + 1}
              </Text>
            </PageButton>
          ))}
          {!isLastPage && (
            <UnstyledButton aria-label={t('skip_to_next_page')} onClick={() => changePage(pageIndex + 1)}>
              <PageIcon rotate={-90} src={smallPageIcon} />
            </UnstyledButton>
          )}
          {!isLastPage && totalPages > 3 && (
            <UnstyledButton aria-label={t('skip_to_last_page')} onClick={() => changePage(totalPages - 1)}>
              <PageIcon rotate={0} src={smallLastPageIcon} />
            </UnstyledButton>
          )}
        </PagesContainer>
      )}
    </Box>
  );
};
