import React, { useMemo } from 'react';
// vendor:
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { MessageBox } from '@components/common/MessageBox';
import { Text } from '@components/common/Text';
import { IconButton } from '@components/deprecated/Buttons/IconButton';
import { ModalContainer, StyledLabel } from '@components/deprecated/Elements/ModalElements';
import { StyledCloseButton, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2 } from '@components/deprecated/Elements/TypographyElements';
import { InputText } from '@components/form/InputText';
import { StyledErrorMessage } from '@components/form/InputText/styles';
import { InputCropType } from '@components/yard/InputCropType';
import { InputPolliContract } from '@components/yard/InputPolliContract';
import APP from '@config/constants';

import Dropdown from '../Dropdown/Dropdown';
import { Fieldset } from '../Fieldset/Fieldset';
import BeeTrackEditBoundariesMap from '../Gmap/BeeTrackEditBoundariesMap';
import { TextArea } from '../TextArea/TextArea';

const StepImg = styled.span`
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? props.theme.buttonPrimaryColor : props.theme.inputColor)};
`;

const FieldNotesPreTag = styled.pre`
  height: 152px;
  border: ${(props) => props.theme.primaryBorderStyle};
  &:hover {
    border: ${(props) => props.theme.secondaryBorderStyle};
  }
  &:focus {
    box-shadow: ${(props) => props.theme.buttonFocusBoxShadow};
    outline: none;
  }
`;

export const UpdateYardModalView = ({
  t,
  isMobile,
  contentObj,
  handleChange,
  handleTextChange,
  handleContractChange,
  handleChangeCropTypes,
  handleClose,
  handleNext,
  handleBack,
  isDisabledBtn,
  isDisabledNext,
  passDropdownDataToParent,
  modalObj,
  setNewYardGeometry,
  validateGeometry,
  isDrop,
  loading,
}) => {
  const { yard_id, name, crop_types_ids, yard_type, field_notes, geometry, contract_id } = contentObj.yardObj;

  const { title, nextButton, backButton, step } = modalObj;
  const hasContractIdError = useMemo(() => isDrop && !contract_id, [contract_id, isDrop]);
  const shouldDisableContractRelatedFields = useMemo(() => isDrop || !!contract_id, [isDrop, contract_id]);
  const dropdownItems = APP.YARD_TYPES.map((type) => {
    return {
      yard_type: type,
      yard_type_name: type,
    };
  });

  dropdownItems.unshift({
    yard_type: 'none',
    yard_type_name: t('choose_one'),
  });

  return (
    <ModalContainer isMobile={isMobile}>
      <IconButton onClick={handleClose} preset={'close-top-right'} />

      <section
        style={{
          padding: 0,
          paddingTop: '3rem',
        }}
      >
        {!isDrop && !!contract_id && step === 1 && (
          <Box paddingHorizontalMD marginVerticalSM>
            <MessageBox>
              <Text typography="CaptionSmall">{t('edit_contract_yard_warning')}</Text>
            </MessageBox>
          </Box>
        )}

        <StyledHeading2
          style={{
            padding: '0 1.5rem',
            marginBottom: '1rem',
          }}
          mobile={isMobile}
        >
          {t(title)}
        </StyledHeading2>

        {1 === step && (
          <Row nogutter style={{ padding: '0 1.5rem 3rem' }}>
            <Col xs={12}>
              <div style={{ marginBottom: '1rem' }}>
                <InputText
                  id="name"
                  name="name"
                  label={t('name')}
                  value={name}
                  disabled={shouldDisableContractRelatedFields}
                  onTextChange={handleTextChange}
                  required
                  autoFocus
                />
              </div>
            </Col>
            <Col xs={12}>
              <Box block marginBottomSM>
                <InputPolliContract
                  id="yard-contract-id"
                  name="contract_id"
                  contractId={contract_id}
                  onContractChange={handleContractChange}
                  hasError={hasContractIdError}
                />

                {hasContractIdError && <StyledErrorMessage>{t('edit_contract_drop_error')}</StyledErrorMessage>}
              </Box>
            </Col>

            <Col xs={12}>
              <Fieldset tabIndex="0" style={{ marginBottom: '1rem' }} disabled={shouldDisableContractRelatedFields}>
                <StyledLabel disabled={shouldDisableContractRelatedFields}>{t('type_yard')}</StyledLabel>
                <Dropdown
                  t={t}
                  disabled={shouldDisableContractRelatedFields}
                  key={yard_id}
                  renderIcon={true}
                  inputName="yard-type"
                  activeItemId={yard_type ? yard_type : 'none'}
                  list={dropdownItems.map((a) => {
                    return {
                      id: a.yard_type,
                      name: a.yard_type_name,
                    };
                  })}
                  passDropdownDataToParent={passDropdownDataToParent}
                  selectBoxListStyle={{ height: '12.5rem' }}
                />
              </Fieldset>
            </Col>
            <Col xs={12}>
              <Fieldset disabled={shouldDisableContractRelatedFields}>
                <InputCropType
                  id="yard-crop-types"
                  name="yard-crop-types"
                  value={crop_types_ids}
                  disabled={shouldDisableContractRelatedFields}
                  onChange={handleChangeCropTypes}
                />
              </Fieldset>
            </Col>
          </Row>
        )}

        {2 === step && (
          <BeeTrackEditBoundariesMap
            t={t}
            isMobile={isMobile}
            geometry={geometry}
            setNewYardGeometry={setNewYardGeometry}
            validateGeometry={validateGeometry}
          />
        )}

        {3 === step && (
          <Row nogutter style={{ padding: '0 1.5rem 3rem' }}>
            <Col style={{ padding: 0 }} xs={12}>
              <FieldNotesPreTag>
                <TextArea
                  id="field_notes"
                  name="field_notes"
                  required
                  value={field_notes}
                  label={t('field_notes')}
                  handleChange={handleChange}
                  type="text"
                />
              </FieldNotesPreTag>
            </Col>
          </Row>
        )}
      </section>

      <footer>
        <div style={{ marginLeft: '1.5rem', left: 0 }}>
          {[1, 2, 3].map((stp, idx) => (
            <StepImg key={idx} active={stp === step && true} />
          ))}
        </div>
        <div>
          <StyledCloseButton type="button" label={t(backButton)} onClick={handleBack} disabled={1 === step} />

          <StyledSubmitButton
            type="button"
            label={t(nextButton)}
            onClick={handleNext}
            disabled={isDisabledBtn || isDisabledNext}
            height="2rem"
          />
        </div>
      </footer>

      {loading && <Loading whiteBackground />}
    </ModalContainer>
  );
};

UpdateYardModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleContractChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleChangeCropTypes: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    yardObj: PropTypes.object.isRequired,
    modalType: PropTypes.string.isRequired,
  }).isRequired,
  passDropdownDataToParent: PropTypes.func.isRequired,
  setNewYardGeometry: PropTypes.func.isRequired,
  validateGeometry: PropTypes.func.isRequired,
  isDisabledNext: PropTypes.bool,
  modalObj: PropTypes.shape({
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    nextButton: PropTypes.string.isRequired,
    backButton: PropTypes.string,
  }),
  isDrop: PropTypes.bool,
  loading: PropTypes.bool,
};
