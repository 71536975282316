import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Mating = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M20.415 4.664C19.426 3.60292 18.0406 3.00037 16.59 3.00037C15.1394 3.00037 13.7541 3.60292 12.765 4.664L12 5.464L12.008 5.472C12.0035 5.46793 11.9966 5.46793 11.992 5.472L12 5.468L11.235 4.668C10.2458 3.60687 8.86023 3.0043 7.40951 3.0043C5.95879 3.0043 4.57324 3.60687 3.58401 4.668C1.47224 6.92969 1.47224 10.4403 3.58401 12.702L4.34901 13.502L4.35801 13.493C4.35546 13.4951 4.35399 13.4982 4.35399 13.5015C4.35399 13.5048 4.35546 13.5079 4.35801 13.51L11.131 20.622C11.3557 20.8631 11.6705 21 12 21C12.3296 21 12.6443 20.8631 12.869 20.622L19.642 13.51C19.6446 13.5079 19.646 13.5048 19.646 13.5015C19.646 13.4982 19.6446 13.4951 19.642 13.493L19.65 13.502L20.415 12.702C22.5307 10.4403 22.5307 6.92566 20.415 4.664Z"
      />
    </IconSVGView>
  );
};
