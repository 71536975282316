import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledPolygonTooltip = styled.div(
  ({ theme }) => `
  position: absolute;
  transform: translate(-100%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: ${theme.animations.transitionFast('opacity')};
  z-index: ${theme.getZIndexOf('tooltip')};
`
);

export const StyledPolygonTooltipBackground = styled.div(
  ({ theme }) => `
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: ${theme.shape.paperRadius01}px;
  background-color: ${theme.colors.grey08};
  opacity: 0.7;
`
);

export const StyledPolygonTooltipContent = styled.div(
  ({ theme }) => `
  position: relative;
  display: flex;
  align-items: center;
  color: ${theme.colors.coreWhite};
  padding: ${theme.spacing.xs}px;
`
);

export const StyledSearchBoxDropdown = styled.div<{ visible: boolean }>(
  ({ theme, visible }) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 14px;
  font-weight: 400;
  font-family: ${theme.font.family};
  background-color: ${theme.colors.coreWhite};
  color: ${theme.colors.grey08};
  border-radius: ${theme.shape.paperRadius01}px;
  border-top: 1px solid ${theme.colors.grey04};
  z-index: ${theme.getZIndexOf('tooltip')};
  box-shadow: ${theme.shadows.boxShadow04};
  transition: ${theme.animations.transitionFast('opacity')};
  transform: translateY(${theme.spacing.xxs}px);
  opacity: ${visible ? 1 : 0};
  pointer-events: ${visible ? 'auto' : 'none'};
  
  strong {
    font-weight: 600;
  } 
  
  img {
    margin-left: auto;
    padding: ${theme.spacing.xxs}px;
  }
`
);

export const StyledSearchBoxDropdownItem = styled.button(
  ({ theme }) => `
  display: block;
  width: 100%;
  height: 48px;
  overflow: hidden;
  padding: 0 ${theme.spacing.xs}px;
  border-radius: ${theme.shape.paperRadius01}px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  & > * {
    margin: ${theme.spacing.xs}px;
  }
  
  &:hover {
    color: ${theme.colors.grey06};
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02};
  }
`
);

export const StyledMapError = styled.div(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.coreBackground};
  `
);
