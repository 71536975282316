import produce from 'immer';

import { CardType } from '@components/yard/MetricCard/type';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

import { YardsMapActionType, YardsMetricActionType, YardsReducer, YardsState } from './types';

const defaultState: YardsState = {
  /** Yards table. */
  list: {
    yards: [],
    pagination: { limit: 0, offset: 0 },
    sorting: { property: 'name', direction: 'asc' },
    isFetching: false,
    fetchError: null,
  },

  /** Yards map. */
  map: {
    yards: [],
    yardDetail: null,
    blocks: [],
    isFetchingYards: false,
    isFetchingYardDetail: false,
    isFetchingBlocks: false,
    fetchYardsError: null,
    fetchYardsDetailError: null,
    fetchBlocksError: null,
  },
  metrics: {
    cards: [],
    isFetching: false,
    fetchError: null,
  },
  trends: {
    data: [{ trend: [], name: 'default' }],
    currentMetricValue: [{ value: 0, previousValue: 0 }],
    isFetching: false,
    fetchError: null,
    metricToQuery: '',
    isMetricModalOpen: false,
    positiveDirection: false,
    cardType: CardType.GLOBAL,
    categories: null,
    start: Date.now(),
  },
};

export const yardsReducer: YardsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case YardsMapActionType.MAP_FETCH_YARDS_START:
        state.map.isFetchingYards = true;
        break;
      case YardsMapActionType.MAP_FETCH_YARDS_FINISH:
        state.map.isFetchingYards = false;
        state.map.fetchYardsError = action.payload.error;
        state.map.yards = action.payload.data;
        break;

      case YardsMapActionType.MAP_FETCH_BLOCKS_START:
        state.map.isFetchingBlocks = true;
        break;
      case YardsMapActionType.MAP_FETCH_BLOCKS_FINISH:
        state.map.isFetchingBlocks = false;
        state.map.fetchBlocksError = action.payload.error;
        state.map.blocks = action.payload.data;
        break;

      case YardsMapActionType.MAP_FETCH_YARD_DETAIL_START:
        state.map.isFetchingYardDetail = true;
        break;
      case YardsMapActionType.MAP_FETCH_YARD_DETAIL_FINISH:
        state.map.isFetchingYardDetail = false;
        state.map.fetchBlocksError = action.payload.error;
        state.map.yardDetail = action.payload.data;

        // Use the downloaded detail to update the yard in the list.
        const yardFromAPI = action.payload.data;
        const yard = state.map.yards.find((y) => y.id === yardFromAPI?.id) as BeeYardOnMapInfo;

        if (yard && yardFromAPI) {
          yard.name = yardFromAPI.name;
          yard.yardCenter = yardFromAPI.yardCenter;
          yard.geometry = yardFromAPI.geometry;
          yard.hivesPosition = yardFromAPI.hivesPosition;
          yard.nbHives = yardFromAPI.nbHives;
        }

        break;
      case YardsMetricActionType.METRIC_FETCH_AVAILABILITY_START:
        state.metrics.isFetching = true;
        break;
      case YardsMetricActionType.METRIC_FETCH_AVAILABILITY_FINISH:
        state.metrics.isFetching = false;
        state.metrics.fetchError = action.payload.error;
        state.metrics.cards = action.payload.cards;
        break;
      case YardsMetricActionType.METRIC_FETCH_VALUE_START:
        state.metrics.cards = state.metrics.cards.map((stateCard) => {
          stateCard.isFetching = true;
          return stateCard;
        });
        break;
      case YardsMetricActionType.METRIC_FETCH_VALUE_FINISH:
        state.metrics.isFetching = false;
        state.metrics.fetchError = action.payload.error;

        state.metrics.cards = state.metrics.cards.map((stateCard) => {
          const newCard = action.payload.cards.find((card) => stateCard.metricName == card.metricName);
          return newCard ? newCard : stateCard;
        });
        break;
      case YardsMetricActionType.OPEN_METRIC_MODAL:
        state.trends.metricToQuery = action.payload.metricName;
        state.trends.currentMetricValue = action.payload.metricValue;
        state.trends.isMetricModalOpen = true;
        state.trends.positiveDirection = action.payload.positiveDirection;
        state.trends.cardType = action.payload.cardType;
        state.trends.categories = action.payload.categories;
        state.trends.start = Date.now();
        break;
      case YardsMetricActionType.CLOSE_METRIC_MODAL:
        const trendModalOpenStop = Date.now();
        const elapsed = (trendModalOpenStop - state.trends.start) / 1000;
        Analytics.sendEvent({
          event: AnalyticsEventType.METRIC_CHART_EXIT,
          eventData: { metric: state.trends.metricToQuery, elapsed },
        });

        state.trends.isMetricModalOpen = false;
        state.trends.metricToQuery = '';
        break;

      case YardsMetricActionType.METRIC_FETCH_TREND_START:
        state.trends.isFetching = true;
        break;
      case YardsMetricActionType.METRIC_FETCH_TREND_FAILURE:
        state.trends.isFetching = false;
        state.trends.isMetricModalOpen = false;
        break;
      case YardsMetricActionType.METRIC_FETCH_TREND_FINISH:
        state.trends.isFetching = false;
        state.trends.fetchError = action.payload.error;
        state.trends.data = action.payload.data;
        break;
    }
    return state;
  });
};
