import React from 'react';
// vendor:
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withGetScreen } from '@HOC/withGetScreen';
// nectar:
import theme from '@style/theme';

const StyledContainer = styled.div`
  margin-top: ${(props) => props.marginTop}px;
`;
export const Container = ({ children, isMobile, isTablet, content, ...others }) => {
  const { view } = content;
  let marginTop;
  const copiedProps = {};

  Object.keys(others).forEach((key) => {
    // these are the props that need to get thru:
    if ('style' === key || 'className' === key) {
      copiedProps[key] = others[key];
    }
  });

  switch (view) {
    case 'yard-beetrack':
    case 'yard-sensor':
      marginTop = isMobile() || isTablet() ? theme.tabBarHeight * 2 : theme.topBarHeight + theme.tabBarHeight;
      break;
    case 'summary':
    case 'list-sensor':
    case 'map-sensor':
      marginTop = isTablet() ? theme.topBarHeight * 2 : isMobile() ? theme.tabBarHeight * 2 : theme.topBarHeight;
      break;
    case 'alerts':
      marginTop = theme.tabBarHeight;
      break;
    case 'workers':
    case 'account':
      marginTop = isTablet() ? theme.topBarHeight : isMobile() ? theme.tabBarHeight : theme.topBarHeight;
      break;
    default:
      // default is list and map beetrack views
      marginTop = isMobile() || isTablet() ? theme.tabBarHeight * 2 : theme.topBarHeight;
      break;
  }

  return (
    <StyledContainer {...copiedProps} marginTop={marginTop}>
      {children}
    </StyledContainer>
  );
};

const mapStateToProps = (state) => ({
  content: state.navBarTitleReducer.content,
});

Container.propTypes = {
  isMobile: PropTypes.func.isRequired,
  isTablet: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps, null)(withGetScreen()(Container));
