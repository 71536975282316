import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Collapse = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" size={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.662 3h5.324a2.662 2.662 0 0 1 2.662 2.662v13.31a2.662 2.662 0 0 1-2.662 2.662H6.662A2.662 2.662 0 0 1 4 18.972V5.662A2.662 2.662 0 0 1 6.662 3Zm0 1.996a.666.666 0 0 0-.666.666v13.31c0 .367.298.665.666.665h5.324a.665.665 0 0 0 .665-.665V5.662a.665.665 0 0 0-.665-.666H6.662Z"
        fill={useColor(color)}
      />
      <path
        d="M20.734 7.143a.907.907 0 0 1 0 1.284l-3.896 3.895 3.896 3.896a.908.908 0 0 1-1.283 1.283l-4.537-4.537a.908.908 0 0 1 0-1.283l4.537-4.538a.907.907 0 0 1 1.283 0Z"
        fill={useColor(color)}
      />
    </IconSVGView>
  );
};
