import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ChevronDown = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M17.707 9.29303C17.3165 8.90265 16.6835 8.90265 16.293 9.29303L12 13.586L7.70701 9.29303C7.31463 8.91406 6.69093 8.91948 6.30519 9.30521C5.91946 9.69095 5.91404 10.3147 6.29301 10.707L11.293 15.707C11.6835 16.0974 12.3165 16.0974 12.707 15.707L17.707 10.707C18.0974 10.3165 18.0974 9.68353 17.707 9.29303V9.29303Z"
      />
    </IconSVGView>
  );
};
