import APP from '@config/constants';
import { containsAtLeastCharacters } from '@regex/containsAtLeastCharacters';

export const verifyPasswordInput = (newPassword, confirmationPassword) => {
  let error = false;

  if (newPassword && confirmationPassword && newPassword !== confirmationPassword) {
    error = 'change_password_match';
  }

  if (
    !containsAtLeastCharacters(APP.passwordMinimum, newPassword) ||
    !containsAtLeastCharacters(APP.passwordMinimum, confirmationPassword)
  ) {
    error = 'change_password_twelve_characters';
  }

  return error;
};
