import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Feeding, Treatment } from '@components/common/Icon/presets';
import { Deadout } from '@components/common/Icon/presets/Deadout';
import { HiveDefault } from '@components/common/Icon/presets/HiveDefault';
import { Introduced } from '@components/common/Icon/presets/Introduced';
import { Queenless } from '@components/common/Icon/presets/Queenless';
import { Queenright } from '@components/common/Icon/presets/Queenright';
import { Line } from '@components/common/Line';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import {
  StyledSummary,
  StyledSummaryItem,
  StyledSummaryValueText,
  StyledSummaryValueTitle,
} from '@components/yard/YardDetailsDashboard/styles';
import { useActionCategoriesFetcher, useActionsNames } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';

export const YardDetailsDashboardSummary: React.VFC = () => {
  const t = useTranslation();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const { isFetching: isFetchingCropTypes } = useActionCategoriesFetcher();

  const actionNames = useActionsNames();

  if (!yard) {
    return null;
  }

  return (
    <StyledSummary column stretch>
      <Box column paddingVerticalSM paddingHorizontalMD gapXXS>
        <Text typography={'Heading3'} weight={'600'}>
          {t('summary')}
        </Text>
      </Box>
      <Line />
      <Box column stretch paddingVerticalSM paddingHorizontalMD gapSM>
        <StyledSummaryItem>
          <HiveDefault />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('Hives')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>{yard.nbHives ?? '—'}</StyledSummaryValueText>
        </StyledSummaryItem>

        <StyledSummaryItem>
          <Queenright />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('queenright')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>—</StyledSummaryValueText>
        </StyledSummaryItem>
        <StyledSummaryItem>
          <Introduced />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('introduced')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>{yard.nbQueenrightHives ?? '—'}</StyledSummaryValueText>
        </StyledSummaryItem>
        <StyledSummaryItem>
          <Queenless />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('queenless')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>{yard.nbQueenlessHives ?? '—'}</StyledSummaryValueText>
        </StyledSummaryItem>
        <StyledSummaryItem>
          <Deadout />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('deadouts')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>{yard.nbDeadoutSeason ?? '—'}</StyledSummaryValueText>
        </StyledSummaryItem>

        <StyledSummaryItem>
          <Feeding />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('last_feeding')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>
            {yard.lastFeedingAction ? actionNames[yard.lastFeedingAction] : '—'}
          </StyledSummaryValueText>
        </StyledSummaryItem>
        <StyledSummaryItem>
          <Treatment />
          <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
            {t('last_treatment')}
          </StyledSummaryValueTitle>
          <StyledSummaryValueText typography={'Heading3'}>
            {yard.lastTreatmentAction ? actionNames[yard.lastTreatmentAction] : '—'}
          </StyledSummaryValueText>
        </StyledSummaryItem>
      </Box>

      <LoadingSkeleton visible={isFetchingCropTypes} backgroundColor={'coreWhite'} absolutelyFitParent>
        <LoadingSkeletonRectangle width={'100%'} height={'100%'} />
      </LoadingSkeleton>
    </StyledSummary>
  );
};
