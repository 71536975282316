import React from 'react';

import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { LoadingSkeletonProps } from '@components/common/LoadingSkeleton/types';

export const LoadingCell: React.VFC<LoadingSkeletonProps> = ({ ...props }) => {
  return (
    <LoadingSkeleton
      absolutelyFitParent
      backgroundColor={'coreWhite'}
      paddingHorizontalSM
      column
      stretch
      center
      {...props}
    >
      <LoadingSkeletonRectangle width={'80%'} height={'55%'} backgroundColor={'grey02'} />
    </LoadingSkeleton>
  );
};
