import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, IHeaderParams } from 'ag-grid-community';

import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { Hide } from '@components/common/Icon/presets/Hide';
import { History } from '@components/common/Icon/presets/History';
import { Menu as MenuIcon } from '@components/common/Icon/presets/Menu';
import { Show } from '@components/common/Icon/presets/Show';
import { Menu } from '@components/common/Menu';
import { MenuItem, MenuProps } from '@components/common/Menu/types';
import {
  DEF_PERMANENT_COLUMNS_KEYS,
  DEF_PERMANENT_LEFT_COLUMNS,
  DEF_PERMANENT_RIGHT_COLUMNS,
} from '@components/yard/YardsList/constants';
import {
  useColumnNameForAnalytics,
  useColumnNameGetterForAnalytics,
} from '@components/yard/YardsList/hooks/useColumnNameGetterForAnalytics';
import { ColumnType, ViewKey } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useAnimationLoop } from '@hooks/useAnimationLoop';

const DEF_BORDER_PROXIMITY_TOLERANCE = 360;
const DEF_MENU_OFFSET: [number, number] = [24, 6];

export interface YardsListHeaderMenuProps extends IHeaderParams {
  target: string;
}

export const YardsListHeaderMenu: React.VFC<YardsListHeaderMenuProps> = ({
  api: gridApi,
  columnApi,
  column,
  target,
}) => {
  const [menuPositionProps, setMenuPositionProps] = useState<Partial<MenuProps>>({});
  const menuAnchorRef = useRef<HTMLSpanElement>(null);

  const { t } = useTranslation();
  const columnDef = GridApiUtil.getColumnDefWithMetadata(column.getColDef());
  const columnNameForAnalytics = useColumnNameForAnalytics(columnDef);
  const getColumnNameForAnalytics = useColumnNameGetterForAnalytics();

  const sendAnalyticsEvent = useCallback(() => {
    Analytics.sendEvent({
      event: AnalyticsEventType.WHITEBOARD_COLUMNS_CHANGE,
      eventData: {
        origin: 'header-menu',
        visible_columns: GridApiUtil.getNonAbstractVisibleColumnDefs(gridApi.getColumnDefs())
          .map(getColumnNameForAnalytics)
          .join(', '),
      },
    });
  }, [getColumnNameForAnalytics, gridApi]);

  const handleAutoResize = useCallback(
    (columnId?: string) => {
      if (columnId) {
        columnApi.autoSizeColumn(columnId);
      } else {
        const resizableColumnKeys = GridApiUtil.getFlattenColumnDefs(gridApi.getColumnDefs())
          .filter((def) => !def.suppressAutoSize)
          .map((def) => def.colId as string);
        columnApi.autoSizeColumns(resizableColumnKeys);
      }
      sendAnalyticsEvent();
    },
    [columnApi, gridApi, sendAnalyticsEvent]
  );

  const handleViewSelected = useCallback(
    (viewKey: ViewKey) => {
      const columnDefs = gridApi.getColumnDefs() ?? [];
      const nextColumnDef = {
        colId: columnDef.colId,
        metadata: { ...columnDef.metadata, activeView: viewKey },
      } as ColDef;
      const patched = GridApiUtil.getPatchedColumnDefsUsingId(columnDefs, nextColumnDef);
      const didChange = GridApiUtil.updateGridColumns(gridApi, patched);
      if (didChange && column.isSorting()) {
        gridApi.refreshInfiniteCache();
      }
    },
    [column, columnDef.colId, columnDef.metadata, gridApi]
  );

  const handleMoveColumn = useCallback(
    (offset: number) => {
      const columnDefs = GridApiUtil.getColumnDefsWithMetadata(
        GridApiUtil.getFlattenColumnDefs(gridApi.getColumnDefs())
      );
      const currentColumnIndex = columnDefs.findIndex((cd) => cd.colId === columnDef.colId);

      let nextIndex = currentColumnIndex + offset;
      let hadSeenVisible = false;

      while (
        columnDefs[nextIndex]?.hide ||
        (columnDef.metadata?.column?.type === ColumnType.DEFAULT &&
          columnDefs[nextIndex]?.metadata?.column?.type !== ColumnType.DEFAULT)
      ) {
        hadSeenVisible = hadSeenVisible || !columnDefs[nextIndex]?.hide;
        nextIndex += offset;
      }

      if (hadSeenVisible) {
        nextIndex -= offset;
      }

      if (columnDefs[nextIndex]) {
        columnApi.moveColumnByIndex(currentColumnIndex, nextIndex);
        gridApi.refreshHeader();
      }

      sendAnalyticsEvent();
    },
    [columnApi, columnDef.colId, columnDef.metadata?.column?.type, gridApi, sendAnalyticsEvent]
  );

  const handleHide = useCallback(() => {
    const patched = GridApiUtil.getPatchedColumnDefsUsingId(gridApi.getColumnDefs() ?? [], {
      colId: columnDef.colId,
      hide: true,
    });
    GridApiUtil.updateGridColumns(gridApi, patched);
    sendAnalyticsEvent();
  }, [columnDef.colId, gridApi, sendAnalyticsEvent]);

  const views = useMemo<Array<MenuItem>>(() => {
    const column = GridApiUtil.getColumnDefWithMetadata(columnDef).metadata?.column;
    const views = column?.views ?? {};
    const activeView = GridApiUtil.getActiveView(columnDef);

    const items = [
      {
        key: 'occurrencesSinceSeasonStart',
        title:
          column?.type === ColumnType.PRACTICE
            ? t('view_number_of_occurrences_since_season_start')
            : t('view_occurrences_since_season_start'),
      },
      { key: 'lastOccurrenceDatetime', title: t('view_last_occurrence') },
      { key: 'periodSinceLastOccurrence', title: t('view_period_since_last_occurrence') },
      { key: 'workerLastOccurrence', title: t('view_worker_last_occurrence') },
    ];
    return items
      .filter((item) => item.key in views)
      .map((item) => ({
        title: item.title,
        checked: item.key === activeView?.key,
        onClick: () => {
          handleViewSelected(item.key);

          Analytics.sendEvent({
            event: AnalyticsEventType.WHITEBOARD_COLUMN_VIEW_CHANGE,
            eventData: {
              column: columnNameForAnalytics,
              column_view: item.key,
              column_type: columnDef.metadata.column.type,
            },
          });
        },
      }));
  }, [columnDef, columnNameForAnalytics, handleViewSelected, t]);

  const menuItems = useMemo<Array<MenuItem>>(() => {
    const items: Array<MenuItem> = [];

    if (views.length) {
      items.push({
        title: t('column_view'),
        icon: Show,
        subItems: views,
      });
    }

    const columnsDefs = GridApiUtil.getFlattenColumnDefs(gridApi.getColumnDefs());
    const visibleColumnDefs = columnsDefs.filter((cd) => !cd.hide);
    const currentColumnIndex = visibleColumnDefs.findIndex((cd) => cd.colId === columnDef.colId);
    const prevColumnDef = GridApiUtil.getColumnDefWithMetadata(visibleColumnDefs[currentColumnIndex - 1]);
    const nextColumnDef = GridApiUtil.getColumnDefWithMetadata(visibleColumnDefs[currentColumnIndex + 1]);
    const canMoveLeft =
      currentColumnIndex > DEF_PERMANENT_LEFT_COLUMNS.length && columnDef.metadata?.column?.type === ColumnType.DEFAULT
        ? !!prevColumnDef
        : prevColumnDef?.metadata?.groupId === columnDef.metadata?.groupId;
    const canMoveRight =
      currentColumnIndex < visibleColumnDefs.length - DEF_PERMANENT_RIGHT_COLUMNS.length - 1 &&
      currentColumnIndex >= DEF_PERMANENT_LEFT_COLUMNS.length &&
      columnDef.metadata?.column?.type === ColumnType.DEFAULT
        ? !!nextColumnDef
        : nextColumnDef?.metadata?.groupId === columnDef.metadata?.groupId;
    const canRemoveColumn = !DEF_PERMANENT_COLUMNS_KEYS.includes(columnDef.field);

    if (canMoveLeft || canMoveRight) {
      items.push(
        {
          title: t('move_to_the_left'),
          group: 'move',
          icon: ArrowLeft,
          disabled: !canMoveLeft,
          onClick: () => handleMoveColumn(-1),
        },
        {
          title: t('move_to_the_right'),
          group: 'move',
          icon: ArrowRight,
          disabled: !canMoveRight,
          onClick: () => handleMoveColumn(1),
        }
      );
    }

    items.push(
      {
        title: t('autosize_this_column'),
        group: 'autosize',
        icon: History,
        onClick: () => handleAutoResize(columnDef.colId),
      },
      {
        title: t('autosize_all_columns'),
        group: 'autosize',
        icon: History,
        onClick: () => handleAutoResize(),
      }
    );

    if (canRemoveColumn) {
      items.push({
        title: t('remove_column'),
        group: 'remove',
        icon: Hide,
        disabled: !canRemoveColumn,
        onClick: () => handleHide(),
      });
    }

    return items;
  }, [
    columnDef.colId,
    columnDef.field,
    columnDef.metadata?.column?.type,
    columnDef.metadata?.groupId,
    gridApi,
    handleAutoResize,
    handleHide,
    handleMoveColumn,
    t,
    views,
  ]);

  const updateMenuPositionProps = useCallback(() => {
    const anchorElement = menuAnchorRef.current;

    if (anchorElement) {
      const menuAnchorRect = anchorElement.getBoundingClientRect();
      const distanceFromRightBorder = window.innerWidth - menuAnchorRect.right;
      const isMenuAtVeryRight = distanceFromRightBorder < DEF_BORDER_PROXIMITY_TOLERANCE;

      const nextProps: Partial<MenuProps> = {
        subMenuProps: {
          placement: isMenuAtVeryRight ? 'left-start' : 'right-start',
        },
      };

      setMenuPositionProps((current) => {
        if (current.subMenuProps?.placement !== nextProps.subMenuProps?.placement) {
          return { ...current, ...nextProps };
        }
        return current;
      });
    }
  }, []);

  useAnimationLoop(updateMenuPositionProps);

  return (
    <span ref={menuAnchorRef}>
      <MenuIcon size={16} />
      <Menu
        items={menuItems}
        target={target}
        placement={'bottom-end'}
        offset={DEF_MENU_OFFSET}
        {...menuPositionProps}
      />
    </span>
  );
};
