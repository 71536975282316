import React, { useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import Tippy, { Instance } from 'tippy.js';

import { TooltipView } from '@components/common/Tooltip/styles';
import { TooltipProps, TooltipRef } from '@components/common/Tooltip/types';
import { QueryParams } from '@helpers/QueryParams';
import { useQueryParamState } from '@helpers/QueryParams/hooks';

import 'tippy.js/animations/scale.css';

const DEFERRED_RENDERING_DELAY = 800;

export const Tooltip = React.forwardRef<TooltipRef, React.PropsWithChildren<TooltipProps>>(
  (
    {
      target,
      appendTo,
      appendToTarget,
      arrow,
      light,
      disabled,
      interactive,
      hideOnClick,
      trigger,
      renderContent,
      className,
      children,

      placement,
      offset,
      onShown,
    },
    ref
  ) => {
    const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
    const [appendToElement, setAppendToElement] = useState<HTMLElement | null>(null);

    const instance = useRef<Instance | null>(null);
    const rootRef = useRef<HTMLDivElement>(null);
    const tooltipVirtualWrapper = useMemo(() => document.createElement('div'), []);

    // Helper for the design team. If ?sticky-tooltips=true is present in the
    // url query params, all tooltips will appear and keep sticky.
    const enableStickyTooltips = useRef(QueryParams.getCurrentQueryParams()['sticky-tooltips']);

    const updateTargets = useCallback(() => {
      const parentElement = rootRef.current?.parentElement;

      const targetElement = target ? document.getElementById(target) : parentElement;
      setTargetElement(targetElement ?? null);

      const appendToElement = (appendToTarget ? targetElement : document.getElementById(appendTo)) || document.body;
      setAppendToElement(appendToElement);
    }, [appendTo, appendToTarget, target]);

    const createTooltip = useCallback(() => {
      if (targetElement && appendToElement) {
        instance.current = Tippy(targetElement, {
          appendTo: appendToElement,
          content: tooltipVirtualWrapper,
          allowHTML: true,
          animation: 'fade',
          trigger: trigger ?? (interactive ? 'click focus' : 'mouseenter focus'),
          interactive,
          placement,
          offset,
          onShown,
        });

        if (enableStickyTooltips.current) {
          const props = { ...instance.current?.props };
          const _onShown = (args: any) => {
            onShown && onShown(args);
            instance.current?.setProps({
              interactive: true,
              trigger: 'click',
            });
          };
          const _onHidden = () => {
            instance.current?.setProps({ ...props, onShown: _onShown, onHidden: _onHidden });
          };
          instance.current?.setProps({
            onShown: _onShown,
            onHidden: _onHidden,
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appendToElement, interactive, offset, placement, targetElement, tooltipVirtualWrapper, trigger]);

    const handleTipClick = useCallback(() => {
      if (hideOnClick) {
        instance.current?.hide();
      }
    }, [hideOnClick]);

    useLayoutEffect(() => {
      if (!disabled) {
        const handler = () => handleTipClick();
        createTooltip();
        tooltipVirtualWrapper.addEventListener('click', handler);
        return () => {
          tooltipVirtualWrapper.removeEventListener('click', handler);
          instance.current?.destroy();
          instance.current = null;
        };
      }
    }, [createTooltip, disabled, handleTipClick, tooltipVirtualWrapper]);

    useLayoutEffect(() => {
      const timeout = setTimeout(updateTargets, DEFERRED_RENDERING_DELAY);
      return () => clearTimeout(timeout);
    }, [updateTargets]);

    useImperativeHandle(
      ref,
      () => ({
        hide: () => instance.current?.hide(),
      }),
      [instance]
    );

    const content = useMemo(
      () =>
        renderContent ? (
          renderContent(children)
        ) : (
          <TooltipView arrow={arrow} light={light} className={className}>
            {children}
          </TooltipView>
        ),
      [renderContent, children, arrow, light, className]
    );

    return (
      <span ref={rootRef} className="tooltip-anchor">
        {ReactDom.createPortal(content, tooltipVirtualWrapper)}
      </span>
    );
  }
);

Tooltip.displayName = 'Tooltip';
