import produce from 'immer';

import * as actionTypes from '../actionTypes';

const initialState = {
  isFetching: false,
};

export const recreatePasswordReducer = (state = initialState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.RECREATE_PASSWORD_START:
        st.isFetching = true;
        break;
      case actionTypes.RECREATE_PASSWORD_SUCCESS:
        st.isFetching = false;
        break;
      case actionTypes.RECREATE_PASSWORD_FAILURE:
        st.isFetching = false;
        break;
      default:
    }
  });
};
