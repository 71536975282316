import React from 'react';

import { Box } from '@components/common/Box';

export const PagePaddingWrapper: React.FC = ({ children }) => {
  return (
    <Box paddingHorizontalLG block>
      {children}
    </Box>
  );
};
