import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

import { GlobalSearch } from '../GlobalSearch';

const HEADER_HEIGHT = 50;

const StyledHeader = styled.header(
  ({ theme }) => css`
    background-color: ${theme.colors.grey08};
    height: ${HEADER_HEIGHT}px;
    position: sticky;
    top: 0;
    width: 100%;
    padding: ${theme.spacing.xs}px ${theme.spacing.md}px;
    z-index: ${theme.getZIndexOf('headerNav')};
  `
);

export const SiteHeader: React.VFC = () => {
  return (
    <StyledHeader>
      <Box alignItems="center" justifyContent="space-between">
        <Text typography="CaptionSmall" color="coreWhite" weight="600">
          {/*
          todo: add breadcrumbs
          WhiteBoard
          */}
        </Text>
        <GlobalSearch />
      </Box>
    </StyledHeader>
  );
};
