/**
 * Helper intended to improve the map rendering performance.
 * It works by pre-computing the given map elements and then
 * and returning which elements are visible.
 * */
import { MapOptimizerUtil } from '@helpers/MapOptimizer/util';

import { MapTileMatrix } from './MapTileMatrix';
import { MapBounds, MapElement, MapTile } from './types';

/** How many tile sizes to generate. */
const TILE_SIZES_COUNT = 19;

/**
 * Value used to subdivide the sequence of tile sizes.
 * */
const TILE_SIZES_DIVIDER = 2;

/** Size of the biggest tile. */
const MAX_TILE_SIZE = 360;

/**
 * Tile sizes are a sequence of subdivisions of MAX_TILE_SIZE, where
 * the next number is a half of the previous one. E.g: 90, 45, 22.5, etc...
 * */
const TILE_SIZES = new Array(TILE_SIZES_COUNT)
  .fill(null)
  .map((_, i) => MAX_TILE_SIZE / Math.pow(TILE_SIZES_DIVIDER, i));

export class MapSmartBounds<P> {
  /**
   * Maps each map element for its current tiles.
   * This will make it easy to remove or update
   * each element.
   * */
  private mapTilesByElement: Record<any, Array<MapTile<P>>> = {};

  /** Maps each tile size for its matrix. */
  private mapTileMatrixes: Record<number, MapTileMatrix<P>> = {};

  /** If true, enables debug logs. */
  private readonly debug: boolean = false;

  constructor(elements?: Array<MapElement<P>>, debug = false) {
    this.debug = debug;

    this.debugTime('constructor');
    elements?.forEach((e) => this.addElement(e));
    this.debugTimeEnd('constructor');
  }

  /**
   * Returns all elements within the given map bounds.
   * */
  public getVisibleElements(mapBounds: MapBounds): Record<any, MapElement<P>> {
    this.debugTime('getVisibleElements');
    const visibleTiles = this.getClosestVisibleTiles(mapBounds);

    const visibleElements = {} as Record<any, MapElement<P>>;
    for (const { elements } of visibleTiles) {
      Object.values(elements).forEach((element) => (visibleElements[element.id] = element));
    }

    this.debugTimeEnd('getVisibleElements');
    this.debugLog(`getVisibleElements: ${Object.keys(visibleElements).length} visible.`);
    return visibleElements;
  }

  /**
   * Returns all tiles within the given map bounds.
   * */
  public getClosestVisibleTiles(mapBounds: MapBounds) {
    const tileSize = this.getClosestTileSize(mapBounds);
    return this.getVisibleTiles(mapBounds, tileSize);
  }

  /**
   * Classifies the given element into the right matrix position
   * for each tile size. Existing elements are removed to avoid
   * duplicates.
   * */
  public addElement(element: MapElement<P>): void {
    this.removeElement(element);
    TILE_SIZES.forEach((tileSize) => {
      const matrix = this.getTileMatrix(tileSize);

      const tile = matrix.getAtLatLng(element.lat, element.lng);
      tile.elements[element.id] = element;

      if (!this.mapTilesByElement[element.id]) {
        this.mapTilesByElement[element.id] = [];
      }
      this.mapTilesByElement[element.id].push(tile);
    });
  }

  /**
   * Removes the given element from the matrix.
   * */
  public removeElement(element: MapElement<P>): void {
    if (this.mapTilesByElement[element.id]) {
      const tiles = this.mapTilesByElement[element.id];
      tiles.forEach(({ elements }) => delete elements[element.id]);
      delete this.mapTilesByElement[element.id];
    }
  }

  private getVisibleTiles(mapBounds: MapBounds, tileSize: number): Array<MapTile<P>> {
    const visibleTiles = [] as Array<MapTile<P>>;
    const boundsList = MapOptimizerUtil.getNormalizedBounds(mapBounds);

    for (const bounds of boundsList) {
      const { north, south, east, west } = bounds;
      const topmostTile = Math.floor(north / tileSize);
      const bottommostTile = Math.floor(south / tileSize);
      const leftmostTile = Math.floor(Math.min(west, east) / tileSize);
      const rightmostTile = Math.floor(Math.max(west, east) / tileSize);
      const matrix = this.getTileMatrix(tileSize);

      for (let i = bottommostTile; i <= topmostTile; i++) {
        for (let j = leftmostTile; j <= rightmostTile; j++) {
          visibleTiles.push(matrix.getAtIJ(i, j));
        }
      }
    }

    return visibleTiles;
  }

  private getClosestTileSize(mapBounds: MapBounds): number {
    const { north, south, east, west } = mapBounds;
    const boundsGap = Math.max(Math.abs(north - south), Math.abs(east - west));
    const closestTileSizes = [...TILE_SIZES].sort((a, b) => Math.abs(a - boundsGap) - Math.abs(b - boundsGap));
    return closestTileSizes[0];
  }

  private getTileMatrix(tileSize: number): MapTileMatrix<P> {
    if (!this.mapTileMatrixes[tileSize]) {
      this.mapTileMatrixes[tileSize] = new MapTileMatrix(tileSize);
    }
    return this.mapTileMatrixes[tileSize];
  }

  private debugLog(tag: string): void {
    this.debug && console.log(`MapSmartBounds: ${tag}`);
  }

  private debugTime(tag: string): void {
    this.debug && console.time(`MapSmartBounds: ${tag}`);
  }

  private debugTimeEnd(tag: string): void {
    this.debug && console.timeEnd(`MapSmartBounds: ${tag}`);
  }
}
