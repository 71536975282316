import React from 'react';
import PropTypes from 'prop-types';

import illustration from '@assets/illu.png';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';

export const BeeTrackLegendModalView = ({ t, isMobile }) => (
  <div>
    <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
      {t('yard_legend')}
    </StyledHeading2>

    <StyledParagraph style={{ marginBottom: '1.5rem' }}>{t('hht_yard_legend_description')}</StyledParagraph>

    <img style={{ width: '482px' }} src={illustration} alt="legend-illustration" />
  </div>
);

BeeTrackLegendModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
