import React from 'react';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import APP from '@config/constants';
// nectar
import AlertsCommon from '@scenes/admin/Alerts/components/AlertsCommon';

const ScrollableContainer = styled.div`
  position: fixed;
  right: 2%;
  top: ${(props) => props.theme.tabBarHeight}px;
  height: fit-content;
  max-height: 700px;
  max-width: 320px;
  width: 100%;
  overflow-x: hidden;
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.boxShadow3};

  &::-webkit-scrollbar {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: white;
  }
`;
const Header = styled.header`
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.alertsColor};
  border-radius: 3px 3px 0 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  font-size: ${(props) => props.theme.heading3FontSize};
`;

const AlertsSpan = styled.span`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  line-height: 10px;
  margin-left: 20px;
`;

const SettingsLink = styled(HashLink)`
  color: rgba(255, 255, 255, 0.6);
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: 0;
  line-height: 10px;
  margin-right: 30px;
  margin-left: auto;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

class AlertsDropdown extends React.Component {
  render() {
    const { t, show, setRef } = this.props;

    return (
      <ScrollableContainer ref={setRef} style={{ display: show ? 'unset' : 'none' }}>
        <Header>
          <AlertsSpan>{t('activity')}</AlertsSpan>
          <SettingsLink
            smooth
            role="navigation"
            to={`${APP.routes.settings}?tab=notifications`}
            key={APP.routes.settings}
          >
            {t('settings')}
          </SettingsLink>
        </Header>

        <AlertsCommon t={t} isMobile={false} alertView="dropdown" />
      </ScrollableContainer>
    );
  }
}

AlertsDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setRef: PropTypes.object.isRequired,
};

export default AlertsDropdown;
