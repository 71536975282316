import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { ContentTransition } from '@components/common/ContentTransition';
import { MapPane } from '@components/common/Map';
import { Text } from '@components/common/Text';
import { Animation } from '@helpers/Animation';

export const StyledPane = styled(MapPane).attrs({
  width: 355,
})``;

export const StyledPaneScroll = styled(Box).attrs({ id: 'yard-details-pane-scroll', column: true, stretch: true })`
  flex: 1;
  position: relative;
  overflow: hidden auto;
`;

export const StyledContentTransition = styled(ContentTransition).attrs({
  animationType: 'focus',
  animationDuration: 'fast',
  animationAnchorSelector: '#yard-details-pane-scroll',
  column: true,
  stretch: true,
})`` as typeof ContentTransition;

export const StyledHeader = styled(Box)(
  ({ theme }) => `
  align-items: center;
  padding-left: ${theme.spacing.sm}px;
  border-bottom: ${theme.colors.grey03} 1px solid;
  
  h3 {
    display: flex;
    flex: 1;
  }
`
);

export const StyledHeaderCloseButton = styled(PrimaryButton).attrs({ flat: true })(
  ({ theme }) => `
  padding: ${theme.spacing.sm}px;
`
);

export const StyledYardTitle = styled(Box)(
  ({ theme }) => `
  height: 125px;
  align-items: center;
  padding: ${theme.spacing.sm}px;
  background-color: ${theme.colors.orange02};
`
);

export const StyledSection = styled(Box).attrs({ column: true, alignItems: 'stretch' })<{ $last?: boolean }>(
  ({ theme, $last }) => `
  padding: ${theme.spacing.md}px ${theme.spacing.sm}px ${theme.spacing.xs}px ${theme.spacing.sm}px;
  
  ${!$last ? `border-bottom: ${theme.colors.grey04} 1px solid;` : ''}
`
);

export const StyledSectionHeader = styled(Text).attrs({
  typography: 'CaptionSmall',
  weight: '600',
  color: 'grey06',
})(
  ({ theme }) => `
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: ${theme.spacing.xs}px;
`
);

export const StyledSectionSubHeader = styled(Text).attrs({
  typography: 'Heading3',
  weight: '600',
})(
  ({ theme }) => `
  white-space: nowrap;
  margin-right: ${theme.spacing.sm}px;
`
);

export const StyledSectionValue = styled(Text).attrs({
  typography: 'Heading3',
  htmlTag: 'p',
  weight: '400',
})(() => ``);

export const StyledSectionRow = styled(Box)(
  ({ theme }) => `
  align-items: flex-start;
  justify-content: space-between;
  padding: ${theme.spacing.sm}px 0;
  
  &:not(:last-child) {
    border-bottom: ${theme.colors.grey04} 1px solid;
  }
  
  svg {
    margin-right: ${theme.spacing.xs}px;
  }
`
);

export const StyledFooter = styled(Box)(
  ({ theme }) => `
  border-top: ${theme.colors.grey03} 1px solid;
  padding: ${theme.spacing.sm}px;
`
);

export const StyledFooterButton = styled(PrimaryButton)(
  ({ theme, flat }) => `
  flex: 1;
  padding: ${theme.spacing.sm}px;
  
  border: ${flat ? `${theme.colors.grey08} 1px solid` : 'none'};
  
  &:first-child {
    margin-right: ${theme.spacing.xs}px;
  }
`
);

export const StyledLoaderWrapper = styled.div(
  () => `
  ${Animation.createCSSKeyframes('yard-pane-loader-fade', (progress) => `opacity: ${progress}`)}
  animation: yard-pane-loader-fade 400ms linear 0ms 1;
`
);
