import { ENDPOINTS } from '@config/api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';

import * as types from '../actionTypes';

import { makeUnauthorizedFetchFailureAction } from './index';
import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string, status: "alive" | "deadout"}}
 *
 */
export const makeHiveListRequestStartAction = (status) => ({
  type: types.FETCH_HHT_HIVES_ACTION_START,
  status,
});

/**
 *
 * @returns {{type: string, hiveList: array, id: string, status: "alive" | "deadout", actionList: array}}
 *
 */
export const makeFetchHiveListRequestSuccessAction = (hiveList, id, status, actionList) => ({
  type: types.FETCH_HHT_HIVES_ACTION_SUCCESS,
  hiveList,
  id,
  status,
  actionList,
});

/**
 * @returns {{type: string, status: "alive" | "deadout"}}
 */
export const makeHiveListRequestFailureAction = (status) => ({
  type: types.FETCH_HHT_HIVES_ACTION_FAILURE,
  status,
});

/**
 * @returns {{type: string, status: "alive" | "deadout", order_by: string, order_direction: string}}
 */
export const handleSortHiveList = (status, order_by, order_direction) => ({
  type: types.HANDLE_SORT_HIVE_LIST,
  status,
  order_by,
  order_direction,
});

/**
 * @returns {{type: string}}
 */
export const makeHiveRequestStartAction = () => ({
  type: types.FETCH_HIVE_ACTION_START,
});

/**
 * @returns {{type: string}}
 */
export const makeFetchHiveRequestSuccessAction = (hive) => ({
  type: types.FETCH_HIVE_ACTION_SUCCESS,
  hive,
});

/**
 * @returns {{type: string, error: *}}
 */
export const makeHiveRequestFailureAction = () => ({
  type: types.FETCH_HIVE_ACTION_FAILURE,
});

export const makeFetchHiveListRequestThunk = (id, status, actionList) => {
  return (dispatch) => {
    dispatch(makeHiveListRequestStartAction(status));

    const hiveStatusQueryParam = status === 'alive' ? null : status;
    const url = URLUtil.buildURL(ENDPOINTS.hhtHiveList, { yard_id: id, hive_status: hiveStatusQueryParam });

    fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
          } else {
            dispatch(makeHiveListRequestFailureAction(status));
            dispatch(makeShowSnackbarAction('snack_default_msg'));
          }
        } else if ('results' in jsonObj) {
          // all is right
          dispatch(makeFetchHiveListRequestSuccessAction(jsonObj.results, id, status, actionList));
        }
      });
  };
};

export const makeFetchHiveRequestThunk = (id) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeHiveRequestStartAction());

    fetch(`${ENDPOINTS.hhtHiveList}${id}/`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeHiveRequestFailureAction());
            dispatch(makeShowSnackbarAction('snack_default_msg'));
            return;
          }
        } else if ('errors' in jsonObj) {
          dispatch(makeHiveRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeFetchHiveRequestSuccessAction(jsonObj));
        }
      });
  };
};
