import React from 'react';

import { StyledAnchor } from './styles';
import { AnchorProps } from './types';

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    {
      children,
      primary,
      secondary,
      tertiary,
      elevated,
      rounded,
      withIconLeft,
      withIconRight,
      paddingHorizontal = true,
      paddingVertical = true,
      ...props
    },
    ref
  ) => {
    return (
      <StyledAnchor
        ref={ref}
        $primary={primary}
        $secondary={secondary}
        $tertiary={tertiary}
        $elevated={elevated}
        $rounded={rounded}
        $withIconLeft={withIconLeft}
        $withIconRight={withIconRight}
        $paddingHorizontal={paddingHorizontal}
        $paddingVertical={paddingVertical}
        {...props}
      >
        {children}
      </StyledAnchor>
    );
  }
);

Anchor.displayName = 'Anchor';
