import { GoogleMapsContextProvider } from '@components/common/Map/apis/GoogleMaps/googleMapsContext';
import { MarkerRenderer } from '@components/common/Map/apis/GoogleMaps/MarkerRenderer';
import { PolygonRenderer } from '@components/common/Map/apis/GoogleMaps/PolygonRenderer';
import { SearchBoxRenderer } from '@components/common/Map/apis/GoogleMaps/SearchBoxRenderer';
import { MapApiProvider } from '@components/common/Map/types';

import { MapRenderer } from './MapRenderer';

export const GoogleMapsApiProvider: MapApiProvider = {
  contextProvider: GoogleMapsContextProvider,
  mapRenderer: MapRenderer,
  markerRenderer: MarkerRenderer,
  polygonRenderer: PolygonRenderer,
  searchBoxRenderer: SearchBoxRenderer,
};
