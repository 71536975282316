import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import backIcon from '@assets/MediumBack.svg';
// nectar:
import logo from '@assets/nectar-logo.svg';
import backMobileIcon from '@assets/SmallBack.svg';
import { YardStatusChip } from '@components/common/YardStatusChip';
import { StyledHeading2 } from '@components/deprecated/Elements/TypographyElements';
import { capitalizeFirst } from '@helpers/deprecated/capitalizeFirst';
import theme from '@style/theme';

const StyledLogoBtn = styled.button`
  height: 100%;
`;

const StyledLogoImg = styled.img`
  width: 90px;
  height: 18px;
  color: ${(props) => props.theme.nectarColor};
  filter: ${(props) => props.theme.nectarLogoFilter};
`;

const StyledBackBtn = styled.button`
  border-radius: 1rem;
  margin-right: ${(props) => (props.isMobile ? '1rem' : '20px')};
  margin-top: 2px;
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
  &:focus {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

const StyledBackIcon = styled.img`
  filter: invert(100%) brightness(123%);
`;

const StyledTextContainer = styled.h2`
  font-size: ${(props) => props.theme.tooltipSmallFontSize};
  line-height: 14px;
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: 0.71px;
  margin-left: 1rem;
  white-space: nowrap;
`;

const NavBarTitleView = ({ content, isMobile, handleGoBack, handleGoHome }) => {
  const { backBtn, label, yard } = content;

  // nothing specific to display, use the logo:
  if (!backBtn) {
    return (
      <StyledLogoBtn tabIndex={1} role="navigation" onClick={handleGoHome}>
        <StyledLogoImg alt="nectar logo" src={logo} />
      </StyledLogoBtn>
    );
  }

  return (
    <>
      {backBtn && (
        <StyledBackBtn tabIndex={1} role="navigation" isMobile={isMobile} onClick={handleGoBack}>
          <StyledBackIcon alt="back button" src={isMobile ? backMobileIcon : backIcon} />
        </StyledBackBtn>
      )}

      <StyledHeading2
        style={{
          color: theme.buttonPrimaryTextColor,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        mobile={isMobile}
      >
        {capitalizeFirst(label)}
      </StyledHeading2>

      <StyledTextContainer data-for="active_since" data-tip>
        {yard && <YardStatusChip status={yard.status} />}
      </StyledTextContainer>
    </>
  );
};

NavBarTitleView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    backBtn: PropTypes.bool.isRequired,
    label: PropTypes.oneOfType([PropTypes.string]),
    yard: PropTypes.object,
  }).isRequired,
  handleGoBack: PropTypes.func,
  handleGoHome: PropTypes.func,
};

export default NavBarTitleView;
