import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { TooltipYardInspection } from '@components/common/TooltipYardInspection';
import {
  StyledSectionHeader,
  StyledSectionRow,
  StyledSectionSubHeader,
  StyledSectionValue,
} from '@components/yard/YardsMapDetailsPane/styles';

const LAST_VISIT_DATE_FORMAT = 'MMM DD, YYYY';

export const YardsMapDetailsPaneSummary: React.VFC<BeeYardOnMapDetailedInfo> = ({
  lastInspection,
  nbHives,
  lastVisits,
  lastWorkers,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledSectionHeader typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
        {t('summary')}
      </StyledSectionHeader>
      <StyledSectionRow>
        <StyledSectionSubHeader>{t('last_inspection')}</StyledSectionSubHeader>
        {lastInspection ? (
          <StyledSectionValue dashed>
            {moment(lastInspection.analyzedAt).format(LAST_VISIT_DATE_FORMAT)}
            <TooltipYardInspection inspection={lastInspection} />
          </StyledSectionValue>
        ) : (
          <StyledSectionValue>-</StyledSectionValue>
        )}
      </StyledSectionRow>
      <StyledSectionRow>
        <StyledSectionSubHeader>{t('number_of_hives')}</StyledSectionSubHeader>
        <StyledSectionValue>{nbHives}</StyledSectionValue>
      </StyledSectionRow>
      <StyledSectionRow>
        <StyledSectionSubHeader>{t('visits_in_past_30_days')}</StyledSectionSubHeader>
        <StyledSectionValue>{lastVisits ?? '-'}</StyledSectionValue>
      </StyledSectionRow>
      <StyledSectionRow>
        <Box column>
          <StyledSectionSubHeader>{t('workers_that_visited_in_past_30_days')}</StyledSectionSubHeader>
          <StyledSectionValue>{lastWorkers ? lastWorkers.join(', ') : '-'}</StyledSectionValue>
        </Box>
      </StyledSectionRow>
    </>
  );
};
