import { useEffect } from 'react';
import throttle from 'lodash/throttle';

/**
 * Executes the given handler in loop
 * until the component is unmounted.
 * */
export function useAnimationLoop(handler: (() => void) | null) {
  useEffect(() => {
    let running = true;
    const tHandler = handler ? throttle(handler, 80) : null;
    const loop = () => {
      if (running) {
        tHandler && tHandler();
        requestAnimationFrame(loop);
      }
    };
    tHandler && loop();
    return () => {
      running = false;
    };
  }, [handler]);
}
