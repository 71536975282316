import React from 'react';
import PropTypes from 'prop-types';

const Index = () => {
  // console.log("404 Index", location)
  return (
    <>
      <h1
        style={{
          marginTop: '20%',
          textAlign: 'center',
          fontSize: '8em',
        }}
      >
        404
      </h1>
    </>
  );
};

Index.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Index;
