import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';
import thunk from 'redux-thunk';

import { rootReducer } from './Root/reducer';

const persistConfig = {
  storage,
  key: 'root',
  stateReconciler: autoMergeLevel2,
  whitelist: ['beeTrackYardListReducer'],
  transforms: [createFilter('beeTrackYardListReducer', ['yardsSearchHistory'], ['yardsSearchHistory'])],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);
