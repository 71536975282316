import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type HiveReducer = Reducer<HiveState, HiveAction>;

export type HiveAction =
  | { type: HiveActionType.HIVE_DISPOSE; payload: { id: number } }
  | { type: HiveActionType.HIVE_FETCH_DETAIL_START; payload: { id: number } }
  | {
      type: HiveActionType.HIVE_FETCH_DETAIL_FINISH;
      payload: {
        id: number;
        data: BeeHiveOnModalDetailedInfo | null;
        error: ApiResponseError | null;
      };
    }
  | { type: HiveActionType.HIVE_FETCH_ACTIVITY_START; payload: { id: number } }
  | {
      type: HiveActionType.HIVE_FETCH_ACTIVITY_FINISH;
      payload: {
        id: number;
        data: ActivityByRequestSet | null;
        error: ApiResponseError | null;
      };
    };

export interface HiveState {
  hives: Record<
    number,
    {
      detail: BeeHiveOnModalDetailedInfo | null;
      isFetchingDetail: boolean;

      activitySet: ActivityByRequestSet | null;
      isFetchingActivitySet: boolean;
    }
  >;
}

export interface ActivityByRequestSet {
  activities: Array<BeeActivityByRequest>;
  totalCount: number;
}

export enum HiveActionType {
  HIVE_DISPOSE = 'HIVE_DISPOSE',

  HIVE_FETCH_DETAIL_START = 'HIVE_FETCH_DETAIL_START',
  HIVE_FETCH_DETAIL_FINISH = 'HIVE_FETCH_DETAIL_FINISH',

  HIVE_FETCH_ACTIVITY_START = 'HIVE_FETCH_ACTIVITY_START',
  HIVE_FETCH_ACTIVITY_FINISH = 'HIVE_FETCH_ACTIVITY_FINISH',
}
