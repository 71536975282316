import React, { useCallback } from 'react';
import { generatePath } from 'react-router';
import { capitalize } from 'lodash';
import moment from 'moment/moment';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { Feeding, Treatment } from '@components/common/Icon/presets';
import { HiveGrading } from '@components/common/Icon/presets/HiveGrading';
import { hiveStatusIcon } from '@components/common/Icon/presets/HiveStatus';
import { Yard } from '@components/common/Icon/presets/Yard';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { StyledSummaryRow, StyledTooltipPractice } from '@components/hive/HiveModalTabsSummary/styles';
import APP from '@config/constants';
import { getDate } from '@helpers/deprecated/time';
import { useAction } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';
import { useHiveData } from '@redux/Hive/hooks';

export const HiveModalTabSummary: React.VFC = () => {
  const t = useTranslation();
  const { detail } = useHiveData();

  const status = useAction(detail?.latestStatusAction ?? null)?.flag as HiveStatus;
  const statusDate = detail?.latestStatusDatetime;

  const grading = useAction(detail?.latestGradingAction ?? null)?.detail;
  const gradingDate = detail?.latestGradingDatetime;

  const feeding = useAction(detail?.latestFeedingAction ?? null)?.detail;
  const feedingDate = detail?.latestFeedingDatetime;

  const treatment = useAction(detail?.latestTreatmentAction ?? null)?.detail;
  const treatmentDate = detail?.latestTreatmentDatetime;

  const renderPracticeTooltip = useCallback((options: { name: string; date: string; Icon: any }) => {
    const formattedDate = moment(options.date).format('MMM DD, YYYY [at] HH:mm');
    return (
      <Tooltip placement={'top-end'}>
        <StyledTooltipPractice marginBottomXS paddingHorizontalXS paddingVerticalXXS gapXS alignItems={'center'}>
          <options.Icon size={16} />
          <Text typography={'SmallParagraph'}>{options.name}</Text>
        </StyledTooltipPractice>
        <Text typography={'CaptionSmall'}>{formattedDate}</Text>
      </Tooltip>
    );
  }, []);

  const renderSummaryItem = useCallback(
    (options: { Icon: any; title: string; value: any; tooltip?: any; hyperlink?: string }) => {
      return (
        <StyledSummaryRow gapXS alignItems={'center'}>
          <options.Icon size={20} />
          <Box fit>
            <Text typography={'SmallParagraph'} weight={'600'}>
              {options.title}
            </Text>
          </Box>
          {options.hyperlink ? (
            <NavRouterLink tertiary to={options.hyperlink} target={'_blank'}>
              {options.value}
            </NavRouterLink>
          ) : (
            <Text typography={'SmallParagraph'} weight={'600'} dashed={!!options.tooltip}>
              {options.value}
              {options.tooltip}
            </Text>
          )}
        </StyledSummaryRow>
      );
    },
    []
  );

  if (!detail) {
    return null;
  }

  return (
    <Box fit column stretch paddingMD>
      {status
        ? renderSummaryItem({
            Icon: hiveStatusIcon[status as HiveStatus],
            title: `${t(status)} ${t('since')}`,
            value: getDate(statusDate),
          })
        : null}
      {status
        ? renderSummaryItem({
            Icon: Yard,
            title: capitalize(t('yard')),
            value: detail.yardName,
            hyperlink: generatePath(APP.routes.whiteboardYardsDetail, { uid: detail.yardId }),
          })
        : null}
      {grading && gradingDate
        ? renderSummaryItem({
            Icon: HiveGrading,
            title: t('hive_grading'),
            value: grading,
            tooltip: renderPracticeTooltip({ name: grading, date: gradingDate, Icon: HiveGrading }),
          })
        : null}
      {feeding && feedingDate
        ? renderSummaryItem({
            Icon: Feeding,
            title: t('last_feed'),
            value: feeding,
            tooltip: renderPracticeTooltip({ name: feeding, date: feedingDate, Icon: Feeding }),
          })
        : null}
      {treatment && treatmentDate
        ? renderSummaryItem({
            Icon: Treatment,
            title: t('last_treatment'),
            value: treatment,
            tooltip: renderPracticeTooltip({ name: treatment, date: treatmentDate, Icon: Treatment }),
          })
        : null}
    </Box>
  );
};
