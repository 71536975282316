import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { SlimPage } from '@components/common/SlimPage';
import { Text } from '@components/common/Text';
import { HiveStructureCard } from '@components/operation/HiveStructureCard';
import { SeasonCard } from '@components/operation/SeasonCard';
import { useOperation, useOperationFetcher } from '@hooks/useOperation';
import { SectionTitle } from '@scenes/admin/Operation/TabProfile/styles';

export const TabProfile: React.FC = () => {
  const { t } = useTranslation();
  const { isFetching } = useOperationFetcher();
  const operation = useOperation();

  return isFetching && !operation ? (
    <Loading />
  ) : (
    <SlimPage>
      <Box marginBottomSM>
        <Text typography={'Heading1'}>{t('operation_profile')}</Text>
      </Box>
      <Box marginBottomLG>
        <Text typography={'Paragraph'}>{t('operation_profile_help_text')}</Text>
      </Box>

      <Box paper02 paddingHorizontalSM paddingVerticalXS marginBottomMD>
        <Box marginXS>
          <SectionTitle typography={'Heading3'} weight={'600'}>
            {t('operation_name')}
          </SectionTitle>
        </Box>
        <Box marginXS marginLeftSM>
          <Text typography={'SmallParagraph'}>{operation?.name}</Text>
        </Box>
      </Box>

      <SeasonCard />
      <Box marginTopXS marginBottomSM />
      <HiveStructureCard />
    </SlimPage>
  );
};
