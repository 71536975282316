import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PagePaddingWrapper } from '@components/common/PageWrapper';
import { Tab, Tabs } from '@components/common/Tabs';
import { PolliContractDetails } from '@components/pollination/PolliContractDetails';
import { PolliDropsTable } from '@components/pollination/PolliDropsTable';
import { PolliMap } from '@components/pollination/PolliMap';
import { useContract, useContractId, useDropsCountTitle } from '@hooks/useContract';
import { makeNavBarTitleChangeAction } from '@redux/deprecated/actions';

export const PolliContract: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useContractId();
  const { contract, isReady } = useContract(id);

  const dropsTabTitle = useDropsCountTitle();

  useEffect(() => {
    // TODO:
    //  This is a really weird way to set the
    //  current navbar title. We should find another
    //  way to handle it without using a global state.
    if (isReady) {
      dispatch(
        makeNavBarTitleChangeAction({
          backBtn: true,
          label: contract?.name ?? '',
        })
      );
    }

    return () => {
      dispatch(
        makeNavBarTitleChangeAction({
          backBtn: false,
          label: '',
        })
      );
    };
  }, [dispatch, contract, isReady]);
  return (
    <Tabs isFixed>
      <Tab id={'contract'} title={t('contract')}>
        <PagePaddingWrapper>
          <PolliContractDetails />
        </PagePaddingWrapper>
      </Tab>
      <Tab id={'drops'} title={dropsTabTitle}>
        <PagePaddingWrapper>
          <PolliDropsTable />
        </PagePaddingWrapper>
      </Tab>
      <Tab id={'map'} title={t('map')}>
        <PolliMap />
      </Tab>
    </Tabs>
  );
};
