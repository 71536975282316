import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const QueenCrown = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <rect fill={useColor(color)} width="24" height="24" fillOpacity="0.01" />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.151 4.77972C19.4125 4.30185 19.912 4.00385 20.4556 4.00136C21.0227 3.98293 21.5513 4.28775 21.8207 4.78842C22.09 5.2891 22.0537 5.89947 21.7269 6.36447C21.4 6.82946 20.839 7.06906 20.2781 6.98312L19.1701 16.2384C19.05 17.2433 18.2 17.9998 17.1905 18.0002H6.76699C5.75754 17.9998 4.90752 17.2433 4.7874 16.2384L3.67942 6.98312C3.62066 6.99268 3.56138 6.99869 3.50191 7.00112C2.81889 7.0038 2.2208 6.54228 2.04851 5.87959C1.87622 5.2169 2.17349 4.52137 2.7709 4.18939C3.3683 3.85741 4.1141 3.97331 4.58325 4.47102C5.0524 4.96874 5.12574 5.72187 4.76147 6.30118L8.98693 9.00096L11.149 5.74722C10.601 5.38029 10.357 4.69701 10.5482 4.06469C10.7394 3.43238 11.3208 3 11.9798 3C12.6388 3 13.2201 3.43238 13.4113 4.06469C13.6025 4.69701 13.3585 5.38029 12.8105 5.74722L14.9816 9.00096L19.1971 6.30118C18.9071 5.8401 18.8895 5.25759 19.151 4.77972ZM5.9951 19.0002H17.9624C18.5132 19.0002 18.9597 19.4478 18.9597 20.0001C18.9597 20.5523 18.5132 21 17.9624 21H5.9951C5.44432 21 4.99782 20.5523 4.99782 20.0001C4.99782 19.4478 5.44432 19.0002 5.9951 19.0002Z"
      />
    </IconSVGView>
  );
};
