import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from '@components/deprecated/Container/Container';
import NavBar from '@components/deprecated/NavBarElements/NavBar';
// nectar:
import { makeFetchEventListRequestThunk, makeNavBarTitleChangeAction } from '@redux/deprecated/actions';
import AlertsCommon from '@scenes/admin/Alerts/components/AlertsCommon';

const AlertsScrollableContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-align: center;
`;

class Alerts extends React.Component {
  componentDidMount() {
    const { dispatchNavBarTitleChange, t, user, fetchEventList } = this.props;

    // immediately dispatch the nav bar change
    dispatchNavBarTitleChange({
      backBtn: true,
      label: t('activity'),
      yard: null,
      view: 'alerts',
    });

    fetchEventList(user, false);
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { t } = this.props;

    return (
      <AlertsScrollableContainer>
        <AlertsCommon t={t} isMobile={true} />
      </AlertsScrollableContainer>
    );
  }
}

Alerts.propTypes = {
  t: PropTypes.func.isRequired,
};

/**
 *
 * @param dispatch
 * @returns {{dispatchNavBarTitleChange: makeNavBarTitleChangeAction}}
 */
const mapDispatchToProps = (dispatch) => ({
  dispatchNavBarTitleChange: (content) => {
    dispatch(makeNavBarTitleChangeAction(content));
  },
  fetchEventList: (user, forceFetch) => {
    dispatch(makeFetchEventListRequestThunk(user, forceFetch));
  },
});

const mapStateToProps = (state) => ({
  user: state.accountReducer.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Alerts)));
