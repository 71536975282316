/**
 * Map layer to render yards, yard geometries and hives.
 * */

import React, { useCallback } from 'react';

import { MarkerOptimizer } from '@components/common/Map/components/MarkerOptimizer';
import { useMap } from '@components/common/Map/hooks';
import { MarkerDescriptor } from '@components/common/Map/types';
import { YardLastInspectionDotRenderer } from '@components/yard/YardsMapLayers/elements/YardLastInspectionDotRenderer';
import { YardMarkerRenderer } from '@components/yard/YardsMapLayers/elements/YardMarkerRenderer';
import { YardPolygonRenderer } from '@components/yard/YardsMapLayers/elements/YardPolygonRenderer';
import {
  useYardDescriptors,
  useYardElementsDescriptors,
  useYardElementsVisibilityFilter,
} from '@components/yard/YardsMapLayers/hooks';
import { useLastInspectionSpecGetter } from '@config/LastInspectionConfig/hooks';

const MIN_ZOOM_TO_SHOW_YARD_PINS = 16.5;
const MAX_ZOOM_TO_SHOW_YARD_DOTS = 16.5;
const DEF_POLYGON_PROPS = { fillOpacity: 0.5, strokeWeight: 1, strokeOpacity: 1 };
const DEF_POLYGON_Z_INDEX_LATITUDE_MULTIPLIER = -10000;
const DEF_POLYGON_Z_INDEX_PRIORITY_MULTIPLIER = 1000000;

export interface YardsMapLastInspectionLayerProps {
  yards: Array<BeeYardOnMapInfo>;
  isYardSelected?: (yard: BeeYardOnMapInfo) => boolean;
  onYardPinClick?: (yard: BeeYardOnMapInfo) => void;
}

export const YardsMapLastInspectionLayer: React.VFC<YardsMapLastInspectionLayerProps> = ({
  yards,
  isYardSelected,
  onYardPinClick,
}) => {
  const map = useMap();

  const yardDescriptors = useYardDescriptors(yards);
  const yardElementsDescriptors = useYardElementsDescriptors(yards);
  const visibleMarkersFilter = useYardElementsVisibilityFilter();

  const getLIMColorSpec = useLastInspectionSpecGetter();

  const getYardPolygonProps = useCallback(
    (yard: BeeYardOnMapInfo) => {
      const { color, priority } = getLIMColorSpec(yard);

      // This will make higher priority items to have higher zIndex.
      // Items with same priority will use the latitude to generate
      // the appropriate zIndex.
      const zIndex =
        priority * DEF_POLYGON_Z_INDEX_PRIORITY_MULTIPLIER +
        yard.yardCenter.coordinates[1] * DEF_POLYGON_Z_INDEX_LATITUDE_MULTIPLIER;

      return { fillColor: color, strokeColor: color, zIndex, ...DEF_POLYGON_PROPS };
    },
    [getLIMColorSpec]
  );

  const onLastInspectionDotClicked = useCallback(
    (yard: BeeYardOnMapInfo) => {
      const [lng, lat] = yard.yardCenter.coordinates;
      const center = { lat, lng };

      // Makes the zoom increment bigger when zoomed out,
      // and smaller when zoomed in.
      const zoomIncrement = Math.max(5 / Math.pow(map.state.zoom, 0.18), 1);
      const zoom = map.state.zoom + zoomIncrement;

      map.setState((curr) => ({ ...curr, zoom, center }));
    },
    [map]
  );

  return (
    <>
      <MarkerOptimizer
        enableSmartBounds
        markers={yardElementsDescriptors}
        markerRenderer={(props) => (
          // eslint-disable-next-line react/prop-types
          <YardPolygonRenderer polygonProps={getYardPolygonProps(props.data)} {...props} />
        )}
        visibleMarkersFilter={visibleMarkersFilter}
        minZoomToRender={MIN_ZOOM_TO_SHOW_YARD_PINS}
      />
      <MarkerOptimizer
        enableSmartBounds
        markers={yardDescriptors}
        markerRenderer={(props) => (
          <YardMarkerRenderer isYardSelected={isYardSelected} onYardPinClick={onYardPinClick} {...props} />
        )}
        minZoomToRender={MIN_ZOOM_TO_SHOW_YARD_PINS}
      />
      <MarkerOptimizer
        enableSmartBounds
        markers={yardDescriptors}
        markerRenderer={(props) => {
          // eslint-disable-next-line react/prop-types
          const yard = props.data;
          const { ...polygonProps } = getYardPolygonProps(yard);
          return (
            <YardLastInspectionDotRenderer
              color={polygonProps.fillColor}
              zIndex={polygonProps.zIndex}
              onClick={() => onLastInspectionDotClicked(yard)}
              {...props}
            />
          );
        }}
        maxZoomToRender={MAX_ZOOM_TO_SHOW_YARD_DOTS}
      />
    </>
  );
};
