import React, { createRef, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

// icons:
import moreIcon from '@assets/xSmall_More.svg';
import Container from '@components/deprecated/Container/Container';
import { Dropbox } from '@components/deprecated/Dropbox/Dropbox';
import { StyledEditIcon } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading1 } from '@components/deprecated/Elements/TypographyElements';
import { Table } from '@components/deprecated/Table_deprecated/Table';
import TableHeader from '@components/deprecated/Table_deprecated/TableHeader';
import { groupColorIndexToRealColor } from '@helpers/deprecated/color';
// nectar
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useOperation } from '@hooks/useOperation';

const StyledContainer = styled(Container)`
  padding: ${(props) => (props.isMobileOrTablet ? '2rem 0' : '3rem')};
  width: 100%;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const StyledTr = styled.tr`
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => !props.isMobile && props.theme.hoverColor};
  }
`;

const GroupColor = styled.div`
  height: 1px;
  width: 16px;
  display: inline-flex;
  margin-right: 16px;
  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    margin-top: -12px;
  }
`;

export const GroupsView = ({
  t,
  isMobile,
  isTablet,
  setRef,
  groups,
  sort,
  display,
  handleSort,
  handleDisplayMenu,
  handleEditGroup,
  handleRemoveGroup,
  handleGroupClick,
}) => {
  const operation = useOperation();
  const arrLength = groups.length;

  const [listRef, setElRefs] = useState([]);

  const seasonStartDate = useMemo(() => {
    if (operation) {
      return moment(operation.seasonStart, 'YYYY/MM/DD').format('MMMM DD, YYYY');
    }
    return null;
  }, [operation]);

  useEffect(() => {
    setElRefs((listRef) =>
      Array(arrLength)
        .fill()
        .map((_, i) => listRef[i] || createRef())
    );
  }, [arrLength]);

  const menuItems = [
    { text: 'edit', handler: handleEditGroup },
    { text: 'remove', handler: handleRemoveGroup },
  ];

  return (
    <StyledContainer isMobileOrTablet={isMobile || isTablet}>
      <StyledHeading1
        mobile={isMobile}
        style={{
          marginBottom: '36px',
          marginLeft: isMobile && '1.5rem',
        }}
      >
        {maybePluralize(groups.length, 'group', t)}
      </StyledHeading1>

      <StyledTable isMobile={isMobile}>
        <thead>
          <tr>
            <TableHeader handleSort={handleSort} id="name" data={t('group')} sort={sort} />
            <TableHeader handleSort={handleSort} id="nb_yards" data={t('yards')} sort={sort} />
            <TableHeader handleSort={handleSort} id="nb_hives" data={t('hives')} sort={sort} />
            <TableHeader
              handleSort={handleSort}
              id="nb_deadout_season"
              data={t('deadout_short')}
              sort={sort}
              tooltip={t('deadout_since', { date: seasonStartDate })}
              tooltipPlace="right"
              tooltipOffset={{ top: 32 }}
            />

            {/* <TableHeader
              handleSort={handleSort}
              id="outliers"
              data={t("outliers")}
              sort={sort}
            />
            <TableHeader
              handleSort={handleSort}
              id="mortality"
              data={t("mortality")}
              sort={sort}
            />
            <TableHeader
              handleSort={handleSort}
              id="latest_used"
              data={t("latest_used")}
              sort={sort}
            />
            <TableHeader
              handleSort={handleSort}
              id="reporting_users"
              data={t("reporting_users")}
              sort={sort}
            /> */}
            <th />
          </tr>
        </thead>

        <tbody>
          {groups.map((group) => {
            const { id, name, nb_yards: nbYards, nb_hives: nbHives, nb_deadout_season: nbDeadoutSeason } = group;

            return (
              <StyledTr
                key={id}
                isMobile={isMobile}
                onClick={() => {
                  handleGroupClick(id);
                }}
              >
                <td
                  style={{
                    textTransform: 'capitalize',
                    width: '153px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    verticalAlign: 'middle',
                  }}
                >
                  <GroupColor color={groupColorIndexToRealColor(group)} />
                  {name}
                </td>
                <td style={{ width: '97px' }}>{nbYards}</td>
                <td style={{ width: '138px' }}>{nbHives}</td>
                <td>{nbDeadoutSeason}</td>

                <td>
                  <StyledEditIcon
                    src={moreIcon}
                    alt="more icon"
                    ref={setRef}
                    onClick={(e) => handleDisplayMenu(e, id, listRef[id])}
                    style={{ marginRight: '34.5px' }}
                  />

                  <Dropbox
                    t={t}
                    menuItems={menuItems}
                    setRef={(el) => (listRef[id] = el)}
                    display={display === id}
                    handlerData={group}
                    customStyle={{
                      width: '180px',
                      right: '2rem',
                      marginTop: '2rem',
                    }}
                  />
                </td>
              </StyledTr>
            );
          })}
        </tbody>
      </StyledTable>
    </StyledContainer>
  );
};

GroupsView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  display: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  setRef: PropTypes.object.isRequired,
  sort: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  user: PropTypes.shape({ season_start: PropTypes.string }).isRequired,
  handleSort: PropTypes.func.isRequired,
  handleDisplayMenu: PropTypes.func.isRequired,
  handleGroupClick: PropTypes.func.isRequired,
  handleEditGroup: PropTypes.func.isRequired,
  handleRemoveGroup: PropTypes.func.isRequired,
};
