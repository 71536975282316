import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ArrowRight = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M4.00021 12.9755H17.5862L13.2932 17.2685C13.0333 17.5196 12.929 17.8914 13.0205 18.241C13.112 18.5906 13.3851 18.8637 13.7347 18.9552C14.0843 19.0467 14.4561 18.9424 14.7072 18.6825L20.7072 12.6825C21.0976 12.292 21.0976 11.659 20.7072 11.2685L14.7072 5.26848C14.3148 4.88951 13.6911 4.89493 13.3054 5.28066C12.9197 5.6664 12.9142 6.2901 13.2932 6.68248L17.5862 10.9755H4.00021C3.44793 10.9755 3.00021 11.4232 3.00021 11.9755C3.00021 12.5278 3.44793 12.9755 4.00021 12.9755Z"
      />
    </IconSVGView>
  );
};
