import styled from 'styled-components';

export const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  height: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 4px;
  font-size: 12px;
`;

export const ChipIcon = styled.div`
  margin-right: 0.5rem;
`;

export const ChipIntroduced = styled(Chip)(
  ({ theme }) => `
  color: ${theme.colors.grey08};
  background-color: ${theme.colors.yellow01};
`
);

export const ChipQueenRight = styled(Chip)(
  ({ theme }) => `
  color: ${theme.colors.grey08};
  background-color: ${theme.colors.green01};
`
);

export const ChipQueenLess = styled(Chip)(
  ({ theme }) => `
  color: ${theme.colors.grey08};
  background-color: ${theme.colors.red01};
`
);

export const ChipDeadOut = styled(Chip)(
  ({ theme }) => `
  color: ${theme.colors.grey08};
  background-color: ${theme.colors.grey04};
`
);

export const ChipRetired = styled(Chip)(
  ({ theme }) => `
  color: ${theme.colors.grey08};
  background-color: ${theme.colors.grey04};
`
);
