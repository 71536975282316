import React from 'react';

import { StyledButton } from './styles';
import { ButtonProps } from './types';

/**
 * Todo: add parent component / props for paired-buttons
 */

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      unstyled,
      primary,
      secondary,
      tertiary,
      elevated,
      rounded,
      withIconLeft,
      withIconRight,
      paddingHorizontal = true,
      paddingVertical = true,
      ...props
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        $unstyled={unstyled}
        $primary={primary}
        $secondary={secondary}
        $tertiary={tertiary}
        $elevated={elevated}
        $rounded={rounded}
        $withIconLeft={withIconLeft}
        $withIconRight={withIconRight}
        $paddingHorizontal={paddingHorizontal}
        $paddingVertical={paddingVertical}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
