import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import checkIcon from '@assets/xSmall_Check.svg';

// nectar:
import '@components/deprecated/Dropdown/dropbox.css';

const StyledUL = styled.ul`
  position: 'fixed';
  display: ${(props) => (props.show ? 'block' : 'none')};
  cursor: pointer;
`;

export const Dropbox = ({
  t,
  menuItems,
  setRef,
  is_active,
  display,
  customStyle,
  customListStyle,
  customLableStyle,
  customDisabledStyle,
  handlerData,
  iconCheck,
  disableCheckOn,
}) => {
  return (
    <div className="select-dropbox" style={customStyle}>
      <StyledUL ref={setRef} className="select-dropbox-list" show={display} style={customListStyle}>
        {menuItems.map((item, i) => {
          const disabled = is_active && disableCheckOn === item.text;
          return (
            <li
              key={i}
              className="select-dropbox-list-item"
              onClick={
                disabled
                  ? () => {}
                  : (e) => {
                      e.stopPropagation();
                      item.handler(handlerData);
                    }
              }
            >
              {item.icon && (
                <img
                  className="select-dropbox-img"
                  src={item.icon}
                  alt="menu icon"
                  style={disabled ? customDisabledStyle : {}}
                />
              )}

              <label
                style={disabled && disableCheckOn === item.text ? customDisabledStyle : { customLableStyle }}
                className="select-dropbox-option"
                aria-hidden={false}
              >
                {t(item.text)}
              </label>

              {iconCheck === item.text && <img className="select-dropbox-check-img" src={checkIcon} alt="check icon" />}
            </li>
          );
        })}
      </StyledUL>
    </div>
  );
};

Dropbox.propTypes = {
  t: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  setRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  display: PropTypes.bool.isRequired,
  is_active: PropTypes.bool,
  customStyle: PropTypes.object,
  customListStyle: PropTypes.object,
  customLableStyle: PropTypes.object,
  customDisabledStyle: PropTypes.object,
  handlerData: PropTypes.object,
  iconCheck: PropTypes.string,
  disableCheckOn: PropTypes.string,
};

export default Dropbox;
