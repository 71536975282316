import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useUser(): BeeUser | undefined | null {
  const user = useSelector<any, any>((state) => state.accountReducer.user);
  return useMemo(() => {
    if (user) {
      return {
        id: user.id,

        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name,
        phoneNumber: user.phone_number,
        email: user.email,
        isActive: user.is_active,
        isStaff: user.is_staff,
        operationName: user.operation_name,
        dndStart: user.dnd_start,
        dndEnd: user.dnd_end,
        seasonStart: user.season_start,
        timezone: user.timezone,
        alertsHiveMovedMail: user.alerts_hive_moved_mail,
        alertsHiveMovedSms: user.alerts_hive_moved_sms,
        alertsHiveStatusMail: user.alerts_hive_status_mail,
        alertsHiveStatusSms: user.alerts_hive_status_sms,
        alertsNewYardCreatedMail: user.alerts_new_yard_created_mail,
        alertsNewYardCreatedSms: user.alerts_new_yard_created_sms,

        alertsDailyReportEmail: user.alerts_daily_report_mail,

        eventReadOn: user.event_read_on,
        featureHhtManager: user.feature_hht_manager,
        featureHhtWorker: user.feature_hht_worker,
        featureSensor: user.feature_sensor,
        hsThreshold: user.hs_threshold,
        languageCode: user.language_code,
        notifyByEmail: user.notify_by_email,
        notifyByPhone: user.notify_by_phone,
        operationId: user.operation_id,
        recapDaily: user.recap_daily,
        recapWeekly: user.recap_weekly,
        tempUnit: user.temp_unit,
      };
    }
  }, [user]);
}
export function useUserFetcher(): { isFetching: boolean } {
  const isFetching = useSelector<any, boolean>((state) => {
    return state.accountReducer.isFetching;
  });
  return { isFetching };
}
