import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';

import plusIcon from '@assets/Small_Plus.svg';
import { Loading } from '@components/common/Loading';
import { Menu } from '@components/common/Menu';
import { Text } from '@components/common/Text';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import { BulletButton } from '@components/deprecated/Buttons/BulletButton';
import WorkerModal from '@components/deprecated/DynamicModals/WorkerModal';
import { EmptyBeeTrackView } from '@components/deprecated/EmptyView/EmptyBeeTrackView';
import { SortTriangle } from '@components/deprecated/Table_deprecated/SortTriangle';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
import { formatPhoneNumber } from '@helpers/deprecated/formatPhoneNumber';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getMoment } from '@helpers/deprecated/time';
import { useWorkers } from '@hooks/useWorkers';
import {
  handleSortWorkerList,
  makeFetchWorkerRequestThunk,
  makeOpenWorkerModalAction,
  makeResendInviteWorkerRequestThunk,
} from '@redux/deprecated/actions';

import {
  HiddenOnMobileTd,
  HiddenOnMobileTh,
  StatusSpan,
  StyledBottomRightNav,
  StyledContainer,
  StyledLastTd,
  StyledTable,
  StyledTableHeading,
  StyledTr,
} from './styles';
import { OpenWorkerModalArgs } from './types';

export const TabWorkers: React.FC = () => {
  const { t } = useTranslation();
  const { workers, order_by, order_direction } = useWorkers();
  const dispatch = useDispatch();

  const handleSort = (orderBy: Array<string>) => dispatch(handleSortWorkerList(orderBy, order_direction));

  const openWorkerModal = (data: OpenWorkerModalArgs) => {
    dispatch(makeOpenWorkerModalAction(data));
  };

  const sort = useMemo(
    () => ({
      order_by: order_by[0],
      order_direction,
    }),
    [order_by, order_direction]
  );

  useEffect(() => {
    dispatch(makeFetchWorkerRequestThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!workers) return <Loading />;

  return (
    <>
      <StyledContainer>
        {workers?.length === 0 ? (
          <EmptyBeeTrackView heading={t('no_workers')} text={t('no_workers_msg')} />
        ) : (
          <>
            <Text typography="Heading1">{maybePluralize(workers.length, 'worker', t)}</Text>
            <Text typography="Paragraph" weight="400" style={{ maxWidth: '585px', margin: '16px 0 40px' }}>
              {t('worker_section_description')}
            </Text>
            <StyledTable>
              <thead>
                <tr>
                  <StyledTableHeading onClick={() => handleSort(['first_name', 'last_name'])}>
                    {t('name')}
                    <SortTriangle id="first_name" sort={sort} />
                  </StyledTableHeading>

                  <HiddenOnMobileTh onClick={() => handleSort(['phone_number', 'first_name', 'last_name'])}>
                    {t('phone')}
                    <SortTriangle id="phone_number" sort={sort} />
                  </HiddenOnMobileTh>

                  <StyledTableHeading onClick={() => handleSort(['status', 'first_name', 'last_name'])}>
                    {t('status')}
                    <SortTriangle id="status" sort={sort} />
                  </StyledTableHeading>

                  <HiddenOnMobileTh onClick={() => handleSort(['last_report', 'first_name', 'last_name'])}>
                    {t('last_report')}
                    <SortTriangle id="last_report" sort={sort} />
                  </HiddenOnMobileTh>

                  <StyledTableHeading />
                </tr>
              </thead>

              <tbody>
                {workers.map((worker) => {
                  const { first_name, last_name, phone_number, status, last_report, id } = worker;

                  const no_data = '-';

                  return (
                    <StyledTr key={id}>
                      <td>{first_name + ' ' + last_name}</td>

                      <HiddenOnMobileTd style={{ letterSpacing: '1px' }}>
                        <NumberFormat
                          value={phone_number}
                          displayType={'text'}
                          format={formatPhoneNumber(phone_number)}
                        />
                      </HiddenOnMobileTd>

                      <td>
                        <span data-for={'status' + id} data-tip>
                          <StatusSpan isActive={status === 'active'}>{status}</StatusSpan>
                        </span>
                        <Tooltip
                          id={'status' + id}
                          disable={'active' === status}
                          tooltipText={t('tooltip_pending_status')}
                        />
                      </td>
                      <HiddenOnMobileTd>{last_report ? getMoment(last_report) : no_data}</HiddenOnMobileTd>
                      <StyledLastTd>
                        <ThreeDotsMenuTrigger id={`menu-trigger-${worker.id}`} />
                        <Menu
                          target={`menu-trigger-${worker.id}`}
                          placement="bottom-start"
                          items={[
                            {
                              title: t('resend_invite'),
                              onClick: () => dispatch(makeResendInviteWorkerRequestThunk(id)),
                            },
                            {
                              title: t('edit'),
                              onClick: () => openWorkerModal({ modalType: 'update-worker', worker }),
                            },
                            {
                              title: t('remove'),
                              onClick: () => openWorkerModal({ modalType: 'remove-worker', worker }),
                            },
                          ]}
                        />
                      </StyledLastTd>
                    </StyledTr>
                  );
                })}
              </tbody>
            </StyledTable>
          </>
        )}
        <StyledBottomRightNav>
          <BulletButton
            textStyle={{}}
            t={t}
            type="button"
            role="dialog"
            onClick={() => openWorkerModal({ modalType: 'add-worker' })}
            imgSrc={plusIcon}
            textToButton={t('add_worker')}
            width="150px"
          />
        </StyledBottomRightNav>
      </StyledContainer>

      <WorkerModal />
    </>
  );
};
