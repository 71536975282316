import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { HiveDot } from '@components/common/Icon/dynamic/HiveDot';
import { Marker } from '@components/common/Map';
import { MarkerDescriptor } from '@components/common/Map/types';

export function YardHivesRenderer(props: MarkerDescriptor<BeeYardOnMapInfo>) {
  const { data: yard } = props;
  const theme = useTheme();

  const yardHiveMarkers = useMemo(
    () =>
      yard.hivesPosition.map(({ coordinates: [lng, lat] }, index) => ({
        id: `${yard.id}-${index}`,
        position: { lat, lng },
        icon: HiveDot.getImageURI({ theme, size: 12 }),
      })),
    [theme, yard.hivesPosition, yard.id]
  );

  return (
    <>
      {yardHiveMarkers.map(({ id, ...markerProps }) => (
        <Marker key={id} {...markerProps} />
      ))}
    </>
  );
}
