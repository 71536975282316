import React, { useCallback } from 'react';
import { generatePath } from 'react-router-dom';

import { Box } from '@components/common/Box';
import { YardVisitCard } from '@components/common/YardVisitCard';
import {
  StyledHeading,
  StyledUnderlinedButton,
  StyledYardBoxInfo,
  StyledYardNameLink,
} from '@components/timeline/TimelineBase/styles';
import { StyledYardVisitWrapper } from '@components/timeline/TimelineByActivityDate/styles';
import { useTimelineByActivityRequestModalOpener } from '@components/timeline/TimelineByActivityRequest/hooks';
import APP from '@config/constants';
import { Children } from '@helpers/Children';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useTranslation } from '@hooks/useTranslation';
import { ActivityFilters } from '@redux/Timeline/types';

export function useItemHeadingRender() {
  const t = useTranslation();
  const { openTimelineByActivityRequestModal } = useTimelineByActivityRequestModalOpener();

  const openYardVisitModal = useCallback(
    (filters: ActivityFilters) => {
      openTimelineByActivityRequestModal(filters);
    },
    [openTimelineByActivityRequestModal]
  );

  return useCallback(
    (item: BeeActivityByDate) => {
      const yardLabel = (
        <>
          {item.yardName ? (
            item.yardIsDeleted ? (
              <strong>{item.yardName}</strong>
            ) : (
              <StyledYardNameLink to={generatePath(APP.routes.yard, { uid: item.yardId })} target={'_blank'}>
                {item.yardName}
              </StyledYardNameLink>
            )
          ) : null}{' '}
          <StyledYardBoxInfo typography={'CaptionSmall'}>
            {item.yardIsDeleted ? t('deleted') : item.yardNbHives}
          </StyledYardBoxInfo>
        </>
      );

      const byWorkerLabel = (
        <>
          {t('by')}{' '}
          {Children.createWithSeparators(item.workerNames, {
            lastSeparator: `, ${t('and')} `,
            singleSeparator: ` ${t('and')} `,
          })}
        </>
      );

      const bounds_updated_label = item.hasYardBeenResized ? t('yard_boundaries_updated') : null;

      switch (item.alertType) {
        case 'new_yard_visit':
          return (
            <StyledHeading typography={'Heading3'}>
              <StyledUnderlinedButton
                onClick={() =>
                  openYardVisitModal({ date: item.occurredAt, yardId: item.yardId, alertType: 'new_inspection' })
                }
              >
                {t('yard_visit')}
              </StyledUnderlinedButton>{' '}
              {t('at')} {yardLabel} {byWorkerLabel}
              {'.'}
            </StyledHeading>
          );
        case 'hive_managed_to_yard':
        case 'hive_added_to_yard':
          return (
            <Box column>
              <StyledHeading typography={'Heading3'}>
                <StyledUnderlinedButton
                  onClick={() =>
                    openYardVisitModal({
                      date: item.occurredAt,
                      yardId: item.yardId,
                      alertType: item.alertType as BeeActivityByRequestAlertType,
                    })
                  }
                >
                  <strong> {maybePluralize(item.nbHives, 'hive', t)}</strong>
                </StyledUnderlinedButton>{' '}
                <strong>{t('added')}</strong> {t('to')} {yardLabel} {byWorkerLabel}
                {'.'}
              </StyledHeading>
              <StyledHeading typography={'Heading3'}>{bounds_updated_label}</StyledHeading>
            </Box>
          );
        case 'new_yard_created':
          return (
            <StyledHeading typography={'Heading3'}>
              {yardLabel} <strong>{t('created')}</strong> {byWorkerLabel}
              {'.'}
            </StyledHeading>
          );
        case 'yard_edited_by_manager':
          return (
            <StyledHeading typography={'Heading3'}>
              {yardLabel} <strong>{t('info')}</strong> {t('were')} <strong>{t('updated')}</strong> {byWorkerLabel}
              {'.'}
            </StyledHeading>
          );
        case 'yard_deleted':
          return (
            <StyledHeading typography={'Heading3'}>
              <strong>{item.yardName}</strong> {t('was')} <strong>{t('deleted')}</strong> {byWorkerLabel}
              {'.'}
            </StyledHeading>
          );
        default:
          return null;
      }
    },
    [openYardVisitModal, t]
  );
}

export function useItemContentRenderer() {
  return useCallback((item: BeeActivityByDate) => {
    if (item.alertType !== 'new_yard_visit') {
      return null;
    }
    return (
      <StyledYardVisitWrapper column stretch>
        <YardVisitCard
          occurredAt={item.occurredAt}
          workerNames={item.workerNames}
          yardNbHivesOnVisitDate={item.yardNbHivesOnVisitDate}
          practices={[...item.actions]}
          notes={item.notes.filter(Boolean)}
        />
      </StyledYardVisitWrapper>
    );
  }, []);
}
