import { ApiResponseError } from '@helpers/Api/types';

export interface YardsMapContextValue {
  isFetching: boolean;
  isFetchingDetail: boolean;
  fetchError: ApiResponseError | null;

  activeLayers: ActiveLayers;

  yards: Array<BeeYardOnMapInfo>;
  selectedYard: BeeYardOnMapInfo | null;
  selectedYardDetail: BeeYardOnMapDetailedInfo | null;

  blocks: Array<BeeBlock>;

  toggleLayer: ActiveLayersToggle;
  setSelectedYard: (yard: BeeYardOnMapInfo | null) => void;
  recenterMap: () => void;
  goToYardManagement: () => void;
  retrieveYards: () => void;
  retrieveSelectedYard: () => void;
}

/**
 * Note: these enum values are used in the URL params,
 * so they are lowercase for better looking.
 * */
export enum YardsMapLayer {
  /** Shows clustered yards and hives. */
  DEFAULT = 'default',

  /** Adds a circle around each yard showing the bee flight radius. */
  BEE_FLIGHT_RADIUS = 'bee-flight-radius',

  /** Adds the geometry for pollination blocks. */
  POLLINATION_BLOCKS = 'blocks',

  /** Adds last inspection info. */
  LAST_INSPECTIONS = 'last-inspections',
}

export type ActiveLayers = Partial<Record<YardsMapLayer, boolean>>;
export type ActiveLayersToggle = (layer: YardsMapLayer) => void;
