import React, { useCallback } from 'react';

import { Loading } from '@components/common/Loading';
import { Map } from '@components/common/Map';
import { useYardsMap } from '@components/yard/YardsMap/hooks';

export const YardsMapSurface: React.FC = ({ children }) => {
  const { isFetching, setSelectedYard } = useYardsMap();

  const onMapClick = useCallback(() => setSelectedYard(null), [setSelectedYard]);

  return (
    <>
      <Map onClick={onMapClick}>{children}</Map>
      <Loading visible={isFetching} blurBackground />
    </>
  );
};
