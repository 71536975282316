import styled, { css } from 'styled-components';

export const StyledVisitCardNavArrow = styled.button(
  () => css`
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
    }
  `
);
