import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import * as uuid from 'uuid';

import { BoxRef } from '@components/common/Box/types';
import { ChildEntry, ContentTransitionProps } from '@components/common/ContentTransition/types';

import { AnimatedContent } from './styles';

const _ContentTransition = ({
  content,
  animationAnchorSelector,
  animationType,
  animationDuration,
  children: propChildren,
  ...props
}: ContentTransitionProps<any>) => {
  const [children, setChildren] = useState<Array<ChildEntry>>([]);

  const nextChildrenRef = useRef<BoxRef<'div'>>(null);

  const theme = useTheme();
  const duration = useMemo(() => {
    return {
      fast: theme.animations.durationFast,
      medium: theme.animations.durationMedium,
      long: theme.animations.durationLong,
    }[animationDuration ?? 'medium'];
  }, [
    animationDuration,
    theme.animations.durationFast,
    theme.animations.durationLong,
    theme.animations.durationMedium,
  ]);

  const getNextChild = useCallback(() => {
    return propChildren && content ? propChildren(content) : null;
  }, [content, propChildren]);

  const getCurrentChildAbsoluteStyle = useCallback((): CSSProperties => {
    const anchorElement = animationAnchorSelector
      ? nextChildrenRef.current?.closest(animationAnchorSelector)
      : nextChildrenRef.current?.parentElement;
    const anchorLayout = anchorElement?.getBoundingClientRect();
    const childrenLayout = nextChildrenRef.current?.getBoundingClientRect();

    if (anchorLayout && childrenLayout) {
      const { width, height } = childrenLayout;
      return {
        position: 'absolute',
        top: childrenLayout.top - anchorLayout.top,
        left: childrenLayout.left - anchorLayout.left,
        width,
        height,
      };
    }
    return {};
  }, [animationAnchorSelector]);

  useEffect(() => {
    setChildren(([...children]) => {
      const next: ChildEntry = { key: uuid.v4(), child: getNextChild(), style: {} };
      const current = children.pop() || { key: uuid.v4(), child: null, style: {} };
      current.style = getCurrentChildAbsoluteStyle();

      // Schedule to remove the children after out animation ends.
      setTimeout(() => {
        setChildren((curr) => curr.filter((child) => child.key !== current.key));
      }, duration);

      return [...children, current, next];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <>
      {children.map(({ key, style, child }, index, arr) => {
        const isTheNextChild = index === arr.length - 1;
        return (
          <AnimatedContent
            key={key}
            ref={isTheNextChild ? nextChildrenRef : null}
            $stage={isTheNextChild ? 'in' : 'out'}
            $duration={duration}
            $type={animationType ?? 'focus'}
            style={style}
            {...props}
          >
            {child}
          </AnimatedContent>
        );
      })}
    </>
  );
};

export const ContentTransition = React.memo(_ContentTransition, (prevProps, nextProps) => {
  return prevProps.content === nextProps.content;
}) as <T>(props: ContentTransitionProps<T>) => any;
