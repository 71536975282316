import produce from 'immer';

import { GMAP_TYPE, SHOW_YARD_HIVES_ZOOM } from '@config/google';
import { getParsedFromSessionStorage } from '@helpers/deprecated/storage';

import * as actionTypes from '../actionTypes';

var getType = sessionStorage.getItem('type');
var getZoom = getParsedFromSessionStorage('zoom');
var lat = getParsedFromSessionStorage('lat');
var lng = getParsedFromSessionStorage('lng');

const defaultState = {
  editMode: false,
  type: getType ? getType : GMAP_TYPE.default,
  zoom: getZoom ? getZoom : SHOW_YARD_HIVES_ZOOM,
  lat,
  lng,
  beeFlyZones: null,
};

export const mapReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.SET_EDIT_MODE:
        st.editMode = action.editMode;
        break;
      case actionTypes.TOGGLE_MAP_TYPE:
        let type = 'terrain' === state.type ? 'satellite' : 'terrain';
        sessionStorage.setItem('type', type);
        st.type = type;
        break;
      case actionTypes.SET_ZOOM_GLOBAL:
        sessionStorage.setItem('zoom', action.zoom);
        st.zoom = action.zoom;
        break;
      case actionTypes.SET_CENTER_GLOBAL:
        sessionStorage.setItem('lat', action.lat);
        sessionStorage.setItem('lng', action.lng);
        st.lat = action.lat;
        st.lng = action.lng;
        break;
      case actionTypes.SET_BEE_FLY_ZONES:
        st.beeFlyZones = action.beeFlyZones;
        break;
      default:
    }
  });
};
