import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { PoiIcon } from '@components/common/PoiIcon';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { AnyInstance, TransientPoi } from '@components/pollination/PolliMap/types';
import { ListItem } from '@components/pollination/PolliMapPaneSummary/styles';
import { useAlphanumericallySorted } from '@hooks/useSorted';

export const PolliMapPanePois: React.VFC = () => {
  const { t } = useTranslation();
  const { poisList, getKey, selectPoi } = usePolliMap();

  const poisListSorted = useAlphanumericallySorted(poisList, 'name');

  const handleItemClick = useCallback(
    (poi: AnyInstance<TransientPoi>) => {
      selectPoi(poi);
    },
    [selectPoi]
  );

  return (
    <Box column stretch>
      {poisListSorted.map((poi) => (
        <ListItem key={getKey(poi)} onClick={() => handleItemClick(poi)}>
          <PoiIcon size={16} category={poi.category} />
          <Box fit paddingHorizontalXS>
            <Text typography={'Heading3'} weight={'600'} align={'left'}>
              {poi.name}
            </Text>
          </Box>
          <Text typography={'Heading3'}>{t(`pollination_poi_category_${poi.category}`)}</Text>
        </ListItem>
      ))}
    </Box>
  );
};
