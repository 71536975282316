import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Road = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4649 2.36841H6.53764C6.29322 2.36841 6.08463 2.54512 6.04444 2.78621L3.00061 21.0492C2.94982 21.354 3.18484 21.6314 3.49381 21.6314H11.4649V19.644H12.5351V21.6314H20.5062C20.8152 21.6314 21.0502 21.354 20.9994 21.0492L17.9555 2.78621C17.9154 2.54511 17.7068 2.36841 17.4623 2.36841H12.5351V4.35586H11.4649V2.36841ZM11.4649 7.41348H12.5351V10.4711H11.4649V7.41348ZM12.5351 13.5287H11.4649V16.5863H12.5351V13.5287Z"
      />
    </IconSVGView>
  );
};
