import React from 'react';
import { withRouter } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import wkx from 'wkx';

import lowSrc from '@assets/staticmap-blurry-lowsrc.jpg';
import { DEFAULT_GOOGLE_MAPS_API_OPTIONS } from '@components/common/Map/apis/GoogleMaps/constants';
import APP from '@config/constants';
import {
  GMAP_API_KEY,
  GMAP_FLYOVER_RADIUS,
  GMAP_SMALL_ICON_SIZES,
  GMP_STATIC_MAP_ICON_BOTHERSOME_URL_64,
  GMP_STATIC_MAP_ICON_CRITICAL_URL_64,
  GMP_STATIC_MAP_ICON_GOOD_URL_64,
  GMP_STATIC_MAP_ICON_UNKNOWN_URL_64,
} from '@config/google';

import { LowSrcImgView } from '../LowSrcImg/LowSrcImgView';

export const ROOT_URL = APP.nginxHost ? APP.nginxHost : APP.rootUrl;

export class MapContainer extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.getMarkerIcon = this.getMarkerIcon.bind(this);
    this.loadMap = this.loadMap.bind(this);
  }

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps) {
    // google props changed
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }
  }

  loadMap() {
    const { google, zoom, markerLocation, colorCode } = this.props;

    // google props is available
    if (this.props && this.props.google && markerLocation) {
      const maps = google.maps;

      const geometry = wkx.Geometry.parse(markerLocation);

      const center = new maps.LatLng(geometry.y, geometry.x);

      const mapConfig = Object.assign(
        {},
        {
          center,
          zoom,
          gestureHandling: 'none',
          draggableCursor: 'arrow',
          disableDefaultUI: true,
          styles: [
            {
              featureType: 'all',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }
      );

      this.map = new maps.Map(this.mapRef, mapConfig);

      new google.maps.Marker({
        position: center,
        map: this.map,
        icon: this.getMarkerIcon(colorCode),
      });

      new google.maps.Circle({
        map: this.map,
        radius: GMAP_FLYOVER_RADIUS,
        center,
        strokeColor: 'white',
        strokeOpacity: 0,
        fillColor: 'white',
        fillOpacity: 0.5,
      });
    }
  }

  getMarkerIcon(colorCode) {
    const { google } = this.props;

    let icon = {
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(GMAP_SMALL_ICON_SIZES.x / 2, GMAP_SMALL_ICON_SIZES.y / 2), // anchor
      scaledSize: new google.maps.Size(GMAP_SMALL_ICON_SIZES.x, GMAP_SMALL_ICON_SIZES.y),
      url: GMP_STATIC_MAP_ICON_UNKNOWN_URL_64,
    };

    switch (colorCode) {
      case 'good':
        return {
          ...icon,
          url: GMP_STATIC_MAP_ICON_GOOD_URL_64,
        };
      case 'worrying':
        return {
          ...icon,
          url: GMP_STATIC_MAP_ICON_BOTHERSOME_URL_64,
        };
      case 'critical':
        return {
          ...icon,
          url: GMP_STATIC_MAP_ICON_CRITICAL_URL_64,
        };
      case 'unknown':
        return icon;
      default:
        return icon;
    }
  }

  render() {
    const { t, height, markerLocation } = this.props;

    return markerLocation ? (
      <div style={{ height }} ref={(node) => (this.mapRef = node)}>
        ...
      </div>
    ) : (
      //todo: is it possible to integrate a fetch img before loading the real map?
      <LowSrcImgView t={t} height={height} src={lowSrc} alt="yard map position" />
    );
  }
}

MapContainer.propTypes = {
  t: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  markerLocation: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colorCode: PropTypes.string.isRequired,
};

const StaticMap = GoogleApiWrapper({
  apiKey: GMAP_API_KEY,
  language: 'en',
  region: 'CA',
  libraries: DEFAULT_GOOGLE_MAPS_API_OPTIONS.libraries,
})(MapContainer);

export default withRouter(StaticMap);

// import React from 'react';
// import PropTypes from 'prop-types';

// //  nectar
// import LowSrcImg from '../LowSrcImg/LowSrcImg';

// import {
//   GMAP_STATIC_MAP_BASE_URL,
//   GMP_STATIC_MAP_QUERY_PARAMS,
// } from '@config/google';

// import { getMarkerIcon } from '@helpers/icons';

// export const StaticMap = ({
//   lang,
//   zoom,
//   markerLocation,
//   colorCode,
//   t,
// }) => {
//   let queryString = '';

//   // building google static map url:
//   Object.entries(GMP_STATIC_MAP_QUERY_PARAMS)
//     .forEach((entryArr) => {
//       queryString += `${entryArr[0]}=${entryArr[1]}&`;
//     });

//   let icon = getMarkerIcon(colorCode);

//   queryString = queryString
//     // .replace('__CENTER__', center)
//     .replace('__MARKERS__', markerLocation)
//     .replace('__LANG__', lang)
//     .replace('__ZOOM__', zoom)
//     .replace('__ICON__', icon)

//   const url = `${GMAP_STATIC_MAP_BASE_URL}${queryString}`;

//   return <LowSrcImg src={url} alt="yard map position" marker={markerLocation} t={t} />;

// };

// StaticMap.propTypes = {
//   t: PropTypes.func.isRequired,
//   // center: PropTypes.string.isRequired,
//   lang: PropTypes.string.isRequired,
//   zoom: PropTypes.number.isRequired,
//   colorCode: PropTypes.string.isRequired,
//   markerLocation: PropTypes.string,
// };
