import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Card, CardContent, CardContentRow, CardHeader } from '@components/common/Card';
import { NotificationBell } from '@components/common/Icon';
import { SlimPage } from '@components/common/SlimPage';
import { Text } from '@components/common/Text';
import { Toggle } from '@components/form/Toggle';
import { makeChangeAccountRequestThunk } from '@redux/deprecated/actions';

import { NotificationRow } from './NotificationRow';
import { StyledGridRow, StyledVisibleBox } from './styles';

const shouldEnableAll = (alerts: Alerts): boolean => {
  let shouldEnable = false;
  for (const alert of Object.values(alerts)) {
    if (alert === true) {
      shouldEnable = true;
      break;
    }
  }
  return shouldEnable;
};

const TopRow = () => {
  const { t } = useTranslation();
  return (
    <StyledGridRow>
      <div>
        <Text typography="SmallParagraph" weight={'600'}>
          {t('settings')}
        </Text>
      </div>

      <Text typography="SmallParagraph" weight={'600'} style={{ textAlign: 'center' }}>
        <span>{t('email')}</span>
      </Text>
      <Text typography="SmallParagraph" weight={'600'} style={{ textAlign: 'center' }}>
        <span>{t('sms')}</span>
      </Text>
    </StyledGridRow>
  );
};

export const TabNotifications: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.accountReducer.user);
  const dispatch = useDispatch();

  const {
    alerts_new_yard_created_mail,
    alerts_new_yard_created_sms,
    alerts_hive_moved_mail,
    alerts_hive_moved_sms,
    alerts_hive_status_mail,
    alerts_hive_status_sms,
    alerts_daily_report_mail,
  } = user;

  const [notificationsState, setNotificationsState] = useState<Alerts>({
    alerts_new_yard_created_mail,
    alerts_new_yard_created_sms,
    alerts_hive_moved_mail,
    alerts_hive_moved_sms,
    alerts_hive_status_mail,
    alerts_hive_status_sms,
    alerts_daily_report_mail,
  });

  const enableAll = useMemo(() => shouldEnableAll(notificationsState), [notificationsState]);

  const updateAllAlerts = (value: boolean) => {
    const newState = {} as Alerts;

    (Object.keys(notificationsState) as (keyof Alerts)[]).forEach((key) => (newState[key] = value));

    setNotificationsState(newState);
    dispatch(makeChangeAccountRequestThunk(newState));
  };

  const updateSingleAlert = (value: boolean, checkboxName: string) => {
    const newState = { ...notificationsState, [checkboxName]: value };
    setNotificationsState(newState);
    dispatch(makeChangeAccountRequestThunk(newState));
  };

  return (
    <SlimPage>
      <Box marginBottomSM>
        <Text typography="Heading2">{t('beetrack_alerts')}</Text>
      </Box>

      <Box marginBottomLG>
        <Text typography="SmallParagraph">{t('beetrack_alerts_desc')}</Text>
      </Box>
      <Card>
        <CardHeader>
          <Text typography="SmallParagraph" weight={'600'}>
            <span style={{ paddingRight: '10px' }}>
              <NotificationBell />
            </span>
            {t('Notifications')}
          </Text>
          <Box>
            <StyledVisibleBox marginRightXS isVisible={!enableAll}>
              <Text typography="SmallParagraph" weight={'600'}>
                {t('off')}
              </Text>
            </StyledVisibleBox>
            <Toggle id="toggleAll" name="toggleAll" value={enableAll} onChange={(value) => updateAllAlerts(value)} />

            <StyledVisibleBox marginLeftXS isVisible={enableAll}>
              <Text typography="SmallParagraph" weight={'600'}>
                {t('on')}
              </Text>
            </StyledVisibleBox>
          </Box>
        </CardHeader>
        <CardContent>
          <CardContentRow>
            <TopRow key="heading" />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alert_label_new_yard"
              label="alert_label_new_yard"
              idMail="alerts_new_yard_created_mail"
              idSms="alerts_new_yard_created_sms"
              valueMail={notificationsState['alerts_new_yard_created_mail']}
              valueSms={notificationsState['alerts_new_yard_created_sms']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alert_label_hive_moved"
              label="alert_label_hive_moved"
              idMail="alerts_hive_moved_mail"
              idSms="alerts_hive_moved_sms"
              valueMail={notificationsState['alerts_hive_moved_mail']}
              valueSms={notificationsState['alerts_hive_moved_sms']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alert_label_hive_status"
              label="alert_label_hive_status"
              idMail="alerts_hive_status_mail"
              idSms="alerts_hive_status_sms"
              valueMail={notificationsState['alerts_hive_status_mail']}
              valueSms={notificationsState['alerts_hive_status_sms']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alerts_label_daily_report_mail"
              label="alerts_label_daily_report_mail"
              idMail="alerts_daily_report_mail"
              valueMail={notificationsState['alerts_daily_report_mail']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
        </CardContent>
      </Card>
    </SlimPage>
  );
};
