import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';

import { ColumnType } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { useTranslation } from '@hooks/useTranslation';

export function useColumnViewName(columnDef: ColDef) {
  const getter = useColumnViewNameGetter();
  return getter(columnDef);
}

export function useColumnViewNameGetter() {
  const t = useTranslation();

  return useCallback(
    (columnDef: ColDef) => {
      const activeView = GridApiUtil.getActiveView(columnDef);
      const column = GridApiUtil.getColumnDefWithMetadata(columnDef).metadata?.column;

      switch (activeView?.key) {
        case 'occurrencesSinceSeasonStart':
          return column?.type === ColumnType.PRACTICE
            ? t('view_number_of_occurrences_since_season_start_short')
            : t('view_occurrences_since_season_start_short');
        case 'lastOccurrenceDatetime':
          return t('view_last_occurrence_short');
        case 'periodSinceLastOccurrence':
          return t('view_period_since_last_occurrence_short');
        case 'workerLastOccurrence':
          return t('view_worker_last_occurrence');
        default:
          return null;
      }
    },
    [t]
  );
}
