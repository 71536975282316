import styled, { css, keyframes } from 'styled-components';

const appearingAnim = keyframes`
  from { width: 0; height: 0 }
  to { width: 48px; height: 48px }
`;

const wingsAnim = keyframes`
  0% { transform: rotate(-90deg); }
  50% { transform: rotate(60deg); }
  100% { transform: rotate(-90deg); }
`;

export const StyledBee = styled.div<{ $isFlying: boolean }>(
  ({ theme, $isFlying }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;

    animation-name: ${appearingAnim};
    animation-iteration-count: 1;
    animation-duration: 350ms;
    animation-timing-function: ${theme.animations.easing};

    transition: top 200ms ${theme.animations.easing}, left 200ms ${theme.animations.easing};

    svg {
      width: 100%;
      height: 100%;
    }

    ${StyledBeeWing} {
      animation-duration: 4s;
      &:last-child {
        animation-delay: 2s;
      }
    }

    ${$isFlying &&
    css`
      ${StyledBeeWing} {
        animation-duration: 40ms;
        &:last-child {
          animation-delay: 75ms;
        }
      }
    `}
  `
);

export const StyledBeeWing = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 2%;
    left: 38%;
    width: 18%;
    height: 26%;
    border-radius: 100%;
    background-color: ${theme.colors.coreFocus01};
    border: ${theme.colors.coreFocus02} 1px solid;

    transform-origin: center bottom;
    transform: rotate(-90deg);

    animation-name: ${wingsAnim};
    animation-iteration-count: infinite;
    animation-timing-function: ${theme.animations.easing};
  `
);
