import React, { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { groupBy } from 'lodash';

import { Box } from '@components/common/Box';
import { Check, IconIMG } from '@components/common/Icon';
import { ChevronRight } from '@components/common/Icon/presets/ChevronRight';
import { Tooltip } from '@components/common/Tooltip';

import { MenuItemTextView, StyledMenuItemGroupView, StyledMenuItemView, StypedMenuView } from './styles';
import { MenuItem, MenuProps } from './types';

export const Menu: React.FC<MenuProps> = ({ items, placement, subMenuProps, ...props }) => {
  const renderContent = useCallback((children: ReactNode) => children, []);

  const handleMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>, item: MenuItem) => {
    event.preventDefault();
    event.stopPropagation();
    item.onClick && item.onClick(event);
  }, []);

  const renderIcon = useCallback((item: MenuItem) => {
    if (item.icon) {
      if (typeof item.icon === 'string') {
        return <IconIMG size={18} color={'grey08'} src={item.icon} marginVerticalXXS marginLeftXS />;
      }

      return (
        <Box center marginVerticalXXS marginLeftXS>
          <item.icon size={18} color={'grey08'} />
        </Box>
      );
    }
    return null;
  }, []);

  const groupedItems = useMemo(() => {
    const groupsKeys = new Set(items.map((item) => item.group ?? ''));
    const groups = groupBy(items, (item) => item.group ?? '');
    return Array.from(groupsKeys).map((key) => groups[key]);
  }, [items]);

  return (
    <Tooltip interactive hideOnClick placement={placement ?? 'bottom'} renderContent={renderContent} {...props}>
      <StypedMenuView>
        {groupedItems.map((items, index) => (
          <StyledMenuItemGroupView key={index}>
            {(items || []).map((item, key) => (
              <StyledMenuItemView key={key} disabled={item.disabled} onClick={(event) => handleMenuClick(event, item)}>
                {renderIcon(item)}
                <MenuItemTextView typography={'Heading3'} weight={'600'}>
                  {item.title}
                </MenuItemTextView>

                {item.checked ? (
                  <Box marginLeftXXS>
                    <Check size={16} />
                  </Box>
                ) : null}

                {item.subItems?.length ? (
                  <>
                    <Box marginLeftXXS>
                      <ChevronRight size={16} />
                    </Box>
                    <Menu interactive trigger={'mouseenter focus click'} items={item.subItems} {...subMenuProps} />
                  </>
                ) : null}
              </StyledMenuItemView>
            ))}
          </StyledMenuItemGroupView>
        ))}
      </StypedMenuView>
    </Tooltip>
  );
};
