import React, { useMemo } from 'react';
import styled, { Color } from 'styled-components';

import { useTranslation } from '@hooks/useTranslation';

const StyledSpan = styled.span<{ color: Color }>(
  ({ theme, color }) => `
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      padding: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
      background-color: ${theme.colors[color]};
      color: ${theme.colors.grey08};
      border-radius: ${theme.spacing.xxs}px;

    `
);
export const YardStatusChip: React.FC<{ status: BeeDropStatus | BeeYardStatus }> = ({ status }) => {
  const t = useTranslation();
  const statusLowerCased = status.toLowerCase();
  const color: Color = useMemo(() => {
    switch (statusLowerCased) {
      case 'active':
        return 'green01';
      case 'emptied':
      case 'unloaded':
        return 'yellow01';
      default:
        return 'grey05';
    }
  }, [statusLowerCased]);
  return <StyledSpan color={color}>{t(statusLowerCased)}</StyledSpan>;
};
