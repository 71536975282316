import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import APP from '@config/constants';
import { QueryParams } from '@helpers/QueryParams';

// nectar
import NavBarTitleView from './NavBarTitleView';

class NavBarTitle extends React.Component {
  constructor(props) {
    super(props);
    this.goBackHandler = this.goBackHandler.bind(this);
    this.goHomeHandler = this.goHomeHandler.bind(this);
  }

  goHomeHandler(e) {
    const { history } = this.props;

    if (!history) {
      e.preventDefault();
    } else {
      history.push({
        pathname: APP.routes.home,
      });
    }
  }

  async goBackHandler(e) {
    e.preventDefault();

    const { history } = this.props;

    if (history) {
      const previousPathname = QueryParams.popLatestPathname() ?? APP.routes.home;
      history.push(previousPathname);
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { t, content, isMobile } = this.props;

    return (
      <NavBarTitleView
        t={t}
        content={content}
        isMobile={isMobile}
        handleGoBack={this.goBackHandler}
        handleGoHome={this.goHomeHandler}
      />
    );
  }
}

NavBarTitle.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

// redux will inject content obj in the props:
const mapStateToProps = (state) => ({
  content: state.navBarTitleReducer.content,
});

// note : order of HOC matters here
// @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md
export default withRouter(connect(mapStateToProps, null)(withTranslation()(NavBarTitle)));
