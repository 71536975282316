import React from 'react';
import { ColDef } from 'ag-grid-community';
import styled from 'styled-components';

import { Accordion, AccordionContent, AccordionHeader } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { Checkbox } from '@components/form/CheckBox';
import { useTranslation } from '@hooks/useTranslation';

import { useColumnNameGetter } from '../YardsList/hooks/useColumnNameGetter';

const StyledAccordion = styled(Accordion)(
  ({ theme }) => `

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.grey04};
    }

    button {
      padding: 0;
      margin: 0;
  
      &::before {
        content: initial;
      }
    }
  `
);

const StyledAccordionContent = styled(AccordionContent)(
  ({ theme }) => `
    padding: 0;
    margin: 0;
  `
);

export const YardsListCategoryAccordion: React.VFC<{
  group: Array<ColDef<any>>;
  toggleColumn: (col: ColDef<any> | ColDef<any>[], shouldHide?: boolean) => void;
}> = ({ group, toggleColumn }) => {
  const t = useTranslation();
  const [category, ...practices] = group;

  const getColumnName = useColumnNameGetter();
  return (
    <StyledAccordion initiallyExpanded>
      <AccordionHeader>
        <Box key={category.colId} alignItems="center" paddingVerticalSM>
          <Text typography={'Heading3'} weight={'600'}>
            {getColumnName(category, category.headerName)}
          </Text>
        </Box>
      </AccordionHeader>
      <StyledAccordionContent>
        <Box key={category.colId} tag={'label'} alignItems="center" paddingBottomSM>
          <Checkbox value={!category.hide} name={category.colId as string} onChange={() => toggleColumn(category)} />
          <Box marginLeftSM>
            <Text typography={'Heading3'}>
              <span>{getColumnName(category, category.headerName)}</span>{' '}
              <Text typography="Heading3" htmlTag="span" color="grey06">
                [{t('category')}]
              </Text>
            </Text>
          </Box>
        </Box>
        {practices.map((columnDef: ColDef<any>) => {
          return (
            <Box key={columnDef.colId} tag={'label'} alignItems="center" paddingBottomSM>
              <Checkbox
                value={!columnDef.hide}
                name={columnDef.colId as string}
                onChange={() => toggleColumn(columnDef)}
              />
              <Box marginLeftSM>
                <Text typography={'Heading3'}>{getColumnName(columnDef, columnDef.headerName)}</Text>
              </Box>
            </Box>
          );
        })}
      </StyledAccordionContent>
    </StyledAccordion>
  );
};
