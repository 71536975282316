/**
 * This component is deprecated.
 * It will be removed once its usage is completely replaced.
 * */

// vendor
import styled from 'styled-components';

export const Table = styled.table`
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.boxShadow1};
  font-size: ${(props) => props.theme.captionSmallFontSize};
  padding: 0 3rem;
  thead {
    white-space: nowrap;
  }
  tbody {
    tr {
      &:hover {
        background-color: ${(props) => props.theme.hoverColor};
      }
    }
  }
  tr {
    width: 100%;
    background-color: ${(props) => props.theme.primaryBackgroundColor};
    height: ${(props) => props.theme.tabBarHeight}px;
    box-shadow: ${(props) => props.theme.tableRowBoxShadow};
    color: ${(props) => props.theme.primaryTextColor};
    font-weight: ${(props) => props.theme.fontWeightSemiBold};
    letter-spacing: 0.5px;
    line-height: 1.5rem;
    td,
    th {
      padding-left: 2rem;
    }
  }
  th {
    height: 64px;
    cursor: pointer;
    vertical-align: middle;
    text-align: left;
  }
  th:first-letter {
    text-transform: uppercase;
  }
  td {
    letter-spacing: 0;
    height: 64px;
    text-transform: none;
    vertical-align: middle;
    font-weight: ${(props) => props.theme.fontWeightRegular};
  }
`;
