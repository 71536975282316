import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Contract = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V5.5H16.5V1H5ZM17.5 4.5V1L21 4.5H17.5ZM5 8.75C5 8.33579 5.33579 8 5.75 8H18.25C18.6642 8 19 8.33579 19 8.75C19 9.16421 18.6642 9.5 18.25 9.5H5.75C5.33579 9.5 5 9.16421 5 8.75ZM5 12.75C5 12.3358 5.33579 12 5.75 12H18.25C18.6642 12 19 12.3358 19 12.75C19 13.1642 18.6642 13.5 18.25 13.5H5.75C5.33579 13.5 5 13.1642 5 12.75ZM5.75 16C5.33579 16 5 16.3358 5 16.75C5 17.1642 5.33579 17.5 5.75 17.5H13.25C13.6642 17.5 14 17.1642 14 16.75C14 16.3358 13.6642 16 13.25 16H5.75Z"
      />
    </IconSVGView>
  );
};
