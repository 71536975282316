export const getTooltipTrendText = (t, population) => {
  switch (true) {
    case 0 === population:
      return t('tooltip_yard_population_same');
    case 0 < population:
      return t('tooltip_yard_population_incr');
    case 0 > population:
      return t('tooltip_yard_population_decr');
    case null === population:
      return t('tooltip_unknown');
    default:
      break;
  }
};

export const getTooltipStatusText = (t, criticality) => {
  switch (true) {
    case criticality > 30:
      return t('tooltip_critical_status');
    case criticality <= 30 && criticality >= 15:
      return t('tooltip_worrying_status');
    case criticality < 15:
      return t('tooltip_good_status');
    case null:
      return t('tooltip_unknown');
    default:
      break;
  }
};

export const getTooltipFiltersText = (t, key) => {
  switch (key) {
    case 'active_yards':
      return t('tooltip_active_yards');
    case 'requiring_attention':
      return t('tooltip_requiring_attention');
    default:
      break;
  }
};
