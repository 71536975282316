import React from 'react';
import PropTypes from 'prop-types';

import './spinner.css';

export const SpinnerView = ({ isSpinning }) => {
  if (isSpinning) {
    return <div className="ch-loading-spinner" />;
  }
  return null;
};

SpinnerView.propTypes = {
  isSpinning: PropTypes.bool,
};
