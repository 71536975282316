import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// vendor:
import { GoogleApiWrapper } from 'google-maps-react';
import * as jsts from 'jsts';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import wkx from 'wkx';

import emptyMarkerSvgIcon from '@assets/gmap-icons/marker-dynamic-transparent.svg';
import hiveIcon from '@assets/marker-hive.png';
import alertIcon from '@assets/Small_Alert.svg';
import layersIcon from '@assets/Small_Layers.svg';
import { DEFAULT_GOOGLE_MAPS_API_OPTIONS } from '@components/common/Map/apis/GoogleMaps/constants';
import { AlertIcon, StyledErrorTitle } from '@components/deprecated/Elements/SummaryElements';
import {
  DEFAULT_MAP_CONFIG,
  EDITABLE_YARD_BOUNDS_STYLE,
  GMAP_API_KEY,
  GMAP_ICON_SIZES,
  GMAP_TYPE,
  SHOW_YARD_HIVES_ZOOM,
  YARD_BOUNDS_STYLE,
} from '@config/google';
import { isEmptyArray } from '@helpers/deprecated/array';
import { initYardVertexDelete } from '@helpers/deprecated/map';
// nectar
import { makeSetZoomAction, makeShowSnackbarAction, toggleMapType } from '@redux/deprecated/actions';
import theme from '@style/theme';

import { StyledButton, StyledIcon, ZoomControlsView } from './components/ZoomControlsView';

import './map-styles.css';

const ErrorContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 2.5rem;
  text-align: left;
  align-items: center;
  padding-left: 1rem;
  background-color: #f0c8c4;
  z-index: 2;
`;

class GmapContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markerDescriptors: [],
      yardPolygons: [],
      hiveDescriptors: [],
      polygonHives: [],
      errorMsgHives: false,
      errorMsgShapes: false,
    };

    this.loadMap = this.loadMap.bind(this);
    this.renderHives = this.renderHives.bind(this);
    this.clearHives = this.clearHives.bind(this);
    this.handleLayerTypeButtonToggle = this.handleLayerTypeButtonToggle.bind(this);
    this.setEditablePolygon = this.setEditablePolygon.bind(this);
    this.trackZoomAndCenter = this.trackZoomAndCenter.bind(this);
    this.validateAndSetYardGeometry = debounce(this.validateAndSetYardGeometry, 500);
    this.checkPolygonsIntersecting = this.checkPolygonsIntersecting.bind(this);
    this.polygonContainsAllHives = this.polygonContainsAllHives.bind(this);
    this.renderNearbyYardBoundaries = this.renderNearbyYardBoundaries.bind(this);
  }

  componentDidMount() {
    const { zoom } = this.props;
    this.loadMap();
    this.renderNearbyYardBoundaries();
    if (zoom > SHOW_YARD_HIVES_ZOOM) this.renderHives();
    this.trackZoomAndCenter();

    if (!zoom) this.setBounds();
    this.setEditablePolygon();
  }

  loadMap() {
    const { google, zoom, type, yard } = this.props;

    if (this.props && google && yard?.yard_center) {
      const { yard_center } = yard;

      const lat = yard_center.coordinates[1];
      const lng = yard_center.coordinates[0];

      // google props is available
      const maps = google.maps;
      const center = new maps.LatLng(lat, lng);

      const mapConfig = Object.assign(
        {},
        {
          zoom,
          center,
          mapTypeId: GMAP_TYPE.satellite === type ? google.maps.MapTypeId.SATELLITE : google.maps.MapTypeId.TERRAIN,
          ...DEFAULT_MAP_CONFIG,
        }
      );

      //load map once DOM is ready
      this.map = new maps.Map(this.mapRef, mapConfig);

      // no need to render anything:
      return null;
    }
  }

  renderNearbyYardBoundaries() {
    //render nearby yards
    const { yard, google } = this.props;
    const { nearby_yards } = yard;
    const maps = google.maps;

    let markerDescriptors = nearby_yards
      ?.filter((yard) => yard.yard_center)
      .map((yard, idx) => ({
        position: {
          lat: yard.yard_center?.coordinates[1],
          lng: yard.yard_center?.coordinates[0],
        },
        geometry: yard.geometry,
        id: idx,
        label: yard.name,
        icon: {
          origin: new maps.Point(0, 0), // origin
          anchor: new maps.Point(GMAP_ICON_SIZES.x / 2, GMAP_ICON_SIZES.y / 2), // anchor
          scaledSize: new maps.Size(GMAP_ICON_SIZES.x, GMAP_ICON_SIZES.y),
          url: emptyMarkerSvgIcon,
        },
      }));

    if (!this.map || !markerDescriptors?.length) {
      return null;
    }

    // render nearby yard markers
    const gMarkers = markerDescriptors.map((mdesc) => {
      let gMarker = new maps.Marker({
        position: mdesc.position,
        icon: mdesc.icon,
        map: this.map,
        id: mdesc.id,
        draggable: false,
      });

      var contentString = `<div class="container">
            <div id=infowindow${mdesc.id} class="infowindow-container">
            <p class="infowindow-desc-name">${mdesc.label}</p>
            </div><div class="infowindow-arrow-down"></div></div>`;

      var infowindow = new maps.InfoWindow({
        pixelOffset: new maps.Size(0, 35),
        content: contentString,
        disableAutoPan: true,
      });

      infowindow.open(this.map, gMarker);

      return gMarker;
    });

    // render nearby yard polygons
    const yardPolygons = markerDescriptors.map((mdesc) => {
      const { coordinates } = mdesc.geometry;

      var polygonCoords = coordinates[0].map((c) => {
        return { lat: c[1], lng: c[0] };
      });

      return new maps.Polygon({
        ...YARD_BOUNDS_STYLE,
        map: this.map,
        paths: polygonCoords,
      });
    });

    this.setState({ yardPolygons, gMarkers, markerDescriptors });
  }

  setEditablePolygon() {
    if (this.props && this.props.google) {
      // google props is available
      const { google, geometry, makeShowSnackbarAction } = this.props;

      const polygonCoords = geometry[0].map((c) => {
        return { lat: c[1], lng: c[0] };
      });

      const maps = google.maps;

      // Define a rectangle and set its editable property to true.
      const polygon = new google.maps.Polygon({
        map: this.map,
        paths: polygonCoords,
        ...EDITABLE_YARD_BOUNDS_STYLE,
      });

      maps.event.addListener(polygon, 'dragend', () => {
        // console.log("Polygon was dragged");
        const paths = polygon.getPath();
        this.validateAndSetYardGeometry(paths);
      });

      const paths = polygon.getPath();

      initYardVertexDelete(polygon, maps, this.map, makeShowSnackbarAction, paths, () =>
        this.validateAndSetYardGeometry(paths)
      );

      ['insert_at', 'set_at'].map((listener) =>
        maps.event.addListener(paths, listener, (index, obj) => {
          this.validateAndSetYardGeometry(paths);
        })
      );
    }
  }

  validateAndSetYardGeometry(paths) {
    const { setNewYardGeometry, google } = this.props;
    const { maps } = google;
    const polygon = new maps.Polygon({ paths });

    this.polygonContainsAllHives(polygon);
    this.checkPolygonsIntersecting(polygon);
    setNewYardGeometry(paths);
  }

  polygonContainsAllHives(paths) {
    const { t, yard, google, validateGeometry } = this.props;
    const { maps } = google;
    const { hives_position } = yard;

    const doesPolygonContainAllHives = hives_position.every((hive) => {
      if (!hive) return true; // if hive doesn't exist, do not mess the function
      var geometry = wkx.Geometry.parse(hive);
      let point = new maps.LatLng(geometry.y, geometry.x);
      return maps.geometry.poly.containsLocation(point, paths);
    });
    this.setState({ errorMsgHives: !doesPolygonContainAllHives ? t('yard_not_all_hives_included_error_msg') : false });

    validateGeometry('hives', !doesPolygonContainAllHives);
  }

  checkPolygonsIntersecting(polygon) {
    const { t, validateGeometry } = this.props;
    const { yardPolygons } = this.state;

    let polygonsIntersectingArray = [];
    var intersectingShape = [];

    const geometryFactory = new jsts.geom.GeometryFactory();
    const firstPolygon = createJstsPolygon(geometryFactory, polygon);
    const bufferedFirst = firstPolygon.buffer(0);

    for (let j = 0; j < yardPolygons.length; j++) {
      var secondPolygon = createJstsPolygon(geometryFactory, yardPolygons[j]);
      var bufferedSecond = secondPolygon.buffer(0);
      var intrsctn = bufferedFirst.intersection(bufferedSecond);

      // if there is a requirement to show the intersecting shape
      intersectingShape = intrsctn.getCoordinates().map((coord) => ({ lat: coord.x, lng: coord.y }));
      polygonsIntersectingArray.push(intersectingShape);
    }

    const intersectionExists = polygonsIntersectingArray.some((e) => !isEmptyArray(e));

    validateGeometry('shape', intersectionExists);

    this.setState({
      errorMsgShapes: intersectionExists ? t('yards_intersecting_error_msg') : false,
    });

    function createJstsPolygon(geometryFactory, polygon) {
      var path = polygon.getPath();
      var coordinates = path.getArray().map(function name(coord) {
        return new jsts.geom.Coordinate(coord.lat(), coord.lng());
      });
      if (coordinates[0].compareTo(coordinates[coordinates.length - 1]) !== 0) coordinates.push(coordinates[0]);
      var shell = geometryFactory.createLinearRing(coordinates);
      return geometryFactory.createPolygon(shell);
    }
  }

  trackZoomAndCenter() {
    const { google, setZoom } = this.props;

    if (google && this.map) {
      const { maps } = google;

      new maps.event.addListener(this.map, 'zoom_changed', () => {
        let zoom = this.map.getZoom();
        setZoom(zoom);
        const { polygonHives, hiveDescriptors } = this.state;

        if (zoom <= SHOW_YARD_HIVES_ZOOM && (!isEmptyArray(hiveDescriptors) || !isEmptyArray(polygonHives)))
          this.clearHives();
        else if (zoom > SHOW_YARD_HIVES_ZOOM && (isEmptyArray(hiveDescriptors) || isEmptyArray(polygonHives)))
          this.renderHives();
      });
    }
  }

  handleLayerTypeButtonToggle(e) {
    const { zoom, toggleMapType } = this.props;
    toggleMapType();

    this.loadMap();
    this.setEditablePolygon();
    this.trackZoomAndCenter();
    if (zoom <= SHOW_YARD_HIVES_ZOOM) this.clearHives();
    else if (zoom > SHOW_YARD_HIVES_ZOOM) this.renderHives();
  }

  setBounds() {
    // google props is available
    const { google, geometry } = this.props;

    if (google && this.map) {
      const { maps } = google;

      //center map by bounds, not center
      const bounds = new maps.LatLngBounds();

      geometry &&
        !isEmptyArray(geometry) &&
        geometry[0].forEach((c) => {
          bounds.extend({
            lat: c[1],
            lng: c[0],
          });
        });
      //fit the map to the bounds of existing markers on first load
      this.map.fitBounds(bounds);
    }
  }

  renderHives() {
    // console.log("renderHives")
    const { markerDescriptors } = this.state;
    const { google, yard } = this.props;

    let hiveDescriptors = [];
    let polygonHives = [];
    const { hives_position } = yard;

    if (google && this.map) {
      const { maps } = google;
      if (!isEmptyArray(hives_position)) {
        hives_position.forEach((hive) => {
          if (hive) {
            var geometry = wkx.Geometry.parse(hive);
            var hiveMarker = new maps.Marker({
              position: { lat: geometry.y, lng: geometry.x },
              icon: hiveIcon,
              map: this.map,
            });
            polygonHives.push(hiveMarker);
          }
          return polygonHives;
        });
      }

      markerDescriptors.map((mdesc) => {
        const { hives_position } = mdesc;

        if (!isEmptyArray(hives_position)) {
          hives_position.map((hive) => {
            if (hive) {
              var geometry = wkx.Geometry.parse(hive);

              var hiveMarker = new google.maps.Marker({
                position: { lat: geometry.y, lng: geometry.x },
                icon: hiveIcon,
                map: this.map,
              });

              hiveDescriptors.push(hiveMarker);
            }
            return hiveDescriptors;
          });
        }
        return hiveDescriptors;
      });

      this.setState({ hiveDescriptors, polygonHives });
    }
  }

  clearHives() {
    // console.log("clearHives")
    const { hiveDescriptors, polygonHives } = this.state;
    hiveDescriptors.map((hive) => hive.setMap(null));
    polygonHives.map((hive) => hive.setMap(null));

    this.setState({ hiveDescriptors: [], polygonHives: [] });
  }

  render() {
    const { isMobile, zoom } = this.props;

    const { errorMsgHives, errorMsgShapes } = this.state;

    let error = errorMsgHives || errorMsgShapes;

    return (
      <div style={{ position: 'relative' }}>
        {error && (
          <ErrorContainer>
            <AlertIcon src={alertIcon} alt="yard alert" />
            <StyledErrorTitle>{error}</StyledErrorTitle>
          </ErrorContainer>
        )}
        <div
          style={{
            height: isMobile
              ? window.innerHeight - theme.topBottomMargin - theme.activityHeaderHeight
              : theme.mapModalHeight,
            position: 'relative',
          }}
          ref={(node) => (this.mapRef = node)}
        >
          ...
        </div>

        <StyledButton
          style={{
            borderRadius: '3px',
            bottom: '129px',
          }}
          onClick={this.handleLayerTypeButtonToggle}
        >
          <StyledIcon src={layersIcon} alt="change map type" />
        </StyledButton>
        <ZoomControlsView
          key={zoom}
          zoom={zoom}
          handleZoomIn={() => this.map?.setZoom(this.map?.getZoom() + 1)}
          handleZoomOut={() => this.map?.setZoom(this.map?.getZoom() - 1)}
        />
      </div>
    );
  }
}

GmapContainer.propTypes = {
  t: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  geometry: PropTypes.array.isRequired,
  setNewYardGeometry: PropTypes.func.isRequired,
  validateGeometry: PropTypes.func.isRequired,
  zoom: PropTypes.number,
  type: PropTypes.string.isRequired,
  yard: PropTypes.object.isRequired,
  setZoom: PropTypes.func.isRequired,
  toggleMapType: PropTypes.func.isRequired,
  makeShowSnackbarAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  zoom: state.modalReducer.zoom,
  yard: state.beeTrackYardListReducer.yard,
  type: state.mapReducer.type,
});

/**
 *
 * @param dispatch
 * @returns {{setZoom: makeSetZoomAction}}
 * @returns {{toggleMapType: toggleMapType}}
 * @returns {{makeShowSnackbarAction: makeShowSnackbarAction}}
 *
 */
const mapDispatchToProps = (dispatch) => ({
  setZoom: (zoom) => {
    dispatch(makeSetZoomAction(zoom));
  },
  toggleMapType: () => {
    dispatch(toggleMapType());
  },
  makeShowSnackbarAction: (msg) => {
    dispatch(makeShowSnackbarAction(msg));
  },
});

const BeeTrackEditBoundariesMap = GoogleApiWrapper({
  apiKey: GMAP_API_KEY,
  language: 'en',
  region: 'CA',
  libraries: DEFAULT_GOOGLE_MAPS_API_OPTIONS.libraries,
})(GmapContainer);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BeeTrackEditBoundariesMap));
