import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidatorBuilder, ValidatorBuilderOptions } from '@components/form/InputTextV2/types';
import { Validators } from '@components/form/InputTextV2/validators';

export function useValidator<V extends ValidatorBuilder>(validatorBuilder: V, options?: ValidatorBuilderOptions<V>) {
  const { t } = useTranslation();

  const { errorMessage: errorMessageI18nKey, ...otherOptions } = options ?? {};

  const errorMessage = useMemo(() => {
    if (errorMessageI18nKey) {
      return t(errorMessageI18nKey);
    }

    // TODO: Add more default error messages.
    switch (validatorBuilder) {
      case Validators.money:
        return t('error_message_money');
      case Validators.required:
        return t('error_message_required');
      case Validators.minLength:
        return t('error_message_min_length', { min: options?.min });
      case Validators.maxLength:
        return t('error_message_max_length', { max: options?.max });
      case Validators.min:
        return t('error_message_min', { min: options?.min });
      case Validators.max:
        return t('error_message_max', { max: options?.max });
      case Validators.range:
        return t('error_message_max', { min: options?.min, max: options?.max });
      case Validators.integer:
        return t('error_message_integer');
      case Validators.email:
        return t('error_message_email');
      default:
        return undefined;
    }
  }, [errorMessageI18nKey, options?.max, options?.min, t, validatorBuilder]);

  return validatorBuilder({ errorMessage, ...otherOptions } as ValidatorBuilderOptions<V>);
}
