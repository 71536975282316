import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common/Text';

export const ContractDetailInstructions: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Text typography="SmallParagraph" weight="600" color="grey06">
      {t('pollination_contract_detail_instruction')}
    </Text>
  );
};
