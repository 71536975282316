import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { capitalize } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

import editIcon from '@assets/xSmall_Edit.svg';
import { Box } from '@components/common/Box';
import { Menu } from '@components/common/Menu';
import { createHeader, Table } from '@components/common/Table';
import { TableCellRenderOptions, TableRowRenderOptions } from '@components/common/Table/types';
import { Text } from '@components/common/Text';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipProps } from '@components/common/Tooltip/types';
import APP from '@config/constants';
import { formatPhoneNumber } from '@helpers/deprecated/formatPhoneNumber';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { moneyFormatter, numberFormatter } from '@helpers/StringTransformers';
import { useCropTypes } from '@hooks/useCropTypes';
import { makeOpenContractModal } from '@redux/Contract/actions';
import { ContractsStats } from '@redux/Contract/types';

import { StyledHiveCount, StyledTableHeader, StyledTextLimit } from './styles';

const StyledSecondLineText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xxs};
`;

const StyledMenuTriggerWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

interface ContractRow {
  name: string;
  grower: string;
  contact: {
    name: string | null;
    phoneNumber: string | null;
  };

  cropTypes: number[];
  blocks: number | undefined;
  drops: number | undefined;
  hives: {
    nbRequiredHives: number | undefined;
    nbTargetedHives: number | undefined;
    nbTrackedHives: number | undefined;
  };
  beesIn: Date | null;
  beesOut: Date | null;
  contractYear: number | string;
  price: {
    costPerHive: number | undefined;
    nbHives: number | undefined;
    totalPrice: number | undefined;
  };
  status: string;
  menu: string;
  id: number;
}

const convertContractToTableRow = (contract: BeeContract): ContractRow => {
  return {
    name: contract.name,
    grower: contract.grower.name,
    contact: {
      name: contract.grower.contactName,
      phoneNumber: contract.grower.contactPhoneNumber,
    },

    cropTypes: contract.cropTypesIds,
    contractYear: contract.contractYear ?? '-',
    blocks: contract.nbBlocks ?? 0,
    drops: contract.nbDrops ?? 0,
    hives: {
      nbRequiredHives: contract.nbRequiredHives ?? 0,
      nbTargetedHives: contract.nbTargetedHives ?? 0,
      nbTrackedHives: contract.nbTrackedHives ?? 0,
    },
    beesIn: contract.beesIn,
    beesOut: contract.beesOut,
    price: {
      costPerHive: contract.costPerHive,
      nbHives: contract.nbRequiredHives,
      totalPrice: contract.totalPrice,
    },
    status: '-',
    menu: '',
    id: contract.id,
  };
};

export const PolliContractTable: React.FC<{
  contracts: BeeContract[];
  contractsStats: ContractsStats | null;
  totalContracts: number | null;
}> = ({ contracts, contractsStats, totalContracts }) => {
  const { t } = useTranslation();
  const { getCropTypesVisualString } = useCropTypes();
  const dispatch = useDispatch();
  const history = useHistory();
  const onItemClick = useCallback(
    (options: TableRowRenderOptions<ContractRow>) => {
      const { id } = options.item;
      history.push(generatePath(APP.routes.pollinationContract, { uid: id.toString() }));
    },
    [history]
  );

  const openEditModal = useCallback(
    (id) => {
      const currentContract = contracts.find((contract) => contract.id === id);
      dispatch(makeOpenContractModal(currentContract));
    },
    [dispatch, contracts]
  );

  const headers = useMemo(() => {
    let order = 0;
    const tooltipProps: TooltipProps = { placement: 'top' };

    return {
      ...createHeader('name', order++, { title: t('pollination_contract_name') }),
      ...createHeader('grower', order++, { title: t('grower') }),
      ...createHeader('contact', order++, { title: t('contact') }),
      ...createHeader('nbRequiredHives', order++, {
        title: t('pollination_hives_required_header'),
        tooltip: t('pollination_hives_required_header_hint'),
        tooltipProps,
      }),
      ...createHeader('nbTargetedHives', order++, {
        title: t('pollination_hives_on_map_header'),
        tooltip: t('pollination_hives_on_map_header_hint'),
        tooltipProps,
      }),
      ...createHeader('nbTrackedHives', order++, {
        title: t('pollination_hives_on_site_header'),
        tooltip: t('pollination_hives_on_site_header_hint'),
        tooltipProps,
      }),
      ...createHeader('drops', order++, { title: t('pollination_drops_number'), sortable: false }),
      ...createHeader('blocks', order++, { title: t('pollination_blocks_number'), sortable: false }),
      ...createHeader('cropTypes', order++, { title: t('crop_type'), sortable: false }), // all sortable:false will be sortable: true later
      ...createHeader('contractYear', order++, { title: t('year') }),
      ...createHeader('beesIn', order++, { title: t('pollination_bees_in'), sortable: false }),
      ...createHeader('beesOut', order++, { title: t('pollination_bees_out'), sortable: false }),
      ...createHeader('price', order++, { title: t('price'), sortable: false }),
      // ...createHeader('status', order++, { title: t('status'), sortable: true }),
      ...createHeader('menu', order++, { title: '' }),
    };
  }, [t]);

  const renderCell = useCallback(
    ({ header, item, value }: TableCellRenderOptions<ContractRow>) => {
      const noContent = (
        <Text typography="CaptionSmall" color="grey06">
          {' - '}
        </Text>
      );

      switch (header) {
        case 'name':
        case 'grower':
          return (
            <Text typography={'CaptionSmall'} weight={'600'}>
              {value}
            </Text>
          );
        case 'contact':
          return (
            <div>
              <Text htmlTag="p" typography="CaptionSmall">
                {item.contact.name}
              </Text>
              <StyledSecondLineText typography="CaptionSmall" color="grey06">
                <NumberFormat
                  value={item.contact.phoneNumber}
                  displayType={'text'}
                  format={formatPhoneNumber(item.contact.phoneNumber)}
                />
              </StyledSecondLineText>
            </div>
          );
        case 'nbRequiredHives':
        case 'nbTargetedHives':
        case 'nbTrackedHives':
          const val = item.hives[header] ?? 0;
          const requiredHives = item.hives.nbRequiredHives ?? 0;
          const warned = ['nbTargetedHives', 'nbTrackedHives'].includes(header) && val !== 0 && val !== requiredHives;
          const hasUnderFlow = val < requiredHives;
          const onMapHint = hasUnderFlow
            ? 'pollination_hives_on_map_underflow_hint'
            : 'pollination_hives_on_map_overflow_hint';
          const onSiteHint = hasUnderFlow
            ? 'pollination_hives_on_site_underflow_hint'
            : 'pollination_hives_on_site_overflow_hint';
          const hint = header === 'nbTargetedHives' ? onMapHint : onSiteHint;
          return (
            <StyledHiveCount $warned={warned} typography={'CaptionSmall'}>
              {val} {t('Hives')}
              {warned && (
                <Tooltip placement={'top-start'}>
                  <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t(hint) }} />
                </Tooltip>
              )}
            </StyledHiveCount>
          );
        case 'beesIn':
        case 'beesOut':
          if (moment.isDate(value)) {
            return <Text typography="CaptionSmall">{moment(value).format('MMM DD, YYYY')}</Text>;
          }
          if (typeof value === 'string') return <Text typography={'CaptionSmall'}>{value || ''}</Text>;
          return noContent;
        case 'menu':
          return (
            <StyledMenuTriggerWrapper>
              <ThreeDotsMenuTrigger id={`menu-${item.id}`} />
              <Menu
                items={[{ title: t('edit_contract'), icon: editIcon, onClick: () => openEditModal(item.id) }]}
                placement={'bottom-end'}
                target={`menu-${item.id}`}
              />
            </StyledMenuTriggerWrapper>
          );
        case 'cropTypes':
          const cropTypes = getCropTypesVisualString(item.cropTypes);
          return (
            <StyledTextLimit>
              <Text typography="CaptionSmall">{cropTypes}</Text>
            </StyledTextLimit>
          );
        case 'blocks':
          return value ? (
            <Text typography={'CaptionSmall'}>{maybePluralize(value, 'block', t).toLowerCase()}</Text>
          ) : (
            noContent
          );
        case 'drops':
          return value ? (
            <Text typography={'CaptionSmall'}>{maybePluralize(value, 'drop', t).toLowerCase()}</Text>
          ) : (
            noContent
          );
        case 'price':
          if (!item.price.costPerHive && !item.price.nbHives && !item.price.totalPrice) {
            return noContent;
          }
          return (
            <div>
              {!!item.price.costPerHive || !!item.price.nbHives ? (
                <>
                  <Text typography="CaptionSmall" color="grey06">
                    {item.price.costPerHive ? moneyFormatter(item.price.costPerHive) : '$ - '}
                  </Text>
                  <Text typography="CaptionSmall" color="grey06">
                    {item.price.nbHives ? ` x ${numberFormatter(item.price.nbHives)}` : t('per_hive')}
                  </Text>
                </>
              ) : (
                noContent
              )}

              <StyledSecondLineText htmlTag="p" typography="CaptionSmall" color="grey08">
                {item.price.totalPrice ? moneyFormatter(item.price.totalPrice) : '$ -'}
              </StyledSecondLineText>
            </div>
          );
        // case 'status':
        //   return
        default:
          return value ? <Text typography={'CaptionSmall'}>{value || ''}</Text> : noContent;
      }
    },
    [getCropTypesVisualString, openEditModal, t]
  );

  const renderHeaderTop = useCallback(() => {
    if (!contractsStats) {
      return <></>;
    }

    const headerValues = [
      { value: totalContracts, label: capitalize(t('contracts')) },
      { value: contractsStats.nbDrops ?? 0, label: t('drops') },
      { value: contractsStats.nbHives ?? 0, label: t('hives_in_stock'), tooltip: t('hives_in_stock_hint') },
      {
        value: moneyFormatter(contractsStats.latestYearTotalIncome ?? 0),
        label: t('income_year', { year: contractsStats.latestYear }),
        tooltip: t('income_year_hint'),
      },
    ];

    return (
      <StyledTableHeader paddingVerticalSM paddingHorizontalSM>
        {headerValues.map(({ value, label, tooltip }, index) => (
          <Box key={index} column alignItems="flex-start" marginLeftXS marginRightMD>
            <Text typography={'Heading2'} weight={'600'}>
              {value}
            </Text>
            <Text typography={'CaptionSmall'} color={'grey06'} dashed={!!tooltip}>
              {label}
              {!!tooltip && (
                <Tooltip>
                  <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltip }} />
                </Tooltip>
              )}
            </Text>
          </Box>
        ))}
      </StyledTableHeader>
    );
  }, [contractsStats, t, totalContracts]);

  return (
    <Box marginBottomMD column stretch>
      <Table
        items={contracts.map((contract) => convertContractToTableRow(contract))}
        onItemClick={onItemClick}
        renderCell={renderCell}
        renderHeaderTop={renderHeaderTop}
        headers={headers}
      />
    </Box>
  );
};
