import styled from 'styled-components';

export const UnstyledButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background-color: none;

  :focus {
    outline: initial; // todo: get proper focus styles from design team
  }
`;
