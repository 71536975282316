import React from 'react';
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import { isEmptyArray } from '@helpers/deprecated/array';
// nectar
import theme from '@style/theme';

const StyledRow = styled(Row)`
  display: flex;
  min-height: ${(props) => props.theme.tabBarHeight}px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  box-shadow: ${(props) => props.theme.boxShadow1};
  margin-top: ${(props) => (props.isMobile ? props.theme.tabBarHeight : props.theme.topBarHeight)}px;
`;

const StyleBtnContainer = styled(Col)`
  flex: 1;
  text-align: center;
`;

const StyleBtn = styled.button`
  display: inline-block;
  line-height: ${(props) => props.theme.tabBarHeight}px;
  &:focus {
    outline: none;
  }
`;

const StyleBtnContent = styled.p`
  text-transform: uppercase;
  color: ${(props) => props.theme.buttonPrimaryColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
  &:focus {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

const activeStyle = {
  boxShadow: theme.navigationBoxShadow,
};

export const YardTopBarView = ({ t, tabs, style, isMobile, handleChangeTabClick, activeItem = 'summary' }) => (
  <StyledRow style={style} nogutter isMobile={isMobile}>
    {!isEmptyArray(tabs) &&
      tabs.map((tab, idx) => (
        <StyleBtnContainer xs={4} key={idx}>
          <StyleBtn
            role="navigation"
            onClick={handleChangeTabClick}
            data-target={tab.label}
            style={tab.label === activeItem ? activeStyle : {}}
          >
            <StyleBtnContent>
              {tab.additional} {t(tab.label)}
            </StyleBtnContent>
          </StyleBtn>
        </StyleBtnContainer>
      ))}
  </StyledRow>
);

YardTopBarView.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      additional: PropTypes.number,
    }).isRequired
  ).isRequired,
  handleChangeTabClick: PropTypes.func.isRequired,
  activeItem: PropTypes.string.isRequired,
};
