import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const CategoryActions = styled(Box)`
  flex-wrap: wrap;
`;

export const PlusButtonIcon = styled.img(
  ({ theme }) => `
  width: 20px;
  height: 20px;
  filter: ${theme.colors.filter('grey08')};
`
);

export const PlusButton = styled.button(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${theme.colors.grey08};
  margin-left: ${theme.spacing.xs}px;
  
  &:hover {
    border: 1px solid ${theme.colors.grey06};

    ${PlusButtonIcon} {
      filter: ${theme.colors.filter('grey06')};
    }
  }
`
);
