import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Storage = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M22.778 8.78408L12.711 2.27808C12.3095 1.90731 11.6905 1.90731 11.289 2.27808L1.222 8.78408C1.00887 8.98992 0.946467 9.30671 1.06561 9.57801C1.18474 9.8493 1.46022 10.0177 1.756 10.0001H4V21.0001C4 21.5524 4.44771 22.0001 5 22.0001H7C7.55228 22.0001 8 21.5524 8 21.0001V13.0001H16V21.0001C16 21.5524 16.4477 22.0001 17 22.0001H19C19.5523 22.0001 20 21.5524 20 21.0001V10.0001H22.244C22.5398 10.0177 22.8152 9.8493 22.9344 9.57801C23.0535 9.30671 22.9911 8.98992 22.778 8.78408Z"
      />
      <path
        fill={useColor(color)}
        d="M9 19.5001C9 19.224 9.22386 19.0001 9.5 19.0001H11.0714C11.3476 19.0001 11.5714 19.224 11.5714 19.5001V21.0716C11.5714 21.3477 11.3476 21.5716 11.0714 21.5716H9.5C9.22386 21.5716 9 21.3477 9 21.0716V19.5001Z"
      />
      <path
        fill={useColor(color)}
        d="M12.4286 19.5001C12.4286 19.224 12.6524 19.0001 12.9286 19.0001H14.5C14.7761 19.0001 15 19.224 15 19.5001V21.0716C15 21.3477 14.7761 21.5716 14.5 21.5716H12.9286C12.6524 21.5716 12.4286 21.3477 12.4286 21.0716V19.5001Z"
      />
      <path
        fill={useColor(color)}
        d="M10.7 16.0001C10.7 15.724 10.9239 15.5001 11.2 15.5001H12.7714C13.0476 15.5001 13.2714 15.724 13.2714 16.0001V17.5716C13.2714 17.8477 13.0476 18.0716 12.7714 18.0716H11.2C10.9239 18.0716 10.7 17.8477 10.7 17.5716V16.0001Z"
      />
    </IconSVGView>
  );
};
