import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Edit } from '@components/common/Icon/presets/Edit';
import { MapControls } from '@components/common/Map';
import { TabsContext } from '@components/common/Tabs/context';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapMode } from '@components/pollination/PolliMap/types';

import { StyledMapToolbarButtonToggle } from './styles';

export const PolliMapControls: React.VFC = () => {
  const { t } = useTranslation();
  const { setMode, isIdle, isStatic, isLoading, fitMapToElements, isEmpty } = usePolliMap();
  const tabContext = useContext(TabsContext);

  const isToolbarToggleVisible = isIdle && !isLoading;
  const recenterMap = isEmpty ? undefined : fitMapToElements;

  const expandMapToolbar = useCallback(() => {
    tabContext?.setTabBarVisible(false);
    setMode(PolliMapMode.MANAGING);
  }, [setMode, tabContext]);

  useEffect(() => {
    if (isIdle) {
      tabContext?.setTabBarVisible(true);
    }
  }, [isIdle, tabContext]);

  if (isStatic) {
    return null;
  }

  return (
    <>
      <MapControls showMapTypeToggle recenterMap={recenterMap} />

      <StyledMapToolbarButtonToggle onClick={expandMapToolbar} isVisible={isToolbarToggleVisible}>
        <Edit size={24} color={'coreWhite'} />{' '}
        <Text typography={'CTA'} weight={'700'}>
          {t('edit')}
        </Text>
      </StyledMapToolbarButtonToggle>
    </>
  );
};
