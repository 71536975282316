import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// nectar:
import Modal from '@components/deprecated/Modal/Modal';
import { Analytics } from '@helpers/Analytics/index';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { verifyPasswordInput } from '@helpers/deprecated/verifyInputs';
import { withGetScreen } from '@HOC/withGetScreen';
import { makeChangePasswordRequestThunk, makeClosePasswordModalAction } from '@redux/deprecated/actions';

import { ChangePasswordModalView } from './ChangePasswordModalView';

const initialState = {
  oldPassword: '',
  newPassword: '',
  confirmationPassword: '',
  isDisabledBtn: true,
  errorMsg: '',
};

class ChangePasswordModal extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleClose(e) {
    e.preventDefault();
    const { closeDispatch } = this.props;
    closeDispatch();

    this.setState({
      ...initialState,
    });
  }

  setIfButtonIsDisabled() {
    const { oldPassword, newPassword, confirmationPassword } = this.state;

    this.setState({
      isDisabledBtn: true,
    });

    if (oldPassword && newPassword && confirmationPassword) {
      this.setState({
        isDisabledBtn: false,
      });
    }
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value.trim(),
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleKeyDown(e) {
    const { contentObj } = this.props;
    const { isDisabledBtn } = this.state;

    const { modalType } = contentObj;

    if ('change-pass' === modalType) {
      if ('Enter' === e.key && !isDisabledBtn) this.handleSubmit(e);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { t, changePassword, history, closeDispatch } = this.props;

    const { oldPassword, newPassword, confirmationPassword } = this.state;

    let error = verifyPasswordInput(newPassword, confirmationPassword);

    if (error) {
      this.setState({
        errorMsg: t(error),
      });
    } else {
      changePassword(oldPassword, newPassword, history);
      Analytics.sendEvent({ event: AnalyticsEventType.PASSWORD_CHANGE });
      closeDispatch();

      this.setState({
        ...initialState,
      });
    }
  }

  render() {
    const { t, isFetching, isPasswordOpen, isMobile } = this.props;

    const { isDisabledBtn, errorMsg, oldPassword, newPassword, confirmationPassword } = this.state;

    const contentObj = {
      oldPassword,
      newPassword,
      confirmationPassword,
    };

    return (
      <Modal isMobile={isMobile()} modalType="change-pass" isOpen={isPasswordOpen} onRequestClose={this.handleClose}>
        <ChangePasswordModalView
          key={isFetching}
          isMobile={isMobile()}
          t={t}
          contentObj={contentObj}
          isDisabledBtn={isDisabledBtn}
          errorMsg={errorMsg}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isFetching={isFetching}
        />
      </Modal>
    );
  }
}
/**
 *
 * @param dispatch
 * @returns {{closeDispatch: closeDispatch}}
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeClosePasswordModalAction());
  },
  changePassword: (oldPassword, newPassword, history) => {
    dispatch(makeChangePasswordRequestThunk(oldPassword, newPassword, history));
  },
});

/**
 *
 * @param state
 * @returns {{isPasswordOpen: (*|boolean)}}
 */
const mapStateToProps = (state) => ({
  isPasswordOpen: state.modalReducer.isPasswordOpen,
  contentObj: state.modalReducer.contentObj,
  isFetching: state.changePasswordReducer.isFetching,
});

ChangePasswordModal.propTypes = {
  isPasswordOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  contentObj: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(ChangePasswordModal)))
);
