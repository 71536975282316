import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import APP from '@config/constants';

import { useOperation } from './useOperation';

export const useNavTabs = () => {
  const { t } = useTranslation();
  const operation = useOperation();

  const isRouteEnabled = useCallback(
    (route) => {
      switch (route) {
        case APP.routes.pollination:
          return operation?.preferences.featureEnabledPollinationLogistic;
        default:
          return true;
      }
    },
    [operation]
  );

  const nav = useMemo(
    () =>
      operation
        ? APP.nav.map(({ label, route }) => ({
            label: t(label),
            route,
            isEnabled: isRouteEnabled(route),
          }))
        : [],
    [t, operation, isRouteEnabled]
  );

  return nav.filter((navItem) => navItem.isEnabled);
};
