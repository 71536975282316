import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Clock = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5194 2.00826 21.9917 6.48058 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.123 20.004 16.1601 19.1624 17.6613 17.6613C19.1624 16.1601 20.004 14.123 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12.5523 6 13 6.44772 13 7V11.5H16.5C17.0523 11.5 17.5 11.9477 17.5 12.5C17.5 13.0523 17.0523 13.5 16.5 13.5H12C11.4477 13.5 11 13.0523 11 12.5V7C11 6.44772 11.4477 6 12 6Z"
      />
    </IconSVGView>
  );
};
