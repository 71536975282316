import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import SeasonIcon from '@assets/Small_Hives.svg';
import { Box } from '@components/common/Box';
import { IconIMG } from '@components/common/Icon';
import { Line } from '@components/common/Line';
import { Text } from '@components/common/Text';
import { Checkbox } from '@components/form/CheckBox';
import { PreferencesSections } from '@components/operation/HiveStructureCard/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { useOperation } from '@hooks/useOperation';
import { makePatchOperationThunk } from '@redux/Operation/actions';

export const HiveStructureCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const operation = useOperation();
  const preferences = operation?.preferences ?? {};
  const preferencesSections = useMemo<PreferencesSections>(
    () => [
      {
        title: 'hive_structure_frames_per_honey_supers',
        items: [
          'framesPerHoneySupers_Ten',
          'framesPerHoneySupers_Nine',
          'framesPerHoneySupers_Eight',
          'framesPerHoneySupers_Other',
        ],
      },
      {
        title: 'hive_structure_frame_base',
        items: ['frameBase_Plastic', 'frameBase_Wax', 'frameBase_Other'],
      },
      {
        title: 'hive_structure_honey_super_type',
        items: ['honeySuperType_Shallow', 'honeySuperType_Medium', 'honeySuperType_Deep', 'honeySuperType_Other'],
      },
      {
        title: 'hive_structure_brood_boxes',
        items: ['broodBoxes_One', 'broodBoxes_Two', 'broodBoxes_Three', 'broodBoxes_Other'],
      },
      {
        title: 'hive_structure_bottom_boards',
        items: ['bottomBoards_Solid', 'bottomBoards_Screen', 'bottomBoards_Other'],
      },
      {
        title: 'hive_structure_queen_excluder',
        items: ['queenExcluder_Metal', 'queenExcluder_Plastic', 'queenExcluder_None'],
      },
    ],
    []
  );

  const handlePreferenceChange = useCallback(
    (checked: boolean, name: keyof BeeOperationPreferences) => {
      dispatch(makePatchOperationThunk({ preferences: { [name]: checked } }));
    },
    [dispatch]
  );

  return (
    <Box paper02 column stretch>
      <Box paddingHorizontalSM paddingVerticalXS alignItems={'center'}>
        <IconIMG src={SeasonIcon} size={18} marginLeftXS />
        <Box marginXS>
          <Text typography={'Heading3'} weight={'600'}>
            {t('hives_structure')}
          </Text>
        </Box>
      </Box>

      <Line />

      <Box column stretch paddingSM>
        <Box column stretch marginXS>
          {preferencesSections.map(({ title, items }, idx, arr) => (
            <Box column stretch key={idx}>
              <Text typography={'Heading3'} weight={'600'}>
                {t(title)}
              </Text>
              <Box alignItems={'center'} marginTopXS>
                {items.map((item: keyof BeeOperationPreferences) => (
                  <Box key={item} center>
                    <Checkbox
                      id={item}
                      name={item}
                      value={preferences[item] ?? false}
                      onChange={(checked) => handlePreferenceChange(checked, item)}
                    />

                    <Box tag={'label'} htmlFor={item} marginLeftXXS marginRightXS paddingLeftXXS paddingRightSM>
                      <Text typography={'SmallParagraph'}>{t(`hive_structure_${CaseAdapter.toSnakeCase(item)}`)}</Text>
                    </Box>
                  </Box>
                ))}
              </Box>

              {idx < arr.length - 1 ? (
                <Box column stretch marginVerticalSM>
                  <Line />
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
