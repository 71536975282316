import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Pollination } from '@components/common/Icon/presets/Pollination';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { ListItem } from '@components/pollination/PolliMapPaneSummary/styles';
import { useAlphanumericallySorted } from '@hooks/useSorted';

export const PolliMapPaneBlocks: React.VFC = () => {
  const { t } = useTranslation();
  const { blocksList, getKey, selectBlock } = usePolliMap();

  const blocksListSorted = useAlphanumericallySorted(blocksList, 'name');

  return (
    <Box column stretch>
      {blocksListSorted.map((block) => (
        <ListItem key={getKey(block)} onClick={() => selectBlock(block)}>
          <Pollination size={16} />
          <Box fit paddingHorizontalXS>
            <Text typography={'Heading3'} weight={'600'} align={'left'}>
              {block.name}
            </Text>
          </Box>
          <Text typography={'Heading3'}>
            {block.area ? t('pollination_block_acres_count', { count: block.area }) : '-'}
          </Text>
        </ListItem>
      ))}
    </Box>
  );
};
