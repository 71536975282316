import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const YardAdd = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6171 3C7.49442 2.99994 7.38097 3.06511 7.31921 3.17111L6.7141 4.20867C6.68301 4.26146 6.66663 4.32162 6.66666 4.38289V8.53078C6.67127 8.91072 6.97816 9.21761 7.3581 9.22222H12.1974C12.5774 9.21761 12.8843 8.91072 12.8889 8.53078V4.38289C12.8889 4.32162 12.8725 4.26146 12.8414 4.20867L12.2363 3.17111C12.1746 3.06511 12.0611 2.99994 11.9384 3H7.6171ZM7.81546 3.77771H11.7401L12.143 4.38282H7.41257L7.81546 3.77771ZM10.4692 5.3334H9.08634C8.90178 5.34215 8.75664 5.49434 8.75664 5.67912C8.75664 5.86389 8.90178 6.01608 9.08634 6.02484H10.4692C10.6538 6.01608 10.7989 5.86389 10.7989 5.67912C10.7989 5.49434 10.6538 5.34215 10.4692 5.3334Z"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06944 10C2.93111 9.99996 2.80317 10.0734 2.73344 10.1929L2.05289 11.3596C2.01822 11.4191 1.99997 11.4867 2 11.5556V16.2222C2.00297 16.6505 2.34946 16.997 2.77778 17H8.22222C8.65054 16.997 8.99703 16.6505 9 16.2222V11.5556C9.00004 11.4867 8.98178 11.4191 8.94711 11.3596L8.26656 10.1929C8.19683 10.0734 8.06889 9.99996 7.93056 10H3.06944ZM3.29267 10.7778H7.70734L8.16079 11.5556H2.83923L3.29267 10.7778ZM6.27778 12.3333H4.72222C4.50744 12.3333 4.33333 12.5074 4.33333 12.7222C4.33333 12.937 4.50744 13.1111 4.72222 13.1111H6.27778C6.49256 13.1111 6.66667 12.937 6.66667 12.7222C6.66667 12.5074 6.49256 12.3333 6.27778 12.3333Z"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.625 10C11.4867 9.99996 11.3587 10.0734 11.289 10.1929L10.6084 11.3596C10.5738 11.4191 10.5555 11.4867 10.5555 11.5556V16.2222C10.5585 16.6505 10.905 16.997 11.3333 17H16.7778C17.2061 16.997 17.5526 16.6505 17.5555 16.2222V11.5556C17.5556 11.4867 17.5373 11.4191 17.5027 11.3596L16.8221 10.1929C16.7524 10.0734 16.6244 9.99996 16.4861 10H11.625ZM11.8482 10.7778H16.2629L16.7163 11.5556H11.3948L11.8482 10.7778ZM14.8333 12.3333H13.2778C13.063 12.3333 12.8889 12.5074 12.8889 12.7222C12.8889 12.937 13.063 13.1111 13.2778 13.1111H14.8333C15.0481 13.1111 15.2222 12.937 15.2222 12.7222C15.2222 12.5074 15.0481 12.3333 14.8333 12.3333Z"
      />
      <path
        fill={useColor(color)}
        d="M20.25 16.5H18.75V18.75H16.5V20.25H18.75V22.5H20.25V20.25H22.5V18.75H20.25V16.5Z"
      />
    </IconSVGView>
  );
};
