import * as jsts from 'jsts';
import wkx from 'wkx';

import theme from '@style/theme';

import { getTextWidth } from './textWidth';

const geometryFactory = new jsts.geom.GeometryFactory();

const COORDINATE_CONVERSION_CACHE = {};

/**
 * Returns the yard ids intersecting with another among all unique combinations in boundaries
 * @param {google.maps.Polygon[]} boundaries - List of polygons
 */
export const getAllIntersectingPolygons = (boundaries) => {
  const intersectingBoundaryIds = [];
  const jstsPolygons = boundaries.map(convertPolygonToJstsPolygon);

  for (let i = 0; i < jstsPolygons.length; i++) {
    for (let j = i + 1; j < jstsPolygons.length; j++) {
      if (doPolygonsIntersect(jstsPolygons[i], jstsPolygons[j])) {
        intersectingBoundaryIds.push(boundaries[i].id, boundaries[j].id);
      }
    }
  }

  return intersectingBoundaryIds;
};

/**
 * Returns the yard ids intersecting with another among all combinations between boundariesA and boundariesB
 * @param {google.maps.Polygon[]} boundariesA - List of polygons
 * @param {google.maps.Polygon[]} boundariesB - List of polygons
 */
export const getIntersectingPolygons = (boundariesA, boundariesB) => {
  const intersectingBoundaryIds = [];
  const jstsPolygonsA = boundariesA.map(convertPolygonToJstsPolygon);
  const jstsPolygonsB = boundariesB.map(convertPolygonToJstsPolygon);

  for (let i = 0; i < jstsPolygonsA.length; i++) {
    for (let j = 0; j < jstsPolygonsB.length; j++) {
      const areSameBoundaries = boundariesA[i].id === boundariesB[j].id;
      if (!areSameBoundaries && doPolygonsIntersect(jstsPolygonsA[i], jstsPolygonsB[j])) {
        intersectingBoundaryIds.push(boundariesA[i].id, boundariesB[j].id);
      }
    }
  }

  return intersectingBoundaryIds;
};

/**
 * Converts a path to a list of coordinates
 * @param {google.maps.LatLng[]} path
 */
export const getCoordinatesFromPath = (path) => {
  let coordinates = [];
  for (var i = 0; i < path.length; i++) {
    let lat = path.getAt(i).lat();
    let lng = path.getAt(i).lng();
    coordinates.push([lng, lat]);
  }
  // quick hack to check that the first item in the coordintates is equal to the last
  const first = 0;
  const last = coordinates.length - 1;
  if (coordinates[first][0] !== coordinates[last][0] || coordinates[first][1] !== coordinates[last][1])
    coordinates.push(coordinates[first]);

  return coordinates;
};

/**
 * Returns a google.maps.LatLng object of the center of the polygon
 * @param {google.maps.Polygon} polygon
 * @param {google.maps}
 */
export const getPolygonCenter = (polygon, maps) => {
  const coordinates = getCoordinatesFromPath(polygon.getPath());
  return getCoordinateListCenter(coordinates, maps);
};

/**
 * Returns a google.maps.LatLng object of the center of the list of coordinates
 * @param {number[][]} coordinates
 * @param {google.maps}
 */
export const getCoordinateListCenter = (coordinates, maps) => {
  const polygonCoords = coordinates.map((coords) => new maps.LatLng(coords[1], coords[0]));
  let bounds = new maps.LatLngBounds();

  for (let i = 0; i < polygonCoords.length; i++) {
    bounds.extend(polygonCoords[i]);
  }
  return bounds.getCenter();
};

/**
 * Returns a boolean indicating if the coordinate is located inside one of the polygons
 * @param {string} coordinates - position encoded in Well Known Text
 * @param {google.maps.Polygon} polygons
 * @param {google.maps} maps
 */
export const isCoordinateInsidePolygons = (coordinates, polygons, maps) => {
  if (!coordinates) return;
  const point = coordinateToLatLng(coordinates, maps);

  for (const polygon of polygons) {
    if (isCoordinateNearPolygon(point, polygon) && isCoordinateInsidePolygon(point, polygon, maps)) {
      return true;
    }
  }

  return false;
};

/**
 * Returns a boolean indicating if the coordinate is contained
 * by tge given polygon.
 * @param {google.maps.LatLng} coordinate
 * @param {google.maps.Polygon & {cacheId: number}} polygon
 * @param {google.maps} maps
 */
const isCoordinateInsidePolygon = (coordinate, polygon, maps) => {
  return maps.geometry?.poly.containsLocation(coordinate, polygon);
};

/**
 * Returns a boolean indicating if the coordinate is at least in a
 * neighbor latitude of the given polygon. It assumes that a polygon won't
 * be larger than a latitude unit, which corresponds to 111km. Longitude
 * is not being used since variates depending on the latitude.
 * @param {google.maps.LatLng} coordinate
 * @param {google.maps.Polygon} polygon
 */
const isCoordinateNearPolygon = (coordinate, polygon) => {
  const coordinateLat = coordinate.lat();
  const polygonLat = polygon.getPath().getAt(0).lat();
  return Math.abs(coordinateLat - polygonLat) <= 1.0;
};

/**
 * Returns the given string coordinate to a LatLng object.
 * @param {string} coordinate - position encoded in Well Known Text
 * @param {google.maps} maps
 */
const coordinateToLatLng = (coordinate, maps) => {
  if (coordinate in COORDINATE_CONVERSION_CACHE) {
    return COORDINATE_CONVERSION_CACHE[coordinate];
  }
  const geometry = wkx.Geometry.parse(coordinate);
  return (COORDINATE_CONVERSION_CACHE[coordinate] = new maps.LatLng(geometry.y, geometry.x));
};

/**
 * Returns a boolean indicating if polygons overlap
 * @param {jsts.Polygon} polygonA
 * @param {jsts.Polygon} polygonB
 */
const doPolygonsIntersect = (polygonA, polygonB) => polygonA.intersects(polygonB);

/**
 * Converts a polygon to a jsts polygon
 * @param {google.maps.Polygon} polygon
 */
const convertPolygonToJstsPolygon = (polygon) => convertPathToJstsPolygon(polygon.getPath()).buffer(0);

/**
 * Converts a path to a jsts polygon
 * @param {google.maps.LatLng[]} path
 */
const convertPathToJstsPolygon = (path) => {
  const coordinates = path.getArray().map((coord) => new jsts.geom.Coordinate(coord.lat(), coord.lng()));
  if (coordinates[0].compareTo(coordinates[coordinates.length - 1]) !== 0) coordinates.push(coordinates[0]);
  const shell = geometryFactory.createLinearRing(coordinates);

  return geometryFactory.createPolygon(shell);
};

const infoWindowSvgTemplate = (hovered, textWidth, yard) =>
  [
    `<svg xmlns="http://www.w3.org/2000/svg" width="${textWidth}" height="60">
    <foreignObject class="node" x="0" y="0" width="${textWidth}" height="60">
    <body xmlns="http://www.w3.org/1999/xhtml">
    <style>
      .container {
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-family: arial;
        font-size: ${theme.captionSmallFontSize};
        justify-content: center;
        padding: 0;
        margin-bottom: 10px;
        outline: none;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
      }
      .infowindow-container {
        box-shadow: 0 3px 6px 0 rgba(148, 136, 112, 0.1), 0 3px 6px 0 rgba(148, 136, 112, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${hovered ? '#252629' : '#fff'};
        border-radius: 1rem;
        color: ${hovered ? '#fff' : '#252629'};
      }
      .infowindow-desc-name {
        font-weight: bold;
        line-height: ${theme.captionSmallFontSize};
      }
      .infowindow-status {
        width: 6px;
        height: 6px;
        margin-left: 0.5rem;
        border-radius: 50%;
      }
      .infowindow-status-good {
        background-color: #A4D96C;
      }
      .infowindow-status-unknown {
        background-color: #666;
      }             
      .infowindow-arrow-down {
        border-top-right-radius: 2px;
        border-bottom-left-radius: 2px;
        background-color: ${hovered ? '#252629' : '#fff'};
        width: 10px;
        height: 10px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    </style>`,
    `<div class="container">`,
    `<div id="infowindow${yard.id}" class="infowindow-container">`,
    `<p class="infowindow-desc-name">${yard.name}</p>`,
    `<div class="infowindow-status ${yard.nb_hives > 0 ? 'infowindow-status-good' : 'infowindow-status-unknown'}"
                ></div>`,
    `</div>`,
    `<div class="infowindow-arrow-down"></div>`,
    `</div>`,
    `</body>
            </foreignObject>
            </svg>`,
  ].join('\n');

/**
 * Generates marker descriptor list according to yards passed.
 * @param {google.maps} maps
 * @param {[]} yards
 * @param {func} handleMarkerClick
 */
export const generateMarkerDescriptors = (maps, yards, handleMarkerClick = () => {}) =>
  yards.reduce((result, yard) => {
    if (yard.yard_center) {
      const { coordinates } = yard.yard_center;

      const textWidth = getTextWidth(yard.name, `bold ${theme.captionSmallFontSize} arial`) + 50;

      const normalSvg = {
        origin: new maps.Point(0, 0), // origin
        anchor: new maps.Point(textWidth / 2, 55), // anchor
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(infoWindowSvgTemplate(false, textWidth, yard)),
      };
      const hoveredSvg = {
        origin: new maps.Point(0, 0), // origin
        anchor: new maps.Point(textWidth / 2, 55), // anchor
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(infoWindowSvgTemplate(true, textWidth, yard)),
      };

      result.push({
        position: {
          lat: coordinates[1],
          lng: coordinates[0],
        },
        active: yard.nb_hives > 0,
        id: yard.id,
        name: yard.name,
        yard,
        normalSvg,
        hoveredSvg,
        icon: normalSvg,
        onClick: () => {
          handleMarkerClick(yard);
        },
      });
    }
    return result;
  }, []);

/**
 * Returns true if latLng point coordinates is matching a pair of coordinates from coordinatesList.
 * @param {google.maps.LatLng} latLng
 * @param {[]} coordinatesList
 */
export const isPointInCoordinatesList = (latLng, coordinatesList) => {
  for (let i = 0; i < coordinatesList.length; i++) {
    const point = coordinatesList[i];
    const lat = latLng.lat();
    const lng = latLng.lng();
    if (point[0] === lng && point[1] === lat) {
      return true;
    }
  }
  return false;
};

/**
 * Adds delete functionality with right click on vertex to yard polygon.
 * @param {google.maps.Polygon} polygon
 * @param {google.maps} maps
 * @param {google.maps.map} map
 * @param {func} makeShowSnackbarAction
 * @param {[]} paths
 * @param {func} validateAndSetYardGeometry
 */
export const initYardVertexDelete = (polygon, maps, map, makeShowSnackbarAction, paths, validateAndSetYardGeometry) => {
  polygon.infoWindow = new maps.InfoWindow({
    content: '<div class="tooltip">Right click to delete</div>',
    pixelOffset: new maps.Size(0, -8),
  });

  maps.event.addListener(polygon, 'contextmenu', (event) => {
    const coordinates = getCoordinatesFromPath(paths);

    if (event.vertex !== undefined) {
      if (coordinates.length < 5) {
        makeShowSnackbarAction('unable_remove_yard_vertex');
        return;
      }

      paths.removeAt(event.vertex);

      if (coordinates.length - 1 === event.vertex) {
        paths.removeAt(0);
      }

      polygon.infoWindow.close();

      validateAndSetYardGeometry();
    }
  });

  maps.event.addListener(polygon, 'mouseover', (e) => {
    const coordinates = getCoordinatesFromPath(paths);
    const latLng = e.latLng;
    const isHoveringVertex = isPointInCoordinatesList(latLng, coordinates);

    if (isHoveringVertex) {
      polygon.infoWindow.setPosition(latLng);
      polygon.infoWindow.open(map);
    }
  });

  maps.event.addListener(polygon, 'mouseout', () => {
    polygon.infoWindow.close();
  });
};
