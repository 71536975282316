import { TableHeaderOptions } from './types';

export function createHeader(key: string, order: number, { title, onClick, ...props }: TableHeaderOptions) {
  return {
    [key]: {
      order,
      title,
      onClick,
      ...props,
    },
  };
}
