import React, { Fragment, ReactNode, useCallback, useMemo } from 'react';

import ArrowIcon from '@assets/xSmall_Arrow-dropdown.svg';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';

import { TableHeaderArrowIcon, TableHeaderView } from './styles';
import { TableHeaderChildren, TableHeaderProps } from './types';

export const TableHeader = React.forwardRef<HTMLDivElement, TableHeaderProps>(
  ({ title, tooltip, tooltipProps, children, sortable, isSorting, sortingDirection, onClick, ...props }, ref) => {
    const hasTooltip = !!tooltip;

    const childrenList = useMemo<Array<TableHeaderChildren>>(() => {
      return children && typeof (children as any).map === 'function'
        ? (children as Array<TableHeaderChildren>)
        : [children as TableHeaderChildren];
    }, [children]);

    const hasOnlyStringChildren = useCallback(() => {
      return childrenList.filter((child) => child !== undefined).every((child) => typeof child === 'string');
    }, [childrenList]);

    const hasAnyFunctionChildren = useCallback(() => {
      return childrenList.some((child) => typeof child === 'function');
    }, [childrenList]);

    const renderSorting = useCallback(
      () =>
        sortable && (
          <TableHeaderArrowIcon
            src={ArrowIcon}
            sortingDirection={sortingDirection}
            isSorting={isSorting}
            sortable={sortable}
          />
        ),
      [sortable, isSorting, sortingDirection]
    );

    const renderTooltip = useCallback(() => {
      return (
        hasTooltip && (
          <Tooltip {...(tooltipProps || {})}>
            <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltip }} />
          </Tooltip>
        )
      );
    }, [hasTooltip, tooltip, tooltipProps]);

    const renderChildrenWithFunctional = useCallback(() => {
      return childrenList.map((child, index) => (
        <Fragment key={index}>
          {typeof child === 'function' ? child(renderSorting()) : child}
          {renderTooltip()}
        </Fragment>
      ));
    }, [childrenList, renderSorting, renderTooltip]);

    return (
      <TableHeaderView
        ref={ref}
        data-title={title}
        sortingDirection={sortingDirection}
        isSorting={isSorting}
        hoverable={sortable || hasTooltip}
        onClick={onClick}
        {...props}
      >
        {hasAnyFunctionChildren() ? (
          renderChildrenWithFunctional()
        ) : (
          <>
            {hasOnlyStringChildren() ? (
              <Text typography={'CaptionSmall'} weight={'600'} dashed={hasTooltip}>
                {children as ReactNode}
                {renderTooltip()}
              </Text>
            ) : (
              <>
                {children}
                {renderTooltip()}
              </>
            )}
            {renderSorting()}
          </>
        )}
      </TableHeaderView>
    );
  }
);

TableHeader.displayName = 'TableHeader';
