const MAX_NUMBER_LENGTH = 24;
const MAX_NUMBER_FRACTION_DIGITS = 12;

export const InputNumberUtil = {
  toNumericValue,
  toTextValue,
  cleanNumericValue,
  MAX_NUMBER_LENGTH,
};

/**
 * Converts the given string to a numeric value.
 * */
function toNumericValue(value: string | undefined, options: { decimal?: boolean; positive?: boolean }) {
  const safeValue = (value ?? '').replace(/\s*/g, '');

  const numericMatchers = [
    // Matches: "1", "10", "1000"
    { regex: /^\d+$/, separator: '.' },

    // Matches: "1000,0000"
    { regex: /^\d+,\d{3,}$/, separator: '.' },
    // Matches: "1000.0000"
    { regex: /^\d+\.\d{3,}$/, separator: ',' },

    // Matches: "1.0", "10.0", "1000.0"
    { regex: /^\d+(\.\d*)?$/, separator: '.' },
    // Matches: "1,0", "10,0", "1000,0"
    { regex: /^\d+(,\d*)?$/, separator: ',' },

    // Matches: "1,000.5", "1,000,000.5"
    { regex: /^\s*[\d,]+\s*(\.\d*)?\s*$/, separator: '.' },
    // Matches: "1.000,5", "1.000.000,5"
    { regex: /^\s*[\d.]+\s*(,\d*)?\s*$/, separator: ',' },
  ];

  const matcher = numericMatchers.find((matcher) => matcher.regex.test(safeValue));

  if (matcher) {
    const cleanValue = safeValue
      .replace(new RegExp(`[^\\d${matcher.separator}]`, 'g'), '')
      .replace(matcher.separator, '.');

    let numericValue = parseFloat(cleanValue);
    numericValue = options?.positive ? Math.max(0, numericValue) : numericValue;
    numericValue = options?.decimal ? numericValue : Math.round(numericValue);
    return numericValue;
  }

  return undefined;
}

/**
 * Converts the given number to its string representation.
 * */
function toTextValue(value: number | undefined) {
  if (value !== undefined) {
    return value.toLocaleString('en-CA', { maximumFractionDigits: MAX_NUMBER_FRACTION_DIGITS }).replace(/\s/g, ' ');
  }
  return value;
}

function cleanNumericValue(
  value: string,
  options?: { cursorPosition?: number; decimal?: boolean; positive?: boolean }
) {
  const safeOptions = { cursorPosition: 0, decimal: true, positive: false, ...options };

  const res = safeOptions.decimal
    ? cleanFloatValue(value, safeOptions.cursorPosition)
    : cleanIntegerValue(value, safeOptions.cursorPosition);
  return safeOptions.positive ? removeSignal(res.value, res.nextCursorPosition) : res;
}

/**
 * Clears the given value turning it into a valid integer.
 * */
function cleanIntegerValue(value: string, cursorPosition: number) {
  return removeInvalidChars(value, cursorPosition, /[^0-9,. ]/);
}

/**
 * Clears the given value turning it into a valid float.
 * */
function cleanFloatValue(value: string, cursorPosition: number) {
  return removeInvalidChars(value, cursorPosition, /[^0-9,. ]/);
}

/**
 * Removes any non-numeric chars.
 * */
function removeSignal(value: string, cursorPosition: number) {
  const hasSignal = value.startsWith('-');
  const nextCursorPosition = cursorPosition - (hasSignal ? 1 : 0);
  return { value: value.replace(/^-/, ''), nextCursorPosition };
}

/**
 * Removes any non-numeric chars.
 * */
function removeInvalidChars(value: string, cursorPosition: number, invalidCharsRegex: RegExp) {
  const { beforeCursor, afterCursor } = getCharOccurences(value, invalidCharsRegex, cursorPosition);
  const hasNegativeSignal = value.startsWith('-');

  value = removeCharAt(value, ...afterCursor.reverse());
  value = removeCharAt(value, ...beforeCursor.reverse());

  let nextCursorPosition = cursorPosition - beforeCursor.length;

  if (hasNegativeSignal) {
    value = '-' + value;
    nextCursorPosition += 1;
  }

  return { value, nextCursorPosition };
}

function getCharOccurences(value: string, charRegex: RegExp, cursorPosition: number) {
  const beforeCursor = [] as Array<number>;
  const afterCursor = [] as Array<number>;

  for (let i = 0; i < value.length; i++) {
    if (charRegex.test(value.substring(i, i + 1))) {
      i < cursorPosition ? beforeCursor.push(i) : afterCursor.push(i);
    }
  }

  return { beforeCursor, afterCursor };
}

function removeCharAt(value: string, ...positions: Array<number>) {
  const sortedPositions = positions.sort((a, b) => b - a);
  for (const pos of sortedPositions) {
    value = value.replace(new RegExp(`(.{${pos}}).`), '$1');
  }
  return value;
}
