import React from 'react';
import { connect } from 'react-redux';
// vendor:
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// nectar:
import notificationIcon from '@assets/MediumNotification.svg';
import notificationMobileIcon from '@assets/Small_Mobile-Menu-Notification.svg';
import { isEmptyArray } from '@helpers/deprecated/array';
import {
  makeChangeAccountRequestThunk,
  makeFetchEventListRequestThunk,
  resetNotificationCountToZero,
  setNotificationCount,
} from '@redux/deprecated/actions';
import AlertsDropdown from '@scenes/admin/Alerts/components/AlertsDropdown';

const StyledNotificationBtn = styled.button`
  flex: 0;
  position: relative;
  top: 0;
  margin-right: ${({ isMobile }) => (isMobile ? '1rem' : '2rem')};
  border-radius: 1rem;
  &:after {
    content: attr(data-notifications);
    display: ${(props) => props.display};
    position: absolute;
    top: 1rem;
    left: 55%;
    padding: 2px 4px;
    border-radius: 7px;
    background-color: ${(props) => props.theme.criticalColor};
    color: ${(props) => props.theme.buttonPrimaryTextColor};
    font-size: 0.65rem;
    z-index: ${(props) => props.theme.getZIndexOf('lowLevelIndex')};
    font-weight: ${(props) => props.theme.fontWeightBold};
  }
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
  &:focus {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

const StyledIcon = styled.img`
  display: inline-block;
  vertical-align: bottom;
  color: white;
  filter: invert(92%) sepia(81%) saturate(6175%) hue-rotate(183deg) brightness(123%) contrast(93%);
`;

class NavBarNotifications extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.alertsButtonRef = React.createRef();
    this.alertsRef = React.createRef();
    this.showNotifications = this.showNotifications.bind(this);
    this.hideNotifications = this.hideNotifications.bind(this);
  }

  componentDidMount() {
    const { user, fetchEventList } = this.props;

    fetchEventList(user, false);

    document.addEventListener('mousedown', this.hideNotifications);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hideNotifications);
  }

  componentDidUpdate(prevProps) {
    const { unreadEvents, setNotificationCount } = this.props;
    if (unreadEvents && unreadEvents !== prevProps.unreadEvents) {
      !isEmptyArray(unreadEvents) && setNotificationCount(unreadEvents.length);
    }
  }

  hideNotifications(event) {
    //  either push the notifications button or anywhere but the scrollable container
    if (
      this.alertsButtonRef &&
      !this.alertsButtonRef.current.contains(event.target) &&
      this.alertsRef &&
      !this.alertsRef.current.contains(event.target)
    ) {
      this.setState({ show: false });
    }
  }

  showNotifications() {
    const { history, isMobile, sendEventReadonDate, resetNotificationCountToZero, notificationCount } = this.props;

    sendEventReadonDate({ event_read_on: moment().utc() });

    resetNotificationCountToZero();

    if (isMobile) {
      history.push({
        pathname: `/alerts`,
      });
    } else this.setState((state) => ({ show: !state.show }));
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const { t, isMobile, disableShowNotif, notificationCount } = this.props;

    const { show } = this.state;

    return (
      <>
        <StyledNotificationBtn
          type="button"
          ref={this.alertsButtonRef}
          onClick={disableShowNotif ? (e) => e : this.showNotifications}
          display={0 >= Number(notificationCount) || disableShowNotif ? 'none' : 'block'}
          data-notifications={disableShowNotif ? 0 : Number(notificationCount)}
          isMobile={isMobile}
        >
          <StyledIcon alt="notifications" src={isMobile ? notificationMobileIcon : notificationIcon} />
        </StyledNotificationBtn>

        <AlertsDropdown t={t} show={show} setRef={this.alertsRef} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  unreadEvents: state.eventListReducer.unreadEvents,
  user: state.accountReducer.user,
  notificationCount: state.notificationCountReducer.notificationCount,
});

/**
 *
 * @param dispatch
 * @return {{resetNotificationCountToZero: resetNotificationCountToZero}}
 * @return {{setNotificationCount: setNotificationCount}}
 * @return {{sendEventReadonDate: makeChangeAccountRequestThunk}}
 * @return {{fetchEventList: makeFetchEventListRequestThunk}}
 *
 */

const mapDispatchToProps = (dispatch) => ({
  resetNotificationCountToZero: () => {
    dispatch(resetNotificationCountToZero());
  },
  setNotificationCount: (notificationCount) => {
    dispatch(setNotificationCount(notificationCount));
  },
  sendEventReadonDate: (data) => {
    dispatch(makeChangeAccountRequestThunk(data));
  },
  fetchEventList: (user, forceFetch) => {
    dispatch(makeFetchEventListRequestThunk(user, forceFetch));
  },
});

NavBarNotifications.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  disableShowNotif: PropTypes.bool,
  sendEventReadonDate: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarNotifications));
