import styled, { StyledComponent } from 'styled-components';

import { TextPresets, TextStyledProps } from '@components/common/Text/types';

export const PRESETS: TextPresets = {
  Heading1: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    htmlTag: 'h1',
  },
  Heading2: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    htmlTag: 'h2',
  },
  Heading3: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    htmlTag: 'h3',
  },
  CaptionSmall: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    htmlTag: 'span',
  },
  Paragraph: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    htmlTag: 'p',
  },
  SmallParagraph: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    htmlTag: 'p',
  },
  CTA: {
    fontSize: '0.875rem',
    lineHeight: '24px',
    htmlTag: 'span',
  },
  Legend: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    htmlTag: 'legend',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  TooltipSmall: {
    fontSize: '0.625rem',
    lineHeight: '1rem',
    htmlTag: 'span',
  },
};

export const StyledTextComponents: Record<string, StyledComponent<any, any>> = {
  h1: styled.h1<TextStyledProps>(({ textStyle }) => textStyle),
  h2: styled.h2<TextStyledProps>(({ textStyle }) => textStyle),
  h3: styled.h3<TextStyledProps>(({ textStyle }) => textStyle),
  p: styled.p<TextStyledProps>(({ textStyle }) => textStyle),
  span: styled.span<TextStyledProps>(({ textStyle }) => textStyle),
  legend: styled.legend<TextStyledProps>(({ textStyle }) => textStyle),
};
