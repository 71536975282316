import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Tab, Tabs } from '@components/common/Tabs';
import { YardDetailsDashboard } from '@components/yard/YardDetailsDashboard';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useActionCategories } from '@hooks/useActions';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';
import BeeTrackHivesList from '@scenes/admin/Yards_deprecated/Yard/components/BeeTrackHivesList';
import BeeTrackYardDashboard from '@scenes/admin/Yards_deprecated/Yard/components/BeeTrackYardDashboard';

export const YardDetailsPage: React.VFC = () => {
  const t = useTranslation();
  const { isMobile, isTablet } = useGetScreenWidth();
  const actionList = useActionCategories();

  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  if (!yard) {
    return null;
  }

  return (
    <Tabs>
      <Tab id={'dashboard'} title={t('dashboard')} isScrollable>
        <YardDetailsDashboard />
      </Tab>
      <Tab id={'hive'} title={maybePluralize(yard.nbHives, 'hive', t, 's', !isMobile)} isScrollable>
        <BeeTrackHivesList
          t={t}
          yardName={yard?.name}
          isMobile={isMobile}
          isTablet={isTablet}
          actionList={actionList}
          status={'alive'}
        />
      </Tab>
      <Tab id={'deadout'} title={maybePluralize(yard.nbDeadoutSeason, 'deadout', t, 's', !isMobile)} isScrollable>
        <BeeTrackHivesList
          t={t}
          yardName={yard?.name}
          isMobile={isMobile}
          isTablet={isTablet}
          actionList={actionList}
          status={'deadout'}
        />
      </Tab>
    </Tabs>
  );
};
