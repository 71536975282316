import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Honey = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4.425V7C20 7 23 9 22 10C20.6314 11.2516 18.8542 11.9625 17 12C16 12 15.25 11.625 14.5 11.25C13.75 10.875 13 10.5 12 10.5C11 10.5 10.25 10.875 9.5 11.25C8.75 11.625 8 12 7 12C5 12 2 11 2 10C2 9 4 7 4 7V4.425C4.00384 3.08731 5.08731 2.00384 6.425 2H17.575C18.9127 2.00384 19.9962 3.08731 20 4.425ZM6.425 3.5C5.91706 3.50699 5.50699 3.91706 5.5 4.425V6H18.5V4.425C18.493 3.91706 18.0829 3.50699 17.575 3.5H6.425ZM17 10.75C16.3122 10.7059 15.6457 10.4937 15.059 10.132C14.1286 9.58808 13.0772 9.28492 12 9.25C10.9228 9.28492 9.87141 9.58808 8.941 10.132C8.35431 10.4937 7.68782 10.7059 7 10.75C5.72655 10.7233 4.47669 10.4012 3.349 9.809C3.68832 9.24875 4.08622 8.72615 4.536 8.25H19.605C20.0557 8.57465 20.4651 8.95312 20.824 9.377C19.7258 10.226 18.3875 10.7065 17 10.75ZM17 13C15.9616 12.9639 14.9486 12.6701 14.052 12.145C13.431 11.7655 12.7264 11.544 12 11.5C11.2732 11.5438 10.5683 11.7653 9.947 12.145C9.05074 12.6699 8.03803 12.9637 7 13C5.98248 12.9687 4.97364 12.8022 4 12.505V20C4.00329 21.1032 4.8968 21.9967 6 22H18C19.1032 21.9967 19.9967 21.1032 20 20V12.391C19.0468 12.7794 18.0292 12.986 17 13ZM15 18.5L12 20L9 18.5V15.5L12 14L15 15.5V18.5Z"
      />
    </IconSVGView>
  );
};
