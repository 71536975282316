//return the color code based on yard status

export const getColorCodeByCriticality = (yard) => {
  const { recently_updated, total_hives, hardware, ai_events } = yard;
  const { nb_no_transmission } = hardware;
  const { criticality } = ai_events;
  const noGoodYard = !recently_updated || total_hives === nb_no_transmission;

  switch (true) {
    case noGoodYard:
      return 'noGoodYard';
    case criticality > 30:
      return 'critical';
    case criticality <= 30 && criticality >= 15:
      return 'worrying';
    case criticality < 15:
      return 'good';
    default:
      return 'unknown';
  }
};
