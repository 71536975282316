import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ArrowDropdown = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path fill={useColor(color)} fillRule="evenodd" clipRule="evenodd" d="M7 10L12 15L17 10H7Z" />
    </IconSVGView>
  );
};
