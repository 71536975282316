import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common/Text';
import { InputNumber } from '@components/form/InputNumber';
import { InputNumberRef } from '@components/form/InputNumber/types';
import { InputText } from '@components/form/InputText';

import { StyledForm, StyledInputWrapper } from './styles';
import { FinancesFormSectionProps } from './types';

export const FormSectionFinances: React.FC<FinancesFormSectionProps> = ({
  isActive,
  section,
  activeSection,
  handleFormSectionSubmit,
  formState,
  defaultState,
}) => {
  const { t } = useTranslation();

  const [localFormState, setLocalFormState] = useState<BeeContractFinances>(defaultState);
  const [isDirtyTotalPrice, setIsDirtyTotalPrice] = useState(false);
  const firstInputRef = useRef<InputNumberRef>(null);

  const handleFormFieldChange = (value: unknown, name: string) => {
    setLocalFormState((oldState) => ({ ...oldState, [name]: value }));
  };

  const focusFirstInput = useCallback(() => {
    if (isActive && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (localFormState.costPerHive && formState.nbRequiredHives && !isDirtyTotalPrice) {
      const totalPrice = localFormState.costPerHive * formState.nbRequiredHives;
      setLocalFormState((oldState) => ({ ...oldState, totalPrice }));
    }
  }, [isDirtyTotalPrice, localFormState.costPerHive, formState.nbRequiredHives]);

  return (
    <StyledForm
      onTransitionEnd={focusFirstInput}
      isActive={isActive}
      activeSection={activeSection}
      id={`contractForm-section-${section}`}
      onSubmit={(e) => handleFormSectionSubmit(e, localFormState)}
    >
      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('price')}
        </Text>
        <StyledInputWrapper>
          <InputNumber
            id="contractForm-costPerHive"
            ref={firstInputRef}
            name="costPerHive"
            inputMode="money"
            decimal
            positive
            disabled={!isActive}
            label={t('pollination_cost_per_hive')}
            placeholder={t('pollination_cost_per_hive_placeholder')}
            value={localFormState['costPerHive']}
            onNumberChange={handleFormFieldChange}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputNumber
            id="contractForm-totalPrice"
            name="totalPrice"
            inputMode="money"
            decimal
            positive
            disabled={!isActive}
            label={t('pollination_total_price')}
            placeholder={t('pollination_total_price_placeholder')}
            value={localFormState['totalPrice']}
            onNumberChange={(newValue, name) => {
              handleFormFieldChange(newValue, name);
              setIsDirtyTotalPrice(true);
            }}
          />
        </StyledInputWrapper>
      </fieldset>
      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('location')}
        </Text>

        <StyledInputWrapper>
          <InputText
            disabled={!isActive}
            label={t('address')}
            id="contractForm-address"
            placeholder={t('address_placeholder')}
            name="address"
            value={localFormState['address']}
            onTextChange={handleFormFieldChange}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputText
            disabled={!isActive}
            rows={2}
            label={t('pollination_notes')}
            type="textarea"
            id="contractForm-address"
            name="notes"
            value={localFormState['notes']}
            onTextChange={handleFormFieldChange}
          />
        </StyledInputWrapper>
      </fieldset>
    </StyledForm>
  );
};
