import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import triangleDownIcon from '@assets/xSmall_Arrow-dropdown.svg';
import Dropdown from '@components/deprecated/Dropdown/Dropdown';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { getActionCategory } from '@helpers/deprecated/actions';
import { capitalizeFirst } from '@helpers/deprecated/capitalizeFirst';
import { withGetScreen } from '@HOC/withGetScreen';
import {
  makeAddActionRequestThunk,
  makeCloseBeekPracticesModalAction,
  makeEnableDisableActionRequestThunk,
  makeOpenBeekPracticesModalAction,
  makeUpdateActionRequestThunk,
} from '@redux/deprecated/actions';
import theme from '@style/theme';

import Modal from '../Modal/Modal';

// nectar:
import { BeekPracticesModalView } from './BeekPracticesModalView';

const initialState = {
  action: null,
  isDisabledBtn: true,
  errorMsg: '',
  errorMsgSpanish: '',
  keepAdding: false,
};

class BeekPracticesModal extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      title: '',
      instructions: '',
      buttonText: '',
      category_id: 'none',
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.verifyInput = this.verifyInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
    this.handleKeepAdding = this.handleKeepAdding.bind(this);
    this.handleSetCategory = this.handleSetCategory.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { t, contentObj, actionList, translations } = this.props;
    const { category_id } = this.state;
    const { modalType, category, actionObj } = contentObj;

    const dropdownItems = actionList ? [...actionList] : [];

    dropdownItems.unshift({
      category_id: 'none',
      category_name: t('choose_one'),
    });

    let items = dropdownItems.filter((item) => !item.read_only);

    if (contentObj !== prevProps.contentObj) {
      const detail_spanish = translations?.find((tr) => tr.hht_actions === actionObj?.id);
      switch (true) {
        case 'update-practice' === modalType:
          return this.setState({
            title: t('edit_practice', { category: category.name }),
            // instructions: t('edit_practice_instructions'), // TODO: Validate with design team.
            buttonText: t('save'),
            action: {
              ...actionObj,
              detail_spanish: detail_spanish?.translated,
            },
            category_id: category.id,
            dropdown: (
              <div className="select-box-disabled">
                <img
                  className="select-img-input-disabled"
                  src={getActionCategory('icon', actionList, actionObj.id)}
                  alt="dropdown icon"
                />
                <label className="select-box-input-text-disabled">
                  {capitalizeFirst(getActionCategory('name', actionList, actionObj.id))}
                </label>
                <img className="select-box-icon-disabled" src={triangleDownIcon} alt="arrow icon" aria-hidden={true} />
              </div>
            ),
          });
        case 'create-practice' === modalType:
          return this.setState({
            title: t('create_new_practice', { category: category.name }),
            instructions: '',
            buttonText: t('create'),
            action: null,
            category_id: category.id,
            dropdown: (
              <Dropdown
                t={t}
                key={category_id}
                renderIcon={true}
                inputName="actions"
                activeItemId={category_id}
                list={items.map((item) => {
                  return {
                    id: item.category_id,
                    name: item.category_name,
                  };
                })}
                passDropdownDataToParent={this.handleSetCategory}
                selectBoxListStyle={{ height: '10rem' }}
              />
            ),
          });
        case 'remove-practice' === modalType:
          const { detail } = actionObj;
          return this.setState({
            title: t('disable_practice', { category: category.name }),
            instructions: (
              <>
                {t('remove_action_instructions')}
                <span style={{ fontWeight: theme.fontWeightSemiBold }}>{detail}</span>
                {t('remove_action_instructions_cont')}
              </>
            ),
            buttonText: t('disable'),
            action: actionObj,
          });
        default:
          break;
      }
    }
  }

  handleClose(e) {
    e.preventDefault();
    const { closeDispatch } = this.props;
    closeDispatch();

    this.setState({
      ...initialState,
      category_id: 'none',
    });
  }

  setIfButtonIsDisabled() {
    const { action, category_id } = this.state;

    this.setState({
      isDisabledBtn: true,
    });

    if (category_id && action?.detail && 'none' !== category_id) {
      this.setState({
        isDisabledBtn: false,
      });
    }
  }

  handleChange(event) {
    this.setState(
      {
        action: {
          ...this.state.action,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  checkActionDetail(action) {
    const { actionList, t } = this.props;
    let error = false;
    actionList.forEach((act) =>
      act.actions.forEach((a) => {
        if (a.id !== action?.id && a.detail.trim().toLowerCase() === action?.detail.trim().toLowerCase()) {
          this.setState({
            errorMsg: t('practice_exists'),
          });
          error = true;
        }
      })
    );
    return error;
  }

  checkActionDetailSpanish(action) {
    const { actionList, t, translations } = this.props;
    let error = false;

    const getDetailName = (id) => {
      let detail;
      actionList.forEach((act) =>
        act.actions.forEach((a) => {
          if (a.id === id) detail = a.detail;
        })
      );
      return detail;
    };

    if (action?.detail_spanish) {
      translations.forEach((translation) => {
        if (
          translation.hht_actions !== action?.id &&
          translation?.translated.trim().toLowerCase() === action?.detail_spanish.trim().toLowerCase()
        ) {
          this.setState({
            errorMsgSpanish: t('practice_spanish_exists', { detail: getDetailName(translation.hht_actions) }),
          });
          error = true;
        }
      });
    }
    return error;
  }

  /**
   *
   * @returns {boolean}
   */
  verifyInput() {
    const { t } = this.props;

    const { action } = this.state;

    this.setState({
      errorMsg: '',
    });

    let error = false;

    if (!action.detail) {
      this.setState({
        errorMsg: t('check_practice'),
      });
      error = true;
    }

    if (this.checkActionDetail(action) || this.checkActionDetailSpanish(action)) error = true;

    return error;
  }

  handleKeyDown(event) {
    const { contentObj } = this.props;
    const { isDisabledBtn } = this.state;

    const { modalType } = contentObj;

    if (modalType.includes('practice')) {
      if ('Enter' === event.key && !isDisabledBtn) this.handleSubmit(event);
    }
  }

  handleKeepAdding() {
    this.setState({
      keepAdding: !this.state.keepAdding,
    });
  }

  handleSetCategory(category_id) {
    this.setState(
      {
        category_id,
      },
      () => this.setIfButtonIsDisabled()
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    const { action, keepAdding } = this.state;
    const {
      deactivateActionDispatch,
      updateActionDispatch,
      createActionDispatch,
      contentObj,
      openBeekPracticesModal,
      closeDispatch,
    } = this.props;

    const { modalType, category } = contentObj;

    if ('remove-practice' === modalType) {
      const { id, detail } = action;
      deactivateActionDispatch(id, { is_active: false });
      this.setState({
        ...initialState,
      });
      Analytics.sendEvent({ event: AnalyticsEventType.PRACTICE_DISABLE });
      return;
    }

    if (!this.verifyInput()) {
      if ('update-practice' === modalType) {
        const { id, detail, detail_spanish } = action;
        const data = {
          detail,
          detail_spanish,
        };
        Analytics.sendEvent({ event: AnalyticsEventType.PRACTICE_EDIT });
        updateActionDispatch(id, data);
        this.setState({
          ...initialState,
          category_id: 'none',
        });
        closeDispatch();
        return;
      }
      if ('create-practice' === modalType) {
        const { detail, detail_spanish } = action;
        const { category_id } = this.state;

        Analytics.sendEvent({ event: AnalyticsEventType.PRACTICE_CREATE });

        createActionDispatch({
          hht_actions_category: category_id,
          detail,
          detail_spanish,
        });
        if (keepAdding) {
          openBeekPracticesModal({ modalType: 'create-practice', category });
          this.setState({
            ...initialState,
            keepAdding: true,
          });
        } else {
          closeDispatch();
          this.setState({
            ...initialState,
            category_id: 'none',
          });
        }
      }
    }
  }

  render() {
    const { t, isActionOpen, isMobile, contentObj, actionList } = this.props;

    const { isDisabledBtn, errorMsg, errorMsgSpanish, action, title, instructions, buttonText, keepAdding, dropdown } =
      this.state;

    const { modalType, categoryID } = contentObj;

    return (
      <Modal isMobile={isMobile()} isOpen={isActionOpen} onRequestClose={this.handleClose} modalType={modalType}>
        <BeekPracticesModalView
          t={t}
          isMobile={isMobile()}
          contentObj={{
            actionList,
            action,
            title,
            instructions,
            buttonText,
            modalType: contentObj.modalType,
            category_id: categoryID,
            dropdown,
          }}
          isDisabledBtn={isDisabledBtn}
          errorMsg={errorMsg}
          errorMsgSpanish={errorMsgSpanish}
          keepAdding={keepAdding}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleKeepAdding={this.handleKeepAdding}
        />
      </Modal>
    );
  }
}
/**
 *
 * @param dispatch
 * @returns {{closeDispatch: makeCloseBeekPracticesModalAction}}
 * @returns {{updateActionDispatch: makeUpdateActionRequestThunk}}
 * @returns {{createActionDispatch: makeAddActionRequestThunk}}
 * @returns {{deactivateActionDispatch: makeEnableDisableActionRequestThunk}}
 * @returns {{openBeekPracticesModal: makeOpenBeekPracticesModalAction}}
 *
 *
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeCloseBeekPracticesModalAction());
  },
  updateActionDispatch: (id, body) => {
    dispatch(makeUpdateActionRequestThunk(id, body));
  },
  createActionDispatch: (body) => {
    dispatch(makeAddActionRequestThunk(body));
  },
  deactivateActionDispatch: (id, body) => {
    dispatch(makeEnableDisableActionRequestThunk(id, body));
  },
  openBeekPracticesModal: (content) => {
    dispatch(makeOpenBeekPracticesModalAction(content));
  },
});

/**
 *
 * @param state
 * @returns {{isActionOpen: (*|boolean)}}
 */
const mapStateToProps = (state) => ({
  isActionOpen: state.modalReducer.isActionOpen,
  contentObj: state.modalReducer.contentObj,
  actionList: state.actionReducer.actionList,
  translations: state.translationsReducer.translations,
});

BeekPracticesModal.propTypes = {
  isActionOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(BeekPracticesModal)))
);
