import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import has from 'lodash/has';
import PropTypes from 'prop-types';

import { Loading } from '@components/common/Loading';
import { Modal } from '@components/common/Modal';
import { GridApiContext } from '@components/yard/YardsList/context';
import { deepClone, findAndReplaceById, findReplaceOrAdd } from '@helpers/deprecated/array';
import { DEFAULT_COLOR_INDEX } from '@helpers/deprecated/color';
import { getUniqId } from '@helpers/deprecated/getUniqId';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { withGetScreen } from '@HOC/withGetScreen';
import {
  makeAssignYardGroupsRequestThunk,
  makeCloseAssignYardGroupsModalAction,
  makeCloseManyToManyGroupsModalAction,
  makeFetchGroupsAssignmentRequestThunk,
  makeFetchManyGroupsRequestThunk,
} from '@redux/deprecated/actions';

import { AssignYardsGroupsModalView } from './AssignYardsGroupsModalView';

const initialState = {
  add: [],
  patch: [],
  new_groups: [],
  yard: null,
  yard_ids: [],
  assign_group_ids: [],
  unassign_group_ids: [],
};

const initialCreateState = {
  createGroup: false,
  name: '',
};
class AssignYardsGroupsModal extends React.Component {
  static contextType = GridApiContext;
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      ...initialCreateState,
      inputRef: null,
      buttonRef: null,
      groups: props.groups ?? null,
      assignedGroups: null,
    };

    this.inputRef = createRef();
    this.buttonRef = createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.toggleCreateGroup = this.toggleCreateGroup.bind(this);
    this.handleAddNewGroupName = this.handleAddNewGroupName.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeExistingGroupName = this.handleChangeExistingGroupName.bind(this);
    this.setSelectedGroupColor = this.setSelectedGroupColor.bind(this);
    this.handleGroupModification = this.handleGroupModification.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
    document.removeEventListener('click', this.handleClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentObj, groups, assignedGroups, fetchAssignedGroups, fetchAllAssignedGroups } = this.props;

    const { createGroup, inputRef } = this.state;

    if (prevProps.contentObj !== contentObj) {
      const { modalType } = contentObj;
      if ('assign-many-to-many' === modalType) {
        const { yard_ids, hasSelectedAllYardsAcrossPages } = contentObj;
        if (hasSelectedAllYardsAcrossPages) fetchAllAssignedGroups();
        else {
          this.setState({ yard_ids });
          fetchAssignedGroups(yard_ids);
        }
      } else if ('assign-yard-groups' === modalType) {
        const { yard } = contentObj;
        this.setState({ yard });
      }
    }

    if (prevProps.assignedGroups !== assignedGroups) this.setState({ assignedGroups });

    if (prevProps.groups !== groups) this.setState({ groups });

    if (!prevState.createGroup && createGroup) {
      inputRef.current.focus();
    }
  }

  handleLatestChanges() {
    // These promises will make the end of the set state
    // calls accessible. This isn't the best solution, but
    // I'm applying it assuming that the best approach for
    // now is to be faster, rather than correct.
    const promises = [];

    const { contentObj } = this.props;
    const { modalType } = contentObj;
    const { createGroup, buttonRef, groups, new_groups, name, yard, assign_group_ids } = this.state;

    const hasCreatedAGroup = createGroup && !buttonRef && name !== '';

    if (hasCreatedAGroup) {
      let updateGroups = deepClone(groups);
      let updateNewGroups = deepClone(new_groups);
      let id = getUniqId();
      let newGroup = {
        id,
        name,
        color: DEFAULT_COLOR_INDEX,
      };
      updateGroups.push(newGroup);
      updateNewGroups.push(newGroup);

      promises.push(
        new Promise((resolve) =>
          this.setState(
            {
              ...initialCreateState,
              groups: updateGroups,
              new_groups: updateNewGroups,
            },
            () => resolve()
          )
        )
      );

      if ('assign-yard-groups' === modalType) {
        let updateGroupIds = yard ? deepClone(yard?.group_ids) : [];

        updateGroupIds.push(id);

        promises.push(
          new Promise((resolve) =>
            this.setState(
              {
                yard: {
                  ...yard,
                  group_ids: updateGroupIds,
                },
              },
              () => resolve()
            )
          )
        );
      }
      if ('assign-many-to-many' === modalType) {
        let updateAssignGroupIds = deepClone(assign_group_ids);

        updateAssignGroupIds.push(id);

        promises.push(
          new Promise((resolve) => this.setState({ assign_group_ids: updateAssignGroupIds }, () => resolve()))
        );
      }
    }

    return Promise.all(promises);
  }

  handleClick(e) {
    const { inputRef } = this.state;
    if (e.target !== inputRef?.current) {
      this.handleLatestChanges().then(null);
    }
  }

  /**
   *
   * @param group
   *
   */
  handleSelectGroup(group) {
    const { contentObj } = this.props;
    const { modalType } = contentObj;
    const { yard, add, new_groups, assign_group_ids, unassign_group_ids, assignedGroups } = this.state;
    const { id, color, name } = group;

    let updateNewGroups = deepClone(new_groups);
    let updateAdd = deepClone(add);
    let groupSelected;

    if ('assign-yard-groups' === modalType) {
      let updateGroupIds = deepClone(yard.group_ids);
      groupSelected = yard.group_ids.includes(id);

      if (groupSelected) updateGroupIds = updateGroupIds.filter((id) => id !== group.id);
      else updateGroupIds.push(id);

      this.setState({
        yard: {
          ...yard,
          group_ids: updateGroupIds,
        },
      });
    }

    if ('assign-many-to-many' === modalType) {
      let updateAssignGroupIds = deepClone(assign_group_ids);
      let updateUnassignGroupsIds = deepClone(unassign_group_ids);
      let updateAssignedGroups = deepClone(assignedGroups);
      groupSelected = assign_group_ids.includes(id) || 'all' === assignedGroups[id];

      if (groupSelected) {
        updateAssignGroupIds = updateAssignGroupIds.filter((id) => id !== group.id);
        updateUnassignGroupsIds.push(id);
        updateAssignedGroups[id] = 'none';
      } else {
        updateUnassignGroupsIds = updateUnassignGroupsIds.filter((id) => id !== group.id);
        updateAssignGroupIds.push(id);
        updateAssignedGroups[id] = 'all';
      }

      this.setState({
        assign_group_ids: updateAssignGroupIds,
        unassign_group_ids: updateUnassignGroupsIds,
        assignedGroups: updateAssignedGroups,
      });
    }

    if (typeof id === 'string' && id.startsWith('uid')) {
      if (groupSelected) {
        updateNewGroups = updateNewGroups.filter((g) => g.id !== id);
        updateAdd = findReplaceOrAdd(add, { id, name, color });
      } else {
        updateAdd = updateAdd.filter((g) => g.id !== id);
        updateNewGroups.push({ id, name, color });
      }
    }

    this.setState({
      add: updateAdd,
      new_groups: updateNewGroups,
    });
  }

  /**
   *
   * @param inputRef
   * @param buttonRef
   *
   */
  toggleCreateGroup(inputRef, buttonRef) {
    const { createGroup } = this.state;

    this.setState({
      createGroup: !createGroup,
      inputRef,
      buttonRef,
    });
  }

  handleAddNewGroupName(e) {
    let name = e.target.value;

    this.setState({
      name,
      buttonRef: null,
    });
  }

  /**
   *
   * @param updated
   */
  handleGroupModification(updated) {
    const { patch, groups, add, new_groups } = this.state;
    const { id } = updated;

    if (typeof id !== 'string') {
      let updatedPatch = findReplaceOrAdd(patch, updated);
      this.setState({ patch: updatedPatch });
    }
    let updatedGroups = findAndReplaceById(groups, updated);
    let updatedAdd = findAndReplaceById(add, updated);
    let updatedNewGroups = findAndReplaceById(new_groups, updated);

    this.setState({
      add: updatedAdd,
      groups: updatedGroups,
      new_groups: updatedNewGroups,
    });
  }

  /**
   *
   * @param event
   * @param group
   */
  handleChangeExistingGroupName(event, group) {
    let name = event.target.value;
    const { id, color } = group;

    this.handleGroupModification({
      name,
      id,
      color,
    });
  }

  /**
   *
   * @param color
   * @param group
   */
  setSelectedGroupColor(color, group) {
    const { id, name } = group;

    this.handleGroupModification({
      name,
      id,
      color,
    });
  }

  handleKeyDown(e) {
    const { contentObj } = this.props;
    const { modalType } = contentObj;

    if (modalType.includes('assign')) {
      if ('Enter' === e.key) this.handleSubmit(e);
    }
  }

  handleClose(e) {
    e.preventDefault();
    const { contentObj, closeAssignYardDispatch, closeManyToManyDispatch, groups, assignedGroups } = this.props;

    const { modalType } = contentObj;

    'assign-yard-groups' === modalType ? closeAssignYardDispatch() : closeManyToManyDispatch();

    this.setState({
      ...initialState,
      ...initialCreateState,
      groups,
      assignedGroups,
    });
  }

  /**
   *
   * @param e
   */
  async handleSubmit(e) {
    e.preventDefault();

    // Flush all pending changes before submitting.
    await this.handleLatestChanges();

    const { assignYardsToGroups, closeAssignYardDispatch, closeManyToManyDispatch, contentObj } = this.props;

    const { yard, add, patch, new_groups, yard_ids, assign_group_ids, unassign_group_ids } = this.state;

    const { modalType, hasSelectedAllYardsAcrossPages, onAssign } = contentObj;

    if ('assign-yard-groups' === modalType) {
      //filter out the newly created group ids
      let group_ids = yard.group_ids.filter((g) => typeof g !== 'string');

      let assign_yard = {
        yard_id: yard.id,
        group_ids,
        new_groups,
      };

      await assignYardsToGroups({ assign_yard, add, patch }, yard);
      closeAssignYardDispatch();
      onAssign && onAssign();
    }

    if ('assign-many-to-many' === modalType) {
      let assign_yards = {
        yard_ids, // remove this if select all
        assign_group_ids: assign_group_ids.filter((g) => typeof g !== 'string'),
        unassign_group_ids: unassign_group_ids.filter((g) => typeof g !== 'string'),
        new_groups,
      };

      if (hasSelectedAllYardsAcrossPages && has(assign_yards, 'yard_ids')) delete assign_yards.yard_ids;

      await assignYardsToGroups({ assign_yards, add, patch }, null);
      closeManyToManyDispatch();
      onAssign && onAssign();
      // todo delete this  'deprecatedDeselectAllYards' when we move to whiteboard fully
      // eslint-disable-next-line react/prop-types
      this.props.deprecatedDeselectAllYards && this.props.deprecatedDeselectAllYards();

      this.context.gridApi?.refreshInfiniteCache();
    }

    this.setState({ ...initialState });
  }

  render() {
    const { t, isYardGroupsOpen, isManyGroupsOpen, isMobile, contentObj, isFetching } = this.props;

    const { yard, createGroup, name, groups, yard_ids, assignedGroups, assign_group_ids } = this.state;

    const { modalType, hasSelectedAllYardsAcrossPages } = contentObj;
    let loader,
      heading = '',
      isOpen = false;
    switch (true) {
      case 'assign-yard-groups' === modalType:
        loader = !groups || !yard;
        heading = t('assign') + ' ' + yard?.name;
        isOpen = isYardGroupsOpen;
        break;
      case 'assign-many-to-many' === modalType:
        loader = !groups || !assignedGroups;
        heading = hasSelectedAllYardsAcrossPages
          ? t('assign_all')
          : t('assign') + ' ' + maybePluralize(yard_ids.length, 'yard', t);
        isOpen = isManyGroupsOpen;
        break;
      default:
        break;
    }

    const isLoadingVisible = !!loader || isFetching;

    return (
      <Modal isOpen={isOpen} onRequestClose={this.handleClose}>
        <AssignYardsGroupsModalView
          t={t}
          isMobile={isMobile()}
          modalType={modalType}
          inputRef={this.inputRef}
          buttonRef={this.buttonRef}
          yard={yard}
          assign_group_ids={assign_group_ids}
          assignedGroups={assignedGroups ?? {}}
          groups={groups}
          createGroup={createGroup}
          name={name}
          heading={heading}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleSelectGroup={this.handleSelectGroup}
          toggleCreateGroup={this.toggleCreateGroup}
          handleAddNewGroupName={this.handleAddNewGroupName}
          handleChangeExistingGroupName={this.handleChangeExistingGroupName}
          setSelectedGroupColor={this.setSelectedGroupColor}
          isLoading={isFetching}
        />

        {isLoadingVisible && <Loading whiteBackground />}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeAssignYardDispatch: () => {
    dispatch(makeCloseAssignYardGroupsModalAction());
  },
  closeManyToManyDispatch: () => {
    dispatch(makeCloseManyToManyGroupsModalAction());
  },
  fetchAssignedGroups: (ids) => {
    dispatch(makeFetchManyGroupsRequestThunk(ids));
  },
  fetchAllAssignedGroups: () => {
    dispatch(makeFetchGroupsAssignmentRequestThunk());
  },
  assignYardsToGroups: (data, yard) => {
    return dispatch(makeAssignYardGroupsRequestThunk(data, yard));
  },
});

/**
 *
 * @param state
 * @returns {{isYardGroupsOpen: (*|boolean)}}
 * @returns {{isManyGroupsOpen: (*|boolean)}}
 * @returns {{contentObj: (*|object)}}
 * @returns {{groups: array}}
 * @returns {{assignedGroups: object}}
 *
 *
 */
const mapStateToProps = (state) => ({
  isYardGroupsOpen: state.modalReducer.isYardGroupsOpen,
  isManyGroupsOpen: state.modalReducer.isManyGroupsOpen,
  contentObj: state.modalReducer.contentObj,
  groups: state.groupsReducer.sortedGroups,
  assignedGroups: state.groupsReducer.assignedGroups,
  isFetching: state.groupsReducer.isFetching,
});

AssignYardsGroupsModal.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  isYardGroupsOpen: PropTypes.bool.isRequired,
  isManyGroupsOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  closeAssignYardDispatch: PropTypes.func.isRequired,
  closeManyToManyDispatch: PropTypes.func.isRequired,
  groups: PropTypes.array,
  assignedGroups: PropTypes.object,
  fetchAssignedGroups: PropTypes.func.isRequired,
  fetchAllAssignedGroups: PropTypes.func.isRequired,
  assignYardsToGroups: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(AssignYardsGroupsModal)))
);
