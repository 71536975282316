/**
 *
 * trims the string to the specified length and replaces the rest with ...
 *
 * @param string
 * @param trimBy
 * @returns {Function}
 */
export const trimString = (string, trimBy) => {
  if (!string) {
    return string;
  }
  return string.length <= trimBy ? `${string}` : `${string}`.substr(0, trimBy) + `...`;
};
