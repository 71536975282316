import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Modal, ModalFooter } from '@components/common/Modal';
import { useTimelineByActivityRequestModalOpener } from '@components/timeline/TimelineByActivityRequest/hooks';
import { StyledTimelineModalInner } from '@components/timeline/TimelineByActivityRequest/styles';
import { TimelineByActivityRequest } from '@components/timeline/TimelineByActivityRequest/TimelineByActivityRequest';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeTimelineFetchVisitActivityThunk } from '@redux/Timeline/actions';
import { useDetailedActivitySet } from '@redux/Timeline/hooks';
import { ActivityFilters } from '@redux/Timeline/types';

export const TimelineByActivityRequestModal: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<ActivityFilters | null>(null);

  const { openedTimelineByActivityRequestModal, closeTimelineByActivityRequestModal } =
    useTimelineByActivityRequestModalOpener();

  const { isFetchingDetailedActivitySets, detailedActivitySet } = useDetailedActivitySet(filters);

  const isLoadingInitialItems = Boolean(isFetchingDetailedActivitySets && !detailedActivitySet?.activities.length);
  const isLoadingMoreItems = Boolean(isFetchingDetailedActivitySets && detailedActivitySet?.activities.length);

  const loadItems = useCallback(() => {
    if (filters) {
      dispatch(makeTimelineFetchVisitActivityThunk(filters));
    }
  }, [filters, dispatch]);

  useEffect(() => {
    if (openedTimelineByActivityRequestModal) {
      setIsOpen(true);
      setFilters(openedTimelineByActivityRequestModal);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openedTimelineByActivityRequestModal?.date,
    openedTimelineByActivityRequestModal?.yardId,
    openedTimelineByActivityRequestModal?.alertType,
  ]);

  useEffect(() => {
    if (!detailedActivitySet?.activities.length) {
      loadItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.date, filters?.yardId, filters?.alertType]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeTimelineByActivityRequestModal} size={'auto'}>
      <StyledTimelineModalInner>
        <TimelineByActivityRequest
          items={detailedActivitySet?.activities ?? []}
          itemsTotalCount={detailedActivitySet?.totalCount ?? 0}
          onMoreItemsRequest={loadItems}
          isLoadingInitialItems={isLoadingInitialItems}
          isLoadingMoreItems={isLoadingMoreItems}
          suppressDayMarks
          footerMessage={t('timeline_bottom_message')}
        />
      </StyledTimelineModalInner>
      <ModalFooter>
        <Box fit justifyContent={'flex-end'}>
          <Button onClick={closeTimelineByActivityRequestModal} primary>
            {t('close')}
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};
