import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withGetScreen } from '@HOC/withGetScreen';
// nectar:
import { makeForgottenPasswordRequestThunk } from '@redux/deprecated/actions';
import { isValidEmailAddress } from '@regex/isValidEmail';

import { ForgottenPasswordView } from './ForgottenPasswordView';

class ForgottenPassword extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMsg: '',
      isDisabledBtn: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.goBackHandler = this.goBackHandler.bind(this);
    this.verifyInput = this.verifyInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  /**
   *
   */
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  /**
   *
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown(e) {
    const { isDisabledBtn } = this.state;
    if ('Enter' === e.key && !isDisabledBtn) {
      this.handleSubmit(e);
    }
  }

  goBackHandler(e) {
    const { history } = this.props;
    if (!history) {
      e.preventDefault();
    } else {
      history.goBack();
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.state;

    const { submitDispatcher, history } = this.props;

    if (!this.verifyInput()) {
      submitDispatcher({ email }, history);

      this.setState({
        email: '',
        errorMsg: '',
        isDisabledBtn: true,
      });
    }
  }

  /**
   *
   * @returns {boolean}
   */
  verifyInput() {
    const { t } = this.props;

    const { email } = this.state;

    this.setState({
      errorMsg: '',
    });

    let error = false;

    if (email && !isValidEmailAddress(email)) {
      this.setState({
        errorMsg: t('check_email_input'),
      });
      error = true;
    }

    return error;
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value.trim(),
      },
      () => {
        const { email } = this.state;
        this.setState({
          isDisabledBtn: true,
        });
        if (email) {
          this.setState({
            isDisabledBtn: false,
          });
        }
      }
    );
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const { email, errorMsg, isDisabledBtn } = this.state;

    const { t, isMobile } = this.props;

    return (
      <ForgottenPasswordView
        t={t}
        isMobile={isMobile()}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleGoBack={this.goBackHandler}
        isDisabledBtn={isDisabledBtn}
        email={email}
        errorMsg={errorMsg}
      />
    );
  }
}

/**
 * receives the dispatch() method and returns callback props
 * that we want to inject into the presentational component
 * @param dispatch
 * @returns {{submitDispatcher: submitDispatcher}}
 */
const mapDispatchToProps = (dispatch) => ({
  submitDispatcher: (email, history) => dispatch(makeForgottenPasswordRequestThunk(email, history)),
});

ForgottenPassword.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  submitDispatcher: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(withGetScreen()(ForgottenPassword))));
