import { useEffect } from 'react';

export enum KeyHandlerPriority {
  LOW,
  MEDIUM,
  HIGH,
}

/**
 * Defines a keyboard event handler.
 * */
interface KeyHandler {
  /**
   * Function called when keyup event is fired. If
   * it returns true, next handlers won't be called.
   * */
  handler: KeyHandlerFunction;

  /** Higher priority handlers are called first. */
  priority?: KeyHandlerPriority;

  /** Handler is only called for these keys. */
  keyFilter?: string[];
}
type KeyHandlerFunction = (event: KeyboardEvent) => void | boolean;

const keyHandlers = {} as Record<any, KeyHandler>;

/**
 * Registers a key event handler.
 * Higher priority handlers are called first.
 * If a handler returns true, it will avoid other
 * handlers from being called for that event.
 * */
export function useKeyHandler(handler: KeyHandler): void;
export function useKeyHandler(handler: KeyHandlerFunction): void;
export function useKeyHandler(handler: KeyHandler | KeyHandlerFunction): void {
  useEffect(() => {
    const keyHandler = typeof handler === 'function' ? { handler, priority: KeyHandlerPriority.LOW } : handler;
    keyHandlers[keyHandler.handler as any] = keyHandler;
    return () => {
      delete keyHandlers[keyHandler.handler as any];
    };
  }, [handler]);
}

bindGlobalEvent();
function bindGlobalEvent() {
  const handleKeyPress = (event: KeyboardEvent) => {
    const handlers = getSortedKeyHandlers();
    handlers.forEach(({ handler, keyFilter }) => {
      if (!keyFilter || keyFilter.includes(event.key)) {
        handler(event);
      }
    });
  };
  document.addEventListener('keyup', handleKeyPress);
}

function getSortedKeyHandlers() {
  return Object.values(keyHandlers).sort(
    (ha, hb) => (hb.priority ?? KeyHandlerPriority.LOW) - (ha.priority ?? KeyHandlerPriority.LOW)
  );
}
