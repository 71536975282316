import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { SlimPage } from '@components/common/SlimPage';
import { Text } from '@components/common/Text';
import BeePracticesModal from '@components/deprecated/DynamicModals/BeekPracticesModal';
import { ActionCategoryCard } from '@components/operation/ActionCategoryCard';
import { useActionCategories, useActionCategoriesFetcher } from '@hooks/useActions';

export const TabPractices: React.FC = () => {
  const { t } = useTranslation();
  const categories = useActionCategories();
  const { isFetching } = useActionCategoriesFetcher();

  return isFetching && categories.length === 0 ? (
    <Loading />
  ) : (
    <SlimPage>
      <Box marginBottomSM>
        <Text typography={'Heading1'}>{t('beek_practices')}</Text>
      </Box>
      <Box marginBottomLG>
        <Text typography={'Paragraph'}>{t('beek_practices_desc')}</Text>
      </Box>

      {categories.map(({ id }) => (
        <ActionCategoryCard key={id} categoryID={id} marginVerticalMD />
      ))}

      <BeePracticesModal />
    </SlimPage>
  );
};
