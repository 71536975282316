import produce from 'immer';

import { AssignContractActionType, AssignContractReducer, AssignContractState } from './types';

const defaultState: AssignContractState = {
  isFetching: false,
  hasFetchError: false,
  isAssignContractModalOpen: false,
  selectedYards: [],
};

export const assignContractReducer: AssignContractReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case AssignContractActionType.OPEN_ASSIGN_CONTRACT_MODAL:
        state.isAssignContractModalOpen = true;
        state.selectedYards = action.payload;
        break;

      case AssignContractActionType.CLOSE_ASSIGN_CONTRACT_MODAL:
        state.isAssignContractModalOpen = false;
        state.selectedYards = [];
        break;

      case AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_START:
        state.isFetching = true;
        break;

      case AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_SUCCESS:
        state.isFetching = false;
        state.hasFetchError = false;
        break;

      case AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_FAILURE:
        state.isFetching = false;
        state.hasFetchError = true;
        break;
    }

    return state;
  });
};
