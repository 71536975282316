import React from 'react';
import PropTypes from 'prop-types';

// nectar:
import { DropdownView } from './DropdownView';

export const Dropdown = ({
  t,
  renderIcon,
  inputName,
  activeItemId,
  list,
  passDropdownDataToParent,
  disabled,
  selectBoxStyle,
  selectBoxCurrentStyle,
  selectBoxListStyle,
  selectBoxOptionStyle,
  selectBoxInputText,
}) => {
  return (
    <DropdownView
      t={t}
      disabled={disabled}
      renderIcon={renderIcon}
      inputName={inputName}
      dropdownItems={[...list]}
      activeItemId={activeItemId}
      selectBoxStyle={selectBoxStyle}
      selectBoxCurrentStyle={selectBoxCurrentStyle}
      selectBoxListStyle={selectBoxListStyle}
      selectBoxOptionStyle={selectBoxOptionStyle}
      selectBoxInputText={selectBoxInputText}
      passDropdownDataToParent={passDropdownDataToParent}
    />
  );
};

Dropdown.propTypes = {
  t: PropTypes.func.isRequired,
  renderIcon: PropTypes.bool,
  activeItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputName: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  passDropdownDataToParent: PropTypes.func.isRequired,
};

export default Dropdown;
