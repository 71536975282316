import * as types from '../actionTypes';

export {
  makeAccountFailureAction,
  makeAccountStartAction,
  makeChangeAccountRequestThunk,
  makeFetchAccountRequestThunk,
  setUserSuccessAction,
} from './accountRequestActionCreator';
export {
  makeActionRequestFailureAction,
  makeActionRequestStartAction,
  makeAddActionRequestSuccessAction,
  makeAddActionRequestThunk,
  makeEnableDisableActionRequestThunk,
  makeFetchActionRequestThunk,
  makeUpdateActionRequestSuccessAction,
  makeUpdateActionRequestThunk,
} from './actionsRequestActionCreator';
export {
  makeBatchUpdateYardsRequestFailureAction,
  makeBatchUpdateYardsRequestStartAction,
  makeBatchUpdateYardsRequestSuccessAction,
  makeBatchUpdateYardsRequestThunk,
} from './batchYardsRequestActionCreator';
export {
  handleSortHiveList,
  makeFetchHiveListRequestSuccessAction,
  makeFetchHiveListRequestThunk,
  makeFetchHiveRequestSuccessAction,
  makeFetchHiveRequestThunk,
  makeHiveListRequestFailureAction,
  makeHiveListRequestStartAction,
  makeHiveRequestFailureAction,
  makeHiveRequestStartAction,
} from './beeTrackHiveListActionCreator';
export {
  makeAddYardToSearchHistory,
  makeDeleteYardRequestFailureAction,
  makeDeleteYardRequestStartAction,
  makeDeleteYardRequestSuccessAction,
  makeDeleteYardRequestThunk,
  makeFetchYardListFailureAction,
  makeFetchYardListRequestThunk,
  makeFetchYardListStartAction,
  makeFetchYardListSuccessAction,
  makeFetchYardMapFailureAction,
  makeFetchYardMapRequestThunk,
  makeFetchYardMapStartAction,
  makeFetchYardMapSuccessAction,
  makeFetchYardRequestFailureAction,
  makeFetchYardRequestStartAction,
  makeFetchYardRequestSuccessAction,
  makeFetchYardRequestThunk,
  makeLimitAction,
  makePaginationAction,
  makeUpdateYardRequestFailureAction,
  makeUpdateYardRequestStartAction,
  makeUpdateYardRequestSuccessAction,
  makeUpdateYardRequestThunk,
  orderYardListBy,
  orderYardListByDirection,
  resetYardData,
  setAddMultipleYards,
  setRemoveMultipleYards,
  setUpdateMultipleYards,
} from './beeTrackYardListRequestActionCreator';
export {
  makeChangePasswordRequestFailureAction,
  makeChangePasswordRequestStartAction,
  makeChangePasswordRequestSuccessAction,
  makeChangePasswordRequestThunk,
} from './changePasswordRequestActionCreator';
export {
  handleActivityFilterChange,
  makeEventListRequestFailureAction,
  makeEventListRequestStartAction,
  makeFetchEventListRequestSuccessAction,
  makeFetchEventListRequestThunk,
  makeFetchEventsByHiveRequestFailureAction,
  makeFetchEventsByHiveRequestStartAction,
  makeFetchEventsByHiveRequestSuccessAction,
  makeFetchEventsByHiveRequestThunk,
  makeFetchEventsByYardRequestFailureAction,
  makeFetchEventsByYardRequestStartAction,
  makeFetchEventsByYardRequestSuccessAction,
  makeFetchEventsByYardRequestThunk,
} from './eventListRequestActionCreator';
export { makeExportThunk } from './exportActionCreator';
export {
  makeFetchFailureAction,
  makeFetchRequestThunk,
  makeFetchStartAction,
  makeFetchSuccessAction,
} from './fetchUrlActionCreator';
export {
  makeForgottenPasswordRequestFailureAction,
  makeForgottenPasswordRequestStartAction,
  makeForgottenPasswordRequestSuccessAction,
  makeForgottenPasswordRequestThunk,
} from './forgottenPasswordRequestActionCreator';
export {
  handleSortGroups,
  makeAddGroupRequestThunk,
  makeAssignYardGroupsRequestThunk,
  makeFetchGroupsAssignmentRequestThunk,
  makeFetchGroupsDetailedListRequestThunk,
  makeFetchGroupsRequestThunk,
  makeFetchManyGroupsRequestThunk,
  makeGroupsRequestFailureAction,
  makeGroupsRequestStartAction,
  makeGroupsRequestSuccessAction,
  makeManyGroupsRequestSuccessAction,
  makeRemoveGroupRequestThunk,
  makeUpdateGroupRequestThunk,
} from './groupsRequestActionCreator';
export {
  makeLoginFailureAction,
  makeLoginRequestThunk,
  makeLoginStartRequestAction,
  makeLoginSuccessAction,
  // logout:
  makeLogoutRequestThunk,
  makeLogoutStartAction,
} from './loginRequestActionCreator';
export {
  makeCloseAssignYardGroupsModalAction,
  makeCloseBeekPracticesModalAction,
  makeCloseCreateYardModalAction,
  makeCloseDeleteYardModalAction,
  makeCloseGroupModalAction,
  makeCloseHiveModalAction,
  makeCloseManyToManyGroupsModalAction,
  makeCloseMenuAction,
  makeCloseModalAction,
  makeClosePasswordModalAction,
  makeCloseUpdateYardModalAction,
  makeCloseWorkerModalAction,
  // groups assign action
  makeOpenAssignYardsGroupsModalAction,
  // beek practices action
  makeOpenBeekPracticesModalAction,
  makeOpenCreateYardModalAction,
  // delete yard, yards and cancel changes action
  makeOpenDeleteYardModalAction,
  // group modal
  makeOpenGroupModalAction,
  // hive action
  makeOpenHiveModalAction,
  // many to many groups modal action
  makeOpenManyToManyGroupsModalAction,
  // menu action
  makeOpenMenuAction,
  // static modal actions
  makeOpenModalAction,
  // password action
  makeOpenPasswordModalAction,
  // yard action
  makeOpenUpdateYardModalAction,
  // worker action
  makeOpenWorkerModalAction,
  makeResetYardZoomModaAction,
  makeSetZoomAction,
} from './modalActionCreator';
export {
  makeRecreatePasswordRequestFailureAction,
  makeRecreatePasswordRequestStartAction,
  makeRecreatePasswordRequestSuccessAction,
  makeRecreatePasswordRequestThunk,
} from './recreatePasswordRequestAction';
export { makeShowSnackbarAction } from './snackbarActionCreator';
export {
  makeFetchTranslationsRequestSuccessAction,
  makeFetchTranslationsRequestThunk,
  makeTranslationsRequestFailureAction,
  makeTranslationsRequestStartAction,
  updateSpanishTranslationsRequestSuccessAction,
} from './translationsRequestActionCreator';
export {
  handleSortWorkerList,
  makeAddWorkerRequestSuccessAction,
  makeAddWorkerRequestThunk,
  makeDeactivateWorkerRequestSuccessAction,
  makeDeactivateWorkerRequestThunk,
  makeFetchWorkerRequestThunk,
  makeResendInviteWorkerRequestThunk,
  makeUpdateWorkerRequestSuccessAction,
  makeUpdateWorkerRequestThunk,
  makeWorkerRequestFailureAction,
  makeWorkerRequestStartAction,
} from './workerRequestActionCreator';

/**
 *
 * @param content
 * @returns {{type: string, content: *}}
 */
export const makeNavBarTitleChangeAction = (content) => ({
  type: types.NAV_BAR_TITLE_CHANGE,
  content,
});

/**
 *
 * @param content
 * @returns {{type: string}}
 */
export const resetNotificationCountToZero = () => ({
  type: types.RESET_NOTIFICATIONS_COUNT_TO_ZERO,
});

/**
 *
 * @param content
 * @returns {{type: string, notificationCount: number}}
 */
export const setNotificationCount = (notificationCount) => ({
  type: types.SET_NOTIFICATION_COUNT,
  notificationCount,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeUnauthorizedFetchFailureAction = () => ({
  type: types.API_UNAUTHORIZED_REQUEST_FAILURE,
});

export const setEditModeAction = (editMode) => ({
  type: types.SET_EDIT_MODE,
  editMode,
});

export const toggleMapType = () => ({
  type: types.TOGGLE_MAP_TYPE,
});

export const setZoomGlobal = (zoom) => ({
  type: types.SET_ZOOM_GLOBAL,
  zoom,
});

export const setCenterGlobal = (lat, lng) => ({
  type: types.SET_CENTER_GLOBAL,
  lat,
  lng,
});

export const setBeeFlyZones = (beeFlyZones) => ({
  type: types.SET_BEE_FLY_ZONES,
  beeFlyZones,
});
