import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';
import moment from 'moment/moment';

import { useOperation } from '@hooks/useOperation';
import { useTranslation } from '@hooks/useTranslation';

export function useColumnTooltip(columnDef: ColDef) {
  const getter = useColumnTooltipGetter();
  return getter(columnDef);
}

export function useColumnTooltipGetter() {
  const t = useTranslation();

  const operation = useOperation();
  const seasonStart = operation ? moment(operation.seasonStart).format('MMMM DD, YYYY') : null;

  return useCallback(
    (columnDef: ColDef) => {
      switch (columnDef.field) {
        case 'deadout':
          return t('deadout_since', { date: seasonStart });
        case 'averageVisits':
          return t('avg_visits');
        default:
          return null;
      }
    },
    [seasonStart, t]
  );
}
