export enum AnalyticsEventType {
  SESSION_START = 'mp_session_start',
  PASSWORD_CHANGE = 'mp_password_change',
  NAVBAR_MENU_OPEN = 'mp_navbar_menu_open',
  WHITEBOARD_SORT = 'mp_whiteboard_sort',
  WHITEBOARD_FILTER = 'mp_whiteboard_filter',
  WHITEBOARD_COLUMNS_CHANGE = 'mp_whiteboard_columns_change',
  WHITEBOARD_COLUMN_VIEW_CHANGE = 'mp_whiteboard_column_view_change',
  WHITEBOARD_TOOLTIP_VIEW = 'mp_whiteboard_tooltip_view',
  WHITEBOARD_PARTIAL_LOAD = 'mp_whiteboard_partial_load',
  WHITEBOARD_COMPLETE_LOAD = 'mp_whiteboard_complete_load',
  WHITEBOARD_VIEW_SAVE = 'mp_whiteboard_view_save',
  YARDS_MAP_LEFT_PANE_VIEW = 'mp_yards_map_left_pane_view',
  YARDS_MAP_TYPE_CHANGE = 'mp_yards_map_type_change',
  YARDS_MAP_LAYER_CHANGE = 'mp_yards_map_layer_change',
  YARD_CREATE = 'mp_yard_create',
  YARD_EDIT = 'mp_yard_edit',
  HIVE_DETAILS_VIEW = 'mp_hive_details_view',
  PRACTICE_CREATE = 'mp_practice_create',
  PRACTICE_EDIT = 'mp_practice_edit',
  PRACTICE_ENABLE = 'mp_practice_enable',
  PRACTICE_DISABLE = 'mp_practice_disable',
  WORKER_CREATE = 'mp_worker_create',
  WORKER_EDIT = 'mp_worker_edit',
  WORKER_REMOVE = 'mp_worker_remove',
  METRIC_CHART_VIEW = 'mp_metric_chart_view',
  METRIC_CHART_EXIT = 'mp_metric_chart_exit',
}

export type AnalyticsEvent =
  | {
      event: AnalyticsEventType.SESSION_START;
      eventData: {
        user_id: number;
        user_language: string;
        user_type: 'staff' | 'customer';
        operation_id: number;
        operation_name: string;
        app_version: string;
        app_environment: 'local' | 'development' | 'staging' | 'production';
      };
    }
  | {
      event: AnalyticsEventType.PASSWORD_CHANGE | AnalyticsEventType.NAVBAR_MENU_OPEN;
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_SORT;
      eventData: {
        column: string;
        direction: 'asc' | 'desc';
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_FILTER;
      eventData: {
        sections: string;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_COLUMNS_CHANGE;
      eventData: {
        visible_columns: string;
        origin: 'header-menu' | 'management-modal' | 'drag-and-drop';
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_COLUMN_VIEW_CHANGE;
      eventData: {
        column: string;
        column_type: 'default' | 'practice' | 'practice_category';
        column_view: string;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_TOOLTIP_VIEW;
      eventData: {
        column: string;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_PARTIAL_LOAD | AnalyticsEventType.WHITEBOARD_COMPLETE_LOAD;
      eventData: {
        visible_columns: string;
        elapsed: number;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_VIEW_SAVE | AnalyticsEventType.YARDS_MAP_LEFT_PANE_VIEW;
    }
  | {
      event: AnalyticsEventType.YARDS_MAP_TYPE_CHANGE;
      eventData: {
        map_type: 'terrain' | 'terrain-simplified' | 'satellite' | 'satellite-simplified';
      };
    }
  | {
      event: AnalyticsEventType.YARDS_MAP_LAYER_CHANGE;
      eventData: {
        map_layers: string;
      };
    }
  | {
      event:
        | AnalyticsEventType.YARD_CREATE
        | AnalyticsEventType.YARD_EDIT
        | AnalyticsEventType.HIVE_DETAILS_VIEW
        | AnalyticsEventType.PRACTICE_CREATE
        | AnalyticsEventType.PRACTICE_EDIT
        | AnalyticsEventType.PRACTICE_ENABLE
        | AnalyticsEventType.PRACTICE_DISABLE
        | AnalyticsEventType.WORKER_CREATE
        | AnalyticsEventType.WORKER_EDIT
        | AnalyticsEventType.WORKER_REMOVE;
    }
  | {
      event: AnalyticsEventType.METRIC_CHART_VIEW;
      eventData: {
        metric: string;
      };
    }
  | {
      event: AnalyticsEventType.METRIC_CHART_EXIT;
      eventData: {
        metric: string;
        elapsed: number;
      };
    };
