import { padStart } from 'lodash';

export const extractDropNumber = (dropName: string | null | undefined) => {
  if (!dropName) return 0;
  const match = dropName.match(/^.*?d(\d+)$/i);
  return match ? parseInt(match[1]) : 0;
};

export const createDropName = (lastDropName: string | null) => {
  const newDropNumber = extractDropNumber(lastDropName) + 1;

  return `D${padStart(String(newDropNumber), 3, '0')}`;
};
