import { ComponentType, CSSProperties, InputHTMLAttributes, RefAttributes } from 'react';
import { Color } from 'styled-components';

import { MenuItem } from '@components/common/Menu/types';

export interface MapProps {
  /**
   * Sets which api will be responsible for rendering the map.
   * Currently, only Google Maps is available, and it's the
   * default value.
   * */
  api?: MapApi;

  /**
   * Sets the current map state. If used along with onStateChange,
   * turns the map into a controlled component.
   * */
  state?: Partial<MapState>;

  /**
   * If enabled, URL query params will be used to store the map state.
   * */
  useURLState?: boolean;

  /**
   * If enabled, SessionStorage will be used to store the map state.
   * */
  useStorageState?: boolean;

  /**
   * Called once the map changes the current state.
   * */
  onStateChange?: (state: MapState) => void;

  /**
   * Callback called once the map instance is available;
   * */
  onInstance?: (instance: MapInstance) => void;

  /**
   * Called once all map tile images are loaded.
   * */
  onTilesLoaded?: () => void;

  /**
   * Called once the map is clicked.
   * */
  onClick?: (coordinate: Coordinate, pixel: PixelPosition) => void;

  /**
   * Attributes applied to the map container.
   * */
  id?: string;
  style?: CSSProperties;
  className?: string;
  children?: any;
}

export interface MarkerProps {
  /**
   * Defines the marker position. If used along with
   * onPositionChange, turns the marker position into
   * a controlled prop, otherwise, works as an
   * initial position.
   * If null is given, the marker enters the initial
   * placing mode. Make sure you only have one placing
   * marker at time.
   * */
  position: Coordinate | null;

  /**
   * If true, allows the marker to be draggable.
   * */
  draggable?: boolean;

  /**
   * Called once the marker position changes. Can be used
   * along with position prop to turn the marker into a
   * controlled component.
   * */
  onPositionChange?: (position: Coordinate) => void;

  /**
   * Called once the marker starts or stops being dragged.
   * */
  onDraggingChange?: (dragging: boolean) => void;

  /**
   * Called once the marker receives a click.
   * */
  onClick?: () => void;

  /**
   * Called once the marker is placed by the user.
   * */
  onFirstPlacementComplete?: (position: Coordinate) => void;

  /**
   * Marker icon resource. It takes precedence over
   * children prop.
   * */
  icon?: string;

  /**
   * Position in the z axis relative to other markers.
   * */
  zIndex?: number;

  /**
   * Marker content.
   * */
  children?: any;
}

/**
 * Default marker pin component props.
 * */
export interface MarkerPinProps {
  id?: string;
  style?: CSSProperties;
  className?: string;
  animateToAppear?: boolean;
  clickable?: boolean;
  active?: boolean;
  disabled?: boolean;
  children?: any;
}

export interface MarkerDescriptor<D = any> extends MarkerProps {
  id: string | number;
  position: Coordinate;
  data: D;
}
export interface MarkerCluster {
  id: string | number;
  position: Coordinate;
  markerCount: number;
  getMarkers: () => Array<MarkerDescriptor>;
}
export interface MarkerOptimizerProps {
  /**
   * If enabled, prints out the current map
   * tiles used in the optimization.
   * */
  debug?: boolean;

  /** Improves performance by mergin overlapping markers. */
  enableClustering?: boolean;

  /** Improves performance by removing non-visible makers. */
  enableSmartBounds?: boolean;

  /** Applied to the marker cluster component. */
  markerClusterProps?: Partial<MarkerProps>;

  /**
   * Only render if map zoom is greater or
   * equals to this value.
   * */
  minZoomToRender?: number;

  /**
   * Only render if map zoom is smaller or
   * equals to this value.
   * */
  maxZoomToRender?: number;

  /**
   * After this zoom level, all markers are shown.
   * */
  maxZoomToCluster?: number;

  markers: Array<MarkerDescriptor>;
  markerRenderer?: (marker: MarkerDescriptor) => any;
  markerClusterRenderer?: (markerCluster: MarkerCluster) => any;

  /** Used to filter which markers may be considered as visible. */
  visibleMarkersFilter?: (markers: Array<MarkerDescriptor>) => Array<MarkerDescriptor>;
}

export interface PolygonProps {
  /**
   * Defines the polygon path. If used along with
   * onPathChange, turns the polygon path into
   * a controlled prop, otherwise, works as an
   * initial path.
   * If nothing is given, the polygon enters the initial
   * drawing mode. Make sure you only have one drawing
   * polygon at time.
   * */
  path?: Path | null;
  paths?: Array<Path> | null;

  /**
   * Sets the polygon area and border colors.
   * */
  fillColor?: Color | string;
  fillOpacity?: number;
  strokeColor?: Color | string;
  strokeOpacity?: number;
  strokeWeight?: number;

  /**
   * If true, allows the polygon to be edited.
   * */
  editable?: boolean;

  /**
   * If true, highlights the polygon.
   * */
  selected?: boolean;

  /**
   * If true, the callback method onDrawingCancel is enabled,
   * otherwise, the drawing will auto-restart in case if an
   * invalid polygon is drawn.
   * */
  cancelable?: boolean;

  /**
   * If true, disables the click event.
   * */
  disabled?: boolean;

  /**
   * Called once the polygon path changes. Can be used
   * along with path prop to turn the polygon into a
   * controlled component.
   * */
  onPathChange?: (path: Path | null) => void;

  /**
   * Called once the polygon starts or stops being dragged.
   * */
  onDraggingChange?: (dragging: boolean) => void;

  /**
   * Called once the user complete the drawing with a valid
   * polygon.
   * */
  onDrawingComplete?: (path: Path) => void;

  /**
   * Called once the user cancels the drawing or complete
   * the drawing with not enouth vertices.
   * */
  onDrawingCancel?: () => void;

  /**
   * Called once the polygon receives a click.
   * */
  onClick?: () => void;

  /**
   * Polygons use an internal HTML marker to render its children
   * in the right map position. Use this prop to override the props
   * of this internal marker.
   * */
  childrenMarkerProps?: Omit<MarkerProps, 'position'>;

  /**
   * Polygon content.
   * */
  children?: any;

  /**
   * If true, children will always be visible, even
   * if it doesn't fit the polygon shape.
   * */
  alwaysShowChildren?: boolean;

  /**
   * If true, children will be visible when the polygon is
   * large enough to fit it, even if neighbor polygons don't.
   * */
  alwaysShowChildrenIfFits?: boolean;
}

/**
 * Default map control component props.
 * */
export interface MapControlsProps {
  /** Shows the default map type toggle menu item. */
  showMapTypeToggle?: boolean;

  /** Adds extra menu items. */
  extraMenuItems?: Array<MenuItem>;

  /** Defines how to recenter the map. */
  recenterMap?: () => void;
}

/**
 * Default map left pane component props.
 * Add the map component as the first child in order
 * to make it work.
 * */
export interface MapPaneProps {
  expanded?: boolean;
  initiallyExpanded?: boolean;

  onToggled?: (expanded: boolean) => void;

  /**
   * If true, the pane will contract the map to
   * expand instead of overlaying it.
   * */
  contractMapToExpand?: boolean;

  /** Hides the default pane toggle. */
  hideToggle?: boolean;

  /** Enables the pane scroll. */
  scrollable?: boolean;

  /**
   * If enabled, URL query params will be used
   * to store the map pane state.
   * */
  useURLState?: boolean;

  /** Overrides the default width value. */
  width?: number;

  children?: any;
}

/**
 * Default map toolbar component props.
 * Add the map component as the first child in order
 * to make it work.
 * */
export interface MapToolbarProps {
  expanded?: boolean;
  onExpanded?: (expanded: boolean) => void;

  /**
   * If enabled, URL query params will be used to store the toolbar state.
   * */
  useURLState?: boolean;

  /** Toolbar content, rendered at center. */
  children?: any;
}

/**
 * Default map search box props.
 * */
export interface MapSearchBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  onAddressFound?: (address: Address) => void;
}

/**
 * Defines the shared map context.
 * */
export interface MapContextValue {
  api: MapApi;
  state: MapState;
  setState: MapStateSetter;

  instance: MapInstance | null;

  /** Internal method, shouldn't be called directly */
  __setInstance: (instance: MapInstance) => void;
}

/**
 * Defines a reference to the current map instance.
 * */
export interface MapInstance {
  coordinateToPixel: (coordinate: Coordinate) => PixelPosition | null;
  pixelToCoordinate: (pixel: PixelPosition) => Coordinate | null;
  fitCoordinates: (coordinates: Array<Coordinate>, padding?: number | Padding) => void;

  panBy(pixel: PixelPosition): void;
  panBy(coordinate: Coordinate): void;
  panTo(coordinate: Coordinate): void;
}

/**
 * Methods responsible to get/set the current map state.
 * */
export type MapStatePartialGetter = (currentState: MapState) => Partial<MapState>;
export type MapStateSetter = (getter: MapStatePartialGetter | Partial<MapState>) => void;

/**
 * Defines the current map center, zoom and bounds state.
 * */
export interface MapState {
  mapType: MapType;
  center: Coordinate;
  zoom: number;
  bounds?: Bounds;
}

export enum MapType {
  /** Show satellite images. */
  SATELLITE = 'satellite',

  /** Show satellite images without names of streets, cities, etc... */
  SATELLITE_SIMPLIFIED = 'satellite-simplified',

  /** Shows default vector generated map. */
  TERRAIN = 'terrain',

  /** Shows default vector generated map without names od streets, cities, etc... */
  TERRAIN_SIMPLIFIED = 'terrain-simplified',
}

/**
 * Defines the availavle Map APIs.
 * */
export enum MapApi {
  GOOGLE_MAPS = 'GOOGLE_MAPS',
}

/**
 * Defines the main interface for integrated Map APIs.
 * */
export interface MapApiProvider {
  /** If given, it's used to wrap the map context. */
  contextProvider?: ComponentType<any>;

  /** How the API render the map. */
  mapRenderer: ComponentType<MapProps>;

  /** How the API renders markers. */
  markerRenderer: ComponentType<MarkerProps>;

  /** How the API renders polygons. */
  polygonRenderer: ComponentType<PolygonProps>;

  /** How the API renders a place searchbox. */
  searchBoxRenderer: ComponentType<MapSearchBoxProps> & RefAttributes<HTMLInputElement | null>;
}

/** Auxiliary interfaces. */
export interface Bounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface PixelPosition {
  x: number;
  y: number;
}

export type Path = Array<Coordinate>;

export interface Address {
  name: string;
  path: Path;
}

export interface Padding {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
