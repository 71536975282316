import React from 'react';

import { StyledLoading, StyledLoadingWrapper } from './styles';

export interface LoadingProps {
  visible?: boolean;
  whiteLoader?: boolean;
  blurBackground?: boolean;
  whiteBackground?: boolean;
  defaultBackground?: boolean;
  size?: string;
}

export const Loading: React.VFC<LoadingProps> = ({ size = '2.5rem', ...props }) => {
  return (
    <StyledLoadingWrapper {...props}>
      <StyledLoading size={size} />
    </StyledLoadingWrapper>
  );
};
