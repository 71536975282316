import { ValidatorBuilder } from '@components/form/InputTextV2/types';

/** Validator methods */

const required: ValidatorBuilder = (options) => {
  return (value) => {
    if (isEmpty(value)) {
      return options?.errorMessage;
    }
  };
};

const email: ValidatorBuilder = (options) => {
  return (value) => {
    // Regex from: https://www.w3resource.com/javascript/form/email-validation.php
    const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    if (!isEmpty(value) && !EMAIL_REGEX.test(value || '')) {
      return options?.errorMessage;
    }
  };
};

const minLength: ValidatorBuilder<{ min?: number }> = (options) => {
  return (value) => {
    const safeOptions = { min: -Infinity, ...options };

    if ((value?.length ?? 0) < safeOptions.min) {
      return safeOptions.errorMessage;
    }
  };
};

const maxLength: ValidatorBuilder<{ max?: number }> = (options) => {
  return (value) => {
    const safeOptions = { max: Infinity, ...options };

    if ((value?.length ?? 0) > safeOptions.max) {
      return safeOptions.errorMessage;
    }
  };
};

const integer: ValidatorBuilder = (options) => {
  return (value) => {
    if (!isEmpty(value) && !isInteger(value)) {
      return options?.errorMessage;
    }
  };
};

const decimal: ValidatorBuilder<{ precision?: number }> = (options) => {
  return (value) => {
    const safeOptions = { precision: 0, ...options };

    if (!isEmpty(value) && !isDecimal(value, safeOptions.precision)) {
      return safeOptions.errorMessage;
    }
  };
};

const money: ValidatorBuilder = (options) => {
  return (value, props) => {
    return positive({ ...options })(value, props) || decimal({ precision: 2, ...options })(value, props);
  };
};

const min: ValidatorBuilder<{ min?: number }> = (options) => {
  return (value) => {
    const safeOptions = { min: -Infinity, ...options };
    const numericValue = parseFloat(value ?? '');

    if (!isEmpty(value) && numericValue < safeOptions.min) {
      return safeOptions.errorMessage;
    }
  };
};

const max: ValidatorBuilder<{ max?: number }> = (options) => {
  return (value) => {
    const safeOptions = { max: Infinity, ...options };
    const numericValue = parseFloat(value ?? '');

    if (!isEmpty(value) && numericValue > safeOptions.max) {
      return safeOptions.errorMessage;
    }
  };
};

const range: ValidatorBuilder<{ min?: number; max?: number }> = (options) => {
  return (value, props) => {
    const safeOptions = { min: -Infinity, max: Infinity, ...options };

    return min(safeOptions)(value, props) || max(safeOptions)(value, props);
  };
};

const positive: ValidatorBuilder = (options) => {
  return min({ min: 0, ...options });
};

/** Helper methods */

function isEmpty(value?: string) {
  return typeof value === 'undefined' || String(value).trim() === '';
}

function isInteger(value?: string) {
  return /^\s*-?\d+\s*$/.test(value as string);
}

function isDecimal(value?: string, precision?: number) {
  const regex = new RegExp(`^\\s*-?\\d+(\\.\\d${precision ? `{1,${precision}}` : '+'})?\\s*$`);
  return regex.test(value as string);
}

export const Validators = {
  required,
  minLength,
  maxLength,
  integer,
  decimal,
  money,
  min,
  max,
  range,
  positive,
  email,
  isEmpty,
};
