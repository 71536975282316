import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Pin = ({ color, size, ...svgProps }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size} {...svgProps}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26096 4.39998C7.76988 2.85333 9.84305 1.98705 12.0038 2.0003C14.1645 1.98705 16.2377 2.85333 17.7466 4.39998C19.2555 5.94663 20.0704 8.04054 20.0038 10.2003C19.9971 13.377 17.5538 17.1203 12.6738 21.4303C12.2919 21.7604 11.7257 21.7604 11.3438 21.4303C6.45045 17.1236 4.00378 13.3803 4.00378 10.2003C3.93719 8.04054 4.75204 5.94663 6.26096 4.39998ZM10.0038 10.0003C10.0038 11.1049 10.8992 12.0003 12.0038 12.0003C13.107 11.997 14.0005 11.1035 14.0038 10.0003C14.0038 8.89573 13.1084 8.0003 12.0038 8.0003C10.8992 8.0003 10.0038 8.89573 10.0038 10.0003Z"
      />
    </IconSVGView>
  );
};
