import { useContext } from 'react';

import { AccordionContext } from '@components/common/Accordion/context';
import { AccordionContextValue } from '@components/common/Accordion/types';

export function useAccordion(): AccordionContextValue {
  const accordion = useContext(AccordionContext);

  if (!accordion) {
    throw new Error('Accordion elements must be rendered inside an <Accordion />.');
  }

  return accordion;
}
