import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import zoomInIcon from '@assets/Small_ZoomIn.svg';
import zoomOutIcon from '@assets/Small_ZoomOut.svg';
import { GMAP_MAX_ZOOM, GMAP_MIN_ZOOM } from '@config/google';

export const StyledButton = styled.button`
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${(props) => props.theme.buttonPrimaryColor};
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'auto')};
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.theme.getZIndexOf('lowLevelIndex')};
  &:hover {
    background-color: ${(props) => !props.disabled && props.theme.deadoutColor};
  }
`;

export const StyledIcon = styled.img`
  filter: ${(props) => (!props.disabled ? 'invert(1)' : props.theme.deadoutFilter)};
`;

export const ZoomControlsView = ({ zoom, handleZoomIn, handleZoomOut }) => {
  const elements = [
    {
      disabled: zoom >= GMAP_MAX_ZOOM,
      style: {
        borderBottom: '1px solid #ffffff29',
        borderRadius: '3px 3px 0 0',
        bottom: '64px',
      },
      handler: handleZoomIn,
      icon: zoomInIcon,
      alt: 'zoom in',
    },
    {
      disabled: zoom <= GMAP_MIN_ZOOM,
      style: {
        borderRadius: '0 0 3px 3px',
        bottom: '1.5rem',
      },
      handler: handleZoomOut,
      icon: zoomOutIcon,
      alt: 'zoom out',
    },
  ];
  return elements.map((e, idx) => (
    <StyledButton key={idx} disabled={e.disabled} style={e.style} onClick={e.disabled ? () => {} : e.handler}>
      <StyledIcon disabled={e.disabled} src={e.icon} alt={e.alt} />
    </StyledButton>
  ));
};

ZoomControlsView.propTypes = {
  handleZoomIn: PropTypes.func.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
  zoom: PropTypes.number,
};
