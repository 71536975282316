import { ENDPOINTS } from '@config/api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { makeUnauthorizedFetchFailureAction } from '@redux/deprecated/actions/index';

import * as types from '../actionTypes';

import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 */
export const makeAccountStartAction = () => ({
  type: types.ACCOUNT_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeAccountSuccessAction = () => ({
  type: types.ACCOUNT_SUCCESS,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeAccountFailureAction = () => ({
  type: types.ACCOUNT_FAILURE,
});

/**
 *
 * @returns {{type: string}}
 */
export const setUserSuccessAction = (user) => ({
  type: types.SET_USER_SUCCESS_ACTION,
  user,
});

/**
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 *
 * Since reducers are supposed to be “pure” (as in, they don’t change
 * anything outside their scope) we can’t do any API calls or
 * dispatch actions from inside a reducer.
 * the redux-thunk middleware knows how to handle functions
 * It passes the dispatch method as an argument to the function,
 * thus making it able to dispatch actions itself.
 * @see https://github.com/reduxjs/redux-thunk
 *
 * @param credentials
 * @returns {Function}
 */

export const makeChangeAccountRequestThunk = (user) => {
  let displaySnack = true;

  if ('event_read_on' in user) {
    displaySnack = false;
  }

  const jsonData = JSON.stringify({ user });

  return function (dispatch) {
    dispatch(makeAccountStartAction());

    fetch(ENDPOINTS.users, {
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'default',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          // stop the spinner
          dispatch(makeAccountFailureAction());
          // display a snack:
          dispatch(makeShowSnackbarAction(jsonObj.error, { status: jsonObj.status }));
        } else if ('user' in jsonObj) {
          // stop the spinner
          dispatch(makeAccountSuccessAction());
          // all is right
          const { user } = jsonObj;
          dispatch(setUserSuccessAction(user));
          // display the snack
          displaySnack && dispatch(makeShowSnackbarAction('snack_account_changed_msg'));
        }
      });
  };
};

export const makeFetchAccountRequestThunk = () => {
  return function (dispatch) {
    dispatch(makeAccountStartAction());

    fetch(ENDPOINTS.users, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'default',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          // stop the spinner
          dispatch(makeAccountFailureAction());
          // display a snack:
          dispatch(makeShowSnackbarAction(jsonObj.error, { status: jsonObj.status }));

          if (jsonObj.error === 'unauthorized') {
            dispatch(makeUnauthorizedFetchFailureAction());
          }
        } else if ('user' in jsonObj) {
          // stop the spinner
          dispatch(makeAccountSuccessAction());
          // all is right
          const { user } = jsonObj;
          dispatch(setUserSuccessAction(user));
        }
      });
  };
};
