import React, { ComponentType } from 'react';

import { AccessPin } from '@components/common/Icon/presets/AccessPin';
import { HazardPin } from '@components/common/Icon/presets/HazardPin';
import { RoadPin } from '@components/common/Icon/presets/RoadPin';
import { SecurityPin } from '@components/common/Icon/presets/SecurityPin';
import { StoragePin } from '@components/common/Icon/presets/StoragePin';
import { IconSVGProps } from '@components/common/Icon/types';

const POI_ICON_PRESET: Record<BeePointOfInterestCategory, ComponentType<IconSVGProps>> = {
  access: AccessPin,
  road: RoadPin,
  security: SecurityPin,
  storage: StoragePin,
  hazard: HazardPin,
};

export const PoiPinIcon: React.VFC<IconSVGProps & { category?: BeePointOfInterestCategory }> = ({
  category = 'access',
  ...props
}) => {
  const Icon = POI_ICON_PRESET[category];
  return <Icon {...props} />;
};
