import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import { PrimaryCTAInverse } from '@style/typeface';

export const StyledPrimaryButton = styled.button`
  ${PrimaryCTAInverse};
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${(props) => props.theme.buttonPrimaryColor};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline-style: none;
  cursor: pointer;
  &:disabled {
    background-color: ${(props) => props.theme.buttonPrimaryDisabledColor};
    color: ${(props) => props.theme.buttonPrimaryDisabledTextColor};
    cursor: default;
  }
  &:hover {
    color: ${(props) => !props.disabled && props.theme.buttonPrimaryTextColor}99;
  }
  &:focus {
    color: ${(props) => props.theme.buttonPrimaryTextColor}99;
    box-shadow: ${(props) => props.theme.buttonFocusBoxShadow};
  }
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
`;

export const PrimaryButton = ({ onClick, label, type = 'button', disabled = false, height = '3.5rem', ...others }) => {
  const copiedProps = {};
  Object.keys(others).forEach((key) => {
    // these are the props that need to get thru:
    if (key.startsWith('data-') || 'style' === key || 'className' === key) {
      copiedProps[key] = others[key];
    }
  });

  return (
    <StyledPrimaryButton
      // role="button"
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...copiedProps}
      aria-disabled="true"
      height={height}
    >
      {label}
    </StyledPrimaryButton>
  );
};

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  height: PropTypes.string,
};
