import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { NavRouterLink } from '@components/common/CTA';
import { Contract } from '@components/common/Icon/presets/Contract';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import { StyledAboutItem } from '@components/yard/YardDetailsDashboard/styles';
import { YardTypeIcon } from '@components/yard/YardTypeIcon';
import APP from '@config/constants';
import { useCropTypeFetcher, useCropTypesDictionary } from '@hooks/useCropTypes';
import { useTranslation } from '@hooks/useTranslation';

const DATEFORMAT = 'MMM DD, YYYY';

export const YardDetailsDashboardAbout: React.VFC = () => {
  const t = useTranslation();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const { isFetching: isFetchingCropTypes } = useCropTypeFetcher();
  const cropTypes = useCropTypesDictionary();

  const getFormattedDate = useCallback((date) => {
    return date ? moment(date).format(DATEFORMAT) : '—';
  }, []);

  const cropTypesLabel = useMemo(() => {
    if (isFetchingCropTypes || !yard?.cropTypesIds.length) {
      return '—';
    }

    return yard.cropTypesIds
      .map((id) => cropTypes[id]?.name)
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }, [cropTypes, isFetchingCropTypes, yard?.cropTypesIds]);

  if (!yard) {
    return null;
  }

  return (
    <Box column paddingVerticalSM paddingHorizontalMD gapSM>
      <Text typography={'Heading3'} weight={'600'}>
        {t('about')}
      </Text>

      <Box gapMD wrap>
        <Box gapMD>
          <StyledAboutItem column gapXS>
            <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
              {t('active_since')}
            </Text>
            <Text typography={'Heading3'} weight={'600'}>
              {getFormattedDate(yard.activeChangeDatetime)}
            </Text>
          </StyledAboutItem>

          <StyledAboutItem column gapXS>
            <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
              {t('creation_date')}
            </Text>
            <Text typography={'Heading3'} weight={'600'}>
              {getFormattedDate(yard.createdAt)}
            </Text>
          </StyledAboutItem>

          <StyledAboutItem column gapXS>
            <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
              {t('contract')}
            </Text>

            <Box alignItems={'center'} gapXS>
              <Contract />
              {yard.contractName ? (
                <NavRouterLink
                  to={generatePath(APP.routes.pollinationContract, { uid: String(yard.contractId) })}
                  paddingVertical={false}
                  paddingHorizontal={false}
                >
                  {yard.contractName}
                </NavRouterLink>
              ) : (
                <Text typography={'Heading3'} weight={'600'}>
                  —
                </Text>
              )}
            </Box>
          </StyledAboutItem>
        </Box>

        <Box gapMD>
          <StyledAboutItem column gapXS>
            <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
              {t('yard_type')}
            </Text>

            <Box alignItems={'center'} gapXS>
              <YardTypeIcon type={yard.yardType} />
              <Text typography={'Heading3'} weight={'600'}>
                {yard.yardType ? t(yard.yardType) : '—'}
              </Text>
            </Box>
          </StyledAboutItem>
          <StyledAboutItem column gapXS>
            <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
              {t('crop_type')}
            </Text>
            <Text typography={'Heading3'} weight={'600'}>
              {cropTypesLabel}
            </Text>
          </StyledAboutItem>
        </Box>
      </Box>

      <LoadingSkeleton visible={isFetchingCropTypes} backgroundColor={'coreWhite'} absolutelyFitParent>
        <LoadingSkeletonRectangle width={'100%'} height={'100%'} />
      </LoadingSkeleton>
    </Box>
  );
};
