import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import moment from 'moment';

import { ActionIcon } from '@components/common/ActionIcon';
import { Box } from '@components/common/Box';
import { Notes } from '@components/common/Icon/presets/Notes';
import { Text } from '@components/common/Text';
import { TooltipProps } from '@components/common/Tooltip/types';
import { StyledActionItem, StyledActionsList, StyledTooltip } from '@components/common/TooltipYardInspection/styles';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { useActions, useCategoryNameByAction } from '@hooks/useActions';

const ANALYZED_AT_DATEFORMAT = 'MMM DD, YYYY [at] HH:MM';

export interface TooltipYardInspectionProps extends Pick<TooltipProps, 'placement'> {
  inspection: BeeYardInspection;
}

export const TooltipYardInspection: React.VFC<TooltipYardInspectionProps> = ({ inspection, placement }) => {
  const { t } = useTranslation();

  inspection = useMemo(() => CaseAdapter.objectToCamelCase(inspection), [inspection]);
  const note = inspection.eventDetails.note;
  const actionsIds = inspection.eventDetails.actions;
  const alertTypeText = inspection.eventDetails.alertType.includes('hive')
    ? 'hive_inspection_by'
    : 'yard_inspection_by';
  const actions = useActions(actionsIds);
  const categoryNameByAction = useCategoryNameByAction(actionsIds);
  const datetime = moment(inspection.analyzedAt).format(ANALYZED_AT_DATEFORMAT);
  const lastInspectionWorkername = inspection.eventDetails.userName;
  const hasActionOrNote = Boolean(note || actions.length);

  return (
    <StyledTooltip placement={placement}>
      <Box column stretch>
        <Box marginXXS>
          <Text
            typography={'CaptionSmall'}
            dangerouslySetInnerHTML={{ __html: t(alertTypeText, { name: lastInspectionWorkername }) }}
          />
        </Box>
        {hasActionOrNote && (
          <StyledActionsList>
            {actions.map(({ id, detail }) => (
              <StyledActionItem key={id}>
                <ActionIcon actionId={id} size={16} />
                <Text typography={'SmallParagraph'} lineHeight="1.125rem">
                  <span>{capitalize(categoryNameByAction[id])}</span>: <span>{detail}</span>
                </Text>
              </StyledActionItem>
            ))}
            {note ? (
              <StyledActionItem>
                <Notes size={16} />
                <Text typography={'SmallParagraph'} lineHeight="1.125rem">
                  <span>{t('notes')}</span>: <span>{note}</span>
                </Text>
              </StyledActionItem>
            ) : null}
          </StyledActionsList>
        )}
        <Box marginXXS>
          <Text typography={'CaptionSmall'}>{datetime}</Text>
        </Box>
      </Box>
    </StyledTooltip>
  );
};
