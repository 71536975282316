import styled, { Color, css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { YardName } from '@components/common/YardName';

export const StyledSearch = styled.div(
  ({ theme }) => css`
    position: relative;
    min-width: 400px;
    min-height: 34px;
  `
);

export const StyledSearchWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.grey07};
    border-radius: ${theme.spacing.xxs}px;
    color: ${theme.colors.grey05};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: ${theme.getZIndexOf('headerNav')};

    input {
      flex-grow: 1;
    }
    &:focus-within ${StyledResultsDrawer} {
      display: block;
    }
  `
);

export const UnstyledInput = styled.input<{ $color?: Color; $placeholderColor?: Color }>(
  ({ theme, $color, $placeholderColor = 'grey06' }) => css`
    background-color: inherit;
    border: none;
    color: ${$color ? theme.colors[$color] : 'inherit'};

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      color: ${$placeholderColor ? theme.colors[$placeholderColor] : 'inherit'};
    }
  `
);

export const StyledClearButton = styled(Button).attrs({ unstyled: true })<{ $visible?: boolean }>(
  ({ $visible }) => css`
    visibility: ${$visible ? 'visible' : 'hidden'};
    opacity: ${$visible ? '1' : '0'}; ;
  `
);

export const StyledResultsDrawer = styled.div(
  ({ theme }) => css`
    display: none;
    width: 100%;
    position: relative;
    border-top: 1px solid ${theme.colors.grey06};
    padding: ${theme.spacing.xs}px 0 0;
  `
);
export const StyledResults = styled.div(
  ({ theme }) => css`
    max-height: 384px;
    overflow: auto;
  `
);

export const StyledResult = styled(Box).attrs({ alignItems: 'center', paddingSM: true, fullWidth: true })(
  ({ theme }) => css`
    &:focus-within,
    &:hover {
      background-color: ${theme.colors.grey08};
      border-left: 2px solid ${theme.colors.orange01};
      padding-left: ${theme.spacing.sm - 2}px;
    }
  `
);

export const StyledYardButton = styled(Button).attrs({ unstyled: true })(
  ({}) => `
      display: flex;
      align-items: center;
    `
);

export const StyledYardName = styled(YardName)`
  width: 160px;
  text-align: left;
  margin-right: 8px;
  white-space: nowrap;
`;

export const ActiveStatus = styled.span<{ $active: boolean }>(
  ({ $active, theme }) => css`
    margin-right: ${theme.spacing.xs}px;
    margin-left: ${theme.spacing.sm}px;
    min-height: 6px;
    min-width: 6px;
    background-color: ${$active ? theme.colors.green02 : theme.colors.yellow02};
    border-radius: 50%;
    display: inline-block;
  `
);

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const StyledNbHives = styled.span`
  padding: 1px 8px 0 0;
  font-size: 12px;
  white-space: nowrap;
`;

export const StyledViewOnMapButton = styled(Button).attrs({ unstyled: true })(
  ({ theme }) => css`
    display: flex;
    white-space: nowrap;
    margin-left: ${theme.spacing.xs}px;
  `
);

export const StyledResultsFooter = styled.div(
  ({ theme }) => css`
    border-top: 1px solid ${theme.colors.grey06};
    padding: ${theme.spacing.sm}px;

    strong {
      font-weight: 700;
    }
  `
);
