import React, { useLayoutEffect, useRef } from 'react';

import {
  StyledLoadingSkeleton,
  StyledLoadingSkeletonCircle,
  StyledLoadingSkeletonRectangle,
} from '@components/common/LoadingSkeleton/styles';
import { LoadingSkeletonProps } from '@components/common/LoadingSkeleton/types';

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ children, ...props }) => {
  const skeletonDivRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const parentElement = skeletonDivRef.current;
    if (parentElement) {
      const parentRect = parentElement.getBoundingClientRect();
      const childrenClasses = [StyledLoadingSkeletonRectangle, StyledLoadingSkeletonCircle].map(String);
      const children = Array.from(parentElement.querySelectorAll<HTMLDivElement>(childrenClasses.join(', ')));
      children.forEach((child) => {
        const childRect = child.getBoundingClientRect();
        const diagonalIndex = childRect.x - parentRect.x + (childRect.y - parentRect.y);
        const animationDelay = diagonalIndex / 2.0;
        child.style.animationDelay = `${animationDelay}ms`;
      });
    }
  }, []);

  return (
    <StyledLoadingSkeleton ref={skeletonDivRef} {...props}>
      {children}
    </StyledLoadingSkeleton>
  );
};
