import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@components/common/Box';
import { RenderWithPermission } from '@components/common/RenderWithPermission';
import { Tab, Tabs } from '@components/common/Tabs';
import BeekPracticesModal from '@components/deprecated/DynamicModals/BeekPracticesModal';
import NavBar from '@components/deprecated/NavBarElements/NavBar';
import APP from '@config/constants';
import { makeNavBarTitleChangeAction } from '@redux/deprecated/actions';
import { TabPractices } from '@scenes/admin/Operation/TabPractices';
import { TabProfile } from '@scenes/admin/Operation/TabProfile';

import { TabWorkers } from './TabWorkers';

export const Operation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO:
    //  This is a really weird way to set the
    //  current navbar title. We should find another
    //  way to handle it without using a global state.
    dispatch(
      makeNavBarTitleChangeAction({
        backBtn: true,
        label: t('operation'),
      })
    );
  }, [dispatch, t]);

  return (
    <Box column stretch>
      <Tabs isFixed>
        <RenderWithPermission require={APP.permissions.hht}>
          <Tab id={'practices'} title={t('practices')}>
            <TabPractices />
          </Tab>
          <Tab id={'workers'} title={t('workers')}>
            <TabWorkers />
          </Tab>
          <Tab id={'profile'} title={t('operation_profile_tab_title')}>
            <TabProfile />
          </Tab>
        </RenderWithPermission>
      </Tabs>
    </Box>
  );
};
