import React from 'react';

import { Holding } from '@components/common/Icon/presets/Holding';
import { Home } from '@components/common/Icon/presets/Home';
import { Honey } from '@components/common/Icon/presets/Honey';
import { Mating } from '@components/common/Icon/presets/Mating';
import { Outdoor } from '@components/common/Icon/presets/Outdoor';
import { Pollination } from '@components/common/Icon/presets/Pollination';
import { IconSVGProps } from '@components/common/Icon/types';

const PRESETS: Record<YardType, React.ComponentType<IconSVGProps>> = {
  pollination: Pollination,
  honey: Honey,
  holding: Holding,
  'indoor wintering': Home,
  'outdoor wintering': Outdoor,
  mating: Mating,
};

export const YardTypeIcon: React.VFC<IconSVGProps & { type: YardType }> = ({ type, ...props }) => {
  const Comp = PRESETS[type];
  if (!Comp) {
    return null;
  }

  return <Comp {...props} />;
};
