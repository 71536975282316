import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useNavTabs } from '@hooks/useNavTabs';
import { from } from '@style/mediaQueries';

const StyledLinkContainer = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
  margin-right: 2rem;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${({ theme }) => theme.colors.coreFocus02};
    outline: none;
  }
`;

const StyledLinkContent = styled.p`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.coreWhite};
  font-weight: 700;
  letter-spacing: 1px;
  line-height: ${(p) => p.theme.navigation.headerHeightMobile}px;

  ${from('tablet')} {
    line-height: ${(p) => p.theme.navigation.headerHeight}px;
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const NavBarTabs: React.FC = () => {
  const tabs = useNavTabs();
  const theme = useTheme();
  return (
    <>
      {tabs.map((tab) => (
        <StyledLinkContainer tabIndex={1} key={tab.route}>
          <StyledLink
            activeStyle={{ boxShadow: `inset 0 -4px ${theme.colors.orange02}` }}
            role="navigation"
            to={tab.route}
          >
            <StyledLinkContent>{tab.label}</StyledLinkContent>
          </StyledLink>
        </StyledLinkContainer>
      ))}
    </>
  );
};
