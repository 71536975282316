import React, { useMemo } from 'react';
import { capitalize } from 'lodash';

import { ActionIcon } from '@components/common/ActionIcon';
import { Box } from '@components/common/Box';
import { HiveAdded } from '@components/common/Icon/presets/HiveAdded';
import { HiveDefault } from '@components/common/Icon/presets/HiveDefault';
import { HiveMoved } from '@components/common/Icon/presets/HiveMoved';
import { NotesNavigator } from '@components/common/NotesNavigator';
import { Text } from '@components/common/Text';
import { HiveActivityCardExpandableTags } from '@components/hive/HiveActivityCard/HiveActivityCardExpandableTags';
import { StyledInspectionCard, StyledInspectionCardItem } from '@components/hive/HiveActivityCard/styles';
import { HiveActivityCardProps } from '@components/hive/HiveActivityCard/types';
import { useActionsNames, useCategoryNameByAction } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';

export const HiveActivityCard: React.VFC<HiveActivityCardProps> = ({ activity, highlightedHiveId, ...props }) => {
  const t = useTranslation();

  const actionsNames = useActionsNames();
  const categoriesNamesByAction = useCategoryNameByAction(activity.actions);

  const actions = useMemo(() => {
    return [...activity.actions].filter(Boolean).sort((a, b) => {
      const comp = (categoriesNamesByAction[a] ?? '').localeCompare(categoriesNamesByAction[b] ?? '');
      if (comp !== 0) {
        return comp;
      }
      return (actionsNames[a] ?? '').localeCompare(actionsNames[b] ?? '');
    });
  }, [actionsNames, activity.actions, categoriesNamesByAction]);

  const hiveGroups = useMemo(() => {
    const hives = activity.hiveIdentities;
    if (activity.alertType === 'new_inspection') {
      return [{ title: t('hives_included'), icon: HiveDefault, hives: hives }];
    } else if (activity.alertType === 'hive_managed_to_yard') {
      return [{ title: t('reassigned_hives'), icon: HiveMoved, hives: hives }];
    }
    return [
      { title: t('moved_hives'), icon: HiveMoved, hives: hives.filter(({ isNew }) => !isNew) },
      { title: t('new_hives'), icon: HiveAdded, hives: hives.filter(({ isNew }) => isNew) },
    ].filter(({ hives }) => hives.length);
  }, [activity.alertType, activity.hiveIdentities, t]);

  return (
    <StyledInspectionCard column stretch {...props}>
      {hiveGroups.map(({ title, hives, icon: Icon }, index) => (
        <StyledInspectionCardItem key={index} paddingXS column stretch>
          <Box gapXS alignItems={'center'}>
            <Icon size={16} />
            <Text typography={'Heading3'} weight={'600'}>
              {title}
            </Text>
          </Box>
          <Text typography={'SmallParagraph'}>
            <HiveActivityCardExpandableTags hives={hives} highlightedHiveId={highlightedHiveId} />
          </Text>
        </StyledInspectionCardItem>
      ))}

      {actions.map((actionId, index) => (
        <StyledInspectionCardItem key={index} paddingXS gapXS alignItems={'flex-start'}>
          <Box fit alignItems={'center'} gapXS>
            <ActionIcon actionId={actionId} size={16} />
            <Text typography={'Heading3'} weight={'600'}>
              {capitalize(categoriesNamesByAction[actionId])}
            </Text>
          </Box>
          <Text typography={'Heading3'} align={'right'}>
            {capitalize(actionsNames[actionId])}
          </Text>
        </StyledInspectionCardItem>
      ))}

      <NotesNavigator notes={activity.notes ? [{ text: activity.notes }] : []} />
    </StyledInspectionCard>
  );
};
