import produce from 'immer';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isFetching: false,
  pending: [],
  fetched: {},
  fetchedData: [],
};

export const fetchReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.API_REQUEST_START:
        st.isFetching = true;
        st.pending = [...st.pending, action.url];
        break;
      case actionTypes.API_REQUEST_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const newFetchedList = { ...st.fetched };
        newFetchedList[action.url] = {
          fetchedAt: new Date().getTime(),
          data: action.fetchedData,
        };
        st.isFetching = false;
        st.fetched = newFetchedList;
        st.fetchedData = newFetchedList[action.url].data;
        st[action.injectedPropName] = action.fetchedData;
        st.pending = [...st.pending.filter((item) => item !== action.url)];
        break;
      case actionTypes.API_REQUEST_FAILURE:
        st.isFetching = false;
        // allow a new request later on:
        st.pending = [...st.pending.filter((item) => item !== action.url)];
        break;
      default:
    }
  });
};
