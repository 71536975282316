import React, { useCallback } from 'react';

import { MarkerPin } from '@components/common/Map/components/MarkerPin';
import { MarkerPinProps } from '@components/common/Map/types';
import { Text } from '@components/common/Text';
import { YardName } from '@components/common/YardName';

import { StyledHivesRatioWrapper } from './styles';

export interface YardMarkerPinProps extends MarkerPinProps {
  yard: { name: string; nbHives: number; targetHiveNumber?: number | null; contractName?: string };
  active?: boolean;
  disabled?: boolean;
  disableShadow?: boolean;
  disableTruncation?: boolean;
}

export const YardMarkerPin: React.FC<YardMarkerPinProps> = ({ yard, children, disableTruncation, ...props }) => {
  const generateHivesRatio = useCallback((yard: YardMarkerPinProps['yard']) => {
    if (yard.targetHiveNumber) {
      return `${yard.nbHives}/${yard.targetHiveNumber}`;
    }
    return String(yard.nbHives);
  }, []);

  return (
    <MarkerPin {...props}>
      <YardName
        name={yard.name}
        contractName={yard.contractName}
        typography={'CaptionSmall'}
        weight={'600'}
        maxWidth={128}
        disableTruncation={disableTruncation}
      />

      <StyledHivesRatioWrapper>
        <Text typography={'CaptionSmall'} weight={'600'} data-pdf-unselectable>
          {generateHivesRatio(yard)}
        </Text>
      </StyledHivesRatioWrapper>

      {children}
    </MarkerPin>
  );
};
