import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { YardRow } from '@components/yard/YardsList/types';
import { useAppliedYardsFilters } from '@redux/YardsFilters/hooks';

import { useGridApi } from '../hooks/useGridApi';

import { YardsListSelectionContext, YardsListSelectionContextValue } from '.';

type YardSelectionMap = Record<number, YardRow>;

export const YardsListSelectionProvider: React.FC = ({ children }) => {
  const { gridApi } = useGridApi();
  const appliedFilters = useAppliedYardsFilters();
  const isFetchingGroups = useSelector((state) => state.groupsReducer.isFetching);
  const isFetchingDeleteYard = useSelector((state) => state.beeTrackYardListReducer.isFetchingDeleteYard);
  const [currentPage, setCurrentPage] = useState(gridApi?.paginationGetCurrentPage() ?? 0);
  const [hasClickedSelectAllAcrossPages, setHasClickedSelectAllAcrossPages] = useState(false);

  /**
   * Returns a nested map of the selected yards, first level is by page, second by ID
   *
   * Needs to be a map and not an array bc you could have page 4 selected and not page 5
   */
  const [selectedYardsbyPage, setSelectedYardsbyPage] = useState<Record<number, YardSelectionMap>>({});

  /**
   * Returns a single level map of the selected yards, by id
   *
   * Used by components outside of this file
   */
  const selectedYardsList = useMemo(() => {
    return Object.values(selectedYardsbyPage).reduce(
      (selectedYardsMap: Record<number, YardRow>, currentPage) => ({ ...selectedYardsMap, ...currentPage }),
      {}
    );
  }, [selectedYardsbyPage]);

  /**
   * Returns the total number of yards in the selected state map
   */
  const selectedYardsLength = useMemo(() => Object.keys(selectedYardsList).length, [selectedYardsList]);

  /**
   * Returns true if the user has actually checked each checkbox of each row, or the entire page mastercheckbox
   * for all (filtered) yards
   *
   * Necessary for instances when there are only 9 yards, and the user wants to assign them all to a contract.
   * Also, if the user actually selects 400 yards, they should be able to mass assign them to a contract :shrug:
   */
  const allYardsAcrossPagesAreSelectedManually = useMemo(() => {
    if (!selectedYardsLength) return false;
    return gridApi?.paginationGetRowCount() === selectedYardsLength;
  }, [gridApi, selectedYardsLength]);

  /**
   * Returns selectedYardsLength OR just the total number of yards, if
   * user has clicked 'selected all across pages', in which case, the yards
   * aren't actually added into selectedYardsbyPage
   */
  const selectionCount = useMemo(() => {
    if (hasClickedSelectAllAcrossPages && gridApi) {
      return gridApi.paginationGetRowCount();
    }

    return selectedYardsLength;
  }, [hasClickedSelectAllAcrossPages, gridApi, selectedYardsLength]);

  /**
   * Returns a true if all yards are selected on the page the user is on
   *
   * Useful for when the user selects all on page 4, navigates to page 5, and navigates back to page 4
   * and expects to see the master page select checkbox checked
   */
  const hasSelectedAllOnCurrentPage = useMemo(() => {
    if (selectedYardsbyPage[currentPage]) {
      return Object.keys(selectedYardsbyPage[currentPage]).length === gridApi?.getRenderedNodes().length;
    } else return false;
  }, [selectedYardsbyPage, currentPage, gridApi]);

  /**
   * Returns true if all yards are selected on any page
   * Basically it only exists to show/hide the yellow banner with
   * the 'select all' and 'deselect all' options
   */
  const hasSelectedAllOnPage = useMemo(() => {
    if (!gridApi) return false;
    if (hasClickedSelectAllAcrossPages || allYardsAcrossPagesAreSelectedManually) return true;

    const numItemsPerPage = gridApi.paginationGetPageSize();
    const atLeastOnePageFullySelected = Object.values(selectedYardsbyPage).some(
      (page) => Object.keys(page).length === numItemsPerPage
    );
    if (atLeastOnePageFullySelected) return true;

    // this is because usually the length of the last page is less than the other pages,
    // so we have to get the length to know if it's been fully selected
    const totalPages = gridApi?.paginationGetTotalPages();
    const lastPage = totalPages - 1;

    if (!selectedYardsbyPage[lastPage]) return false;

    const totalItems = gridApi?.paginationGetRowCount();
    const totalItemsOnLastPage = totalItems % numItemsPerPage;

    const isLastPageFullySelected = Object.keys(selectedYardsbyPage[lastPage]).length === totalItemsOnLastPage;
    return isLastPageFullySelected;
  }, [allYardsAcrossPagesAreSelectedManually, gridApi, hasClickedSelectAllAcrossPages, selectedYardsbyPage]);

  /**
   * Returns a list of all the yards currently on the page
   *
   * Basically just a shorthand variable with some typing
   */
  const currentlyVisibleRows = useCallback(() => {
    if (!gridApi) return [];
    const nodes = gridApi.getRenderedNodes();
    return nodes.map((node) => node.data as YardRow);
  }, [gridApi]);

  /**
   * Adds a yard to the selectedYardsbyPage state map
   *
   * The exposed function used outside of this file
   */
  const selectYard = useCallback(
    (yardToSelect: YardRow) => {
      setSelectedYardsbyPage((pages) => ({
        ...pages,
        [currentPage]: { ...pages[currentPage], [yardToSelect.meta.id]: yardToSelect },
      }));
    },
    [currentPage]
  );

  /**
   * Removes a yard from the selectedYardsbyPage state map
   *
   * The exposed function used outside of this file
   */

  const deselectYard = useCallback(
    (yardToDeselect: YardRow) => {
      setSelectedYardsbyPage((pages) => {
        if (Object.keys(pages[currentPage]).length === 1 && yardToDeselect.meta.id in pages[currentPage]) {
          const pagesCopy = { ...pages };
          delete pagesCopy[currentPage];
          return pagesCopy;
        }
        const yards = { ...pages[currentPage] };
        delete yards[yardToDeselect.meta.id];
        return {
          ...pages,
          [currentPage]: yards,
        };
      });
      // probably delete this / move this elsewhere
      setHasClickedSelectAllAcrossPages(false);
    },
    [currentPage]
  );

  /**
   * Adds all yards on the page to the selectedYardsbyPage state map
   *
   * The exposed function used outside of this file
   */

  const selectAllOnPage: (page?: number) => void = useCallback(
    (page) => {
      const obj: YardSelectionMap = {};
      const newPage = page ?? currentPage;
      currentlyVisibleRows().forEach((row) => (obj[row?.meta?.id] = row));

      setSelectedYardsbyPage((pages) => {
        return {
          ...pages,
          [newPage]: obj,
        };
      });
    },
    [currentPage, currentlyVisibleRows]
  );

  /**
   * Removes the current page from selectedYardsbyPage
   *
   * The exposed function used outside of this file
   */

  const deselectAllOnPage = useCallback(() => {
    if (!gridApi) return;
    setSelectedYardsbyPage((pages) => {
      const copiedPages = { ...pages };
      delete copiedPages[currentPage];
      return copiedPages;
    });

    // probably delete this / move this elsewhere
    setHasClickedSelectAllAcrossPages(false);
  }, [currentPage, gridApi]);

  const selectAllAcrossPages = useCallback(() => {
    if (!gridApi) return;
    setHasClickedSelectAllAcrossPages(true);
  }, [gridApi]);

  const deselectAllAcrossPages = useCallback(() => {
    if (!selectedYardsLength) return;
    setSelectedYardsbyPage({});
    setHasClickedSelectAllAcrossPages(false);
  }, [selectedYardsLength]);

  /**
   * This function checks to see if the current page is missing from selectedYardsbyPage
   * and if missing, adds that page
   */
  const maybeAddSelectedYardsToStateMap = useCallback(
    (newPage: number) => {
      if (!gridApi) return;
      if (
        hasClickedSelectAllAcrossPages &&
        (!selectedYardsbyPage[newPage] ||
          Object.keys(selectedYardsbyPage[newPage]).length < gridApi.paginationGetPageSize())
      ) {
        selectAllOnPage(newPage);
      }
    },
    [gridApi, hasClickedSelectAllAcrossPages, selectAllOnPage, selectedYardsbyPage]
  );
  /**
   * If the user has clicked 'selectAllAcrossPages'--which doesn't actually
   * add all yards to selectedYardsbyPage--and navigates between pages,
   * they expect to see all the yards on the page selected, so
   * this function checks to see if the current page is missing from selectedYardsbyPage
   * and then adds that page
   */
  useEffect(() => {
    if (!gridApi) return;

    const onPageChange = () => {
      const newPage = gridApi.paginationGetCurrentPage();
      setCurrentPage(newPage);

      maybeAddSelectedYardsToStateMap(newPage);
    };
    gridApi.addEventListener('paginationChanged', onPageChange);

    return () => gridApi.removeEventListener('paginationChanged', onPageChange);
  }, [gridApi, maybeAddSelectedYardsToStateMap]);

  useEffect(() => {
    maybeAddSelectedYardsToStateMap(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClickedSelectAllAcrossPages]);

  /**
   * Deselect yards when:
   * 1. filters change
   * 2. groups change
   * 3. a yard is deleted -- even if the deleted yard wasn't previously selected
   */
  useEffect(() => {
    deselectAllAcrossPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters, isFetchingGroups, isFetchingDeleteYard]);

  const context: YardsListSelectionContextValue = {
    selectYard,
    deselectYard,
    selectedYards: selectedYardsList,
    selectionCount,
    hasSelectedAllOnPage,
    hasSelectedAllOnCurrentPage,
    allYardsAcrossPagesAreSelectedManually,
    hasClickedSelectAllAcrossPages,
    selectAllAcrossPages,
    deselectAllAcrossPages,
    deselectAllOnPage,
    selectAllOnPage,
  };
  return <YardsListSelectionContext.Provider value={context}>{children}</YardsListSelectionContext.Provider>;
};
