import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Filter = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M4.21 5.61C6.23 8.2 10 13 10 13V18C10.0033 19.1032 10.8968 19.9967 12 20V20C13.1032 19.9967 13.9967 19.1032 14 18V13C14 13 17.77 8.2 19.79 5.61C20.0222 5.30841 20.063 4.90117 19.8954 4.55949C19.7277 4.2178 19.3806 4.00091 19 4H5C4.6194 4.00091 4.27231 4.2178 4.10465 4.55949C3.937 4.90117 3.97783 5.30841 4.21 5.61Z"
      />
    </IconSVGView>
  );
};
