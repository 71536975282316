import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeFetchActionRequestThunk, makeFetchTranslationsRequestThunk } from '@redux/deprecated/actions';

export function useActionsNames(): Record<number, string> {
  const actions = useActions();
  return useMemo(() => {
    const names = {} as Record<number, string>;
    actions.forEach((a) => (names[a.id] = a.detail));
    return names;
  }, [actions]);
}

export function useAction(actionID: number | null): BeeAction | null {
  const categories = useActionCategories();
  return useMemo(() => {
    for (const category of categories) {
      for (const act of category.actions) {
        if (act.id === actionID) {
          return act;
        }
      }
    }
    return null;
  }, [actionID, categories]);
}

export function useActions(actionsIds?: number[]): BeeAction[];
export function useActions(categoryID?: number): BeeAction[];
export function useActions(actionIdsOrCategoryID?: number | number[]): Array<BeeAction> {
  const categories = useActionCategories();
  return useMemo(() => {
    if (typeof actionIdsOrCategoryID === 'number') {
      const categoryID = actionIdsOrCategoryID;
      const category = categories.find((category) => category.id === categoryID);
      return category?.actions ?? [];
    } else if (typeof actionIdsOrCategoryID === 'object') {
      const actionsIds = actionIdsOrCategoryID;
      return categories.reduce(
        (acc, category) => [...acc, ...category.actions.filter(({ id }) => actionsIds.includes(id))],
        [] as BeeAction[]
      );
    }
    return categories.reduce((acc, category) => [...acc, ...category.actions], [] as BeeAction[]);
  }, [actionIdsOrCategoryID, categories]);
}

export function useActionCategory(categoryID: number): BeeActionCategory | null {
  const categories = useActionCategories();
  return useMemo(() => {
    return categories.find((category) => category.id === categoryID) ?? null;
  }, [categories, categoryID]);
}

export function useActionCategories(): Array<BeeActionCategory> {
  return useSelector<any, Array<BeeActionCategory>>((state) => {
    const categories = state.actionReducer.actionList || [];
    return categories.map(
      (cat: any) =>
        ({
          id: cat.category_id,
          name: cat.category_name,
          icon: cat.category_icon,
          flag: cat.category_flag,
          readOnly: cat.read_only,
          availableInHiveReports: cat.available_in_hive_reports,
          availableInYardReports: cat.available_in_yard_reports,
          isMutuallyExclusive: cat.is_mutually_exclusive,
          actions: cat.actions.map(
            (act: any) =>
              ({
                id: act.id,
                detail: act.detail,
                flag: act.flag,
                isDefault: act.is_default,
                isActive: act.is_active,
                isDeleted: act.is_deleted,
              } as BeeAction)
          ),
        } as BeeActionCategory)
    );
  });
}

export function useCategoryNameByAction(actionsIds?: number[]) {
  const categories = useActionCategories();
  return useMemo(() => {
    const names = {} as Record<number, string>;

    categories.forEach(({ name, actions }) => {
      actions.forEach(({ id }) => {
        if (!actionsIds || actionsIds.includes(id)) {
          names[id] = name;
        }
      });
    });

    return names;
  }, [actionsIds, categories]);
}

export function useActionCategoriesFetcher(): { isFetching: boolean } {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeFetchActionRequestThunk());
    dispatch(makeFetchTranslationsRequestThunk());
  }, [dispatch]);

  const isFetching = useSelector<any, boolean>((state) => {
    return state.actionReducer.isFetching;
  });

  return { isFetching };
}
