import produce from 'immer';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isFetching: false,
  loggedIn: false,
};

export const loginReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.LOGIN_START:
        st.isFetching = true;
        break;
      case actionTypes.LOGIN_SUCCESS:
        st.isFetching = false;
        st.loggedIn = true;
        break;
      case actionTypes.LOGIN_FAILURE:
        st.isFetching = false;
        st.loggedIn = false;
        break;
      case actionTypes.LOGOUT_START:
        st.isFetching = true;
        break;
      case actionTypes.LOGOUT_SUCCESS:
        localStorage.clear();
        sessionStorage.clear();
        st.isFetching = false;
        st.loggedIn = false;
        break;
      case actionTypes.LOGOUT_FAILURE:
        st.isFetching = false;
        st.loggedIn = true;
        break;
      default:
    }
  });
};
