import styled from 'styled-components';

import { CaptionSmall, Heading1, Heading2, Heading3, Paragraph, ParagraphSmall } from '@style/typeface';

export const StyledHeading1 = styled.h1`
  ${Heading1}
`;

export const StyledHeading2 = styled.h2`
  ${Heading2}
`;

export const StyledHeading3 = styled.h3`
  ${Heading3}
`;

export const StyledParagraph = styled.p`
  ${Paragraph}
`;

export const StyledParagraphSmall = styled.p`
  ${ParagraphSmall}
`;

export const StyledCaptionSmall = styled.span`
  ${CaptionSmall}
`;
