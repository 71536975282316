export type BreakPoint = 'tiny' | 'mobile' | 'tablet' | 'laptop' | 'desktop';
type BreakPointNumberMap = Record<BreakPoint, number>;

export const BREAKPOINTS: BreakPointNumberMap = {
  tiny: 360,
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
};
