import styled from 'styled-components';

import { from, until } from '@style/mediaQueries';

export const StyledMapToolbarIconButton = styled.button<{ active?: boolean; disabled?: boolean }>(
  ({ theme, active }) => `
  width: 32px;
  height: 32px;
  border-radius: 4px; /* Design is not following the theme. */
  margin: 0 ${theme.spacing.sm}px;
 
  background-color: ${active ? theme.colors.grey03 : theme.colors.coreWhite};
  
  &:hover {
    background-color: ${theme.colors.grey03};
    path {
      fill: ${theme.colors.grey06};
    }
  }
  
  &[disabled] {
    background-color: transparent;
    path {
      fill: ${theme.colors.grey05};
    }
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02}; 
  }
`
);

export const StyledMapToolbarButton = styled.button(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 ${theme.spacing.sm}px; 
  text-transform: uppercase;
  background-color: ${theme.colors.grey08};
  color: ${theme.colors.coreWhite};
  border-radius: ${theme.shape.paperRadius01}px;
  
  &:hover {
    color: ${theme.colors.grey05};
  
    path {
      fill: ${theme.colors.grey05};
    }
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02}; 
  }
  
  &[disabled] {
    background-color: ${theme.colors.grey05};
    color: ${theme.colors.grey06};
  }
`
);

export const StyledMapToolbarButtonDone = styled(StyledMapToolbarButton)(
  () => `
  width: 98px;
  margin-right: 2.5rem; /** Aligned with the NavBar button. */
`
);

export const StyledMapToolbarButtonCancel = styled(StyledMapToolbarButton)(
  ({ theme }) => `
  width: 98px;
  margin-right: ${theme.spacing.sm}px;
  
  background-color: ${theme.colors.coreWhite};
  color: ${theme.colors.grey08};
  
  &:hover {
    color: ${theme.colors.grey06};
  }
  
  &[disabled] {
    background-color: ${theme.colors.coreWhite};
    color: ${theme.colors.grey06};
  }
`
);

export const StyledMapToolbarIconButtonGroup = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  
  height: 100%;
  padding: 0 ${theme.spacing.xs}px;
  
  border-right: 1px solid ${theme.colors.grey04};
  &:first-child {
    border-left: 1px solid ${theme.colors.grey04};
  }
`
);

export const StyledMapToolbarContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  
  height: 100%;
  
  ${until('desktop')} {
    &:nth-child(2) {
      flex: none;
    }
  }
  
  ${from('desktop')} {
    &:first-child {
      border-right: 1px solid ${theme.colors.grey04};
    }
  }
  
  &:last-child {
    justify-content: flex-end;
  }
`
);
