import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@components/common/Box';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { Tab, Tabs } from '@components/common/Tabs';
import ChangePasswordModal from '@components/deprecated/DynamicModals/ChangePasswordModal';
import APP from '@config/constants';
import { makeNavBarTitleChangeAction } from '@redux/deprecated/actions';

import { TabNotifications } from './TabNotifications';
import { TabUserProfile } from './TabUserProfile';

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO:
    //  This is a really weird way to set the
    //  current navbar title. We should find another
    //  way to handle it without using a global state.
    dispatch(
      makeNavBarTitleChangeAction({
        backBtn: true,
        label: t('settings'),
      })
    );
  }, [dispatch, t]);

  return (
    <Box column stretch flexGrow={1}>
      <PageHeader>
        <PageHeaderTitle>{t('settings')}</PageHeaderTitle>
      </PageHeader>
      <Tabs useURLPath>
        <Tab id={'user-profile'} title={t('user_profile')} path={APP.routes.settingsUserProfile}>
          <TabUserProfile />
        </Tab>
        <Tab id={'notifications'} title={t('notifications')} path={APP.routes.settingsNotifications}>
          <TabNotifications />
        </Tab>
      </Tabs>
      <ChangePasswordModal />
    </Box>
  );
};
