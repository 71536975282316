import { useContext } from 'react';

import { useQueryParamsModalOpener } from '@components/common/Modal/hooks';
import { HiveModalInnerContext } from '@components/hive/HiveModal/context';

export function useHiveModalOpener() {
  const { openedModals, openModal, closeModal } = useQueryParamsModalOpener({
    paramName: 'hive',
    paramValueParser: parseInt,
  });

  return { openedHiveModals: openedModals, openHiveModal: openModal, closeHiveModal: closeModal };
}

export function useHiveModalInnerContext() {
  const context = useContext(HiveModalInnerContext);

  if (!context) {
    throw new Error("Can't find a valid hive modal context.");
  }

  return context;
}
