import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';

import * as types from '../actionTypes';

import { makeUnauthorizedFetchFailureAction } from './index';
import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeWorkerRequestStartAction = () => ({
  type: types.WORKER_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeFetchWorkerRequestSuccessAction = (workerList) => ({
  type: types.FETCH_WORKER_SUCCESS,
  workerList,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{worker: object}}
 *
 */
export const makeAddWorkerRequestSuccessAction = (worker) => ({
  type: types.ADD_WORKER_SUCCESS,
  worker,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{worker: object}}
 *
 */
export const makeUpdateWorkerRequestSuccessAction = (worker) => ({
  type: types.UPDATE_WORKER_SUCCESS,
  worker,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{id: string}}
 *
 */
export const makeDeactivateWorkerRequestSuccessAction = (id) => ({
  type: types.DEACTIVATE_WORKER_SUCCESS,
  id,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeWorkerRequestFailureAction = () => ({
  type: types.WORKER_FAILURE,
});

/**
 *
 * @param error
 * @returns {{type: string, order_by: string, order_direction: string}}
 */
export const handleSortWorkerList = (order_by, order_direction) => ({
  type: types.SORT_WORKER_LIST,
  order_by,
  order_direction,
});

/**
 *
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * @param workerName
 * @param workerPhone
 * @returns {Function}
 */

export const makeFetchWorkerRequestThunk = () => {
  return (dispatch) => {
    dispatch(makeWorkerRequestStartAction());

    fetch(ENDPOINTS.workerList, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeWorkerRequestFailureAction());
            dispatch(makeShowSnackbarAction('snack_default_msg'));
            return;
          }
        } else if ('results' in jsonObj) {
          // all is right
          dispatch(makeFetchWorkerRequestSuccessAction(jsonObj.results));
        }
      });
  };
};

export const makeAddWorkerRequestThunk = (first_name, last_name, phone_number) => {
  return async function (dispatch) {
    dispatch(makeWorkerRequestStartAction());

    const url = ENDPOINTS.workerList;
    const data = {
      first_name,
      last_name,
      phone_number,
    };

    const response = await Api.post(url, data);

    if (response.error) {
      if (response.error.detail?.error?.includes('WORKER_ALREADY_ACTIVE')) {
        dispatch(makeShowSnackbarAction('worker_already_active'));
      } else if (response.error.detail?.error?.includes('PHONE_NUMBER_ALREADY_TAKEN')) {
        dispatch(makeShowSnackbarAction('worker_duplicated'));
      } else if (response.error.detail?.phoneNumber) {
        dispatch(makeShowSnackbarAction('worker_phone_number_error'));
      } else if (response.status === 400) {
        dispatch(makeShowSnackbarAction('worker_generic_error'));
      } else {
        dispatch(makeShowSnackbarAction(response.error.translation));
      }

      return false;
    }

    const message = response.status === 201 ? 'worker_added' : 'worker_reactivated';
    dispatch(makeShowSnackbarAction(message, { icon: true, status: '' }));
    dispatch(makeAddWorkerRequestSuccessAction(await response.json()));

    return true;
  };
};

export const makeUpdateWorkerRequestThunk = (id, first_name, last_name, phone_number) => {
  return async function (dispatch) {
    dispatch(makeWorkerRequestStartAction());

    const url = URLUtil.buildURL(ENDPOINTS.workerDetail, { id });
    const data = {
      first_name,
      last_name,
      phone_number,
    };

    const response = await Api.patch(url, data);

    if (response.error) {
      if (response.error.detail?.error?.includes('WORKER_ALREADY_ACTIVE')) {
        dispatch(makeShowSnackbarAction('worker_already_active'));
      } else if (response.error.detail?.error?.includes('PHONE_NUMBER_ALREADY_TAKEN')) {
        dispatch(makeShowSnackbarAction('worker_duplicated'));
      } else if (response.error.detail?.phoneNumber) {
        dispatch(makeShowSnackbarAction('worker_phone_number_error'));
      } else if (response.status === 400) {
        dispatch(makeShowSnackbarAction('worker_generic_error'));
      } else {
        dispatch(makeShowSnackbarAction(response.error.translation));
      }

      return false;
    }

    dispatch(makeShowSnackbarAction('worker_updated', { icon: true, status: '' }));
    dispatch(makeUpdateWorkerRequestSuccessAction(await response.json()));

    return true;
  };
};

export const makeDeactivateWorkerRequestThunk = (id) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeWorkerRequestStartAction());

    fetch(`${ENDPOINTS.workerList}${id}/remove/`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeWorkerRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_default_msg'));
          return;
        } else {
          dispatch(makeDeactivateWorkerRequestSuccessAction(id));
          dispatch(makeShowSnackbarAction('worker_deactivated', { icon: true }));
        }
      });
  };
};

export const makeResendInviteWorkerRequestThunk = (id) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeWorkerRequestStartAction());

    fetch(`${ENDPOINTS.workerList}${id}/reinvite/`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeWorkerRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_default_msg'));
          return;
        } else {
          dispatch(makeShowSnackbarAction('worker_sms_resent', { icon: true }));
        }
      });
  };
};
