import styled from 'styled-components';

import { PRESETS } from '@components/common/Text/styles';

export const DropdownWrapper = styled.div`
  width: 269px;
  height: 40px;
  display: flex;
  position: relative;
`;

export const DropdownSelect = styled.select(
  ({ theme }) => `
        -moz-appearance: none;
        appearance: none;

        border: 1px solid ${theme.colors.grey03};
        border-radius: ${theme.shape.paperRadius01}px;

        padding: 0 32px 0 16px;
        width: 100%;
        height: 40px;
                
        background: ${theme.colors.coreWhite};    
        
        color: ${theme.colors.grey08};
        font-family: ${theme.font.family};
        font-style: normal;
        font-weight: 400;
        font-size: ${PRESETS.Heading3.fontSize};
        line-height: ${PRESETS.Heading3.lineHeight};

        &:focus-visible {
          box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02}; 
          border-color: transparent;
          outline: none;
        }
    `
);

export const DropdownArrowSpan = styled.span(
  ({ theme }) => `
        z-index: ${theme.getZIndexOf('highLevelIndex')};
        pointer-events: none;
        position: absolute;
        right: ${theme.spacing.sm}px;
        height: 100%;
        display: flex;
        align-items: center;
  `
);
