import produce from 'immer';

import * as actionTypes from '../actionTypes';

export const changePasswordReducer = (state = { isFetching: false }, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.CHANGE_PASSWORD_START:
        st.isFetching = true;
        break;
      case actionTypes.CHANGE_PASSWORD_SUCCESS:
        st.isFetching = false;
        break;
      case actionTypes.CHANGE_PASSWORD_FAILURE:
        st.isFetching = false;
        break;
      default:
    }
  });
};
