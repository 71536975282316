// StepRangeSlider code from https://github.com/robertgonzales/react-step-range-slider

import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import { configureRange } from './slider-utils';

import './StepRangeSlider.css';

export default class StepRangeSlider extends Component {
  componentWillMount() {
    this.handleChange = throttle(this.handleChange, 200);
    this.setInitialState(this.props);
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.handleTouchMove);
    window.addEventListener('touchend', this.handleMouseUp);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    this.handleChange.cancel();
    window.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('touchend', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.range && nextProps.range !== this.props.range) {
      this.setInitialState(nextProps);
    }
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.value) {
      const value = this.state.range.ensureValue(nextProps.value);
      this.setState({ value });
    }
  }

  setInitialState = (props) => {
    const range = configureRange(props.range);
    const value = range.ensureValue(props.value);
    const currentStep = range.getStepForValue(value);
    this.setState({ value, range, currentStep });
  };

  stepUp = (amount) => {
    const { range, currentStep } = this.state;
    const nextStep = currentStep + amount;
    if (nextStep <= range.maxStep) {
      const nextValue = range.getValueForStep(nextStep);
      this.setState({ currentStep: nextStep, value: nextValue });
    }
  };

  stepDown = (amount) => {
    const { range, currentStep } = this.state;
    const nextStep = currentStep - amount;
    if (nextStep >= range.minStep) {
      const nextValue = range.getValueForStep(nextStep);
      this.setState({ currentStep: nextStep, value: nextValue });
    }
  };

  handleChange = () => {
    const { value } = this.state;
    const { onChange } = this.props;
    isFunction(onChange) && onChange(value);
  };

  handleChangeComplete = () => {
    const { value } = this.state;
    const { onChangeComplete } = this.props;
    isFunction(onChangeComplete) && onChangeComplete(value);
  };

  handleMouseUp = (e) => {
    if (this.state.pressed) {
      this.setState({ pressed: false });
      this.handleChangeComplete();
    }
  };

  handleMouseMove = (e) => {
    if (this.state.pressed) {
      this.handleMove(e);
    }
  };

  handleMouseDown = (e) => {
    e.preventDefault();
    this.handlePress();
    this.handleMove(e);
  };

  handleTouchMove = (e) => {
    if (this.state.pressed) {
      e.preventDefault();
      this.handleMouseMove(e.touches[0]);
    }
  };

  handleTouchStart = (e) => {
    this.handlePress();
    this.handleMove(e.touches[0]);
  };

  handlePress = () => {
    this.sliderRect = this.slider.getBoundingClientRect();
    this.setState({ pressed: true });
  };

  handleMove = (e) => {
    const { clientX } = e;
    const { disabled } = this.props;
    const { range } = this.state;
    const { width, left, right } = this.sliderRect;

    if (!clientX || disabled) return;

    let position;
    if (clientX < left) {
      position = 0;
    } else if (clientX > right) {
      position = right - left;
    } else {
      position = clientX - left;
    }
    const currentStep = Math.round((position / width) * range.maxStep);
    const value = range.getValueForStep(currentStep);

    if (value !== this.state.value || currentStep !== this.state.currentStep) {
      this.setState({ value, currentStep }, this.handleChange);
    }
  };

  render() {
    const { value, range, currentStep } = this.state;
    const { hideRange } = this.props;

    const offset = (currentStep / range.maxStep) * 100;
    const offsetStyle = { left: `${offset}%`, top: hideRange ? '2px' : '49px' };

    return (
      <div
        className="StepRangeSlider"
        style={{ paddingTop: !hideRange && '3rem' }}
        onMouseDown={this.handleMouseDown}
        ref={(node) => (this.slider = node)}
      >
        {!hideRange &&
          range.values.map((e, idx) => {
            return (
              <div
                key={idx}
                className="StepRangeSlider__range"
                style={{
                  left: `${(idx * 100) / range.maxStep}%`,
                  color: e > value && '#D4D4D4',
                }}
              >
                {e}
              </div>
            );
          })}

        {range.values.slice(1).map((e, idx) => {
          return (
            <div
              key={idx}
              className="StepRangeSlider__track"
              style={{
                left: `${(idx * 100) / range.maxStep}%`,
                backgroundColor: e > value && '#F2F2F2',
                borderTopLeftRadius: 0 === idx && '7px',
                borderBottomLeftRadius: 0 === idx && '7px',
                borderTopRightRadius: range.values.length - 1 === idx && '7px',
                borderBottomRightRadius: range.values.length - 1 === idx && '7px',
              }}
            />
          );
        })}

        <div
          className="StepRangeSlider__handle"
          onTouchStart={this.handleTouchStart}
          onMouseDown={this.handleMouseDown}
          style={offsetStyle}
        >
          <div
            className="StepRangeSlider__thumb"
            aria-valuemin={range.minValue}
            aria-valuemax={range.maxValue}
            aria-valuenow={value}
            role="slider"
          />
        </div>
      </div>
    );
  }
}

StepRangeSlider.displayName = 'StepRangeSlider';

StepRangeSlider.propTypes = {
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
  range: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  hideRange: PropTypes.bool.isRequired,
};
