import { useContext } from 'react';

import { GridApiContext, GridApiContextValue } from '../context';

export function useGridApi(): GridApiContextValue {
  const gridApiContext = useContext(GridApiContext);

  if (!gridApiContext) {
    throw new Error("Can't find a parent yards list context.");
  }

  return gridApiContext;
}
