import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';

export const YardDetailsLoading: React.VFC = () => {
  const isFetching = useSelector((state) => state.yardDetailsReducer.isFetchingDetail);

  return (
    <LoadingSkeleton
      visible={isFetching}
      absolutelyFitParent
      column
      stretch
      paddingMD
      backgroundColor={'coreBackground'}
    >
      <LoadingSkeletonRectangle
        width={'192px'}
        height={'28px'}
        backgroundColor={'grey06'}
        borderRadius={'paperRadius02'}
      />
      <Box marginTopLG gapSM>
        <LoadingSkeletonRectangle width={'128px'} height={'24px'} />
        <LoadingSkeletonRectangle width={'148px'} height={'24px'} />
        <LoadingSkeletonRectangle width={'128px'} height={'24px'} />
      </Box>
      <Box marginTopLG stretch gapMD>
        <Box flex={2} column stretch gapSM>
          <LoadingSkeletonRectangle width={'100%'} height={'30vh'} />
          <LoadingSkeletonRectangle width={'100%'} height={'30vh'} />
        </Box>
        <Box fit column stretch gapSM>
          <LoadingSkeletonRectangle width={'100%'} height={'100%'} />
        </Box>
      </Box>
    </LoadingSkeleton>
  );
};
