import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '@components/common/Modal';
import { useContract, useContractState } from '@hooks/useContract';
import { makeCloseContractModal } from '@redux/Contract/actions';

import { FormContract } from './FormContract';

type ModalFormContractProps = {
  contract?: BeeContract;
};

enum ContractFormType {
  EDIT,
  CREATE,
}

export const ModalFormContract: React.FC<ModalFormContractProps> = () => {
  const dispatch = useDispatch();
  const { isContractModalOpen, contractToEdit } = useContractState();

  const closeModal = useCallback(() => {
    dispatch(makeCloseContractModal());
  }, [dispatch]);

  return (
    <Modal isOpen={isContractModalOpen} size="large" onRequestClose={closeModal}>
      {contractToEdit ? (
        <FormContract onCancel={closeModal} contract={contractToEdit} isEdit={true} />
      ) : (
        <FormContract onCancel={closeModal} />
      )}
    </Modal>
  );
};
