import { HTMLAttributes } from 'react';

import { MetricValue } from '@redux/Yards/types';

export enum CardType {
  GLOBAL = 'global',
  GLOBAL_WITH_RATIO = 'global_with_ratio',
  CATEGORY = 'category',
}

export type MetricMetadataProps = {
  cardType: CardType;
  metricName: string;
  metricValue: MetricValue[];
  previousValueDelta?: string | null;
  positiveDirection: boolean;
  sampleSize: number;
  isFetching: boolean;
  onClick: HTMLAttributes<HTMLElement>['onClick'];
};
