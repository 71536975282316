import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Close } from '@components/common/Icon';
import { Contract } from '@components/common/Icon/presets/Contract';
import { Group } from '@components/common/Icon/presets/Group';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { UnstyledButton } from '@components/common/UnstyledButton';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useContractSummaries } from '@hooks/useContract';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenAssignContractModal } from '@redux/AssignContract/actions';
import { AssignContractYard } from '@redux/AssignContract/types';
import { makeOpenManyToManyGroupsModalAction } from '@redux/deprecated/actions';
import { from } from '@style/mediaQueries';

import { useYardsListSelection } from '../YardsList/hooks/useYardsListSelectionContext';

export const StyledBottomNav = styled.div<{ visible?: boolean }>(
  ({ theme, visible }) => `
  position: fixed;
  left: 50%;
  bottom: 1.5rem;  
  transition: ${theme.animations.transitionMedium('opacity', 'transform')};
  opacity: ${visible ? 1 : 0};
  transition-delay: ${visible ? '0ms' : '400ms'};
  transform: translateX(-50%) ${visible ? 'translateY(0px) scale(1)' : 'translateY(64px) scale(0.5)'};
  z-index: ${theme.getZIndexOf('tabs')};

  ${from('laptop')} {
    bottom: 2.5rem;
  }
`
);

const StyledActionWrapper = styled.span(
  ({ theme }) => `
    padding: ${theme.spacing.xs}px;
    display: block;
    border-radius: 3px;

    &:hover {
      background-color: ${theme.colors.coreWhite};
      color: ${theme.colors.grey08};
    }
  
  `
);

const StyledActionButton = styled(UnstyledButton)(
  ({ theme }) => `
    &:disabled ${StyledActionWrapper} {
      color: ${theme.colors.grey06};
      &:hover {
        background-color: initial;
        color: ${theme.colors.grey06};
      }
    }
  `
);

export const BatchActionBar = styled(Box)(
  ({ theme }) => `
    border-radius: 10000px;
    padding: ${theme.spacing.xs}px ${theme.spacing.md}px; 
    
    background-color: ${theme.colors.grey08};
    color: ${theme.colors.coreWhite};
    
    white-space: nowrap;
    cursor: pointer;

  `
);

export const BatchActionVerticalLine = styled.div(
  ({ theme }) => `
    width: 0px;
    height: 24px;
    border: 1px solid ${theme.colors.coreWhite};
    margin: 0 12px;
  `
);

const StyledTooltip = styled(Tooltip)`
  max-width: 200px;
`;

export const BulkActionsNav: React.FC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { selectedYards, selectionCount, hasClickedSelectAllAcrossPages, deselectAllAcrossPages } =
    useYardsListSelection();
  const contractSummaries = useContractSummaries();
  const disableContractButton = hasClickedSelectAllAcrossPages;
  const noContracts = !contractSummaries || !contractSummaries.length;
  const shouldDisableContractButton = disableContractButton || noContracts;
  const contractButtonTooltipText = disableContractButton
    ? 'assign_contract_disabled_all_yards'
    : noContracts
    ? 'assign_contract_disabled_no_contracts'
    : 'assign_contract';

  const handleManyToManyGroupsModal = useCallback(() => {
    const modalObj = {
      modalType: 'assign-many-to-many',
      yard_ids: Object.keys(selectedYards),
      hasSelectedAllYardsAcrossPages: hasClickedSelectAllAcrossPages,
    };
    dispatch(makeOpenManyToManyGroupsModalAction(modalObj));
  }, [dispatch, hasClickedSelectAllAcrossPages, selectedYards]);

  const handleBulkAssignContractModal = useCallback(() => {
    const assignContractYards: AssignContractYard[] = Object.values(selectedYards).map((yard) => {
      return {
        id: yard.meta.id,
        contractName: yard.meta.contractName,
        name: yard.meta.name,
      };
    });
    dispatch(makeOpenAssignContractModal(assignContractYards));
  }, [dispatch, selectedYards]);

  return (
    <StyledBottomNav visible={!!selectionCount}>
      <BatchActionBar center>
        <Text typography={'SmallParagraph'} weight={'600'}>
          {t('yards_selected', {
            number: maybePluralize(selectionCount, 'yard', t),
          })}
        </Text>
        <BatchActionVerticalLine />
        <StyledActionButton onClick={handleManyToManyGroupsModal}>
          <StyledActionWrapper id="assign-to-group-icon">
            <Group size={24} />
          </StyledActionWrapper>

          <Tooltip target="assign-to-group-icon">
            <Text typography="CaptionSmall">{t('assign_groups')}</Text>
          </Tooltip>
        </StyledActionButton>

        <StyledActionButton onClick={handleBulkAssignContractModal} disabled={shouldDisableContractButton}>
          <StyledActionWrapper id="assign-to-contract-icon">
            <Contract size={24} />
          </StyledActionWrapper>

          <StyledTooltip target="assign-to-contract-icon">
            <Text typography="CaptionSmall">{t(contractButtonTooltipText)}</Text>
          </StyledTooltip>
        </StyledActionButton>

        <StyledActionButton onClick={(e) => deselectAllAcrossPages && deselectAllAcrossPages()}>
          <StyledActionWrapper id="clear-selection-icon">
            <Close size={24} />
          </StyledActionWrapper>

          <Tooltip target="clear-selection-icon">
            <Text typography="CaptionSmall">{t('clear_selection')}</Text>
          </Tooltip>
        </StyledActionButton>
      </BatchActionBar>
    </StyledBottomNav>
  );
};
