import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HiveDefault = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <rect fill={useColor(color)} width="24" height="24" fillOpacity="0.01" />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 3C5.39382 3.00108 5.06509 3.19153 4.887 3.5L3.137 6.5C3.04791 6.65161 3.00063 6.82415 3 7V19C3.00817 20.1012 3.89883 20.9918 5 21H19C20.1012 20.9918 20.9918 20.1012 21 19V7C20.9994 6.82415 20.9521 6.65161 20.863 6.5L19.113 3.5C18.9349 3.19153 18.6062 3.00108 18.25 3H5.75ZM6.324 5H17.676L18.842 7H5.158L6.324 5ZM14 9H10C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9Z"
      />
    </IconSVGView>
  );
};
