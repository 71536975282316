import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// nectar:
import { LiveStyleGuideFirstView } from './LiveStyleGuideFirstView';
import { LiveStyleGuideSecondView } from './LiveStyleGuideSecondView';
import { LiveStyleGuideThirdView } from './LiveStyleGuideThirdView';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 5rem;
`;

export const LiveStyleGuide = (props) => {
  const [activeItem, setActiveItem] = useState('all');
  const [hideList, toggleList] = useState(true);
  const [checked, handleToggleChecked] = useState(true);

  return (
    <Container>
      <LiveStyleGuideFirstView
        t={props.t}
        checked={checked}
        handleToggleChecked={() => handleToggleChecked(!checked)}
      />
      <LiveStyleGuideSecondView
        t={props.t}
        activeItem={activeItem}
        setActiveItem={(activeItem) => setActiveItem(activeItem)}
        toggleList={() => toggleList(!hideList)}
        hideList={hideList}
      />
      <LiveStyleGuideThirdView t={props.t} />
    </Container>
  );
};

LiveStyleGuide.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(LiveStyleGuide));
