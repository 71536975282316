import React, { useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import { ActionIcon } from '@components/common/ActionIcon';
import { Box } from '@components/common/Box';
import { NotesNavigator } from '@components/common/NotesNavigator';
import {
  StyledVisitCard,
  StyledVisitCardIcon,
  StyledVisitCardItem,
  StyledVisitCardLeftText,
  StyledVisitCardRightText,
} from '@components/common/YardVisitCard/styles';
import { YardVisitCardProps } from '@components/common/YardVisitCard/types';
import { useActionsNames, useCategoryNameByAction } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';

export const YardVisitCard: React.VFC<YardVisitCardProps> = ({
  preset = 'timeline',
  yardNbHivesOnVisitDate,
  practices,
  notes,
}) => {
  const t = useTranslation();
  const actionsNames = useActionsNames();
  const categoriesNames = useCategoryNameByAction(practices.map(({ id }) => id));

  const hasNotes = notes.length > 0;
  const [selectedNote, setSelectedNote] = useState<{ text: string; nbHives: number } | null>(null);

  const sortedPractice = useMemo(() => {
    return practices.sort((a, b) => {
      const comp = (categoriesNames[a.id] ?? '').localeCompare(categoriesNames[b.id] ?? '');
      if (comp !== 0) {
        return comp;
      }
      return (actionsNames[a.id] ?? '').localeCompare(actionsNames[b.id] ?? '');
    });
  }, [actionsNames, categoriesNames, practices]);

  return (
    <StyledVisitCard preset={preset}>
      {sortedPractice.map(({ id, nbHives }) => (
        <StyledVisitCardItem key={id}>
          <Box fit alignItems={'flex-start'}>
            <StyledVisitCardIcon>
              <ActionIcon actionId={id} />
            </StyledVisitCardIcon>
            <StyledVisitCardLeftText preset={preset}>
              <b>{capitalize(categoriesNames[id])}</b>: {capitalize(actionsNames[id])}
            </StyledVisitCardLeftText>
            <StyledVisitCardRightText preset={preset}>
              {nbHives}
              {nbHives >= yardNbHivesOnVisitDate ? `/${yardNbHivesOnVisitDate}` : ''}
            </StyledVisitCardRightText>
          </Box>
        </StyledVisitCardItem>
      ))}

      {hasNotes && (
        <Box column stretch>
          <StyledVisitCardItem>
            <Box fit {...(preset === 'tooltip' ? { stretch: true } : { alignItems: 'flex-start' })}>
              <NotesNavigator
                notes={notes}
                titleTextProps={preset === 'tooltip' ? { typography: 'CaptionSmall', weight: '600' } : undefined}
                bodyTextProps={preset === 'tooltip' ? { typography: 'CaptionSmall' } : undefined}
                onNoteSelected={setSelectedNote}
              />

              {!!selectedNote && (
                <StyledVisitCardRightText preset={preset}>
                  {selectedNote.nbHives}
                  {selectedNote.nbHives >= yardNbHivesOnVisitDate ? `/${selectedNote.nbHives}` : ''}
                </StyledVisitCardRightText>
              )}
            </Box>
          </StyledVisitCardItem>
        </Box>
      )}
    </StyledVisitCard>
  );
};
