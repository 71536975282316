import React from 'react';
// vendor:
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

// nectar:
import theme from '@style/theme';

class Modal extends React.Component {
  /**
   * @see https://github.com/reactjs/react-modal/issues/133
   */
  componentDidMount() {
    if ('undefined' !== typeof window) {
      ReactModal.setAppElement('body');
    }
  }

  render() {
    const { isOpen, modalType, isMobile, onRequestClose, children } = this.props;

    const getWidth = () => {
      switch (modalType) {
        case 'legend':
        case 'change-pass':
        case 'add-worker':
        case 'update-worker':
        case 'update-practice':
        case 'create-practice':
        case 'contact':
        case 'update-yard':
        case 'create-yard':
        case 'hive-identity':
        case 'filter-yards':
        case 'add-group':
        case 'update-group':
          return theme.maxModalWidth;
        case 'remove-practice':
        case 'remove-worker':
        case 'remove-yards':
        case 'remove-yard':
        case 'cancel-changes':
        case 'assign-yard-groups':
        case 'assign-many-to-many':
        case 'remove-group':
          return theme.maxSmallModalWidth;
        default:
          return null;
      }
    };

    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            backgroundColor: theme.modalOverlayBgColor,
            zIndex: theme.getZIndexOf('modal'),
          },
          content: {
            width: isMobile ? '100%' : getWidth(),
            height: isMobile ? '100%' : 'fit-content',
            padding: 0,
            border: 'none',
            backgroundColor: theme.primaryBackgroundColor,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowX: 'hidden',
            overflowY: 'visible',
            // for firefox
            display: 'table',
          },
        }}
      >
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
