import React from 'react';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { YardVisitCard } from '@components/common/YardVisitCard';
import { Children } from '@helpers/Children';
import { useTranslation } from '@hooks/useTranslation';

export const LastVisitTooltipContent: React.FC<{ data: any }> = ({ data }) => {
  const t = useTranslation();

  const workerNames = Children.createWithSeparators(data?.workerNames ?? [], {
    lastSeparator: ` ${t('and')} `,
  }).join('');
  const occurredAt = data?.occurredAt ? moment(data.occurredAt).format('MMMM DD, YYYY [at] hh:mm') : '';

  return data ? (
    <Box column stretch paddingXXS>
      <Box paddingBottomXS marginBottomXXS>
        <Text
          typography={'CaptionSmall'}
          dangerouslySetInnerHTML={{ __html: t('yard_visit_by', { name: workerNames }) }}
        />
      </Box>
      <YardVisitCard preset={'tooltip'} {...data} />
      <Box paddingTopXS marginTopXXS>
        <Text typography={'CaptionSmall'}>{occurredAt}</Text>
      </Box>
    </Box>
  ) : null;
};
