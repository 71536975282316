import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// nectar:
import triangleDownIcon from '@assets/xSmall_Arrow-dropdown.svg';
import { capitalizeFirst } from '@helpers/deprecated/capitalizeFirst';
import { getIconByType } from '@helpers/deprecated/icons';
import { elementInViewport } from '@helpers/deprecated/viewport';
import theme from '@style/theme';

import './dropdown.css';

export const DropdownView = ({
  t,
  disabled,
  inputName,
  renderIcon,
  dropdownItems,
  activeItemId,
  selectBoxStyle,
  selectBoxCurrentStyle,
  selectBoxListStyle,
  selectBoxOptionStyle,
  selectBoxInputText,
  passDropdownDataToParent,
}) => {
  const useKeyPress = function (targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    useEffect(() => {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    });

    return keyPressed;
  };

  const [activeItem, setActiveItem] = useState(activeItemId);
  const [hideList, toggleList] = useState(true);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  let isElementInViewport = true;

  isElementInViewport = elementInViewport(document.getElementById('select-box'));

  const smallScreenHeightAdjust = isElementInViewport ? { top: '3rem' } : { bottom: '4.25rem' };

  useEffect(() => {
    if (activeItemId === activeItem) return;
    setActiveItem(activeItemId);
  }, [activeItem, activeItemId]);

  useEffect(() => {
    if (dropdownItems.length && downPress) {
      if (hideList) {
        toggleList(false);
      } else {
        setCursor((prevState) => (prevState < dropdownItems.length - 1 ? prevState + 1 : prevState));
      }
    }
  }, [downPress, dropdownItems, hideList]);

  useEffect(() => {
    if (dropdownItems.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress, dropdownItems]);

  useEffect(() => {
    if (dropdownItems.length && enterPress && !hideList) {
      setActiveItem(dropdownItems[cursor].id);
      toggleList(true);
      passDropdownDataToParent(dropdownItems[cursor].id);
    }
  }, [cursor, enterPress, dropdownItems, hideList, passDropdownDataToParent]);

  useEffect(() => {
    if (dropdownItems.length && hovered) {
      setCursor(dropdownItems.indexOf(hovered));
    }
  }, [hovered, dropdownItems]);

  const handleUserKeyPress = useCallback(
    (event) => {
      if (!hideList) toggleList(true);
    },
    [hideList]
  );

  useEffect(() => {
    window.addEventListener('click', handleUserKeyPress);
    return () => {
      window.removeEventListener('click', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const ListItem = ({ item, active, setActiveItem, setHovered }) => (
    <li
      style={active ? { backgroundColor: theme.dropdownHoverStyle } : {}}
      className="select-box-list-item"
      onClick={() => {
        setActiveItem(item.id);
        passDropdownDataToParent(item.id);
      }}
      onTouchStart={() => {
        setActiveItem(item.id);
        passDropdownDataToParent(item.id);
      }}
      onMouseEnter={() => setHovered(item)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {renderIcon && (
        <img className="select-img-item" src={getIconByType(inputName, item.name, 'x')} alt="dropdown icon" />
      )}
      <label
        className="select-box-option"
        style={selectBoxOptionStyle}
        htmlFor={`${inputName}_` + item.id}
        aria-hidden={false}
      >
        {capitalizeFirst(t(item.name))}
      </label>
    </li>
  );

  if (disabled) {
    const selectedItem = dropdownItems.find((item) => item.id === activeItem);
    return (
      <div className="select-box disabled" id="select-box" style={selectBoxStyle}>
        <div className="select-box-current" tabIndex="0" style={selectBoxCurrentStyle}>
          <div key={selectedItem.id} className="select-box-value">
            <p className="select-box-input-text select-box-input-text-disabled">
              {capitalizeFirst(t(selectedItem.name))}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="select-box" id="select-box" style={selectBoxStyle}>
      <div className="select-box-current" tabIndex="0" style={selectBoxCurrentStyle}>
        {dropdownItems.map((item, i) => (
          <div key={item.id} className="select-box-value">
            <input
              className="select-box-input"
              onChange={() => toggleList(!hideList)}
              onClick={() => toggleList(!hideList)}
              type="radio"
              id={`${inputName}_` + item.id}
              name={inputName}
              value={item.id}
              checked={activeItem === item.id}
            />
            <label
              className="select-box-input-text"
              style={0 === i ? {} : selectBoxInputText}
              htmlFor={`${inputName}_` + item.id}
            >
              {renderIcon && 0 !== i && (
                <img className="select-img-input" src={getIconByType(inputName, item.name)} alt="dropdown icon" />
              )}
              {capitalizeFirst(t(item.name))}
            </label>
          </div>
        ))}
        <img
          onClick={() => toggleList(!hideList)}
          className="select-box-icon"
          src={triangleDownIcon}
          alt="arrow icon"
          aria-hidden={true}
        />
      </div>

      <ul
        tabIndex="1"
        id="select-box-list"
        className="select-box-list"
        style={{
          display: hideList ? 'none' : 'inherit',
          ...selectBoxListStyle,
          ...smallScreenHeightAdjust,
        }}
      >
        {dropdownItems.map(
          (item, i) =>
            0 !== i && (
              <ListItem
                key={item.id}
                active={i === cursor}
                item={item}
                setActiveItem={setActiveItem}
                setHovered={setHovered}
              />
            )
        )}
      </ul>
    </div>
  );
};

DropdownView.propTypes = {
  t: PropTypes.func.isRequired,
  renderIcon: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  activeItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  passDropdownDataToParent: PropTypes.func.isRequired,
};
