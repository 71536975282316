import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledTimelineDayMark = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: center;
    z-index: ${theme.getZIndexOf('tabs')};
    pointer-events: none;
  `
);

export const StyledTimelineDayLine = styled(Box)(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.grey05};
    transform: translateY(24px);
  `
);

export const StyledTimelineDayMarkSelector = styled(Box)(
  ({ theme }) => css`
    position: relative;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 16px;
    pointer-events: all;
    border: ${theme.colors.grey05} 1px solid;
    background-color: ${theme.colors.coreWhite};
    padding: 0 ${theme.spacing.sm}px;
    margin-top: ${theme.spacing.xs}px;
  `
);
