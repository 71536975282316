import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import menuIcon from '@assets/MediumBurgerMenu.svg';
import menuMobileIcon from '@assets/Small_Burger-menu.svg';
import SearchBox from '@components/deprecated/SearchBox';
import { SearchBoxContainer } from '@components/deprecated/SearchBox/style';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeGlobalSearchFetchYardsThunk, makeGlobalSearchPushToHistoryAction } from '@redux/YardsGlobalSearch/actions';
import theme from '@style/theme';
import { Paragraph } from '@style/typeface';

import NavBarNotifications from './NavBarNotifications';
import { NavBarTabs } from './NavBarTabs';
import NavBarTitle from './NavBarTitle';

const StyledRow = styled(Row)`
  width: 100%;
  height: ${(props) => props.height}px;
  position: fixed;
  background-color: ${(props) => props.theme.primaryColor};
  box-shadow: ${(props) => props.theme.boxShadow1};
  flex-wrap: nowrap !important;
  z-index: ${(props) => props.theme.getZIndexOf('headerNav')};
  padding-left: ${(props) => (props.isMobile ? '1.5rem' : '3rem')};
`;

const OperationContainer = styled.div`
  ${Paragraph};
  display: flex;
  align-items: center;
  height: 2rem;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: solid 1px #5f6867;
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  color: ${(props) => props.theme.buttonPrimaryTextColor};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #464646;
`;

const StyledCol = styled(Col)`
  display: flex;
  height: 100%;
`;

const StyledMenuBtn = styled.button`
  flex: 0;
  border-radius: 1rem;
  margin-right: 2.5rem;
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
  &:focus {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

const StyledIcon = styled.img`
  display: inline-block;
  vertical-align: bottom;
  filter: invert(1);
`;

const MobileSearchRow = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.tabBarHeight}px;
  position: fixed;
  background-color: ${({ theme }) => theme.primaryColor};
  box-shadow: ${({ theme }) => theme.boxShadow1};
  flex-wrap: nowrap !important;
  z-index: ${({ theme }) => theme.getZIndexOf('highLevelIndex')};
  padding: 0 1rem;
  display: flex;
  ${SearchBoxContainer} {
    flex-grow: 1;
  }
`;

export const NavBarView = ({
  t,
  user,
  isMobile,
  isMobileOrTablet,
  isSearchFocused,
  disableShowNotif,
  handleClickOnMenu,
  showTab,
  searchInput,
  setSearchInput,
  searchExpandedOnMobile,
  setSearchFocused,
  clearYardFilters,
}) => {
  const dispatch = useDispatch();

  const yardsListSummary = useSelector((state) => state.yardsGlobalSearchReducer.yards ?? []);
  const yardsSearchHistory = useSelector((state) => state.yardsGlobalSearchReducer.history ?? []);
  const isYardsListSummaryFetching = useSelector((state) => state.yardsGlobalSearchReducer.isFetching);

  const searchHistory = useMemo(
    () => yardsListSummary.filter((yard) => yardsSearchHistory.includes(yard.id)),
    [yardsListSummary, yardsSearchHistory]
  );

  const searchIndex = useMemo(() => {
    return new Fuse(yardsListSummary, {
      keys: ['contractName', 'name'],
      threshold: 0.2,
    });
  }, [yardsListSummary]);

  const searchResults = useMemo(() => {
    return searchIndex.search(searchInput).map(({ item }) => item);
  }, [searchIndex, searchInput]);

  const addYardToSearchHistory = useCallback(
    (yardId) => {
      dispatch(makeGlobalSearchPushToHistoryAction({ id: yardId }));
    },
    [dispatch]
  );

  useEffect(() => {
    isSearchFocused && dispatch(makeGlobalSearchFetchYardsThunk());
  }, [dispatch, isSearchFocused]);

  return (
    <>
      {searchExpandedOnMobile ? (
        <MobileSearchRow>
          <SearchBox
            isMobile={isMobile}
            isLoading={isYardsListSummaryFetching}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            setSearchFocused={setSearchFocused}
            searchExpandedOnMobile={searchExpandedOnMobile}
            searchResults={searchResults}
            t={t}
            clearYardFilters={clearYardFilters}
            addYardToSearchHistory={addYardToSearchHistory}
            searchHistory={yardsSearchHistory}
          />
        </MobileSearchRow>
      ) : (
        <StyledRow nogutter isMobile={isMobile} height={isMobileOrTablet ? theme.tabBarHeight : theme.topBarHeight}>
          {/* <StyledCol style={{ alignItems: 'center' }} xs={7} sm={6}>
            <NavBarTitle isMobile={isMobile} />
            {showTab && user && <OperationContainer>{user.operation_name}</OperationContainer>}
          </StyledCol> */}

          <Col xs={1} sm={2} />

          <StyledCol style={{ justifyContent: 'flex-end' }} xs={4}>
            <SearchBox
              isMobile={isMobile}
              isLoading={isYardsListSummaryFetching}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              setSearchFocused={setSearchFocused}
              searchResults={searchResults}
              t={t}
              clearYardFilters={clearYardFilters}
              addYardToSearchHistory={addYardToSearchHistory}
              searchHistory={searchHistory}
            />
            {/* <NavBarNotifications t={t} isMobile={isMobile} disableShowNotif={disableShowNotif} /> */}

            {/* <StyledMenuBtn onClick={handleClickOnMenu}>
              <StyledIcon alt="menu" src={isMobile ? menuMobileIcon : menuIcon} />
            </StyledMenuBtn> */}
          </StyledCol>
        </StyledRow>
      )}

      {isMobile && <Divider />}
    </>
  );
};

NavBarView.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  isSearchFocused: PropTypes.bool,
  showTab: PropTypes.bool,
  isMobileOrTablet: PropTypes.bool.isRequired,
  handleClickOnMenu: PropTypes.func.isRequired,
  disableShowNotif: PropTypes.bool,
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  searchExpandedOnMobile: PropTypes.bool.isRequired,
  setSearchFocused: PropTypes.func.isRequired,
  clearYardFilters: PropTypes.func.isRequired,
};

export default withRouter(connect()(withTranslation()(NavBarView)));
