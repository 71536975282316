import produce from 'immer';

import { NAV_BAR_TITLE_CHANGE } from '../actionTypes';

const defaultState = {
  content: {
    backBtn: false,
    label: '',
    yard: null,
    view: '',
  },
};

export const navBarTitleReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    if (NAV_BAR_TITLE_CHANGE === action.type) {
      st.content = action.content;
    }
  });
};
