import styled from 'styled-components';

import { TableCellLayout, TableHeaderProps } from '@components/common/Table/types';

export const StyledTableWrapper = styled.div(
  ({ theme }) => `
  overflow: auto;
  max-height: 75vh;
  max-width: 75vw;
`
);

export const StyledGridTable = styled.div<{ columns?: number; templateColumns?: string }>(
  ({ theme, columns, templateColumns }) => `
  display: grid;
  background: ${theme.colors.coreWhite};
  box-shadow: ${theme.shadows.boxShadow01};
  border-radius: ${theme.shape.paperRadius01}px;
  ${
    templateColumns
      ? `grid-template-columns: ${templateColumns};`
      : `grid-template-columns: repeat(${columns}, minmax(max-content, 1fr))`
  }
  
  `
);

export const TableHeaderExtraView = styled.div(
  () => `
    display: flex;
    grid-column: 1/-1; // makes it take the whole line
    background-color: white;
    position: sticky;
    left: 0;
`
);

export const StyledFakeRow = styled.div(
  ({ theme }) => `
    display: contents;
    cursor: pointer;
    :not(:last-child) > div {
      border-bottom: ${theme.colors.grey04} 1px solid;
    }
    :hover > div {
      background-color: ${theme.colors.grey02};
    }
  `
);

export const TableCellView = styled.div<TableCellLayout>(
  ({ theme, width, minWidth, padding, selected, disabled, isFirstColumn }) => `
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  
  ${
    minWidth
      ? `
          flex-grow: 1;
          flex-basis: ${typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
        `
      : width
      ? `
          flex-grow: ${width === 'auto' ? 1 : 0};
          flex-basis: ${width === 'auto' ? '0%' : typeof width === 'number' ? `${width}px` : width};
        `
      : ''
  }
  
  align-items: center;
  min-height: 64px;
  padding: ${
    typeof padding === 'string'
      ? padding
      : typeof padding === 'number'
      ? `${padding}px`
      : `${theme.spacing.xs}px ${theme.spacing.md}px`
  };
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: ${theme.getZIndexOf('tableBody')};
  box-sizing: border-box;
  background-color: ${selected ? theme.colors.grey02 : theme.colors.coreWhite};
  
  &:first-child {
    position: sticky;
    left: 0;
    z-index: ${theme.getZIndexOf('tableCell')};
    border-right: ${theme.colors.grey04} 1px solid;
    padding-left: ${theme.spacing.md}px;
  }
  
  &:last-child {
    padding-right: ${theme.spacing.md}px;
  }

  & *[role="text"] {
    opacity: ${disabled ? 0.4 : 1};
  }
  

  ${
    isFirstColumn
      ? `
    position: sticky; 
    left: 0; 
    z-index: ${theme.getZIndexOf('tableHeaderLeft')};
    box-shadow: 3px 0px 2px 0px rgb(102 102 102 / 10%);
  `
      : ''
  }

`
);

export const TableHeaderArrowIcon = styled.img<TableHeaderProps>(
  ({ theme, isSorting, sortingDirection }) => `
  width: 20px;
  height: 20px;
  margin-left: ${theme.spacing.xs}px;
  transition: ${theme.animations.transitionFast('transform', 'opacity')};
  transform: translateY(${sortingDirection === 'ASC' ? 10 : -5}%) scaleY(${sortingDirection === 'ASC' ? '-1' : '1'});
  opacity: ${isSorting ? 1 : 0};
  filter: ${isSorting ? theme.colors.filter('grey08') : theme.colors.filter('grey06')};
`
);

export const TableHeaderView = styled(TableCellView)<{ hoverable?: boolean } & TableHeaderProps>(
  ({ theme, hoverable }) => `
    font-weight: 600;
    font-size: 0.75rem;
    background-color: ${theme.colors.coreWhite};
    cursor: ${hoverable ? 'pointer' : 'default'};
    position: sticky;
    top: -1px;
    z-index: ${theme.getZIndexOf('tableHeaderTop')};
    border-bottom: ${theme.colors.grey04} 1px solid;

   &:first-child {
      position: sticky;
      left: 0;
      z-index: ${theme.getZIndexOf('tableHeaderTopLeft')};
      border-right: none;
      border-left: none;
  }


  &:hover {
    &, & * {
      color: ${hoverable ? theme.colors.grey06 : theme.colors.grey08};
    }

    & img {
      opacity: 1;
      filter: ${theme.colors.filter('grey06')};
    }
  }

`
);
