import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SortTriangle } from '@components/deprecated/Table_deprecated/SortTriangle';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';

const StyledTh = styled.th`
  &:hover > .header-title {
    opacity: ${(props) => (props.disableHover ? 1 : 0.6)};
  }
`;

const StyledThTitle = styled.span`
  border-bottom: ${(props) => (props.hasTooltip ? '1px dashed rgba(55,67,65,0.6)' : 'none')};
`;

export const TableHeader = ({
  id,
  style,
  data,
  handleSort,
  disableHover,
  sort,
  width,
  tooltip,
  tooltipPlace,
  tooltipOffset,
}) => {
  const [hover, handleHover] = useState(false);
  const direction = id === sort?.order_by ? sort?.order_direction : false;
  const tooltipId = useMemo(() => `${id}-tooltip`, [id]);

  return (
    <StyledTh
      onMouseOver={() => (disableHover ? {} : handleHover(id))}
      onMouseLeave={() => (disableHover ? {} : handleHover(false))}
      onClick={() => handleSort(id, direction)}
      style={style}
      disableHover={disableHover}
      width={width}
    >
      {tooltip && (
        <Tooltip id={tooltipId} disable={false} tooltipText={tooltip} place={tooltipPlace} offset={tooltipOffset} />
      )}
      <StyledThTitle className="header-title" hasTooltip={tooltip} data-for={tooltipId} data-tip>
        {data}
      </StyledThTitle>
      {sort && <SortTriangle id={id} sort={sort} show={hover === id} />}
    </StyledTh>
  );
};

TableHeader.propTypes = {
  handleSort: PropTypes.func,
  id: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  style: PropTypes.object,
  disableHover: PropTypes.bool,
  sort: PropTypes.object,
  tooltip: PropTypes.string,
  tooltipPlace: PropTypes.oneOf(['top', 'right', 'bottom', 'top']),
  tooltipOffset: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  width: PropTypes.string,
};

TableHeader.defaultProps = {
  handleSort: () => {},
};

export default TableHeader;
