import { connect } from 'react-redux';

import { SpinnerView } from './SpinnerView';

/**
 *
 * transforms the current Redux store state into the props for
 * the wrapped presentational component
 *
 * @param state
 * @param ownProps
 * @returns {{}}
 */
const mapStateToProps = (state) => {
  return {
    isSpinning:
      state.loginReducer.isFetching ||
      state.changePasswordReducer.isFetching ||
      state.forgottenPasswordReducer.isFetching ||
      state.recreatePasswordReducer.isFetching,
  };
};

// connects the component to the Redux store:
// react-i18next High Order Component inject t and i18n in the component props
export default connect(mapStateToProps, null)(SpinnerView);
