import produce from 'immer';

import { toggleDirection } from '@helpers/deprecated/toggleDirection';
import { Sorting } from '@helpers/Sorting';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isFetching: false,
  groups: null,
  sortedGroups: null,
  fetchedAt: null,
  assignedGroups: null,
  order_by: 'name',
  order_direction: 'ASC',
  groupsMap: {},
};

export const groupsReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.GROUPS_ACTION_START:
        st.isFetching = true;
        break;
      case actionTypes.GROUPS_ACTION_SUCCESS: {
        let sortedGroups = sortGroups(action.groups, st.order_by);
        st.isFetching = false;
        st.groups = action.groups;
        st.sortedGroups = sortedGroups;
        st.groupsMap = action.groups.reduce((accumulator, group) => ({ ...accumulator, [group.id]: group }), {});
        break;
      }
      case actionTypes.GROUPS_ACTION_FAILURE:
        st.isFetching = false;
        break;
      case actionTypes.MANY_GROUPS_ACTION_SUCCESS:
        st.isFetching = false;
        st.assignedGroups = action.assignedGroups;
        break;
      case actionTypes.SORT_GROUP_LIST: {
        let order_direction = toggleDirection(action.order_by, action.order_direction);
        let newSortedGroups = sortGroups(state.sortedGroups, action.order_by);
        st.sortedGroups = newSortedGroups;
        st.order_by = action.order_by;
        st.order_direction = order_direction;
        break;
      }
      case actionTypes.REMOVE_GROUP_SUCCESS:
      case actionTypes.ADD_GROUP_SUCCESS:
      case actionTypes.UPDATE_GROUP_SUCCESS:
        st.isFetching = false;
        break;
      default:
        break;
    }
  });
};

function sortGroups(groups, prop) {
  return Sorting.sortAlphanumerically(groups, prop);
}
