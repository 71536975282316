import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box } from '@components/common/Box';
import {
  StyledDollarSign,
  StyledErrorMessage,
  StyledInput,
  StyledLabel,
  StyledWrapper,
} from '@components/form/InputText/styles';
import { InputMode, InputTextV2Props, InputTextV2Ref } from '@components/form/InputTextV2/types';
import { removeAllHTMLTags } from '@helpers/StringTransformers';

const INPUT_MODE_MAP = {
  money: 'decimal',
} as { [K in Exclude<InputMode, undefined>]: InputMode };

export const InputTextV2 = React.forwardRef<InputTextV2Ref, InputTextV2Props>(function InputTextV2(props, ref) {
  const {
    value,
    validators,
    errors: propErrors,
    inputMode: propInputMode,
    onChange: propOnChange,
    onTextChange,
    onValid,
    onInvalid,
    ...htmlAttrs
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasBeenSubmited, setHasBeenSubmited] = useState(false);

  const allErrors = useMemo(() => [...errors, ...(propErrors || [])], [errors, propErrors]);
  const hasErrors = allErrors.length > 0;
  const mayShowErrors = hasErrors && (hasChanged || hasBeenSubmited);
  const inputMode = useMemo(
    () => (INPUT_MODE_MAP[propInputMode ?? 'text'] ?? 'text') as HTMLAttributes<HTMLInputElement>['inputMode'],
    [propInputMode]
  );

  const getValue = useCallback((): string | undefined => {
    return inputRef.current?.value;
  }, []);

  const getInput = useCallback((): HTMLInputElement | undefined => {
    return inputRef.current ?? undefined;
  }, []);

  const getForm = useCallback((): HTMLFormElement | undefined => {
    return inputRef.current?.form ?? undefined;
  }, []);

  const triggerCallback = useCallback(
    (callback: any) => {
      callback && callback(getValue(), htmlAttrs.name);
    },
    [getValue, htmlAttrs.name]
  );

  const markInputAsChanged = useCallback(() => {
    setHasChanged(true);
  }, []);

  const markInputAsSubmited = useCallback(() => {
    setHasBeenSubmited(true);
  }, []);

  const validateInput = useCallback(() => {
    const input = getInput();
    const errorSet = new Set();

    for (const validator of validators || []) {
      const error = validator(getValue(), props);
      if (typeof error === 'string') {
        errorSet.add(error);
      }
    }

    const errors = Array.from(errorSet.values()) as string[];
    setErrors(errors);

    if (errors.length) {
      triggerCallback(onInvalid);
      input?.setCustomValidity(removeAllHTMLTags(errors[0]));
    } else {
      triggerCallback(onValid);
      input?.setCustomValidity('');
    }
  }, [getInput, getValue, onInvalid, onValid, props, triggerCallback, validators]);

  const onChange = useCallback(
    (e) => {
      propOnChange && propOnChange(e);
      triggerCallback(onTextChange);
      validateInput();
      markInputAsChanged();
    },
    [markInputAsChanged, propOnChange, onTextChange, triggerCallback, validateInput]
  );

  const nativelySetValue = useCallback(
    (value: string | undefined, options?: { triggerValidation?: boolean; triggerOnChange?: boolean }) => {
      const input = getInput();
      if (input && input.value !== value) {
        if (options?.triggerOnChange) {
          // This is a hack to force react to fire an on change
          // event, which already triggers the validation.
          const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
          valueSetter?.call(input, value ?? '');
          const event = new Event('input', { bubbles: true });
          input.dispatchEvent(event);
        } else {
          input.value = value ?? '';
          if (options?.triggerValidation) {
            validateInput();
          }
        }
      }
    },
    [getInput, validateInput]
  );

  useImperativeHandle<InputTextV2Ref | null, InputTextV2Ref | null>(ref, () => {
    const input = getInput();
    if (input) {
      const ref = input as InputTextV2Ref;
      ref.nativelySetValue = nativelySetValue;
      return ref;
    }
    return null;
  });

  useEffect(() => validateInput(), [validateInput]);

  // Listen to all other form inputs form 'invalid'
  // event. When it's called, it means the form was
  // invalidly submited.
  useLayoutEffect(() => {
    const form = getForm();
    const allFormInputs = Array.from(form?.elements ?? []) as Array<HTMLInputElement>;
    const unsubscribers = [] as Array<() => void>;

    for (const input of allFormInputs) {
      const handler = () => markInputAsSubmited();
      input.addEventListener('invalid', handler);
      unsubscribers.push(() => form?.removeEventListener('invalid', handler));
    }

    return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
  }, [getForm, hasErrors, markInputAsSubmited]);

  return (
    <div>
      <StyledWrapper hasError={mayShowErrors}>
        <StyledLabel isRequired={htmlAttrs.required} htmlFor={htmlAttrs.id}>
          {htmlAttrs.label}
        </StyledLabel>
        <Box paddingTopXXS alignItems={'center'}>
          {propInputMode === 'money' && !!getValue() && <StyledDollarSign>$</StyledDollarSign>}
          <StyledInput
            ref={inputRef}
            value={value}
            onChange={onChange}
            autoComplete={'off'}
            inputMode={inputMode}
            type={'text'}
            {...htmlAttrs}
          />
        </Box>
      </StyledWrapper>
      {mayShowErrors
        ? allErrors.map((error, index) => (
            <StyledErrorMessage
              key={index}
              id={htmlAttrs.name ? `${htmlAttrs.name}-error-message-${index}` : undefined}
              dangerouslySetInnerHTML={{ __html: error }}
            />
          ))
        : null}
    </div>
  );
});
