import * as UUID from 'uuid';

import { Api } from '@helpers/Api/Api';
import { ApiRequest, ApiRequestOptions, ApiResponse } from '@helpers/Api/types';

export class ApiClient {
  private requests: Record<string, ApiRequest> = {};

  async get(url: string, options?: ApiRequestOptions): Promise<ApiResponse> {
    return this.performCancelableRequest((extraOptions: ApiRequestOptions) =>
      Api.get(url, { ...options, ...extraOptions })
    );
  }

  public cancelPendingRequests() {
    Object.values(this.requests).forEach((request) => {
      this.cancelRequest(request);
      this.removeRequest(request);
    });
  }

  private async performCancelableRequest(
    handler: (extraOptions: ApiRequestOptions) => Promise<ApiResponse>
  ): Promise<ApiResponse> {
    const request = this.createRequest();
    const result = await handler({ signal: request.abortController.signal });
    this.removeRequest(request);
    return result;
  }

  private createRequest(): ApiRequest {
    const uuid = UUID.v4();
    this.requests[uuid] = { uuid, abortController: new AbortController() };
    return this.requests[uuid];
  }

  private removeRequest(request: ApiRequest) {
    delete this.requests[request.uuid];
  }

  private cancelRequest(request: ApiRequest) {
    this.requests[request.uuid].abortController.abort();
  }
}
