import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledPageHeader = styled(Box)(
  ({ theme }) => css`
    height: 72px;
    align-items: center;
    padding: 0 ${theme.spacing.md}px;
    background-color: ${theme.colors.coreWhite};
  `
);
