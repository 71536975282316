import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MENU_ITEMS from '@config/menu';
import { withGetScreen } from '@HOC/withGetScreen';
import { makeCloseMenuAction, makeLogoutRequestThunk, makeOpenModalAction } from '@redux/deprecated/actions';

// nectar:
import { MenuView } from './MenuView';

class Menu extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.handleEsc = this.handleEsc.bind(this);

    const { t } = this.props;

    // translating the menu labels:
    this.destinations = MENU_ITEMS.map((itemObj) => {
      return {
        ...itemObj,
        label: t(itemObj.label),
      };
    });
  }

  /**
   *
   */
  componentDidMount() {
    document.addEventListener('keydown', this.handleEsc, false);
  }

  /**
   *
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc, false);
  }

  handleEsc(e) {
    if ('Escape' === e.key) {
      this.hideMenu();
    }
  }

  handleClose(e) {
    if ('BUTTON' === e.target.nodeName.toUpperCase()) {
      e.preventDefault();
      const { func } = e.target.dataset;
      if ('=logout' === func) {
        const { logoutDispatch } = this.props;
        logoutDispatch();
      }
      if ('+contact' === func) {
        const { openContact } = this.props;
        openContact({ modalType: 'contact' });
      }
    } else {
      // it'a a link, just let the event propagate...
    }
    // close the big menu anyway:
    this.hideMenu();
  }

  /**
   *
   */
  hideMenu() {
    const { closeDispatch } = this.props;
    closeDispatch();
  }

  /**
   *
   * @returns {null|*}
   */
  render() {
    const { isMenuOpen, isMobile } = this.props;
    if (!isMenuOpen) {
      return null;
    }
    return <MenuView isMobile={isMobile()} destinations={this.destinations} handleClose={this.handleClose} />;
  }
}

/**
 *
 * @param dispatch
 * @returns {{closeDispatch: closeDispatch}}
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => dispatch(makeCloseMenuAction()),
  logoutDispatch: () => dispatch(makeLogoutRequestThunk()),
  openContact: (modalType) => {
    dispatch(makeOpenModalAction(modalType));
  },
});

/**
 *
 * @param state
 * @returns {{isMenuOpen: (*|boolean)}}
 */
const mapStateToProps = (state) => ({
  isMenuOpen: state.modalReducer.isMenuOpen,
});

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  logoutDispatch: PropTypes.func.isRequired,
  openContact: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(Menu))));
