import { getEventTypes } from './getEventTypes';

export const groupEventData = (eventData, type) => {
  // now let's group similar event types
  let v = [];
  let types = getEventTypes(type); //returns an array of strings to loop through depending on the type
  for (let x = 0; x <= types.length; x++) {
    let data = eventData.reduce((acc, i) => {
      if (!acc[i[types[x]]] && typeof i[types[x]] !== 'undefined') {
        acc[i[types[x]]] = [i];
      } else if (Array.isArray(acc[i[types[x]]])) {
        acc[i[types[x]]].push(i);
      } else if (typeof acc[i[types[x]]] === 'object') {
        acc[i[types[x]]] = [acc[i[types[x]]]];
        acc[i[types[x]]].push(i);
      }
      return acc;
    }, {});
    // Doesn't add a property for the type if there are no data
    Object.keys(data).length && v.push({ [`${types[x]}_${type}`]: data });
  }
  return v;
};
