import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { Button } from '@components/common/CTA';
import { Text } from '@components/common/Text';

export const StyledFilterButton = styled(Button)(
  () => `
  position: relative;
`
);

export const StyledFilterCount = styled(Text).attrs({ typography: 'TooltipSmall', weight: '600' })<{
  $isVisible: boolean;
}>(
  ({ theme, $isVisible }) => `
  position: absolute;
  top: -4px;
  right: -4px;
  
  min-width: 16px;
  border-radius: 8px;
  background-color: ${theme.colors.yellow02};
  color: ${theme.colors.grey08};
  
  transition: ${theme.animations.transitionMedium('transform')};
  transform: scale(${$isVisible ? 1 : 0});
`
);

export const StyledFiltersForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  background-color: ${theme.colors.coreWhite};
  border-radius: ${theme.shape.paperRadius01}px;
`
);

export const StyledFiltersFormHeader = styled(Box)(
  ({ theme }) => `
  overflow: hidden auto;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing.lg}px ${theme.spacing.md}px 0 ${theme.spacing.md}px;
  padding: 0 0 ${theme.spacing.md}px 0;
  border-bottom: ${theme.colors.grey04} 1px solid;
`
);

export const StyledFiltersFormContent = styled(Box).attrs({
  column: true,
  stretch: true,
})(
  ({ theme }) => `
  position: relative;
  height: 60vh;
  overflow: hidden auto;
  padding: 0 ${theme.spacing.md}px 0 ${theme.spacing.md}px;
`
);

export const StyledClearFiltersButton = styled(PrimaryButton).attrs({
  flat: true,
})(
  () => `
  padding: 0;
`
);

export const StyledClearFiltersButtonText = styled(Text).attrs({
  typography: 'SmallParagraph',
  weight: '600',
})(
  () => `
  text-transform: capitalize;
  text-decoration: underline;
`
);

export const StyledFilterSection = styled(Box).attrs({
  column: true,
})(
  ({ theme }) => `
  align-items: flex-start;
  padding: ${theme.spacing.md}px 0;
  
  &:not(:last-child) {
    border-bottom: ${theme.colors.grey04} 1px solid;
  }
`
);

export const StyledFilterSectionTitle = styled(Text).attrs({
  typography: 'Heading3',
  weight: '600',
})(() => ``);

export const StyledFilterSectionContent = styled(Box)(
  ({ theme }) => `
  width: 100%;
  flex-wrap: wrap;
  margin: -${theme.spacing.xs}px;
`
);

export const StyledFilterItem = styled(Box).attrs({
  tag: 'label',
})<{ isDisabled: boolean }>(
  ({ theme, isDisabled }) => css`
    width: 50%;
    align-items: normal;
    padding: ${theme.spacing.xs}px;
    cursor: ${isDisabled ? 'default' : 'pointer'};

    [role='text'] {
      max-width: calc(100% - ${theme.spacing.lg}px);
    }
  `
);
