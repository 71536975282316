import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import APP from '@config/constants';
import { PermissibleRender_deprecated } from '@HOC/withPermissions_deprecated';

import { PrimaryButton } from '../Buttons/PrimaryButton';
import { ModalContainer } from '../Elements/ModalElements';

import { BeeTrackLegendModalView } from './Partials/BeeTrackLegendModalView';
// nectar:
import { ContactModalView } from './Partials/ContactModalView';
import { LegendModalView } from './Partials/LegendModalView';

const StyledCloseButton = styled(PrimaryButton)`
  width: 110px;
  margin-right: 1.5rem;
  float: right;
`;

const buildModalContent = (paramObj, t, metric, isMobile, permissions) => {
  const { modalType } = paramObj;

  switch (modalType) {
    case 'contact':
      return <ContactModalView t={t} isMobile={isMobile} />;
    case 'legend':
      return (
        <PermissibleRender_deprecated
          userPermissions={permissions.names}
          requiredPermissions={[APP.permissions.hht]}
          renderOtherwise={<LegendModalView t={t} metric={metric} isMobile={isMobile} />}
          oneperm
        >
          {<BeeTrackLegendModalView t={t} isMobile={isMobile} />}
        </PermissibleRender_deprecated>
      );
    default:
      return null;
  }
};

const buildFooterContent = (paramObj, t, handleClose) => {
  const { modalType } = paramObj;

  switch (modalType) {
    case 'contact':
    case 'legend':
      return (
        <div style={{ width: '100%' }}>
          <StyledCloseButton type="button" label={t('close')} onClick={handleClose} disabled={false} height="2rem" />
        </div>
      );
    default:
      return null;
  }
};

export const StaticModalView = ({ t, isMobile, contentObj: paramObj, handleClose, metric, permissions }) => {
  const content = buildModalContent(paramObj, t, metric, isMobile, permissions);

  const footer = buildFooterContent(paramObj, t, handleClose);

  return (
    <ModalContainer isMobile={isMobile}>
      <section>{content}</section>
      <footer>{footer}</footer>
    </ModalContainer>
  );
};

StaticModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  contentObj: PropTypes.shape({
    modalType: PropTypes.string.isRequired,
  }).isRequired,
  metric: PropTypes.bool.isRequired,
};
