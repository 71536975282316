import React from 'react';
import PropTypes from 'prop-types';

//CSS from here : https://codepen.io/FlorinPop17/pen/MxPXOL?editors=1100
import './toggle.css';

export const Toggle = ({ id, checked, handleToggle }) => {
  return (
    <div className="checkbox-container">
      <input type="checkbox" name="toggle" id={id} value="toggle" checked={checked} onChange={handleToggle} />
      <label aria-labelledby="toggle" className={checked ? 'checked' : 'unchecked'} htmlFor={id}></label>
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};
