import styled from 'styled-components';

export const StyledHivesRatioWrapper = styled.span(
  ({ theme }) => `
    display: block;
    min-width: 18px;
    padding: 2px ${theme.spacing.xxs}px;
    background-color: ${theme.colors.grey04};
    margin-left: ${theme.spacing.xxs}px;
    border-radius: 2px;
    transition: ${theme.animations.transitionFast('background-color')};
    pointer-events: none;
  `
);
