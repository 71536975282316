import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@components/common/Box';
import { PrimaryButtonWithIcon } from '@components/common/Button';
import { Add } from '@components/common/Icon/presets/Add';
import { Loading } from '@components/common/Loading';
import { PagePaddingWrapper } from '@components/common/PageWrapper';
import { Pagination } from '@components/common/Pagination';
import { Text } from '@components/common/Text';
import { ModalFormContract } from '@components/pollination/FormContract/ModalFormContract';
import { PolliContractTable } from '@components/pollination/PolliContractTable';
import { useContracts } from '@hooks/useContract';
import { makeFetchContractsThunk, makeOpenContractModal } from '@redux/Contract/actions';

import { StyledNoContractsWrapper, StyledWrapper } from './styles';

const LIMIT = 20;
export const PolliContractsList: React.FC = () => {
  const { contracts, contractsStats, totalContracts, isFetchingContracts } = useContracts();
  const [pageIndex, setPageOffset] = useState(0);
  const dispatch = useDispatch();

  const openCreateModal = useCallback(() => {
    dispatch(makeOpenContractModal());
  }, [dispatch]);

  const changePage = (pageIndex: number) => {
    setPageOffset(pageIndex);
    dispatch(makeFetchContractsThunk({ offset: pageIndex * LIMIT, limit: LIMIT }));
  };

  const { t } = useTranslation();

  if (!contracts || isFetchingContracts) {
    return (
      <PagePaddingWrapper>
        <Loading />
      </PagePaddingWrapper>
    );
  }

  if (contracts.length) {
    return (
      <PagePaddingWrapper>
        <StyledWrapper>
          <Box justifyContent={'space-between'} paddingBottomMD marginBottomXS alignItems={'center'}>
            <Text typography="Heading1">{t('pollination_contracts')}</Text>
            <PrimaryButtonWithIcon onClick={() => openCreateModal()}>
              <Add size={24} />
              <span>{t('pollination_add_contract')}</span>
            </PrimaryButtonWithIcon>
            <ModalFormContract />
          </Box>

          <PolliContractTable contracts={contracts} contractsStats={contractsStats} totalContracts={totalContracts} />
          <Pagination
            changePage={changePage}
            itemLabel={t('contracts')}
            limit={LIMIT}
            pageIndex={pageIndex}
            totalItems={totalContracts ?? 0}
          />
        </StyledWrapper>
      </PagePaddingWrapper>
    );
  }

  return (
    <PagePaddingWrapper>
      <StyledNoContractsWrapper>
        <Box justifyContent={'center'} marginBottomXXS>
          <Text typography="Heading2" weight={'700'}>
            {t('no_pollination_contracts_yet')}
          </Text>
        </Box>
        <Text typography="SmallParagraph">{t('no_pollination_contracts_message')}</Text>
        <Box justifyContent={'center'} marginTopMD>
          <PrimaryButtonWithIcon onClick={() => openCreateModal()}>
            <Add size={24} />
            <span>{t('pollination_add_contract')}</span>
          </PrimaryButtonWithIcon>
          <ModalFormContract />
        </Box>
      </StyledNoContractsWrapper>
    </PagePaddingWrapper>
  );
};
