// currently only supports french and north american formats
export const formatPhoneNumber = (phone) => {
  if (!phone) return '-';
  switch (true) {
    // French
    case phone.startsWith('+33'):
      return '+## ## ## ## ## ##';
    // british
    case phone.startsWith('+44'):
      return '+## ## ## ## ## ##';
    // North American
    case phone.startsWith('+1'):
      return '+# ### ### ####';
    // Armenia
    case phone.startsWith('+374'):
      return '+### ## ######';
    // Mexico
    case phone.startsWith('+52'):
      return '+## ### ### ####';
    case phone.startsWith('+502'): // Guatemala
    case phone.startsWith('+504'): // Honduras
    case phone.startsWith('+505'): // Nicaragua
    case phone.startsWith('+506'): // Costa Rica
      return '+### #### ####';
    default:
      return '+## ## ### ####';
  }
};
