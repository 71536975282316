import styled from 'styled-components';

import { Accordion, AccordionHeader } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';

export const StyledCardWrapper = styled(Box)<{ $visible?: boolean }>(
  ({ theme, $visible }) => `
  position: absolute;
  bottom: 2rem;
  left: ${theme.spacing.sm}px;
  opacity: ${$visible ? 1 : 0};
  pointer-events: ${$visible ? 'auto' : 'none'};
  transition: ${theme.animations.transitionFast('opacity')};
`
);

export const StyledAccordion = styled(Accordion)(
  ({ theme }) => `
  border-radius: ${theme.shape.paperRadius02}px;
  overflow: hidden;
`
);

export const StyledAccordionHeader = styled(AccordionHeader)<{ $expanded?: boolean }>(
  ({ theme, $expanded }) => `
  justify-content: flex-start;
  width: 260px;
  
  & > svg {
    margin-right: ${theme.spacing.xs}px;
  }
  
  & > h3 {
    flex: 1;
    text-align: left;
  }
  
  &:before {
    left: -10px;
    right: -10px;
    opacity: ${$expanded ? 1 : 0};
    transition: ${theme.animations.transitionFast('opacity')};
  }
`
);

export const StyledOperationAVGCard = styled(Box)(
  ({ theme }) => `
  padding: 12px;
  border: ${theme.colors.grey05} 1px solid;
  border-radius: ${theme.shape.paperRadius01}px;
  position: relative;
  justify-content: space-between;
`
);

export const StyledProgressCard = styled(PrimaryButton).attrs({ flat: true })<{ $marginTop: boolean }>(
  ({ theme, $marginTop }) => `
  margin-top: ${$marginTop ? theme.spacing.sm : 0}px;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  text-transform: none;
  letter-spacing: 0px;
  
  &[disabled] {
    color: ${theme.colors.grey08};
  }
`
);
