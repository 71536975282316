import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Edit } from '@components/common/Icon/presets/Edit';
import { Hide } from '@components/common/Icon/presets/Hide';
import { Show } from '@components/common/Icon/presets/Show';
import { MapControls } from '@components/common/Map';
import { MenuItem } from '@components/common/Menu/types';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import { YardsMapLayer } from '@components/yard/YardsMap/types';
import { StyledManageYardsButton, StyledMapTopRightControls } from '@components/yard/YardsMapControls/styles';

export const YardsMapControls: React.VFC = () => {
  const { t } = useTranslation();
  const { yards, activeLayers, toggleLayer, goToYardManagement, recenterMap } = useYardsMap();
  const theme = useTheme();

  // Can only recenter map if there are yards.
  const recenterMapAction = yards.length ? recenterMap : undefined;

  const layerMenuItems = useMemo<Array<MenuItem>>(() => {
    const layers = [
      { layer: YardsMapLayer.BEE_FLIGHT_RADIUS, showText: 'show_bee_fly', hideText: 'hide_bee_fly' },
      { layer: YardsMapLayer.LAST_INSPECTIONS, showText: 'show_last_visit', hideText: 'hide_last_visit' },
      { layer: YardsMapLayer.POLLINATION_BLOCKS, showText: 'show_blocks', hideText: 'hide_blocks' },
    ];
    return layers.map(({ layer, showText, hideText }) => ({
      title: activeLayers[layer] ? t(hideText) : t(showText),
      icon: activeLayers[layer] ? Hide : Show,
      onClick: () => setTimeout(() => toggleLayer(layer), theme.animations.durationMedium),
    }));
  }, [activeLayers, t, toggleLayer, theme]);

  return (
    <>
      <StyledMapTopRightControls>
        <StyledManageYardsButton onClick={goToYardManagement}>
          <Edit size={24} />
          {t('manage_yards')}
        </StyledManageYardsButton>
      </StyledMapTopRightControls>

      <MapControls showMapTypeToggle recenterMap={recenterMapAction} extraMenuItems={layerMenuItems} />
    </>
  );
};
