import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { BREAKPOINTS } from '@style/constants';

function generateScreenWidthBooleans() {
  return {
    isMobile: window.matchMedia(`(max-width: ${BREAKPOINTS.tablet - 1}px)`).matches,
    isTablet: window.matchMedia(`(min-width: ${BREAKPOINTS.tablet}px) and (max-width: ${BREAKPOINTS.laptop - 1}px)`)
      .matches,
    isTabletOrSmaller: window.matchMedia(`(max-width: ${BREAKPOINTS.laptop - 1}px)`).matches,
    isLaptop: window.matchMedia(`(min-width: ${BREAKPOINTS.laptop}px) and (max-width: ${BREAKPOINTS.desktop - 1}px)`)
      .matches,
    isDesktop: window.matchMedia(`(min-width: ${BREAKPOINTS.desktop}px)`).matches,
  };
}

export function useGetScreenWidth() {
  const [screenWidthBooleans, setScreenWidthBooleans] = useState(generateScreenWidthBooleans);

  useEffect(() => {
    const handleResize = debounce(() => setScreenWidthBooleans(generateScreenWidthBooleans()), 150);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenWidthBooleans;
}
