const SCREEN_CLASSES = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export const fromScreenClassAndUp = (screenClass) => {
  validateScreenClass(screenClass);
  return SCREEN_CLASSES.slice(SCREEN_CLASSES.indexOf(screenClass));
};

export const fromScreenClassAndDown = (screenClass) => {
  validateScreenClass(screenClass);
  return SCREEN_CLASSES.slice(0, SCREEN_CLASSES.indexOf(screenClass) + 1);
};

const validateScreenClass = (screenClass) => {
  if (!SCREEN_CLASSES.includes(screenClass)) {
    throw new Error(`"${screenClass}" is an invalid screen class. Available are ${SCREEN_CLASSES.join(', ')}.`);
  }
};
