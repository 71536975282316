import { Reducer } from 'redux';

import { FetchState } from '@redux/types';

export type OperationReducer = Reducer<OperationState, OperationAction>;

export type OperationAction =
  | { type: OperationActionType.FETCH_START | OperationActionType.FETCH_FAILURE }
  | { type: OperationActionType.FETCH_SUCCESS; payload: BeeOperation }
  | { type: OperationActionType.PATCH_START | OperationActionType.PATCH_FAILURE }
  | { type: OperationActionType.PATCH_SUCCESS; payload: BeeOperation };

export type OperationState = FetchState<{
  operation: BeeOperation | null;
}>;

export enum OperationActionType {
  FETCH_START = 'OPERATION_FETCH_START',
  FETCH_SUCCESS = 'OPERATION_FETCH_SUCCESS',
  FETCH_FAILURE = 'OPERATION_FETCH_FAILURE',
  PATCH_START = 'OPERATION_PATCH_START',
  PATCH_SUCCESS = 'OPERATION_PATCH_SUCCESS',
  PATCH_FAILURE = 'OPERATION_PATCH_FAILURE',
}
