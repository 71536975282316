import React, { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputPill, Item } from '@components/common/InputSelector';
import { InputProps } from '@components/form/types';
import { useCropTypes, useCropTypesDictionary } from '@hooks/useCropTypes';

export const InputCropType = ({ name, id = name, value, onChange, disabled }: InputProps<Array<number>>) => {
  const [selectedCropIds, setSelectedCropIds] = useState<Key[]>(() => value.map(String));
  const { t } = useTranslation();

  const { cropTypes } = useCropTypes();
  const cropTypesDictionary = useCropTypesDictionary();

  useEffect(() => {
    if (value.join('-') !== selectedCropIds.join('-')) {
      onChange(
        selectedCropIds.map((key) => parseInt(key as string)),
        name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, onChange, selectedCropIds]);

  useEffect(() => {
    if (value.join('-') !== selectedCropIds.join('-')) {
      setSelectedCropIds(value.map(String));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <InputPill
      label={t('crop_type_input_label')}
      disabledKeys={selectedCropIds}
      selectedKeys={selectedCropIds}
      setSelectedKeys={setSelectedCropIds}
      placeholder={t('crop_type_input_placeholder')}
      dataDictionary={cropTypesDictionary}
      id={id}
      isDisabled={disabled}
      name={name}
    >
      {cropTypes.map((crop) => (
        <Item key={crop.id} title={crop.name}>
          {crop.name}
        </Item>
      ))}
    </InputPill>
  );
};
