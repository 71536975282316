import { useContext } from 'react';

import { YardsListSaveViewContext, YardsListSaveViewContextValue } from '../context';

export function useYardsListSaveView(): YardsListSaveViewContextValue {
  const yardsListSaveView = useContext(YardsListSaveViewContext);

  if (!yardsListSaveView) {
    throw new Error("Can't find a parent yards list save view context.");
  }

  return yardsListSaveView;
}
