import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';

import { useColumnNameGetter } from '@components/yard/YardsList/hooks/useColumnNameGetter';
import { useColumnViewNameGetter } from '@components/yard/YardsList/hooks/useColumnViewNameGetter';
import { ColumnType } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';

export function useColumnNameForAnalytics(columnDef: ColDef) {
  const getter = useColumnNameGetterForAnalytics();
  return getter(columnDef);
}

export function useColumnNameGetterForAnalytics() {
  const getColumnName = useColumnNameGetter();
  const getViewName = useColumnViewNameGetter();

  return useCallback(
    (columnDef: ColDef) => {
      const columnDefWithMetadata = GridApiUtil.getColumnDefWithMetadata(columnDef);
      const column = columnDefWithMetadata.metadata?.column;
      const columnName = getColumnName(columnDef, columnDef.headerName);
      const activeViewName = getViewName(columnDef);
      let columnDescriptor;

      if (column?.type === ColumnType.PRACTICE) {
        columnDescriptor = `${column.practice.categoryName}/${column.practice.name}/${activeViewName}`;
      } else if (column?.type === ColumnType.PRACTICE_CATEGORY) {
        columnDescriptor = `${column.practiceCategory.name}/${activeViewName}`;
      } else {
        columnDescriptor = columnName as string;
      }

      return columnDescriptor;
    },
    [getColumnName, getViewName]
  );
}
