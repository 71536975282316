import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Sorting } from '@helpers/Sorting';
import { useCropTypes } from '@hooks/useCropTypes';
import { useGroups } from '@hooks/useGroups';
import { useYardsFilters } from '@redux/YardsFilters/hooks';
import {
  YardsFiltersCategoryKey,
  YardsFiltersFlagValuesKey,
  YardsFiltersLastInspectionIntervalValuesKey,
  YardsFiltersStatusValuesKey,
  YardsFilterValuesKey,
} from '@redux/YardsFilters/types';

interface FilterValueDefinition {
  label: string;
  tooltip?: string;
  valueKey: YardsFilterValuesKey;
}

interface FilterDefinition {
  title: string;
  canSelectOnlyOneValue?: boolean;
  tooltip?: string;
  categoryKey: YardsFiltersCategoryKey;
  values: FilterValueDefinition[];
}

export function useYardsFiltersDefinitions(): FilterDefinition[] {
  const { t } = useTranslation();
  const { availableFilters } = useYardsFilters();

  const groups = useGroups();
  const { getCropTypesVisualString } = useCropTypes();

  const getGroupName = useCallback(
    (groupId: string) => {
      return groups.find(({ id }) => String(id) === groupId)?.name;
    },
    [groups]
  );

  const isFilterKnown = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: any) => {
      const values = (availableFilters[categoryKey]?.values ?? {}) as Record<string, boolean>;
      return valueKey in values;
    },
    [availableFilters]
  );

  return useMemo(() => {
    const yardStatusKey = YardsFiltersStatusValuesKey;
    const yardStatusCategory = {
      title: t('yard_status'),
      canSelectOnlyOneValue: true,
      categoryKey: YardsFiltersCategoryKey.YARD_STATUS,
      values: [
        { label: t('yards_all'), valueKey: yardStatusKey.ALL_YARDS },
        { label: t('inactive_yards'), tooltip: t('tooltip_inactive_yards'), valueKey: yardStatusKey.INACTIVE_YARDS },
        { label: t('active_yards'), tooltip: t('tooltip_active_yards'), valueKey: yardStatusKey.ACTIVE_YARDS },
      ].filter(({ valueKey }) => isFilterKnown(YardsFiltersCategoryKey.YARD_STATUS, valueKey)),
    };

    const yardFlagKey = YardsFiltersFlagValuesKey;
    const yardFlagCategory = {
      title: t('yard_flags'),
      categoryKey: YardsFiltersCategoryKey.YARD_FLAG,
      values: [
        {
          label: t('requiring_attention'),
          tooltip: t('tooltip_requiring_attention'),
          valueKey: yardFlagKey.REQUIRING_ATTENTION,
        },
        { label: t('new_yards'), valueKey: yardFlagKey.NEW_YARDS },
        { label: t('with_queenless'), valueKey: yardFlagKey.QUEEN_LESS },
      ].filter(({ valueKey }) => isFilterKnown(YardsFiltersCategoryKey.YARD_FLAG, valueKey)),
    };

    const lastInsIntervalKey = YardsFiltersLastInspectionIntervalValuesKey;
    const lastInspectionIntervalCategory = {
      title: t('last_visits'),
      categoryKey: YardsFiltersCategoryKey.LAST_INSPECTION_INTERVAL,
      values: [
        {
          label: t('last_visit_range', { from: 0, to: 15 }),
          valueKey: lastInsIntervalKey.BETWEEN_00_15,
        },
        {
          label: t('last_visit_range', { from: 16, to: 30 }),
          valueKey: lastInsIntervalKey.BETWEEN_16_30,
        },
        {
          label: t('last_visit_range', { from: 31, to: 90 }),
          valueKey: lastInsIntervalKey.BETWEEN_31_90,
        },
        {
          label: t('last_visit_range_upper_limit', { from: 91 }),
          valueKey: lastInsIntervalKey.BETWEEN_91_INF,
        },
      ].filter(({ valueKey }) => isFilterKnown(YardsFiltersCategoryKey.LAST_INSPECTION_INTERVAL, valueKey)),
    };

    const yardTypeValuesKeys = Object.keys(availableFilters[YardsFiltersCategoryKey.YARD_TYPE]?.values ?? {});
    const yardTypeValues = yardTypeValuesKeys.map((valueKey) => ({
      label: t(valueKey),
      valueKey,
    }));
    const yardTypeCategory = {
      title: t('yard_type'),
      tooltip: t('yard_type_tooltip'),
      categoryKey: YardsFiltersCategoryKey.YARD_TYPE,
      values: yardTypeValues,
    };

    const groupValuesKeys = Object.keys(availableFilters[YardsFiltersCategoryKey.GROUP]?.values ?? {});
    const groupValues = groupValuesKeys
      .map((valueKey) => ({
        label: getGroupName(valueKey)?.trim() ?? '',
        valueKey,
      }))
      .filter((x) => Boolean(x.label));
    const groupValuesSorted = Sorting.sortAlphanumerically(groupValues, 'label');
    const groupCategory = {
      title: t('group'),
      categoryKey: YardsFiltersCategoryKey.GROUP,
      values: groupValuesSorted,
    };

    const cropTypeValuesKeys = Object.keys(availableFilters[YardsFiltersCategoryKey.CROP_TYPE]?.values ?? {});
    const cropTypeValues = cropTypeValuesKeys.map((valueKey) => ({
      label: getCropTypesVisualString([parseInt(valueKey)])?.trim(),
      valueKey,
    }));
    const cropTypeValuesSorted = Sorting.sortAlphanumerically(cropTypeValues, 'label');
    const cropTypeCategory = {
      title: t('crop_type'),
      tooltip: t('crop_type_filter_help_text'),
      categoryKey: YardsFiltersCategoryKey.CROP_TYPE,
      values: cropTypeValuesSorted,
    };

    return [
      yardStatusCategory,
      yardFlagCategory,
      lastInspectionIntervalCategory,
      yardTypeCategory,
      groupCategory,
      cropTypeCategory,
    ].filter(({ values }) => values.length);
  }, [availableFilters, getCropTypesVisualString, getGroupName, isFilterKnown, t]);
}
