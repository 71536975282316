import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';

const StyledCellText = styled(Text).attrs({
  typography: 'SmallParagraph',
})(
  () => css`
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // It needs to be greater than 1.0,
    // otherwise it will cut some letters.
    line-height: 1.05rem;
  `
);

export const DefaultCellText: React.FC<Partial<TextProps>> = ({ children, ...props }) => {
  return (
    <StyledCellText {...props} typography={'SmallParagraph'}>
      {children}
    </StyledCellText>
  );
};
