import { useState } from 'react';

import i18n from '../../src/i18n';

interface TranslationOptions {
  lng: BeeSupportedLangCode;
}
const defaultOptions = {
  lng: 'en',
};

export type Translate = (key: string, variables?: any) => string;

export function useTranslation(options?: TranslationOptions): Translate {
  const tOptions = { ...defaultOptions, ...options };
  const [lng, setLng] = useState(tOptions.lng);
  return (key: string, variables?: any) => i18n.t(key, { lng, ...variables });
}
