import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import * as uuid from 'uuid';

import { useModalContext, useModalDeferredOpenState } from '@components/common/Modal/hooks';
import { ModalBaseProps } from '@components/common/Modal/types';

import { StyledModalBackground, StyledModalContentWrapper, StyledModalWrapper } from './styles';

export const ModalBase: React.FC<ModalBaseProps> = ({
  isOpen: propIsOpen,
  shouldCloseOnOverlayClick,
  onRequestClose,
  onFocus,
  children,
}) => {
  const [id] = useState(() => uuid.v4());
  const { addModalToStack, popModalFromStack, getModalIndex, getModalReverseIndex } = useModalContext();
  const isOpen = useModalDeferredOpenState(propIsOpen);

  useEffect(() => {
    if (isOpen) {
      addModalToStack(id);
      return () => popModalFromStack(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const stackIndex = getModalIndex(id);
  const stackReverseIndex = getModalReverseIndex(id);

  useEffect(() => {
    const tId = setTimeout(() => {
      if (isOpen && stackReverseIndex === 0) {
        onFocus && onFocus();
      }
    }, 180);
    return () => clearTimeout(tId);
  }, [isOpen, onFocus, propIsOpen, stackReverseIndex]);

  return createPortal(
    <StyledModalWrapper $visible={isOpen} $stackIndex={stackIndex} $stackReverseIndex={stackReverseIndex}>
      <StyledModalBackground onClick={shouldCloseOnOverlayClick ? onRequestClose : undefined} />
      <StyledModalContentWrapper $stackReverseIndex={stackReverseIndex}>{children}</StyledModalContentWrapper>
    </StyledModalWrapper>,
    document.body,
    id
  );
};
