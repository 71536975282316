import styled from 'styled-components';

export const StyledButton = styled.button(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 48px;
  border: none;
  outline: none;
  border-radius: ${theme.shape.paperRadius01}px;
  text-transform: uppercase;
  
  &:focus-visible { 
    box-shadow: 0 0 4px 2px ${theme.colors.coreFocus02}; 
  }
`
);

export const StyledButtonFlat = styled(StyledButton)(
  ({ theme }) => `
  background-color: ${theme.colors.coreWhite};
  color: ${theme.colors.grey08};
  
  :hover {
    color: ${theme.colors.grey06};
  }
  
  &[disabled] {
    color: ${theme.colors.grey05};
  }
`
);

export const StyledButtonPrimary = styled(StyledButton)(
  ({ theme }) => `
  background-color: ${theme.colors.grey08};
  color: ${theme.colors.coreWhite};
  
  :hover {
    color: ${theme.colors.grey05};
  }
`
);
