import React from 'react';
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import { StyledCloseButton, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import { Fieldset } from '@components/deprecated/Fieldset/Fieldset';
import KeepAddingComponent from '@components/deprecated/KeepAddingComponent';
// nectar:
import { TextInput } from '@components/deprecated/TextInput/TextInput';

import { ModalContainer, StyledLabel } from '../Elements/ModalElements';

import '@components/deprecated/Dropdown/dropdown.css';

const ErrorMsg = styled.div`
  color: ${(props) => props.theme.criticalColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  line-height: 19px;
  margin-top: 1rem;
`;

export const BeekPracticesModalView = ({
  t,
  isMobile,
  contentObj,
  handleChange,
  errorMsg,
  errorMsgSpanish,
  handleClose,
  handleSubmit,
  isDisabledBtn,
  keepAdding,
  handleKeepAdding,
}) => {
  const { title, instructions, action, modalType, buttonText, dropdown } = contentObj;

  const keepAddingComponent = 'create-practice' === modalType && (
    <KeepAddingComponent t={t} isMobile={isMobile} handleKeepAdding={handleKeepAdding} keepAdding={keepAdding} />
  );

  const isThereError = errorMsg || errorMsgSpanish;

  return (
    <ModalContainer isMobile={isMobile} smallModal={'remove-practice' === modalType}>
      <section>
        <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
          {title}
        </StyledHeading2>
        <StyledParagraph style={{ marginBottom: 'remove-practice' !== modalType && '1.5rem' }}>
          {instructions}
        </StyledParagraph>

        {'remove-practice' !== modalType && (
          <Row nogutter>
            <Col
              style={{
                paddingRight: !isMobile ? '0.5rem' : 0,
                paddingBottom: isMobile ? '1rem' : 0,
              }}
              xs={12}
              sm={6}
            >
              <Fieldset>
                <TextInput
                  id="detail"
                  name="detail"
                  required
                  value={action ? action.detail : ''}
                  defaultValue="e.g. Sugar syrup"
                  label={t('practice_english')}
                  handleChange={handleChange}
                  type="text"
                  error={errorMsg}
                />
              </Fieldset>
            </Col>
            <Col xs={12} sm={6} style={{ paddingLeft: !isMobile ? '0.5rem' : 0 }}>
              <Fieldset>
                <TextInput
                  id="detail_spanish"
                  name="detail_spanish"
                  required
                  value={action ? action.detail_spanish : ''}
                  defaultValue="e.g. Jarabe de azúcar"
                  label={t('practice_spanish')}
                  handleChange={handleChange}
                  type="text"
                  error={errorMsgSpanish}
                />
              </Fieldset>
            </Col>
          </Row>
        )}

        {isThereError && <ErrorMsg>{isThereError}</ErrorMsg>}

        {isMobile && keepAddingComponent}
      </section>
      <footer>
        {!isMobile && keepAddingComponent}

        <div>
          <StyledCloseButton type="button" label={t('cancel')} onClick={handleClose} disabled={false} />

          <StyledSubmitButton
            type="button"
            style={{ marginRight: 'remove-practice' === modalType && 0 }}
            label={buttonText}
            onClick={handleSubmit}
            disabled={isDisabledBtn && 'remove-practice' !== modalType}
            height="2rem"
          />
        </div>
      </footer>
    </ModalContainer>
  );
};

BeekPracticesModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    action: PropTypes.object,
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    modalType: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    dropdown: PropTypes.object,
  }).isRequired,
  errorMsg: PropTypes.string.isRequired,
  errorMsgSpanish: PropTypes.string.isRequired,
  handleKeepAdding: PropTypes.func.isRequired,
  keepAdding: PropTypes.bool.isRequired,
};
