import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Layers = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.463 4.10899L21.428 8.57699C21.7789 8.75077 22.0009 9.10845 22.0009 9.49999C22.0009 9.89153 21.7789 10.2492 21.428 10.423L12.463 14.891C12.1714 15.036 11.8286 15.036 11.537 14.891L2.572 10.423C2.22113 10.2492 1.99913 9.89153 1.99913 9.49999C1.99913 9.10845 2.22113 8.75077 2.572 8.57699L11.537 4.10899C11.8286 3.96399 12.1714 3.96399 12.463 4.10899ZM11.594 19.914C11.7217 19.971 11.8601 20.0003 12 20C12.1399 20.0003 12.2782 19.971 12.406 19.914L21.406 15.914C21.7325 15.7689 21.9569 15.4607 21.9945 15.1054C22.0322 14.7501 21.8774 14.4017 21.5885 14.1914C21.2997 13.9811 20.9205 13.9409 20.594 14.086L12 17.905L3.406 14.086C2.90121 13.8618 2.31022 14.0892 2.086 14.594C1.86177 15.0988 2.08921 15.6898 2.594 15.914L11.594 19.914Z"
      />
    </IconSVGView>
  );
};
