import styled from 'styled-components';

export const StyledNoContractsWrapper = styled.div`
  max-width: 516px;
  max-height: 228px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 40px;
  background-color: white;
  text-align: center;
  border-radius: 8px;
`;

export const StyledWrapper = styled.div(
  () => `
    padding: 4.5rem 0;
  `
);

export const StyledLoaderWrapper = styled.div(
  ({ theme }) => `
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: rgb(255 255 255 / 100%);
  z-index: ${theme.getZIndexOf('highLevelIndex')};
  
  & > div {
    width: 192px;
  }
`
);
