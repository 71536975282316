import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  IdentifiableItem,
  ItemChangeEvent,
  QueryParamsItemSelectionOptions,
  QueryParamsItemSelectionReturn,
} from '@helpers/QueryParams/types';

import { Params, ParamsSetter } from './types';
import { QueryParams } from '.';

/**
 * Helper for item selection based on query params.
 *
 * Example:
 *     const [selectedItem, setSelectedItemId] = useQueryParamsItemSelection({ availableItems });
 *
 *     setSelectedItemId(10);
 *     setSelectedItemId(null);
 * */
export function useQueryParamsItemSelection<T extends IdentifiableItem>({
  paramName,
  availableItems,
  autoClearSelection,
  onSelectionChange,
}: QueryParamsItemSelectionOptions<T>): QueryParamsItemSelectionReturn<T> {
  const [params, setParams] = useQueryParamsState();
  const selectedItemId = params[paramName] || null;
  const lastSetItemId = useRef<any>(null);

  const getItemFromID = useCallback(
    (id: any) => {
      // Avoid iterating over all items if not necessary.
      if (id !== null) {
        return Object.values(availableItems).find((item) => String(item.id) === String(id)) ?? null;
      }
      return null;
    },
    [availableItems]
  );

  const selectedItem = useMemo(() => getItemFromID(selectedItemId), [getItemFromID, selectedItemId]);

  const setSelectedItem = useCallback(
    (item: T | null) => {
      // Used to detect if the change comes from setState or url navigation.
      lastSetItemId.current = item?.id ?? null;

      setParams((curr: any) => ({ ...curr, [paramName]: item?.id ?? null }));
    },
    [paramName, setParams]
  );

  useEffect(() => {
    const lastSetItem = getItemFromID(lastSetItemId.current);
    const changeEvent: ItemChangeEvent<T> = {
      item: selectedItem,
      origin: lastSetItem?.id === selectedItem?.id ? 'programmatic' : 'navigation',
    };
    onSelectionChange && onSelectionChange(changeEvent);
    lastSetItemId.current = null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?.id]);

  useEffect(() => {
    // Auto clearing the selection in case the current
    // item id is not found in the available items.
    if (autoClearSelection && selectedItemId) {
      const isSelectedIdAvailable = getItemFromID(selectedItemId) !== null;
      if (!isSelectedIdAvailable) {
        setSelectedItem(null);
      }
    }
  }, [autoClearSelection, getItemFromID, selectedItemId, setSelectedItem]);

  return [selectedItem, setSelectedItem];
}

/**
 * Equivalent to useState, it saves the given string value to the
 * current url at the given param name.
 *
 * Example:
 *     const [itemId, setItemId] = useQueryParamState('selected-item');
 *
 *     setItemId('10');
 *     // Current URL would be /?selected-item=10
 *
 * Note: if the same url params are set from multiple calls, all states will respond to it.
 * */
export function useQueryParamState(paramName: string, defaultValue = ''): [string, Dispatch<SetStateAction<string>>] {
  const [params, setParams] = useQueryParamsState({ [paramName]: defaultValue });

  const state = useMemo(() => params[paramName] ?? '', [paramName, params]);
  const setState = useCallback<Dispatch<SetStateAction<string>>>(
    (valueOrFunc) => {
      if (typeof valueOrFunc === 'function') {
        setParams((current) => ({ ...current, [paramName]: valueOrFunc(current[paramName]) }));
      } else {
        setParams((current) => ({ ...current, [paramName]: valueOrFunc }));
      }
    },
    [paramName, setParams]
  );

  return [state, setState];
}

/**
 * Equivalent to useState, it saves the given dictionary to the
 * current url as params.
 *
 * Example:
 *     type Params = { search: string };
 *     const [params, setParams] = useQueryParams<Params>();
 *
 *     setParams(() => ({ search: "ABC" });
 *     // Current URL would be /?search=ABC
 *
 * Note: if the same url params are set from multiple calls, all states will respond to it.
 * */
export function useQueryParamsState<T extends Params = Params>(defaultValue?: T): [T, ParamsSetter<T>] {
  const [params, setParams] = useState<T>(() => {
    const value = QueryParams.getCurrentQueryParams<T>();
    return !Object.entries(value).length && defaultValue ? defaultValue : value;
  });

  useEffect(() => QueryParams.addListener<T>(setParams), []);

  const setter = useCallback<ParamsSetter<T>>((getter) => {
    const nextParams = getter(QueryParams.getCurrentQueryParams<T>());
    QueryParams.setCurrentQueryParams(nextParams);
  }, []);

  return [params, setter];
}
