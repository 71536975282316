/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { useListBox, useOption } from 'react-aria';
import { ListState } from 'react-stately';
import styled from 'styled-components';

import type { AriaListBoxOptions } from '@react-aria/listbox';
import type { Node } from '@react-types/shared';

import { Check } from '../Icon';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

const StyledItem = styled.li<{ isFocused: boolean; isDisabled: boolean }>(
  ({ theme, isFocused, isDisabled }) => `
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    background-color: ${isFocused ? theme.colors.grey02 : theme.colors.coreWhite};
    color: ${isDisabled ? theme.colors.grey06 : theme.colors.grey07};
    padding: 10px ${theme.spacing.sm}px;
    border: 1px solid ${theme.colors.grey03};  
  `
);
const StyledUl = styled.ul(
  ({ theme }) => `
    max-height: 200px;
    overflow-y: auto;
  `
);

export function ListBox(props: ListBoxProps) {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <StyledUl {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </StyledUl>
  );
}

function Option({ item, state }: OptionProps) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isFocused, isDisabled } = useOption(
    {
      key: item.key,
    },
    state,
    ref
  );

  return (
    <StyledItem {...optionProps} ref={ref} isFocused={isFocused} isDisabled={isDisabled}>
      {item.rendered}
      {isDisabled && (
        <span>
          <Check />
        </span>
      )}
    </StyledItem>
  );
}
