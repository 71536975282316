import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Search = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7436 14.0336H15.5336L19.7736 18.2936C20.1657 18.7082 20.1566 19.3596 19.7531 19.7631C19.3496 20.1666 18.6982 20.1757 18.2836 19.7836L14.0336 15.5336V14.7436L13.7636 14.4636C11.1054 16.7456 7.11866 16.5181 4.73729 13.9485C2.35591 11.3788 2.43179 7.38638 4.90908 4.90908C7.38638 2.43179 11.3788 2.35591 13.9485 4.73729C16.5181 7.11866 16.7456 11.1054 14.4636 13.7636L14.7436 14.0336ZM5.37616 11.2557C6.07268 12.9372 7.71354 14.0336 9.53362 14.0336C10.7276 14.0352 11.8731 13.5616 12.7174 12.7174C13.5616 11.8731 14.0352 10.7276 14.0336 9.53362C14.0336 7.71354 12.9372 6.07268 11.2557 5.37616C9.57416 4.67965 7.63863 5.06465 6.35164 6.35164C5.06465 7.63863 4.67965 9.57416 5.37616 11.2557Z"
      />
    </IconSVGView>
  );
};
