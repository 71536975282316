import React from 'react';
import { useTheme } from 'styled-components';

import { Close } from '@components/common/Icon';
import { useModalDeferredOpenState } from '@components/common/Modal/hooks';
import { useColor } from '@style/theme/hooks';

import { ModalBase } from './ModalBase';
import { StyledModalClose, StyledModalMeerkat } from './styles';
import { ModalMeerkatProps } from './types';

const DEF_MODAL_MEERKAT_SIZE = 544;

export const ModalMeerkat: React.FC<ModalMeerkatProps> = ({
  backgroundColor: propBackgroundColor,
  children,
  ...props
}) => {
  const theme = useTheme();
  const width = DEF_MODAL_MEERKAT_SIZE;
  const backgroundColor = useColor(propBackgroundColor, theme.colors.coreWhite);
  const isOpen = useModalDeferredOpenState(props.isOpen);

  return (
    <ModalBase shouldCloseOnOverlayClick {...props}>
      <StyledModalMeerkat $width={width} $backgroundColor={backgroundColor} open={isOpen}>
        {children}
        <StyledModalClose onClick={props.onRequestClose}>
          <Close size={24} />
        </StyledModalClose>
      </StyledModalMeerkat>
    </ModalBase>
  );
};
