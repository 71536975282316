const DEF_ANIMATION_STEP = 0.025;

export type Easing = (progress: number) => number;
export type StyleGetter = (progress: number) => string;

export const Animation = {
  createElasticCSSKeyframes,
  createCSSKeyframes,
};

function createElasticCSSKeyframes(name: string, getStyle: StyleGetter): string {
  return createCSSKeyframes(name, getStyle, easeOutElastic);
}

function createCSSKeyframes(
  name: string,
  getStyle: StyleGetter,
  easing: Easing = easeLinear,
  step = DEF_ANIMATION_STEP
): string {
  const steps = [] as Array<string>;
  for (let x = 0; x < 1 + step; x += step) {
    steps.push(`\t${(x * 100).toFixed(1)}% { ${getStyle(easing(x))} }`);
  }
  return `@keyframes ${name} {\n${steps.join('\n')}\n}`;
}

function easeOutElastic(x: number): number {
  const c4 = (2 * Math.PI) / 3;
  return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}

function easeLinear(x: number): number {
  return x;
}
