import { IMapProps } from 'google-maps-react';

import { Loading } from '@components/common/Loading';
import { MapType } from '@components/common/Map/types';
import { GMAP_API_KEY } from '@config/google';

export const DEFAULT_GOOGLE_MAPS_RETRY_INTERVAL = 4000;
export const DEFAULT_GOOGLE_MAPS_DEBOUNCE = 400;
export const DEFAULT_GOOGLE_MAPS_MIN_POLYGON_LENGTH = 3;
export const DEFAULT_GOOGLE_MAPS_STYLES = {
  withLabels: [
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
    { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  ],
  withoutLabels: [
    { elementType: 'labels', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.land_parcel', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.neighborhood', stylers: [{ visibility: 'off' }] },
    { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#f5f5f5' }] },
    { featureType: 'road', elementType: 'geometry.stroke', stylers: [{ visibility: 'off' }] },
  ],
  withoutLabelsNorRoad: [
    { elementType: 'labels', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.land_parcel', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.neighborhood', stylers: [{ visibility: 'off' }] },
    { featureType: 'road', stylers: [{ visibility: 'off' }] },
  ],
};
export const DEFAULT_GOOGLE_MAPS_TYPE_MAP = {
  [MapType.TERRAIN]: { typeId: 'custom-tr', styles: DEFAULT_GOOGLE_MAPS_STYLES.withLabels },
  [MapType.TERRAIN_SIMPLIFIED]: { typeId: 'custom-ts', styles: DEFAULT_GOOGLE_MAPS_STYLES.withoutLabels },
  [MapType.SATELLITE]: { typeId: 'custom-st', styles: DEFAULT_GOOGLE_MAPS_STYLES.withLabels },
  [MapType.SATELLITE_SIMPLIFIED]: { typeId: 'custom-ss', styles: DEFAULT_GOOGLE_MAPS_STYLES.withoutLabelsNorRoad },
};
export const DEFAULT_GOOGLE_MAPS_API_OPTIONS = {
  apiKey: GMAP_API_KEY ?? '',
  LoadingContainer: Loading,
  language: 'en',
  region: 'CA',
  libraries: ['drawing', 'places'],
};
export const DEFAULT_GOOGLE_MAPS_OPTIONS: Omit<IMapProps, 'google'> = {
  tilt: 0,
  minZoom: 2,
  maxZoom: 21,
  isFractionalZoomEnabled: true,
  scaleControl: true,
  disableDefaultUI: true,
  gestureHandling: 'auto',
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
};

export const DEFAULT_GOOGLE_MAPS_POLYGON = {
  clickable: true,
  fillColor: '#64CEE5',
  strokeColor: '#64CEE5',
  strokeOpacity: 1,
  strokeWeight: 3,
};

export const DEFAULT_GOOGLE_MAPS_IDLE_POLYGON = {
  ...DEFAULT_GOOGLE_MAPS_POLYGON,
  fillOpacity: 0.2,
};

export const DEFAULT_GOOGLE_MAPS_SELECTED_POLYGON = {
  ...DEFAULT_GOOGLE_MAPS_POLYGON,
  fillOpacity: 0.6,
};
