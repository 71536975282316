import { useCallback, useEffect, useRef } from 'react';
import { GridApi } from 'ag-grid-community';

export function useYardsListDataCycle(
  gridApi: GridApi | null,
  options?: {
    /** Called any time the grid data change. */
    onDataLoad?: (gridApi: GridApi) => void;

    /** Called before the first server call. */
    onDataPreLoad?: (gridApi: GridApi) => void;

    /** Called after the first server call. */
    onDataServerLoad?: (gridApi: GridApi) => void;

    /** Called after each subsequent server calls. */
    onDataServerReload?: (gridApi: GridApi) => void;
  }
) {
  const dataLoadCount = useRef(0);

  const handleDataUpdated = useCallback(() => {
    if (gridApi) {
      const isDataPreLoad = dataLoadCount.current === 0;
      const isDataFirstServerLoad = dataLoadCount.current === 1;

      dataLoadCount.current++;

      options?.onDataLoad && options.onDataLoad(gridApi);

      if (isDataPreLoad) {
        options?.onDataPreLoad && options.onDataPreLoad(gridApi);
      } else if (isDataFirstServerLoad) {
        options?.onDataServerLoad && options.onDataServerLoad(gridApi);
      } else {
        options?.onDataServerReload && options.onDataServerReload(gridApi);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, options?.onDataLoad, options?.onDataPreLoad, options?.onDataServerLoad, options?.onDataServerReload]);

  useEffect(() => {
    if (gridApi) {
      const handler = () => {
        const isGridLoading =
          gridApi.getRenderedNodes().length > 0 && gridApi.getRenderedNodes()[0]?.data === undefined;
        !isGridLoading && handleDataUpdated();
      };
      dataLoadCount.current === 0 && handleDataUpdated(); // Call for the very first load.
      gridApi.addEventListener('modelUpdated', handler);
      return () => gridApi.removeEventListener('modelUpdated', handler);
    }
  }, [gridApi, handleDataUpdated]);
}
