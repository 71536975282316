import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Hide = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.71 4.54001C2.32228 4.14997 2.32228 3.52005 2.71 3.13001C3.10301 2.74003 3.73699 2.74003 4.13 3.13001L20.44 19.46C20.7798 19.8568 20.7569 20.4482 20.3876 20.8176C20.0182 21.1869 19.4268 21.2098 19.03 20.87L16.31 18.15C13.3942 19.2976 10.1417 19.2383 7.26963 17.9851C4.3976 16.732 2.14193 14.388 1 11.47C1.76844 9.52143 3.03792 7.8104 4.68 6.51001L2.71 4.54001ZM16.0379 8.47839C15.0941 7.19751 13.5909 6.44986 12 6.47001C11.5009 6.47821 11.0057 6.55906 10.53 6.71001L8.36 4.54001C14.3012 2.62636 20.7075 5.65446 23 11.46C22.3141 13.2008 21.2241 14.7535 19.82 15.99L16.76 12.93C17.2505 11.4165 16.9817 9.75928 16.0379 8.47839ZM12 16.47C9.23858 16.47 7 14.2314 7 11.47C6.99976 10.7288 7.16727 9.99723 7.49 9.33001L9.06 10.9C9.02415 11.088 9.00408 11.2787 9 11.47C9 13.1269 10.3431 14.47 12 14.47C12.192 14.4671 12.383 14.4436 12.57 14.4L14.14 15.97C13.4748 16.2995 12.7423 16.4706 12 16.47ZM14.97 11.14C14.8226 9.74758 13.7224 8.64739 12.33 8.50001L14.97 11.14Z"
      />
    </IconSVGView>
  );
};
