import { AsyncTask, AsyncTaskPoolOptions } from '@helpers/AsyncTaskPool/types';

/**
 * Helper class to execute a list of async tasks in a given number
 * of parallel sequences.
 * */
export class AsyncTaskPool {
  constructor(private readonly options: AsyncTaskPoolOptions) {}

  public async execute(): Promise<void> {
    const { tasks, threads } = this.options;
    const pool = [...tasks].reverse();
    let error: any = null;

    const promises = new Array(threads).fill(null).map(
      () =>
        new Promise<void>((resolve) => {
          setTimeout(async () => {
            let task: AsyncTask | undefined;
            while (!error && (task = pool.pop())) {
              try {
                await task();
              } catch (error_) {
                error = error_;
                break;
              }
            }
            resolve();
          });
        })
    );

    await Promise.all(promises);

    if (error) {
      throw error;
    }
  }
}
