import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Outdoor = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M19.621 13.5679L17.267 14.1119L13.887 11.9999L17.267 9.88789L19.621 10.4319C20.1592 10.5561 20.6962 10.2206 20.8205 9.68239C20.9448 9.14418 20.6092 8.60715 20.071 8.48289L18.288 8.07089L18.7 6.28789C18.808 5.75676 18.4732 5.23629 17.9451 5.1143C17.417 4.99232 16.8879 5.31322 16.752 5.83789L16.208 8.19189L13 10.1999V6.41389L14.708 4.70589C14.968 4.45481 15.0722 4.08301 14.9807 3.73338C14.8892 3.38375 14.6161 3.1107 14.2665 3.01919C13.9169 2.92767 13.5451 3.03193 13.294 3.29189L12 4.58589L10.706 3.29189C10.4549 3.03193 10.0831 2.92767 9.73349 3.01919C9.38386 3.1107 9.11082 3.38375 9.0193 3.73338C8.92779 4.08301 9.03204 4.45481 9.292 4.70589L11 6.41389V10.1999L7.793 8.19989L7.249 5.83789C7.11309 5.31322 6.58395 4.99232 6.05587 5.1143C5.52779 5.23629 5.19301 5.75676 5.301 6.28789L5.712 8.07089L3.929 8.48289C3.40433 8.61879 3.08343 9.14793 3.20542 9.67601C3.32741 10.2041 3.84788 10.5389 4.379 10.4309L6.733 9.88789L10.113 11.9999L6.733 14.1119L4.379 13.5679C3.8408 13.4436 3.30376 13.7792 3.1795 14.3174C3.05524 14.8556 3.3908 15.3926 3.929 15.5169L5.712 15.9289L5.3 17.7119C5.19201 18.243 5.52679 18.7635 6.05487 18.8855C6.58295 19.0075 7.11209 18.6866 7.248 18.1619L7.792 15.8079L11 13.7999V17.5819L9.292 19.2939C8.91303 19.6863 8.91845 20.31 9.30418 20.6957C9.68992 21.0814 10.3136 21.0869 10.706 20.7079L12 19.4139L13.294 20.7079C13.6864 21.0869 14.3101 21.0814 14.6958 20.6957C15.0816 20.31 15.087 19.6863 14.708 19.2939L13 17.5859V13.7999L16.207 15.7999L16.751 18.1539C16.8869 18.6786 17.416 18.9995 17.9441 18.8775C18.4722 18.7555 18.807 18.235 18.699 17.7039L18.288 15.9209L20.071 15.5089C20.4192 15.4285 20.698 15.1685 20.8024 14.8268C20.9069 14.4851 20.8211 14.1136 20.5774 13.8523C20.3337 13.591 19.9692 13.4795 19.621 13.5599V13.5679Z"
      />
    </IconSVGView>
  );
};
