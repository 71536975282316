// pass the value to an array of arrays, map through it and return an array to which this value belongs

export const arrayByMatch = (array, value) => {
  const getArray = ({ x, y }) => array.find((a) => a.some((o) => o.x === x && o.y === y));
  return getArray(value);
};

export const isEmptyArray = (array) => array === undefined || (array && array.length === 0);

export const deepClone = (el) => JSON.parse(JSON.stringify(el));

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const findAndReplaceById = (group, updated) => {
  let g = deepClone(group);
  const { id } = updated;
  let j = g.findIndex((g) => g.id === id);
  if (j >= 0) g.splice(j, 1, updated);
  return g;
};

export const findReplaceOrAdd = (group, updated) => {
  let g = deepClone(group);
  const { id } = updated;
  let j = g.findIndex((g) => g.id === id);
  if (j >= 0) g.splice(j, 1, updated);
  else g.push(updated);
  return g;
};
