import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as TimelineEmptyImage } from '@assets/timeline/empty-timeline.svg';
import { Box } from '@components/common/Box';

export const StyledTimelineEmpty = styled(Box)(
  () => css`
    margin-top: 187px;
  `
);

export const StyledTimelineEmptyImageWrapper = styled(Box)(
  ({ theme }) => css`
    position: relative;
    width: 146px;
    height: 146px;
    border-bottom-right-radius: 123px;
    border-bottom-left-radius: 123px;
    overflow: hidden;
    margin-bottom: ${theme.spacing.xs}px;
  `
);

export const StyledTimelineEmptyImageBackground = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    border-radius: 123px;
    overflow: hidden;
    background: linear-gradient(180deg, ${theme.colors.grey08} -150%, ${theme.colors.coreBackground} 100%);
  `
);

export const StyledTimelineEmptyImage = styled(TimelineEmptyImage)(
  ({ theme }) => css`
    position: absolute;
    width: 146px;
    height: 146px;
    top: 8px;
    left: -2px;

    animation-name: ${EmptyImageKeyframes};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ${theme.animations.easing};
  `
);

export const EmptyImageKeyframes = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
`;
