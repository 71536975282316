import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Bold,
  DropColumn,
  StyledCol,
  StyledDesc,
  StyledHeader,
  StyledImg,
  StyledRow,
} from '@components/deprecated/Elements/EventsElements';
// nectar
import { getEventTypes } from '@helpers/deprecated/getEventTypes';
import { getIconByType } from '@helpers/deprecated/icons';
import theme from '@style/theme';

export const EventsView = ({ t, alertView, eventObj }) => {
  const { yard_name, event_details, type, analyzed_at } = eventObj;

  // for sensor events
  const buildCustomEventString = (id, name) => {
    let bh_name = name.split('-');
    switch (id) {
      case 'no_transmission_bc':
        return (
          <>
            {t('event_beecon', { hive: name })}
            <Bold>{t('no_transmission')}</Bold>.
          </>
        );
      case 'low_battery_bc':
        return (
          <>
            {t('event_beecon_low_battery', { hive: name })}
            <Bold>{t('low_battery')}</Bold>.
          </>
        );
      case 'low_data_rate_bc':
        return (
          <>
            {t('event_beecon', { hive: name })}
            <Bold>{t('low_data_rate')}</Bold>.
          </>
        );
      case 'no_transmission_bh':
        return (
          <>
            {t('event_beehub', { beehub: bh_name[1] ? bh_name[1] : bh_name[0] })}
            <Bold>{t('no_transmission_since_days')}</Bold>.
          </>
        );
      case 'not_charging_bh':
        return (
          <>
            {t('event_beehub', { beehub: bh_name[1] ? bh_name[1] : bh_name[0] })}
            <Bold>{t('not_charging')}</Bold>.
          </>
        );
      case 'new_beehub_bh':
        return t('event_beehub_new_yard');
      case 'deadout_ai':
        return (
          <>
            {t('event_detected', { hive: name })}
            <Bold>{t('deadout')}</Bold>.
          </>
        );
      case 'queenless_ai':
        return (
          <>
            {t('event_detected', { hive: name })}
            <Bold>{t('queenless')}</Bold>.
          </>
        );
      default:
        return null;
    }
  };

  return getEventTypes(type).map((item) => {
    const id = `${item}_${type}`;
    return event_details.map(
      (b) =>
        b[id] &&
        b[id].true &&
        b[id].true.length < 3 &&
        b[id].true.map((c, idx) => (
          <StyledRow key={idx} style={{ paddingLeft: 'dropdown' === alertView && 0 }}>
            {'dropdown' === alertView && (
              <DropColumn xs={3}>
                <StyledImg src={getIconByType('events', id)} alt={id} />
              </DropColumn>
            )}
            <StyledCol xs={'dropdown' === alertView ? 9 : 12}>
              {('summary' === alertView || 'dropdown' === alertView) && <StyledHeader>{yard_name}</StyledHeader>}
              <StyledDesc>
                {buildCustomEventString(id, 'bh' === type ? c.hostname : c.hive_name)}
                {'dropdown' === alertView && (
                  <p style={{ color: theme.supportingColor }}>{moment(analyzed_at).local().fromNow()}</p>
                )}
              </StyledDesc>
            </StyledCol>
          </StyledRow>
        ))
    );
  });
};

EventsView.propTypes = {
  t: PropTypes.func.isRequired,
  alertView: PropTypes.string,
  eventObj: PropTypes.shape({
    yard_name: PropTypes.string,
    yard_id: PropTypes.number.isRequired,
    event_details: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    type: PropTypes.string.isRequired,
    analyzed_at: PropTypes.string,
  }).isRequired,
};
