import { DynamicIcon } from '@components/common/Icon/types';
import { SVG } from '@helpers/SVG';

export const LastInspectionDot: DynamicIcon<[{ color?: string; size: number }]> = {
  getImageURI({ color, size }) {
    const cacheKey = `${color}-${size}`;
    return SVG.generateCachedSvgUri(cacheKey, () => {
      return `
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="${size}" height="${size}" 
          viewBox="0 0 ${size} ${size}">
          <circle 
            r="${size / 2 - 1}" cx="50%" cy="50%" fill="${color}" stroke="white" stroke-width="1" opacity="1.0" />
        </svg>
      `;
    });
  },
};
