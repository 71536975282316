import React, { HTMLAttributes, useMemo } from 'react';
import styled, { Color, useTheme } from 'styled-components';

export interface ProgressBarProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'color'> {
  /**
   * Current progress from 0 to 1.
   * Defaults to 0;
   * */
  progress?: number;

  /**
   * Current progress color. Besides
   * the color theme key, it also accepts
   * a function to return the color given
   * the current progress.
   * */
  color?: Color | ((progress: number) => Color);
}

/**
 * Note: <span/> is being used instead of the
 * native <progressbar/> component in order to
 * get better customization.
 * */
export const StyledProgress = styled.span<{ progress: number; color: string }>(
  ({ theme, color, progress }) => `
  position: relative;
  height: 6px;
  overflow: hidden;
  border-radius: 3px;
  background-color: ${theme.colors.grey04};
  
  &::before {
    content: "";
    position: absolute;
    background-color: ${color};
    transition: ${theme.animations.transitionLong('right', 'background-color')};
    top: 0;
    left: 0;
    right: ${100 * (1 - progress)}%;
    bottom: 0;
  }
`
);

export const ProgressBar: React.VFC<ProgressBarProps> = ({
  progress: propsProgress,
  color: propsColor,
  ...spanProps
}) => {
  const theme = useTheme();

  const progress = propsProgress ?? 0.0;

  const color = useMemo(() => {
    let colorKey: Color;

    if (!propsColor) {
      colorKey = 'green02';
    } else if (typeof propsColor === 'function') {
      colorKey = propsColor(progress);
    } else {
      colorKey = propsColor;
    }

    return theme.colors[colorKey];
  }, [progress, propsColor, theme.colors]);

  return <StyledProgress progress={progress} color={color} role="progressbar" {...spanProps} />;
};
