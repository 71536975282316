import React from 'react';

import { LastInspectionDot } from '@components/common/Icon/dynamic/LastInspectionDot';
import { Marker } from '@components/common/Map';
import { MarkerDescriptor } from '@components/common/Map/types';
import { useColor } from '@style/theme/hooks';

export function YardLastInspectionDotRenderer(props: { color?: string } & MarkerDescriptor<BeeYardOnMapInfo>) {
  const { data: yard, color: propColor, ...extraProps } = props;
  const [lng, lat] = yard.yardCenter.coordinates;
  const color = useColor(propColor);

  return <Marker {...extraProps} position={{ lat, lng }} icon={LastInspectionDot.getImageURI({ color, size: 18 })} />;
}
