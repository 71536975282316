import { css } from 'styled-components';

import { from } from './mediaQueries';

export const Heading1 = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.heading1MobileFontSize};
  letter-spacing: 0;
  font-weight: ${(props) => props.theme.fontWeightRegular};
  word-break: break-word;

  ${from('tablet')} {
    font-size: props.theme.heading1FontSize;
    line-height: 2.5rem;
  }
`;

export const Heading2 = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => (props.mobile ? props.theme.heading2MobileFontSize : props.theme.heading2FontSize)};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: 0;
  line-height: ${(props) => (props.mobile ? '2rem' : '36px')};
  word-break: break-word;
`;

export const Heading3 = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: 0;
  line-height: 1.5rem;
  word-break: break-word;
`;

export const Paragraph = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.paragraphFontSize};
  font-weight: ${(props) => props.theme.fontWeightRegular};
  letter-spacing: 0;
  line-height: 1.5rem;
  word-break: break-word;
`;

export const ParagraphSmall = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.paragraphSmallFontSize};
  font-weight: ${(props) => props.theme.fontWeightRegular};
  letter-spacing: 0;
  line-height: 1.5rem;
  word-break: break-word;
`;

export const RowsNumbers = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.rowsNumbersFontSize};
  font-weight: ${(props) => props.theme.fontWeightRegular};
  letter-spacing: 1px;
  line-height: 1.5rem;
  word-break: break-word;
`;

export const CaptionSmall = css`
  color: ${(props) => props.theme.supportingColor};
  font-size: ${(props) => props.theme.captionSmallFontSize};
  font-weight: ${(props) => props.theme.fontWeightRegular};
  letter-spacing: 0;
  line-height: 1rem;
  word-break: break-word;
`;

export const PrimaryCTA = css`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${(props) => props.theme.primaryCTAFontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: 1px;
  line-height: 1rem;
  text-transform: uppercase;
  word-break: break-word;
`;

export const PrimaryCTAInverse = css`
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  font-size: ${(props) => props.theme.primaryCTAFontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: 1px;
  line-height: 1rem;
  text-transform: uppercase;
  word-break: break-word;
`;
