import { MapTile } from './types';

export class MapTileMatrix<P> {
  private matrix: Record<string, MapTile<P>> = {};

  constructor(private tileSize: number) {}

  public forEach(handler: (tile: MapTile<P>) => void) {
    Object.values(this.matrix).forEach(handler);
  }

  public getAtLatLng(lat: number, lng: number): MapTile<P> {
    const { i, j } = this.getIJFromLatLng(lat, lng);
    return this.getAtIJ(i, j);
  }

  public getAtIJ(i: number, j: number): MapTile<P> {
    const key = this.getKeyFromIJ(i, j);
    if (!this.matrix[key]) {
      this.matrix[key] = {
        index: { i, j },
        size: this.tileSize,
        elements: {},
      };
    }
    return this.matrix[key];
  }

  private getIJFromLatLng(lat: number, lng: number) {
    const i = Math.floor(lat / this.tileSize);
    const j = Math.floor(lng / this.tileSize);
    return { i, j };
  }

  private getKeyFromIJ(i: number, j: number): string {
    return `${i}-${j}`;
  }
}
