import React, { useCallback, useEffect, useState } from 'react';

import { ModalMeerkat } from '@components/common/Modal';
import { HiveModalInnerContext } from '@components/hive/HiveModal/context';
import { useHiveModalOpener } from '@components/hive/HiveModal/hooks';
import { HiveModalContent } from '@components/hive/HiveModalContent';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

export const HiveModal: React.VFC = () => {
  const { openedHiveModals, closeHiveModal } = useHiveModalOpener();
  const [openModalsStack, setOpenModalsStack] = useState<Array<{ id: number; isOpen: boolean; isFocused: boolean }>>(
    []
  );

  const markAsFocused = useCallback((index: number) => {
    setOpenModalsStack((curr) => {
      if (curr[index] && !curr[index].isFocused) {
        curr[index].isFocused = true;
        return [...curr];
      }
      return curr;
    });
  }, []);

  useEffect(() => {
    setOpenModalsStack((stack) => {
      const nextStack = [...stack];
      const nextStackWithoutStaleModals = nextStack.filter(({ isOpen }) => isOpen);
      const nextStackWithUpdatedOpenState = nextStackWithoutStaleModals.map((entry, index) => ({
        ...entry,
        isOpen: openedHiveModals[index] === entry.id,
      }));
      return [
        ...nextStackWithUpdatedOpenState,
        ...openedHiveModals.slice(nextStackWithUpdatedOpenState.length).map((id) => ({
          id,
          isOpen: true,
          isFocused: false,
        })),
      ];
    });
  }, [openedHiveModals]);

  useEffect(() => {
    // TODO: Add analytics when data is downloaded and visible
    const isDataAvailableAndVisible = false;
    if (isDataAvailableAndVisible) {
      Analytics.sendEvent({
        event: AnalyticsEventType.HIVE_DETAILS_VIEW,
      });
    }
  }, []);

  return (
    <>
      {openModalsStack.map(({ id, isOpen, isFocused }, index) => (
        <ModalMeerkat
          key={`${id}-${index}`}
          isOpen={isOpen}
          onRequestClose={() => closeHiveModal(id)}
          onFocus={() => markAsFocused(index)}
        >
          <HiveModalInnerContext.Provider value={{ id: id, isOpen, isFocused }}>
            <HiveModalContent />
          </HiveModalInnerContext.Provider>
        </ModalMeerkat>
      ))}
    </>
  );
};
