import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Collapse } from '@components/common/Icon/presets/Collapse';
import { GroupPage } from '@components/common/Icon/presets/GroupPage';
import { HelpFull } from '@components/common/Icon/presets/HelpFull';
import { Logo } from '@components/common/Icon/presets/Logo';
import { Logout } from '@components/common/Icon/presets/Logout';
import { Operation } from '@components/common/Icon/presets/Operation';
import { Pollination } from '@components/common/Icon/presets/Pollination';
import { Settings } from '@components/common/Icon/presets/Settings';
import { Yard } from '@components/common/Icon/presets/Yard';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import config from '@config/config';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import { makeLogoutRequestThunk } from '@redux/deprecated/actions';

import { HelpModal } from './HelpModal';
import {
  StyledCollapseButton,
  StyledFixedContent,
  StyledFooter,
  StyledFooterBox,
  StyledFooterText,
  StyledInitialsCircle,
  StyledLogoutButton,
  StyledLogoWrap,
  StyledNavButton,
  StyledNavLink,
  StyledNavSeparator,
  StyledNavText,
  StyledNavWrapper,
  StyledSVGWrap,
} from './styles';

export const SideNav: React.VFC<any> = () => {
  const [expanded, setExpanded] = useState(true);
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const dispatch = useDispatch();
  const t = useTranslation();

  const {
    first_name: firstName,
    last_name: lastName,
    operation_name: operationName,
  } = useSelector((state) => state.accountReducer.user);

  const toggleSideNav = useCallback(() => setExpanded((curr) => !curr), []);
  const openHelpModal = useCallback(() => setHelpModalOpen(true), []);
  const closeHelpModal = useCallback(() => setHelpModalOpen(false), []);
  const logout = useCallback(() => dispatch(makeLogoutRequestThunk()), [dispatch]);

  const initials = `${firstName[0] ?? ''}${lastName[0] ?? ''}`.toUpperCase();

  return (
    <>
      <div>
        <StyledNavWrapper $expanded={expanded}>
          <StyledFixedContent $expanded={expanded}>
            <header>
              <Box paddingSM marginBottomMD justifyContent={'center'} alignItems="center">
                <StyledLogoWrap to={APP.routes.whiteboardYardsList} $expanded={expanded}>
                  <Logo width={95} />
                </StyledLogoWrap>

                <StyledCollapseButton
                  $expanded={expanded}
                  onClick={toggleSideNav}
                  id="nav-item-expand"
                  aria-labelledby="nav-tooltip-expand"
                >
                  <Collapse size={24} />
                </StyledCollapseButton>
                <Tooltip target="nav-item-expand" placement="right" offset={[0, 24]} light>
                  <Text typography={'CaptionSmall'} id="nav-tooltip-expand">
                    {t(expanded ? 'collapse' : 'expand')}
                  </Text>
                </Tooltip>
              </Box>
            </header>
            <nav>
              <StyledNavLink to={APP.routes.whiteboardYardsList} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-whiteboard">
                  <Yard />
                </StyledSVGWrap>
                <StyledNavText>{t('whiteboard')}</StyledNavText>
              </StyledNavLink>

              <StyledNavSeparator />

              <StyledNavLink to={APP.routes.pollination} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-pollination">
                  <Pollination />
                </StyledSVGWrap>
                <StyledNavText>{t('pollination')}</StyledNavText>
              </StyledNavLink>

              <StyledNavLink to={APP.routes.groups} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-groups">
                  <GroupPage />
                </StyledSVGWrap>
                <StyledNavText>{t('groups')}</StyledNavText>
              </StyledNavLink>

              <StyledNavSeparator />

              <StyledNavLink to={APP.routes.operation} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-operation">
                  <Operation />
                </StyledSVGWrap>
                <StyledNavText>{t('operation')}</StyledNavText>
              </StyledNavLink>

              <StyledNavLink to={APP.routes.settings} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-settings">
                  <Settings />
                </StyledSVGWrap>
                <StyledNavText>{t('settings')}</StyledNavText>
              </StyledNavLink>

              <StyledNavButton $expanded={expanded} onClick={openHelpModal}>
                <StyledSVGWrap id="nav-item-help">
                  <HelpFull />
                </StyledSVGWrap>
                <StyledNavText>{t('help')}</StyledNavText>
              </StyledNavButton>

              <Tooltip target="nav-item-whiteboard" placement="right" offset={[0, 24]} disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('whiteboard')}</Text>
              </Tooltip>
              <Tooltip target="nav-item-pollination" placement="right" offset={[0, 24]} disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('pollination')}</Text>
              </Tooltip>
              <Tooltip target="nav-item-groups" placement="right" offset={[0, 24]} disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('groups')}</Text>
              </Tooltip>
              <Tooltip target="nav-item-operation" placement="right" offset={[0, 24]} disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('operation')}</Text>
              </Tooltip>
              <Tooltip target="nav-item-settings" placement="right" offset={[0, 24]} disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('settings')}</Text>
              </Tooltip>
              <Tooltip target="nav-item-help" placement="right" offset={[0, 24]} disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('help')}</Text>
              </Tooltip>
            </nav>
            <StyledFooter $expanded={expanded}>
              <Box block paddingHorizontalSM>
                <Text typography="CaptionSmall" color="grey06">
                  {config.VERSION}
                </Text>
              </Box>

              <StyledNavSeparator />
              <StyledFooterBox alignItems="center" paddingHorizontalSM marginBottomMD>
                <StyledInitialsCircle>
                  <span>{initials}</span>
                </StyledInitialsCircle>
                <StyledFooterText>
                  <Text typography="SmallParagraph" lineHeight="24px">
                    {firstName} {lastName}
                  </Text>
                  <Text typography="CaptionSmall" color="grey06">
                    {operationName}
                  </Text>
                </StyledFooterText>

                <StyledLogoutButton id="nav-item-logout" onClick={logout} aria-labelledby="nav-tooltip-logout">
                  <Logout size={expanded ? 16 : 24} />
                </StyledLogoutButton>
              </StyledFooterBox>
              <Tooltip target="nav-item-logout" placement="right" offset={[0, 24]}>
                <Text typography={'CaptionSmall'} id="nav-tooltip-logout">
                  {t('logout')}
                </Text>
              </Tooltip>
            </StyledFooter>
          </StyledFixedContent>
        </StyledNavWrapper>
      </div>

      <HelpModal isOpen={helpModalOpen} onRequestClose={closeHelpModal} />
    </>
  );
};
