import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { CropTypeAction, CropTypeActionType } from '@redux/CropType/types';

export const makeFetchCropTypesStartAction = (): CropTypeAction => ({
  type: CropTypeActionType.FETCH_START,
});

export const makeFetchCropTypesSuccessAction = (payload: Array<BeeCropType>): CropTypeAction => ({
  type: CropTypeActionType.FETCH_SUCCESS,
  payload,
});

export const makeFetchCropTypesFailureAction = (): CropTypeAction => ({
  type: CropTypeActionType.FETCH_FAILURE,
});

export const makeFetchCropTypesOnceThunk = Thunk.createTakeLast(() => {
  return async (dispatch, getState) => {
    if (!getState().cropTypeReducer.cropTypes.length) {
      dispatch(makeFetchCropTypesThunk());
    }
  };
});

export const makeFetchCropTypesThunk = Thunk.createTakeLast(() => {
  return async (dispatch) => {
    dispatch(makeFetchCropTypesStartAction());

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.cropTypes));

    if (response.error) {
      dispatch(makeFetchCropTypesFailureAction());
    } else {
      dispatch(makeFetchCropTypesSuccessAction(await response.json()));
    }
  };
});
