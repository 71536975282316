// vendor
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseIcon from '@assets/Small_Close.svg';
// nectar
import { getFastTransition } from '@helpers/deprecated/animation';

const PRESETS = {
  close: {
    icon: CloseIcon,
  },
  'close-top-right': {
    icon: CloseIcon,
    style: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
    },
  },
};

const Button = styled.button`
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
  transition: ${({ theme }) => getFastTransition(theme)};

  & * {
    pointer-events: none;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.buttonIconHoverColor};
  }

  &:focus {
    background-color: ${(props) => props.theme.buttonIconFocusColor};
  }

  &:active {
    background-color: ${(props) => props.theme.buttonIconActiveColor};
  }
`;

export const IconButton = ({ icon, preset, onClick }) => {
  const _preset = useMemo(() => {
    return PRESETS[preset] ?? { icon, style: null };
  }, [preset, icon]);

  return (
    <Button onClick={onClick} style={_preset.style}>
      <img src={_preset.icon} alt={'button icon'} />
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.any,
  preset: PropTypes.oneOf(Object.keys(PRESETS)),
  onClick: PropTypes.func,
};
