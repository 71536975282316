import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Yard = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.222 3C9.06427 2.99992 8.9184 3.08371 8.839 3.22L8.061 4.554C8.02103 4.62188 7.99996 4.69923 8 4.778V10.111C8.00594 10.5995 8.4005 10.9941 8.889 11H15.111C15.5995 10.9941 15.9941 10.5995 16 10.111V4.778C16 4.69923 15.979 4.62188 15.939 4.554L15.161 3.22C15.0816 3.08371 14.9357 2.99992 14.778 3H9.222ZM9.477 4H14.523L15.041 4.778H8.959L9.477 4ZM12.889 6H11.111C10.8737 6.01126 10.6871 6.20693 10.6871 6.4445C10.6871 6.68207 10.8737 6.87774 11.111 6.889H12.889C13.1263 6.87774 13.3129 6.68207 13.3129 6.4445C13.3129 6.20693 13.1263 6.01126 12.889 6ZM3.375 12C3.19714 11.9999 3.03264 12.0944 2.943 12.248L2.068 13.748C2.02342 13.8245 1.99996 13.9115 2 14V20C2.00382 20.5507 2.4493 20.9962 3 21H10C10.5507 20.9962 10.9962 20.5507 11 20V14C11 13.9115 10.9766 13.8245 10.932 13.748L10.057 12.248C9.96736 12.0944 9.80286 11.9999 9.625 12H3.375ZM3.662 13H9.338L9.921 14H3.079L3.662 13ZM7.5 15H5.5C5.22386 15 5 15.2239 5 15.5C5 15.7761 5.22386 16 5.5 16H7.5C7.77614 16 8 15.7761 8 15.5C8 15.2239 7.77614 15 7.5 15ZM13.943 12.248C14.0326 12.0944 14.1971 11.9999 14.375 12H20.625C20.8029 11.9999 20.9674 12.0944 21.057 12.248L21.932 13.748C21.9766 13.8245 22 13.9115 22 14V20C21.9962 20.5507 21.5507 20.9962 21 21H14C13.4493 20.9962 13.0038 20.5507 13 20V14C13 13.9115 13.0234 13.8245 13.068 13.748L13.943 12.248ZM20.338 13H14.662L14.079 14H20.921L20.338 13ZM16.5 15H18.5C18.7761 15 19 15.2239 19 15.5C19 15.7761 18.7761 16 18.5 16H16.5C16.2239 16 16 15.7761 16 15.5C16 15.2239 16.2239 15 16.5 15Z"
      />
    </IconSVGView>
  );
};
