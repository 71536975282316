import { useCallback } from 'react';

import { useQueryParamsModalOpener } from '@components/common/Modal/hooks';
import { ActivityFilters } from '@redux/Timeline/types';

const ALERT_TYPE_PARAM_MAP: Record<BeeActivityByRequestAlertType, string> = {
  new_inspection: 'ni',
  hive_added_to_yard: 'ha',
  hive_managed_to_yard: 'hm',
};
const ALERT_TYPE_PARAM_MAP_REVERSED = Object.entries(ALERT_TYPE_PARAM_MAP).reduce(
  (acc, [alertType, slug]) => ({ ...acc, [slug]: alertType }),
  {}
) as Record<string, BeeActivityByRequestAlertType>;

export function useTimelineByActivityRequestModalOpener() {
  const { openedModals, openModal, closeModal } = useQueryParamsModalOpener({
    paramName: 'visit',
    paramValueStringifier: useCallback((value: ActivityFilters): string => {
      const cleanDate = value.date.replace(/(^\d+-\d+-\d+).*?$/, '$1');
      const cleanAlertType = ALERT_TYPE_PARAM_MAP[value.alertType];
      return [cleanDate, cleanAlertType, value.yardId].join('-');
    }, []),
    paramValueParser: useCallback((value: string): ActivityFilters | null => {
      const regex = /^(\d+-\d+-\d+)-(\w+)-(\d+)$/;
      const match = value.match(regex);
      if (match && match[2] in ALERT_TYPE_PARAM_MAP_REVERSED) {
        return {
          date: match[1],
          alertType: ALERT_TYPE_PARAM_MAP_REVERSED[match[2]],
          yardId: parseInt(match[3]),
        };
      }
      return null;
    }, []),
  });

  const close = useCallback(() => {
    openedModals.forEach((entry) => closeModal(entry));
  }, [closeModal, openedModals]);

  return {
    openedTimelineByActivityRequestModal: openedModals[0] ?? null,
    openTimelineByActivityRequestModal: openModal,
    closeTimelineByActivityRequestModal: close,
  };
}
