import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDown } from '@components/common/Icon/presets/ChevronDown';
import { ChevronUp } from '@components/common/Icon/presets/ChevronUp';
import { InputNumberProps, InputNumberRef } from '@components/form/InputNumber/types';
import { InputNumberUtil } from '@components/form/InputNumber/util';
import { InputTextV2 } from '@components/form/InputTextV2';
import { InputTextV2Ref, InputValidator } from '@components/form/InputTextV2/types';
import { Validators } from '@components/form/InputTextV2/validators';
import './util';

import { StyledInputWrapper, StyledStepButton, StyledStepButtons } from './styles';

const INCREMENT_INITIAL_DELAY = 250;
const INCREMENT_SEQUENCE_DELAY = 60;
const INCREMENT_DIRECTION_NONE = 0;
const INCREMENT_DIRECTION_POSITIVE = 1;
const INCREMENT_DIRECTION_NEGATIVE = -1;
const INCREMENT_DEFAULT_STEP = 1;

export const InputNumber = React.forwardRef<InputNumberRef, InputNumberProps>(function InputNumber(props, ref) {
  const {
    decimal,
    positive,
    value: propValue,
    onBlur: propOnBlur,
    onValid: propOnValid,
    onInvalid: propOnInvalid,
    validators: propValidators,
    onNumberChange,
    ...inputProps
  } = props;

  const { t } = useTranslation();
  const inputRef = useRef<InputTextV2Ref>(null);
  const [incrementDirection, setIncrementDirection] = useState<number>(INCREMENT_DIRECTION_NONE);
  const numericConstraints = useMemo(() => ({ decimal, positive }), [decimal, positive]);

  const getTextValue = useCallback(() => inputRef.current?.value, []);
  const setTextValue = useCallback<InputTextV2Ref['nativelySetValue']>(
    (value, options) => {
      if (value) {
        const cursorPosition = inputRef.current?.selectionStart ?? 0;
        const cleaningResult = InputNumberUtil.cleanNumericValue(value, { cursorPosition, ...numericConstraints });
        value = cleaningResult.value;
        inputRef.current?.nativelySetValue(value, options);
        if (inputRef.current) {
          inputRef.current.setSelectionRange(cleaningResult.nextCursorPosition, cleaningResult.nextCursorPosition);
        }
      } else {
        inputRef.current?.nativelySetValue(value, options);
      }
    },
    [numericConstraints]
  );

  const triggerCallback = useCallback(
    (callback: any, value: string | undefined) => {
      callback && callback(InputNumberUtil.toNumericValue(value, numericConstraints), inputProps.name);
    },
    [inputProps.name, numericConstraints]
  );

  const onTextChange = useCallback(
    (value: string | undefined) => {
      setTextValue(value);
      triggerCallback(onNumberChange, getTextValue());
    },
    [getTextValue, onNumberChange, setTextValue, triggerCallback]
  );

  const onBlur = useCallback(
    (event) => {
      propOnBlur && propOnBlur(event);

      const numericValue = InputNumberUtil.toNumericValue(getTextValue(), numericConstraints);
      if (numericValue !== undefined) {
        setTextValue(InputNumberUtil.toTextValue(numericValue), { triggerValidation: true });
      }
    },
    [getTextValue, numericConstraints, propOnBlur, setTextValue]
  );

  const numericValidator = useCallback<InputValidator>(
    (value) => {
      if (!Validators.isEmpty(value) && InputNumberUtil.toNumericValue(value, numericConstraints) === undefined) {
        return t('error_message_number');
      }
    },
    [numericConstraints, t]
  );

  const validators = useMemo(() => [numericValidator, ...(propValidators ?? [])], [numericValidator, propValidators]);
  const onValid = useCallback((value) => triggerCallback(propOnValid, value), [propOnValid, triggerCallback]);
  const onInvalid = useCallback((value) => triggerCallback(propOnInvalid, value), [propOnInvalid, triggerCallback]);

  const startIncrement = useCallback((direction: number) => setIncrementDirection(direction), []);
  const stopIncrement = useCallback(() => setIncrementDirection(INCREMENT_DIRECTION_NONE), []);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
        const direction =
          event.key === 'ArrowUp'
            ? INCREMENT_DIRECTION_POSITIVE
            : event.key === 'ArrowDown'
            ? INCREMENT_DIRECTION_NEGATIVE
            : INCREMENT_DIRECTION_NONE;
        startIncrement(direction);
      }
    },
    [startIncrement]
  );

  const onKeyUp = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
        stopIncrement();
      }
    },
    [stopIncrement]
  );

  useEffect(() => {
    const numericValue = InputNumberUtil.toNumericValue(getTextValue(), numericConstraints);
    if (propValue !== undefined && propValue !== numericValue) {
      setTextValue(InputNumberUtil.toTextValue(propValue), { triggerValidation: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propValue]);

  useEffect(() => {
    let textValue = getTextValue();
    const numericValue = InputNumberUtil.toNumericValue(textValue, numericConstraints);

    if (numericValue !== undefined && incrementDirection !== 0) {
      let interval: ReturnType<typeof setInterval>;
      let safeNumericValue = numericValue ?? 0;

      const increment = () => {
        const step = parseFloat(String(inputProps.step ?? INCREMENT_DEFAULT_STEP));
        safeNumericValue = safeNumericValue + step * incrementDirection;
        safeNumericValue = positive ? Math.max(0, safeNumericValue) : safeNumericValue;
        textValue = InputNumberUtil.toTextValue(safeNumericValue);
        setTextValue(textValue, { triggerOnChange: true });
      };

      increment();

      const timeout = setTimeout(() => {
        interval = setInterval(increment, INCREMENT_SEQUENCE_DELAY);
      }, INCREMENT_INITIAL_DELAY);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incrementDirection]);

  useImperativeHandle(ref, () => inputRef.current as InputNumberRef);

  return (
    <StyledInputWrapper>
      <InputTextV2
        ref={inputRef}
        maxLength={InputNumberUtil.MAX_NUMBER_LENGTH}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        {...{ onTextChange, onValid, onInvalid, onBlur, validators, ...inputProps }}
      />

      <StyledStepButtons>
        <StyledStepButton
          tabIndex={-1}
          onMouseDown={() => startIncrement(INCREMENT_DIRECTION_POSITIVE)}
          onMouseUp={stopIncrement}
          onMouseLeave={stopIncrement}
        >
          <ChevronUp size={18} />
        </StyledStepButton>
        <StyledStepButton
          tabIndex={-1}
          onMouseDown={() => startIncrement(INCREMENT_DIRECTION_NEGATIVE)}
          onMouseUp={stopIncrement}
          onMouseLeave={stopIncrement}
        >
          <ChevronDown size={18} />
        </StyledStepButton>
      </StyledStepButtons>
    </StyledInputWrapper>
  );
});
