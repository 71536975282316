import React from 'react';
import PropTypes from 'prop-types';

// nectar:
import { groupEventData } from '@helpers/deprecated/groupEventData';

import { BeeTrackDropdownEventsView } from './components/BeeTrackDropdownEventsView';
import { CumulativeEvents } from './components/CumulativeEvents';
import { CumulativeRecoveredEvents } from './components/CumulativeRecoveredEvents';
import { EventsView } from './components/EventsView';
import { RecoveredEvents } from './components/RecoveredEvents';

export const AlertsView = ({ t, eventList, alertView, handleEditYard }) => {
  const renderEvents = (yard, event_details, type) => {
    let { yard_name, yard_id, analyzed_at } = yard;

    const eventObj = {
      yard_name,
      yard_id,
      analyzed_at,
      event_details,
      type,
    };

    return (
      <div key={`${yard_id}_${yard_name}_${analyzed_at}_${type}`}>
        {/* no cummulative or recovered events yet for beetrack alerts */}

        {'alert' !== type ? (
          <>
            <CumulativeRecoveredEvents t={t} alertView={alertView} eventObj={eventObj} />

            <RecoveredEvents t={t} alertView={alertView} eventObj={eventObj} />

            <CumulativeEvents t={t} alertView={alertView} eventObj={eventObj} />

            <EventsView t={t} alertView={alertView} eventObj={eventObj} />
          </>
        ) : (
          <BeeTrackDropdownEventsView t={t} eventObj={eventObj} handleEditYard={handleEditYard} />
        )}
      </div>
    );
  };

  const renderEventByAnalyzer = (obj) => {
    const { analyzer } = obj;
    switch (true) {
      case 'hardware_health' === analyzer:
        const { beecons, beehubs } = obj.event_details;
        const bc = beecons && groupEventData(beecons, 'bc');
        const bh = beehubs && groupEventData(beehubs, 'bh');
        if (beecons && beehubs) {
          let tempArray = [];
          tempArray.push(bc, bh);
          return tempArray.map((a, idx) => {
            let type = 0 === idx ? 'bc' : 'bh';
            return renderEvents(obj, a, type);
          });
        } else if (beecons) {
          return renderEvents(obj, bc, 'bc');
        } else if (beehubs) {
          return renderEvents(obj, bh, 'bh');
        }
        break;

      case 'ai_hive_status' === obj.analyzer:
        const ai = groupEventData(obj.event_details, 'ai');
        return renderEvents(obj, ai, 'ai');

      case 'alert' === obj.analyzer:
        return renderEvents(obj, obj.event_details, 'alert');

      default:
        return null;
    }
  };
  return eventList && eventList.map((el, idx) => <div key={idx}>{renderEventByAnalyzer(el)}</div>);
};

AlertsView.propTypes = {
  t: PropTypes.func.isRequired,
  eventList: PropTypes.array,
  alertView: PropTypes.string,
  handleEditYard: PropTypes.func.isRequired,
};
