import styled, { css, keyframes } from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';

export const ArrowButton = styled(PrimaryButton)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  padding: 0;
  align-items: center;
  justify-content: center;
  z-index: ${theme.getZIndexOf('lowLevelIndex')}; 
  transition: ${theme.animations.transitionMedium('background-color')}; 
`
);

const CardAppearingKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const EmptyView = styled(Box)(
  () => css`
    position: absolute;
    inset: 0;
  `
);

export const CardWrapper = styled.div<{ $width: number; $index: number; $hidden: boolean; $animate: boolean }>(
  ({ theme, $width, $index, $hidden, $animate }) => css`
    position: absolute;
    display: flex;
    top: 0;
    left: ${$index * $width}px;
    opacity: ${$hidden ? 0 : 1};
    pointer-events: ${$hidden ? 'none' : 'all'};

    transition: ${theme.animations.transitionMedium('left', 'opacity')};
    animation: ${$animate ? CardAppearingKeyframes : 'none'} ${theme.animations.durationLong + 100 * $index}ms
      ${theme.animations.easing};

    width: ${$width}px;
    padding: 0 ${theme.spacing.xs}px;
    z-index: ${theme.getZIndexOf('base')};
  `
);

export const CardsContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CarrouselContainer = styled.div<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    width: 100%;
    height: 0;
    gap: ${({ theme }) => theme.spacing.xs}px;

    transition: ${theme.animations.transitionMedium('height')};

    ${$isVisible &&
    css`
      height: 108px;
    `}
  `
);

export const StyledToggleMetricsButton = styled.button<{ $areMetricsVisible: boolean }>(
  ({ theme, $areMetricsVisible }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-top: ${-theme.spacing.xs}px;

    transition: ${theme.animations.transitionMedium('margin-top')};

    &:before {
      content: '';
      position: absolute;
      inset: 50% 0 auto 0;
      height: 1px;
      background-color: ${theme.colors.grey05};
    }

    div {
      position: relative;
      background-color: ${theme.colors.coreBackground};
      padding: 0 ${theme.spacing.sm}px;
      gap: ${theme.spacing.xs}px;
    }

    svg {
      transition: ${theme.animations.transitionMedium('transform')};
      transform: rotate(180deg);
    }

    ${$areMetricsVisible &&
    css`
      margin-top: ${theme.spacing.xs}px;

      svg {
        transform: rotate(0deg);
      }
    `}
  `
);
