import { useContext } from 'react';

import { YardsListSelectionContext, YardsListSelectionContextValue } from '../context';

export function useYardsListSelection(): YardsListSelectionContextValue {
  const yardsListSelection = useContext(YardsListSelectionContext);

  if (!yardsListSelection) {
    throw new Error("Can't find a parent yards list context.");
  }

  return yardsListSelection;
}
