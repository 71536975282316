import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Modal, ModalFooter } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import { useTranslation } from '@hooks/useTranslation';

// This file is mostly lifted from the old version, still lots to fix

const StyledLink = styled.a`
  text-decoration: underline;
  //   color: ${(props) => props.theme.colors.grey08};
  cursor: pointer;
`;
const ConfirmationBox = styled.div`
  position: absolute;
  top: 25%;
  font-size: 12px;
  padding: 7px 0.5rem;
  font-weight: 300;
  background-color: ${(props) => props.theme.colors.grey08};
  color: ${(props) => props.theme.colors.coreWhite};
  line-height: 17px;
  letter-spacing: 0.86px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow04};
  border-radius: 3px;
`;

export const HelpModal: React.VFC<ModalProps> = (props) => {
  const [show, setShow] = useState(false);
  const delay = 1; // in seconds
  const t = useTranslation();

  // how to correctly copy to clipboard using JS source -> https://stackoverflow.com/a/30810322
  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };
  const copyTextToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Async: Copying to clipboard was successful!');
        setShow(true);
        const timer = setTimeout(() => setShow(false), delay * 1000);
        return () => {
          clearTimeout(timer);
        };
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };
  return (
    <Modal {...props}>
      <Box paddingVerticalLG paddingHorizontalMD block>
        <StyledHeading2 style={{ marginBottom: '1rem' }}>{t('contact_title')}</StyledHeading2>
        <StyledParagraph style={{ marginBottom: '1.5rem' }}>
          <Trans i18nKey="contact_desc" />
          {show && <ConfirmationBox>{t('email_clipboard')}</ConfirmationBox>}
          <StyledLink onClick={() => copyTextToClipboard('support@nectar.buzz')}>support@nectar.buzz.</StyledLink>
        </StyledParagraph>
        <StyledParagraph>
          {t('contact_text')}
          <StyledLink href="https://help.nectar.buzz/en" target="_blank" rel="noopener noreferrer">
            {t('online_knowledge_base')}
          </StyledLink>
          {t('contact_text_cont')}
        </StyledParagraph>
      </Box>
      <ModalFooter>
        <Box justifyContent="flex-end">
          <Button primary onClick={() => props.onRequestClose && props.onRequestClose()}>
            {t('close')}
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};
