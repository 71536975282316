import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { Card, CardContent, CardContentRow, CardHeader } from '@components/common/Card';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import { ExportPDFButton } from '@components/pollination/PolliContractDetails/ExportPDFButton';
import { PointsOfInterest } from '@components/pollination/PolliContractDetails/PointsOfInterest';
import { formatPhoneNumber } from '@helpers/deprecated/formatPhoneNumber';
import { useContract, useContractId } from '@hooks/useContract';
import { useCropTypes } from '@hooks/useCropTypes';
import { makeOpenContractModal } from '@redux/Contract/actions';

import { ModalFormContract } from '../FormContract/ModalFormContract';

import { ContractDetailInstructions } from './ContractDetailInstructions';
import { Pricing } from './Pricing';
import { StyledGrid, StyledNote, StyledWrapper, StyledYellowText } from './styles';

export const PolliContractDetails: React.FC = () => {
  const uid = useContractId();
  const { contract } = useContract(uid);
  const { getCropTypesVisualString } = useCropTypes();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openEditModal = useCallback(() => {
    if (contract) {
      dispatch(makeOpenContractModal(contract));
    }
  }, [contract, dispatch]);

  if (!contract || contract.id !== uid) {
    return <Loading />;
  }

  return (
    <StyledWrapper>
      <Box alignItems={'center'} justifyContent={'space-between'}>
        <Text typography="Heading1">
          <span>{t('contract')}</span>: <StyledYellowText>{contract?.name}</StyledYellowText>
        </Text>

        <Box>
          <ExportPDFButton />
          <PrimaryButton onClick={() => openEditModal()}>
            <span>{t('edit')}</span>
          </PrimaryButton>
        </Box>

        <ModalFormContract contract={contract} />
      </Box>
      <StyledGrid>
        <Card>
          <CardHeader>
            <Text typography="Heading3" weight={'600'}>
              {t('pollination_contract_details')}
            </Text>
          </CardHeader>
          <CardContent>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('grower')}
                </Text>
                <Text typography="SmallParagraph">{contract.grower.name}</Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('contact')}
                </Text>
                <Text typography="SmallParagraph">{contract.grower.contactName}</Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('phone')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.grower.contactPhoneNumber ? (
                    <NumberFormat
                      value={contract.grower.contactPhoneNumber}
                      displayType={'text'}
                      format={formatPhoneNumber(contract.grower.contactPhoneNumber)}
                    />
                  ) : (
                    '-'
                  )}
                </Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('email')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.grower.contactEmail ? contract.grower.contactEmail : '-'}
                </Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('crop_type')}
                </Text>
                <Text typography="SmallParagraph">{getCropTypesVisualString(contract.cropTypesIds)}</Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('pollination_bees_in')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.beesIn ? moment(contract.beesIn).format('MMM DD, YYYY') : '-'}
                </Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('pollination_bees_out')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.beesOut ? moment(contract.beesOut).format('MMM DD, YYYY') : '-'}
                </Text>
              </Box>
            </CardContentRow>
          </CardContent>
        </Card>
        <Box gap={'24px'} column>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('price')}
              </Text>
            </CardHeader>
            <Pricing contract={contract} />
          </Card>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('location')}
              </Text>
            </CardHeader>
            <CardContent>
              <Box marginVerticalXS>
                {contract.address ? (
                  <Text typography="SmallParagraph">{contract.address}</Text>
                ) : (
                  <ContractDetailInstructions />
                )}
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('pollination_notes')}
              </Text>
            </CardHeader>
            <CardContent>
              <Box marginVerticalXS>
                {contract.notes ? (
                  <StyledNote typography="SmallParagraph" dangerouslySetInnerHTML={{ __html: contract.notes }} />
                ) : (
                  <ContractDetailInstructions />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box gap={'24px'} column>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('pollination_hives_operations')}
              </Text>
            </CardHeader>
            <CardContent>
              <Box column stretch>
                <Box justifyContent={'space-between'} marginVerticalXXS>
                  <Text typography={'Heading3'} weight={'600'}>
                    {t('pollination_hives_on_map')}
                  </Text>
                  <Text typography={'Heading3'}>
                    {contract.nbTargetedHives ?? 0}/{contract.nbRequiredHives ?? 0}
                  </Text>
                </Box>
                <Box justifyContent={'space-between'} marginVerticalXXS>
                  <Text typography={'Heading3'} weight={'600'}>
                    {t('pollination_hives_on_site')}
                  </Text>
                  <Text typography={'Heading3'}>
                    {contract.nbTrackedHives ?? 0}/{contract.nbRequiredHives ?? 0}
                  </Text>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card fit>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('pollination_pois')}
              </Text>
            </CardHeader>
            <PointsOfInterest />
          </Card>
        </Box>
      </StyledGrid>
    </StyledWrapper>
  );
};
