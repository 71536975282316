import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@components/common/Box';
import { Pin } from '@components/common/Icon/presets/Pin';
import { Loading } from '@components/common/Loading';
import { PoiIcon } from '@components/common/PoiIcon';
import { useCurrentTabFocus, useTabs } from '@components/common/Tabs/hooks';
import { Text } from '@components/common/Text';
import { PolliMapUtil } from '@components/pollination/PolliMap/util';
import { useContract, useContractId } from '@hooks/useContract';
import { useAlphanumericallySorted } from '@hooks/useSorted';
import { makeFetchPoisMapThunk } from '@redux/Contract/actions';

import {
  StyledCardContent,
  StyledPOICard,
  StyledPOICardHeader,
  StyledPOICardHeaderButton,
  StyledPOIDescriptionDivider,
} from './styles';

export const PointsOfInterest: React.VFC = () => {
  const uid = useContractId();
  const { contractPoisMap, isFetchingContractPois } = useContract(uid);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tabs = useTabs();

  const poisListSorted = useAlphanumericallySorted(contractPoisMap || [], 'name');

  useCurrentTabFocus(
    useCallback(() => {
      if (uid) {
        // Currently using the same POIs list as map tab.
        dispatch(makeFetchPoisMapThunk(uid));
      }
    }, [uid, dispatch])
  );

  const viewOnMap = useCallback(
    ({ id }: BeePointOfInterest) => {
      tabs.goToTab('map', PolliMapUtil.buildSelectionParams({ poi: id }));
    },
    [tabs]
  );

  return (
    <StyledCardContent scrollable>
      {isFetchingContractPois ? (
        <Loading />
      ) : !poisListSorted.length ? (
        <Text typography="SmallParagraph" weight="600" color="grey06">
          {t('pollination_pois_empty')}
        </Text>
      ) : (
        poisListSorted.map((poi) => {
          const { id, name, category, descriptionEnglish, descriptionSpanish } = poi;
          const descriptions = [descriptionEnglish, descriptionSpanish].filter((desc) => !!desc);
          const hasDescriptions = !!descriptions.length;

          return (
            <StyledPOICard key={id}>
              <StyledPOICardHeader showBottomBorder={hasDescriptions}>
                <PoiIcon category={category} size={16} color={'grey08'}></PoiIcon>
                <Text typography={'Heading3'} weight={'600'}>
                  {name}
                </Text>
                <StyledPOICardHeaderButton onClick={() => viewOnMap(poi)}>
                  {t('view_on_map')}
                  <Pin size={16} />
                </StyledPOICardHeaderButton>
              </StyledPOICardHeader>
              {hasDescriptions &&
                descriptions.map((desc, index) => (
                  <Fragment key={index}>
                    {index > 0 && <StyledPOIDescriptionDivider />}
                    <Box paddingSM>
                      <Text typography={'SmallParagraph'}>{desc}</Text>
                    </Box>
                  </Fragment>
                ))}
            </StyledPOICard>
          );
        })
      )}
    </StyledCardContent>
  );
};
