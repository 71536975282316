import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { CardContent } from '@components/common/Card';
import { Text } from '@components/common/Text';
import { PRESETS } from '@components/common/Text/styles';
import { from } from '@style/mediaQueries';

export const StyledCardContent = styled(CardContent)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-height: 420px;
    align-items: stretch;
    padding: ${theme.spacing.md}px ${theme.spacing.sm}px ${theme.spacing.md}px ${theme.spacing.md}px;
    margin-right: ${theme.spacing.xs}px;
  
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      margin: ${theme.spacing.md}px 0;
      background: ${theme.colors.grey02};
    }
  `
);

export const StyledPOICard = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid ${theme.colors.grey05};
    
    &:not(:last-child) {
      margin-bottom: ${theme.spacing.sm}px;
    }
  
    // This border radius is very specific.
    // If it becomes more used, it will deserve 
    // its own theme prop.
    border-radius: 16px;
  `
);

export const StyledPOICardHeader = styled(Box)<{ showBottomBorder: boolean }>(
  ({ theme, showBottomBorder }) => `
    align-items: center;
    margin: ${theme.spacing.sm}px ${theme.spacing.sm}px 0 ${theme.spacing.sm}px;
    padding-bottom: ${theme.spacing.sm}px;
    border-bottom: ${showBottomBorder ? `1px solid ${theme.colors.grey04}` : 'none'};
    
    & > svg {
      margin-right: ${theme.spacing.xs}px;
    }
    
    & > h3 {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 132px);
      overflow: hidden;
    }
  `
);

export const StyledPOICardHeaderButton = styled.button(
  ({ theme }) => `
    font-size: ${PRESETS.CaptionSmall.fontSize};
    line-height: ${PRESETS.CaptionSmall.lineHeight};
    color: ${theme.colors.grey06};
    margin-left: ${theme.spacing.xs}px;
    
    &:hover {
      color: ${theme.colors.grey07};
    }
    
    svg {
      margin-left: ${theme.spacing.xs}px;
    }
  `
);

export const StyledPOIDescriptionDivider = styled.span(
  ({ theme }) => `
    display: inline-block;
    width: 28px;
    height: 1px;
    background-color: ${theme.colors.grey08};
    margin-left: ${theme.spacing.sm}px;
  `
);

export const StyledWrapper = styled.div(
  () => `
      padding: 4.5rem 0;
    `
);

export const StyledYellowText = styled.span`
  color: ${({ theme }) => theme.colors.orange02};
`;

export const StyledGrid = styled.div`
  grid-gap: 24px;
  display: grid;
  margin-top: 24px;
  ${from('mobile')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${from('laptop')} {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
  }
`;

export const StyledNote = styled(Text)`
  white-space: pre-wrap;
`;
