/**
 * Map layer to render yards, yard geometries and hives.
 * */

import React, { useCallback, useMemo } from 'react';

import { BlockPolygonText } from '@components/common/BlockPolygonText';
import { Polygon } from '@components/common/Map';
import { MarkerOptimizer } from '@components/common/Map/components/MarkerOptimizer';
import { MarkerDescriptor } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';

const MIN_ZOOM_TO_SHOW_BLOCKS = 13;
const BLOCK_POLYGON_PROPS = {
  fillColor: '#64CEE5',
  fillOpacity: 0.2,
  strokeColor: '#64CEE5',
};

export interface YardsMapBlocksLayerProps {
  blocks: Array<BeeBlock>;
}

export const YardsMapBlocksLayer: React.VFC<YardsMapBlocksLayerProps> = ({ blocks }) => {
  const blocksMarkers = useMemo(
    () =>
      blocks.map((block) => {
        const { id, geometry } = block;
        const path = geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));
        const { lng, lat } = MapUtil.getPathCenter(path);
        return { id, position: { lat, lng }, data: { path, block } };
      }, []),
    [blocks]
  );

  const blockRenderer = useCallback(
    ({ data: { path, block } }: MarkerDescriptor) => (
      <Polygon path={path} alwaysShowChildrenIfFits {...BLOCK_POLYGON_PROPS}>
        <BlockPolygonText block={block} />
      </Polygon>
    ),
    []
  );

  return (
    <MarkerOptimizer
      enableSmartBounds
      markers={blocksMarkers}
      markerRenderer={blockRenderer}
      minZoomToRender={MIN_ZOOM_TO_SHOW_BLOCKS}
    />
  );
};
