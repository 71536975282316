import has from 'lodash/has';

import { ENDPOINTS } from '@config/api';
import { parseJsonObjError, parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';

import * as types from '../actionTypes';

import {
  makeFetchYardListRequestThunk,
  makeFetchYardRequestThunk,
  makeUpdateYardRequestSuccessAction,
} from './beeTrackYardListRequestActionCreator';
import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeGroupsRequestStartAction = () => ({
  type: types.GROUPS_ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{groups: array}}
 *
 */
export const makeGroupsRequestSuccessAction = (groups) => ({
  type: types.GROUPS_ACTION_SUCCESS,
  groups,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeGroupsRequestFailureAction = () => ({
  type: types.GROUPS_ACTION_FAILURE,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{assignedGroups: array}}
 *
 */
export const makeManyGroupsRequestSuccessAction = (assignedGroups) => ({
  type: types.MANY_GROUPS_ACTION_SUCCESS,
  assignedGroups,
});

/**
 *
 * @param error
 * @returns {{type: string, order_by: string, order_direction: string}}
 */
export const handleSortGroups = (order_by, order_direction) => ({
  type: types.SORT_GROUP_LIST,
  order_by,
  order_direction,
});

export const makeFetchGroupsAssignmentRequestThunk = () => {
  return function (dispatch, getState) {
    dispatch(makeGroupsRequestStartAction());

    const { appliedFilters } = getState().yardsFiltersReducer;
    const url = URLUtil.buildURL(ENDPOINTS.assignedGroups, appliedFilters);

    fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'default',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          const failure = makeGroupsRequestFailureAction;
          parseJsonObjError({ dispatch, jsonObj, failure });
        } else dispatch(makeManyGroupsRequestSuccessAction(jsonObj));
      });
  };
};

export const makeFetchManyGroupsRequestThunk = (ids) => {
  const jsonData = JSON.stringify({ ids });

  return function (dispatch) {
    dispatch(makeGroupsRequestStartAction());

    fetch(ENDPOINTS.assignedGroups, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          const failure = makeGroupsRequestFailureAction;
          parseJsonObjError({ dispatch, jsonObj, failure });
        } else dispatch(makeManyGroupsRequestSuccessAction(jsonObj));
      });
  };
};

export const makeFetchGroupsRequestThunk = () => {
  return function (dispatch) {
    dispatch(makeGroupsRequestStartAction());

    fetch(ENDPOINTS.groups, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'default',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeGroupsRequestFailureAction;
        parseJsonObjError({ dispatch, jsonObj, failure });
        if ('results' in jsonObj) {
          dispatch(makeGroupsRequestSuccessAction(jsonObj.results));
        }
      });
  };
};

export const makeFetchGroupsOnceRequestThunk = () => {
  return function (dispatch, getState) {
    if (getState().groupsReducer.groups === null) {
      dispatch(makeFetchGroupsRequestThunk());
    }
  };
};

export const makeFetchGroupsDetailedListRequestThunk = () => {
  return function (dispatch) {
    dispatch(makeGroupsRequestStartAction());

    fetch(ENDPOINTS.groupsDetails, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'default',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeGroupsRequestFailureAction;
        parseJsonObjError({ dispatch, jsonObj, failure });
        if ('results' in jsonObj) {
          dispatch(makeGroupsRequestSuccessAction(jsonObj.results));
        }
      });
  };
};

export const makeAssignYardGroupsRequestThunk = (data, yard) => {
  const jsonData = JSON.stringify(data);

  return async function (dispatch, getState) {
    const { appliedFilters } = getState().yardsFiltersReducer;
    const yardListReducer = getState().beeTrackYardListReducer;
    const { yard: selectedYard } = yardListReducer;

    // show the spinning screen:
    dispatch(makeGroupsRequestStartAction());
    const includeFilterQueryParams = has(data, 'assign_yards') && !has(data.assign_yards, 'yard_ids');
    const endpoint = URLUtil.buildURL(ENDPOINTS.groupsBatch, includeFilterQueryParams ? appliedFilters : {});

    await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeGroupsRequestFailureAction;
        if ('error' in jsonObj) parseJsonObjError({ dispatch, jsonObj, failure });
        else {
          if (yard) dispatch(makeUpdateYardRequestSuccessAction(yard, yard.id));
          dispatch(makeShowSnackbarAction('changes_saved'));
          dispatch(makeFetchGroupsRequestThunk());

          // Necessary to keep the yard list and detail in
          // sync with last added groups.
          dispatch(makeFetchYardListRequestThunk());
          selectedYard && dispatch(makeFetchYardRequestThunk(selectedYard.id));
        }
      });
  };
};

export const makeRemoveGroupRequestSuccessAction = () => ({
  type: types.REMOVE_GROUP_SUCCESS,
});

export const makeRemoveGroupRequestThunk = (id) => {
  return async function (dispatch) {
    // show the spinning screen:
    dispatch(makeGroupsRequestStartAction());

    await fetch(`${ENDPOINTS.groups}${id}`, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          const failure = makeGroupsRequestFailureAction;
          parseJsonObjError({ dispatch, jsonObj, failure });
        } else {
          dispatch(makeRemoveGroupRequestSuccessAction());
          dispatch(makeShowSnackbarAction('group_removed', { icon: true }));
          dispatch(makeFetchGroupsDetailedListRequestThunk());
        }
      });
  };
};

export const makeAddGroupRequestSuccessAction = () => ({
  type: types.ADD_GROUP_SUCCESS,
});

export const makeAddGroupRequestThunk = (body) => {
  const jsonData = JSON.stringify(body);

  return async function (dispatch) {
    // show the spinning screen:
    dispatch(makeGroupsRequestStartAction());

    await fetch(ENDPOINTS.groups, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          const failure = makeGroupsRequestFailureAction;
          parseJsonObjError({ dispatch, jsonObj, failure });
        } else {
          dispatch(makeAddGroupRequestSuccessAction());
          dispatch(makeShowSnackbarAction('group_added', { icon: true }));
          dispatch(makeFetchGroupsDetailedListRequestThunk());
        }
      });
  };
};

export const makeUpdateGroupRequestSuccessAction = () => ({
  type: types.UPDATE_GROUP_SUCCESS,
});

export const makeUpdateGroupRequestThunk = (id, body) => {
  const jsonData = JSON.stringify(body);

  return async function (dispatch) {
    // show the spinning screen:
    dispatch(makeGroupsRequestStartAction());

    await fetch(`${ENDPOINTS.groups}${id}`, {
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          const failure = makeGroupsRequestFailureAction;
          parseJsonObjError({ dispatch, jsonObj, failure });
        } else {
          dispatch(makeUpdateGroupRequestSuccessAction());
          dispatch(makeShowSnackbarAction('group_updated', { icon: true }));
          dispatch(makeFetchGroupsDetailedListRequestThunk());
        }
      });
  };
};
