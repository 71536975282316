import React, { useCallback, useMemo } from 'react';

import { Marker } from '@components/common/Map';
import { Coordinate } from '@components/common/Map/types';
import { PoiIcon } from '@components/common/PoiIcon';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { AnyInstance, TransientPoi } from '@components/pollination/PolliMap/types';

import { PoiMarker } from './styles';

export interface PolliMapPoisProps {
  zIndex?: number;
  onPoiClick?: (drop: AnyInstance<TransientPoi>) => void;
  onPoiPositionChange?: (drop: AnyInstance<TransientPoi>, position: Coordinate) => void;
  onPoiDraggingChange?: (drop: AnyInstance<TransientPoi>, dragging: boolean) => void;
}

export const PolliMapPois: React.VFC<PolliMapPoisProps> = ({
  zIndex,
  onPoiClick,
  onPoiPositionChange,
  onPoiDraggingChange,
}) => {
  const {
    pois,
    selectedPoi,
    getKey,
    isManagingPois,
    isManagingAPoi,
    isManagingDrops,
    isManagingADrop,
    isManagingBlocks,
    isManagingABlock,
  } = usePolliMap();

  const poisList = useMemo(() => {
    const visiblePoisDict = { ...pois.added, ...pois.edited } as Record<string, AnyInstance<TransientPoi>>;
    selectedPoi && (visiblePoisDict[getKey(selectedPoi)] = selectedPoi);

    const visiblePois = Object.values(visiblePoisDict);
    return visiblePois.sort((a, b) => b.location.lat - a.location.lat);
  }, [getKey, pois.added, pois.edited, selectedPoi]);

  const isPoiSelected = useCallback(
    (poi: AnyInstance<TransientPoi>) => {
      return Boolean(selectedPoi && getKey(selectedPoi) === getKey(poi));
    },
    [getKey, selectedPoi]
  );

  const isPoiDraggable = useCallback(
    (poi: AnyInstance<TransientPoi>) => {
      const isManagingAnyPoi = isManagingPois || isManagingAPoi;
      const thereIsNoOtherSelection = !isManagingADrop || Boolean(selectedPoi && getKey(selectedPoi) === getKey(poi));
      return isManagingAnyPoi && thereIsNoOtherSelection;
    },
    [getKey, isManagingADrop, isManagingAPoi, isManagingPois, selectedPoi]
  );

  const isPoiDisabled = useCallback(
    (poi: AnyInstance<TransientPoi>) => {
      const isManagingAnotherPoi = isManagingAPoi && selectedPoi && getKey(selectedPoi) !== getKey(poi);
      return Boolean(
        isManagingDrops || isManagingBlocks || isManagingABlock || isManagingADrop || isManagingAnotherPoi
      );
    },
    [isManagingAPoi, selectedPoi, getKey, isManagingDrops, isManagingBlocks, isManagingABlock, isManagingADrop]
  );

  return (
    <>
      {poisList.map((poi) => (
        <Marker
          key={getKey(poi)}
          draggable={isPoiDraggable(poi)}
          onClick={() => onPoiClick && onPoiClick(poi)}
          onPositionChange={(position) => onPoiPositionChange && onPoiPositionChange(poi, position)}
          onDraggingChange={(dragging) => onPoiDraggingChange && onPoiDraggingChange(poi, dragging)}
          position={poi.location}
          zIndex={zIndex}
        >
          <PoiMarker active={isPoiSelected(poi)} disabled={isPoiDisabled(poi)}>
            <PoiIcon category={poi.category} size={16} />
          </PoiMarker>
        </Marker>
      ))}
    </>
  );
};
