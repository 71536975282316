import React from 'react';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { Text } from '@components/common/Text';

export const PageHeaderTitle: React.FC<BoxProps<'div'>> = ({ children, ...props }) => {
  return (
    <Box fit {...props}>
      <Text typography={'Heading2'} htmlTag="h1" weight={'600'}>
        {children}
      </Text>
    </Box>
  );
};
