import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-web';

import SavePDFLoading from '@assets/animations/save-pdf-loading.json';
import { Loading } from '@components/common/Loading';
import { PolliContractPrintable } from '@components/pollination/PolliContractPrintable';
import APP from '@config/constants';
import { PDF } from '@helpers/PDF';
import { QueryParams } from '@helpers/QueryParams';
import { useContract, useContractMapData } from '@hooks/useContract';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { StyledLoaderWrapper } from '@scenes/admin/Pollination/styles';

type ContractType = 'manager' | 'worker-en' | 'worker-es';
// http://localhost:3000/pollination-contract-printable?id=66&type=manager,worker-es,worker-en

export const PolliContractsPrintable: React.FC = () => {
  const [mapsLoadedCount, setMapsLoadedCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const loadAnimRef = useRef<HTMLDivElement>(null);
  const isGeneratePDFCalled = useRef(false);

  const { id, type } = QueryParams.getCurrentQueryParams<{
    id?: string;
    type?: ContractType;
  }>();

  /**
   * DELETE THIS COMMENT WHEN MULTIPLE CONTRACTS WORK IS DONE
   * Right now multiple ids don't actually work because
   * we don't have a way to call the backend for specific list of ids
   * but in the meantime, i'm trying to kind of mock how the data will look
   * when we can get a list of specific contracts from the backend,
   * and hopefully those contract objects will include pois & drop & block info in one object
   *
   * until then, i think it makes sense to get all the contract data here and pass it down as props
   * since we'll probably be mapping over the list of contracts from the back end like this in the future
   */
  const ids = id?.split(',').map((id) => Number(id)) || [];
  const types = useMemo(() => type?.split(',') || [], [type]);
  const { contract } = useContract(ids[0]);
  const { loaded, contractDropsMap, contractBlocksMap, contractPoisMap } = useContractMapData(ids[0]);

  // Trying to mock the data that will hopefully come from backend.
  const contracts = useMemo<Array<BeeContract>>(() => (contract ? [contract] : []), [contract]);

  const mapsToLoadCount = contracts.length * types.length;
  const hasLoadedAllMaps = mapsLoadedCount === mapsToLoadCount;
  const hasLoadedAllPages = contracts.length && types.length && hasLoadedAllMaps && loaded;

  const exitGeneratePDFPage = useCallback(() => {
    if (history) {
      const previousPathname = QueryParams.popLatestPathname() ?? APP.routes.home;
      history.push(previousPathname);
    }
  }, [history]);

  const generatePDF = useCallback(async () => {
    try {
      // Waits the initial map animation to fit drops, blocks, and POIs.
      const mapPanningAnimationDelay = 250;
      await new Promise((resolve) => setTimeout(resolve, mapPanningAnimationDelay));

      const pages = Array.from(document.querySelectorAll('.js-page')) as Array<HTMLElement>;
      const doc = await PDF.elementToPDF(...pages);

      exitGeneratePDFPage();

      await doc?.save(contracts[0].name, { returnPromise: true });
      dispatch(makeShowSnackbarAction({ translation: 'pdf_success' }));
    } catch (error) {
      console.error(error);
      dispatch(makeShowSnackbarAction({ translation: 'pdf_fail' }));
    }
  }, [contracts, dispatch, exitGeneratePDFPage]);

  useLayoutEffect(() => {
    if (!isGeneratePDFCalled.current && hasLoadedAllPages) {
      isGeneratePDFCalled.current = true;
      generatePDF().then(null);
    }
  }, [loaded, contracts, types, hasLoadedAllMaps, history, dispatch, generatePDF, hasLoadedAllPages]);

  useLayoutEffect(() => {
    if (loadAnimRef.current) {
      const anim = Lottie.loadAnimation({
        container: loadAnimRef.current,
        animationData: SavePDFLoading,
        renderer: 'svg',
        autoplay: true,
        loop: true,
      });

      return () => anim.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!contracts.length, !types.length]);

  useLayoutEffect(() => {
    /**
     * Auto scrolling through the page to trigger the map rendering.
     *
     * After an update, Google Maps now only start rendering if the map
     * element is visible on the screen. Their documentation doesn't seem
     * to have a way to disable this behavior right now.
     * */
    const scrollableElement = document.getElementById('root') as HTMLDivElement;
    const scrollingStep = window.innerHeight / 2.0;

    const intervalId = setInterval(() => {
      const availableScrollHeight = scrollableElement.scrollHeight - scrollableElement.clientHeight;
      if (scrollableElement.scrollTop < availableScrollHeight) {
        scrollableElement.scrollTop = Math.min(scrollableElement.scrollTop + scrollingStep, availableScrollHeight);
      }
    }, 40);
    return () => clearInterval(intervalId);
  }, [contracts]);

  if (!contracts.length || !types.length) {
    return <Loading />;
  }

  return (
    <>
      <StyledLoaderWrapper>
        <div ref={loadAnimRef} />
      </StyledLoaderWrapper>

      {contracts.map((contract) => {
        const printableProps = {
          contract,
          drops: contractDropsMap,
          blocks: contractBlocksMap,
          pois: contractPoisMap,
          onMapLoad: () => setMapsLoadedCount((current) => current + 1),
        };
        return (
          <div key={contract.id}>
            {types.includes('manager') && <PolliContractPrintable type={'manager'} lang={'en'} {...printableProps} />}
            {types.includes('worker-en') && <PolliContractPrintable type={'worker'} lang={'en'} {...printableProps} />}
            {types.includes('worker-es') && <PolliContractPrintable type={'worker'} lang={'es'} {...printableProps} />}
          </div>
        );
      })}
    </>
  );
};
