import React, { useCallback, useMemo } from 'react';
import ReactTooltip from 'react-tooltip-currenttarget';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ReactTooltipStyled = styled(ReactTooltip)`
  // need to overwrite the default tooltip styles :/
  opacity: 1 !important;
  font-size: ${(props) => props.theme.captionSmallFontSize} !important;
  padding: 7px 0.5rem !important;
  font-weight: 300 !important;
  background-color: ${(props) => props.theme.buttonPrimaryColor} !important;
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  display: block;
  width: auto;
  max-width: ${(props) => props.maxWidth};
  text-align: left;
  text-transform: none;
  line-height: 17px;
  letter-spacing: 0.86px;
  box-shadow: ${(props) => props.theme.boxShadow4};
  border-radius: ${(props) => props.theme.defaultBorderRadius};

  z-index: ${(props) => props.theme.getZIndexOf('tooltip')} !important;

  strong {
    font-weight: 700;
  }
`;

export const Tooltip = ({ id, disable, disableTimeout, tooltipText, place, offset, maxWidth }) => {
  const timeout = useMemo(() => ({ value: null }), []);

  const startTimeout = useCallback(() => {
    timeout.value = setTimeout(ReactTooltip.hide, 5000);
  }, [timeout]);

  const stopTimeout = useCallback(() => {
    clearTimeout(timeout.value);
  }, [timeout]);

  const getContent = useCallback(() => {
    if (typeof tooltipText === 'string') {
      return <span dangerouslySetInnerHTML={{ __html: tooltipText }} />;
    }
    return tooltipText;
  }, [tooltipText]);

  return (
    <ReactTooltipStyled
      key={id}
      id={id}
      disable={disable}
      getContent={getContent}
      maxWidth={maxWidth ? maxWidth : '275px'}
      multiline={true}
      effect="solid"
      wrapper={'span'}
      place={place ? place : 'top'}
      offset={offset}
      afterShow={disableTimeout ? undefined : startTimeout}
      afterHide={disableTimeout ? undefined : stopTimeout}
    />
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.string,
  maxWidth: PropTypes.string,
  disable: PropTypes.bool,
  disableTimeout: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  offset: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};
