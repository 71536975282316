export const CaseAdapter = {
  objectToCamelCase,
  objectToSnakeCase,
  toCamelCase,
  toSnakeCase,
};

function objectToCamelCase<T = any>(obj: any, transformKeyValues?: Array<string>): T {
  return objectToCase<T>(obj, toCamelCase, transformKeyValues);
}

function objectToSnakeCase<T = any>(obj: any, transformKeyValues?: Array<string>): T {
  return objectToCase<T>(obj, toSnakeCase, transformKeyValues);
}

function objectToCase<T = any>(obj: any, transformer: (text: string) => string, transformKeyValues?: Array<string>): T {
  if (typeof obj != 'object' || obj === null) {
    return obj;
  } else if (typeof obj.map === 'function') {
    return obj.map((item: any) => objectToCase(item, transformer, transformKeyValues));
  }

  const transformedObj = {} as any;
  for (const [key, value] of Object.entries(obj)) {
    const _value = typeof value === 'string' && transformKeyValues?.includes(key) ? transformer(value) : value;
    transformedObj[transformer(key)] = objectToCase(_value, transformer, transformKeyValues);
  }
  return transformedObj as T;
}

function toCamelCase(text: string): string {
  return text.replace(/([a-z]_?)_([a-z])/g, (_, a, b) => a + b.toUpperCase());
}

function toSnakeCase(text: string): string {
  return text.replace(/([a-z]_?)([A-Z])/g, '$1_$2').toLowerCase();
}
