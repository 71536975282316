import produce from 'immer';

import { ActivityFilters, TimelineActionType, TimelineReducer, TimelineState } from '@redux/Timeline/types';

const defaultState: TimelineState = {
  isFetchingActivitySet: false,
  activitySet: null,

  isFetchingDetailedActivitySets: false,
  detailedActivitySets: [],
};

export const timelineReducer: TimelineReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case TimelineActionType.TIMELINE_FETCH_ACTIVITY_START:
        state.isFetchingActivitySet = true;
        break;

      case TimelineActionType.TIMELINE_FETCH_ACTIVITY_FINISH:
        state.activitySet = action.payload.data
          ? {
              totalCount: action.payload.data.totalCount,
              activities: [...(state.activitySet?.activities ?? []), ...action.payload.data.activities],
            }
          : null;
        state.isFetchingActivitySet = false;
        break;

      case TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_START: {
        state.isFetchingDetailedActivitySets = true;
        break;
      }

      case TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH: {
        state.isFetchingDetailedActivitySets = false;

        if (action.payload.data) {
          const detailedActivitySet = {
            filters: action.payload.filters,
            ...action.payload.data,
          };
          const index = getDetailedActivitySetIndex(state, action.payload.filters);
          if (detailedActivitySet && index === -1) {
            state.detailedActivitySets.push(detailedActivitySet);
          } else {
            state.detailedActivitySets[index] = detailedActivitySet;
          }
        }
      }
    }

    return state;
  });
};

export function getDetailedActivitySet(state: TimelineState, filters: ActivityFilters) {
  const index = getDetailedActivitySetIndex(state, filters);
  return state.detailedActivitySets[index] ?? null;
}

function getDetailedActivitySetIndex(state: TimelineState, filters: ActivityFilters): number {
  return state.detailedActivitySets.findIndex(({ filters: { date, yardId, alertType } }) => {
    return date === filters.date && yardId === filters.yardId && alertType === filters.alertType;
  });
}
