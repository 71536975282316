import React, { Fragment, useCallback } from 'react';

import { Text } from '@components/common/Text';
import { DefaultCellText } from '@components/yard/YardsList/cells/DefaultCellText';
import { DefaultCellWrapper } from '@components/yard/YardsList/cells/DefaultCellWrapper';
import { LastVisitTooltipContent } from '@components/yard/YardsList/cells/LastVisitTooltipContent';
import { PracticeCellTooltipContent } from '@components/yard/YardsList/cells/PracticeCellTooltipContent';
import { YardsListCellProps } from '@components/yard/YardsList/cells/types';
import { ColumnType } from '@components/yard/YardsList/types';
import { YardsListTooltip } from '@components/yard/YardsList/YardsListTooltip';
import { Children } from '@helpers/Children';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getDate } from '@helpers/deprecated/time';
import { Sorting } from '@helpers/Sorting';
import { useActionCategoriesFetcher, useActionsNames } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';

interface PracticeOccurrencesData {
  occurrences: number;
}

interface PracticeCategoryOccurrencesData {
  [key: string]: {
    occurrences: number;
    practiceId: number;
  };
}

export const PracticeCell: React.FC<YardsListCellProps> = ({ id, column, view, colDef, data }) => {
  const t = useTranslation();

  useActionCategoriesFetcher();

  const practiceNames = useActionsNames();
  const getPracticeName = useCallback((practiceId: number) => practiceNames[practiceId] ?? '-', [practiceNames]);
  const tooltipParams = { id, column, view, colDef };
  const interactiveTooltipParams = { interactive: true, trigger: 'mouseenter focus', ...tooltipParams };

  if (data === null) {
    return null;
  }

  switch (view.key) {
    case 'lastOccurrenceDatetime':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>
              {getDate(data)}

              {data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'periodSinceLastOccurrence':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={typeof data === 'number' || !!data}>
              {maybePluralize(data, 'day', t, 's', true)}

              {typeof data === 'number' || !!data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'workerLastOccurrence':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>
              {data}

              {data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'occurrencesSinceSeasonStart':
      if (column.type === ColumnType.PRACTICE) {
        const _data = data as PracticeOccurrencesData;
        return (
          <DefaultCellWrapper>
            <DefaultCellText>
              <Text typography={'SmallParagraph'} htmlTag={'span'} dashed={_data.occurrences > 0}>
                {_data.occurrences}

                {_data.occurrences > 0 && (
                  <YardsListTooltip {...tooltipParams}>
                    {({ occurrences }) => <PracticeCellTooltipContent dates={occurrences} />}
                  </YardsListTooltip>
                )}
              </Text>
            </DefaultCellText>
          </DefaultCellWrapper>
        );
      } else {
        const _data = data as PracticeCategoryOccurrencesData;
        const occurrences = Object.values(_data);
        const nonZeroOccurrences = occurrences.filter(({ occurrences }) => occurrences > 0);
        const namedOccurrences = nonZeroOccurrences.map((occurrence) => ({
          ...occurrence,
          practiceName: getPracticeName(occurrence.practiceId),
        }));
        const sortedOccurrences = Sorting.sortAlphanumerically(namedOccurrences, 'practiceName');

        return (
          <DefaultCellWrapper>
            <DefaultCellText>
              {Children.createWithSeparators(sortedOccurrences, {
                itemWrapper: ({ practiceId, practiceName, occurrences }, separator) => (
                  <Fragment key={practiceId}>
                    <Text typography={'SmallParagraph'} htmlTag={'span'} dashed>
                      {practiceName} ({occurrences})
                      <YardsListTooltip {...tooltipParams}>
                        {(occurrencesByPractice) => (
                          <PracticeCellTooltipContent dates={occurrencesByPractice[practiceId]?.occurrences ?? []} />
                        )}
                      </YardsListTooltip>
                    </Text>
                    {separator}
                  </Fragment>
                ),
              })}
            </DefaultCellText>
          </DefaultCellWrapper>
        );
      }
    default:
      return null;
  }
};
