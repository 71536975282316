import { Path } from '@components/common/Map/types';

const NB_SQUARE_METERS_IN_ACRE = 4046.86;

export const Geometry = {
  calculateArea,
};

function calculateArea(path: Path): number {
  const areaInSquareMeters = google.maps.geometry.spherical.computeArea(path);
  return Number((areaInSquareMeters / NB_SQUARE_METERS_IN_ACRE).toFixed(2));
}
