import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { Map } from 'google-maps-react';
import { debounce } from 'lodash';

import {
  DEFAULT_GOOGLE_MAPS_DEBOUNCE,
  DEFAULT_GOOGLE_MAPS_OPTIONS,
  DEFAULT_GOOGLE_MAPS_TYPE_MAP,
} from '@components/common/Map/apis/GoogleMaps/constants';
import { GoogleMapsContext } from '@components/common/Map/apis/GoogleMaps/googleMapsContext';
import { StyledMapError } from '@components/common/Map/apis/GoogleMaps/styles';
import { Coordinate, MapProps, MapType, Padding, PixelPosition } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';
import { Text } from '@components/common/Text';
import { useTranslation } from '@hooks/useTranslation';

export function MapRenderer(props: MapProps) {
  const {
    // Default HTML attributes.
    id,
    style,
    className,

    // Provider props.
    state,
    onStateChange,
    onInstance,
    onTilesLoaded,
    onClick,
    children,
  } = props;

  const t = useTranslation();

  const googleMapContext = useContext(GoogleMapsContext);
  const google = googleMapContext?.google;

  const mapRef = useRef<Map>(null);
  const mapHasSetDefaultProps = useRef(false);

  const getMap = useCallback(() => {
    return ((mapRef.current as any)?.map ?? null) as google.maps.Map | null;
  }, []);

  const getMapWrapper = useCallback(
    (handler: (map: google.maps.Map) => (() => void) | void) => {
      const map = getMap();
      return map ? handler(map) : void 0;
    },
    [getMap]
  );

  const getMapListener = useCallback((map: google.maps.Map, events: Array<string>, handler: (...args: any) => void) => {
    const listeners = events.map((ev) => map.addListener(ev, handler));
    return () => listeners.map((listener) => listener.remove());
  }, []);

  const getMapTopLeft = useCallback(() => {
    let topLeft: google.maps.LatLng | null = null;
    getMapWrapper((map) => {
      const bounds = map.getBounds();
      if (google && bounds) {
        topLeft = new google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getSouthWest().lng());
      }
    });
    return topLeft;
  }, [getMapWrapper, google]);

  const coordinateToPixelAbsolute = useCallback(
    (coordinate: Coordinate): PixelPosition | null => {
      let pixel: PixelPosition | null = null;
      getMapWrapper((map) => {
        const numTiles = Math.pow(2, map.getZoom() ?? 0);
        const projection = map.getProjection();

        if (projection) {
          const pos = projection.fromLatLngToPoint(coordinate);
          if (pos) {
            pixel = {
              x: pos.x * numTiles,
              y: pos.y * numTiles,
            };
          }
        }
      });
      return pixel;
    },
    [getMapWrapper]
  );

  const pixelToCoordinateAbsolute = useCallback(
    (pixel: PixelPosition) => {
      let coordinate: Coordinate | null = null;
      getMapWrapper((map) => {
        const numTiles = 1 << (map.getZoom() ?? 0);
        const projection = map.getProjection();

        if (google && projection) {
          const point = new google.maps.Point(pixel.x / numTiles, pixel.y / numTiles);
          coordinate = projection.fromPointToLatLng(point)?.toJSON() ?? null;
        }
      });
      return coordinate;
    },
    [getMapWrapper, google]
  );

  const coordinateToPixel = useCallback(
    (coordinate: Coordinate) => {
      const topLeft = getMapTopLeft();
      const topLeftPX = topLeft ? coordinateToPixelAbsolute(topLeft) : null;
      const coordinatePX = coordinateToPixelAbsolute(coordinate);

      if (topLeftPX && coordinatePX) {
        return {
          x: coordinatePX.x - topLeftPX.x,
          y: coordinatePX.y - topLeftPX.y,
        };
      }
      return null;
    },
    [coordinateToPixelAbsolute, getMapTopLeft]
  );

  const pixelToCoordinate = useCallback(
    (pixel: PixelPosition) => {
      const topLeft = getMapTopLeft();
      const topLeftPX = topLeft ? coordinateToPixelAbsolute(topLeft) : null;
      if (topLeftPX) {
        return pixelToCoordinateAbsolute({
          x: topLeftPX.x + pixel.x,
          y: topLeftPX.y + pixel.y,
        });
      }
      return null;
    },
    [coordinateToPixelAbsolute, getMapTopLeft, pixelToCoordinateAbsolute]
  );

  const fitCoordinates = useCallback(
    (coordinates: Array<Coordinate>, padding?: number | Padding) => {
      getMapWrapper((map) => {
        if (google) {
          const bounds = new google.maps.LatLngBounds();
          coordinates.forEach((coord) => bounds.extend(new google.maps.LatLng(coord)));
          map.fitBounds(bounds, padding);
        }
      });
    },
    [getMapWrapper, google]
  );

  const panBy = useCallback(
    (coordinateOrPixel: Coordinate | PixelPosition) => {
      getMapWrapper((map) => {
        const distanceInPixel =
          typeof (coordinateOrPixel as any).x === 'number'
            ? (coordinateOrPixel as PixelPosition)
            : coordinateToPixel(coordinateOrPixel as Coordinate);
        distanceInPixel && map.panBy(distanceInPixel.x, distanceInPixel.y);
      });
    },
    [coordinateToPixel, getMapWrapper]
  );

  const panTo = useCallback(
    (coordinate: Coordinate) => {
      getMapWrapper((map) => {
        map.panTo(coordinate);
      });
    },
    [getMapWrapper]
  );

  useLayoutEffect(() => {
    getMapWrapper((map) => {
      if (state) {
        map.setOptions(DEFAULT_GOOGLE_MAPS_OPTIONS);
        state.center && map.setCenter(state.center);
        state.zoom && map.setZoom(state.zoom);
        mapHasSetDefaultProps.current = true;
        googleMapContext?.setMap(map);

        if (onInstance) {
          onInstance({
            coordinateToPixel,
            pixelToCoordinate,
            fitCoordinates,
            panBy,
            panTo,
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      getMapWrapper((map) => {
        const nextOptions = {} as google.maps.MapOptions;

        const nextMapType = state?.mapType;
        const nextMapSpec = nextMapType ? DEFAULT_GOOGLE_MAPS_TYPE_MAP[nextMapType] : undefined;
        const prevMapType = map.getMapTypeId();

        if (nextMapType && nextMapSpec && nextMapType !== prevMapType) {
          nextOptions.mapTypeId = nextMapSpec.typeId;
          nextOptions.styles = nextMapSpec.styles;
        }

        const prevCenter = map.getCenter()?.toJSON();
        const nextCenter = state?.center;
        if (nextCenter && !MapUtil.areCoordinatesEquals(prevCenter, nextCenter)) {
          nextOptions.center = nextCenter;
        }

        const prevZoom = map.getZoom();
        const nextZoom = state?.zoom;
        if (nextZoom && prevZoom != nextZoom) {
          nextOptions.zoom = nextZoom;
        }

        // The map zooming/panning animation doesn't work correctly
        // if the zoom is too small, so 'moveCamera' method will
        // perform the change without animations.
        if (nextOptions.center && nextOptions.zoom && prevZoom && prevZoom <= 2) {
          map.moveCamera({
            center: nextOptions.center,
            zoom: nextOptions.zoom,
          });
        }

        map.setOptions(nextOptions);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, getMapListener, getMapWrapper, onStateChange]
  );

  useLayoutEffect(() => {
    getMapWrapper((map) => {
      return getMapListener(
        map,
        ['bounds_changed'],
        debounce(() => {
          const mapSpec = Object.entries(DEFAULT_GOOGLE_MAPS_TYPE_MAP).find(
            ([, spec]) => spec.typeId === map.getMapTypeId()
          );
          const mapType = (mapSpec ? mapSpec[0] : MapType.SATELLITE) as MapType;
          const zoom = map.getZoom();
          const center = map.getCenter()?.toJSON();
          const bounds = map.getBounds()?.toJSON();

          if (mapType && zoom && center && bounds && onStateChange) {
            onStateChange({ center, zoom, mapType, bounds });
          }
        }, DEFAULT_GOOGLE_MAPS_DEBOUNCE)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMapListener, getMapWrapper, onStateChange]);

  useLayoutEffect(() => {
    return getMapWrapper((map) => {
      return getMapListener(map, ['click'], (ev: { latLng: google.maps.LatLng; pixel: google.maps.Point }) => {
        if (!(map as any).skipInteractions && onClick) {
          onClick(ev.latLng.toJSON(), ev.pixel);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClick]);

  useLayoutEffect(() => {
    return getMapWrapper((map) => {
      return getMapListener(map, ['tilesloaded'], () => {
        onTilesLoaded && onTilesLoaded();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTilesLoaded]);

  useLayoutEffect(() => {
    return getMapWrapper((map) => {
      return getMapListener(map, ['idle'], () => {
        Object.entries(DEFAULT_GOOGLE_MAPS_TYPE_MAP).forEach(([mapType, { typeId }]) => {
          if (!map.mapTypes.get(typeId)) {
            const baseStyle = mapType.includes('satellite') ? map.mapTypes.get('hybrid') : map.mapTypes.get('terrain');
            map.mapTypes.set(typeId, baseStyle);
          }
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTilesLoaded]);

  const isGoogleAvailable = !!google;
  const canRenderChildren = isGoogleAvailable && !!googleMapContext?.map;

  return (
    <MemoizedMap ref={mapRef} id={id} google={google} className={className} style={style}>
      {canRenderChildren && children}

      {!isGoogleAvailable && (
        <StyledMapError>
          <Text typography={'CaptionSmall'} color={'grey06'}>
            {t('map_error_message')}
          </Text>
        </StyledMapError>
      )}
    </MemoizedMap>
  );
}

const MemoizedMap = React.memo<any>(Map, (prev, next) => {
  return prev.children === next.children;
});
