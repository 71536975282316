import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Location = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M12.6688 19.4416C13.0542 20.2243 14.1869 20.1726 14.4995 19.3582L19.9312 5.20517C20.2417 4.3962 19.4465 3.60214 18.638 3.91378L4.49285 9.36597C3.67883 9.67973 3.62883 10.8126 4.41204 11.1968L9.79275 13.8367C9.89197 13.8854 9.97226 13.9656 10.0211 14.0647L12.6688 19.4416Z"
      />
    </IconSVGView>
  );
};
