import React from 'react';
import PropTypes from 'prop-types';

import { StyledEmptyContainer } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading1, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import APP from '@config/constants';

export const EmptyBeeTrackView = ({ heading, text }) => (
  <StyledEmptyContainer style={{ maxWidth: `${APP.noGroupsBannerWidth}px` }}>
    <StyledHeading1>{heading}</StyledHeading1>
    <StyledParagraph style={{ marginTop: '1rem', width: `${APP.noGroupsBannerWidth}px` }}>{text}</StyledParagraph>
  </StyledEmptyContainer>
);

EmptyBeeTrackView.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
