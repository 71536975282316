import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HelpFull = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.61 6.03997C10.6443 5.74061 8.75716 6.93056 8.18001 8.8333C8.09778 9.10801 8.15035 9.40543 8.32177 9.63531C8.49319 9.86518 8.76326 10.0004 9.05001 9.99997H9.25001C9.65422 9.98454 10.0057 9.71824 10.13 9.3333C10.4683 8.39116 11.451 7.84425 12.43 8.0533C13.3961 8.27346 14.0607 9.16092 14 10.15C13.9411 10.9357 13.389 11.3615 12.7755 11.8345C12.3481 12.1641 11.8909 12.5166 11.55 13.03C11.55 13.035 11.5475 13.0375 11.545 13.04C11.5425 13.0425 11.54 13.045 11.54 13.05C11.54 13.0556 11.5337 13.0644 11.5264 13.0745C11.5207 13.0824 11.5144 13.0912 11.51 13.1C11.4128 13.2593 11.3292 13.4266 11.26 13.6C11.255 13.615 11.2475 13.6275 11.24 13.64C11.2325 13.6525 11.225 13.665 11.22 13.68C11.212 13.7029 11.2053 13.7263 11.2 13.75C11.0624 14.1521 10.9947 14.5749 11 15H13C12.9973 14.6248 13.0939 14.2557 13.28 13.93C13.29 13.915 13.2975 13.9 13.305 13.885L13.305 13.885C13.3125 13.87 13.32 13.855 13.33 13.84C13.4118 13.7021 13.5055 13.5716 13.61 13.45C13.615 13.445 13.62 13.4375 13.625 13.43C13.63 13.4225 13.635 13.415 13.64 13.41C13.7435 13.2915 13.8549 13.1802 13.9733 13.0766C14.1223 12.9354 14.2795 12.7982 14.4378 12.6602L14.4378 12.6602C15.2991 11.9087 16.1914 11.1302 15.9633 9.51663C15.7269 7.74876 14.3682 6.3401 12.61 6.03997ZM11 18V16H13V18H11Z"
      />
    </IconSVGView>
  );
};
