import * as React from 'react';
import { useCallback } from 'react';
import { useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';

import type { ComboBoxProps } from '@react-types/combobox';

import { ListBox } from './ListBox';
import { Popover } from './Popover';
import { StyledDropdownWrapper, StyledInput, StyledInputWrapper, StyledLabel } from './styles';

interface InputDropDown {
  name: string;
  id?: string;
  onSelectedKeyChange: (key: string) => any;
  selectedKey?: string;
  filterType?: 'startsWith' | 'contains';
}

export function InputDropdown<T extends object>(props: ComboBoxProps<T> & InputDropDown) {
  const {
    selectedKey: selectedKeyProps,
    onSelectedKeyChange,
    filterType,
    menuTrigger = 'focus',
    ...comboBoxProps
  } = props;
  const [selectedKey, setSelectedKey] = React.useState<string | null | undefined>(selectedKeyProps);
  const { startsWith, contains } = useFilter({ sensitivity: 'base' });

  const defaultFilter = React.useMemo(() => {
    if (!filterType || filterType === 'contains') return contains;
    return startsWith;
  }, [contains, filterType, startsWith]);

  const updateSelectedKey = useCallback(
    (key: React.Key) => {
      if (key === selectedKey) return;
      if (!key) {
        setSelectedKey('');
        onSelectedKeyChange('');
      } else {
        setSelectedKey(key.toString());
        onSelectedKeyChange(key.toString());
      }
    },
    [onSelectedKeyChange, selectedKey]
  );

  const state = useComboBoxState({
    ...comboBoxProps,
    selectedKey,
    onSelectionChange: updateSelectedKey,
    menuTrigger: menuTrigger,
    defaultFilter,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...comboBoxProps,
      inputRef,

      listBoxRef,
      popoverRef,
    },
    state
  );

  React.useEffect(() => {
    if (menuTrigger === 'focus' && state.isFocused && !selectedKey) {
      state.open();
    }
  }, [state, selectedKey, menuTrigger]);

  React.useEffect(() => {
    if (selectedKeyProps === selectedKey) return;
    state.setSelectedKey(selectedKeyProps || '');
  }, [selectedKey, selectedKeyProps, state]);

  return (
    <StyledDropdownWrapper>
      <StyledInputWrapper column isDisabled={comboBoxProps.isDisabled}>
        <StyledLabel $isDisabled={comboBoxProps.isDisabled} {...labelProps}>
          {props.label}
        </StyledLabel>
        <StyledInput {...inputProps} ref={inputRef} />
      </StyledInputWrapper>
      {state.isOpen && (
        <Popover popoverRef={popoverRef} isOpen={state.isOpen} onClose={state.close}>
          <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
    </StyledDropdownWrapper>
  );
}
