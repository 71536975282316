import { useMemo } from 'react';

import { SortFunc, Sorting } from '@helpers/Sorting';

export function useAlphanumericallySorted<T = any>(items: Array<T>, sortKey: keyof T): Array<T> {
  return useMemo(() => Sorting.sortAlphanumerically<T>(items, sortKey), [items, sortKey]);
}

export function useSorted<T = any>(items: Array<T>, sortKey: keyof T): Array<T>;
export function useSorted<T = any>(items: Array<T>, sortFunc: SortFunc<T>): Array<T>;
export function useSorted<T>(items: Array<T>, sortKeyOrFunc: SortFunc<T> | keyof T): Array<T> {
  return useMemo(() => Sorting.sort<T>(items, sortKeyOrFunc), [items, sortKeyOrFunc]);
}
