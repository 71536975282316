import { MapBounds } from '@helpers/MapOptimizer/types';

export const MapOptimizerUtil = {
  getNormalizedBounds,
};

/**
 * In case the map is centered at the westernmost or easternmost longitude,
 * its current bounds value might have west longitude being greater than
 * the east longitude. To normalize it we return two bounds representing
 * the left and the right bounds of the map.
 * */
function getNormalizedBounds(mapBounds: MapBounds): Array<MapBounds> {
  if (mapBounds.west > mapBounds.east) {
    return [
      { ...mapBounds, west: -180 },
      { ...mapBounds, east: 180 },
    ];
  }

  return [{ ...mapBounds }];
}
