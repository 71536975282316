import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

const StyledBulletButton = styled.button`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${(props) => props.theme.buttonPrimaryColor};
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 20px;
  outline-style: none;
  box-shadow: ${(props) => props.theme.boxShadow3};
  transition: all ease-in-out 0.3s;
  &:hover {
    width: ${(props) => props.width};
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
  &:focus {
    box-shadow: ${(props) => props.theme.buttonFocusBoxShadow};
  }
`;

const Text = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  width: 20px;
  opacity: 0;
  transition: opacity width ease-in-out 0.13s;
  &:hover {
    opacity: 1;
    width: 100%;
    transition: opacity 0.65s width 0.3s;
    padding-left: 2rem;
    padding-right: 1rem;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  filter: invert(1);
`;

export const BulletButton = ({
  t,
  onClick,
  imgSrc,
  type = 'button',
  textToButton,
  textStyle,
  width = '102px',
  role = 'button',
  ...others
}) => {
  const copiedProps = {};

  Object.keys(others).forEach((key) => {
    // these are the props that need to get thru:
    if (key.startsWith('data-') || 'style' === key || 'className' === key) {
      copiedProps[key] = others[key];
    }
  });

  return (
    <StyledBulletButton role={role} onClick={onClick} type={type} {...copiedProps} aria-disabled="true" width={width}>
      <StyledImg src={imgSrc} alt={imgSrc} />
      <Text style={textStyle}>{textToButton}</Text>
    </StyledBulletButton>
  );
};

BulletButton.propTypes = {
  t: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  textToButton: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  textStyle: PropTypes.object,
};
