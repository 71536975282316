/**
 * Halper class to provide marker clustering.
 * */

import SuperCluster, { AnyProps, ClusterFeature, PointFeature } from 'supercluster';

import { MapBounds, MapElement, MapElementCluster } from '@helpers/MapOptimizer/types';
import { MapOptimizerUtil } from '@helpers/MapOptimizer/util';

const CLUSTERER_OPTIONS = { radius: 100 };
const MAX_LEAVE_RETRIEVAL_COUNT = 2048;

export class MapClusterer<P extends SuperCluster.AnyProps> {
  private clusterer;
  private readonly debug: boolean = false;

  constructor(private elements: Array<MapElement<P>>, options?: SuperCluster.Options<P, P>, debug = false) {
    this.clusterer = new SuperCluster<P, P>({ ...CLUSTERER_OPTIONS, ...options });
    this.debug = debug;

    this.debugTime('constructor');
    this.clusterer.load(
      elements.map(({ id, lat, lng, properties }) => ({
        id,
        properties,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      }))
    );
    this.debugTimeEnd('constructor');
  }

  public getElementsAndClusters(mapBounds: MapBounds, mapZoom: number) {
    this.debugTime('getClusters');

    const clusters = {} as Record<any, MapElementCluster<P>>;
    const elements = {} as Record<any, MapElement<P>>;

    const boundsList = MapOptimizerUtil.getNormalizedBounds(mapBounds);

    for (const bounds of boundsList) {
      const { west, south, east, north } = bounds;
      this.clusterer.getClusters([west, south, east, north], mapZoom).map((feature) => {
        if (feature.properties.cluster) {
          const cluster = this.getClusterFromFeature(feature);
          clusters[cluster.id] = cluster;
        } else {
          const element = this.getElementFromFeature(feature);
          elements[element.id] = element;
        }
      });
    }

    this.debugTimeEnd('getClusters');
    return { clusters, elements };
  }

  private getElementFromFeature = ({ id, geometry, properties }: ClusterFeature<AnyProps> | PointFeature<P>) => {
    const {
      coordinates: [lng, lat],
    } = geometry;
    return {
      id,
      lat,
      lng,
      properties: properties as P,
    } as MapElement<P>;
  };

  private getClusterFromFeature = ({ id, geometry, properties }: ClusterFeature<AnyProps> | PointFeature<P>) => {
    const { cluster_id, point_count } = properties;
    const {
      coordinates: [lng, lat],
    } = geometry;
    return {
      id,
      lat,
      lng,
      elementCount: point_count,
      getElements: () => {
        this.debugTime('getElements');

        const leaves = this.clusterer.getLeaves(cluster_id, MAX_LEAVE_RETRIEVAL_COUNT);
        const elements = leaves
          .filter((feature) => !feature.properties.cluster)
          .reduce((elements, feature) => {
            const element = this.getElementFromFeature(feature);
            return { ...elements, [element.id]: element };
          }, {} as Record<any, MapElement<P>>);

        this.debugTimeEnd('getElements');
        return elements;
      },
    } as MapElementCluster<P>;
  };

  private debugTime(tag: string): void {
    this.debug && console.time(`MapClusterer: ${tag}`);
  }

  private debugTimeEnd(tag: string): void {
    this.debug && console.timeEnd(`MapClusterer: ${tag}`);
  }
}
