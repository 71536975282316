import aMarkerSvgIcon from '@assets/gmap-icons/marker-dynamic-green.svg';
import dMarkerSvgIcon from '@assets/gmap-icons/marker-dynamic-grey.svg';
import bMarkerSvgIcon from '@assets/gmap-icons/marker-dynamic-orange.svg';
import cMarkerSvgIcon from '@assets/gmap-icons/marker-dynamic-red.svg';
import increaseIcon from '@assets/xSmall_Arrow-NE.svg';
import decreaseIcon from '@assets/xSmall_Arrow-SE.svg';
import equalIcon from '@assets/xSmall_Equal.svg';
import moreIcon from '@assets/xSmall_More.svg';
import { importAll } from '@helpers/deprecated/importAll';

const alertIcons = importAll(require.context('@assets/alerts/', false, /\.(svg)$/));
const actionIcons = importAll(require.context('@assets/actions/', false, /\.(svg)$/));
const yardTypeIcons = importAll(require.context('@assets/yard-types/', false, /\.(svg)$/));

export const getIconByType = (type, category, size) => {
  if (!type) return;

  function buildIcon(arr, n) {
    let icon = size ? `${size}${n}` : n;
    return arr.find((i) => i.includes(icon));
  }

  let catType = category?.toString().toLowerCase();

  switch (type) {
    case 'general':
      switch (catType) {
        case 'yard':
          return buildIcon(alertIcons, 'Small_Yard');
        default:
          return;
      }
    case 'alerts':
      switch (catType) {
        case 'new_yard_created':
          return buildIcon(alertIcons, 'Small_Yard');
        case 'new_practice_created':
          return buildIcon(alertIcons, 'Small_Feeding');
        // return buildIcon(alertIcons, 'Small_HiveAdded');
        case 'hive_added_to_yard':
        case 'hive_moved_to_yard':
          return buildIcon(alertIcons, 'Small_HiveMoved');
        case 'new_hive_report':
        case 'new_yard_report':
          return buildIcon(alertIcons, 'Small_Inspection');
        case 'reminder_deadouts':
          return buildIcon(alertIcons, 'Small_Deadout');
        case 'reminder_queenless':
          return buildIcon(alertIcons, 'Small_Queenless');
        case 'yard_edited_by_manager':
          return buildIcon(alertIcons, 'Small_Edit');
        case 'yard_deleted':
          return buildIcon(alertIcons, 'Small_Delete');
        default:
          return;
      }
    case 'actions':
      switch (catType) {
        case 'hive status':
          return buildIcon(actionIcons, 'Small_Show');
        case 'feeding':
          return buildIcon(actionIcons, 'Small_Feeding');
        case 'treatment':
          return buildIcon(actionIcons, 'Small_Treatment');
        case 'hive grading':
          return buildIcon(actionIcons, 'Small_Hive-grading');
        case 'hive issues':
          return buildIcon(actionIcons, 'Small_Issue');
        case 'queen management':
          return buildIcon(actionIcons, 'Small_Queen');
        case 'custom practice':
          return buildIcon(actionIcons, 'Small_Other');
        default:
          return buildIcon(actionIcons, 'Small_Show');
      }
    case 'yard-type':
      switch (catType) {
        case 'pollination':
          return buildIcon(yardTypeIcons, 'Small_Pollination');
        case 'honey':
          return buildIcon(yardTypeIcons, 'Small_Honey');
        case 'holding':
          return buildIcon(yardTypeIcons, 'Small_Holding');
        case 'indoor wintering':
          return buildIcon(yardTypeIcons, 'Small_IndoorWintering');
        case 'outdoor wintering':
          return buildIcon(yardTypeIcons, 'Small_OutdoorWintering');
        case 'mating':
          return buildIcon(yardTypeIcons, 'Small_Mating');
        default:
          return;
      }
    case 'events':
      switch (catType) {
        case 'deadout_ai':
          return buildIcon(alertIcons, 'Small_Deadout');
        case 'queenless_ai':
          return buildIcon(alertIcons, 'Small_Queenless');
        case 'no_transmission_bc':
        case 'low_battery_bc':
        case 'low_data_rate_bc':
          return buildIcon(alertIcons, 'Small_Beecon');
        case 'no_transmission_bh':
        case 'not_charging_bh':
        case 'new_beehub_bh':
          return buildIcon(alertIcons, 'Small_Beehub');
        default:
          return;
      }
    default:
      return;
  }
};

export const getPopTrend = (population) => {
  switch (true) {
    case 0 === population:
      return equalIcon;
    case 0 < population:
      return increaseIcon;
    case 0 > population:
      return decreaseIcon;
    default:
      return moreIcon;
  }
};

export const getMarkerIcon = (yard) => {
  const { recently_updated, total_hives, hardware, ai_events } = yard;
  const { nb_no_transmission } = hardware;
  const { criticality } = ai_events;
  const noGoodYard = !recently_updated || total_hives === nb_no_transmission;
  switch (true) {
    case noGoodYard:
      return dMarkerSvgIcon;
    case criticality > 30:
      return cMarkerSvgIcon;
    case criticality <= 30 && criticality >= 15:
      return bMarkerSvgIcon;
    case criticality < 15:
      return aMarkerSvgIcon;
    default:
      return dMarkerSvgIcon;
  }
};
