import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  AlertsIconContainer,
  Bold,
  DropColumn,
  StyledCol,
  StyledDesc,
  StyledImg,
  StyledRow,
  StyledTextButton,
} from '@components/deprecated/Elements/EventsElements';
import { StyledCaptionSmall } from '@components/deprecated/Elements/TypographyElements';
// nectar
import { getIconByType } from '@helpers/deprecated/icons';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getDate } from '@helpers/deprecated/time';

export const BeeTrackDropdownEventsView = ({ t, eventObj, handleEditYard }) => {
  const {
    yard_id,
    event_details,
    analyzed_at,
    yard_name, // yard name to be the updated one, not the one that was at the time of alert
  } = eventObj;

  const buildCustomAlertString = (alert) => {
    const {
      alert_type,
      user_name,
      nb_hives_created,
      nb_hives_moved,
      inspection_nb_hives,
      yard_total_nb_hives,
      city_name,
      edited_information,
      has_yard_been_resized,
    } = alert;

    let user = user_name && ` by ${user_name}`;
    let city = city_name && ` around ${city_name}`;
    let totalNumHives = nb_hives_created + nb_hives_moved;
    let hives_excluded = yard_total_nb_hives - inspection_nb_hives;
    let additional_info = '';
    let information = edited_information?.map((info) => t(info));

    if (0 !== hives_excluded && yard_total_nb_hives)
      additional_info = ` (${maybePluralize(hives_excluded, 'hive', t)} excluded)`;

    switch (alert_type) {
      case 'new_yard_created':
        return (
          <>
            <Bold>{yard_name} created</Bold>
            {city}
            {user}.
          </>
        );
      case 'hive_added_to_yard':
        return (
          <>
            <Bold>{maybePluralize(totalNumHives, 'hive', t)}</Bold> added to <Bold>{yard_name}</Bold>
            {user}. {has_yard_been_resized && t('yard_boundaries_updated')}
          </>
        );
      case 'new_hive_report':
        return (
          <>
            <Bold>{t('hive_inspection')}</Bold> for <Bold>{maybePluralize(inspection_nb_hives, 'hive', t)}</Bold> at{' '}
            <Bold>{yard_name}</Bold>
            {user}.
          </>
        );
      case 'new_yard_report':
        return (
          <>
            <Bold>{t('yard_inspection')}</Bold> at <Bold>{yard_name}</Bold>
            {user}
            {additional_info}.
          </>
        );
      case 'yard_deleted':
        return (
          <>
            <Bold>{yard_name}</Bold> was <Bold>deleted</Bold>
            {user}.
          </>
        );
      case 'yard_edited_by_manager':
        return (
          <>
            <Bold>
              {yard_name} {information.join(', ')}
            </Bold>{' '}
            were <Bold>updated</Bold>
            {user}.
          </>
        );

      // for the future, maybe
      case 'reminder_deadouts':
        return (
          <>
            At <Bold>{yard_name}</Bold>, {maybePluralize(alert.nb_deadouts, 'hive', t)} have been flagged as deadout{' '}
            <Bold>for {moment(alert.deadout_since).local().fromNow(true)}.</Bold>{' '}
          </>
        );
      case 'reminder_queenless':
        return (
          <>
            At <Bold>{yard_name}</Bold>, {maybePluralize(alert.nb_queenless, 'hive', t)} have been flagged as queenless{' '}
            <Bold>for {moment(alert.queenless_since).local().fromNow(true)}.</Bold>{' '}
          </>
        );
      case 'new_practice_created':
        return (
          <>
            <Bold>{alert.action}</Bold> now available in inspection reports under <Bold>{alert.category}.</Bold>{' '}
          </>
        );
      default:
        return null;
    }
  };

  if ('hive_moved_to_yard' === event_details.alert_type) return null;

  return (
    <StyledRow>
      <DropColumn xs={3}>
        <AlertsIconContainer>
          <StyledImg src={getIconByType('alerts', event_details.alert_type, 'x')} alt="hive alert icon" />
        </AlertsIconContainer>
      </DropColumn>

      <StyledCol xs={9}>
        <StyledDesc>
          {buildCustomAlertString(event_details)}
          <br />
          <StyledCaptionSmall
            style={{
              marginTop: '0.5rem',
            }}
          >
            {getDate(analyzed_at)}
          </StyledCaptionSmall>
        </StyledDesc>

        {'new_yard_created' === event_details.alert_type && (
          <StyledTextButton type="button" label={t('rename')} onClick={() => handleEditYard(yard_id)} />
        )}
      </StyledCol>
    </StyledRow>
  );
};

BeeTrackDropdownEventsView.propTypes = {
  t: PropTypes.func.isRequired,
  eventObj: PropTypes.shape({
    yard_name: PropTypes.string,
    yard_id: PropTypes.number,
    event_details: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    type: PropTypes.string.isRequired,
    analyzed_at: PropTypes.string,
  }).isRequired,
  handleEditYard: PropTypes.func.isRequired,
};
