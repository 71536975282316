import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledMapWrapper = styled(Box)(
  () => `
  position: absolute;
  inset: 0;
`
);
