import React, { useCallback, useMemo } from 'react';

import { BlockPolygonText } from '@components/common/BlockPolygonText';
import { Polygon } from '@components/common/Map';
import { Path } from '@components/common/Map/types';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { AnyInstance, TransientBlock } from '@components/pollination/PolliMap/types';

export interface PolliMapBlocksProps {
  zIndex?: number;
  onBlockClick?: (block: AnyInstance<TransientBlock>) => void;
  onBlockPathChange?: (block: AnyInstance<TransientBlock>, path: Path | null) => void;
  onBlockDraggingChange?: (block: AnyInstance<TransientBlock>, dragging: boolean) => void;
}

export const PolliMapBlocks: React.VFC<PolliMapBlocksProps> = ({
  zIndex,
  onBlockClick,
  onBlockPathChange,
  onBlockDraggingChange,
}) => {
  const {
    isStatic,
    blocks,
    selectedBlock,
    getKey,
    isManagingABlock,
    isManagingDrops,
    isManagingADrop,
    isManagingPois,
    isManagingAPoi,
  } = usePolliMap();

  const blocksList = useMemo(() => {
    const visibleBlocksDict = { ...blocks.added, ...blocks.edited } as Record<string, AnyInstance<TransientBlock>>;
    if (selectedBlock) {
      const selectedBlockKey = getKey(selectedBlock);
      visibleBlocksDict[selectedBlockKey] = selectedBlock;
    }
    return Object.values(visibleBlocksDict);
  }, [blocks.added, blocks.edited, getKey, selectedBlock]);

  // TODO: Review and simplify bellow boolean methods.

  const isBlockEditable = useCallback(
    (block: AnyInstance<TransientBlock>) => {
      return isManagingABlock && Boolean(selectedBlock && getKey(selectedBlock) === getKey(block));
    },
    [isManagingABlock, getKey, selectedBlock]
  );

  const isBlockSelected = useCallback(
    (block: AnyInstance<TransientBlock>) => {
      return Boolean(selectedBlock && getKey(selectedBlock) === getKey(block));
    },
    [getKey, selectedBlock]
  );

  const isBlockDisabled = useCallback(
    (block: AnyInstance<TransientBlock>) => {
      const isManagingAnotherBlock = isManagingABlock && selectedBlock && getKey(selectedBlock) !== getKey(block);
      return Boolean(isManagingPois || isManagingDrops || isManagingAPoi || isManagingADrop || isManagingAnotherBlock);
    },
    [isManagingABlock, selectedBlock, getKey, isManagingPois, isManagingDrops, isManagingAPoi, isManagingADrop]
  );

  return (
    <>
      {blocksList.map((block) => (
        <Polygon
          key={getKey(block)}
          path={block.path}
          editable={isBlockEditable(block)}
          selected={isBlockSelected(block)}
          disabled={isBlockDisabled(block)}
          onClick={() => onBlockClick && onBlockClick(block)}
          onPathChange={(path) => onBlockPathChange && onBlockPathChange(block, path)}
          onDraggingChange={(dragging) => onBlockDraggingChange && onBlockDraggingChange(block, dragging)}
          alwaysShowChildren={isStatic}
          childrenMarkerProps={{ zIndex }}
        >
          <BlockPolygonText block={block} />
        </Polygon>
      ))}
    </>
  );
};
