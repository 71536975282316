import React, { useCallback, useMemo, useState } from 'react';

import { StyledInspectionHiveHighlighted, StyledInspectionHiveLink } from '@components/hive/HiveActivityCard/styles';
import { useHiveModalOpener } from '@components/hive/HiveModal/hooks';
import { Children } from '@helpers/Children';
import { useTranslation } from '@hooks/useTranslation';

const DEF_HIVE_TAGS_PREVIEW_SIZE = 4;

export const HiveActivityCardExpandableTags: React.VFC<{
  hives: BeeActivityByRequest['hiveIdentities'];
  highlightedHiveId?: number;
}> = ({ hives, highlightedHiveId }) => {
  const [isPreviewMode, setIsPreviewMode] = useState(true);

  const t = useTranslation();
  const { openHiveModal } = useHiveModalOpener();

  const hiddenItemsCount = Math.max(0, hives.length - DEF_HIVE_TAGS_PREVIEW_SIZE);
  const showToggleControl = hiddenItemsCount > 0;

  const sortedHives = useMemo(() => {
    return [...hives]
      .map(({ tag, ...props }) => ({
        ...props,
        tag: tag.replace(/^.*(\d{7})$/, '$1'), // Keep only the last 7 digits
      }))
      .sort((a, b) => (a.id === highlightedHiveId ? -1 : b.id === highlightedHiveId ? 1 : a.tag.localeCompare(b.tag)));
  }, [highlightedHiveId, hives]);

  const slicedHives = useMemo(() => {
    return isPreviewMode ? sortedHives.slice(0, DEF_HIVE_TAGS_PREVIEW_SIZE) : sortedHives;
  }, [isPreviewMode, sortedHives]);

  const togglePreviewMode = useCallback(() => {
    setIsPreviewMode((curr) => !curr);
  }, []);

  return (
    <>
      {Children.createWithSeparators(slicedHives, {
        separator: ' , ',
        singleSeparator: ' and ',
        lastSeparator: isPreviewMode ? ' , ' : ' , and ',
        itemWrapper: (item, separator) => {
          const isHighlighted = item.id === highlightedHiveId;
          return (
            <>
              {isHighlighted ? (
                <StyledInspectionHiveHighlighted>{item.tag}</StyledInspectionHiveHighlighted>
              ) : (
                <StyledInspectionHiveLink onClick={() => !isHighlighted && openHiveModal(item.id)}>
                  {item.tag}
                </StyledInspectionHiveLink>
              )}

              {separator}
            </>
          );
        },
      })}
      {showToggleControl && (isPreviewMode ? ` , ` : <br />)}
      {showToggleControl && (
        <StyledInspectionHiveLink onClick={togglePreviewMode}>
          {isPreviewMode ? `+${hiddenItemsCount}` : t('show_less')}
        </StyledInspectionHiveLink>
      )}
    </>
  );
};
