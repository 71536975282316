import React, { ComponentType, useMemo } from 'react';

import { Feeding } from '@components/common/Icon/presets/Feeding';
import { HiveGrading } from '@components/common/Icon/presets/HiveGrading';
import { Inspection } from '@components/common/Icon/presets/Inspection';
import { Issue } from '@components/common/Icon/presets/Issue';
import { QueenCrown } from '@components/common/Icon/presets/QueenCrown';
import { Show } from '@components/common/Icon/presets/Show';
import { Treatment } from '@components/common/Icon/presets/Treatment';
import { IconSVGProps } from '@components/common/Icon/types';
import { useActionCategories } from '@hooks/useActions';

const ACTION_ICON_DEFAULT = Show;
const ACTION_ICON_PRESETS = {
  feeding: Feeding,
  treatment: Treatment,
  hive_status: Show,
  hive_grading: HiveGrading,
  hive_issues: Issue,
  queen_management: QueenCrown,
  custom_practice: Inspection,
} as Record<string, ComponentType<IconSVGProps>>;

export interface ActionIconProps extends IconSVGProps {
  actionId?: number;
}

function getIconPreset(categoryFlag?: string) {
  categoryFlag = categoryFlag?.replace(/\s+/, '_').toLowerCase();

  if (categoryFlag) {
    for (const [flag, icon] of Object.entries(ACTION_ICON_PRESETS)) {
      if (categoryFlag.startsWith(flag)) {
        return icon;
      }
    }
  }

  return ACTION_ICON_DEFAULT;
}

export const ActionIcon: React.VFC<ActionIconProps> = ({ actionId, ...props }) => {
  const categories = useActionCategories();
  const category = useMemo(
    () => categories.find(({ actions }) => actions.some(({ id }) => id === actionId)),
    [actionId, categories]
  );

  const IconComponent = getIconPreset(category?.flag);
  return <IconComponent {...props} />;
};
