import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';
import { YardVisitCardProps } from '@components/common/YardVisitCard/types';

export const StyledVisitCardItem = styled(Box).attrs({
  stretch: true,
})(
  () => css`
    &:not(:last-child) {
      border-bottom: 1px solid;
      border-color: inherit;
    }
  `
);

export const StyledVisitCardIcon = styled(Box).attrs({
  paddingBottomXS: true,
  paddingLeftXS: true,
})(
  () => css`
    margin-top: 13px;
  `
);

export const StyledVisitCardText = styled(Text)<
  Pick<YardVisitCardProps, 'preset'> & Omit<TextProps, 'typography'> & { typography?: string }
>`
  b,
  strong {
    font-weight: 600;
  }
`;

export const StyledVisitCardLeftText = styled(StyledVisitCardText).attrs((props: YardVisitCardProps) => ({
  htmlTag: 'span',
  typography: props.preset === 'timeline' ? 'Heading3' : 'CaptionSmall',
}))(
  ({ theme, preset }) => css`
    display: inline-block;
    margin: ${theme.spacing.xs}px;

    ${preset === 'tooltip' &&
    css`
      width: 160px;
    `};
  `
);

export const StyledVisitCardRightText = styled(StyledVisitCardText).attrs((props: YardVisitCardProps) => ({
  htmlTag: 'span',
  typography: props.preset === 'timeline' ? 'SmallParagraph' : 'CaptionSmall',
}))(
  ({ theme }) => css`
    display: flex;
    min-width: 36px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid;
    border-color: inherit;
    padding: ${theme.spacing.xs}px;
    margin-left: auto;
  `
);

export const StyledVisitCardNotesText = styled(StyledVisitCardText).attrs((props: YardVisitCardProps) => ({
  htmlTag: 'span',
  typography: props.preset === 'timeline' ? 'SmallParagraph' : 'CaptionSmall',
}))(
  () => css`
    display: flex;
    width: 160px;
  `
);

export const StyledVisitCard = styled(Box).attrs({
  column: true,
  stretch: true,
})<{ preset: YardVisitCardProps['preset'] }>(
  ({ theme, preset }) => css`
    border: 1px solid;

    ${preset === 'timeline' &&
    css`
      border-radius: ${theme.shape.paperRadius02}px;
      background-color: ${theme.colors.coreWhite};
      color: ${theme.colors.grey08};
      padding: ${theme.spacing.xs}px;

      ${StyledVisitCardRightText} {
        border-left: none;
      }

      &,
      * {
        border-color: ${theme.colors.grey04};
      }
    `}

    ${preset === 'tooltip' &&
    css`
      border-radius: ${theme.shape.paperRadius01}px;
      background-color: ${theme.colors.grey08};
      color: ${theme.colors.coreWhite};

      &,
      * {
        border-color: ${theme.colors.grey06};
      }
    `}
  `
);
