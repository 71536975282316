import produce from 'immer';

import {
  YardsGlobalSearchActionType,
  YardsGlobalSearchReducer,
  YardsGlobalSearchState,
} from '@redux/YardsGlobalSearch/types';

const defaultState: YardsGlobalSearchState = {
  isFetching: false,
  yards: null,
  history: [],
};

restoreFromSessionStorage(defaultState);
cleanUpHistory(defaultState);

export const yardsGlobalSearchReducer: YardsGlobalSearchReducer = (state = defaultState, action) => {
  return produce(state, (nextState) => {
    switch (action.type) {
      case YardsGlobalSearchActionType.GLOBAL_SEARCH_FETCH_YARDS_START:
        nextState.isFetching = true;
        break;
      case YardsGlobalSearchActionType.GLOBAL_SEARCH_FETCH_YARDS_FINISH:
        nextState.yards = action.payload.data;
        nextState.isFetching = false;
        break;
      case YardsGlobalSearchActionType.GLOBAL_SEARCH_PUSH_TO_HISTORY:
        nextState.history.push(action.payload.data.id);
        break;
    }

    cleanUpHistory(nextState);
    saveToSessionStorage(nextState);
  });
};

/**
 * Removes invalid yard ids from history.
 * Normally an id becomes invalid when the
 * respective yard is removed.
 * */
function cleanUpHistory(state: YardsGlobalSearchState) {
  if (!state.yards) {
    return;
  }

  const availableYardsIds = state.yards.map((yard) => yard.id);
  state.history = state.history.filter((id) => availableYardsIds.includes(id));
}

function restoreFromSessionStorage(state: YardsGlobalSearchState) {
  try {
    const restoredState = JSON.parse(sessionStorage.getItem('global-search-storage-state') ?? 'null');
    state.history = restoredState.history ?? [];
  } catch (error) {
    console.warn(`Can't restore global search`, error);
  }
}

function saveToSessionStorage(state: YardsGlobalSearchState) {
  try {
    sessionStorage.setItem('global-search-storage-state', JSON.stringify({ history: state.history }));
  } catch (error) {
    console.warn(`Can't persist global search`, error);
  }
}
