import React from 'react';
import { useTheme } from 'styled-components';

import { useModalDeferredOpenState } from '@components/common/Modal/hooks';
import { useColor } from '@style/theme/hooks';

import { ModalBase } from './ModalBase';
import { StyledModal } from './styles';
import { ModalProps, ModalSize } from './types';

const DEF_MODAL_SIZES: Record<ModalSize, string> = { auto: 'auto', small: '420px', large: '540px', xLarge: '640px' };
const DEF_MODAL_SIZE: ModalSize = 'large';

export const Modal: React.FC<ModalProps> = ({
  size: propSize,
  backgroundColor: propBackgroundColor,
  children,
  ...props
}) => {
  const theme = useTheme();
  const width = DEF_MODAL_SIZES[propSize ?? DEF_MODAL_SIZE];
  const backgroundColor = useColor(propBackgroundColor, theme.colors.coreWhite);
  const isOpen = useModalDeferredOpenState(props.isOpen);

  return (
    <ModalBase shouldCloseOnOverlayClick {...props}>
      <StyledModal $width={width} $backgroundColor={backgroundColor} open={isOpen}>
        {children}
      </StyledModal>
    </ModalBase>
  );
};
