import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import eyeOffIcon from '@assets/Small_Hide.svg';
// nectar:
import eyeOnIcon from '@assets/Small_Show.svg';

const StyledFormRow = styled.div`
  position: relative;
  top: 0;
  overflow: hidden;
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  max-width: ${(props) => props.theme.maxDesktopWidth};
  height: 4rem;
  margin: 0 auto;
  padding-left: 1rem;
  padding-top: 20px;
  background-color: ${(props) => (props.disabled ? 'rgba(102,102,102,0.05)' : props.theme.primaryBackgroundColor)};
  color: ${(props) => (props.error ? props.theme.criticalColor : props.theme.primaryTextColor)};
  border: none;
  outline: none;
  font-family: 'Open Sans';
  font-size: ${(props) => props.theme.paragraphFontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  line-height: 22px;
  letter-spacing: 0;
  ::placeholder {
    font-family: 'Open Sans';
    color: rgba(55, 67, 65, 0.6);
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 12px;
  left: 1rem;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  overflow: hidden;
  color: ${(props) =>
    props.disabled ? 'rgba(55,67,65,0.6)' : props.error ? props.theme.criticalColor : props.theme.supportingColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
  letter-spacing: 0;
  &:disabled {
    color: rgba(55, 67, 65, 0.6);
  }
`;

const StyledViewPasswordBtn = styled.button`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 0.5rem;
  top: 20px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  filter: ${(props) => props.theme.primaryColorFilter};
`;

export class TextInput extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
    this.isPassword = 'password' === props.type;
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
  }

  handleClickShowPassword(e) {
    e.preventDefault();
    this.setState((state) => ({ showPassword: !state.showPassword }));
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const {
      id,
      name,
      label,
      value,
      defaultValue,
      handleChange,
      type,
      styles,
      required = false,
      disabled = false,
      error = false,
    } = this.props;

    const { showPassword } = this.state;

    return (
      <StyledFormRow>
        <StyledInput
          onChange={handleChange}
          id={id}
          name={name}
          placeholder={defaultValue}
          value={value ? value : ''}
          type={this.isPassword && showPassword ? 'text' : type}
          style={styles}
          required={required}
          disabled={disabled}
          error={error}
        />
        <StyledLabel error={error} disabled={disabled} htmlFor={id}>
          {label}
        </StyledLabel>
        {'password' === type && (
          <StyledViewPasswordBtn onClick={this.handleClickShowPassword} type="button" role="button" tabIndex={-1}>
            <img
              src={showPassword ? eyeOffIcon : eyeOnIcon}
              alt={showPassword ? 'password shown' : 'password hidden'}
            />
          </StyledViewPasswordBtn>
        )}
      </StyledFormRow>
    );
  }
}

TextInput.defaultProps = {
  required: false,
  disabled: false,
  error: false,
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  styles: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
