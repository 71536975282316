import React from 'react';
import { actionTypes } from 'react-redux-snackbar/src/state/snackbarReducer';

import checkIcon from '@assets/xSmall_Check.svg';
import APP from '@config/constants';
import { getUniqId } from '@helpers/deprecated/getUniqId';
import theme from '@style/theme';

import i18n from '../../../i18n';

export const makeShowSnackbarAction = (msg = '', params = { status: '', icon: false }) => {
  return {
    type: actionTypes.SHOW_SNACK,
    payload: {
      id: getUniqId(),
      data: {
        label: (
          <>
            {params.icon && (
              <img
                style={{
                  filter: theme.snackFilter,
                  marginRight: '1rem',
                }}
                src={checkIcon}
                alt="check icon"
              />
            )}
            {i18n.t(msg, params)}
          </>
        ),
        timeout: APP.snackbarDuration,
        button: { label: !params.icon ? 'OK' : '' },
      },
    },
  };
};
