import config from '@config/config';

export const API_URL = config.REACT_APP_API_URL;

export const ENDPOINTS = {
  login: `${API_URL}auth/login`,

  logout: `${API_URL}auth/logout`,

  passwordChange: `${API_URL}auth/password/change`,

  passwordForgotten: `${API_URL}auth/password/reset`,

  passwordRecreate: `${API_URL}auth/password/reset/confirm`,

  users: `${API_URL}users/current`,

  yardList: `${API_URL}yardlist`,

  hhtYards: `${API_URL}hht-yards/`,

  hhtYardDetail: `${API_URL}hht-yards/{{id}}/`,

  yardsListSummary: `${API_URL}hht-yards/summary`,

  yardsBatch: `${API_URL}hht-yards/batch`,

  yardsList: `${API_URL}hht-yards/list`,

  yardsListWhiteboard: `${API_URL}hht-yards/whiteboard`,

  yardsListWhiteboardTooltips: `${API_URL}hht-yards/whiteboard-tooltips`,

  yardsMap: `${API_URL}hht-yards/map`,

  yardsMetricAvailability: `${API_URL}hht-yards/metrics/availability`,

  yardsMetric: `${API_URL}hht-yards/metrics`,

  metricTrend: `${API_URL}hht-yards/metric/trend/{{name}}`,

  hives: `${API_URL}hivelist`,

  hhtHiveList: `${API_URL}hht-hivelist/`,

  hhtHiveDetail: `${API_URL}hht-hivelist/{{id}}/`,

  hhtActivitiesByRequest: `${API_URL}activities-by-request`,

  hhtActivitiesByDate: `${API_URL}activities-by-date`,

  chart: `${API_URL}hivechart`,

  export: `${API_URL}csvexport`,

  eventList: `${API_URL}eventlist`,

  workerList: `${API_URL}workerlist/`,

  workerDetail: `${API_URL}workerlist/{{id}}/`,

  actionList: `${API_URL}actionlist?see_disabled_actions=true&lang=EN`,

  action: `${API_URL}action`,

  yardType: `${API_URL}yardtype`,

  activities: `${API_URL}activities`,

  translations: `${API_URL}translations`,

  filters: `${API_URL}hht-yards/filters`,

  groups: `${API_URL}groups/`,

  groupsDetails: `${API_URL}groups/list`,

  groupsBatch: `${API_URL}groups/batch`,

  assignedGroups: `${API_URL}groups/assignment`,

  operation: `${API_URL}operation/current`,

  cropTypes: `${API_URL}hht-crop-types/`,

  contract: `${API_URL}contract/`,

  contractSummary: `${API_URL}contract/summary`,

  contractWithId: `${API_URL}contract/{{id}}/`,

  assignContract: `${API_URL}contract/{{contract_id}}/drops/`,

  pollinationBatch: `${API_URL}contract/{{id}}/map_batch/`,

  dropsList: `${API_URL}hht-yards/list`,

  dropsMap: `${API_URL}hht-yards/pollinationmap`,

  blocks: `${API_URL}block/`,

  pois: `${API_URL}point-of-interest/`,
};
