import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { URLUtil } from '@helpers/URL';
import { makeFetchYardListRequestThunk } from '@redux/deprecated/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { ThunkAction } from '@redux/types';

import { AssignContractAction, AssignContractActionType, AssignContractYard } from './types';

export const makeOpenAssignContractModal = (selectedYards: Array<AssignContractYard>): AssignContractAction => ({
  type: AssignContractActionType.OPEN_ASSIGN_CONTRACT_MODAL,
  payload: selectedYards,
});
export const makeCloseAssignContractModal = (): AssignContractAction => ({
  type: AssignContractActionType.CLOSE_ASSIGN_CONTRACT_MODAL,
});

export const makePatchAssignContractBatchStartAction = (): AssignContractAction => ({
  type: AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_START,
});

export const makePatchAssignContractBatchSuccessAction = (): AssignContractAction => ({
  type: AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_SUCCESS,
});

export const makePatchAssignContractBatchFailureAction = (): AssignContractAction => ({
  type: AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_FAILURE,
});

export const makePatchAssignContractBatchThunk = (contractId: number, yardIds: number[]): ThunkAction => {
  return async (dispatch): Promise<boolean> => {
    dispatch(makePatchAssignContractBatchStartAction());

    const patchURL = URLUtil.buildURL(ENDPOINTS.assignContract, { contract_id: contractId });
    const response = await Api.patch(patchURL, { yard_ids: yardIds });

    if (response.error) {
      dispatch(makePatchAssignContractBatchFailureAction());
      dispatch(makeShowSnackbarAction(response.error));
      return false;
    } else {
      const data = await response.json();
      dispatch(makePatchAssignContractBatchSuccessAction());
      dispatch(makeFetchYardListRequestThunk());
      dispatch(makeCloseAssignContractModal());
      dispatch(makeShowSnackbarAction({ translation: 'assign_contract_success' }));
      return true;
    }
  };
};
