import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { URLUtil } from '@helpers/URL';

export function useYardsFilters() {
  return useSelector((state) => state.yardsFiltersReducer);
}

export function useAppliedYardsFilters() {
  const appliedFilters = useSelector((state) => state.yardsFiltersReducer.appliedFilters);
  const [localAppliedFilters, setLocalAppliedFilters] = useState(appliedFilters);

  useEffect(() => {
    if (URLUtil.buildURL(appliedFilters) !== URLUtil.buildURL(localAppliedFilters)) {
      setLocalAppliedFilters({ ...appliedFilters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return localAppliedFilters;
}
