import produce from 'immer';

import {
  AppliedFilters,
  AvailableFilters,
  IncompatibleFilterValues,
  YardsFiltersActionType,
  YardsFiltersCategory,
  YardsFiltersCategoryKey,
  YardsFiltersFlagValuesKey,
  YardsFiltersReducer,
  YardsFiltersState,
  YardsFiltersStatusValuesKey,
  YardsFilterValuesKey,
} from '@redux/YardsFilters/types';

const defaultAppliedFilters: AppliedFilters = {
  [YardsFiltersCategoryKey.YARD_STATUS]: [YardsFiltersStatusValuesKey.ALL_YARDS],
};

const incompatibleFilterValues: IncompatibleFilterValues = {
  [YardsFiltersStatusValuesKey.INACTIVE_YARDS]: {
    [YardsFiltersCategoryKey.YARD_FLAG]: [
      YardsFiltersFlagValuesKey.QUEEN_LESS,
      YardsFiltersFlagValuesKey.REQUIRING_ATTENTION,
    ],
  },
};

const defaultState: YardsFiltersState = {
  availableFilters: {},
  transientAvailableFilters: {},
  transientAppliedFilters: {},
  appliedFilters: defaultAppliedFilters,
  appliedFiltersCount: 0,
  isFetching: false,
  fetchError: null,
};

migrateFiltersFromStorage();
restoreAppliedFiltersFromStorage(defaultState);
updateAppliedFiltersCount(defaultState);

export const yardsFiltersReducer: YardsFiltersReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case YardsFiltersActionType.FETCH_FILTERS_AVAILABILITIES_START:
        state.isFetching = true;
        break;
      case YardsFiltersActionType.FETCH_FILTERS_AVAILABILITIES_FINISH: {
        state.isFetching = false;
        state.fetchError = action.payload.error;

        const cleanedAppliedAvailable = cleanupFilters(state.appliedFilters, action.payload.data);
        state.appliedFilters = cleanedAppliedAvailable.appliedFilters;
        state.availableFilters = cleanedAppliedAvailable.availableFilters;

        const cleanedAppliedAvailableTransient = cleanupTransientFilters(state.appliedFilters, state.availableFilters);
        state.transientAppliedFilters = cleanedAppliedAvailableTransient.appliedFilters;
        state.transientAvailableFilters = cleanedAppliedAvailableTransient.availableFilters;

        updateAppliedFiltersCount(state);
        saveYardsFiltersStateToStorage(state);
        break;
      }
      case YardsFiltersActionType.PATCH_APPLIED_FILTERS: {
        const cleanedAppliedAvailable = cleanupFilters(
          { ...state.appliedFilters, ...action.payload.data },
          state.availableFilters
        );

        state.appliedFilters = cleanedAppliedAvailable.appliedFilters;
        state.availableFilters = cleanedAppliedAvailable.availableFilters;
        updateAppliedFiltersCount(state);
        saveYardsFiltersStateToStorage(state);
        break;
      }
      case YardsFiltersActionType.CLEAR_APPLIED_FILTERS:
        state.appliedFilters = { ...defaultAppliedFilters };
        updateAppliedFiltersCount(state);
        saveYardsFiltersStateToStorage(state);
        break;
      case YardsFiltersActionType.PATCH_TRANSIENT_FILTERS: {
        const cleanedAppliedAvailableTransient = cleanupTransientFilters(
          { ...state.transientAppliedFilters, ...action.payload.data },
          state.availableFilters
        );

        state.transientAppliedFilters = cleanedAppliedAvailableTransient.appliedFilters;
        state.transientAvailableFilters = cleanedAppliedAvailableTransient.availableFilters;
        break;
      }
      case YardsFiltersActionType.CLEAR_TRANSIENT_FILTERS:
        state.transientAppliedFilters = { ...defaultAppliedFilters };
        state.transientAvailableFilters = { ...state.availableFilters };
        break;
    }

    return state;
  });
};

function cleanupFilters(appliedFilters: AppliedFilters, availableFilters: AvailableFilters) {
  ({ appliedFilters, availableFilters } = cleanupStaleFilters(appliedFilters, availableFilters));
  ({ appliedFilters, availableFilters } = cleanupDefaultValuesAreApplied(appliedFilters, availableFilters));
  ({ appliedFilters, availableFilters } = deselectInvalidFilterCombinations(appliedFilters, availableFilters));

  return { appliedFilters, availableFilters };
}

function cleanupTransientFilters(appliedFilters: AppliedFilters, availableFilters: AvailableFilters) {
  ({ appliedFilters, availableFilters } = cleanupFilters(appliedFilters, availableFilters));

  // Only doing this cleanup for transient filters as the filter availability only depends on currently selected values
  ({ appliedFilters, availableFilters } = disableInvalidFilters(appliedFilters, availableFilters));

  return { appliedFilters, availableFilters };
}

/**
 * Removes from applied filters all values not found in the
 * available filters.
 * */
function cleanupStaleFilters(appliedFilters: AppliedFilters, availableFilters: AvailableFilters) {
  const clearedAppliedFilters = {} as AppliedFilters;

  for (const [categoryKey, values] of Object.entries(appliedFilters) as Array<
    [YardsFiltersCategoryKey, Array<string>]
  >) {
    for (const value of values) {
      const availableCategory = availableFilters[categoryKey];
      const availableCategoryValues = (availableCategory?.values ?? {}) as Record<string, boolean>;
      const isFilterValueAvailable = availableCategoryValues[value];

      if (isFilterValueAvailable) {
        clearedAppliedFilters[categoryKey] = [...(clearedAppliedFilters[categoryKey] ?? []), value];
      }
    }
  }

  return { appliedFilters: clearedAppliedFilters, availableFilters };
}

/**
 * Ensures the default values are applied within a category if no other values within this category has been restored
 * */
function cleanupDefaultValuesAreApplied(appliedFilters: AppliedFilters, availableFilters: AvailableFilters) {
  const cleanedAppliedFilters = { ...appliedFilters };

  for (const [categoryKey, values] of Object.entries(defaultAppliedFilters) as Array<
    [YardsFiltersCategoryKey, Array<string>]
  >) {
    const areNoValuesSelected = !(categoryKey in cleanedAppliedFilters) || !cleanedAppliedFilters[categoryKey]?.length;
    if (areNoValuesSelected) {
      cleanedAppliedFilters[categoryKey] = values;
    }
  }

  return { appliedFilters: cleanedAppliedFilters, availableFilters };
}

/**
 * Removes invalid applied filter combinations
 * */
function deselectInvalidFilterCombinations(appliedFilters: AppliedFilters, availableFilters: AvailableFilters) {
  const cleanedAppliedFilters = { ...appliedFilters };
  const cleanupAppliedFilters = (
    categoryKey: YardsFiltersCategoryKey,
    filterValuesToRemove: Array<YardsFilterValuesKey>
  ) => {
    cleanedAppliedFilters[categoryKey] =
      cleanedAppliedFilters[categoryKey]?.filter((appliedFilter) => !filterValuesToRemove.includes(appliedFilter)) ??
      [];
  };

  cleanupInvalidValues(appliedFilters, cleanupAppliedFilters);

  return {
    appliedFilters: cleanedAppliedFilters,
    availableFilters,
  };
}

function disableInvalidFilters(appliedFilters: AppliedFilters, availableFilters: AvailableFilters) {
  const cleanedAvailableFilters = { ...availableFilters };
  const cleanupAvailableFilters = (
    categoryKey: YardsFiltersCategoryKey,
    filterValuesToRemove: Array<YardsFilterValuesKey>
  ) => {
    const categoryCleanedAvailableFilters = { ...cleanedAvailableFilters[categoryKey] };
    const categoryCleanedAvailableFilterValues = { ...categoryCleanedAvailableFilters.values };

    for (const key of filterValuesToRemove) {
      categoryCleanedAvailableFilterValues[key] = false;
    }

    categoryCleanedAvailableFilters.values = categoryCleanedAvailableFilterValues;
    cleanedAvailableFilters[categoryKey] = categoryCleanedAvailableFilters as YardsFiltersCategory;
  };

  cleanupInvalidValues(appliedFilters, cleanupAvailableFilters);

  return {
    appliedFilters,
    availableFilters: cleanedAvailableFilters,
  };
}

function cleanupInvalidValues(
  appliedFilters: AppliedFilters,
  cleanup: (categoryKey: YardsFiltersCategoryKey, filterValuesToRemove: Array<YardsFilterValuesKey>) => void
) {
  const appliedFilterValues = Object.values(appliedFilters).flat();

  Object.entries(incompatibleFilterValues).forEach(([filterValue, filterValuesToRemoveByCategoryKey]) => {
    const hasAppliedAnIncompatibleValue = appliedFilterValues.includes(filterValue);
    if (!hasAppliedAnIncompatibleValue || filterValuesToRemoveByCategoryKey === undefined) return;

    Object.entries(filterValuesToRemoveByCategoryKey).forEach((entry) => {
      const categoryKey = entry[0] as YardsFiltersCategoryKey;
      const filterValuesToRemove = entry[1];

      cleanup(categoryKey, filterValuesToRemove);
    });
  });
}

function updateAppliedFiltersCount(state: YardsFiltersState) {
  state.appliedFiltersCount = Object.entries(state.appliedFilters)
    .flatMap(([categoryName, valueNames]) => valueNames.map((valueName) => ({ categoryName, valueName })))
    .filter(
      ({ categoryName, valueName }) =>
        // filters applied by default is not included in the count
        !defaultAppliedFilters[categoryName as YardsFiltersCategoryKey]?.includes(valueName)
    ).length;
}

function saveYardsFiltersStateToStorage(state: YardsFiltersState) {
  saveAppliedFiltersToStorage(state.appliedFilters);
}

function saveAppliedFiltersToStorage(appliedFilters: AppliedFilters) {
  try {
    sessionStorage.setItem('applied-filters-storage-state', JSON.stringify(appliedFilters));
  } catch (error) {
    console.warn("Can't restore filters", error);
  }
}

function restoreAppliedFiltersFromStorage(state: YardsFiltersState) {
  try {
    state.appliedFilters = getAppliedFiltersFromStorage() as AppliedFilters;
  } catch (error) {
    console.warn("Can't restore filters", error);
  }
}

function getAppliedFiltersFromStorage() {
  const storageFilters = sessionStorage.getItem('applied-filters-storage-state');
  if (storageFilters === null) {
    return {};
  }

  try {
    return JSON.parse(storageFilters);
  } catch (error) {
    console.warn("Can't restore filters", error);
    return {};
  }
}

function migrateFiltersFromStorage() {
  migrateDeprecatedAppliedFiltersFromStorage();
  migrateCommonCategoryToYardStatusAndYardFlagCategories();
}

/**
 * Restores the old filters from session storage.
 * TODO: Remove after this new reducer is deployed.
 * */
function migrateDeprecatedAppliedFiltersFromStorage() {
  try {
    const restoredFilters = {
      common: JSON.parse(sessionStorage.getItem('common') ?? '[]'), // common filter category doesn't exist anymore
      [YardsFiltersCategoryKey.GROUP]: JSON.parse(sessionStorage.getItem('group') ?? '[]'),
      [YardsFiltersCategoryKey.YARD_TYPE]: JSON.parse(sessionStorage.getItem('yardType') ?? '[]'),
      [YardsFiltersCategoryKey.CROP_TYPE]: JSON.parse(sessionStorage.getItem('cropType') ?? '[]'),
      [YardsFiltersCategoryKey.LAST_INSPECTION_INTERVAL]: JSON.parse(
        sessionStorage.getItem('lastInspectionInterval') ?? '[]'
      ),
    };

    sessionStorage.removeItem('common');
    sessionStorage.removeItem('yardStatus');
    sessionStorage.removeItem('yardFlag');
    sessionStorage.removeItem('group');
    sessionStorage.removeItem('yardType');
    sessionStorage.removeItem('cropType');
    sessionStorage.removeItem('lastInspectionInterval');

    const hasAppliedFilters = Object.values(restoredFilters).some((values) => values.length);
    if (hasAppliedFilters) {
      sessionStorage.setItem('applied-filters-storage-state', JSON.stringify(restoredFilters));
    }
  } catch (error) {
    console.warn("Can't restore deprecated filters", error);
  }
}

/**
 * Migrates old deprecated "common" category to the new "yard_status" and "yard_flag" category
 * TODO: Remove after we assume all managers have migrated their filters to the new ones
 * */
function migrateCommonCategoryToYardStatusAndYardFlagCategories() {
  const restoredFilters = getAppliedFiltersFromStorage();
  if (!restoredFilters) return;

  const deprecatedCommonFilterMappingToNewCategories = {
    active_yards: { key: YardsFiltersCategoryKey.YARD_STATUS, value: YardsFiltersStatusValuesKey.ACTIVE_YARDS },
    new_yards: { key: YardsFiltersCategoryKey.YARD_FLAG, value: YardsFiltersFlagValuesKey.NEW_YARDS },
    requiring_attention: {
      key: YardsFiltersCategoryKey.YARD_FLAG,
      value: YardsFiltersFlagValuesKey.REQUIRING_ATTENTION,
    },
    queenless: { key: YardsFiltersCategoryKey.YARD_FLAG, value: YardsFiltersFlagValuesKey.QUEEN_LESS },
  };
  const commonRestoredFilters = restoredFilters['common'];
  const hasOldCommonFiltersAndNoNewFilters =
    !!commonRestoredFilters &&
    !(YardsFiltersCategoryKey.YARD_FLAG in restoredFilters) &&
    !(YardsFiltersCategoryKey.YARD_STATUS in restoredFilters);
  delete restoredFilters['common'];
  let migratedFilters = { ...restoredFilters };

  if (hasOldCommonFiltersAndNoNewFilters) {
    const newCategoryFilters: AppliedFilters = {};
    commonRestoredFilters
      .filter((name: string) => name in deprecatedCommonFilterMappingToNewCategories)
      .forEach((name: keyof typeof deprecatedCommonFilterMappingToNewCategories) => {
        const { key, value } = deprecatedCommonFilterMappingToNewCategories[name];
        newCategoryFilters[key] = [...(newCategoryFilters[key] ?? []), value];
      });

    migratedFilters = { ...migratedFilters, ...newCategoryFilters };
  }

  migratedFilters && saveAppliedFiltersToStorage(migratedFilters);
}
