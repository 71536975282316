import { Color } from 'styled-components';

export enum MetricName {
  TOTAL_YARDS = 'total_yards',
  TOTAL_HIVES = 'total_hives',
  TOTAL_LOST_HIVES = 'total_lost_hives',
  GRADING = 'grading',
  MORTALITY = 'mortality',
  WINTER_MORTALITY = 'winter_mortality',
  VARROA = 'varroa',
  NUC = 'nuc',
  MOVEMENT = 'avg_hive_movement',
}

export const CategoryColors = {
  weak: 'red02',
  medium: 'yellow02',
  strong: 'green02',
} as Record<string, Color>;

export const MetricConfigurations = {
  [MetricName.TOTAL_YARDS]: {
    cardType: 'global',
    categories: null,
    positiveDirection: true,
    previousValueDelta: 'last_week',
  },
  [MetricName.TOTAL_HIVES]: {
    cardType: 'global',
    categories: null,
    positiveDirection: true,
    previousValueDelta: 'last_week',
  },
  [MetricName.TOTAL_LOST_HIVES]: {
    cardType: 'global',
    categories: null,
    positiveDirection: false,
    previousValueDelta: 'last_week',
  },
  [MetricName.GRADING]: {
    cardType: 'category',
    categories: ['weak', 'medium', 'strong'],
    positiveDirection: true,
    previousValueDelta: null,
  },
  [MetricName.MORTALITY]: {
    cardType: 'global_with_ratio',
    categories: null,
    positiveDirection: false,
    previousValueDelta: 'last_week',
  },
  [MetricName.WINTER_MORTALITY]: {
    cardType: 'global_with_ratio',
    categories: null,
    positiveDirection: false,
    previousValueDelta: 'last_season',
  },
  [MetricName.VARROA]: {
    cardType: 'global',
    categories: null,
    positiveDirection: false,
    previousValueDelta: 'last_month',
  },
  [MetricName.NUC]: {
    cardType: 'global',
    categories: null,
    positiveDirection: true,
    previousValueDelta: 'last_week',
  },
  [MetricName.MOVEMENT]: {
    cardType: 'global',
    categories: null,
    positiveDirection: false,
    previousValueDelta: 'last_month',
  },
};
