export const GROUP_COLOR_BY_COLOR_ID = {
  1: '#E6A1A1',
  2: '#C3A1E6',
  3: '#E6E6A1',
  4: '#C3E6A1',
  5: '#A1E6A1',
  6: '#A1E6C3',
  7: '#A1E6E6',
  8: '#A1C3E6',
  9: '#A1A1E6',
  10: '#C3A1E6',
  11: '#E6A1E6',
  12: '#D4D4D4',
  13: '#F8EBE8',
  14: '#FFF4D0',
  15: '#DBF0C4',
  16: '#000000',
};

export const DEFAULT_COLOR_INDEX = 12;
const defaultColor = GROUP_COLOR_BY_COLOR_ID[DEFAULT_COLOR_INDEX];

export const groupColorIndexToRealColor = (group) => {
  return GROUP_COLOR_BY_COLOR_ID[group?.color] ?? defaultColor;
};

export const getGroupColor = (groups, groupId) => {
  const group = groups?.find((g) => g.id === groupId);
  return groupColorIndexToRealColor(group);
};
