import styled from 'styled-components/macro';

import { StyledHeader } from '@components/deprecated/Elements/AuthElements';

export const StoreStyledHeader = styled(StyledHeader)`
  margin: ${(props) => (props.isMobile ? '2rem 2rem' : '3rem 3rem')};
  position: absolute;
`;

export const AppStoreLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  a {
    margin-top: 20px;
  }
`;
