import React, { useState } from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled, { css } from 'styled-components';

// nectar:
import { ModalContainer } from '@components/deprecated/Elements/ModalElements';
import { StyledCloseButton, StyledInput, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import { GroupColorsPalleteView } from '@components/deprecated/GroupColors/GroupColorsPalleteView';
import { GroupColorsView } from '@components/deprecated/GroupColors/GroupColorsView';
import { groupColorIndexToRealColor } from '@helpers/deprecated/color';

const GroupModalFont = css`
  font-style: normal;
  font-weight: ${({ theme: { fontWeightRegular } }) => fontWeightRegular};
  font-size: ${({ theme: { captionSmallFontSize } }) => captionSmallFontSize};
  line-height: 1rem;
  color: ${({ theme: { unknownColor } }) => unknownColor};
`;

const StyledRow = styled.div`
  padding-top: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const ErrorMsg = styled.div`
  color: ${(props) => props.theme.criticalColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  line-height: 19px;
  margin-top: 1rem;
`;

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${GroupModalFont}
`;

const TextFieldWrapper = styled.div`
  padding: 0.75rem 0 0 1rem;
  background: ${({ theme: { primaryBackgroundColor } }) => primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  border: ${({ theme: { primaryBorderStyle } }) => primaryBorderStyle};
  div {
    ${GroupModalFont}
  }
`;

export const GroupModalView = ({
  t,
  isMobile,
  contentObj,
  errorMsg,
  handleClose,
  handleSubmit,
  isDisabledBtn,
  handleChangeName,
  handleChangeColor,
}) => {
  const { title, instructions, group, modalType, buttonText } = contentObj;

  const [showColor, showColorSchema] = useState(false);

  const isThereError = errorMsg;

  return (
    <ModalContainer isMobile={isMobile} smallModal={'remove-group' === modalType}>
      <section>
        <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
          {title}
        </StyledHeading2>
        <StyledParagraph style={{ marginBottom: '1.5rem' }}>{instructions}</StyledParagraph>

        {'remove-group' !== modalType && (
          <StyledRow>
            <TextFieldWrapper>
              <div>Group name</div>
              <StyledInput
                value={group?.name ?? ''}
                type="text"
                required={true}
                autoFocus={true}
                onChange={(e) => handleChangeName(e, group)}
                style={{ width: '395px', paddingLeft: '0' }}
              />
            </TextFieldWrapper>
            <ColorWrapper>
              <GroupColorsView
                onClick={() => showColorSchema(true)}
                background={groupColorIndexToRealColor(group)}
                style={{ width: '40px', height: '40px', position: 'static', marginBottom: '0.5rem' }}
              />
              <div>Edit color</div>
            </ColorWrapper>
            {showColor && (
              <GroupColorsPalleteView
                setSelectedGroupColor={(key) => {
                  showColorSchema(false);
                  handleChangeColor(key);
                }}
              />
            )}
          </StyledRow>
        )}

        {isThereError && <ErrorMsg>{isThereError}</ErrorMsg>}
      </section>
      <footer>
        <div>
          <StyledCloseButton type="button" label={t('cancel')} onClick={handleClose} disabled={false} />

          <StyledSubmitButton
            type="button"
            style={{ marginRight: 'remove-group' === modalType && 0 }}
            label={buttonText}
            onClick={handleSubmit}
            disabled={isDisabledBtn && 'remove-group' !== modalType}
            height="2rem"
          />
        </div>
      </footer>
    </ModalContainer>
  );
};

GroupModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeColor: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    modalType: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    group: PropTypes.object,
  }).isRequired,
  errorMsg: PropTypes.string.isRequired,
};
