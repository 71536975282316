import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common/Text';
import { InputCalendar } from '@components/form';
import { InputNumber } from '@components/form/InputNumber';
import { InputText } from '@components/form/InputText';
import { useValidator } from '@components/form/InputTextV2/hooks';
import { Validators } from '@components/form/InputTextV2/validators';
import { InputCropType } from '@components/yard/InputCropType';

import { StyledBorderWrapper, StyledForm, StyledInputPairWrapper, StyledInputWrapper } from './styles';
import { OverviewFormSectionProps } from './types';

export const FormSectionOverview: React.FC<OverviewFormSectionProps> = ({
  isActive,
  section,
  activeSection,
  defaultState,
  handleFormSectionSubmit,
}) => {
  const { t } = useTranslation();
  const [localFormState, setLocalFormState] = useState<BeeContractOverview>(defaultState);
  const firstInput = useRef<HTMLInputElement>(null);

  const requiredHivesValidators = [
    useValidator(Validators.required, { errorMessage: t('pollination_required_hives_error') }),
    useValidator(Validators.min, { errorMessage: t('pollination_required_hives_error'), min: 1 }),
  ];

  const handleFormFieldChange = useCallback((value: unknown, name: string) => {
    setLocalFormState((oldState) => ({ ...oldState, [name]: value }));
  }, []);

  return (
    <StyledForm
      onTransitionEnd={() => isActive && firstInput && firstInput.current && firstInput.current.focus()}
      id={`contractForm-section-${section}`}
      isActive={isActive}
      activeSection={activeSection}
      onSubmit={(e) => handleFormSectionSubmit(e, localFormState)}
    >
      <fieldset>
        <InputText
          inputRef={firstInput}
          required
          disabled={!isActive}
          label={t('pollination_contract_name')}
          placeholder={t('pollination_contract_name')}
          id="contractForm-name"
          name="name"
          value={localFormState['name']}
          onTextChange={handleFormFieldChange}
        />
      </fieldset>
      <fieldset>
        <Text typography="Legend" htmlTag="legend" weight={'600'} color="grey06">
          {t('pollination_details')}
        </Text>

        <StyledInputWrapper>
          <StyledBorderWrapper>
            <InputCropType
              disabled={!isActive}
              id="contractForm-cropType"
              name="cropTypesIds"
              value={localFormState['cropTypesIds']}
              onChange={handleFormFieldChange}
            />
          </StyledBorderWrapper>
        </StyledInputWrapper>
      </fieldset>

      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('Hives')}
        </Text>

        <StyledInputWrapper>
          <InputNumber
            id="contractForm-nbHives"
            name="nbRequiredHives"
            inputMode="numeric"
            positive
            required
            label={t('pollination_required_hives')}
            placeholder={t('pollination_required_hives_placeholder')}
            disabled={!isActive}
            validators={requiredHivesValidators}
            value={localFormState['nbRequiredHives']}
            onNumberChange={handleFormFieldChange}
          />
        </StyledInputWrapper>
      </fieldset>

      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('dates')}
        </Text>

        <StyledInputPairWrapper>
          <InputCalendar
            label={t('pollination_bees_in')}
            id={'contractForm-beesIn'}
            name={'beesIn'}
            value={localFormState['beesIn']}
            onChange={handleFormFieldChange}
          />
          <InputCalendar
            label={t('pollination_bees_out')}
            id={'contractForm-beesOut'}
            name={'beesOut'}
            value={localFormState['beesOut']}
            onChange={handleFormFieldChange}
          />
        </StyledInputPairWrapper>
      </fieldset>
    </StyledForm>
  );
};
