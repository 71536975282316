import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledSummaryRow = styled(Box)(
  ({ theme }) => css`
    height: 48px;

    &:not(:last-child) {
      border-bottom: ${theme.colors.grey03} 1px solid;
    }
  `
);

export const StyledTooltipPractice = styled(Box)(
  ({ theme }) => css`
    min-width: 150px;
    border: ${theme.colors.grey06} 1px solid;
    border-radius: ${theme.shape.paperRadius01}px;
    color: ${theme.colors.coreWhite};
  `
);
