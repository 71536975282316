import React from 'react';
import { useTheme } from 'styled-components';

import { withMapApi } from '@components/common/Map';
import { MarkerPinProps } from '@components/common/Map/types';

import { StyledMarkerPin, StyledMarkerPinArrow } from './styles';

/**
 * Renders the default marker pin appearance.
 * */
export const MarkerPin = withMapApi(function MarkerPin({
  animateToAppear,
  clickable,
  active,
  disabled,
  children,
  ...props
}: MarkerPinProps) {
  const theme = useTheme();

  return (
    <StyledMarkerPin
      $animateToAppear={animateToAppear}
      $clickable={clickable}
      $active={active}
      $disabled={disabled}
      {...props}
    >
      <StyledMarkerPinArrow width="8" height="10" viewBox="0 0 6 8" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={theme.colors.coreWhite}
          stroke={theme.colors.grey05}
          transform="translate(0, 2)"
          d={
            'M0.601425 1.57346C0.137481 0.910686 ' +
            '0.611633 0 1.42066 0H4.57934C5.38837 ' +
            '0 5.86252 0.910685 5.39858 1.57346' +
            'L3.81923 3.82967C3.42113 4.39838 ' +
            '2.57887 4.39839 2.18077 3.82967' +
            'L0.601425 1.57346Z'
          }
        />
      </StyledMarkerPinArrow>

      {children}
    </StyledMarkerPin>
  );
});
