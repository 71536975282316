import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sortAlphanumerically } from '@helpers/Sorting';
import { makeFetchCropTypesOnceThunk } from '@redux/CropType/actions';
import { RootState } from '@redux/Root/types';
import { FetchState } from '@redux/types';

const throttledFetchCropTypesThunk = makeFetchCropTypesOnceThunk();

export function useCropTypesDictionary(ids?: Array<number>): Record<number, BeeCropType> {
  const { cropTypes } = useCropTypes(ids);

  return useMemo(() => {
    const dictionary = {} as Record<number, BeeCropType>;
    cropTypes.forEach((cropType) => (dictionary[cropType.id] = cropType));
    return dictionary;
  }, [cropTypes]);
}

export function useCropTypes(ids?: Array<number>): {
  cropTypes: Array<BeeCropType>;
  getCropTypesFromIds: (ids: Array<number>) => Array<BeeCropType>;
  getCropTypesVisualString: (ids: Array<number>) => string;
} {
  useCropTypeFetcher();

  const allCropTypes = useSelector<RootState, Array<BeeCropType>>((state) => state.cropTypeReducer.cropTypes);

  const getCropTypesFromIds = useCallback(
    (ids: Array<number>): Array<BeeCropType> => {
      return allCropTypes.filter(({ id }) => ids.includes(id));
    },
    [allCropTypes]
  );

  const getCropTypesVisualString = useCallback(
    (ids: Array<number>): string => {
      if (!ids || !ids.length) return '-';
      const cropTypesSorted = sortAlphanumerically(getCropTypesFromIds(ids), 'name');
      return cropTypesSorted.map(({ name }) => name).join(', ');
    },
    [getCropTypesFromIds]
  );

  const cropTypes = useMemo(() => {
    if (ids) {
      return getCropTypesFromIds(ids);
    }
    return allCropTypes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, allCropTypes]);

  return useMemo(
    () => ({ cropTypes, getCropTypesFromIds, getCropTypesVisualString }),
    [cropTypes, getCropTypesFromIds, getCropTypesVisualString]
  );
}

export function useCropTypeFetcher(): FetchState {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(throttledFetchCropTypesThunk);
  }, [dispatch]);

  const { isFetching, hasFetchError } = useSelector<RootState, FetchState>((state) => state.cropTypeReducer);
  return { isFetching, hasFetchError };
}
