import React from 'react';
import PropTypes from 'prop-types';

import logo from '@assets/nectar-logo.svg';
import { BackgroundImage } from '@components/deprecated/BackgroundImage/BackgroundImage';
// nectar:
import { PrimaryButton } from '@components/deprecated/Buttons/PrimaryButton';
import {
  Container,
  LoginContainer,
  StyledErrorMsg,
  StyledForm,
  StyledHeader,
  StyledLogoImg,
  StyledText,
} from '@components/deprecated/Elements/AuthElements';
import { StyledHeading1 } from '@components/deprecated/Elements/TypographyElements';
import { Fieldset } from '@components/deprecated/Fieldset/Fieldset';
import { TextInput } from '@components/deprecated/TextInput/TextInput';
import theme from '@style/theme';

const firstRowInputStyle = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: theme.primaryBorderStyle,
};

const lastRowInputStyle = {
  marginTop: '1px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

export const CreateNewPasswordView = ({
  t,
  isMobile,
  newPassword,
  confirmationPassword,
  handleSubmit,
  handleChange,
  isDisabledBtn,
  errorMsg,
}) => {
  return (
    <Container>
      <LoginContainer isMobile={isMobile}>
        <StyledForm isMobile={isMobile} onSubmit={handleSubmit} autoComplete="off">
          <StyledHeader>
            <StyledLogoImg src={logo} className="" alt="nectar logo" />
          </StyledHeader>

          <section style={{ flex: '0 1 25%' }} />

          <StyledHeader>
            <StyledHeading1 style={{ display: 'inline-block' }} mobile={isMobile}>
              {t('create_new_password')}
            </StyledHeading1>
          </StyledHeader>

          <StyledText>{t('create_password_instruction')}</StyledText>

          <StyledErrorMsg>{errorMsg}</StyledErrorMsg>

          <Fieldset style={{ margin: '2rem 0' }} tabIndex={1}>
            <TextInput
              id="newPassword"
              name="newPassword"
              autocomplete="new-password"
              required
              value={newPassword}
              label={t('new_password')}
              handleChange={handleChange}
              type="password"
              styles={firstRowInputStyle}
            />

            <TextInput
              id="confirmationPassword"
              name="confirmationPassword"
              autocomplete="new-password"
              required
              value={confirmationPassword}
              label={t('confirm_password')}
              handleChange={handleChange}
              type="password"
              styles={lastRowInputStyle}
            />
          </Fieldset>

          <section style={{ flex: '0 1 36%' }}>
            <PrimaryButton label={t('submit')} onClick={handleSubmit} disabled={isDisabledBtn} />
          </section>
        </StyledForm>
      </LoginContainer>

      {!isMobile && <BackgroundImage />}
    </Container>
  );
};

CreateNewPasswordView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  newPassword: PropTypes.string.isRequired,
  confirmationPassword: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
