import React from 'react';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

import { PrimaryCTA } from '@style/typeface';

const StyledSecondaryLightButton = styled.button`
  ${PrimaryCTA};
  width: 100%;
  height: 56px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.buttonSecondaryColor};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  border: ${(props) => props.theme.buttonLightBorderStyle};
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline-style: none;
  cursor: pointer;
  &:disabled {
    color: rgba(55, 67, 65, 0.6);
    cursor: default;
    border: ${(props) => props.theme.buttonLightBorderStyle};
  }
  &:hover {
    border: ${(props) => !props.disabled && props.theme.buttonLightHoverStyle};
  }
  &:focus {
    border: ${(props) => props.theme.buttonLightHoverStyle};
    box-shadow: ${(props) => props.theme.buttonFocusBoxShadow};
  }
`;

export const SecondaryLightButton = ({ onClick, label, type = 'button', disabled = false, ...others }) => {
  const copiedProps = {};
  Object.keys(others).forEach((key) => {
    // these are the props that need to get thru:
    if (key.startsWith('data-') || 'style' === key || 'className' === key) {
      copiedProps[key] = others[key];
    }
  });

  return (
    <StyledSecondaryLightButton onClick={onClick} disabled={disabled} type={type} {...copiedProps}>
      {label}
    </StyledSecondaryLightButton>
  );
};

SecondaryLightButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
