import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { Loading } from '@components/common/Loading';
import { MessageBox } from '@components/common/MessageBox';
import { Modal, ModalFooter } from '@components/common/Modal';
import { Text } from '@components/common/Text';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import { makeCloseAssignContractModal, makePatchAssignContractBatchThunk } from '@redux/AssignContract/actions';
import { AssignContractYard } from '@redux/AssignContract/types';

import { InputPolliContract } from '../InputPolliContract';
import { useGridApi } from '../YardsList/hooks';
import { useYardsListSelection } from '../YardsList/hooks/useYardsListSelectionContext';

const StyledLink = styled(Link)`
  color: inherit;
`;

enum ModalStep {
  SELECT_CONTRACT = 1,
  CONFIRM = 2,
}

const DropReassignWarningMessage: React.FC<{ yardsThatAreDrops: AssignContractYard[] }> = ({ yardsThatAreDrops }) => {
  const t = useTranslation();
  const yardsThatAreDropsLength = useMemo(() => yardsThatAreDrops.length, [yardsThatAreDrops]);

  const title =
    yardsThatAreDropsLength === 1
      ? t('assign_contract_warning_title_single')
      : t('assign_contract_warning_title', { amount: yardsThatAreDropsLength });

  const moveThan5YardsText = `${yardsThatAreDropsLength} ${t('yards')} `;

  const andTranslated = t('and');
  return (
    <Box block marginBottomMD>
      <MessageBox>
        <Box block marginBottomXXS>
          <Text typography="CaptionSmall" weight="700">
            {title}
          </Text>
        </Box>

        <Text typography="CaptionSmall">
          {yardsThatAreDropsLength > 5
            ? moveThan5YardsText
            : yardsThatAreDrops.map((yard, index) => (
                <React.Fragment key={yard.id}>
                  {yardsThatAreDropsLength > 1 && index === yardsThatAreDropsLength - 1 && `${andTranslated} `}
                  <StyledLink key={yard.id} to={generatePath(APP.routes.yard, { uid: yard.id })} target="_blank">
                    {yard.contractName} {yard.name}
                  </StyledLink>
                  {yardsThatAreDropsLength > 2 && index < yardsThatAreDropsLength - 2 ? ', ' : ' '}
                </React.Fragment>
              ))}
          {yardsThatAreDropsLength === 1
            ? t('assign_contract_warning_amount_single')
            : t('assign_contract_warning_amount')}
        </Text>
      </MessageBox>
    </Box>
  );
};
export const BulkAssignContractModal: React.FC<{ deprecatedDeselectAllYards?: () => null }> = ({
  deprecatedDeselectAllYards,
}) => {
  const {
    isAssignContractModalOpen: isOpen,
    selectedYards,
    isFetching,
  } = useSelector((state) => state.assignContractReducer);

  const numberOfYards = useMemo(() => selectedYards.length, [selectedYards]);
  const [step, setStep] = useState<ModalStep>(ModalStep.SELECT_CONTRACT);
  const [contractId, setContractId] = useState<number>();
  const t = useTranslation();
  const { gridApi } = useGridApi();
  const { deselectAllOnPage } = useYardsListSelection();

  const yardsThatAreDrops = useMemo(() => selectedYards.filter((yard) => !!yard.contractName), [selectedYards]);

  const selectedYardIds = useMemo(() => selectedYards.map((yard) => yard.id), [selectedYards]);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(makeCloseAssignContractModal());
    setContractId(undefined);
  }, [dispatch]);

  const assignYardsToContract = async () => {
    if (contractId) {
      const isSuccessfull = await dispatch(makePatchAssignContractBatchThunk(contractId, selectedYardIds));
      if (!isSuccessfull) return;
      setContractId(undefined);
      deselectAllOnPage();
      gridApi?.refreshInfiniteCache();
      // todo delete this  'deprecatedDeselectAllYards' when we move to whiteboard fully
      deprecatedDeselectAllYards && deprecatedDeselectAllYards();
    }
  };
  useEffect(() => {
    if (numberOfYards === 0) closeModal();
  }, [closeModal, numberOfYards]);

  useEffect(() => {
    if (isOpen) {
      setStep(ModalStep.SELECT_CONTRACT);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      {step === 1 ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setStep(ModalStep.CONFIRM);
          }}
        >
          <Box block marginTopLG marginHorizontalMD>
            {yardsThatAreDrops.length > 0 && <DropReassignWarningMessage yardsThatAreDrops={yardsThatAreDrops} />}
            <Text typography="Heading2">
              {numberOfYards > 1 ? t('assign_x_yards_contract', { number: numberOfYards }) : t('assign_yard_contract')}
            </Text>
            <Box marginVerticalMD block>
              <InputPolliContract
                id="yard-contract-id"
                name="contract_id"
                contractId={contractId}
                onContractChange={(contract) => setContractId(contract?.id)}
              />
            </Box>
          </Box>
          <ModalFooter>
            <Box justifyContent={'flex-end'}>
              <PrimaryButton flat type="button" size={'small'} onClick={closeModal}>
                {t('cancel')}
              </PrimaryButton>
              <PrimaryButton size={'small'} disabled={!contractId}>
                {t('assign')}
              </PrimaryButton>
            </Box>
          </ModalFooter>
        </form>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            assignYardsToContract();
          }}
        >
          {isFetching && <Loading whiteBackground />}
          <Box paddingTopLG marginHorizontalMD marginBottomMD column alignItems={'center'}>
            <Text typography="Heading2" weight="600">
              {t('assign_contract_confirmation_title')}
            </Text>
            <Box block marginTopSM>
              <Text typography="Paragraph">{t('assign_contract_confirmation_desc')}</Text>
            </Box>
          </Box>
          <ModalFooter>
            <Box justifyContent={'center'}>
              <PrimaryButton flat type="button" size={'small'} onClick={() => setStep(ModalStep.SELECT_CONTRACT)}>
                {t('cancel')}
              </PrimaryButton>
              <PrimaryButton size={'small'} disabled={!contractId}>
                {t('assign_contract_confirmation_submit')}
              </PrimaryButton>
            </Box>
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};
