import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Operation = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" size={size}>
      <g clipPath="url(#a)">
        <path
          d="M6.666 10.667V10h-4.66L2 12.667C2 13.407 2.593 14 3.333 14h9.333c.74 0 1.334-.593 1.334-1.333V10H9.333v.667H6.666Zm6.667-6H10.66V3.333L9.326 2H6.66L5.333 3.333v1.334H2.67c-.737 0-1.334.596-1.334 1.333v2c0 .737.597 1.333 1.334 1.333h4V8h2.666v1.333h4c.737 0 1.334-.596 1.334-1.333V6a1.338 1.338 0 0 0-1.337-1.333Zm-4.003 0H6.663V3.333H9.33v1.334Z"
          fill={useColor(color)}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={useColor(color)} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconSVGView>
  );
};
