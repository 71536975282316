import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ChevronRight = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M9.29279 6.29284C8.9024 6.68334 8.9024 7.31634 9.29279 7.70684L13.5858 11.9998L9.29279 16.2928C8.91381 16.6852 8.91923 17.3089 9.30497 17.6947C9.6907 18.0804 10.3144 18.0858 10.7068 17.7068L15.7068 12.7068C16.0972 12.3163 16.0972 11.6833 15.7068 11.2928L10.7068 6.29284C10.3163 5.90246 9.68329 5.90246 9.29279 6.29284V6.29284Z"
      />
    </IconSVGView>
  );
};
