import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { Color } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { DefaultCellWrapper } from '@components/yard/YardsList/cells/DefaultCellWrapper';

export const GradingRoundedSquare = styled(Box)<{ $color: Color }>(
  ({ theme, $color }) => `
      min-width: 24px;
      height: 24px;
      border-radius: ${theme.shape.paperRadius01}px;
      border: ${theme.colors[$color]} 1px solid;
      padding: 0 ${theme.spacing.xxs}px;
      margin-right: ${theme.spacing.xs}px;
      
      &:not(:first-child) {
        margin-left: ${theme.spacing.sm}px;
      }
    `
);

export const Grading: React.FC<{
  data: { nbStrongHives: number; nbMediumHives: number; nbWeakHives: number } | null;
}> = ({ data }) => {
  const { t } = useTranslation();
  return data ? (
    <DefaultCellWrapper>
      <GradingRoundedSquare center $color={'green02'}>
        <Text typography={'CaptionSmall'}>{data.nbStrongHives}</Text>
      </GradingRoundedSquare>
      <Text typography={'SmallParagraph'}>{t('strong')}</Text>

      <GradingRoundedSquare center $color={'yellow02'}>
        <Text typography={'CaptionSmall'}>{data.nbMediumHives}</Text>
      </GradingRoundedSquare>
      <Text typography={'SmallParagraph'}>{t('medium')}</Text>

      <GradingRoundedSquare center $color={'red02'}>
        <Text typography={'CaptionSmall'}>{data.nbWeakHives}</Text>
      </GradingRoundedSquare>
      <Text typography={'SmallParagraph'}>{t('weak')}</Text>
    </DefaultCellWrapper>
  ) : null;
};
