import produce from 'immer';

import * as actionTypes from '../actionTypes';

const intialState = {
  isFetching: false,
};

export const forgottenPasswordReducer = (state = intialState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.FORGOTTEN_PASSWORD_START:
        st.isFetching = true;
        break;
      case actionTypes.FORGOTTEN_PASSWORD_SUCCESS:
        st.isFetching = false;
        break;
      case actionTypes.FORGOTTEN_PASSWORD_FAILURE:
        st.isFetching = false;
        break;
      default:
    }
  });
};
