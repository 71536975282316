import React from 'react';
// vendor:
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { verifyPasswordInput } from '@helpers/deprecated/verifyInputs';
import { withGetScreen } from '@HOC/withGetScreen';
// nectar
import { makeRecreatePasswordRequestThunk } from '@redux/deprecated/actions';

import { CreateNewPasswordView } from './CreateNewPasswordView';

const initialState = {
  newPassword: '',
  confirmationPassword: '',
  isDisabledBtn: true,
  errorMsg: '',
};

class CreateNewPasswordForm extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown(e) {
    const { isDisabledBtn } = this.state;
    if ('Enter' === e.key && !isDisabledBtn) {
      this.handleSubmit(e);
    }
  }

  setIfButtonIsDisabled() {
    const { newPassword, confirmationPassword } = this.state;

    this.setState({
      isDisabledBtn: true,
    });

    if (newPassword && confirmationPassword) {
      this.setState({
        isDisabledBtn: false,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { t, match, submitDispatcher, history } = this.props;

    const { newPassword, confirmationPassword } = this.state;
    var qparam = queryString.parse(history.location.search);
    const { uid, token } = { ...match.params, ...qparam };

    let error = verifyPasswordInput(newPassword, confirmationPassword);

    if (error) {
      this.setState({
        errorMsg: t(error),
      });
    } else {
      submitDispatcher(
        {
          newPassword,
          confirmationPassword,
          uid,
          token,
        },
        history
      );

      this.setState({
        ...initialState,
      });
    }
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value.trim(),
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const { newPassword, confirmationPassword, errorMsg, isDisabledBtn } = this.state;

    const { t, isMobile } = this.props;

    return (
      <CreateNewPasswordView
        t={t}
        isMobile={isMobile()}
        newPassword={newPassword}
        confirmationPassword={confirmationPassword}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        isDisabledBtn={isDisabledBtn}
        errorMsg={errorMsg}
      />
    );
  }
}

/**
 * receives the dispatch() method and returns callback props
 * that we want to inject into the presentational component
 * @param dispatch
 * @returns {{submitDispatch: submitDispatch}}
 */
const mapDispatchToProps = (dispatch) => ({
  submitDispatcher: (bodyObj, history) => dispatch(makeRecreatePasswordRequestThunk(bodyObj, history)),
});

CreateNewPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  isMobile: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  submitDispatcher: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(withGetScreen()(CreateNewPasswordForm))));
