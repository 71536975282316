import React from 'react';
import { Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import notificationEmptyIcon from '@assets/Medium_NotificationEmpty.svg';
import { StyledParagraph } from '@components/deprecated/Elements/TypographyElements';

import { StyledEmptyWarning, StyledIcon } from '../Elements/SummaryElements';

export const EmptyAlertsView = ({ t, isMobile }) => (
  <StyledEmptyWarning height={isMobile ? '100%' : '270px'}>
    <Col xs={12}>
      <StyledIcon src={notificationEmptyIcon} alt="no notifications" />
      <StyledParagraph style={{ marginTop: '1rem' }}>{t('no_notifications')}</StyledParagraph>
    </Col>
  </StyledEmptyWarning>
);

EmptyAlertsView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
