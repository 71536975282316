import React, { useCallback, useState } from 'react';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { StyledTabButton, StyledTabContent, StyledTabsWrapper } from '@components/hive/HiveModalTabs/styles';
import { HiveModalTabActivity } from '@components/hive/HiveModalTabsActivity';
import { HiveModalTabSummary } from '@components/hive/HiveModalTabsSummary';
import { useTranslation } from '@hooks/useTranslation';

export const HiveModalTabs: React.VFC = () => {
  const t = useTranslation();
  const [tabs] = useState(() => [
    { key: 'summary', title: t('info').toUpperCase(), component: HiveModalTabSummary },
    { key: 'activity', title: t('activity').toUpperCase(), component: HiveModalTabActivity },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [visitedTabs, setVisitedTabs] = useState<Record<string, boolean>>(() => ({ [activeTab]: true }));
  const activeIndex = tabs.findIndex((tab) => tab.key === activeTab);

  const goToTab = useCallback((key) => {
    setActiveTab(key);
    setVisitedTabs((curr) => ({ ...curr, [key]: true }));
  }, []);

  return (
    <Box fit column stretch>
      <Box stretch>
        {tabs.map(({ key, title }) => (
          <StyledTabButton key={key} $active={key === activeTab} onClick={() => goToTab(key)}>
            <Text typography={'SmallParagraph'} weight={'700'}>
              {title}
            </Text>
          </StyledTabButton>
        ))}
      </Box>

      <StyledTabsWrapper column stretch fit>
        {tabs.map(({ key, component: Component }, index) =>
          visitedTabs[key] ? (
            <StyledTabContent key={key} $isHiddenAtLeft={index < activeIndex} $isHiddenAtRight={index > activeIndex}>
              <Component />
            </StyledTabContent>
          ) : null
        )}
      </StyledTabsWrapper>
    </Box>
  );
};
