import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withGetScreen } from '@HOC/withGetScreen';
// nectar:
import { makeLoginRequestThunk } from '@redux/deprecated/actions';

import { LoginView } from './LoginView';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorMsg: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown(e) {
    if ('Enter' === e.key) this.handleSubmit(e);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.setIfButtonIsDisabled()) return;
    // const { t } = this.props;
    const { username, password } = this.state;

    let error = false;

    this.setState({
      errorMsg: '',
    });

    // if email validity needs to happen on the FE, uncomment this:
    // if (!checkEmailValidity(username)) {
    //   this.setState({
    //     errorMsg: t('invalid_email')
    //   })

    //   error = true;
    // }
    if (!error) {
      this.props.submitDispatcher({
        user: {
          username,
          password,
        },
      });
    }
  }

  /**
   *
   * @returns {boolean}
   */
  setIfButtonIsDisabled() {
    const { username, password } = this.state;
    return !username || !password;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim() });
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const { t, isMobile } = this.props;
    const { username, password, errorMsg } = this.state;

    return (
      <LoginView
        t={t}
        isMobile={isMobile()}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        setIfButtonIsDisabled={this.setIfButtonIsDisabled}
        errorMsg={errorMsg}
        username={username}
        password={password}
      />
    );
  }
}

/**
 * receives the dispatch() method and returns callback props
 * that we want to inject into the presentational component
 * @param dispatch
 * @returns {{submitDispatcher: submitDispatcher}}
 */
const mapDispatchToProps = (dispatch) => ({
  submitDispatcher: (credentials) => dispatch(makeLoginRequestThunk(credentials)),
});

Login.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  submitDispatcher: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(withTranslation()(withGetScreen()(Login)));
