import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButtonWithIcon } from '@components/common/Button';

export const StyledMapTopRightControls = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing.sm}px;
  right: ${theme.spacing.sm}px;
`
);

export const StyledManageYardsButton = styled(PrimaryButtonWithIcon)(
  () => `
  height: 40px;
`
);
