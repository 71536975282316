import APP from '@config/constants';

export const getEventTypes = (type) => {
  let types = [];

  switch (true) {
    case 'ai' === type:
      types = APP.HIVE_STATUS_EVENT_TYPES;
      break;
    case 'bc' === type:
      types = APP.BEECON_EVENT_TYPES;
      break;
    case 'bh' === type:
      types = APP.BEEHUB_EVENT_TYPES;
      break;
    case 'alert' === type:
      types = APP.BEETRACK_EVENT_TYPES;
      break;
    default:
      break;
  }

  return types;
};
