import produce from 'immer';

import { CropTypeActionType, CropTypeReducer, CropTypeState } from '@redux/CropType/types';

const defaultState: CropTypeState = {
  isFetching: false,
  hasFetchError: false,
  cropTypes: [],
};

export const cropTypeReducer: CropTypeReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case CropTypeActionType.FETCH_START:
        state.isFetching = true;
        break;

      case CropTypeActionType.FETCH_SUCCESS:
        state.isFetching = false;
        state.hasFetchError = false;
        state.cropTypes = action.payload;
        break;

      case CropTypeActionType.FETCH_FAILURE:
        state.isFetching = false;
        state.hasFetchError = true;
        break;

      default:
        break;
    }

    return state;
  });
};
