import React from 'react';
import moment from 'moment';

import { moneyFormatter } from '@helpers/StringTransformers';
import { useCropTypes } from '@hooks/useCropTypes';

import { StyledColumn, StyledDetailRow, StyledDetailsHeading, StyledDetailText } from './styles';

export const PolliContractDetailsPrintable: React.FC<{
  contract: BeeContract;
  type: 'worker' | 'manager';
  t: (key: string, variables?: any) => string;
}> = ({ contract, t, type }) => {
  const { getCropTypesVisualString } = useCropTypes();
  return (
    <StyledColumn>
      <StyledDetailsHeading>{t('pollination_contract_details')}</StyledDetailsHeading>
      <div>
        <StyledDetailRow>
          <StyledDetailText>{t('grower')}</StyledDetailText>
          <StyledDetailText textRight>{contract.grower.name}</StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('location')}</StyledDetailText>
          <StyledDetailText textRight>{contract.address ? contract.address : '-'}</StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('contact')}</StyledDetailText>
          <StyledDetailText textRight>{contract.grower.contactName}</StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('phone')}</StyledDetailText>
          <StyledDetailText textRight>
            {contract.grower.contactPhoneNumber ? contract.grower.contactPhoneNumber : '-'}
          </StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('email')}</StyledDetailText>
          <StyledDetailText textRight>
            {contract.grower.contactEmail ? contract.grower.contactEmail : '-'}
          </StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('crop_type')}</StyledDetailText>
          <StyledDetailText textRight>
            {contract.cropTypesIds ? getCropTypesVisualString(contract.cropTypesIds) : '-'}
          </StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('pollination_amount_of_blocks')}</StyledDetailText>
          <StyledDetailText textRight>{contract.nbBlocks ?? '-'}</StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('pollination_bees_in')}</StyledDetailText>
          <StyledDetailText textRight>
            {moment.isDate(contract.beesIn) ? moment(contract.beesIn).format('MMM DD, YYYY') : '-'}
          </StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('pollination_bees_out')}</StyledDetailText>
          <StyledDetailText textRight>
            {moment.isDate(contract.beesOut) ? moment(contract.beesOut).format('MMM DD, YYYY') : '-'}
          </StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('pollination_total_acres')}</StyledDetailText>
          <StyledDetailText textRight>{contract.totalAcres ? contract.totalAcres.toFixed(2) : '-'}</StyledDetailText>
        </StyledDetailRow>
        <StyledDetailRow>
          <StyledDetailText>{t('pollination_required_hives')}</StyledDetailText>
          <StyledDetailText textRight>{contract.nbRequiredHives ?? '-'}</StyledDetailText>
        </StyledDetailRow>
      </div>
      {type === 'manager' && (
        <>
          <StyledDetailsHeading>{t('pollination_income_details')}</StyledDetailsHeading>
          <div>
            <StyledDetailRow>
              <StyledDetailText>{t('Hives')}</StyledDetailText>
              <StyledDetailText textRight>{contract.nbRequiredHives ?? '-'}</StyledDetailText>
            </StyledDetailRow>
            <StyledDetailRow>
              <StyledDetailText>{t('cost')}</StyledDetailText>
              <StyledDetailText textRight>
                {contract.costPerHive ? t('per_hive_amount', { amount: moneyFormatter(contract.costPerHive) }) : '-'}
              </StyledDetailText>
            </StyledDetailRow>
            <StyledDetailRow>
              <StyledDetailText>{t('total')}</StyledDetailText>
              <StyledDetailText textRight>
                {contract.totalPrice ? moneyFormatter(contract.totalPrice) : '-'}
              </StyledDetailText>
            </StyledDetailRow>
          </div>
        </>
      )}
    </StyledColumn>
  );
};
