import React from 'react';

import { StyledLoadingSkeletonCircle } from '@components/common/LoadingSkeleton/styles';
import { LoadingSkeletonCircleProps } from '@components/common/LoadingSkeleton/types';

const DEF_PROPS: Partial<LoadingSkeletonCircleProps> = {
  backgroundColor: 'grey04',
};

export const LoadingSkeletonCircle: React.FC<LoadingSkeletonCircleProps> = ({ children, ...props }) => {
  const safeProps = { ...DEF_PROPS, ...props } as Required<LoadingSkeletonCircleProps>;
  return <StyledLoadingSkeletonCircle {...safeProps}>{children}</StyledLoadingSkeletonCircle>;
};
