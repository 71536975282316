import { ENDPOINTS } from '@config/api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';

import * as types from '../actionTypes';

import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 */
export const makeLoginStartRequestAction = () => ({
  type: types.LOGIN_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeLoginSuccessAction = () => ({
  type: types.LOGIN_SUCCESS,
});

/**
 *
 * @returns {{type: string, user: *}}
 */
export const makeLocalStorageLoginSuccessAction = (user) => ({
  type: types.LOCAL_STORAGE_LOGIN_SUCCESS,
  user,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeLoginFailureAction = () => ({
  type: types.LOGIN_FAILURE,
});

/**
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * Since reducers are supposed to be “pure” (as in, they don’t change anything
 * outside their scope) we can’t do any API calls or dispatch actions from inside a reducer.
 * the redux-thunk middleware knows how to handle functions
 * It passes the dispatch method as an argument to the function,
 * thus making it able to dispatch actions itself.
 * @see https://github.com/reduxjs/redux-thunk
 *
 * @param credentials
 * @returns {Function}
 */
export const makeLoginRequestThunk = (credentials) => {
  return (dispatch) => {
    dispatch(makeLoginStartRequestAction());
    const jsonData = JSON.stringify(credentials);

    // for "simple request" definition
    // @see https://javascript.info/fetch-crossorigin#simple-requests
    // When request’s credentials mode is "include" it has an impact on the
    // functioning of the CORS protocol
    // @see https://fetch.spec.whatwg.org/#cors-protocol-and-credentials
    // Fetch does not use cookie by default. "same-origin" enables cookie
    // ("include" for cross-origin requests)
    // @see https://developers.google.com/web/updates/2015/03/introduction-to-fetch
    // @see https://github.com/github/fetch#sending-cookies
    // For a request that includes credentials, browsers won’t let JS access the response
    // if the value of the Access-Control-Allow-Origin response header is the wildcard *

    fetch(ENDPOINTS.login, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeLoginFailureAction());

          if ('snack_non_field_errors' === jsonObj.error) {
            dispatch(makeShowSnackbarAction('snack_unable_login'));
            return;
          } else if ('unauthorized' === jsonObj.error) return;
          else dispatch(makeShowSnackbarAction(jsonObj.error));
        } else if ('user' in jsonObj) {
          const { user } = jsonObj;
          dispatch(makeLocalStorageLoginSuccessAction(user));
          dispatch(makeLoginSuccessAction());
        }
      });
  };
};

/**
 *
 * @returns {{type: string}}
 */
export const makeLogoutStartAction = () => ({
  type: types.LOGOUT_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeLogoutSuccessAction = () => ({
  type: types.LOGOUT_SUCCESS,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeLogoutFailureAction = () => ({
  type: types.LOGOUT_FAILURE,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeLocalStorageLogoutSuccessAction = () => ({
  type: types.REMOVE_USER_AND_PERMISSIONS,
});

/**
 * "thunk" action creator
 * using the thunk middleware, an action can return a function instead of an object
//  * the thunk action receives the 'dispatch' method and can therefore dispatch other actions
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * @returns {Function}
 */
export const makeLogoutRequestThunk = () => {
  return (dispatch) => {
    dispatch(makeLogoutStartAction());

    fetch(ENDPOINTS.logout, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          // will display the snack
          dispatch(makeShowSnackbarAction(jsonObj.error));
          dispatch(makeLogoutFailureAction());
          return;
        } else {
          dispatch(makeLogoutSuccessAction());
          dispatch(makeShowSnackbarAction('snack_logout_msg'));
          dispatch(makeLocalStorageLogoutSuccessAction());
        }
      });
  };
};
