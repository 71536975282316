import React from 'react';
import * as HtmlToImage from 'html-to-image';
import HtmlToCanvas from 'html2canvas';

const DEF_IMAGE_WAIT_TIMEOUT = 5000;
const DEF_IMAGE_WAIT_INTERVAL = 80;
const DEF_GOOGLE_MAP_FADE_DURATION = 250;

export interface ImagesOptions {
  filter: (img: HTMLImageElement) => boolean;
}

export interface WaitImagesOptions extends ImagesOptions {
  timeout?: number;
  interval?: number;
}

export const DOM = {
  waitAllMapTilesToLoad,
  waitAllImagesToLoad,
  getDistanceFromParent,
  elementToCanvas,
};

async function waitAllMapTilesToLoad() {
  await waitAllImagesToLoad({
    filter: (img) => img.src.includes('googleapis.com'),
  });
}

async function waitAllImagesToLoad(options?: ImagesOptions): Promise<void> {
  await waitToHaveImages(options);

  const images = getDocumentLoadingImages(options);
  const promises = images.map(
    (img) =>
      new Promise((resolve) =>
        img.addEventListener('load', () => {
          // Wait tile fade animation.
          setTimeout(resolve, DEF_GOOGLE_MAP_FADE_DURATION);
        })
      )
  );

  await Promise.all(promises);
  await waitToHaveImages(options);

  if (hasLoadingImages(options)) {
    await waitAllImagesToLoad(options);
  }
}

/**
 * Waits until some image is present in the document or the
 * timeout is reached.
 * */
async function waitToHaveImages(options?: WaitImagesOptions): Promise<Array<HTMLImageElement>> {
  return await new Promise((resolve) => {
    const timeout = options?.timeout ?? DEF_IMAGE_WAIT_TIMEOUT;
    const interval = options?.interval ?? DEF_IMAGE_WAIT_INTERVAL;
    const startTime = +new Date();
    const intervalId = setInterval(() => {
      const images = getDocumentImages(options);
      if (images.length || +new Date() - startTime > timeout) {
        clearInterval(intervalId);
        resolve(images);
      }
    }, interval);
  });
}

/** Returns true if there is any image still loading. */
function hasLoadingImages(options?: ImagesOptions): boolean {
  return getDocumentLoadingImages(options).length > 0;
}

/** Returns all images still loading. */
function getDocumentLoadingImages(options?: ImagesOptions): Array<HTMLImageElement> {
  return getDocumentImages(options).filter((img) => !img.complete);
}

/** Returns all images present in the document. */
function getDocumentImages(options?: ImagesOptions): Array<HTMLImageElement> {
  let images = Array.from(document.images);

  if (options?.filter) {
    images = images.filter(options.filter);
  }

  return images;
}

/** get the distance from element bottom to parent top */
function getDistanceFromParent(el: HTMLElement, parentRef: HTMLElement) {
  const parentRect = parentRef.getBoundingClientRect();
  const childRect = el.getBoundingClientRect();
  return {
    x: childRect.right - parentRect.left,
    y: childRect.bottom - parentRect.top,
  };
}

async function elementToCanvas(
  element: HTMLElement,
  engine: 'alt1' | 'alt2',
  filter?: (el: Element) => boolean
): Promise<HTMLCanvasElement> {
  return await {
    alt1: elementToCanvasAlt1,
    alt2: elementToCanvasAlt2,
  }[engine](element, filter);
}

/**
 * Renders the given HTML element into a canvas.
 * Faster option, but it doesn't work with Google Maps.
 * */
async function elementToCanvasAlt1(
  element: HTMLElement,
  filter?: (el: Element) => boolean
): Promise<HTMLCanvasElement> {
  return await HtmlToImage.toCanvas(element, {
    quality: 0.99,
    skipFonts: true,
    backgroundColor: 'white',
    pixelRatio: 1.5,
    cacheBust: true,
    includeQueryParams: true,
    filter,
  });
}

/**
 * Renders the given HTML element into a canvas.
 * Slower option, but it works with Google Maps.
 * */
async function elementToCanvasAlt2(
  element: HTMLElement,
  filter?: (el: Element) => boolean
): Promise<HTMLCanvasElement> {
  return await HtmlToCanvas(element, { useCORS: true, scale: 1.5, ignoreElements: filter });
}
