import React from 'react';

import { useBoxComponent, useBoxProps, useBoxStyle } from './hooks';
import { BoxPropsWithRef, BoxRef, BoxTag } from './types';

export const _Box: any = React.forwardRef<BoxRef<any>, BoxPropsWithRef<BoxRef<any>>>(function Box(props, ref) {
  const { tag, children } = props;
  const boxStyle = useBoxStyle(tag, props);
  const boxProps = useBoxProps(props);
  const BoxComponent = useBoxComponent(tag);
  return (
    <BoxComponent ref={ref} boxStyle={boxStyle} {...boxProps}>
      {children}
    </BoxComponent>
  );
});

export const Box = _Box as <T extends BoxTag = 'div'>(props: BoxPropsWithRef<T>) => any;
