import React from 'react';
import PropTypes from 'prop-types';

import appleBadge from '@assets/Apple_App_Store_Badge.svg';
import googleBadge from '@assets/Google_Play_Badge.png';
import logo from '@assets/nectar-logo.svg';
import { BackgroundImage } from '@components/deprecated/BackgroundImage/BackgroundImage';
import { Container, LoginContainer, StyledLogoImg } from '@components/deprecated/Elements/AuthElements';
import APP from '@config/constants';

import { AppStoreLayout, StoreStyledHeader } from './style';

const AppStoreView = ({ isMobile }) => {
  return (
    <Container>
      <LoginContainer isMobile={isMobile}>
        <StoreStyledHeader isMobile={isMobile}>
          <StyledLogoImg src={logo} className="" alt="nectar logo" />
        </StoreStyledHeader>
        <AppStoreLayout>
          <a href={APP.APPLE_APP_STORE_LINK}>
            <img alt="Available on the App Store" src={appleBadge} height="100" />
          </a>
          <a href={APP.GOOGLE_PLAY_STORE_LINK}>
            <img alt="Get it on Google Play" src={googleBadge} height="100" width="301" />
          </a>
        </AppStoreLayout>
      </LoginContainer>
      {!isMobile && <BackgroundImage />}
    </Container>
  );
};

AppStoreView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default AppStoreView;
