import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HelpAdd = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 19C15.1944 19 19 15.1944 19 10.5C19 5.80558 15.1944 2 10.5 2C5.80558 2 2 5.80558 2 10.5C2 15.1944 5.80558 19 10.5 19ZM11.0185 5.43408C9.34767 5.17962 7.74359 6.19108 7.25301 7.80841C7.18311 8.04191 7.2278 8.29472 7.37351 8.49011C7.51921 8.68551 7.74877 8.80046 7.99251 8.80008H8.16251C8.50608 8.78696 8.80487 8.56061 8.91051 8.23341C9.19806 7.43259 10.0334 6.96772 10.8655 7.14541C11.6867 7.33254 12.2516 8.08689 12.2 8.92758C12.1499 9.59549 11.6806 9.95736 11.1592 10.3594C10.7959 10.6396 10.4072 10.9392 10.1175 11.3756C10.1175 11.3798 10.1154 11.382 10.1133 11.3841C10.1111 11.3862 10.109 11.3883 10.109 11.3926C10.109 11.3973 10.1036 11.4048 10.0974 11.4134L10.0974 11.4135C10.0926 11.4202 10.0872 11.4276 10.0835 11.4351C10.0009 11.5705 9.92982 11.7127 9.87101 11.8601C9.86676 11.8728 9.86038 11.8835 9.85401 11.8941C9.84763 11.9047 9.84126 11.9153 9.83701 11.9281C9.83021 11.9476 9.82453 11.9674 9.82001 11.9876C9.70303 12.3294 9.64553 12.6888 9.65001 13.0501H11.35C11.3477 12.7312 11.4298 12.4174 11.588 12.1406C11.5965 12.1278 11.6029 12.1151 11.6093 12.1023C11.6156 12.0896 11.622 12.0768 11.6305 12.0641C11.7001 11.9469 11.7797 11.8359 11.8685 11.7326C11.8728 11.7283 11.877 11.722 11.8813 11.7156C11.8855 11.7092 11.8898 11.7028 11.894 11.6986C11.982 11.5979 12.0767 11.5032 12.1773 11.4152C12.304 11.2952 12.4376 11.1786 12.5721 11.0613L12.5721 11.0613C13.3042 10.4225 14.0627 9.76075 13.8688 8.38924C13.6679 6.88655 12.513 5.68919 11.0185 5.43408ZM9.65001 13.9H11.35V15.6H9.65001V13.9Z"
      />
      <path
        fill={useColor(color)}
        d="M20.25 16.5H18.75V18.75H16.5V20.25H18.75V22.5H20.25V20.25H22.5V18.75H20.25V16.5Z"
      />
    </IconSVGView>
  );
};
