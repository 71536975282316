import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { StyledNotesUpdatedLabel } from '@components/yard/YardDetailsDashboard/styles';
import { useTranslation } from '@hooks/useTranslation';

const DATEFORMAT = 'MMM DD, YYYY';

export const YardDetailsDashboardNotes: React.VFC = () => {
  const t = useTranslation();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const lastUpdateLabel = useMemo(() => {
    if (!yard) {
      return null;
    }
    const lastUpdateDate = moment(yard.fieldNotesUpdatedAt).format(DATEFORMAT);
    return `${t('updated_by')} ${yard.fieldNotesUpdatedBy} ${t('on').toLowerCase()} ${lastUpdateDate}`;
  }, [t, yard]);

  if (!yard) {
    return null;
  }

  return (
    <Box column paddingVerticalSM paddingHorizontalMD gapXXS>
      <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
        {t('notes')}
      </Text>

      {yard.fieldNotes ? (
        <>
          <Text typography={'SmallParagraph'}>{yard.fieldNotes}</Text>
          <StyledNotesUpdatedLabel>{lastUpdateLabel}</StyledNotesUpdatedLabel>
        </>
      ) : (
        '—'
      )}
    </Box>
  );
};
