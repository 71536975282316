import { DefaultTheme } from 'styled-components';

import { DynamicIcon } from '@components/common/Icon/types';
import { SVG } from '@helpers/SVG';

export const MarkerCluster: DynamicIcon<[{ theme: DefaultTheme; markerCount: number; size: number }]> = {
  getImageURI({ theme, markerCount, size }) {
    const cacheKey = `${markerCount}-${size}`;
    return SVG.generateCachedSvgUri(cacheKey, () => {
      return `
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="${size}" height="${size}" 
            viewBox="0 0 ${size} ${size}">
            <circle r="50%" cx="50%" cy="50%" opacity="0.24" fill="${theme.colors.grey08}" />
            <circle r="47.5%" cx="50%" cy="47.5%" fill="${theme.colors.coreWhite}" />
            <text 
                x="50%" y="54%" 
                font-family="sans-serif"
                font-weight="600"
                text-anchor="middle"
                dominant-baseline="middle"
                fill="${theme.colors.grey08}">
                ${markerCount}
            </text>
        </svg>
      `;
    });
  },
};
