import React from 'react';
import moment from 'moment-timezone';

import { ArrowDown } from '@components/common/Icon/presets/ArrowDown';

import { DropdownArrowSpan, DropdownSelect, DropdownWrapper } from './styles';

interface DropdownProps {
  id: string;
  name: string;
  list: string[];
  defaultValue: string;
  onUpdate: (value: string) => void;
}

/**
 *
 * Component is WIP and not currently reusable, currently only works for the timezone selector
 */

export const Dropdown: React.FC<DropdownProps> = ({ id, name, list, defaultValue, onUpdate }) => {
  return (
    <DropdownWrapper>
      <DropdownSelect
        id={id}
        name={name}
        defaultValue={defaultValue}
        onChange={(event) => onUpdate(event.target.value)}
      >
        {list.map((option) => {
          return (
            <option key={'timezone_' + option.replace('/', '_')} value={option}>
              {`(GMT${moment().tz(option).format('Z')}) ${option} (${moment().tz(option).format('z')})`}
            </option>
          );
        })}
      </DropdownSelect>
      <DropdownArrowSpan>
        <ArrowDown />
      </DropdownArrowSpan>
    </DropdownWrapper>
  );
};
