import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/common/CTA';
import { ButtonProps } from '@components/common/CTA/types';
import { YardsListColumnsModal } from '@components/yard/YardsListColumnsModal/YardsListColumnsModal';

export const YardsListColumnsModalTrigger: React.VFC<ButtonProps> = ({ ...props }) => {
  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation();

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback(() => setOpen(false), []);

  return (
    <>
      <Button primary onClick={openModal} {...props}>
        {t('manage_columns')}
      </Button>
      <YardsListColumnsModal isOpen={isOpen} onRequestClose={closeModal} />
    </>
  );
};
