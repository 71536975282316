import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export type HTMLInteractiveElement = HTMLButtonElement | HTMLInputElement | HTMLAnchorElement | null;

export function useKeyboardNavigation(parentEl: Element | null, onEscape?: (e: any) => void) {
  const index = useRef(0);

  const goToIndex = (newIndex: number) => {
    const interactiveEls = interactiveElements();
    // in case the number of interactive elements change and the index is outdated
    if (newIndex >= interactiveEls.length) {
      index.current = interactiveEls.length - 1;
    } else {
      index.current = newIndex;
    }

    interactiveEls[index.current]?.focus();
  };

  const interactiveElements = useCallback(() => {
    if (!parentEl) return [];
    return [
      ...parentEl.querySelectorAll('button:not(:disabled), a:not(:disabled), input:not(:disabled)'),
    ] as HTMLInteractiveElement[];
  }, [parentEl]);

  const onKeyDown = (e: any) => {
    switch (e.key) {
      case 'ArrowDown':
        e.stopPropagation();
        e.preventDefault();
        if (index.current === interactiveElements().length - 1) {
          goToIndex(0);
        } else {
          goToIndex(index.current + 1);
        }
        return false;
      case 'ArrowUp':
        e.stopPropagation();
        e.preventDefault();
        if (index.current > 0) goToIndex(index.current - 1);
        return false;
      case 'ArrowLeft':
        break;
      case 'ArrowRight':
        break;
      case 'Escape':
        interactiveElements()[index.current]?.blur();
        onEscape && onEscape(e);
        break;
      case 'Enter':
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!parentEl) return;
    parentEl.addEventListener('keydown', onKeyDown, false);
    return () => {
      parentEl.removeEventListener('keydown', onKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentEl]);

  return { goToIndex };
}
