import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { now } from '@helpers/deprecated/time';

import * as types from '../actionTypes';

import { makeUnauthorizedFetchFailureAction } from './index';
import { makeShowSnackbarAction } from './snackbarActionCreator';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeEventListRequestStartAction = () => ({
  type: types.FETCH_HHT_EVENT_ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeFetchEventListRequestSuccessAction = (eventList, user) => ({
  type: types.FETCH_HHT_EVENT_ACTION_SUCCESS,
  eventList,
  user,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeEventListRequestFailureAction = () => ({
  type: types.FETCH_HHT_EVENT_ACTION_FAILURE,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeFetchEventsByYardRequestStartAction = () => ({
  type: types.FETCH_EVENTS_BY_YARD_ACTION_START,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeFetchEventsByYardRequestSuccessAction = (response, id, hasMoreYards) => ({
  type: types.FETCH_EVENTS_BY_YARD_ACTION_SUCCESS,
  response,
  id,
  hasMoreYards,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeFetchEventsByYardRequestFailureAction = () => ({
  type: types.FETCH_EVENTS_BY_YARD_ACTION_FAIL,
});

/**
 *
 * @param clear If true, clears the current events list
 * @returns {{type: string, error: *}}
 */
export const makeFetchEventsByHiveRequestStartAction = (clear) => ({
  type: types.FETCH_EVENTS_BY_HIVE_ACTION_START,
  clear,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeFetchEventsByHiveRequestSuccessAction = (response, hasMoreHives) => ({
  type: types.FETCH_EVENTS_BY_HIVE_ACTION_SUCCESS,
  response,
  hasMoreHives,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeFetchEventsByHiveRequestFailureAction = () => ({
  type: types.FETCH_EVENTS_BY_HIVE_ACTION_FAIL,
});

export const makeFetchEventListRequestThunk = (user, forceFetch) => {
  return (dispatch, getState) => {
    const fetchState = getState().eventListReducer;

    const { fetchedAt } = fetchState;
    if (now - fetchedAt < APP.defaultCacheForSeconds * 1000 && !forceFetch) return;

    dispatch(makeEventListRequestStartAction());

    fetch(ENDPOINTS.eventList, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeEventListRequestFailureAction());
            dispatch(makeShowSnackbarAction('snack_default_msg'));
            return;
          }
        } else if ('eventlist' in jsonObj) {
          // all is right
          dispatch(makeFetchEventListRequestSuccessAction(jsonObj.eventlist, user));
        }
      });
  };
};

export const makeFetchEventsByYardRequestThunk = (query, id, hasMoreYards) => {
  return (dispatch) => {
    dispatch(makeFetchEventsByYardRequestStartAction(id));

    fetch(`${ENDPOINTS.activities}${query}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeFetchEventsByYardRequestFailureAction());
            dispatch(makeShowSnackbarAction('snack_default_msg'));
            return;
          }
        } else if ('eventlist' in jsonObj) {
          // all is right
          dispatch(makeFetchEventsByYardRequestSuccessAction(jsonObj, id, hasMoreYards));
        }
      });
  };
};

export const handleActivityFilterChange = (filter) => ({
  type: types.HANDLE_ACTIVITY_FILTER_CHANGE,
  filter,
});

export const makeFetchEventsByHiveRequestThunk = (query, hasMoreHives, clear) => {
  return (dispatch) => {
    dispatch(makeFetchEventsByHiveRequestStartAction(clear));

    fetch(`${ENDPOINTS.activities}${query}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeFetchEventsByHiveRequestFailureAction());
            dispatch(makeShowSnackbarAction('snack_default_msg'));
            return;
          }
        } else if ('eventlist' in jsonObj) {
          // all is right
          dispatch(makeFetchEventsByHiveRequestSuccessAction(jsonObj, hasMoreHives));
        }
      });
  };
};
