import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import closeIcon from '@assets/MediumClose.svg';
// nectar:
import logo from '@assets/nectar-logo.svg';
import closeMobileIcon from '@assets/Small_Close.svg';
import config from '@config/config';

import { SecondaryDarkButton } from '../Buttons/SecondaryDarkButton';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.buttonPrimaryTextColor};
  font-size: ${(props) => props.theme.heading2FontSize};
  z-index: ${(props) => props.theme.getZIndexOf('highLevelIndex')};
`;

const StyledNav = styled.nav`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledVersion = styled.div`
  color: ${(props) => props.theme.supportingColor};
  font-size: ${(props) => props.theme.captionSmallFontSize};
`;

const StyledLogoImg = styled.img`
  width: 230px;
  height: auto;
  margin: 0 auto;
  color: ${(props) => props.theme.nectarColor};
  filter: ${(props) => props.theme.nectarLogoFilter};
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-size: 28px;
  font-weight: 600;
  line-height: 76px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledModalBtn = styled.button`
  color: inherit;
  font-size: 28px;
  font-weight: 600;
  line-height: 76px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  width: 180px;
  align-self: center;
`;

const StyledCloseBtn = styled.button`
  position: fixed;
  //this is calculated to be in the same position as the burger icon
  top: ${(props) => props.top};
  right: 1.5rem;
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

const Break = styled.div`
  height: 4%;
`;

const BreakLarge = styled.div`
  height: 7%;
`;

const SectionBreak = styled.div`
  height: 15%;
`;

export const MenuView = ({ isMobile, destinations, handleClose }) => {
  const linkList = destinations.filter(({ route }) => route.startsWith('/'));
  const buttonList = destinations.filter(({ route }) => route.startsWith('='));
  const modalList = destinations.filter(({ route }) => route.startsWith('+'));

  return (
    <StyledOverlay>
      <StyledCloseBtn type="button" onClick={handleClose} top={isMobile ? '12px' : '20px'}>
        <img src={isMobile ? closeMobileIcon : closeIcon} alt="close_modal" style={{ filter: 'invert(1)' }} />
      </StyledCloseBtn>

      <StyledNav aria-modal="true">
        <SectionBreak />

        <StyledLogoImg src={logo} alt="nectar logo" />

        <Break />

        {linkList.map(({ route, label }) => (
          <StyledLink role="navigation" to={route} key={route} onClick={handleClose}>
            {label}
          </StyledLink>
        ))}

        {modalList.map(({ route, label }) => (
          <StyledModalBtn type="button" role="dialog" data-func={route} key={route} onClick={handleClose}>
            {label}
          </StyledModalBtn>
        ))}

        <SectionBreak />

        {buttonList.map(({ route, label }) => (
          <ButtonContainer key={route}>
            <SecondaryDarkButton label={label} type="button" data-func={route} onClick={handleClose} disabled={false} />
          </ButtonContainer>
        ))}

        <BreakLarge />

        <StyledVersion>{config.VERSION}</StyledVersion>

        <Break />
      </StyledNav>
    </StyledOverlay>
  );
};

MenuView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired
  ).isRequired,
};
