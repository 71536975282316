const ID_PREFIX = 'H';
const SEQUENTIAL = { value: 0 };

export const HexID = {
  generateHexID,
  isHexID,
};

/**
 * Generates a unique hex ID.
 * If the browser refreshes, these ids reset, so it
 * should only be used to temprary elements.
 * */
function generateHexID(pad = 4): string {
  const numericID = SEQUENTIAL.value++;
  return ID_PREFIX + numericID.toString(16).padStart(pad, '0');
}

function isHexID(id: string): boolean {
  return new RegExp(`^${ID_PREFIX}\\d+$`).test(id);
}
