import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StyledCaptionSmall,
  StyledHeading1,
  StyledHeading2,
  StyledHeading3,
  StyledParagraph,
  StyledParagraphSmall,
} from '@components/deprecated/Elements/TypographyElements';
import { PrimaryCTA, PrimaryCTAInverse, RowsNumbers } from '@style/typeface';

const StyledRowsNumbers = styled.p`
  ${RowsNumbers}
`;

const StyledPrimaryCTA = styled.p`
  ${PrimaryCTA}
`;

const StyledPrimaryCTAInverse = styled.p`
  ${PrimaryCTAInverse};
  margin-left: 36px;
`;
const Break = styled.div`
  height: 4rem;
`;

const StyledTitleText = styled.p`
font-family: Monaco;
font-size: ${(props) => props.theme.heading3FontSize};
letter-spacing: 0;
line-height: 19px
margin-bottom: 2rem;
`;

const SupportingText = styled.p`
  font-family: Monaco;
  font-size: ${(props) => props.theme.heading3FontSize};
  letter-spacing: 0;
  line-height: 1.5rem;
`;

const StyledTable = styled.table`
  width: 75%;
  color: ${(props) => props.theme.primaryTextColor};
  thead {
    white-space: nowrap;
    border-bottom: ${(props) => props.theme.primaryBorderStyle};
    text-align: left;
  }
  th {
    width: 25%;
    color: #252629;
    font-family: Monaco;
    font-size: ${(props) => props.theme.heading3FontSize};
    letter-spacing: 0;
    line-height: 19px;
    padding-bottom: 12px;
  }
  h1,
  h2,
  h3,
  p {
    margin-bottom: 1rem;
  }
`;

const StyledRow = styled.tr`
  height: 230px;
`;

const InverseCol = styled.td`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.buttonPrimaryTextColor};
`;

export const LiveStyleGuideThirdView = ({ t }) => {
  return (
    <>
      <Break />

      <StyledTitleText>{t('typeface')}:</StyledTitleText>

      <StyledHeading1 style={{ marginTop: '1.5rem' }}>Open Sans</StyledHeading1>

      <StyledTitleText>https://fonts.google.com/specimen/Open+Sans</StyledTitleText>

      <Break />

      <StyledTitleText>{t('type_styles')}:</StyledTitleText>

      <Break />

      <StyledTable>
        <thead>
          <tr>
            <th>{t('tablet')}</th>
            <th>{t('mobile')}</th>
            <th>{t('inverse')}</th>
          </tr>
        </thead>
        <tbody>
          <StyledRow>
            <td>
              <StyledHeading1 style={{ marginTop: '1.5rem' }}>{t('heading1')}</StyledHeading1>
              <SupportingText>
                Font-weight: Regular; <br />
                Font-size: 32px; <br />
                Line-height: 2.5rem; <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <td>
              <StyledHeading1 style={{ marginTop: '1.5rem' }} mobile={true}>
                {t('heading1')}
              </StyledHeading1>
              <SupportingText>
                Font-weight: Regular; <br />
                Font-size: 1.5rem; <br />
                Line-height: 36px; <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledHeading2>{t('heading2')}</StyledHeading2>
              <SupportingText>
                Font-weight: SemiBold;
                <br />
                Font-size: 1.5rem;
                <br />
                Line-height: 36px;
                <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <td>
              <StyledHeading2 mobile={true}>{t('heading2')}</StyledHeading2>
              <SupportingText>
                Font-weight: SemiBold;
                <br />
                Font-size: 20px;
                <br />
                Line-height: 32px;
                <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledHeading3>{t('heading3')}</StyledHeading3>
              <SupportingText>
                Font-weight: SemiBold;
                <br />
                Font-size: 14px;
                <br />
                Line-height: 1.5rem;
                <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <td />
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledParagraph>{t('paragraph_normal')}</StyledParagraph>
              <SupportingText>
                Font-weight: Regular;
                <br />
                Font-size: 1rem;
                <br />
                Line-height: 1.5rem;
                <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <td />
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledParagraphSmall>{t('paragraph_small')}</StyledParagraphSmall>
              <SupportingText>
                Font-weight: Regular;
                <br />
                Font-size: 14px;
                <br />
                Line-height: 1.5rem;
                <br />
                Color: #252629;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <td />
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledRowsNumbers>{t('row_numbers')}</StyledRowsNumbers>
              <SupportingText>
                Font-weight: Regular;
                <br />
                Font-size: 14px;
                <br />
                Line-height: 1.5rem;
                <br />
                Color: #252629;
                <br />
                Letter-spacing: 1px;
              </SupportingText>
            </td>
            <td />
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledCaptionSmall>{t('small_captions')}</StyledCaptionSmall>
              <SupportingText>
                Font-weight: Regular;
                <br />
                Font-size: 12px;
                <br />
                Line-height: 1rem;
                <br />
                Color: #878E8D;
                <br />
                Letter-spacing: 0;
              </SupportingText>
            </td>
            <td />
            <InverseCol />
          </StyledRow>

          <StyledRow>
            <td>
              <StyledPrimaryCTA>{t('primary_cta')}</StyledPrimaryCTA>
              <SupportingText>
                Font-weight: Bold;
                <br />
                Font-size: 1rem;
                <br />
                Line-height: 1rem;
                <br />
                Letter-spacing: 1px;
                <br />
                Color: #252629;
                <br />
                Text-transform: uppercase;
              </SupportingText>
            </td>
            <td />
            <InverseCol>
              <StyledPrimaryCTAInverse>{t('primary_cta')}</StyledPrimaryCTAInverse>
              <SupportingText style={{ color: 'white', marginLeft: '36px' }}>
                Font-weight: Bold;
                <br />
                Font-size: 1rem;
                <br />
                Line-height: 1rem;
                <br />
                Letter-spacing: 1px;
                <br />
                Color: #FFFFFF;
                <br />
                Text-transform: uppercase;
              </SupportingText>
            </InverseCol>
          </StyledRow>

          <StyledRow>
            <td />
            <td />
            <InverseCol />
          </StyledRow>
        </tbody>
      </StyledTable>
    </>
  );
};

LiveStyleGuideThirdView.propTypes = {
  t: PropTypes.func.isRequired,
};
