import produce from 'immer';

import APP from '@config/constants';
import { getActionFlag } from '@helpers/deprecated/actions';
import { sortList } from '@helpers/deprecated/sort';
import { toggleDirection } from '@helpers/deprecated/toggleDirection';

import * as actionTypes from '../actionTypes';

const defaultState = {
  // State for alive hives
  alive: {
    isFetching: false,
    hiveList: null,
    sortedHiveList: null,
    order_by: 'status',
    order_direction: 'DESC',
    yardId: null,
  },

  // State for deadout hives
  deadout: {
    isFetching: false,
    hiveList: null,
    sortedHiveList: null,
    order_by: 'status',
    order_direction: 'DESC',
    yardId: null,
  },

  // General state
  isFetching: false,
  hive: null,
  hiveId: null,
};

export const beeTrackHiveListReducer = (state = defaultState, action) => {
  return produce(state, (nextState) => {
    const subState = action.status ? nextState[action.status] : null;

    switch (action.type) {
      case actionTypes.HANDLE_SORT_HIVE_LIST: {
        const order_direction = toggleDirection(action.order_by, action.order_direction);
        const sortedHiveList = sortList([...subState.sortedHiveList], action.order_by);
        subState.sortedHiveList = sortedHiveList;
        subState.order_by = action.order_by;
        subState.order_direction = order_direction;
        break;
      }
      case actionTypes.FETCH_HHT_HIVES_ACTION_START: {
        subState.isFetching = true;
        subState.hiveList = null;
        subState.sortedHiveList = null;
        break;
      }
      case actionTypes.FETCH_HHT_HIVES_ACTION_SUCCESS: {
        let newGradingList = [...action.hiveList].map((hive) => ({
          ...hive,
          latest_grading_action_flag: getActionFlag(hive.latest_grading_action, action.actionList),
        }));
        let newStatusList = [...newGradingList].map((hive) => {
          let latest_status_action_name = getActionFlag(hive.latest_status_action, action.actionList);
          let newHive = { ...hive };

          // if the latest_status_action_name is null, it mean no status report has been applied to the hive yet, therefore it is queeright
          newHive.latest_status_action_name = latest_status_action_name
            ? latest_status_action_name
            : APP.STATUS.queenright;

          switch (latest_status_action_name) {
            case APP.STATUS.deadout.toLowerCase():
              newHive.status = 2;
              return newHive;
            case APP.STATUS.queenless.toLowerCase():
              newHive.status = 1;
              return newHive;
            default:
              newHive.status = 0;
              return newHive;
          }
        });
        let lst = sortList(newStatusList, state.order_by);
        subState.isFetching = false;
        subState.sortedHiveList = lst;
        subState.hiveList = newStatusList;
        subState.yardId = action.id;
        break;
      }
      case actionTypes.FETCH_HHT_HIVES_ACTION_FAILURE: {
        subState.isFetching = false;
        break;
      }
      case actionTypes.FETCH_HIVE_ACTION_START: {
        nextState.hive = null;
        nextState.isFetching = true;
        break;
      }
      case actionTypes.FETCH_HIVE_ACTION_SUCCESS: {
        nextState.hive = action.hive;
        nextState.isFetching = false;
        nextState.hiveId = action.hive.hive_identity_id;
        break;
      }
      case actionTypes.FETCH_HIVE_ACTION_FAILURE: {
        nextState.isFetching = false;
        break;
      }
      default:
    }
  });
};
