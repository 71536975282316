import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const StyledPagesWrapper = styled.div`
  @page {
    margin: 0;
  }
`;

export const StyledHeader = styled.header`
  padding: 0 40px;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.grey05};
  page-break-before: always;
  page-break-after: avoid;
`;

export const StyledPage = styled.div(
  () => `
    overflow: hidden;
    position: relative;
    min-height: 11in;
    
    display: block;
    width: 1024px;
    height: 1440px;
`
);

export const StyledFooter = styled.footer`
  margin: 0 40px;
  left: 0;
  right: 0;
  bottom: 16px;
  position: absolute;
`;

export const StyledColumnWrapper = styled.div<{ marginTopMd?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({ marginTopMd, theme }) => (marginTopMd ? `margin-top: ${theme.spacing.md}px` : '')}
`;

export const StyledRightHand = styled.div`
  text-align: right;
`;

export const StyledExportText = styled(Text)`
  margin-top: 28px;
`;

export const StyledDetailsWrapper = styled.div`
  padding: 24px 40px 48px;
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StyledColumn = styled.div`
  margin-bottom: 25px;
`;

export const StyledDetailRow = styled.div(
  ({ theme }) => `
    border-bottom: 1.5px solid ${theme.colors.grey05};
    margin-bottom: ${theme.spacing.xs}px;
    padding-bottom: ${theme.spacing.xs}px;
    display: grid;
    grid-template-columns: 40% auto;
    align-items: center;
    grid-column-gap: 1rem;
  `
);

export const StyledDetailsHeading = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 32px 0 16px;
`;

export const StyledDetailText = styled.div.attrs({
  'data-pdf-text': true,
})<{ textRight?: boolean; bold?: boolean }>(
  ({ textRight, bold }) => `
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    text-align: ${textRight ? 'right' : 'left'};
    font-weight: ${bold ? 600 : 400}
  `
);

export const StyledBeetrackText = styled(StyledDetailText)(
  ({ theme }) => `
    color: ${theme.colors.orange02};
    font-weight: 700;
  
  `
);
export const StyledBoxBordered = styled(Box)(
  ({ theme }) => `
    margin-left: 12px;
    border-left: 1px solid ${theme.colors.grey07};
  `
);

export const StyledPoiCardWrapper = styled.div<{ halfWidth?: boolean }>(
  ({ theme, halfWidth }) => `
    margin-bottom: ${theme.spacing.md}px;
    break-inside: avoid;
    width: ${halfWidth ? 'calc(50% - 20px)' : 'auto'};
  `
);

export const StyledPoiDescription = styled(StyledDetailText)(
  ({ theme }) => `
    color: ${theme.colors.grey07};
  `
);

export const StyledMapWrapper = styled.div`
  position: relative;
  width: 1220px;
  transform: rotate(90deg) translate(170px, 92px);
  height: 944px;

  & * {
    font-size: 10px !important;
  }
`;
export const StyledWrapper = styled.div`
  padding: 0 40px;
`;

export const StyledFlexColWrapper = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-top: 32px;
  height: 1220px;
`;

export const StyledMapLegend = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 40px;
  height: 740px;
  padding-top: 24px;
`;

export const StyledMapLegendItem = styled.div`
  width: calc(50% - 20px);
  flex-shrink: 0;
  display: grid;
  break-inside: avoid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 1rem;
  margin-bottom: 8px;
`;

export const StyledSectionTitle = styled(StyledDetailText)(
  ({ theme }) => `
    width: calc(50% - 20px);
    flex-shrink: 0;
    border-bottom: 1.5px solid ${theme.colors.grey05};
    padding-bottom: 8px;
    margin-bottom: 8px;
    break-after: avoid;
    
    :not(:first-child) {
      margin-top: 34px;
    }
  `
);
