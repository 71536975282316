import produce from 'immer';
import moment from 'moment';

import APP from '@config/constants';
import { isEmptyArray } from '@helpers/deprecated/array';

import * as actionTypes from '../actionTypes';

const defaultState = {
  unreadEvents: null,
  readEvents: null,
  yardEvents: null,
  hiveEvents: null,
  filter: 'all_activity',
  yardEventListCount: 0,
  hiveEventListCount: 0,
  fetchedAt: null,
  isFetching: false,
  yardId: null,
};

export const eventListReducer = (state = defaultState, action) => {
  const getActivity = (hasMore, stateEvents, actionEvents) => {
    if (hasMore) return [...stateEvents].concat(actionEvents);
    else return [...actionEvents];
  };
  const getCount = (hasMore, stateCount, actionCount) => {
    if (hasMore) return stateCount - APP.offsetLimit;
    else return actionCount;
  };
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.FETCH_HHT_EVENT_ACTION_START:
        st.isFetching = true;
        break;
      case actionTypes.FETCH_HHT_EVENT_ACTION_SUCCESS:
        const { user, eventList } = action;
        if (user) {
          const { event_read_on } = user;
          const unreadEvents =
            !isEmptyArray(eventList) && eventList.filter((e) => moment(e.analyzed_at).isAfter(event_read_on));
          const readEvents =
            !isEmptyArray(eventList) && eventList.filter((e) => moment(e.analyzed_at).isBefore(event_read_on));
          st.unreadEvents = unreadEvents;
          st.readEvents = readEvents;
          st.fetchedAt = new Date().getTime();
        }
        break;
      case actionTypes.FETCH_HHT_EVENT_ACTION_FAILURE:
        st.isFetching = false;
        break;
      case actionTypes.HANDLE_ACTIVITY_FILTER_CHANGE:
        st.filter = action.filter;
        st.yardEvents = null;
        break;
      case actionTypes.FETCH_EVENTS_BY_YARD_ACTION_START:
        st.isFetching = true;
        break;
      case actionTypes.FETCH_EVENTS_BY_YARD_ACTION_SUCCESS:
        let yardEventsFromState = state.yardEvents;
        let yardEventsFromAction = action.response.eventlist;
        let yardEventCountFromState = state.yardEventListCount;
        let yardEventCountFromAction = action.response.eventlist_count;
        let yardActivity = getActivity(action.hasMoreYards, yardEventsFromState, yardEventsFromAction);
        let yardCount = getCount(action.hasMoreYards, yardEventCountFromState, yardEventCountFromAction);
        st.yardEvents = yardActivity;
        st.yardEventListCount = yardCount;
        st.yardId = action.id;
        st.isFetching = false;
        break;
      case actionTypes.FETCH_EVENTS_BY_YARD_ACTION_FAIL:
        st.isFetching = false;
        break;
      case actionTypes.FETCH_EVENTS_BY_HIVE_ACTION_START:
        if (action.clear) {
          st.hiveEvents = [];
          st.hiveEventListCount = 0;
        }
        st.isFetching = true;
        break;
      case actionTypes.FETCH_EVENTS_BY_HIVE_ACTION_SUCCESS:
        let hiveEventsFromState = state.hiveEvents;
        let hiveEventsFromAction = action.response.eventlist;
        let hiveEventsCountFromState = state.hiveEventListCount;
        let hiveEventsCountFromAction = action.response.eventlist_count;
        let hiveActivity = getActivity(action.hasMoreHives, hiveEventsFromState, hiveEventsFromAction);
        let hiveCount = getCount(action.hasMoreHives, hiveEventsCountFromState, hiveEventsCountFromAction);
        st.hiveEvents = hiveActivity;
        st.hiveEventListCount = hiveCount;
        st.isFetching = false;
        break;
      case actionTypes.FETCH_EVENTS_BY_HIVE_ACTION_FAIL:
        st.isFetching = false;
        break;
      default:
        break;
    }
  });
};
