import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const PollinationAdd = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 5.40064C17.0972 5.40064 19 7.30343 19 9.65064C18.9982 11.1091 18.2465 12.4641 17.0102 13.2376C17.7001 15.0048 17.1286 17.0151 15.6121 18.155C14.0957 19.2948 12.0057 19.285 10.5 18.1311C8.99383 19.282 6.90587 19.2902 5.39064 18.1513C3.8754 17.0123 3.30297 15.0044 3.98986 13.2376C2.38597 12.2324 1.64212 10.2862 2.16664 8.46742C2.69116 6.64866 4.35713 5.39745 6.25001 5.40064C6.26528 5.40064 6.28008 5.40278 6.29491 5.40492C6.30813 5.40683 6.32138 5.40874 6.33501 5.40914C6.73723 3.42837 8.47881 2.00488 10.5 2.00488C12.5212 2.00488 14.2628 3.42837 14.665 5.40914C14.6786 5.40874 14.6919 5.40683 14.7051 5.40492C14.7199 5.40278 14.7347 5.40064 14.75 5.40064ZM11.9306 11.7341L13.985 10.9869H13.9859C14.4178 10.8201 14.6368 10.3383 14.4784 9.90323C14.32 9.46814 13.8425 9.23986 13.4045 9.38978L11.35 10.1369V7.95073C11.35 7.48129 10.9694 7.10073 10.5 7.10073C10.0306 7.10073 9.65 7.48129 9.65 7.95073V10.1369L7.59641 9.38978C7.15833 9.23986 6.68085 9.46814 6.52246 9.90323C6.36408 10.3383 6.58307 10.8201 7.01501 10.9869L9.0686 11.7341L7.66441 13.4077C7.36256 13.7683 7.41012 14.3052 7.77066 14.6071C8.13119 14.9089 8.66815 14.8614 8.97001 14.5008L10.5 12.6733L12.03 14.5008C12.2253 14.7339 12.53 14.8451 12.8295 14.7925C13.129 14.74 13.3777 14.5316 13.4819 14.246C13.5861 13.9603 13.53 13.6408 13.3348 13.4077L11.9306 11.7341Z"
      />
      <path
        fill={useColor(color)}
        d="M20.25 16.5H18.75V18.75H16.5V20.25H18.75V22.5H20.25V20.25H22.5V18.75H20.25V16.5Z"
      />
    </IconSVGView>
  );
};
