import * as types from '../actionTypes';

/**
 *
 * @returns {{type: string, contentObj: *}}
 */
export const makeOpenModalAction = (contentObj) => ({
  type: types.OPEN_MODAL,
  contentObj,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeCloseModalAction = () => ({
  type: types.CLOSE_MODAL,
});

export const makeOpenMenuAction = (contentObj) => ({
  type: types.OPEN_MAIN_MENU,
  contentObj,
});

export const makeCloseMenuAction = () => ({
  type: types.CLOSE_MAIN_MENU,
});

export const makeOpenPasswordModalAction = (contentObj) => ({
  type: types.OPEN_PASSWORD_MODAL,
  contentObj,
});

export const makeClosePasswordModalAction = () => ({
  type: types.CLOSE_PASSWORD_MODAL,
});

export const makeOpenWorkerModalAction = (contentObj) => ({
  type: types.OPEN_WORKER_MODAL,
  contentObj,
});

export const makeCloseWorkerModalAction = () => ({
  type: types.CLOSE_WORKER_MODAL,
});

export const makeOpenBeekPracticesModalAction = (contentObj) => ({
  type: types.OPEN_ACTION_MODAL,
  contentObj,
});

export const makeCloseBeekPracticesModalAction = () => ({
  type: types.CLOSE_ACTION_MODAL,
});

/** Spreading yard in order to creating a 'new' yard copy to force it to be updated */
export const makeOpenUpdateYardModalAction = ({ yard, ...data }) => ({
  type: types.OPEN_UPDATE_YARD_MODAL,
  contentObj: { yard: { ...yard }, ...data },
});

export const makeCloseUpdateYardModalAction = () => ({
  type: types.CLOSE_UPDATE_YARD_MODAL,
});

export const makeOpenCreateYardModalAction = (contentObj) => ({
  type: types.OPEN_CREATE_YARD_MODAL,
  contentObj,
});

export const makeCloseCreateYardModalAction = () => ({
  type: types.CLOSE_CREATE_YARD_MODAL,
});

export const makeSetZoomAction = (zoom) => ({
  type: types.SET_ZOOM,
  zoom,
});

export const makeResetYardZoomModaAction = () => ({
  type: types.RESET_YARD_ZOOM,
});

export const makeOpenHiveModalAction = (contentObj) => ({
  type: types.OPEN_HIVE_MODAL,
  contentObj,
});

export const makeCloseHiveModalAction = () => ({
  type: types.CLOSE_HIVE_MODAL,
});

export const makeOpenDeleteYardModalAction = (contentObj) => ({
  type: types.OPEN_DELETE_YARD_MODAL,
  contentObj,
});

export const makeCloseDeleteYardModalAction = () => ({
  type: types.CLOSE_DELETE_YARD_MODAL,
});

export const makeOpenAssignYardsGroupsModalAction = (contentObj) => ({
  type: types.OPEN_GROUPS_MODAL,
  contentObj,
});

export const makeCloseAssignYardGroupsModalAction = () => ({
  type: types.CLOSE_GROUPS_MODAL,
});

export const makeOpenManyToManyGroupsModalAction = (contentObj) => ({
  type: types.OPEN_MANY_TO_MANY_GROUPS_MODAL,
  contentObj,
});

export const makeCloseManyToManyGroupsModalAction = () => ({
  type: types.CLOSE_MANY_TO_MANY_GROUPS_MODAL,
});

export const makeOpenGroupModalAction = (contentObj) => ({
  type: types.OPEN_GROUP_MODAL,
  contentObj,
});

export const makeCloseGroupModalAction = () => ({
  type: types.CLOSE_GROUP_MODAL,
});
