import { useSelector } from 'react-redux';

import { useHiveModalInnerContext } from '@components/hive/HiveModal/hooks';
import { HiveState } from '@redux/Hive/types';

export function useHiveData<HT extends number>(): HiveState['hives'][HT] {
  // TODO: Remove this hive modal dependency
  const { id } = useHiveModalInnerContext();
  const state = useSelector((state) => state.hiveReducer.hives[id] ?? null);
  return (
    state || {
      detail: null,
      isFetchingDetail: false,
      activitySet: null,
      isFetchingActivitySet: false,
    }
  );
}
