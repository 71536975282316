import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { UnstyledButton } from '@components/common/UnstyledButton';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeClearAppliedFiltersAction } from '@redux/YardsFilters/actions';

const StyledEmptyView = styled(Box)<{ $visible: boolean; $withFilters?: boolean }>(
  ({ theme, $visible, $withFilters }) => css`
    position: absolute;
    inset: 0;
    background-color: ${$withFilters ? theme.colors.coreWhite : theme.colors.coreBackground};
    transition: ${theme.animations.transitionLong('opacity')} 400ms;
    z-index: ${theme.getZIndexOf('tableOverlay')};
    pointer-events: ${$visible ? 'auto' : 'none'};
    opacity: ${$visible ? 1 : 0};

    button {
      pointer-events: ${$visible ? 'auto' : 'none'};
    }
  `
);

const StyledEmptyCardView = styled(Box).attrs({
  paddingMD: true,
  gapXS: true,
  column: true,
})(
  ({ theme }) => css`
    background-color: ${theme.colors.coreWhite};
    border-radius: ${theme.shape.paperRadius02}px;
  `
);

const StyledClearFiltersButton = styled(UnstyledButton)(
  () => css`
    text-decoration: underline;
  `
);

export const YardsListEmpty: React.VFC<{ visible: boolean }> = ({ visible }) => {
  const t = useTranslation();
  return (
    <StyledEmptyView center $visible={visible}>
      <StyledEmptyCardView>
        <Text typography={'Heading2'} weight={'700'} align={'center'}>
          {t('whiteboard_empty_title')}
        </Text>
        <Text
          typography={'SmallParagraph'}
          dangerouslySetInnerHTML={{ __html: t('whiteboard_empty_text') }}
          align={'center'}
        />
      </StyledEmptyCardView>
    </StyledEmptyView>
  );
};

export const YardsListEmptyWithFilters: React.VFC<{ visible: boolean }> = ({ visible }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const clearFilters = useCallback(() => dispatch(makeClearAppliedFiltersAction()), [dispatch]);

  return (
    <StyledEmptyView center $visible={visible} $withFilters>
      <StyledEmptyCardView>
        <Text typography={'Heading2'} weight={'700'} align={'center'}>
          {t('no_yards_msg')}
        </Text>
        <Text typography={'SmallParagraph'} align={'center'}>
          <StyledClearFiltersButton onClick={clearFilters}>{t('clear_filters')}</StyledClearFiltersButton>
          {t('clear_filters_cont')}
        </Text>
      </StyledEmptyCardView>
    </StyledEmptyView>
  );
};
