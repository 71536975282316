import React from 'react';
import PropTypes from 'prop-types';

// nectar
import {
  Bold,
  DropColumn,
  StyledCol,
  StyledDesc,
  StyledHeader,
  StyledImg,
  StyledRow,
} from '@components/deprecated/Elements/EventsElements';
import { getEventTypes } from '@helpers/deprecated/getEventTypes';
import { getIconByType } from '@helpers/deprecated/icons';

export const CumulativeRecoveredEvents = ({
  t,
  alertView,

  eventObj,
}) => {
  const { yard_name, event_details, type } = eventObj;

  const buildCustomEventString = (id, number) => {
    switch (id) {
      case 'no_transmission_bc':
      case 'low_data_rate_bc':
        return (
          <>
            {t('recovered_bulk_beecon', { number })}
            <Bold>{t('now_transmitting')}</Bold>.
          </>
        );
      case 'low_battery_bc':
        return (
          <>
            {t('recovered_bulk_beecon_low_battery', { number })}
            <Bold>{t('charged')}</Bold>.
          </>
        );
      case 'no_transmission_bh':
        return (
          <>
            {t('recovered_bulk_beehub', { number })}
            <Bold>{t('now_transmitting')}</Bold>.
          </>
        );
      case 'not_charging_bh':
        return (
          <>
            {t('recovered_bulk_beehub', { number })}
            <Bold>{t('now_charging')}</Bold>.
          </>
        );
      case 'deadout_ai':
        return (
          <>
            {t('recovered_bulk_deadout', { number })}
            <Bold>{t('deadout')}</Bold>.
          </>
        );
      case 'queenless_ai':
        return (
          <>
            {t('recovered_bulk_queenless', { number })}
            <Bold>{t('queenright')}</Bold>.
          </>
        );
      default:
        return null;
    }
  };

  return getEventTypes(type).map((item) => {
    const id = `${item}_${type}`;
    return event_details.map(
      (b, idx) =>
        b[id] &&
        b[id].false &&
        b[id].false.length >= 3 && (
          <StyledRow key={idx} style={{ paddingLeft: 'dropdown' === alertView && 0 }}>
            {'dropdown' === alertView && (
              <DropColumn xs={3}>
                <StyledImg src={getIconByType('events', id)} alt={id} />
              </DropColumn>
            )}
            <StyledCol xs={'dropdown' === alertView ? 9 : 12}>
              {('summary' === alertView || 'dropdown' === alertView) && <StyledHeader>{yard_name}</StyledHeader>}
              <StyledDesc>{buildCustomEventString(id, b[id].false.length)}</StyledDesc>
            </StyledCol>
          </StyledRow>
        )
    );
  });
};

CumulativeRecoveredEvents.propTypes = {
  t: PropTypes.func.isRequired,
  alertView: PropTypes.string,
  eventObj: PropTypes.shape({
    yard_name: PropTypes.string.isRequired,
    event_details: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    type: PropTypes.string.isRequired,
    analyzed_at: PropTypes.string,
  }).isRequired,
};
