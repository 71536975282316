import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { IconIMGProps } from '@components/common/Icon/types';

const DEF_SIZE = 16;

export const IconSVGView = styled.svg<{ size?: number; height?: number | string; width?: number | string }>(
  ({ size = DEF_SIZE, height, width }) => `
    height: ${height ?? size}px;
    width: ${width ?? size}px;
    vertical-align: middle;
  `
);

export const IconIMGView = styled(Box)<Pick<IconIMGProps, 'color' | 'size'> & BoxProps<'img'>>(
  ({ theme, color = 'grey08', size = DEF_SIZE }) => `
  width: ${size}px;
  height: ${size}px;
  filter: ${theme.colors.filter(color)};
`
);
