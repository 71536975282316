import styled from 'styled-components';

import { Map } from '@components/common/Map';
import { markerHighlightedStyle, markerHoverStyle, markerNormalStyle } from '@components/common/Map/components/styles';
import { Animation } from '@helpers/Animation';

export const StyledMapWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledMap = styled(Map)<{ $static: boolean }>`
  pointer-events: ${({ $static }) => ($static ? 'none' : 'auto')};
`;

export const PoiMarker = styled.div<{ active: boolean; disabled?: boolean }>(
  ({ theme, disabled, active }) => `
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  pointer-events: ${disabled ? 'none' : 'all'};
  box-shadow: ${theme.shadows.boxShadow02};
  transition: ${theme.animations.transitionFast('background', 'color')};
  
  ${Animation.createElasticCSSKeyframes('poi-appearing', (progress) => `transform: scale(${progress.toFixed(4)});`)}
  
  animation-name: poi-appearing;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-duration: 1600ms;
  
  ${active ? markerHighlightedStyle(theme) : markerNormalStyle(theme)}

  &:hover {
    ${markerHoverStyle(theme)}
  }
`
);
