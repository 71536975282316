/**
 * Helper to create thunk actions.
 *
 * Usage examples:
 *     // Creating the thunk.
 *     const makeFetchMyResourcesThunk = Thunk.create(() => (dispatch, getState) => {});
 *     const makeFetchMyResourceDetailThunk = Thunk.create((arg1: number, arg2: string) => (dispatch, getState) => {});
 *
 *     // Dispatching thunk action.
 *     dispatch(makeFetchMyResourcesThunk());
 *     dispatch(makeFetchMyResourceDetailThunk(10, "ABC"));
 * */

import { debounce } from 'lodash';

import APP from '@config/constants';
import { ThunkActionWrapper, ThunkMaker, ThunkMakerBaseArgs } from '@helpers/Thunk/types';
import { ThunkAction } from '@redux/types';

export const Thunk = {
  create,
  createTakeFirst,
  createTakeLast,
};

/**
 * Creates a basic thunk action with/without args.
 * A - Genetic type to infer the thunk args.
 * R - Generic type to infer the thunk return.
 * */
function create<A extends ThunkMakerBaseArgs, R>(
  maker: ThunkMaker<A, R>,
  wrapper?: ThunkActionWrapper<R>
): ThunkMaker<A, R> {
  return (...thunkMakerArgs) => {
    const thunkAction: ThunkAction<R> = (...thunkActionArgs) => {
      return maker(...thunkMakerArgs)(...thunkActionArgs);
    };
    return wrapper ? wrapper(thunkAction) : thunkAction;
  };
}

/**
 * Creates a debounced thunk action with/without args.
 * In case this thunk is dispatched multiple times in
 * a row, only the first call is executed.
 * */
function createTakeFirst<A extends ThunkMakerBaseArgs, R>(
  creator: ThunkMaker<A, R | undefined>
): ThunkMaker<A, R | undefined> {
  return create(creator, (thunkAction) =>
    debounce(thunkAction, APP.THUNK_DEBOUNCE_INTERVAL, { leading: true, trailing: false })
  );
}

/**
 * Creates a debounced thunk action with/without args.
 * In case this thunk is dispatched multiple times in
 * a row, only the last call is executed.
 *
 * Note: Don't use it if you are awaiting the dispatch
 * return, since trailing debounced actions won't return for
 * the first call.
 * */
function createTakeLast<A extends ThunkMakerBaseArgs, R>(
  creator: ThunkMaker<A, R | undefined>
): ThunkMaker<A, R | undefined> {
  return create(creator, (thunkAction) =>
    debounce(thunkAction, APP.THUNK_DEBOUNCE_INTERVAL, { leading: false, trailing: true })
  );
}
