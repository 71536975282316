import produce from 'immer';

import { OperationActionType, OperationReducer, OperationState } from '@redux/Operation/types';

const defaultState: OperationState = {
  isFetching: false,
  hasFetchError: false,
  operation: null,
};

export const operationReducer: OperationReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case OperationActionType.FETCH_START:
        state.isFetching = true;
        break;

      case OperationActionType.FETCH_SUCCESS:
      case OperationActionType.PATCH_SUCCESS:
        state.isFetching = false;
        state.hasFetchError = false;
        state.operation = action.payload;
        break;

      case OperationActionType.FETCH_FAILURE:
        state.isFetching = false;
        state.hasFetchError = true;
        break;

      default:
        break;
    }

    return state;
  });
};
