import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// nectar
import theme from '@style/theme';

import { NavBarTabs } from './NavBarTabs';

const StyledRow = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.theme.primaryColor};
  box-shadow: ${(props) => props.theme.boxShadow1};
  flex-wrap: nowrap !important;
  top: ${(props) => props.height}px;
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
`;

export const MobileTabBarView = ({ isMobileOrTablet, tabStyle }) => {
  let height = isMobileOrTablet ? theme.tabBarHeight : theme.topBarHeight;
  return (
    <StyledRow height={height} style={tabStyle}>
      <NavBarTabs />
    </StyledRow>
  );
};

MobileTabBarView.propTypes = {
  isMobileOrTablet: PropTypes.bool.isRequired,
  tabStyle: PropTypes.string,
};
