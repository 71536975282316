import React from 'react';

import { StyledLoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/styles';
import { LoadingSkeletonRectProps } from '@components/common/LoadingSkeleton/types';

const DEF_PROPS: Partial<LoadingSkeletonRectProps> = {
  backgroundColor: 'grey04',
  borderRadius: 'paperRadius01',
};

export const LoadingSkeletonRectangle: React.FC<LoadingSkeletonRectProps> = ({ children, ...props }) => {
  const safeProps = { ...DEF_PROPS, ...props } as Required<LoadingSkeletonRectProps>;
  return <StyledLoadingSkeletonRectangle {...safeProps}>{children}</StyledLoadingSkeletonRectangle>;
};
