import React from 'react';
import { Col, Row, setConfiguration } from 'react-grid-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import alertIcon from '@assets/Small_Alert.svg';
import boxDisabledIcon from '@assets/Small_Box-disable.svg';
// icons
import infoIcon from '@assets/Small_Info.svg';
import triangleDownIcon from '@assets/xSmall_Arrow-dropdown.svg';
import checkIcon from '@assets/xSmall_Check.svg';
import { BulletButton } from '@components/deprecated/Buttons/BulletButton';
import { PrimaryButton } from '@components/deprecated/Buttons/PrimaryButton';
import { SecondaryDarkButton } from '@components/deprecated/Buttons/SecondaryDarkButton';
import { SecondaryLightButton } from '@components/deprecated/Buttons/SecondaryLightButton';
import { TagButton } from '@components/deprecated/Buttons/TagButton';
import { TextButton } from '@components/deprecated/Buttons/TextButton';
import Dropdown from '@components/deprecated/Dropdown/Dropdown';
import { Fieldset } from '@components/deprecated/Fieldset/Fieldset';
import { MobileTabBarView } from '@components/deprecated/NavBarElements/MobileTabBarView';
// nectar
import { NavBarView } from '@components/deprecated/NavBarElements/NavBarView';
import { YardCardView } from '@components/deprecated/Partials/YardCardView';
import { Select } from '@components/deprecated/Select/Select';
import { TextInput } from '@components/deprecated/TextInput/TextInput';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
// constants
import APP from '@config/constants';
// helpers
import { getUniqId } from '@helpers/deprecated/getUniqId';
import theme from '@style/theme';

// components
import { YardTopBarView } from '../../admin/Yards_deprecated/Yard/components/YardTopBarView';

import '@components/deprecated/Dropdown/dropdown.css';

const InlineBlock = styled.div`
  display: inline-block;
  min-width: fit-content;
  vertical-align: top;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin-right: ${(props) => props.marginright};
  margin-left: ${(props) => props.marginleft && props.marginleft};
  margin-bottom: ${(props) => props.marginbottom && props.marginbottom};
  margin-top: ${(props) => props.margintop && props.margintop};
`;

const StyledButtonGroupRow = styled(Row)`
  flex-wrap: nowrap !important;
`;

const StyledButtonGroupCol = styled(Col)`
  flex: 1;
  height: 2.5rem;
  cursor: pointer;
  text-align: center;
  background-color: ${(props) => props.theme.buttonPrimaryTextColor};
  outline-style: none;
  &:hover {
    background-color: rgba(102, 102, 102, 0.05);
  }
  &:focus {
    background-color: rgba(102, 102, 102, 0.05);
    box-shadow: ${(props) => props.theme.buttonFocusBoxShadow};
    margin: 0 1px;
  }
`;

const StyleBtnGroup = styled.button`
  height: 100%;
  color: ${(props) => props.theme.supportingColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  line-height: 20px;
  letter-spacing: 0;
`;

const TabStyleBtn = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColor}99;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeightBold};
  line-height: 2.5rem;
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  margin-right: 1rem;
  &:hover {
    color: ${(props) => props.theme.primaryTextColor};
  }
  &:focus {
    color: ${(props) => props.theme.primaryTextColor};
  }
`;

const Break = styled.div`
  height: 4rem;
`;

const SectionBreak = styled.div`
  height: 8rem;
`;

const StyledCloseButton = styled(TextButton)`
  width: 56px;
  margin-right: 2rem;
`;

const StyledSubmitButton = styled(PrimaryButton)`
  width: 110px;
  margin-right: 12px;
`;

const StyledText = styled.p`
  margin-bottom: 1.5rem;
  font-family: Monaco;
  text-transform: uppercase;
`;

const FloatingText = styled.p`
  position: relative;
  width: 100%;
  font-family: Monaco;
  font-size: ${(props) => props.theme.captionSmallFontSize};
  letter-spacing: 0;
  line-height: 1rem;
  color: ${(props) => (props.color ? props.color : 'inherit')};
`;

const Divider = styled.div`
  position: relative;
  border-bottom: ${(props) => props.theme.primaryBorderStyle};
  width: 489px;
`;

const HorizontalDiv = styled.div`
  position: relative;
  border-bottom: 1px solid #71a3fb;
  width: 30px;
`;

const VerticalDiv = styled.div`
  position: relative;
  border-right: 1px solid #71a3fb;
  height: 30px;
`;

const TooltipContainer = styled.div`
cursor: pointer;
text-align: center;
text-transform: uppercase;
font-weight:${(props) => props.theme.fontWeightSemiBold};
vertical-align: middle;
margin-top: 3rem
&:hover {
  color: ${(props) => props.theme.primaryTextColor}99;
}
`;

const SnackContainer = styled.div`
display: flex;
padding: 1rem 1.5rem;
text-align: left;
border-radius: ${(props) => props.theme.defaultBorderRadius};
background-color: ${(props) => props.theme.primaryColor};
box-shadow: 0 0 15px 0 rgba(0,0,0,0.25);
color: ${(props) => props.theme.buttonPrimaryTextColor};
font-size: ${(props) => props.theme.captionSmallFontSize};
font-weight: ${(props) => props.theme.fontWeightSemiBold};
letter-spacing: 0.86px
line-height: 17px;
margin-top: 1rem;
`;

const ActionBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  box-shadow: inset 0 1px 0 0 #dedede;
  width: 100%;
  height: 64px;
`;

const ErrorRow = styled(Row)`
  width: ${(props) => (props.isMobile ? '360px' : '1112px')};
  height: 2.5rem;
  text-align: left;
  align-items: center;
  padding-left: 1rem;
  background-color: rgba(217, 118, 109, 0.4);
`;

const StyledErrorTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  line-height: ${(props) => (props.isMobile ? '1rem' : '1.5rem')};
  letter-spacing: ${(props) => (props.isMobile ? '0.5px' : '1px')};
  font-size: ${(props) => (props.isMobile ? props.theme.captionSmallFontSize : props.theme.heading3FontSize)};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
`;

const AlertIcon = styled.img`
  height: 1.5rem;
  margin-right: 1rem;
  filter: ${(props) => props.theme.primaryColorFilter};
`;

const ToastActionButton = styled.button`
  padding: 0 1rem;
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

export const LiveStyleGuideSecondView = ({ t, toggleList, activeItem, setActiveItem }) => {
  const list = [
    { id: 'all', name: t('all_status') },
    { id: getUniqId(), name: t('abnormal') },
    { id: getUniqId(), name: t('hardware_issue') },
  ];

  const renderTextAndHorizontalDiv = (text, textColor) => (
    <>
      <InlineBlock margintop="30px" marginright="0.5rem">
        <HorizontalDiv />
      </InlineBlock>
      <InlineBlock margintop="22px" marginright="4rem">
        <FloatingText color={textColor}>{t(text)}</FloatingText>
      </InlineBlock>{' '}
      <br />
    </>
  );

  const renderYardTopBarView = (style) => (
    <YardTopBarView
      t={t}
      isMobile={false}
      tabs={APP.yardTabs}
      activeItem="summary"
      style={style}
      handleChangeTabClick={(e) => e}
    />
  );

  const renderTabBar = (style) => <MobileTabBarView tabStyle={{ position: 'inherit' }} isMobileOrTablet={true} />;

  const renderPrimaryButton = (label, disabled, style, height) => (
    <InlineBlock width="296px" marginbottom="2rem" marginright="2rem">
      <PrimaryButton label={t(label)} onClick={(e) => e} disabled={disabled} height={height} style={style} />
    </InlineBlock>
  );

  const renderStyleBtGroup = (style, label) => (
    <StyleBtnGroup role="button" tabIndex={-1} style={style}>
      {t(label)}
    </StyleBtnGroup>
  );

  const renderSecondaryLightBtn = (disabled, style) => (
    <InlineBlock width="296px" marginbottom="2rem" marginright="2rem">
      <SecondaryLightButton label={t('view_all')} onClick={(e) => e} disabled={disabled} style={style} />
    </InlineBlock>
  );

  const renderSecondaryDarkBtn = (disabled, style) => (
    <InlineBlock width="296px" marginbottom="2rem" marginright="2rem">
      <SecondaryDarkButton label={t('logout')} type="button" onClick={(e) => e} disabled={disabled} style={style} />
    </InlineBlock>
  );

  const renderTextBtn = (disabled, style) => (
    <InlineBlock width="106px" marginright="2rem" marginbottom="1rem">
      <TextButton type="button" label={t('download')} onClick={(e) => e} disabled={disabled} style={style} />
    </InlineBlock>
  );

  const renderTagButton = (style) => (
    <InlineBlock width="106px" marginright="2rem" marginbottom="2rem">
      <TagButton type="button" role="navigation" onClick={(e) => e} label={t('list')} style={style} />
    </InlineBlock>
  );
  setConfiguration({ gutterWidth: 0 });

  return (
    <>
      <SectionBreak />

      <StyledText>{t('nav_bars')}</StyledText>

      <InlineBlock width="360px" marginbottom="2rem">
        <NavBarView
          style={{ position: 'inherit' }}
          t={t}
          isMobile={true}
          isMobileOrTablet={true}
          tabs={[]}
          handleClickOnMenu={(e) => e}
          disableShowNotif={true}
          showTab={false}
        />
        {renderTabBar()}
      </InlineBlock>

      <StyledText>{t('tabs')}</StyledText>

      <InlineBlock width="50%" style={{ borderRight: theme.primaryBorderStyle }}>
        <InlineBlock width="360px" marginbottom="2rem" marginright="2rem">
          {renderTabBar()}
        </InlineBlock>

        {renderTextAndHorizontalDiv('active')}

        <InlineBlock width="360px" marginbottom="2rem" marginright="2rem">
          {renderTabBar({ opacity: '60%' })}
        </InlineBlock>

        {renderTextAndHorizontalDiv('hover')}

        <InlineBlock width="360px" marginbottom="2rem" marginright="2rem">
          {renderTabBar({ opacity: '60%' })}
        </InlineBlock>

        {renderTextAndHorizontalDiv('focus')}

        <InlineBlock width="360px" marginbottom="2rem" marginright="2rem">
          {renderYardTopBarView({ marginTop: 0 })}
        </InlineBlock>

        {renderTextAndHorizontalDiv('active')}

        <InlineBlock width="360px" marginbottom="2rem" marginright="2rem">
          {renderYardTopBarView({ opacity: '60%', marginTop: 0 })}
        </InlineBlock>

        {renderTextAndHorizontalDiv('hover')}

        <InlineBlock width="360px" marginbottom="2rem" marginright="2rem">
          {renderYardTopBarView({ opacity: '60%', marginTop: 0 })}
        </InlineBlock>

        {renderTextAndHorizontalDiv('focus')}
      </InlineBlock>

      <InlineBlock width="40%">
        <InlineBlock width="129px" marginleft="96px" marginbottom="2rem">
          {APP.tabs.map((tab, idx) => (
            <TabStyleBtn
              key={idx}
              role="navigation"
              // onClick={handleChangeTabClick}
              data-target={tab.label}
              style={
                tab.label === 'hives'
                  ? {
                      boxShadow: theme.formSelectorBoxShadow,
                      color: theme.primaryTextColor,
                    }
                  : {}
              }
            >
              {t(tab.label)}
            </TabStyleBtn>
          ))}
        </InlineBlock>

        {renderTextAndHorizontalDiv('active')}

        <InlineBlock width="129px" marginleft="96px" marginbottom="2rem">
          {APP.tabs.map((tab, idx) => (
            <TabStyleBtn
              key={idx}
              role="navigation"
              // onClick={handleChangeTabClick}
              data-target={tab.label}
              style={
                tab.label === 'hives'
                  ? {
                      boxShadow: theme.formSelectorBoxShadow,
                      color: theme.primaryTextColor,
                    }
                  : { color: theme.primaryTextColor }
              }
            >
              {t(tab.label)}
            </TabStyleBtn>
          ))}
        </InlineBlock>

        {renderTextAndHorizontalDiv('hover')}

        <InlineBlock width="129px" marginleft="96px" marginbottom="2rem">
          {APP.tabs.map((tab, idx) => (
            <TabStyleBtn
              key={idx}
              role="navigation"
              // onClick={handleChangeTabClick}
              data-target={tab.label}
              style={
                tab.label === 'hives'
                  ? {
                      boxShadow: theme.formSelectorBoxShadow,
                      color: theme.primaryTextColor,
                    }
                  : { color: theme.primaryTextColor }
              }
            >
              {t(tab.label)}
            </TabStyleBtn>
          ))}
        </InlineBlock>

        {renderTextAndHorizontalDiv('focus')}
      </InlineBlock>

      <SectionBreak />

      <InlineBlock width="30%" marginright="4rem" style={{ borderRight: theme.primaryBorderStyle }}>
        <StyledText>{t('primary_big_cta')}</StyledText>

        {renderPrimaryButton('login', false)}

        {renderTextAndHorizontalDiv('default')}

        {renderPrimaryButton('login', false, { color: `${theme.buttonPrimaryTextColor}99` })}

        {renderTextAndHorizontalDiv('hover')}

        {renderPrimaryButton('login', false, {
          color: `${theme.buttonPrimaryTextColor}99`,
          boxShadow: theme.buttonFocusBoxShadow,
        })}

        {renderTextAndHorizontalDiv('focus')}

        {renderPrimaryButton('login', true)}

        {renderTextAndHorizontalDiv('disabled')}
      </InlineBlock>

      <InlineBlock width="30%" marginright="4rem" style={{ borderRight: theme.primaryBorderStyle }}>
        <StyledText>{t('primary_md_cta')}</StyledText>
        {renderPrimaryButton('send', false, {}, '2.5rem')}

        {renderTextAndHorizontalDiv('default')}

        {renderPrimaryButton('send', false, { color: `${theme.buttonPrimaryTextColor}99` }, '2.5rem')}

        {renderTextAndHorizontalDiv('hover')}

        {renderPrimaryButton(
          'send',
          false,
          { color: `${theme.buttonPrimaryTextColor}99`, boxShadow: theme.buttonFocusBoxShadow },
          '2.5rem'
        )}

        {renderTextAndHorizontalDiv('focus')}

        {renderPrimaryButton('send', true, {}, '2.5rem')}

        {renderTextAndHorizontalDiv('disabled')}
      </InlineBlock>

      <InlineBlock width="auto">
        <StyledText>{t('button_group')}</StyledText>

        <InlineBlock width="143px" marginbottom="2rem" marginright="2rem">
          <StyledButtonGroupRow
            style={{
              borderRadius: theme.defaultBorderRadius,
              boxShadow: theme.boxShadow1,
            }}
          >
            <StyledButtonGroupCol
              xs={6}
              tabIndex={0}
              onClick={(e) => e}
              style={{ borderRadius: '3px 0 0 3px', borderRight: theme.primaryBorderStyle }}
            >
              {renderStyleBtGroup(
                {
                  fontWeight: theme.fontWeightSemiBold,
                  color: theme.primaryColor,
                  boxShadow: theme.formSelectorBoxShadow,
                },
                'temp'
              )}
            </StyledButtonGroupCol>

            <StyledButtonGroupCol xs={6} tabIndex={0} onClick={(e) => e} style={{ borderRadius: '0 3px 3px 0' }}>
              {renderStyleBtGroup({}, 'hum')}
            </StyledButtonGroupCol>
          </StyledButtonGroupRow>
        </InlineBlock>

        {renderTextAndHorizontalDiv('active')}

        <InlineBlock width="143px" marginbottom="2rem" marginright="2rem">
          <StyledButtonGroupRow style={{ borderRadius: theme.defaultBorderRadius, boxShadow: theme.boxShadow1 }}>
            <StyledButtonGroupCol
              xs={6}
              tabIndex={0}
              onClick={(e) => e}
              style={{ borderRadius: '3px 0 0 3px', borderRight: theme.primaryBorderStyle }}
            >
              {renderStyleBtGroup(
                {
                  fontWeight: theme.fontWeightSemiBold,
                  color: theme.primaryColor,
                  boxShadow: theme.formSelectorBoxShadow,
                },
                'temp'
              )}
            </StyledButtonGroupCol>

            <StyledButtonGroupCol
              xs={6}
              tabIndex={0}
              onClick={(e) => e}
              style={{
                borderRadius: '0 3px 3px 0',
                backgroundColor: 'rgba(102,102,102,0.05)',
                margin: '0 1px',
              }}
            >
              {renderStyleBtGroup({}, 'hum')}
            </StyledButtonGroupCol>
          </StyledButtonGroupRow>
        </InlineBlock>

        {renderTextAndHorizontalDiv('hover')}

        <InlineBlock width="143px" marginbottom="2rem" marginright="2rem">
          <StyledButtonGroupRow style={{ borderRadius: theme.defaultBorderRadius, boxShadow: theme.boxShadow1 }}>
            <StyledButtonGroupCol
              xs={6}
              tabIndex={0}
              onClick={(e) => e}
              style={{ borderRadius: '3px 0 0 3px', borderRight: theme.primaryBorderStyle }}
            >
              {renderStyleBtGroup(
                {
                  fontWeight: theme.fontWeightSemiBold,
                  color: theme.primaryColor,
                  boxShadow: theme.formSelectorBoxShadow,
                },
                'temp'
              )}
            </StyledButtonGroupCol>

            <StyledButtonGroupCol
              xs={6}
              tabIndex={0}
              onClick={(e) => e}
              style={{
                borderRadius: '0 3px 3px 0',
                backgroundColor: 'rgba(102,102,102,0.05)',
                boxShadow: theme.buttonFocusBoxShadow,
                margin: '0 1px',
              }}
            >
              {renderStyleBtGroup({}, 'hum')}
            </StyledButtonGroupCol>
          </StyledButtonGroupRow>
        </InlineBlock>

        {renderTextAndHorizontalDiv('focus')}
      </InlineBlock>

      <SectionBreak />

      <InlineBlock width="30%" style={{ paddingTop: '1rem' }}>
        <StyledText>{t('secondary_big_light_cta')}</StyledText>

        {renderSecondaryLightBtn(false)}

        {renderTextAndHorizontalDiv('default')}

        {renderSecondaryLightBtn(false, { border: theme.buttonLightHoverStyle })}

        {renderTextAndHorizontalDiv('hover')}

        {renderSecondaryLightBtn(false, { border: theme.buttonLightHoverStyle, boxShadow: theme.buttonFocusBoxShadow })}

        {renderTextAndHorizontalDiv('focus')}

        {renderSecondaryLightBtn(true)}

        {renderTextAndHorizontalDiv('disabled')}
      </InlineBlock>

      <InlineBlock
        width="30%"
        marginright="4rem"
        style={{ backgroundColor: theme.primaryColor, paddingLeft: '4rem', paddingTop: '1rem' }}
      >
        <StyledText style={{ color: theme.buttonPrimaryTextColor }}>{t('secondary_big_dark_cta')}</StyledText>

        {renderSecondaryDarkBtn(false)}

        {renderTextAndHorizontalDiv('default', theme.buttonPrimaryTextColor)}

        {renderSecondaryDarkBtn(false, { border: theme.buttonHoverStyle })}

        {renderTextAndHorizontalDiv('hover', theme.buttonPrimaryTextColor)}

        {renderSecondaryDarkBtn(false, { border: theme.buttonHoverStyle, boxShadow: theme.buttonFocusBoxShadow })}

        {renderTextAndHorizontalDiv('focus', theme.buttonPrimaryTextColor)}

        {renderSecondaryDarkBtn(true)}

        {renderTextAndHorizontalDiv('disabled', theme.buttonPrimaryTextColor)}
      </InlineBlock>

      <InlineBlock width="auto" style={{ paddingTop: '1rem' }}>
        <StyledText>{t('text_btn')}</StyledText>
        {renderTextBtn(false)}
        <InlineBlock margintop="10px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="3px">
          <FloatingText>{t('default')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        {renderTextBtn(false, { color: 'rgba(55,67,65,0.6)' })}
        <InlineBlock margintop="10px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="3px">
          <FloatingText>{t('hover')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        {renderTextBtn(false, { color: 'rgba(55,67,65,0.6)' })}
        <InlineBlock margintop="10px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="3px">
          <FloatingText>{t('focus')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        {renderTextBtn(true)}
        <InlineBlock margintop="10px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="3px">
          <FloatingText>{t('disabled')}</FloatingText>
        </InlineBlock>{' '}
        <br />
      </InlineBlock>

      <SectionBreak />

      <InlineBlock width="25%" marginright="4rem" style={{ borderRight: theme.primaryBorderStyle }}>
        <StyledText>{t('bullet')}</StyledText>

        <InlineBlock width="106px" marginright="2rem" marginbottom="2rem">
          <BulletButton
            t={t}
            type="button"
            role="dialog"
            onClick={(e) => e}
            imgSrc={infoIcon}
            textToButton={t('info')}
          />
        </InlineBlock>

        {renderTextAndHorizontalDiv('default')}

        <InlineBlock width="106px" marginright="2rem" marginbottom="2rem">
          <BulletButton
            t={t}
            type="button"
            role="dialog"
            onClick={(e) => e}
            imgSrc={infoIcon}
            style={{ boxShadow: theme.boxShadow4, width: '102px', borderRadius: '20px' }}
            textToButton={t('info')}
            textStyle={{ opacity: 1, color: 'white', paddingLeft: '0.5rem', paddingRight: '1rem' }}
          />
        </InlineBlock>

        {renderTextAndHorizontalDiv('hover')}

        <InlineBlock width="106px" marginright="2rem" marginbottom="2rem">
          <BulletButton
            t={t}
            type="button"
            role="dialog"
            onClick={(e) => e}
            imgSrc={infoIcon}
            style={{ boxShadow: theme.buttonFocusBoxShadow, width: '102px', borderRadius: '20px' }}
            textToButton={t('info')}
            textStyle={{ opacity: 1, color: 'white', paddingLeft: '0.5rem', paddingRight: '1rem' }}
          />
        </InlineBlock>

        {renderTextAndHorizontalDiv('focus')}
      </InlineBlock>

      <InlineBlock width="25%" marginright="4rem" style={{ borderRight: theme.primaryBorderStyle }}>
        <StyledText>{t('tag')}</StyledText>

        {renderTagButton()}

        {renderTextAndHorizontalDiv('default')}

        {renderTagButton({ color: 'rgba(255,255,255,0.6)', boxShadow: theme.boxShadow3 })}

        {renderTextAndHorizontalDiv('hover')}

        {renderTagButton({ color: 'rgba(255,255,255,0.6)', boxShadow: theme.buttonFocusBoxShadow })}

        {renderTextAndHorizontalDiv('focus')}
      </InlineBlock>

      <InlineBlock width="auto">
        <StyledText>{t('action_bar')}</StyledText>
        <InlineBlock
          style={{
            position: 'relative',
            width: '515px',
            height: '112px',
            borderRadius: '3px',
            boxShadow: theme.boxShadow4,
            backgroundColor: theme.colors.coreBackground,
          }}
        >
          <ActionBarContainer>
            <StyledCloseButton type="button" label={t('cancel')} onClick={(e) => e} disabled={false} />

            <StyledSubmitButton type="button" label={t('send')} onClick={(e) => e} disabled={false} height="2rem" />
          </ActionBarContainer>
        </InlineBlock>
        <InlineBlock margintop="76px" marginleft="-15px">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="69px" marginleft="0.5rem">
          <FloatingText>{t('primary_md')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock margintop="-1rem" marginleft="330px">
          <VerticalDiv />
        </InlineBlock>
        <InlineBlock margintop="1rem" marginleft="-1.5rem">
          <FloatingText>{t('text_btn')}</FloatingText>
        </InlineBlock>{' '}
        <br />
      </InlineBlock>

      <SectionBreak />

      <InlineBlock
        width="30%"
        marginright="4rem"
        style={{ backgroundColor: theme.primaryBackgroundColor, padding: '1.5rem' }}
      >
        <StyledText>{t('checkbox')}</StyledText>
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow>
            <Col style={{ marginRight: '1rem' }}>
              <button type="checkbox" style={{ verticalAlign: 'middle' }}>
                <Select
                  id="checkbox"
                  type="checkbox"
                  src={false}
                  altActive="checkbox checked"
                  altInactive="checkbox unchecked"
                  tooltip={false}
                  hover={false}
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('notify_by_email_to', { email: 'thomas@nectar.buzz' })}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('unselected')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow>
            <Col style={{ marginRight: '1rem' }}>
              <button type="checkbox" style={{ verticalAlign: 'middle', filter: theme.supportingColorFilter }}>
                <Select
                  id="checkbox"
                  type="checkbox"
                  src={false}
                  altActive="checkbox checked"
                  altInactive="checkbox unchecked"
                  tooltip={false}
                  hover={false}
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('notify_by_email_to', { email: 'thomas@nectar.buzz' })}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('hover')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow>
            <Col style={{ marginRight: '1rem' }}>
              <button type="checkbox" style={{ verticalAlign: 'middle' }}>
                <Select
                  id="checkbox"
                  type="checkbox"
                  src={true}
                  altActive="checkbox checked"
                  altInactive="checkbox unchecked"
                  tooltip={false}
                  hover={false}
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('notify_by_email_to', { email: 'thomas@nectar.buzz' })}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('selected')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow>
            <Col style={{ marginRight: '1rem' }}>
              <button type="checkbox" style={{ verticalAlign: 'middle' }}>
                <img
                  style={{
                    cursor: 'default',
                    filter: 'invert(99%) sepia(1%) saturate(3048%) hue-rotate(216deg) brightness(111%) contrast(66%)',
                  }}
                  id="disabledCheck"
                  src={boxDisabledIcon}
                  alt="disabled check box"
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('notify_by_email_to', { email: 'thomas@nectar.buzz' })}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('disabled')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock margintop="1rem" marginbottom="1rem">
          <Divider />
        </InlineBlock>
        <StyledText>{t('radio_buttons')}</StyledText>
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow style={{ width: '110px' }}>
            <Col style={{ marginRight: '1rem' }}>
              <button type="radio" style={{ verticalAlign: 'middle' }}>
                <Select
                  id="checkbox"
                  type="radio"
                  src={false}
                  altActive="checkbox checked"
                  altInactive="checkbox unchecked"
                  tooltip={false}
                  hover={false}
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('fahrenheit')}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('unselected')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow style={{ width: '110px' }}>
            <Col style={{ marginRight: '1rem' }}>
              <button type="radio" style={{ verticalAlign: 'middle', filter: theme.supportingColorFilter }}>
                <Select
                  id="checkbox"
                  type="radio"
                  src={false}
                  altActive="checkbox checked"
                  altInactive="checkbox unchecked"
                  tooltip={false}
                  hover={false}
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('fahrenheit')}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('hover')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock marginright="2rem" marginbottom="1rem">
          <StyledButtonGroupRow style={{ width: '110px' }}>
            <Col style={{ marginRight: '1rem' }}>
              <button type="radio" style={{ verticalAlign: 'middle' }}>
                <Select
                  id="checkbox"
                  type="radio"
                  src={true}
                  altActive="checkbox checked"
                  altInactive="checkbox unchecked"
                  tooltip={false}
                  hover={false}
                />
              </button>
            </Col>

            <Col style={{ alignSelf: 'center' }}>{t('celsius')}</Col>
          </StyledButtonGroupRow>
        </InlineBlock>
        <InlineBlock margintop="14px" marginright="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="6px" marginright="4rem">
          <FloatingText>{t('selected')}</FloatingText>
        </InlineBlock>{' '}
        <br />
      </InlineBlock>

      <InlineBlock width="auto">
        <StyledText>{t('tooltips')}</StyledText>

        <InlineBlock marginright="4rem">
          <FloatingText>{t('tooltips_hover')}</FloatingText>
          <TooltipContainer data-for="trend-hover" data-tip>
            {t('trend')}
          </TooltipContainer>
          <Tooltip id="trend-hover" disable={false} tooltipText={t('hive_pop_trend')} />
        </InlineBlock>

        <InlineBlock marginright="4rem">
          <FloatingText>{t('tooltips_hover_tap')}</FloatingText>
          <TooltipContainer data-for="trend-hover-tap" data-tip>
            <span style={{ borderBottom: `2px dotted ${theme.primaryColor}` }}>{t('trend')}</span>
          </TooltipContainer>
          <Tooltip id="trend-hover-tap" disable={false} tooltipText={t('tooltip_hive_low_battery')} />
        </InlineBlock>
      </InlineBlock>

      <InlineBlock width="30%" style={{ borderLeft: theme.primaryBorderStyle, paddingLeft: '4rem' }}>
        <StyledText>{t('weather_tooltip')}</StyledText>
        <InlineBlock margintop="4rem">
          <YardCardView
            t={t}
            yard={{
              yard_id: 116,
              yard_name: 'Standford',
              yard_nickname: 'SFD',
              total_hives: 32,
              ai_events: { nb_queenless: 0, nb_deadouts: 2, avg_pop_trend: -1, criticality: 0 },
              yard_location: 'SRID=4326;POINT (-103.3490000024841 46.17899999953806)',
              hardware: { nb_low_battery: 4, nb_low_data_rate: 4, nb_low_rssi: 4, nb_no_transmission: 0 },
              recently_updated: true,
              latest_update: '2020-09-23T23:13:12Z',
            }}
            showWeather={true}
          />
        </InlineBlock>
      </InlineBlock>

      <SectionBreak />
      <StyledText>{t('toast')}</StyledText>
      <InlineBlock marginright="4rem" width="22.5rem">
        <FloatingText>{t('toasts_with_icon')}</FloatingText>
        <SnackContainer>
          <img
            style={{
              filter: 'invert(88%) sepia(30%) saturate(661%) hue-rotate(33deg) brightness(89%) contrast(91%)',
              marginRight: '1rem',
            }}
            src={checkIcon}
            alt="check icon"
          />
          <p style={{ alignSelf: 'center' }}>{t('link_clipboard')}</p>
        </SnackContainer>
      </InlineBlock>

      <InlineBlock marginright="4rem">
        <FloatingText>{t('toasts_with_action')}</FloatingText>
        <SnackContainer>
          <p style={{ alignSelf: 'center', width: '200px' }}>{t('toast_map_error')}</p>
          <ToastActionButton>OK</ToastActionButton>
        </SnackContainer>
      </InlineBlock>
      <SectionBreak />

      <StyledText>{t('yard_alerts')}</StyledText>

      <ErrorRow>
        <Col style={{ display: 'flex', alignItems: 'center' }} xs={12}>
          <AlertIcon src={alertIcon} alt="yard alert" />
          <StyledErrorTitle>{t('yard_error_msg')}</StyledErrorTitle>
        </Col>
      </ErrorRow>

      <Break />

      <ErrorRow isMobile={true}>
        <Col style={{ display: 'flex', alignItems: 'center' }} xs={12}>
          <AlertIcon src={alertIcon} alt="yard alert" />
          <StyledErrorTitle isMobile={true}>{t('yard_error_msg')}</StyledErrorTitle>
        </Col>
      </ErrorRow>

      <SectionBreak />

      <StyledText>{t('input')}</StyledText>
      <InlineBlock width="30%" marginright="2rem">
        <InlineBlock>
          <Fieldset style={{ margin: '2rem 0' }} tabIndex={1}>
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              value=""
              label={t('email')}
              handleChange={(e) => e}
            />
          </Fieldset>
        </InlineBlock>
        <InlineBlock margintop="65px" marginright="0.5rem" marginleft="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="58px">
          <FloatingText>{t('empty')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock>
          <Fieldset tabIndex={1} style={{ border: theme.secondaryBorderStyle, margin: '2rem 0' }}>
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              value={''}
              label={t('email')}
              handleChange={(e) => e}
            />
          </Fieldset>
        </InlineBlock>
        <InlineBlock margintop="65px" marginright="0.5rem" marginleft="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="58px">
          <FloatingText>{t('hover')}</FloatingText>
        </InlineBlock>{' '}
        <br />
      </InlineBlock>

      <InlineBlock width="30%" marginright="2rem">
        <InlineBlock>
          <Fieldset tabIndex={1} style={{ border: theme.secondaryBorderStyle, margin: '2rem 0' }}>
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              value="joan@happybee.com"
              label={t('email')}
              handleChange={(e) => e}
            />
          </Fieldset>
        </InlineBlock>
        <InlineBlock margintop="65px" marginright="0.5rem" marginleft="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="58px">
          <FloatingText>{t('activated')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock>
          <Fieldset tabIndex={1} style={{ margin: '2rem 0', boxShadow: theme.buttonFocusBoxShadow, outline: 'none' }}>
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              value="joan@happybee.com"
              label={t('email')}
              handleChange={(e) => e}
            />
          </Fieldset>
        </InlineBlock>
        <InlineBlock margintop="65px" marginright="0.5rem" marginleft="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="58px">
          <FloatingText>{t('focus')}</FloatingText>
        </InlineBlock>{' '}
        <br />
      </InlineBlock>

      <InlineBlock width="auto%">
        <InlineBlock>
          <Fieldset style={{ margin: '2rem 0' }} tabIndex={1} error={true}>
            <TextInput
              id="password"
              name="password"
              type="password"
              autocomplete="password"
              required
              value="****"
              label={t('current_password')}
              handleChange={(e) => e}
              error={true}
            />
          </Fieldset>
        </InlineBlock>
        <InlineBlock margintop="65px" marginright="0.5rem" marginleft="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="58px">
          <FloatingText>{t('error')}</FloatingText>
        </InlineBlock>{' '}
        <br />
        <InlineBlock>
          <Fieldset style={{ margin: '2rem 0' }} tabIndex={1} disabled={true}>
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              value=""
              label={t('email')}
              handleChange={(e) => e}
              disabled={true}
            />
          </Fieldset>
        </InlineBlock>
        <InlineBlock margintop="65px" marginright="0.5rem" marginleft="0.5rem">
          <HorizontalDiv />
        </InlineBlock>
        <InlineBlock margintop="58px">
          <FloatingText>{t('disabled')}</FloatingText>
        </InlineBlock>{' '}
        <br />
      </InlineBlock>

      <SectionBreak />

      <StyledText>{t('dropdowns')}</StyledText>

      <InlineBlock width="220px" marginright="2rem">
        <FloatingText style={{ textAlign: 'center', marginBottom: '1rem' }}>{t('closed')}</FloatingText>
        <Dropdown
          t={t}
          inputName="owen"
          activeItemId="all"
          list={list}
          passDropdownDataToParent={(e) => e}
          renderIcon={false}
          selectBoxListStyle={{ height: '7.5rem' }}
        />
      </InlineBlock>

      <InlineBlock width="220px" marginright="2rem">
        <FloatingText style={{ textAlign: 'center', marginBottom: '1rem' }}>{t('hover')}</FloatingText>
        <Dropdown
          t={t}
          inputName="viola"
          activeItemId="all"
          list={list}
          passDropdownDataToParent={(e) => e}
          selectBoxCurrentStyle={{ border: '1px solid #D4D4D4' }}
          renderIcon={false}
          selectBoxListStyle={{ height: '7.5rem' }}
        />
      </InlineBlock>

      <InlineBlock width="220px" marginright="2rem">
        <FloatingText style={{ textAlign: 'center', marginBottom: '1rem' }}>{t('focus')}</FloatingText>
        <Dropdown
          t={t}
          inputName="flora"
          activeItemId="all"
          list={list}
          passDropdownDataToParent={(e) => e}
          selectBoxCurrentStyle={{ boxShadow: theme.selectFocusBoxShadow, cursor: 'pointer' }}
          renderIcon={false}
          selectBoxListStyle={{ height: '7.5rem' }}
        />
      </InlineBlock>
      <InlineBlock width="220px" marginright="2rem">
        <FloatingText style={{ textAlign: 'center', marginBottom: '1rem' }}>{t('open')}</FloatingText>
        <Dropdown
          t={t}
          inputName="miles"
          activeItemId="all"
          list={list}
          passDropdownDataToParent={(e) => e}
          selectBoxCurrentStyle={{ boxShadow: theme.selectFocusBoxShadow, cursor: 'pointer' }}
          selectBoxListStyle={{
            opacity: 1,
            display: 'inherit',
            animationName: 'none',
            cursor: 'pointer',
            height: '7.5rem',
          }}
          selectBoxOptionStyle={{ cursor: 'pointer' }}
          renderIcon={false}
        />
      </InlineBlock>
      <InlineBlock width="220px" marginright="2rem">
        <FloatingText style={{ textAlign: 'center', marginBottom: '1rem' }}>{t('open_hover')}</FloatingText>

        <div className="select-box">
          <div
            className="select-box-current"
            style={{ boxShadow: theme.selectFocusBoxShadow, cursor: 'pointer' }}
            tabIndex="0"
            autoFocus={true}
          >
            {list.map((y, i) => (
              <div key={y.id} className="select-box-value">
                <input
                  onChange={() => setActiveItem(y.id)}
                  className="select-box-input"
                  type="radio"
                  id={'hannah' + y.id}
                  name="hannah"
                  value={y.id}
                  checked={activeItem === y.id}
                  tabIndex="1"
                />
                <label onClick={() => toggleList()} className="select-box-input-text" htmlFor={'hannah' + y.id}>
                  {y.name}
                </label>
              </div>
            ))}

            <img
              onClick={() => toggleList()}
              className="select-box-icon"
              src={triangleDownIcon}
              alt="arrow icon"
              aria-hidden={true}
            />
          </div>

          <ul
            className="select-box-list"
            style={{ opacity: 1, display: 'inherit', animationName: 'none', cursor: 'pointer' }}
          >
            {list.map(
              (y, idx) =>
                0 !== idx && (
                  <li className="select-box-list-item" key={y.id}>
                    <label
                      style={{
                        backgroundColor: t('abnormal') === y.name && 'rgba(102, 102, 102, 0.05)',
                        cursor: 'pointer',
                      }}
                      className="select-box-option"
                      htmlFor={'hannah' + y.id}
                      aria-hidden={false}
                    >
                      {y.name}
                    </label>
                  </li>
                )
            )}
          </ul>
        </div>
      </InlineBlock>
      <InlineBlock width="220px" marginright="2rem">
        <FloatingText style={{ textAlign: 'center', marginBottom: '1rem' }}>{t('open_focus')}</FloatingText>
        <div className="select-box">
          <div
            className="select-box-current"
            style={{ boxShadow: theme.selectFocusBoxShadow, cursor: 'pointer' }}
            tabIndex="0"
            autoFocus={true}
          >
            {list.map((y, i) => (
              <div key={y.id} className="select-box-value">
                <input
                  onChange={() => setActiveItem(y.id)}
                  className="select-box-input"
                  type="radio"
                  id={'danny' + y.id}
                  name="danny"
                  value={y.id}
                  checked={activeItem === y.id}
                  tabIndex="1"
                />
                <label onClick={() => toggleList()} className="select-box-input-text" htmlFor={'danny' + y.id}>
                  {y.name}
                </label>
              </div>
            ))}
            <img
              onClick={() => toggleList()}
              className="select-box-icon"
              src={triangleDownIcon}
              alt="arrow icon"
              aria-hidden={true}
            />
          </div>

          <ul
            className="select-box-list"
            style={{ opacity: 1, display: 'inherit', animationName: 'none', cursor: 'pointer' }}
          >
            {list.map(
              (y, idx) =>
                0 !== idx && (
                  <li className="select-box-list-item" key={y.id}>
                    <label
                      style={t('abnormal') === y.name ? { cursor: 'pointer', boxShadow: '0 0 4px 2px #71A3FB' } : {}}
                      className="select-box-option"
                      htmlFor={'danny' + y.id}
                      aria-hidden={false}
                    >
                      {y.name}
                    </label>
                  </li>
                )
            )}
          </ul>
        </div>
      </InlineBlock>

      <SectionBreak />
    </>
  );
};

LiveStyleGuideSecondView.propTypes = {
  t: PropTypes.func.isRequired,
  activeItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setActiveItem: PropTypes.func.isRequired,
  toggleList: PropTypes.func.isRequired,
};
