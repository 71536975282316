import React, { MouseEventHandler, useCallback } from 'react';
import { IHeaderParams } from 'ag-grid-community';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { ArrowDown } from '@components/common/Icon/presets/ArrowDown';
import { ArrowsVertical } from '@components/common/Icon/presets/ArrowsVertical';
import { ArrowUp } from '@components/common/Icon/presets/ArrowUp';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { UnstyledButton } from '@components/common/UnstyledButton';
import {
  DEF_MENU_COLUMN_DEF,
  DEF_PERMANENT_COLUMNS_KEYS,
  DEF_PERMANENT_COLUMNS_WITH_MENU_KEYS,
} from '@components/yard/YardsList/constants';
import { useColumnName } from '@components/yard/YardsList/hooks/useColumnNameGetter';
import { useColumnNameForAnalytics } from '@components/yard/YardsList/hooks/useColumnNameGetterForAnalytics';
import { useColumnTooltip } from '@components/yard/YardsList/hooks/useColumnTooltipGetter';
import { useColumnViewName } from '@components/yard/YardsList/hooks/useColumnViewNameGetter';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { YardsListHeaderMenu } from '@components/yard/YardsList/YardsListHeaderMenu';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

import { useYardsListSelection } from './hooks/useYardsListSelectionContext';

const StyledShowOnHover = styled.span(
  () => css`
    opacity: 0;
    max-width: 0;
    margin: 0;
  `
);

const StyledHeader = styled(Box)<{ $enableHover: boolean }>(
  ({ theme, $enableHover }) => css`
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: stretch;
    padding: 0 ${theme.spacing.sm}px;
    transition: ${theme.animations.transitionFast('background-color')};
    overflow: hidden;

    ${$enableHover &&
    css`
      &:hover,
      &:focus-within {
        background-color: ${theme.colors.grey02};

        ${StyledShowOnHover} {
          opacity: 1;
          max-width: 96px;
          margin-left: ${theme.spacing.xs}px;
        }
      }
    `}
  `
);

const StyledHeaderText = styled(Text).attrs({ typography: 'Heading3', weight: '600' })(() => css``);

const StyledHeaderSubText = styled(Text).attrs({ typography: 'CaptionSmall', color: 'grey06' })(() => css``);

const StyledHeaderSortButton = styled(UnstyledButton).attrs({
  flat: true,
})(
  () =>
    css`
      display: flex;
      align-items: center;
      overflow: hidden;
      flex: 1;
    `
);

const StyledHeaderSortButtonTextBox = styled.div(
  () =>
    css`
      display: block;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      max-width: 100%;

      & > * {
        display: inline;
      }
    `
);

const StyledHeaderMenuButton = styled(UnstyledButton).attrs({
  flat: true,
})(() => css``);

export const YardsListHeader: React.VFC<IHeaderParams> = (props) => {
  const { column, progressSort, enableSorting, displayName: propDisplayName } = props;
  const { deselectAllAcrossPages } = useYardsListSelection();
  const columnDef = GridApiUtil.getColumnDefWithMetadata(column.getColDef());
  const columnField = columnDef.field;

  const headerId = `_${columnDef.colId}`;
  const headerMenuId = `_${columnDef.colId}-menu`;

  const mayShowMenu =
    !DEF_PERMANENT_COLUMNS_KEYS.includes(columnDef.field) ||
    DEF_PERMANENT_COLUMNS_WITH_MENU_KEYS.includes(columnDef.field);
  const mayUseHoverEffect = columnField !== DEF_MENU_COLUMN_DEF.field;

  const displayName = useColumnName(columnDef, propDisplayName);
  const tooltipText = useColumnTooltip(columnDef);
  const viewDisplayName = useColumnViewName(columnDef);
  const columnNameForAnalytics = useColumnNameForAnalytics(columnDef);

  const onSortRequested = useCallback<MouseEventHandler>(
    (event) => {
      deselectAllAcrossPages();
      progressSort(event.shiftKey);

      const sort = column.getSort();
      if (sort) {
        Analytics.sendEvent({
          event: AnalyticsEventType.WHITEBOARD_SORT,
          eventData: {
            column: columnNameForAnalytics,
            direction: sort,
          },
        });
      }
    },
    [column, columnNameForAnalytics, deselectAllAcrossPages, progressSort]
  );

  const menuButton = mayShowMenu ? (
    <StyledHeaderMenuButton id={headerMenuId}>
      <StyledShowOnHover>
        <YardsListHeaderMenu target={headerMenuId} {...props} />
      </StyledShowOnHover>
    </StyledHeaderMenuButton>
  ) : null;

  const tooltip = tooltipText ? (
    <Tooltip>
      <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltipText }} />
    </Tooltip>
  ) : null;

  const sortingIcon = column.isSortAscending() ? (
    <Box marginLeftSM>
      <ArrowUp />
    </Box>
  ) : column.isSortDescending() ? (
    <Box marginLeftSM>
      <ArrowDown />
    </Box>
  ) : (
    <StyledShowOnHover>
      <ArrowsVertical color="grey06" height={12} />
    </StyledShowOnHover>
  );

  const headerText = (
    <>
      <StyledHeaderText dashed={!!tooltipText} title={displayName}>
        {displayName}
        {tooltip}
      </StyledHeaderText>
      {Boolean(viewDisplayName) && (
        <>
          <br />
          <StyledHeaderSubText title={viewDisplayName ?? undefined}>{viewDisplayName}</StyledHeaderSubText>
        </>
      )}
    </>
  );

  if (enableSorting) {
    return (
      <StyledHeader id={headerId} $enableHover={mayUseHoverEffect}>
        <StyledHeaderSortButton onClick={onSortRequested}>
          <StyledHeaderSortButtonTextBox>{headerText}</StyledHeaderSortButtonTextBox>
          {sortingIcon}
        </StyledHeaderSortButton>
        {menuButton}
      </StyledHeader>
    );
  }

  return (
    <StyledHeader id={headerId} $enableHover={mayUseHoverEffect}>
      <Box fit column alignItems={'flex-start'} justifyContent={'center'}>
        {headerText}
      </Box>
      {menuButton}
    </StyledHeader>
  );
};
