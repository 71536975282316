import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { PRESETS, StyledTextComponents } from '@components/common/Text/styles';
import { TextProps, TextWeight } from '@components/common/Text/types';

export const Text: React.FC<TextProps> = ({
  weight,
  color,
  align,
  dashed,
  children,
  typography,
  lineHeight: propLineHeight,
  htmlTag: propHtmlTag,
  ...extraProps
}) => {
  const { colors, font } = useTheme();

  const { props, component } = useMemo(() => {
    if (!(typography in PRESETS)) {
      throw new Error(`Text component requires a valid typography. Given: ${typography || 'none'}`);
    }

    const { htmlTag: presetHtmlTag, lineHeight: presetLineHeight, ...presetStyles } = PRESETS[typography];

    const htmlTag = propHtmlTag ?? presetHtmlTag;
    const component = StyledTextComponents[htmlTag] ?? htmlTag;
    const fontColor = color ? colors[color] : 'inherit';
    const fontWeight: TextWeight = weight ?? '400';
    const fontHeight = propLineHeight ?? presetLineHeight;

    return {
      component,
      props: {
        role: 'text',
        textStyle: {
          fontWeight,
          color: fontColor,
          fontFamily: font.family,
          cursor: dashed ? 'pointer' : undefined,
          borderBottom: dashed ? `1px dashed ${colors.grey07}` : undefined,
          textAlign: align,
          lineHeight: fontHeight,
          ...presetStyles,
        },
        ...extraProps,
      },
    };
  }, [align, color, colors, dashed, extraProps, font.family, propHtmlTag, propLineHeight, typography, weight]);

  return React.createElement(component, props, children);
};
