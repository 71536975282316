import produce from 'immer';
import { sortBy } from 'lodash';

import { toggleDirection } from '@helpers/deprecated/toggleDirection';

import * as actionTypes from '../actionTypes';

const defaultState = {
  isFetching: false,
  workers: null,
  sortedWorkerList: null,
  order_by: ['status'],
  order_direction: 'DESC',
};

export const workerReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.WORKER_START:
        st.isFetching = true;
        break;
      case actionTypes.FETCH_WORKER_SUCCESS:
        // reorg the pending worker list to the top
        let sortedWorkerList = sortWorkers([...action.workerList], state.order_by);
        st.workers = action.workerList;
        st.sortedWorkerList = sortedWorkerList;
        st.isFetching = false;
        break;
      case actionTypes.ADD_WORKER_SUCCESS:
        st.isFetching = false;
        let newWorkers = [...state.workers, action.worker];
        st.workers = newWorkers;
        st.sortedWorkerList = newWorkers;
        break;
      case actionTypes.UPDATE_WORKER_SUCCESS:
        let w = [...state.workers];
        let i = w.findIndex((worker) => worker.id === action.worker.id);
        if (i >= 0) {
          w.splice(i, 1, {
            id: action.worker.id,
            ...action.worker,
          });
        }
        st.sortedWorkerList = sortWorkers([...w], state.order_by);
        [];
        st.workers = w;
        st.isFetching = false;
        break;
      case actionTypes.DEACTIVATE_WORKER_SUCCESS:
        st.isFetching = false;
        st.workers = state.workers.filter((item) => item.id !== action.id);
        st.sortedWorkerList = state.workers.filter((item) => item.id !== action.id);
        break;
      case actionTypes.WORKER_FAILURE:
        st.isFetching = false;
        break;
      case actionTypes.SORT_WORKER_LIST:
        let order_direction = toggleDirection(action.order_by, action.order_direction);
        let newSortedWorkers = sortWorkers([...state.sortedWorkerList], action.order_by);
        st.sortedWorkerList = newSortedWorkers;
        st.order_by = action.order_by;
        st.order_direction = order_direction;
        st.sorted = true;
        break;
      default:
    }
  });
};

function sortWorkers(workers, orderBy) {
  const workersStatusMap = {
    active: 0,
    pending: 1,
  };
  const sortableWorkers = workers.map((worker) => ({
    ...worker,
    status: workersStatusMap[worker.status],
    __item__: worker,
  }));
  return sortBy(sortableWorkers, orderBy).map(({ __item__ }) => __item__);
}
