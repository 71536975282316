import React from 'react';

import { Box } from '@components/common/Box';
import { Tab, Tabs } from '@components/common/Tabs';
import { TimelineByActivityDatePage } from '@components/timeline/TimelineByActivityDate';
import { TimelineByActivityDate } from '@components/timeline/TimelineByActivityDate/TimelineByActivityDate';
import { GridApiProvider } from '@components/yard/YardsList/context';
import { YardsListSaveViewProvider } from '@components/yard/YardsList/context/YardsListSaveViewProvider';
import { YardsListTooltipsProvider } from '@components/yard/YardsList/context/YardsListTooltipsProvider';
import { YardsListPage } from '@components/yard/YardsList/YardsListPage';
import { YardsMapPage } from '@components/yard/YardsMap';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import { WhiteboardHeader } from '@scenes/admin/Whiteboard/WhiteboardHeader';

export const Whiteboard: React.FC = () => {
  const t = useTranslation();

  return (
    <GridApiProvider>
      <YardsListTooltipsProvider>
        <YardsListSaveViewProvider>
          <Box block fullWidth>
            <WhiteboardHeader />

            <Tabs useURLPath>
              <Tab id={'yards-list'} title={t('yards_list')} path={APP.routes.whiteboardYardsList}>
                <YardsListPage />
              </Tab>

              <Tab id={'yards-map'} title={t('yards_map')} path={APP.routes.whiteboardYardsMap}>
                <YardsMapPage />
              </Tab>

              <Tab id={'activities'} title={t('activities')} path={APP.routes.whiteboardActivities}>
                <TimelineByActivityDatePage />
              </Tab>
            </Tabs>
          </Box>
        </YardsListSaveViewProvider>
      </YardsListTooltipsProvider>
    </GridApiProvider>
  );
};
