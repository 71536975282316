import React from 'react';

import { Text } from '@components/common/Text';
import {
  StyledTimelineEmpty,
  StyledTimelineEmptyImage,
  StyledTimelineEmptyImageBackground,
  StyledTimelineEmptyImageWrapper,
} from '@components/timeline/TimelineEmpty/styles';
import { useTranslation } from '@hooks/useTranslation';

export const TimelineEmpty: React.VFC<{ message?: string }> = ({ message }) => {
  const t = useTranslation();

  return (
    <StyledTimelineEmpty fit center column gapSM>
      <StyledTimelineEmptyImageWrapper>
        <StyledTimelineEmptyImageBackground />
        <StyledTimelineEmptyImage />
      </StyledTimelineEmptyImageWrapper>
      <Text typography={'Paragraph'} weight={'700'}>
        {t('activity_empty')}
      </Text>
      {message ? (
        <Text align={'center'} typography={'SmallParagraph'} dangerouslySetInnerHTML={{ __html: message }} />
      ) : null}
    </StyledTimelineEmpty>
  );
};
