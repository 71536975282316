import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';

import { Box } from '../Box';

export interface YardNameProps extends Omit<TextProps, 'children'> {
  name: string;
  contractName?: string | null;
  maxWidth?: number;
  disableTruncation?: boolean;
}

const StyledText = styled(Text)`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTruncatedText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const YardName: React.VFC<YardNameProps> = ({
  name,
  contractName,
  maxWidth: propMaxWidth,
  disableTruncation,
  ...textProps
}) => {
  const yardNameRef = useRef<HTMLElement>(null);
  const truncatedTextRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (!disableTruncation && truncatedTextRef.current) {
      const maxWidth = (propMaxWidth ?? 0) - (yardNameRef.current?.getBoundingClientRect().width ?? 0);
      truncatedTextRef.current.style.maxWidth = `${maxWidth}px`;
    }
  }, [disableTruncation, propMaxWidth]);

  if (contractName && disableTruncation) {
    return (
      <StyledText {...textProps}>
        <Box tag="span" paddingRightXXS>
          {contractName}:
        </Box>
        <span ref={yardNameRef}> {name}</span>
      </StyledText>
    );
  }

  if (contractName) {
    return (
      <StyledText {...textProps}>
        <StyledTruncatedText ref={truncatedTextRef}>{contractName}</StyledTruncatedText>
        <span ref={yardNameRef}>: {name}</span>
      </StyledText>
    );
  }

  if (disableTruncation) {
    return (
      <StyledText {...textProps}>
        <Box tag={'span'} paddingRightXXS>
          {name}
        </Box>
      </StyledText>
    );
  }

  return (
    <StyledText {...textProps}>
      <StyledTruncatedText ref={truncatedTextRef}>{name}</StyledTruncatedText>
    </StyledText>
  );
};
