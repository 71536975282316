import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { StyledCloseButton as StyledSecondaryButton } from '@components/deprecated/Elements/SummaryElements';
import { FormErrors } from '@components/pollination/FormContract/types';
import APP from '@config/constants';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useContract, useContractError } from '@hooks/useContract';
import { makeCloseContractModal, makeCreateContractThunk, makePatchContractThunk } from '@redux/Contract/actions';

import { FormSectionFinances } from './FormSectionFinances';
import { FormSectionGrowerInfo } from './FormSectionGrowerInfo';
import { FormSectionOverview } from './FormSectionOverview';
import {
  Dot,
  StyledFooter,
  StyledFormWrapper,
  StyledPrimaryButton,
  StyledSectionWrapper,
  StyledWrapper,
} from './styles';

type FormContractProps = {
  onCancel: () => void;
  contract?: BeeContract;
  isEdit?: boolean;
};

enum FormSection {
  OVERVIEW = 1,
  GROWER = 2,
  FINANCES = 3,
}

export const FormContract: React.FC<FormContractProps> = ({ onCancel, contract, isEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { isFetchingContract } = useContract();

  const overviewFormDefaultState: BeeContractOverview = useMemo(() => {
    return {
      name: contract?.name ?? '',
      cropTypesIds: contract?.cropTypesIds ?? [],
      nbRequiredHives: contract?.nbRequiredHives ?? undefined,
      beesIn: contract?.beesIn ?? null,
      beesOut: contract?.beesOut ?? null,
    };
  }, [contract]);

  const growerInfoDefaultState = useMemo(() => {
    return (
      contract?.grower ?? {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactEmail: '',
      }
    );
  }, [contract]);

  const financesFormDefaultState = useMemo(() => {
    return {
      costPerHive: contract?.costPerHive ?? undefined,
      totalPrice: contract?.totalPrice ?? undefined,
      address: contract?.address ?? '',
      notes: contract?.notes ?? '',
    };
  }, [contract]);

  const contractFormDefaultState = useMemo(() => {
    return {
      ...overviewFormDefaultState,
      grower: growerInfoDefaultState,
      ...financesFormDefaultState,
    };
  }, [overviewFormDefaultState, growerInfoDefaultState, financesFormDefaultState]);

  const [contractForm, setContractForm] = useState<BeeContractForm>(contractFormDefaultState);

  const [activeSection, setActiveSection] = useState(1);

  const handleSectionChange = (e: React.FormEvent<HTMLFormElement>, localFormState: Partial<BeeContractForm>) => {
    e.preventDefault();
    setContractForm((oldState) => ({ ...oldState, ...localFormState }));
    setActiveSection((current) => current + 1);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>, localFormState: Partial<BeeContractForm>) => {
    e.preventDefault();
    if (isFetchingContract) return;
    const completedForm = { ...contractForm, ...localFormState };
    setContractForm(completedForm);

    let response: BeeContract | undefined;
    if (isEdit && contract?.id) {
      response = await dispatch(makePatchContractThunk({ id: contract.id, ...completedForm }));
    } else {
      response = await dispatch(makeCreateContractThunk(completedForm));
    }

    if (response && response.id) {
      dispatch(makeCloseContractModal());
      history.push(generatePath(APP.routes.pollinationContract, { uid: response.id.toString() }));
    }
  };

  const handleSectionBack = () => setActiveSection((current) => current - 1);

  // TODO: Handle more possible form errors.
  const contractError = useContractError();
  const formErrors = useMemo<FormErrors>(() => {
    const errors = contractError || {};
    return { growerContactPhoneNumber: errors['grower.contactPhoneNumber'] ?? null };
  }, [contractError]);

  useEffect(() => {
    if ([formErrors.growerContactPhoneNumber].some((error) => !!error)) {
      setActiveSection(FormSection.GROWER);
    }
  }, [formErrors.growerContactPhoneNumber]);

  return (
    <StyledWrapper>
      <StyledFormWrapper>
        <Text typography="Heading2" weight="600">
          {isEdit ? t('edit_contract') : t('pollination_create_contract')}
        </Text>

        <StyledSectionWrapper>
          <FormSectionOverview
            defaultState={overviewFormDefaultState}
            section={FormSection.OVERVIEW}
            isActive={activeSection === FormSection.OVERVIEW}
            activeSection={activeSection}
            formErrors={formErrors}
            handleFormSectionSubmit={handleSectionChange}
          />
          <FormSectionGrowerInfo
            defaultState={growerInfoDefaultState}
            section={FormSection.GROWER}
            isActive={activeSection === FormSection.GROWER}
            activeSection={activeSection}
            formErrors={formErrors}
            handleFormSectionSubmit={handleSectionChange}
          />
          <FormSectionFinances
            formState={contractForm}
            defaultState={financesFormDefaultState}
            section={FormSection.FINANCES}
            isActive={activeSection === FormSection.FINANCES}
            activeSection={activeSection}
            formErrors={formErrors}
            handleFormSectionSubmit={handleFormSubmit}
          />
        </StyledSectionWrapper>
      </StyledFormWrapper>
      <StyledFooter>
        <Box alignItems={'center'}>
          {[FormSection.OVERVIEW, FormSection.GROWER, FormSection.FINANCES].map((section) => (
            <Dot key={section} isActive={section === activeSection} />
          ))}
        </Box>

        <div>
          {activeSection === FormSection.OVERVIEW ? (
            <StyledSecondaryButton type="button" label={t('cancel')} onClick={onCancel} />
          ) : (
            <StyledSecondaryButton type="button" label={t('back')} onClick={handleSectionBack} />
          )}
          {activeSection < FormSection.FINANCES ? (
            <StyledPrimaryButton type="submit" form={`contractForm-section-${activeSection}`}>
              {t('next')}
            </StyledPrimaryButton>
          ) : (
            <StyledPrimaryButton type="submit" form="contractForm-section-3">
              {isEdit ? t('save') : t('pollination_create')}
            </StyledPrimaryButton>
          )}
        </div>
      </StyledFooter>
    </StyledWrapper>
  );
};
