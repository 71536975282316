import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Logout = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3ZM16.407 16.007L19.707 12.707C20.098 12.316 20.098 11.684 19.707 11.293L16.407 7.993C16.016 7.602 15.384 7.602 14.993 7.993C14.602 8.384 14.602 9.016 14.993 9.407L16.586 11H9C8.447 11 8 11.447 8 12C8 12.553 8.447 13 9 13H16.586L14.993 14.593C14.602 14.984 14.602 15.616 14.993 16.007C15.188 16.202 15.444 16.3 15.7 16.3C15.956 16.3 16.212 16.202 16.407 16.007Z"
      />
    </IconSVGView>
  );
};
