import { hexToCSSFilter } from 'hex-to-css-filter';
import { DefaultTheme } from 'styled-components';

import theme_deprecated from './theme_deprecated';

/**
 * Default theme object.
 * It must be in sync with the last specification from ZeroHeight.
 * https://zeroheight.com/33a5fe70c/p/2551b5-colors
 * */
const index: DefaultTheme = {
  // Old theme specification, spread here for backward compatibility.
  ...theme_deprecated,

  colors: {
    coreBackground: '#F0F3F6',
    coreFocus02: '#71A3FB',
    coreFocus01: '#BFD4F9', // check this, it's the inner border of the focus
    coreWhite: '#FFFFFF',
    coreBlack: '#000000',

    grey01: '#F7F7F7',
    grey02: '#F2F2F2',
    grey03: '#E6E6E6',
    grey04: '#E1E3E3',
    grey05: '#D4D4D4',
    grey06: '#878E8D',
    grey07: '#454850',
    grey08: '#252629',

    orange01: '#F7BD13',
    orange02: '#D8A348',
    orange03: '#78500D',

    red01: '#F8EBE8',
    red02: '#D9766D',

    green01: '#DBF0C4',
    green02: '#A4D96C',

    yellow01: '#FFF4D0',
    yellow02: '#FFCF80',

    turquoise: '#12ABA2',

    disabled: '#D4D4D4',
    overlayBackground: 'rgba(0, 0, 0, 0.4)',
    overlayWhiteBackground: 'rgba(255, 255, 255, 0.5)',

    filter(color) {
      return hexToCSSFilter(this[color]).filter;
    },
  },

  spacing: {
    xxs: 4,
    xs: 8,
    sm: 16,
    md: 24,
    lg: 48,
    xl: 96,
  },

  shadows: {
    boxShadow01: '0 1px 3px rgba(102,102,102,0.10),  0 1px 3px rgba(102,102,102,0.20)',
    boxShadow02: '0 3px 6px rgba(102,102,102,0.10),  0 3px 6px rgba(102,102,102,0.20)',
    boxShadow03: '0 10px 20px rgba(102,102,102,0.15),  0 6px 6px rgba(102,102,102,0.15)',
    boxShadow04: '0 14px 28px rgba(102,102,102,0.20),  0 10px 10px rgba(102,102,102,0.30)',
  },

  font: {
    family: `
      Open Sans,
      -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto, Helvetica,
        Arial, sans-serif
    `,

    heading3FontSize: '0.88rem', // 14px
  },

  shape: {
    paperRadius01: 3,
    paperRadius02: 8,
    paperRadius03: 16,
  },

  navigation: {
    headerHeight: 72,
    headerHeightMobile: 56,
    tabBarHeight: 56,
    tabBarHeightMobile: 56,
  },

  zIndexes: [
    'base', // 0
    'content', // 1
    'lowLevelIndex', // to do: update this to a different name
    'table',
    'tableBody',
    'tableCell',
    'tableHeaderLeft',
    'tableHeaderTop',
    'tableHeaderTopLeft',
    'tableShadow',
    'tableOverlay',
    'tabs',
    'headerNav',
    'sidenav',
    'loading',
    'highLevelIndex', // to do: update this to a different name
    'modal',
    'tooltip',
  ],
  getZIndexOf(item) {
    return this.zIndexes.indexOf(item);
  },

  animations: {
    durationFast: 150,
    durationMedium: 250,
    durationLong: 350,

    easing: 'cubic-bezier(0.75, 0.35, 0.35, 0.75)',

    transitionFast(...props) {
      return props.map((p) => `${p} ${this.durationFast}ms ${this.easing}`).join(', ');
    },

    transitionMedium(...props) {
      return props.map((p) => `${p} ${this.durationMedium}ms ${this.easing}`).join(', ');
    },

    transitionLong(...props) {
      return props.map((p) => `${p} ${this.durationLong}ms ${this.easing}`).join(', ');
    },
  },
};

export default index;
