import React from 'react';

import { LoadingWavesView, Wave } from '@components/common/LoadingWaves/styles';
import { LoadingWavesProps } from '@components/common/LoadingWaves/types';

export const LoadingWaves: React.FC<LoadingWavesProps> = ({ loading, children, ...props }) => {
  return (
    <LoadingWavesView loading={loading} {...props}>
      {children}
      <Wave delay={900} />
      <Wave delay={1800} />
    </LoadingWavesView>
  );
};
