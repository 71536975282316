import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Text } from '@components/common/Text';
import { StyledSectionHeader } from '@components/yard/YardsMapDetailsPane/styles';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { BeeTrackEventsView } from '@scenes/admin/Alerts/components/BeeTrackEventsView';

export const YardsMapDetailsPaneVisit: React.VFC<Pick<BeeYardOnMapDetailedInfo, 'lastVisitInspections'>> = ({
  lastVisitInspections,
}) => {
  const { t } = useTranslation();

  // Using reducer state directly and events in snake case in
  // order to be compatible with the component <BeeTrackEventsView />
  const actionsList = useSelector<any, Array<BeeActionCategory>>((state) => state.actionReducer.actionList || []);
  const events = useMemo(() => CaseAdapter.objectToSnakeCase(lastVisitInspections ?? []), [lastVisitInspections]);

  return (
    <>
      <StyledSectionHeader typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
        {t('last_visit')}
      </StyledSectionHeader>

      {events.length === 0 ? (
        <Text typography={'Heading3'} color={'grey06'}>
          {t('activity_empty')}
        </Text>
      ) : (
        <BeeTrackEventsView t={t} actionList={actionsList} type={'yard_activity'} events={events} />
      )}
    </>
  );
};
