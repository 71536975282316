import React, { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipProps } from '@components/common/Tooltip/types';
import { useColumnNameForAnalytics } from '@components/yard/YardsList/hooks/useColumnNameGetterForAnalytics';
import { useYardsListTooltips } from '@components/yard/YardsList/hooks/useYardsListTooltipsContext';
import { Column, View } from '@components/yard/YardsList/types';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

const StyledTooltipContent = styled(Box)`
  min-width: 72px;
  min-height: 36px;
`;

export interface YardsListTooltipProps extends TooltipProps {
  id: number;
  column: Column;
  view: View;
  colDef: ColDef;
  children: (data: any) => any;
}

export const YardsListTooltip: React.FC<YardsListTooltipProps> = ({ id, column, view, colDef, children, ...props }) => {
  const { isFetching, fetchTooltipData, getTooltipData } = useYardsListTooltips();
  const data = getTooltipData(id, column, view);
  const columnNameForAnalytics = useColumnNameForAnalytics(colDef);

  const onShown = useCallback(() => {
    fetchTooltipData(id);
    Analytics.sendEvent({
      event: AnalyticsEventType.WHITEBOARD_TOOLTIP_VIEW,
      eventData: {
        column: columnNameForAnalytics,
      },
    });
  }, [columnNameForAnalytics, fetchTooltipData, id]);

  return (
    <Tooltip onShown={onShown} {...props}>
      <StyledTooltipContent>
        {Boolean(data) && children(data)}
        <Loading whiteLoader blurBackground visible={isFetching(id)} size={'1.5rem'} />
      </StyledTooltipContent>
    </Tooltip>
  );
};
