import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TimelineByActivityDate } from '@components/timeline/TimelineByActivityDate/TimelineByActivityDate';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeTimelineFetchActivityThunk } from '@redux/Timeline/actions';

export const TimelineByActivityDatePage: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { activitySet, isFetchingActivitySet } = useSelector((state) => state.timelineReducer);

  const isLoadingInitialItems = Boolean(isFetchingActivitySet && !activitySet?.activities.length);
  const isLoadingMoreItems = Boolean(isFetchingActivitySet && activitySet?.activities.length);

  const loadItems = useCallback(() => {
    dispatch(makeTimelineFetchActivityThunk());
  }, [dispatch]);

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimelineByActivityDate
      items={activitySet?.activities ?? []}
      itemsTotalCount={activitySet?.totalCount ?? 0}
      onMoreItemsRequest={loadItems}
      isLoadingInitialItems={isLoadingInitialItems}
      isLoadingMoreItems={isLoadingMoreItems}
      footerMessage={t('timeline_bottom_message')}
    />
  );
};
