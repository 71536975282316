import styled from 'styled-components';

import { UnstyledButton } from '../UnstyledButton';

export const MoreButton = styled(UnstyledButton)(
  ({ theme }) => `
      margin-right: ${theme.spacing.xs}px;
      padding: ${theme.spacing.xs}px;
      border-radius: 50%;
      transform: rotate(90deg);
      
      img {
        opacity: 0.5;
      }
      
      &:hover {
        background-color: ${theme.colors.grey04};
        
        img {
          opacity: 0.7;
        }
      }
    `
);

export const Icon = styled.img`
  width: 18px;
  height: 18px;
`;
