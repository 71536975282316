import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGProps } from '../types';
// logo is not a square icon, so height / width can't be defined with 'size'
export const Logo = ({ color = 'orange02', height, width }: IconSVGProps) => {
  return (
    <svg viewBox="0 0 150 30" height={height} width={width}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.436 13.1652L150 9.51816C147.88 7.34496 144.949 6 141.711 6C135.243 6 130 11.3651 130 17.9829C130 17.9835 130 17.9835 130 17.9835V30H135.04V17.9835C135.04 14.2134 138.027 11.1573 141.711 11.1573C143.557 11.1573 145.228 11.9252 146.436 13.1652"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.093 24.8337C116.061 24.8337 116.031 24.8357 116 24.8357C112.224 24.8357 109.164 21.7755 109.164 18.0003C109.164 14.2248 112.224 11.1643 116 11.1643C119.775 11.1643 122.835 14.2248 122.835 18.0003V24.8337H116.093ZM128 24.8337V17.9997C128 11.3726 122.628 6 116 6C109.373 6 104 11.3726 104 17.9997C104 24.6277 109.373 30 116 30C116.031 30 116.062 29.9991 116.093 29.9991V30H128V24.8337Z"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.1268 22.3679C67.8824 23.8764 66.0071 24.8369 63.9089 24.8369C60.1621 24.8369 57.1248 21.7761 57.1248 18.0003C57.1248 14.2253 60.1621 11.1646 63.9089 11.1646C66.0065 11.1646 67.8816 12.1244 69.1256 13.6318H75C73.2663 9.16524 68.9559 6 63.9084 6C57.3316 6 52 11.3726 52 18.0003C52 24.6277 57.3316 30 63.9084 30C68.9559 30 73.2663 26.8348 75 22.3679H69.1268"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0003 11.1648C41.0061 11.1648 43.5567 13.1056 44.4726 15.802H31.528C32.4433 13.1056 34.9945 11.1648 38.0003 11.1648ZM43.7014 21.771C42.4776 23.6181 40.3816 24.8364 38.0003 24.8364C34.6733 24.8364 31.9028 22.4591 31.2913 19.3113H39.8486V19.2722H49.9326C49.9769 18.8544 50 18.4299 50 18.0001C50 11.3726 44.6277 6 38 6C31.3726 6 26 11.3726 26 18.0001C26 24.6274 31.3726 30 38 30C43.3098 30 47.8108 26.5501 49.3926 21.771H43.7014Z"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8357 16.4052V18.1907C95.8357 21.9055 92.7747 24.9169 89.0003 24.9169C85.225 24.9169 82.1643 21.9055 82.1643 18.1907V11.4021H95.8456V6.37225H82.1643V0H77V18.1907C77.0006 24.7127 82.3723 30 89.0003 30C95.6277 30 101 24.7127 101 18.1907V16.4052H95.8357"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 6C5.37259 6 0 11.365 0 17.9831V30H5.16423V17.9831C5.16481 14.2136 8.22486 11.1575 11.9997 11.1575C15.7754 11.1575 18.8358 14.2136 18.8358 17.9831V30H24V17.9831C24 11.365 18.6277 6 11.9997 6"
      />
    </svg>
  );
};
