import React, { useMemo } from 'react';
import { generatePath } from 'react-router';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';

import { ModalProvider } from '@components/common/Modal';
import { Page404 } from '@components/common/Page404';
import { Snackbar } from '@components/deprecated/Snackbar';
import Spinner from '@components/deprecated/Spinner/Spinner';
import APP from '@config/constants';
import { QueryParams } from '@helpers/QueryParams';
import Admin from '@scenes/admin';
import Auth from '@scenes/auth';
import AppStore from '@scenes/auth/AppStore';
import CreateNewPassword from '@scenes/auth/CreateNewPassword/CreateNewPassword';
import ForgottenPassword from '@scenes/auth/ForgottenPassword/ForgottenPassword';
import { LiveStyleGuide } from '@scenes/auth/LiveStyleGuide/LiveStyleGuide';
import { GlobalStyle } from '@style/global';
import theme from '@style/theme';

const App = () => {
  const history = useMemo(() => createBrowserHistory(), []);

  return (
    <ModalProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router history={history}>
          <Switch>
            {[...APP.mainRedirects, ...APP.backwardCompatibilityRedirects].map(({ from, to }, index) => (
              <Route
                key={index}
                exact
                path={from}
                render={({ match }) => {
                  QueryParams.popLatestPathname();
                  return <Redirect to={generatePath(to, match.params)} />;
                }}
              />
            ))}

            <Route
              path={[
                APP.routes.whiteboardYardsList,
                APP.routes.whiteboardYardsMap,
                APP.routes.whiteboardYardsMapManagement,
                APP.routes.whiteboardYardsDetailDashboard,
                APP.routes.whiteboardActivities,

                APP.routes.pollination,
                APP.routes.pollinationContract,
                APP.routes.pollinationContractPrintable,

                APP.routes.settings,
                APP.routes.operation,
                APP.routes.alerts,
                APP.routes.groups,
              ]}
              render={(props) => <Admin {...props} />}
            />
            <Route path={APP.routes.login} render={(props: any) => <Auth {...props} />} />
            <Route path={APP.routes.passwordCreate} render={(props) => <CreateNewPassword {...props} />} />
            <Route path={APP.routes.passwordForgotten} render={(props) => <ForgottenPassword {...props} />} />
            <Route path={APP.routes.lifeStyle} render={(props: any) => <LiveStyleGuide {...props} />} />
            <Route exact path={APP.routes.appStore} component={AppStore} />

            <Route component={Page404} />
          </Switch>
          <Spinner />
        </Router>
        <Snackbar theming={theme} />
      </ThemeProvider>
    </ModalProvider>
  );
};

export default App;
