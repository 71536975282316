import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

// nectar:
import mapLegendIllu from '@assets/map-legend-illu.svg';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import { GMAP_FLYOVER_RADIUS } from '@config/google';

const ScrollableContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: ${(props) => (props.isMobile ? '484px' : '428px')};
  margin-left: 4px;
  padding: ${(props) => props.isMobile && '1rem'};
`;

const StyleImg = styled.img`
  margin-bottom: 2rem;
`;

const Text = styled.p`
  margin-bottom: 2rem;
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const LegendModalView = ({ t, isMobile, metric }) => (
  <ScrollableContainer isMobile={isMobile}>
    <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
      {t('yard_legend')}
    </StyledHeading2>
    <StyledParagraph style={{ marginBottom: '1.5rem' }}>
      <Text>
        {t('yard_legend_description', { beeFlyZoneWithUnits: metric ? `${GMAP_FLYOVER_RADIUS / 1000}km` : '1.8mi.' })}
      </Text>

      <StyleImg src={mapLegendIllu} alt="legend-illustration" />

      <Text>
        <Bold>{t('green')}</Bold>
        {t('yard_legend_green')}
      </Text>
      <Text>
        <Bold>{t('yellow')}</Bold>
        {t('yard_legend_yellow')}
      </Text>
      <Text>
        <Bold>{t('red')}</Bold>
        {t('yard_legend_red')}
      </Text>
    </StyledParagraph>
  </ScrollableContainer>
);

LegendModalView.propTypes = {
  t: PropTypes.func.isRequired,
  metric: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
