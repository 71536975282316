import styled, { css, keyframes } from 'styled-components';

const RotateAnimation = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

export const StyledLoading = styled.div<{ size: string }>(
  ({ theme, size }) => css`
    position: absolute;

    top: 50%;
    left: 50%;

    width: ${size};
    height: ${size};

    border-radius: 50%;
    border: 2px solid transparent;

    animation: ${RotateAnimation} 1s ${theme.animations.easing} infinite;
  `
);

export const StyledLoadingWrapper = styled.div<{
  visible?: boolean;
  whiteLoader?: boolean;
  blurBackground?: boolean;
  whiteBackground?: boolean;
  defaultBackground?: boolean;
}>(
  ({ theme, visible, whiteLoader, blurBackground, whiteBackground, defaultBackground }) => `
  pointer-events: none;
  position: absolute;
  inset: 0;

  transition: ${theme.animations.transitionMedium('opacity')};
  opacity: ${[undefined, true].includes(visible) ? 1 : 0};

  z-index: ${theme.getZIndexOf('loading')};
  background-color: ${
    defaultBackground
      ? theme.colors.coreBackground
      : blurBackground
      ? `rgba(255, 255, 255, 0.5)`
      : whiteBackground
      ? theme.colors.coreWhite
      : 'none'
  };
  border-radius: ${theme.shape.paperRadius01}px;

  ${StyledLoading} {
    ${
      whiteLoader
        ? `
            border-top-color: ${theme.colors.coreWhite};
            border-bottom-color: ${theme.colors.coreWhite};
          `
        : `
            border-top-color: ${theme.colors.grey07};
            border-bottom-color: ${theme.colors.grey07};
          `
    }
  }
`
);
