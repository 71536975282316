import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type YardDetailsReducer = Reducer<YardDetailsState, YardDetailsAction>;

export type YardDetailsAction =
  | { type: YardDetailsActionType.YARD_DETAILS_DISPOSE }
  | { type: YardDetailsActionType.YARD_DETAILS_FETCH_START; payload: { id: number } }
  | {
      type: YardDetailsActionType.YARD_DETAILS_FETCH_FINISH;
      payload: {
        data: BeeYardDetails | null;
        error: ApiResponseError | null;
      };
    };

export interface YardDetailsState {
  detail: BeeYardDetails | null;
  isFetchingDetail: boolean;
  hasError: boolean;
}

export enum YardDetailsActionType {
  YARD_DETAILS_DISPOSE = 'YARD_DETAILS_DISPOSE',
  YARD_DETAILS_FETCH_START = 'YARD_DETAILS_FETCH_START',
  YARD_DETAILS_FETCH_FINISH = 'YARD_DETAILS_FETCH_FINISH',
}
