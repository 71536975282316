import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const NotificationBell = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 32 32" size={size}>
      <path
        fill={useColor(color)}
        d="M26.293 23.293 26 23l-2-2v-8a8 8 0 0 0-6-7.738V5a2 2 0 0 0-4 0v.262A8 8 0 0 0 8 13v8l-2 2-.293.293A1 1 0 0 0 6.414 25h19.172a1 1 0 0 0 .707-1.707ZM18 26h-4.5v.5a2.5 2.5 0 0 0 5 0V26Z"
      />
      <path fill="none" d="M0 0h32v32H0z" />
    </IconSVGView>
  );
};
