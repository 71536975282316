import React from 'react';
//vendor
import { Col, Row } from 'react-grid-system';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@style/theme';

const WeatherRow = styled(Row)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const WeatherColumn = styled(Col)`
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.5rem;
  font-weight: ${(props) => props.fontWeight};
`;

const InvertedImg = styled.img`
  filter: grayscale(${(props) => props.grayscale}) invert(${(props) => props.invert});
  padding-top: 6px;
  padding-bottom: 6px;
`;

const Text = styled.span`
  letter-spacing: 0.5px;
  font-size: ${(props) => props.theme.tooltipSmallFontSize};
  color: ${(props) => props.color};
  line-height: 14px;
`;

export const WeatherTooltipView = ({ t, days, src, high, low }) => {
  return (
    <WeatherRow>
      {days.map((d, idx) => {
        const color = 0 === idx ? theme.buttonPrimaryTextColor : theme.inputColor;
        const filter = 0 === idx ? '1' : '0.8';
        const fontWeight = 0 === idx ? theme.fontWeightBold : theme.fontWeightSemiBold;
        return (
          <WeatherColumn fontWeight={fontWeight} key={idx} xs>
            <Text color={color}>{t(moment(d.day).format('ddd'))}</Text>
            <br />
            <InvertedImg grayscale={filter} invert={filter} src={src} alt={'weather icon' + d} />
            <br />
            <Text color={color}>
              {high}&deg; <span style={{ marginRight: '5%' }} />
              {low}&deg;
            </Text>
          </WeatherColumn>
        );
      })}
    </WeatherRow>
  );
};

InvertedImg.propTypes = {
  grayscale: PropTypes.string.isRequired,
  invert: PropTypes.string.isRequired,
};

Text.propTypes = {
  color: PropTypes.string.isRequired,
};

WeatherTooltipView.propTypes = {
  t: PropTypes.func.isRequired,
  days: PropTypes.array.isRequired,
  src: PropTypes.string.isRequired,
  high: PropTypes.number.isRequired,
  low: PropTypes.number.isRequired,
};
