import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Select } from '@components/deprecated/Select/Select';

import { StyledButton, StyledText } from './style';

const KeepAddingComponent = ({ t, isMobile, handleKeepAdding, keepAdding }) => {
  const [hover, handleHover] = useState(false);

  return (
    <StyledButton
      isMobile={isMobile}
      type="checkbox"
      onMouseOver={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onClick={handleKeepAdding}
    >
      <Select
        id="keep_adding"
        type="checkbox"
        src={keepAdding}
        hover={hover}
        altActive="keep adding group enabled"
        altInactive="keep adding group disabled"
        tooltip={false}
      />
      <StyledText>{t('keep_adding')}</StyledText>
    </StyledButton>
  );
};

KeepAddingComponent.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleKeepAdding: PropTypes.func.isRequired,
  keepAdding: PropTypes.bool.isRequired,
};

export default KeepAddingComponent;
