import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { TimelineBar } from '@components/deprecated/Elements/EventsElements';
import { EmptyAlertsView } from '@components/deprecated/EmptyView/EmptyAlertsView';
// nectar
import { isEmptyArray } from '@helpers/deprecated/array';
import { makeOpenUpdateYardModalAction } from '@redux/deprecated/actions';
import { AlertsView } from '@scenes/admin/Alerts/AlertsView';

class AlertsCommon extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditYard = this.handleEditYard.bind(this);
  }

  handleEditYard(id) {
    const { openModifyYardModal } = this.props;
    openModifyYardModal({ modalType: 'update-yard', yard: { id } });
  }

  render() {
    const { t, alertView, isMobile, unreadEvents, readEvents } = this.props;

    return (
      <>
        {!readEvents && !unreadEvents && <EmptyAlertsView t={t} isMobile={isMobile} />}

        {!isEmptyArray(unreadEvents) && (
          <>
            <TimelineBar>{t('new')}</TimelineBar>

            <AlertsView t={t} alertView={alertView} eventList={unreadEvents} handleEditYard={this.handleEditYard} />
          </>
        )}
        {!isEmptyArray(readEvents) && (
          <>
            <TimelineBar>{t('recent')}</TimelineBar>

            <AlertsView t={t} alertView={alertView} eventList={readEvents} handleEditYard={this.handleEditYard} />
          </>
        )}
      </>
    );
  }
}

AlertsCommon.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  alertView: PropTypes.string,
};

const mapStateToProps = (state) => ({
  yardsList: state.beeTrackYardListReducer.yardsList,
  yard: state.beeTrackYardListReducer.yard,
  user: state.accountReducer.user,
  permissions: state.accountReducer.permissions,
  unreadEvents: state.eventListReducer.unreadEvents,
  readEvents: state.eventListReducer.readEvents,
});

const mapDispatchToProps = (dispatch) => ({
  openModifyYardModal: (content) => {
    dispatch(makeOpenUpdateYardModalAction(content));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlertsCommon));
