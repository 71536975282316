import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import smallPinIcon from '@assets/Small_Pin.svg';
import clockIcon from '@assets/xSmall_Clock.svg';
import xSmallPinIcon from '@assets/xSmall_Pin.svg';
import yardIcon from '@assets/xSmall_Yard.svg';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import APP from '@config/constants';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';

import {
  ActiveStatus,
  CancelCommand,
  ClearCommand,
  NbHives,
  NoResults,
  ResultsFooter,
  ResultsHeader,
  ResultsLayout,
  ResultsList,
  ResultsListItem,
  SearchBoxContainer,
  ShowMore,
  Spacer,
  StyledYardName,
  ViewOnMap,
  YardIcon,
} from './style';

const DEFAULT_NUM_RESULTS = 5;

const SearchBox = ({
  t,
  isMobile,
  isLoading,
  searchInput,
  setSearchInput,
  setSearchFocused,
  searchExpandedOnMobile,
  searchResults,
  clearYardFilters,
  addYardToSearchHistory,
  searchHistory,
}) => {
  const inputRef = useRef();
  const searchRef = useRef();
  const firstResultRef = useRef();
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();
  const [resultFocused, setResultFocused] = useState(false);

  const firstResults = useMemo(() => searchResults.slice(0, DEFAULT_NUM_RESULTS), [searchResults]);
  const results = showAll ? searchResults : firstResults;

  const haveResults = searchResults.length > 0;
  const showHistory = searchHistory.length > 0 && searchInput === '';
  const noDropDown = searchHistory.length === 0 && searchInput === '';

  const list = showHistory ? [...searchHistory].reverse() : results;

  const expandedResultsOnMobile = searchExpandedOnMobile && showAll && haveResults;

  const onCancel = useCallback(() => {
    setSearchInput('');
    setSearchFocused(false);
    setResultFocused(false);
  }, [setSearchInput, setSearchFocused]);

  const onYardSelect = useCallback(
    (yardId) => {
      history.push(generatePath(APP.routes.yard, { uid: yardId }));
      addYardToSearchHistory(yardId);
      onCancel();
    },
    [history, addYardToSearchHistory, onCancel]
  );

  const onMapClick = useCallback(
    (yardId) => {
      const active = document.activeElement;
      clearYardFilters();
      addYardToSearchHistory(yardId);
      onCancel();

      // Cleaning and setting selection again to make sure the navigation
      // occurs even if the same item is re-selected.
      history.push(APP.routes.yardsMap);
      setTimeout(() => history.push(APP.routes.yardsMap + `?yard=${yardId}`));

      if (active) {
        active.blur();
      }
    },
    [history, addYardToSearchHistory, onCancel, clearYardFilters]
  );

  useEffect(() => {
    const node = searchRef.current;
    const onKeyDown = (e) => {
      const active = document.activeElement;

      if (active.getAttribute('data-search-input') === 'input') {
        if (e.key === 'ArrowDown' && firstResultRef && firstResultRef.current) {
          setResultFocused(true);
          firstResultRef.current.focus();
        } else if (e.key === 'Escape') {
          setSearchInput('');
          inputRef.current.blur();
        }
      } else if (active.getAttribute('data-search-result') === 'result') {
        if (e.key === 'ArrowDown' && active.nextSibling) {
          active.nextSibling.focus();
        } else if (e.key === 'ArrowUp' && active.previousSibling) {
          active.previousSibling.focus();
        } else if (e.key === 'Escape') {
          setSearchInput('');
          setResultFocused(false);
        } else if (e.key === 'Enter') {
          onYardSelect(parseInt(active.getAttribute('data-search-id')));
          active.blur();
        }
      }
    };

    node.addEventListener('keydown', onKeyDown);
    return () => {
      node.removeEventListener('keydown', onKeyDown);
    };
  }, [inputRef, searchRef, firstResultRef, setResultFocused, setSearchInput, onYardSelect]);

  return (
    <SearchBoxContainer
      ref={searchRef}
      hasInput={searchInput}
      isMobile={isMobile}
      searchExpandedOnMobile={searchExpandedOnMobile}
      expanded={expandedResultsOnMobile}
      resultFocused={resultFocused}
      noDropDown={noDropDown}
      showHistory={showHistory}
    >
      <div>
        <input
          ref={inputRef}
          type="text"
          placeholder="Search"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
            setShowAll(false);
          }}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
          data-search-input="input"
        />
        {searchInput && (
          <ClearCommand
            onClick={() => {
              setSearchInput('');
              inputRef.current.focus();
            }}
            searchExpandedOnMobile={searchExpandedOnMobile}
          >
            {t('clear')}
          </ClearCommand>
        )}
        <ResultsLayout>
          <Loading visible={isLoading} size="1.5rem" whiteBackground />

          {(haveResults || showHistory) && (
            <ResultsHeader>{showHistory ? t('recent_searches') : t('yards')}</ResultsHeader>
          )}
          {!haveResults && searchInput && <NoResults>{t('no_results', { searchInput })}</NoResults>}
          <ResultsList isMobile={isMobile}>
            {list.map((yard, index) => (
              <ResultsListItem
                data-search-result="result"
                data-search-id={yard.id}
                key={yard.id}
                onMouseDown={(e) => {
                  if (e.target.getAttribute('data-map') === 'data-map') {
                    onMapClick(yard.id);
                  } else {
                    onYardSelect(yard.id);
                  }
                }}
                ref={index === 0 ? firstResultRef : null}
                onBlur={(e) => {
                  const active = e.relatedTarget;
                  if (!active || active.getAttribute('data-search-result') !== 'result') {
                    setResultFocused(false);
                  }
                }}
                onMouseOver={(e) => !isMobile && resultFocused && e.target.focus()}
              >
                <YardIcon src={showHistory ? clockIcon : yardIcon} />
                <ActiveStatus active={yard.nbHives > 0} />
                <StyledYardName
                  name={yard.name}
                  contractName={yard.contractName}
                  maxWidth={160}
                  typography={'SmallParagraph'}
                />
                <NbHives>{maybePluralize(yard.nbHives, 'hive', t)}</NbHives>
                <Spacer />
                <ViewOnMap isMobile={isMobile}>
                  {!isMobile && <div data-map="data-map">{t('view_on_map')}</div>}
                  <img src={isMobile ? smallPinIcon : xSmallPinIcon} alt="Map" data-map="data-map" />
                </ViewOnMap>
              </ResultsListItem>
            ))}
          </ResultsList>
          {searchResults.length > firstResults.length && (
            <ShowMore>
              <div
                onMouseUp={() => {
                  setShowAll((prev) => !prev);
                  inputRef.current.focus();
                  setResultFocused(false);
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                {showAll
                  ? t('show_less')
                  : t('show_more', { results: maybePluralize(searchResults.length, 'result', t) })}
              </div>
            </ShowMore>
          )}
          {!isMobile && (showHistory || haveResults) && (
            <ResultsFooter>
              Use <span>Arrows</span> to navigate, <span>Enter</span> to select, <span>Escape</span> to cancel search
            </ResultsFooter>
          )}
        </ResultsLayout>
      </div>
      {searchExpandedOnMobile && <CancelCommand onClick={onCancel}>Cancel</CancelCommand>}
    </SearchBoxContainer>
  );
};

SearchBox.propTypes = {
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  setSearchFocused: PropTypes.func.isRequired,
  searchExpandedOnMobile: PropTypes.bool,
  searchResults: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  clearYardFilters: PropTypes.func.isRequired,
  addYardToSearchHistory: PropTypes.func.isRequired,
  searchHistory: PropTypes.array.isRequired,
};

SearchBox.defaultProps = {
  isMobile: false,
  searchExpandedOnMobile: false,
};

export default SearchBox;
