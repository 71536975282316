import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ArrowUp = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M12.6 8.29999L17.85 15.3005C18.0204 15.5278 18.0479 15.8318 17.9208 16.0859C17.7938 16.34 17.5341 16.5005 17.25 16.5005H6.75C6.46592 16.5005 6.20622 16.34 6.07918 16.0859C5.95213 15.8318 5.97955 15.5278 6.15 15.3005L11.4 8.29999C11.5416 8.11113 11.7639 7.99999 12 7.99999C12.2361 7.99999 12.4584 8.11113 12.6 8.29999Z"
      />
    </IconSVGView>
  );
};
