import React, { Ref, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useState } from 'react';
import produce from 'immer';

import { Box } from '@components/common/Box';
import { useActiveTabQueryParamsState, useActiveTabURLPathState } from '@components/common/Tabs/hooks';
import { TabBar, TabBarButton, TabBarButtonInner } from '@components/common/Tabs/styles';
import { TabID, TabInfo, TabsContextValue, TabsProps } from '@components/common/Tabs/types';
import { Text } from '@components/common/Text';
import { buildShallowEquals } from '@helpers/deprecated/shallowEquals';
import { QueryParams } from '@helpers/QueryParams';

import { TabsContext } from './context';

export const Tabs = React.memo(
  ({ ref, isFixed, useURLPath, onTabChange, children }: TabsProps & { ref?: Ref<TabsContextValue> }) => {
    const [tabs, setTabs] = useState<Record<string, TabInfo>>({});
    const [isTabBarVisible, setTabBarVisible] = useState(true);

    const orderedTabsList = useMemo(() => Object.values(tabs).sort((a, b) => a.order - b.order), [tabs]);
    const defaultTab = orderedTabsList[0]?.id ?? null;

    const queryParamsState = useActiveTabQueryParamsState(tabs, defaultTab);
    const urlPathState = useActiveTabURLPathState(tabs, defaultTab);
    const [activeTab, setActiveTab] = useURLPath ? urlPathState : queryParamsState;

    const goToTab = useCallback<TabsContextValue['goToTab']>(
      (id: TabID, extraParams) => {
        if (id in tabs) {
          setActiveTab(id);
          extraParams &&
            QueryParams.setCurrentQueryParams({
              ...QueryParams.getCurrentQueryParams(),
              ...extraParams,
            });
        }
      },
      [setActiveTab, tabs]
    );

    const updateTabState = useCallback((id: TabID, state: Partial<Omit<TabInfo, 'id'>>) => {
      setTabs((curr) =>
        produce(curr, (curr) => {
          const isActivatingTab = state.isActive;
          if (isActivatingTab) {
            // Disable all tabs by default
            Object.values(curr).forEach((tab) => (tab.isActive = false));
          }
          curr[id] = { ...curr[id], ...state };
        })
      );
    }, []);

    const callTabChangeListener = useCallback(() => {
      onTabChange && onTabChange({ tab: tabs[activeTab] });
    }, [activeTab, onTabChange, tabs]);

    const __registerTab = useCallback(
      ({ id, path, title }) => {
        setTabs((currTabs) => {
          const existingTab = currTabs[id];

          if (existingTab) {
            return { ...currTabs, [id]: { ...existingTab, id, path, title } };
          }

          const currTabsList = Object.keys(currTabs);
          const isActive = activeTab ? id === activeTab : useURLPath ? null : !currTabsList.length;
          const hasBeenVisited = isActive;
          const order = currTabsList.length;

          return { ...currTabs, [id]: { id, path, title, order, hasBeenVisited, isActive } };
        });
      },
      [activeTab, useURLPath]
    );

    const __unregisterTab = useCallback(({ id }) => {
      setTabs((curr) => {
        delete curr[id];
        return curr;
      });
    }, []);

    const contextValue = useMemo<TabsContextValue>(
      () => ({
        tabs,
        activeTab,
        goToTab,
        isFixed,
        isTabBarVisible,
        setTabBarVisible,
        __registerTab,
        __unregisterTab,
      }),
      [__registerTab, __unregisterTab, activeTab, goToTab, isFixed, isTabBarVisible, tabs]
    );

    useImperativeHandle(ref, () => contextValue, [contextValue]);

    useLayoutEffect(() => {
      if (activeTab) {
        updateTabState(activeTab, { isActive: true, hasBeenVisited: true });
        callTabChangeListener();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const renderTabBar = useCallback(() => {
      return orderedTabsList.length ? (
        <TabBar $isFixed={isFixed} $isTabBarVisible={isTabBarVisible}>
          {orderedTabsList.map((tab) => (
            <TabBarButton key={tab.id} onClick={() => goToTab(tab.id)}>
              <TabBarButtonInner $isActive={tab.id === activeTab}>
                <Text typography={'SmallParagraph'} weight={'600'}>
                  {tab.title}
                </Text>
              </TabBarButtonInner>
            </TabBarButton>
          ))}
        </TabBar>
      ) : null;
    }, [isFixed, activeTab, goToTab, isTabBarVisible, orderedTabsList]);

    return (
      <TabsContext.Provider value={contextValue}>
        <Box column stretch>
          {renderTabBar()}
          {children}
        </Box>
      </TabsContext.Provider>
    );
  },
  buildShallowEquals<TabsProps>(['children'])
);

Tabs.displayName = 'Tabs';
