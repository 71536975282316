import React, { useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Logo } from '@components/common/Icon/presets/Logo';
import { Text } from '@components/common/Text';
import { Translate } from '@hooks/useTranslation';

import { ContractBox } from './ContractBox';
import {
  StyledBeetrackText,
  StyledColumn,
  StyledColumnWrapper,
  StyledDetailText,
  StyledExportText,
  StyledFooter,
  StyledHeader,
  StyledPage,
  StyledRightHand,
} from './styles';

const PRINTABLE_TEMPLATE_VERSION = '1.0';

interface HeaderProps {
  heading: string;
  subheading: string;
  t: Translate;
  lang: BeeSupportedLangCode;
  firstPage?: boolean;
}
export interface PrintablePageProps extends HeaderProps {
  totalPages: number;
}
const Header: React.FC<HeaderProps> = ({ heading, subheading, t, lang, firstPage }) => {
  const localMoment = moment();
  localMoment.locale(lang);
  const todayFormatted = localMoment.format('LL');
  return (
    <StyledHeader>
      {firstPage && (
        <StyledColumnWrapper>
          <StyledColumn>
            <ContractBox lang={lang} />
          </StyledColumn>
          <StyledRightHand>
            <StyledExportText typography="SmallParagraph">
              {t('exported_on', { date: todayFormatted })}
            </StyledExportText>
          </StyledRightHand>
        </StyledColumnWrapper>
      )}

      <StyledColumnWrapper marginTopMd={!firstPage}>
        <StyledColumn>
          <Text typography="Heading2" htmlTag="h1" weight={'600'}>
            {heading}
          </Text>
          <StyledDetailText>{subheading}</StyledDetailText>
        </StyledColumn>
        <StyledRightHand>
          <Logo width="100" />
          <StyledBeetrackText data-pdf-unselectable textRight>
            {t('beetrack')}
          </StyledBeetrackText>
        </StyledRightHand>
      </StyledColumnWrapper>
    </StyledHeader>
  );
};

export const PolliContractPrintablePage: React.FC<PrintablePageProps> = ({
  children,
  t,
  totalPages,
  lang,
  ...headerProps
}) => {
  const pageRef = useRef<HTMLDivElement>(null);
  const pageNumber = useRef(1);

  useLayoutEffect(() => {
    if (pageRef && pageRef.current) {
      const parent = pageRef.current.closest('.js-pages');
      if (parent) {
        const pageIndex = [...parent.querySelectorAll('.js-page')].indexOf(pageRef.current);
        pageNumber.current = pageIndex + 1;
      }
    }
  }, [pageRef, totalPages]);
  return (
    <StyledPage className="js-page" ref={pageRef}>
      <Header t={t} lang={lang} {...headerProps} />
      {children}
      <StyledFooter>
        <Box alignItems={'center'} justifyContent={'space-between'}>
          <Text typography="SmallParagraph" color={'grey06'}>
            {t('pollination_pdf_template_version', { version: PRINTABLE_TEMPLATE_VERSION })}
          </Text>

          <p>{t('page_of_pages', { current: pageNumber.current, total: totalPages })}</p>
        </Box>
      </StyledFooter>
    </StyledPage>
  );
};
