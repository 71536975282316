import React from 'react';
import { useTranslation } from 'react-i18next';

import { Feeding } from '@components/common/Icon/presets/Feeding';
import { Treatment } from '@components/common/Icon/presets/Treatment';
import { TooltipYardAction } from '@components/common/TooltipYardAction';
import {
  StyledSectionHeader,
  StyledSectionRow,
  StyledSectionSubHeader,
  StyledSectionValue,
} from '@components/yard/YardsMapDetailsPane/styles';
import { useActionsNames } from '@hooks/useActions';

export const YardsMapDetailsPaneActions: React.VFC<BeeYardOnMapDetailedInfo> = ({
  lastFeedingAction,
  lastFeedingDatetime,
  lastTreatmentAction,
  lastTreatmentDatetime,
}) => {
  const { t } = useTranslation();
  const actionsNames = useActionsNames();

  return (
    <>
      <StyledSectionHeader typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
        {t('feeding_and_treatment')}
      </StyledSectionHeader>
      <StyledSectionRow>
        <StyledSectionSubHeader>
          <Feeding size={16} />
          {t('last_feed')}
        </StyledSectionSubHeader>

        {lastFeedingAction ? (
          <StyledSectionValue dashed>
            {actionsNames[lastFeedingAction] ?? '-'}
            <TooltipYardAction
              icon={Feeding}
              title={t('feeding')}
              actions={[{ actionId: lastFeedingAction, dates: [lastFeedingDatetime] }]}
            />
          </StyledSectionValue>
        ) : (
          <StyledSectionValue>-</StyledSectionValue>
        )}
      </StyledSectionRow>
      <StyledSectionRow>
        <StyledSectionSubHeader>
          <Treatment size={16} />
          {t('last_treatment')}
        </StyledSectionSubHeader>

        {lastTreatmentAction ? (
          <StyledSectionValue dashed>
            {actionsNames[lastTreatmentAction] ?? '-'}
            <TooltipYardAction
              icon={Treatment}
              title={t('Treatment')}
              actions={[{ actionId: lastTreatmentAction, dates: [lastTreatmentDatetime] }]}
            />
          </StyledSectionValue>
        ) : (
          <StyledSectionValue>-</StyledSectionValue>
        )}
      </StyledSectionRow>
    </>
  );
};
