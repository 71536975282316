import { useCallback } from 'react';
import { useTheme } from 'styled-components';

import { MetricValue } from '@redux/Yards/types';

export function useCheckTrendDirectionGetter() {
  const theme = useTheme();
  return useCallback(
    (metricValue: MetricValue[], positiveDirection: boolean) => {
      const stable = { color: 'none', direction: '' };
      if (metricValue.length > 0) {
        if (metricValue[0].previousValue && metricValue[0].value) {
          if (metricValue[0].value > metricValue[0].previousValue) {
            if (positiveDirection) {
              return { color: theme.colors.green02, direction: 'rotate(0deg)' };
            } else {
              return { color: theme.colors.red02, direction: 'rotate(0deg)' };
            }
          } else {
            if (metricValue[0].value < metricValue[0].previousValue) {
              if (positiveDirection) {
                return { color: theme.colors.red02, direction: 'rotate(180deg)' };
              } else {
                return { color: theme.colors.green02, direction: 'rotate(180deg)' };
              }
            } else {
              return stable;
            }
          }
        } else {
          return stable;
        }
      } else {
        return stable;
      }
    },
    [theme.colors.green02, theme.colors.red02]
  );
}
