import React, { Fragment, ReactNode, ReactNodeArray } from 'react';
import { isArray } from 'lodash';

export const Children = {
  isEmpty,
  isNotEmpty,
  hasOnlyText,
  asList,
  createWithSeparators,
};

function isEmpty(children: ReactNode): boolean {
  return asList(children).every((child) => [undefined, null, ''].includes(child as any));
}

function isNotEmpty(children: ReactNode): boolean {
  return !isEmpty(children);
}

function hasOnlyText(children: ReactNode): boolean {
  return asList(children).every((child) => ['undefined', 'string', 'number'].includes(typeof child));
}

function asList(children: ReactNode): ReactNodeArray {
  return isArray(children) ? children : [children];
}

/**
 * Returns a React children composed by the given items intercalated
 * with the given separators.
 *
 * Usage:
 *     const children = Children.createWithSeparators(["A", "B", "C"])
 *     return <span>{children}</span>
 *     rendered output >> A, B, C
 *
 *     const children = Children.createWithSeparators(["A", "B", "C"], { lastSeparator: ", and then" })
 *     return <span>{children}</span>
 *     rendered output >> A, B, and then C
 *
 *     const children = Children.createWithSeparators(
 *         ["A", "B", "C"],
 *         { itemWrapper: (item, separator) => <><a>{item}</a>{separator}</> }
 *     )
 *     return <span>{children}</span>
 *     rendered output >> A, B, C // Each items turns into an anchor link.
 * */
export function createWithSeparators<I>(
  items: Array<I>,
  options: {
    /** Separator to use. Defaults to comma. */
    separator?: ReactNode;

    /**
     * In case there are only two items, this separator
     * is used. Defaults to 'separator' value.
     * */
    singleSeparator?: ReactNode;

    /**
     * In case there are more than two items, or
     * 'singleSeparator' is not given, this separator
     * is used. Defaults to 'separator' value. */
    lastSeparator?: ReactNode;

    /**
     * Apply a transform to each item and its next
     * separator before it being returned.
     * */
    itemWrapper?: (item: I, separator: ReactNode) => ReactNode;
  }
): Array<any> {
  const defOptions = { separator: ', ' };
  const { separator, singleSeparator, lastSeparator, itemWrapper } = { ...defOptions, ...options };

  const children = [] as Array<ReactNode>;

  items.forEach((item, index) => {
    const hasTwoItems = items.length === 2;
    const hasMoreThanOneItem = items.length > 1;
    const hasSeparator = hasMoreThanOneItem && index < items.length - 1;
    const isLastSeparator = index === items.length - 2;

    let separatorToUse: any = '';

    if (hasSeparator) {
      separatorToUse = separator;

      if (isLastSeparator) {
        if (hasTwoItems) {
          separatorToUse = singleSeparator ?? lastSeparator ?? separator;
        } else {
          separatorToUse = lastSeparator ?? separator;
        }
      }
    }

    if (itemWrapper) {
      children.push(itemWrapper(item, separatorToUse));
    } else {
      return children.push(item, separatorToUse);
    }
  });

  if (itemWrapper) {
    return children.map((child, key) => React.createElement(Fragment, { key }, child));
  }

  return children.filter(Boolean);
}
