import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Loading } from '@components/common/Loading';
import { MapPane } from '@components/common/Map';
import { DEF_PANE_WIDTH } from '@components/common/Map/constants';
import { useMap } from '@components/common/Map/hooks';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapMode } from '@components/pollination/PolliMap/types';
import { PolliMapPaneSummary } from '@components/pollination/PolliMapPaneSummary/PolliMapPaneSummary';

export const PolliMapPane: React.FC = ({ children }) => {
  const {
    mode,
    isIdle,
    isStatic,
    isManaging,
    isManagingElements,
    isManagingAnElement,
    isDraggingElements,
    isLoading,
    selectedDrop,
    selectedBlock,
    selectedPoi,
  } = usePolliMap();

  const { instance: mapInstance } = useMap();
  const currentMode = useRef(PolliMapMode.IDLE);
  const lastMode = useRef(PolliMapMode.IDLE);
  const hasSelection = selectedDrop || selectedBlock || selectedPoi;

  const isExpanded = useMemo(() => {
    if (isDraggingElements) {
      return false;
    } else if (isManaging || isManagingAnElement || hasSelection) {
      return true;
    } else if (isManagingElements) {
      return false;
    }
    return undefined;
  }, [hasSelection, isDraggingElements, isManaging, isManagingAnElement, isManagingElements]);

  const adjustMapContent = useCallback(
    (isPaneExpanded) => {
      // Can only adjust the content if the mode is switching between idle and managing.
      const canAdjustContent =
        [PolliMapMode.IDLE, PolliMapMode.MANAGING].includes(lastMode.current) &&
        [PolliMapMode.IDLE, PolliMapMode.MANAGING].includes(currentMode.current);

      if (canAdjustContent) {
        if (isPaneExpanded) {
          mapInstance?.panBy({ x: DEF_PANE_WIDTH / -2, y: 0 });
        } else {
          mapInstance?.panBy({ x: DEF_PANE_WIDTH / 2, y: 0 });
        }
      }
    },
    [mapInstance]
  );

  useEffect(() => {
    lastMode.current = currentMode.current;
    currentMode.current = mode;
  }, [mode]);

  if (isStatic) {
    return <>{children}</>;
  }

  return (
    <MapPane initiallyExpanded expanded={isExpanded} hideToggle={!isIdle} onToggled={adjustMapContent}>
      {children}
      {isLoading ? <Loading /> : <PolliMapPaneSummary />}
    </MapPane>
  );
};
