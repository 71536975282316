import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const CheckboxHover = styled.div(
  ({ theme }) => `
    position: absolute;
    top: ${-theme.spacing.xs}px;
    left: ${-theme.spacing.xs}px;
    right: ${-theme.spacing.xs}px;
    bottom: ${-theme.spacing.xs}px;
    border-radius: 50%;
    transition: ${theme.animations.transitionFast('transform')};
    pointer-events: none;
    transform: scale(0);

    ${CheckboxWrapper}:hover && {
      background-color: ${theme.colors.grey03};
      transform: scale(1.0);
    }
`
);

export const CheckboxInput = styled.input(
  ({ theme }) => `
    appearance: none;
    cursor: pointer;
    position: relative;
    margin: 0;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: ${theme.shape.paperRadius01}px;
    border: 2px solid ${theme.colors.grey05};
    background-color: white;
    transition: ${theme.animations.transitionFast('border')};

    ::after {
      content: '';
      position: absolute;
      width: 11px;
      height: 6px;
      border-bottom: 0 solid ${theme.colors.coreWhite};
      border-left: 0 solid ${theme.colors.coreWhite};
      transform: translate(0px, -1px) rotate(-45deg);
      pointer-events: none;
      top: 5px;
      left: 4px;
    }

    :checked {
      border: none;
      background-color: ${theme.colors.grey08};
    }

    :checked::after {
      border-bottom: 2px solid ${theme.colors.coreWhite};
      border-left: 2px solid ${theme.colors.coreWhite};
    }

    :disabled {
      cursor: default;
    }
  `
);

export const CheckboxHiddenLabel = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
