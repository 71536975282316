import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';

import { Box } from '@components/common/Box';
import { Pollination } from '@components/common/Icon/presets/Pollination';
import { Yard } from '@components/common/Icon/presets/Yard';
import { PoiIcon } from '@components/common/PoiIcon';
import { DOM } from '@helpers/DOM';
import { HexID } from '@helpers/HexID';
import { Translate } from '@hooks/useTranslation';

import { PolliMap } from '../PolliMap';

import { LegendItem } from './PolliContractPrintable';
import { PolliContractPrintablePage, PrintablePageProps } from './PolliContractPrintablePage';
import {
  StyledDetailText,
  StyledFlexColWrapper,
  StyledMapLegend,
  StyledMapLegendItem,
  StyledMapWrapper,
  StyledSectionTitle,
  StyledWrapper,
} from './styles';

interface PolliContractMapPrintableProps {
  t: Translate;
  contract: BeeContract;
  drops: Array<BeeDrop> | null;
  blocks: Array<BeeBlock> | null;
  pois: Array<BeePointOfInterest> | null;
  renderedItems: LegendItem[][];
  setRenderedItems: React.Dispatch<React.SetStateAction<LegendItem[][]>>;
  onLoad: () => void;
}

const BlockItem: React.FC<{ id: string; block: BeeBlock; t: Translate }> = ({ block, t, id }) => {
  return (
    <StyledMapLegendItem id={id}>
      <Box gap={'12px'}>
        <div>
          <Pollination size={24} color={'grey08'} />
        </div>
        <StyledDetailText bold>{block.name}</StyledDetailText>
      </Box>
      <StyledDetailText textRight>{t('pollination_block_acres_count', { count: block.area })}</StyledDetailText>
    </StyledMapLegendItem>
  );
};
const DropItem: React.FC<{ id: string; drop: BeeDrop; t: Translate }> = ({ drop, t, id }) => {
  return (
    <StyledMapLegendItem id={id}>
      <Box gap={'12px'}>
        <Yard size={24} color={'grey08'} />

        <StyledDetailText bold>{drop.name}</StyledDetailText>
      </Box>
      <StyledDetailText textRight>
        {t('pollination_drops_hives_count', { count: drop.targetHiveNumber })}
      </StyledDetailText>
    </StyledMapLegendItem>
  );
};
const PoiItem: React.FC<{ id: string; poi: BeePointOfInterest; t: Translate }> = ({ poi, t, id }) => {
  return (
    <StyledMapLegendItem id={id}>
      <Box gap={'12px'}>
        <div>
          <PoiIcon category={poi.category} size={24} color={'grey08'} />
        </div>

        <StyledDetailText bold>{poi.name}</StyledDetailText>
      </Box>

      <StyledDetailText textRight>{t(`pollination_poi_category_${poi.category}`)}</StyledDetailText>
    </StyledMapLegendItem>
  );
};
export const PolliContractMapPrintable: React.FC<PolliContractMapPrintableProps & PrintablePageProps> = ({
  t,
  contract,
  totalPages,
  heading,
  subheading,
  lang,
  renderedItems,
  setRenderedItems,
  ...props
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { drops, blocks, pois } = props;

  const generateItemKey = useCallback(() => {
    return `legend-item-${contract.id}-${HexID.generateHexID()}`;
  }, [contract.id]);

  const allItems = useMemo(() => {
    if (!blocks || !drops || !pois) return [];

    return [
      { data: 'pollination_blocks', key: generateItemKey(), type: 'title' },
      ...blocks.map((data) => ({ data, key: generateItemKey(), type: 'block' })),
      { data: 'pollination_drops', key: generateItemKey(), type: 'title' },
      ...drops.map((data) => ({ data, key: generateItemKey(), type: 'drop' })),
      { data: 'pollination_pois', key: generateItemKey(), type: 'title' },
      ...pois.map((data) => ({ data, key: generateItemKey(), type: 'poi' })),
    ] as Array<LegendItem>;
  }, [blocks, drops, generateItemKey, pois]);

  useLayoutEffect(() => {
    if (!allItems.length) {
      return;
    }

    if (!renderedItems.length) {
      setRenderedItems([[...allItems]]);
      return;
    }

    const getBrokenItemKey = () => {
      const items = Array.from<HTMLElement>(
        contentRef.current?.querySelectorAll(`[id^=legend-item-${contract.id}-]`) ?? []
      );

      for (const item of items) {
        const parent = item?.closest('.js-page') as HTMLElement;
        const maxPageWidth = parent?.getBoundingClientRect().width ?? 0;
        const maxPageHeight = (parent?.getBoundingClientRect().height ?? 0) * 0.95;
        const parentOffset = DOM.getDistanceFromParent(item, parent);
        const isWithinThePage = parentOffset.x < maxPageWidth && parentOffset.y < maxPageHeight;

        if (!isWithinThePage) {
          return item.id;
        }
      }

      return null;
    };

    const brokenItemKey = getBrokenItemKey();

    if (brokenItemKey) {
      setRenderedItems((current) => {
        const lastItemsPage = current[current.length - 1];

        if (lastItemsPage) {
          const brokenItemIndex = lastItemsPage.findIndex(({ key }) => key === brokenItemKey);
          if (brokenItemIndex !== -1) {
            return [
              ...renderedItems.slice(0, renderedItems.length - 1),
              lastItemsPage.slice(0, brokenItemIndex),
              lastItemsPage.slice(brokenItemIndex),
            ];
          }
        }
        return current;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedItems, allItems]);

  return (
    <div ref={contentRef}>
      <PolliContractPrintablePage totalPages={totalPages} heading={heading} subheading={subheading} t={t} lang={lang}>
        <StyledMapWrapper data-polli-map={contract.id}>
          <PolliMap static {...props} />
        </StyledMapWrapper>
      </PolliContractPrintablePage>
      {renderedItems.map((pageOfItems, index) => {
        return (
          <PolliContractPrintablePage
            key={index}
            totalPages={totalPages}
            heading={heading}
            subheading={subheading}
            t={t}
            lang={lang}
          >
            <StyledFlexColWrapper>
              {pageOfItems.map((item) => {
                const itemKey = item.key;

                if (item.type === 'title') {
                  return (
                    <StyledSectionTitle bold id={itemKey} key={itemKey}>
                      {t(item.data as string)}
                    </StyledSectionTitle>
                  );
                }
                if (item.type === 'block')
                  return <BlockItem id={itemKey} key={itemKey} block={item.data as BeeBlock} t={t} />;
                if (item.type === 'drop')
                  return <DropItem id={itemKey} key={itemKey} drop={item.data as BeeDrop} t={t} />;
                if (item.type === 'poi')
                  return <PoiItem id={itemKey} key={itemKey} poi={item.data as BeePointOfInterest} t={t} />;
              })}
            </StyledFlexColWrapper>
          </PolliContractPrintablePage>
        );
      })}
    </div>
  );
};
