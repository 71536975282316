import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import xSmallAssignIcon from '@assets/xSmall_Assign.svg';
import deleteIcon from '@assets/xSmall_Delete.svg';
import editIcon from '@assets/xSmall_Edit.svg';
import { Box } from '@components/common/Box';
import { Menu } from '@components/common/Menu';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import { useGridApi } from '@components/yard/YardsList/hooks';
import { YardRow } from '@components/yard/YardsList/types';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import {
  makeOpenAssignYardsGroupsModalAction,
  makeOpenDeleteYardModalAction,
  makeOpenUpdateYardModalAction,
} from '@redux/deprecated/actions';
const featureFlagGroupingEnabled = APP.featureFlag.grouping;

export const MenuCell: React.FC<{ data: YardRow | null }> = ({ data }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const isActive = data?.meta.isActive;
  const { gridApi } = useGridApi();

  const handleUpdateYardModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        dispatch(
          makeOpenUpdateYardModalAction({
            modalType: 'update-yard',
            yard: data.meta,
            onUpdated: () => gridApi?.refreshInfiniteCache(),
          })
        );
      }
    },
    [data, dispatch, gridApi]
  );

  const handleRemoveYardModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        dispatch(
          makeOpenDeleteYardModalAction({
            modalType: 'remove-yard',
            yard: data.meta,
            onDelete: () => gridApi?.refreshInfiniteCache(),
          })
        );
      }
    },
    [data, dispatch, gridApi]
  );

  const handleAssignGroupsModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        // Making it compatible with the old modal.
        const yard = { ...data.meta, group_ids: data.meta.groups.map((group) => group.id) };
        dispatch(
          makeOpenAssignYardsGroupsModalAction({
            modalType: 'assign-yard-groups',
            yard,
            onAssign: () => gridApi?.refreshInfiniteCache(),
          })
        );
      }
    },
    [data, dispatch, gridApi]
  );

  const defMenuItems = [
    { title: t('edit_yard'), icon: editIcon, onClick: handleUpdateYardModal },
    { title: t('delete_yard'), icon: deleteIcon, disabled: isActive, onClick: handleRemoveYardModal },
  ];
  const menuItems = featureFlagGroupingEnabled
    ? [...defMenuItems, { title: t('assign_groups'), icon: xSmallAssignIcon, onClick: handleAssignGroupsModal }]
    : defMenuItems;

  return data ? (
    <Box fit justifyContent={'flex-end'} className={'yard-list--menu-cell'} style={{ position: 'relative' }}>
      <ThreeDotsMenuTrigger id={`menu-${data.meta.id}`} />
      <Menu items={menuItems} placement={'bottom-end'} target={`menu-${data.meta.id}`} />
    </Box>
  ) : null;
};
