import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const ArrowLeft = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M20 11.0001H6.414L10.707 6.70711C10.967 6.45603 11.0712 6.08423 10.9797 5.7346C10.8882 5.38497 10.6151 5.11192 10.2655 5.02041C9.91588 4.92889 9.54407 5.03315 9.293 5.29311L3.293 11.2931C2.90262 11.6836 2.90262 12.3166 3.293 12.7071L9.293 18.7071C9.68538 19.0861 10.3091 19.0807 10.6948 18.6949C11.0806 18.3092 11.086 17.6855 10.707 17.2931L6.414 13.0001H20C20.5523 13.0001 21 12.5524 21 12.0001C21 11.4478 20.5523 11.0001 20 11.0001Z"
      />
    </IconSVGView>
  );
};
