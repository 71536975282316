import React from 'react';

import { TimelineBase } from '@components/timeline/TimelineBase';
import { useItemContentRenderer, useItemHeadingRender } from '@components/timeline/TimelineByActivityDate/hooks';
import { TimelineByActivityDateProps } from '@components/timeline/TimelineByActivityDate/types';
import { TimelineByActivityRequestModal } from '@components/timeline/TimelineByActivityRequest/TimelineByActivityRequestModal';
import { useActionCategoriesFetcher } from '@hooks/useActions';
import { useTranslation } from '@hooks/useTranslation';

export const TimelineByActivityDate: React.VFC<TimelineByActivityDateProps> = ({ highlightedYardId, ...props }) => {
  const t = useTranslation();

  useActionCategoriesFetcher();

  const itemHeadingRenderer = useItemHeadingRender();
  const itemContentRenderer = useItemContentRenderer();

  return (
    <>
      <TimelineBase
        enableGoToTopArrow
        emptyMessage={t('timeline_empty_message')}
        {...{ itemHeadingRenderer, itemContentRenderer }}
        {...props}
      />
      <TimelineByActivityRequestModal />
    </>
  );
};
