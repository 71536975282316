import { useCallback, useContext, useMemo } from 'react';

import { YardsMapContext } from '@components/yard/YardsMap/contex';
import { ActiveLayers, ActiveLayersToggle, YardsMapContextValue, YardsMapLayer } from '@components/yard/YardsMap/types';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useQueryParamState } from '@helpers/QueryParams/hooks';

export function useYardsMap(): YardsMapContextValue {
  const yardsMap = useContext(YardsMapContext);

  if (!yardsMap) {
    throw new Error("Can't find a parent yards map context.");
  }

  return yardsMap;
}

/**
 * Stores the selected map layers to the URL query params.
 * */
export function useActiveLayersState(
  options = { paramName: 'layers', separator: ',', alwaysActive: [YardsMapLayer.DEFAULT] }
): [ActiveLayers, ActiveLayersToggle] {
  const { paramName, separator, alwaysActive } = options;
  const [layersQueryParam, setLayersQueryParam] = useQueryParamState(paramName);

  const activeLayers = useMemo(() => {
    const _activeLayers = layersQueryParam
      .split(separator)
      .filter((layer) => layer)
      .reduce((activeLayers, layer) => ({ ...activeLayers, [layer]: true }), {}) as ActiveLayers;

    alwaysActive.forEach((layer) => (_activeLayers[layer] = true));

    return _activeLayers;
  }, [alwaysActive, layersQueryParam, separator]);

  const toggleActiveLayer = useCallback(
    (layer: YardsMapLayer) => {
      const nextLayers = { ...activeLayers, [layer]: !activeLayers[layer] };

      const nextLayersQueryParam = (Object.keys(nextLayers) as Array<YardsMapLayer>)
        .filter((layer) => nextLayers[layer])
        .filter((layer) => !alwaysActive.includes(layer))
        .join(separator);

      setLayersQueryParam(nextLayersQueryParam);
      Analytics.sendEvent({
        event: AnalyticsEventType.YARDS_MAP_LAYER_CHANGE,
        eventData: { map_layers: nextLayersQueryParam },
      });
    },
    [activeLayers, alwaysActive, separator, setLayersQueryParam]
  );

  return [activeLayers, toggleActiveLayer];
}
