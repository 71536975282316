import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Deadout = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H20V8C20 9.65685 18.6569 11 17 11C15.3431 11 14 9.65685 14 8V7H13C12.4477 7 12 6.55228 12 6C12 5.44772 12.4477 5 13 5H14V4C14 2.34315 15.3431 1 17 1C18.6569 1 20 2.34315 20 4V5ZM17 9C17.5523 9 18 8.55228 18 8V7H16V8C16 8.55228 16.4477 9 17 9ZM18 5H16V4C16 3.44772 16.4477 3 17 3C17.5523 3 18 3.44772 18 4V5ZM3.418 2.423C3.02753 2.03143 2.39357 2.03053 2.002 2.421C1.61043 2.81147 1.60953 3.44543 2 3.837L5.555 7.392C4.60251 7.91547 4.00764 8.91317 4 10V11H3C2.44771 11 2 11.4477 2 12C2 12.5523 2.44771 13 3 13H4V14C4 15.6569 5.34314 17 7 17C8.65685 17 10 15.6569 10 14V13H11C11.0452 12.994 11.09 12.985 11.134 12.973L14.015 15.856C14.015 15.8812 14.011 15.9068 14.0072 15.9318L14.0072 15.9318L14.0072 15.9319C14.0035 15.9555 14 15.9786 14 16V17H13C12.4477 17 12 17.4477 12 18C12 18.5523 12.4477 19 13 19H14V20C13.9937 21.3667 14.9158 22.5633 16.239 22.9056C17.5622 23.2478 18.9488 22.6483 19.606 21.45L19.733 21.577C20.1235 21.9677 20.7568 21.968 21.1475 21.5775C21.5382 21.187 21.5385 20.5537 21.148 20.163L3.418 2.423ZM8 14C8 14.5523 7.55228 15 7 15C6.44771 15 6 14.5523 6 14V13H8V14ZM8 11V10C7.99311 9.93179 7.97938 9.86445 7.959 9.799L7.2 9.039C7.13476 9.01952 7.06777 9.00646 7 9C6.73347 8.99563 6.47656 9.09958 6.28807 9.28807C6.09958 9.47657 5.99563 9.73347 6 10V11H8ZM18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20V19H17.157L18 19.843V20Z"
      />
    </IconSVGView>
  );
};
